ï»¿/* eslint-disable no-invalid-this */
const api = (module.exports = {});

const $ = require("jquery");
const ko = require("knockout");
const pageState = require("../Core/plex-pagestate");
const nav = require("../Core/plex-navigate");
const navbar = require("./plex-navbar");
const pubsub = require("../Core/plex-pubsub");

const MainMenu = require("./Menu/plex-main-menu");
const RoleBasedMenu = require("./Menu/plex-role-based-menu");
const plexExport = require("../../global-export");

const selector = ".plex-compass";

function Compass(el, options) {
  this.$element = $(el);
  this.init(options);
}

function getDefaultNavigationMode(callback) {
  return $.getJSON(nav.buildUrl("/Platform/Navigation/GetDefaultNavigationMode"), callback);
}

Compass.prototype = {
  init: function (_options) {
    const self = this;
    let mainMenu, roleBasedMenu;

    const compassLoad = ko.observable(false);
    const lastKnownNavigationMode = pageState.getLastKnownValue("lastMenuDisplayMode");
    const defaultUserNavigationMode = ko.observable(null);
    const previousSelectedNavigationMode = ko.observable(null);
    const defaultNavigationMode = ko.pureComputed(() => {
      return lastKnownNavigationMode || defaultUserNavigationMode() || "buttons";
    });
    const previousNavigationMode = previousSelectedNavigationMode.extend({ or: defaultNavigationMode });
    const privateDisplayMode = ko.observable(null);

    getDefaultNavigationMode((data) => {
      defaultUserNavigationMode(data);
      pageState.addToCurrentState("lastMenuDisplayMode", data);
    });

    self.displayMode = ko.pureComputed({
      read: function () {
        return privateDisplayMode() || defaultNavigationMode();
      },
      write: function (value) {
        privateDisplayMode(value);
      },
      owner: this
    });

    self.menu = ko.computed(() => {
      if (compassLoad() !== true) {
        return null;
      }

      if (self.displayMode() === "role") {
        if (!roleBasedMenu) {
          roleBasedMenu = new RoleBasedMenu();
        }

        roleBasedMenu.loadInitialMenu();
        return roleBasedMenu;
      }

      if (!mainMenu) {
        mainMenu = new MainMenu();
      }

      mainMenu.loadInitialMenu();
      return mainMenu;
    });

    pubsub.subscribe("compass.load", (oCallback) => {
      oCallback();

      if (pageState.getCurrentState().selectedNodeKey) {
        compassLoad(false);
        mainMenu = null;
      }

      compassLoad(true);
    });

    self.displayMode.subscribe((newValue) => {
      previousNavigationMode(newValue);
      pageState.addToCurrentState("lastMenuDisplayMode", newValue);
    });

    self.reset = () => {
      mainMenu?.reset();
      roleBasedMenu?.reset();
      compassLoad(false);
    };

    ko.renderTemplate("menu", self, {}, self.$element[0]);
  },

  close: function () {
    navbar.closePanels();
  }
};

$.fn.compass = function (options) {
  /// <summary>
  /// Attaches events & data gathering functions to the navbar.
  /// </summary>
  /// <param name="options"></param>
  /// <returns>The jQuery object that called navbar.</returns>

  const settings = $.extend(
    {
      // These are the defaults.
    },
    options
  );

  return this.each(function () {
    let currentCompass = $(this).data("compass");
    if (!currentCompass) {
      currentCompass = new Compass(this, settings);
      $(this).data("compass", currentCompass);
    }

    return this;
  });
};

// todo: obsolete
api.search = navbar.search;

api.reset = function () {
  const compass = $(selector).data("compass");
  compass?.reset();
  $(document).click();
};

$(() => {
  $(selector).each(function () {
    $(this).compass();
  });
});

plexExport("compass", api);
