/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const DialogController = require("./plex-dialog-controller");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");

const defaultAskOptions = {
  autoGlossarize: true,
  autoGlossarizeOptions: true,
  displayType: "default",
  rejectOnEmpty: true
};

const displayTypes = ["buttons", "list", "textbox", "password", "checkboxes", "textarea"];

const normalizeAnswers = (message, index) => {
  let answer = message;
  if (typeof answer === "string") {
    answer = {
      answerText: answer,
      answerValue: answer
    };
  }

  answer.answerValue = answer.answerValue || answer.answerText;
  answer.buttonClass = answer.buttonClass || "btn btn-sm default-action";

  if (index === 0 && !answer.answerValue) {
    answer.answerValue = undefined;
  }

  return answer;
};

const getDefaultDisplayType = (answers) => {
  if (answers.length < 2) {
    return "textbox";
  }

  if (answers.length > 4) {
    return "list";
  }

  return "buttons";
};

const setupVisualKeyboard = (options) => {
  if (!options.displayPasswordKeyboard) {
    return;
  }

  if (options.displayType !== "password" && options.displayType !== "textbox") {
    return;
  }

  const visualKeyboard = plexImport("visualKeyboard", true);
  if (visualKeyboard) {
    const isPassword = options.displayType === "password";
    const id = isPassword ? "AskDialog_Password_Keyboard" : "AskDialog_Keyboard";
    const boundId = isPassword ? "AskDialog_Password" : "AskDialog_Text";

    setTimeout(() => {
      visualKeyboard.setupKeyboardElement({
        id,
        boundInputIds: [boundId],
        onAccept: this.sendAnswer,
        model: {
          layout: { address: "ElementKeyboardLayout" },
          scale: options.keyboardScale || 1
        }
      });
    }, 300);
  }
};

function Ask(question, answersOrEmpty, defaultAnswerOrCallback, optionsOrCallback, callbackOrEmpty) {
  if (!(this instanceof Ask)) {
    return new Ask(question, answersOrEmpty, defaultAnswerOrCallback, optionsOrCallback, callbackOrEmpty);
  }

  const $deferred = new $.Deferred();

  let defaultAnswer = defaultAnswerOrCallback;
  let callback = callbackOrEmpty;
  let options = optionsOrCallback;
  const answers = (answersOrEmpty || []).map(normalizeAnswers);

  if (typeof defaultAnswer === "function") {
    callback = defaultAnswer;
    defaultAnswer = null;
  }

  if (typeof options === "function") {
    callback = options;
    options = null;
  }

  options = { ...defaultAskOptions, ...options };

  if (!Array.isArray(defaultAnswer)) {
    defaultAnswer = [defaultAnswer];
  }

  this.$element = $("<div class='dialog-wrapper'>").appendTo(document.body);

  let questionObject = question;
  if (typeof question === "string") {
    questionObject = {
      text: question
    };
  }

  if (!questionObject) {
    notify.error("Message property is not defined for Confirm Action.");
    return $deferred.reject();
  }

  questionObject.autoGlossarize = options.autoGlossarize;

  this.question = questionObject;
  this.answers = answers;
  this.selectedAnswers = ko.observableArray(defaultAnswer);
  this.config = options;
  this.title = options.title ? { text: options.title, autoGlossarize: options.autoGlossarize } : "Question";

  if (!displayTypes.some((t) => t === this.config.displayType)) {
    this.config.displayType = getDefaultDisplayType(answers);
  }

  this.selectedAnswer = ko.pureComputed({
    read: this.selectedAnswers,
    write: (value) => {
      this.selectedAnswers.removeAll();
      this.selectedAnswers.push(value);
    }
  });

  this.sendAnswer = () => {
    // TODO: Having tokens in the answer is causing issues with them coming back when they are not used in the "list" display mode.
    let sentAnswers;
    if (this.config.displayType === "checkboxes") {
      sentAnswers = this.selectedAnswers();
    } else {
      sentAnswers = this.selectedAnswers()[0];
    }

    this.dialog.close(sentAnswers);
  };

  this.selectAndSend = (selectedValue) => {
    this.selectedAnswer(selectedValue);
    this.sendAnswer();
  };

  ko.renderTemplate("dialog-ask", this, {}, this.$element[0]);

  this.dialog = DialogController.create({
    dialogElement: this.$element.find(".plex-dialog"),
    autoShow: true,
    returnAction: function (data) {
      callback?.(data);

      if (data || !options.rejectOnEmpty) {
        $deferred.resolve(data);
      } else {
        $deferred.reject();
      }
    },
    closeAction: $deferred.reject
  });

  setupVisualKeyboard(options);

  return $deferred.promise();
}

plexExport("Dialogs.Ask", Ask);

module.exports = function (...args) {
  // Using global so that local references will pick up replaced instance for SPA.
  return plexImport("Dialogs.Ask")(...args);
};
