ï»¿const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const root = plexImport("documentGallery");

const DocumentGalleryItemFactory = {
  create: function (item) {
    if (!item || !(item.address in root)) {
      throw new Error("Unable to find document gallery item for: " + (item && item.address));
    }

    const galleryItem = new root[item.address]();
    galleryItem.init(item);

    return galleryItem;
  }
};

module.exports = DocumentGalleryItemFactory;
plexExport("documentGallery.DocumentGalleryItemFactory", DocumentGalleryItemFactory);
