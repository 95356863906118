ï»¿const ko = require("knockout");
const $ = require("jquery");
const Glossary = require("../Globalization/plex-glossary-handler");

ko.peek = ko.peek || ko.utils.peekObservable;

// inspired by: http://www.knockmeout.net/2011/06/fun-with-highlighting-in-knockoutjs.html
ko.bindingHandlers.highlightedText = {
  update: function (element, valueAccessor) {
    const options = valueAccessor();
    const value = ko.unwrap(options.text);
    const search = ko.unwrap(options.highlight);
    const css = ko.unwrap(options.css);
    let rgx;

    if (search && value) {
      /* eslint-disable no-useless-escape */
      rgx = new RegExp("(" + String(search).replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1") + ")", "gi");
      element.innerHTML = value.replace(rgx, "<span class='" + css + "'>$1</span>");
    } else {
      element.innerHTML = value;
    }
  }
};

ko.bindingHandlers.glossarizedOptions = {
  update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    const selectOptions = ko.unwrap(valueAccessor());
    const allBindings = ko.unwrap(allBindingsAccessor());
    const config = viewModel.config;

    let i, plexWord, customerWord, dynamicObject, newValueAccessor;
    const plexWords = [];
    let glossarizedSelectOptions = [];
    const optionValues = {};

    for (i = 0; i < selectOptions.length; i++) {
      // "allBindings.optionsText" and "allBindings.optionsValue" are
      // dynamic properties for the select list option text and value.
      plexWord = selectOptions[i][allBindings.optionsText];

      plexWords.push(plexWord);
      optionValues[plexWord] = selectOptions[i][allBindings.optionsValue];
    }

    if (config.autoGlossarizeOptions) {
      Glossary.getCustomerWordBulkAsync(plexWords).done((results) => {
        glossarizedSelectOptions = [];

        for (i = 0; i < selectOptions.length; i++) {
          plexWord = selectOptions[i][allBindings.optionsText];

          customerWord = results[plexWord];

          // "allBindings.optionsText" and "allBindings.optionsValue" are
          // dynamic properties for the select list option text and value.
          dynamicObject = {};
          dynamicObject[allBindings.optionsValue] = optionValues[plexWord];
          dynamicObject[allBindings.optionsText] = customerWord;

          glossarizedSelectOptions.push(dynamicObject);
        }

        newValueAccessor = function () {
          return glossarizedSelectOptions;
        };

        ko.bindingHandlers.options.update(element, newValueAccessor, allBindingsAccessor, viewModel, bindingContext);
      });
    } else {
      ko.bindingHandlers.options.update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    }
  }
};

ko.bindingHandlers.stopBindings = {
  init: function () {
    return { controlsDescendantBindings: true };
  }
};
ko.virtualElements.allowedBindings.stopBindings = true;

ko.bindingHandlers.fadeVisible = {
  init: function (element, valueAccessor) {
    // Initially set the element to be instantly visible/hidden depending on the value
    const value = valueAccessor();
    $(element).toggle(ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
  },
  update: function (element, valueAccessor) {
    // Whenever the value subsequently changes, slowly fade the element in or out
    const value = valueAccessor();
    ko.utils.unwrapObservable(value) ? $(element).fadeIn(100) : $(element).hide();
  }
};

ko.bindingHandlers.keepFocus = {
  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    $(element).keepFocus(value ? "on" : "off");
  }
};

ko.bindingHandlers.focusTabbableChildren = {
  init: function (element) {
    const $element = $(element);

    const tabFunction = function (e) {
      if (e.which === 9) {
        e.preventDefault();
        let tabbleElements = $element.find(":tabbable").toArray();

        // elements with tabIndex needed to be sorted
        // sorting of elements with tabIndex == 0 Ñauses problems with elements order, we should divide tabbleElements into two arrays and sort elements with tabIndex > 0 separately
        const elementsWithoutTabIndex = tabbleElements.filter((elem) => elem.tabIndex === 0);
        const elementsWithTabIndex = tabbleElements
          .filter((elem) => elem.tabIndex > 0)
          .sort((a, b) => a.tabIndex - b.tabIndex);
        tabbleElements = elementsWithoutTabIndex.concat(elementsWithTabIndex);

        let nextElementIndex = tabbleElements.indexOf(e.target);

        if (e.shiftKey === true) {
          nextElementIndex = nextElementIndex > 0 ? nextElementIndex - 1 : tabbleElements.length - 1;
        } else {
          nextElementIndex = nextElementIndex < tabbleElements.length - 1 ? nextElementIndex + 1 : 0;
        }

        const nextInput = tabbleElements[nextElementIndex];
        if (nextInput) {
          // UXTC-229: RichTextEditor is situated in iframe element, which prevents normal tab behaviour on dialogs
          if (nextInput.localName === "iframe") {
            nextInput.contentWindow.document.body.focus();
          } else {
            $(nextInput).focus().select();
          }
        }
      }
    };

    $element.on("keydown.focusTabbableChildren", tabFunction);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $element.off("keydown.focusTabbableChildren", tabFunction);
    });
  }
};
