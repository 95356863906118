ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const pubsub = require("../Core/plex-pubsub");
const pageHandler = require("./plex-handler-page");
const dataUtils = require("../Utilities/plex-utils-data");
const FeatureProcessor = require("../Features/plex-feature-processor");
const bindingHandler = require("./plex-handler-bindings");
const printing = require("../Core/plex-printing");
const validation = require("../Core/plex-validation");
const JsUtils = require("../Utilities/plex-utils-js");
const ElementHandler = require("./plex-handler-element");
const ControllerFactory = require("./plex-controller-factory");
const DataSourceFactory = require("../Data/plex-datasource-factory");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const TreeController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

function Node(key, nodeModel, nodeDepth, label, labelProperty, titleLabel, features) {
  this.Key = key;
  this.NodeModel = nodeModel;
  this.NodeDepth = nodeDepth;
  this.elements = {};
  this.features = features;
  // KO track doesnt work on this level, jqtree wipes out getters/setters
  this.isOpen = ko.observable(false).extend({ notify: "always" });

  // jqtree required properties
  this.titleLabel = ko.observable(titleLabel);

  if (labelProperty) {
    this.label = dataUtils.getObservable(nodeModel, labelProperty);
  } else {
    this.label = ko.observable(label);
  }

  this.id = 0;
  /* eslint camelcase: "off" */
  this.load_on_demand = true;
  this.is_open = false;
}

// Filters a collection of Nodes by a Key
function filterNodeData(nodes, filterProp, filterKey) {
  let props;

  if (filterProp) {
    props = filterProp.split(".");
  }

  return nodes.filter((item) => {
    if (!filterKey && filterKey !== 0) {
      return true;
    }
    if (item[filterProp] === filterKey) {
      return true;
    }
    if (props[1] && item[props[0]][props[1]] === filterKey) {
      return true;
    }
    return false;
  });
}

function flattenTree(treeData) {
  const nodeArray = [];

  const addNodes = function (nodes) {
    nodes.forEach((node) => {
      nodeArray.push(node);
      if (node.children) {
        addNodes(node.children);
      }
    });
  };

  addNodes(treeData);

  return nodeArray;
}

function createXmlFromData(node, data) {
  const xnode = node
    .createNode("plex-control-tree-node")
    .addElement("name", ko.utils.unwrapObservable(data.name))
    .addAttribute("opened", data.is_open || false);

  if (data.elementArray && data.elementArray.length > 0) {
    const printElements = printing.filterElements(data.elementArray);
    if (printElements.length > 0) {
      const elements = xnode.createNode("plex-controls");
      printElements.forEach((element) => {
        elements.addControlElement(element);
      });
    }
  }

  if (data.children && data.children.length > 0) {
    const children = xnode.createNode("plex-control-tree");
    data.children.forEach((child) => {
      createXmlFromData(children, child);
    });
  }
}

function setupFeatures(node, data, controller) {
  const fp = new FeatureProcessor(node.features, node, controller);
  const featureResult = ko.pureComputed(() => fp.process(data));

  node.style = ko.pureComputed(() => featureResult().style);
  node.css = ko.pureComputed(() => featureResult().css.join(" "));
  node.visible = ko.observable(true).andAlso(() => featureResult().render !== false);
}

function setupBindings(node, nodeLevel, parentNode, controller) {
  if (nodeLevel.bindings.length > 0 && parentNode) {
    nodeLevel.bindings.forEach((binding) => {
      binding.target = node.NodeModel;
      bindingHandler.update(binding, parentNode.NodeModel);
      dataUtils.trackProperty(node.NodeModel, binding.targetPropertyName);

      node.subscriptions.push(
        ko.getObservable(parentNode.NodeModel, binding.sourcePropertyName).subscribe((_value) => {
          // The Target node reference is different on the tree
          binding.target = controller.getNodeById(node.id).NodeModel;
          bindingHandler.update(binding, parentNode.NodeModel);
        })
      );
    });
  }
}

function setupPropertyValidation(node, $node) {
  // Get Validatable Properties
  for (const element in node.elements) {
    if (Object.prototype.hasOwnProperty.call(node.elements, element) && node.elements[element].propertyName) {
      const propertyName = node.elements[element].propertyName;
      node.$elements[propertyName] = node.$elements[propertyName] || [];

      const $element = $node.find("#" + node.elements[element].id);

      if ($element.length > 0) {
        node.$elements[propertyName].push($element);

        // We delay property validation initlization until elements have been rendered.
        JsUtils.defer(node.validator.initPropertyValidation, node.validator, [propertyName]);
      }
    }
  }
}

TreeController.prototype = {
  constructor: TreeController,
  init: function () {
    const self = this;
    this.elements = {};
    self.nodeLevelRepository = [];
    self.$element = $(document.getElementById(self.config.id));
    self.$element.data("controller", this);
    self.$form = this.$element.closest("form");
    plexImport("currentPage")[this.config.id] = this;

    self.treeCreatedCallbacks = [];

    // Used to create unique id's
    self.nodeIndex = 0;
    self.elementIndex = 0;

    // Allows us to call validation on whole tree
    self.validate = function () {
      let valid = true;

      const nodes = flattenTree(self.getTreeData());

      const validateableNodes = nodes.filter((node) => {
        if (node.validator) {
          return true;
        }
        return false;
      });

      if (validateableNodes.length > 0) {
        valid = validateableNodes[0].validator.validateJustValidationControllers.apply(this, validateableNodes);
      }

      return valid;
    };

    // Create the tree tree
    // ko.track is called in the containing form and wipes out ko subscriptions,
    // so we delay execution until after form has been initilized.
    const sub = pubsub.subscribe("init." + this.config.parent.config.id, () => {
      // Dispose subscription
      sub.dispose();

      // Get element after html rendered, in cases of client side template
      self.$element = $(document.getElementById(self.config.id));

      // Create Tree
      $.when(self.createRootNode())
        .then((results) => {
          self.createTree(self, results);
        })
        .then(self.restoreState.bind(self))
        .then(self.initExpandedNodes.bind(self))
        .then(self._treeCreated.bind(self));
    });
  },

  createRootNode: function () {
    this.loadNodeLevelRepository(this.createRootNodeLevel(this.config), 0);
    const nodeLevel = this.nodeLevelRepository[0];

    // This creates the first tree level from tree config properties, if any
    if (this.config.displayProperty || this.config.displayName || this.config.displayElement) {
      return this.createNodes(nodeLevel, [this.model]);
    }

    // Creates first tree level from a node level if it has data loaded server side
    if (this.nodeLevelHasData(nodeLevel.childNodeLevel)) {
      return this.createNodes(nodeLevel.childNodeLevel, nodeLevel.childNodeLevel.datasource.data);
    }

    // Creates first tree level from data retrieved via ajax
    return this.getRemoteData(nodeLevel.childNodeLevel, this.model).then((data) => this.createNodes(nodeLevel, data));
  },

  // Creates nodes from nodeLevel Configuration and Data
  createNodes: function (nodeLevel, data, parentNode) {
    // Return an array of promises
    const promises = data.map((record) => this.createNode(nodeLevel, record, parentNode));

    // Resolve when all promises in array resolve
    return $.when(...promises).then(function () {
      const nodes = $.makeArray(arguments);
      return nodes;
    });
  },

  createNode: function (nodeLevel, record, parentNode) {
    const self = this;
    // var deferred = $.Deferred();

    ko.track(record);
    const node = new Node(
      record[nodeLevel.nodeLevelKey] || nodeLevel.nodeLevelKey,
      record,
      nodeLevel.depth,
      nodeLevel.displayName,
      nodeLevel.displayPropertyName,
      nodeLevel.displayPropertyNameLabel,
      nodeLevel.features
    );

    node.$elements = {};
    node.subscriptions = [];

    // Unique ID for nodes, is not persistent
    node.id = ++self.nodeIndex;

    // Setup Toggler
    const resetToggler = ko.observable();

    node.toggler = ko.computed(() => {
      resetToggler();
      // Get Node from tree, may not exist yet in tree
      const treeNode = self.getNodeById(node.id) || node;
      const level = self.getNodeLevelFromNode(treeNode);

      // Remove toggler if no children
      if (self.nodeHasChildren(level, treeNode)) {
        return ko.unwrap(treeNode.isOpen) ? "â¼" : "âº";
      } else {
        return "";
      }
    });

    // Initialize optional element for title area
    if (nodeLevel.displayElement) {
      node.displayElement = self.initElements(node, [nodeLevel.displayElement])[0];
    }

    // Initilize any elements for element area
    if (nodeLevel.elements) {
      node.elementArray = self.initElements(node, nodeLevel.elements);
      self.subscribeToChanges(node);
    }

    setupBindings(node, nodeLevel, parentNode, this);

    setupFeatures(node, record, self);

    if (nodeLevel.validationModel && nodeLevel.validationModel.rules) {
      node.validator = validation.createValidator(this.$form, nodeLevel.validationModel, node);
      // This will be the last validationController, but that ok
      self.validator = node.validator;
    }

    node.validate = function (suppressBanner) {
      if (!node.validator) {
        return true;
      }

      return node.validator.isValid(suppressBanner);
    };

    // DataRoute based Key needs to be setup after setupBindings
    if (!node.Key) {
      node.Key = self.createNodeKeyFromDataRoute(node.NodeModel, nodeLevel);
    }

    // Subscribe to changes in DataRoute Bindings and refresh children
    if (nodeLevel.childNodeLevel && nodeLevel.childNodeLevel.dataRoute) {
      const childDataRoute = nodeLevel.childNodeLevel.dataRoute;

      childDataRoute.parameters.forEach((parm) => {
        const prop = parm.binding ? parm.binding.targetPropertyName : null;

        if (prop) {
          parm.binding.target = node.NodeModel;
          const observable = dataUtils.getObservable(node.NodeModel, prop);

          node.subscriptions.push(
            observable.subscribe(() => {
              self.refreshChildNodes(self.getNodeById(node.id)).then(() => {
                resetToggler.notifySubscribers();
              });
            })
          );
        }
      });
    }

    // We create nodes if we have child data loaded or config requests to expand node
    if (nodeLevel.childNodeLevel && (nodeLevel.nodeExpanded || self.nodeLevelHasData(nodeLevel.childNodeLevel))) {
      const promise = self.getChildNodeData(self, node).then((results) => {
        node.children = results;
        // deferred.resolve(node);
        return node;
      });

      node.load_on_demand = false;
      return promise;
    }

    // Node has child data, but AJAX and not configured to expand
    if (nodeLevel.childNodeLevel) {
      node.load_on_demand = true;
      // deferred.resolve(node);
      return $.when(node);
    }

    // Node does not have child data
    node.load_on_demand = false;
    return $.when(node);
  },

  createNodeKeyFromDataRoute: function (record, nodeLevel) {
    const childNodeLevel = this.nodeLevelRepository[nodeLevel.depth + 1];
    let key = "";

    if (childNodeLevel && childNodeLevel.dataRoute) {
      childNodeLevel.dataRoute.parameters.forEach((parm) => {
        key = key + record[parm.name] + ":";
      });
    }

    return key;
  },

  createTree: function (controller, data) {
    controller.$element
      .tree({
        data,
        useContextMenu: false,
        selectable: controller.config.selectable,
        onCreateLi: function (node, $li) {
          ko.cleanNode($li[0]);

          // Add placeholderto be replaced by template
          $li.html("<!-- ko stopBindings: true --><div class='temp-container'></div><!-- /ko -->");

          ko.renderTemplate(
            "tree-elements-container",
            { data: node.NodeModel, node, elements: node.elementArray },
            null,
            $li.children(".temp-container"),
            "replaceNode"
          );
          ko.applyBindingsToNode($li[0], { visible: node.visible });

          // Setup Property Validation
          if (node.validator) {
            setupPropertyValidation(node, $li);
          }

          node.element = $li;
        }
      })
      .off("click")
      .click((e) => {
        const $clickedNode = $(e.target);
        const $anchor = $clickedNode.is("a")
          ? $clickedNode
          : $clickedNode.closest(".title-container").closest("li").children(".jqtree-element").find("a");
        let $li, node;

        // User Clicked on title to open
        if ($anchor.hasClass("jqtree-toggler")) {
          $li = $anchor.closest("li");
          $li.addClass("jqtree-loading");
          node = controller.getNodeById($anchor.data("id"));
          controller
            .loadChildren(node)
            .done(controller.toggle.bind(controller))
            .always(controller.saveState.bind(controller))
            .always(() => {
              $li.removeClass("jqtree-loading ");
            });
        }
      })
      .on("tree.open", (e) => {
        $(e.node.element).children(".jqtree-element").addClass("jqtree-open");
      })
      .on("tree.close", (e) => {
        const $tree = $(e.node.element);
        $tree.children(".jqtree-element").removeClass("jqtree-open");
      })
      .on("tree.select", (e) => {
        controller.onTreeSelect(e.node);
      });

    // Select Node in capture phase so ActionElements can have access to selected element
    // Supported in all browsers except <ie9
    controller.$element[0].addEventListener(
      "click",
      (e) => {
        const $clickedNode = $(e.target);

        // Ignore Clicks outside of title or elements
        if ($clickedNode.hasClass("jqtree-elements-container") === false) {
          const nodeId = $clickedNode.closest(".jqtree-element").children("a").data("id");
          const node = controller.getNodeById(nodeId);

          controller.selectNode(node);
        }
      },
      true
    );
  },

  openToNode: function (_nodeDepth, modelProperty, modelKey) {
    const self = this;
    const nodes = flattenTree(this.getTreeData());

    // Get the Node, it must already be loaded
    const node = filterNodeData(nodes, "NodeModel." + modelProperty, modelKey)[0];
    if (node) {
      return this.loadChildren(node).then((nd) => {
        self.openNodeById(nd.id);
      });
    }

    return $.when();
  },

  initExpandedNodes: function () {
    this.openStartExpandedNodes(this.getTreeData());
  },

  openStartExpandedNodes: function (nodes) {
    const self = this;

    nodes.forEach((node) => {
      if (node.children && self.nodeLevelRepository[node.NodeDepth].nodeExpanded) {
        self.openNodeById(node.id);
        self.openStartExpandedNodes(node.children);
      }
    });
  },

  initElements: function (node, elements) {
    const self = this;
    const clones = [];

    elements.forEach((el) => {
      const elementId = "Node-" + node.id + "-" + el.id;

      const clone = $.extend(true, {}, ko.toJS(el));

      self.prepareElement(clone, self.elementIndex);
      ++self.elementIndex;
      self.setElementBindingTarget(clone, node.NodeModel, self);
      self.setElementFeatureSource(clone, node.NodeModel, self);
      ElementHandler.initElement(clone, node.NodeModel, null, node);
      self.updateElement(clone);
      self.elements[elementId] = clone;
      // Flattened Hash of Elements
      self.copyElementsToNode(node, clone);
      clones.push(clone);
    });

    return clones;
  },

  setElementFeatureSource: function (el, nodeModel, controller) {
    const id = controller.config.id;
    const setSource = function (features) {
      features.forEach((feature) => {
        if (feature.sourceId === id) {
          feature.source = nodeModel;
        }
      });
    };

    // Setup Feature Source on Element
    if (el.features) {
      setSource(el.features);
    }

    // Setup Feature Source for Grid
    if (el.columns) {
      el.columns.forEach((column) => {
        if (column.features) {
          setSource(column.features);
        }
      });
    }

    // Setup feature source for child elements
    if (el.elements) {
      el.elements.forEach((child) => {
        controller.setElementFeatureSource(child, nodeModel, controller);
      });
    }
  },

  setElementBindingTarget: function (el, nodeModel, controller) {
    const id = controller.config.id;

    // Update element bindings
    if (el.bindings && el.bindings.length > 0) {
      el.bindings.forEach((binding) => {
        if (binding.targetKey === id) {
          binding.target = nodeModel;
        }
      });
    }

    // Update datasource bindings
    if (el.dataSource && (el.dataSource.route || el.dataSource.parameters)) {
      // Datasource could be a route or IElementDataSource
      const parameters = el.dataSource.parameters || el.dataSource.route.parameters;

      if (parameters) {
        parameters.forEach((parm) => {
          if (parm.binding && parm.binding.targetKey === id) {
            parm.binding.target = nodeModel;
          }
        });
      }
    }

    // Update bindings in child elements
    if (el.elements && el.elements.length > 0) {
      el.elements.forEach((child) => {
        controller.setElementBindingTarget(child, nodeModel, controller);
      });
    }
  },

  copyElementsToNode: function (node, element) {
    const self = this;
    node.elements[element.id] = element;

    if (element.elements) {
      element.elements.forEach((el) => {
        self.copyElementsToNode(node, el);
      });
    }
  },

  subscribeToChanges: function (node) {
    // Subscribe to changes on observable properties
    Object.keys(node.NodeModel).forEach((prop) => {
      let subscribeToEvent = "";
      const observable = dataUtils.getObservable(node.NodeModel, prop);

      // Skip any properties that arent observable
      if (observable) {
        // If an array subscribe to array changes
        if (Array.isArray(node.NodeModel[prop])) {
          subscribeToEvent = "arrayChange";
        }

        const subscription = observable.subscribe(
          this.onModelChange,
          { controller: this, node, prop },
          subscribeToEvent
        );

        node.subscriptions.push(subscription);
      }
    });
  },

  prepareElement: function (el, index) {
    const self = this;
    el.id = self.config.id + "_" + el.id + "_" + index;

    if (el.elements && el.elements.length > 0) {
      el.elements.forEach((child) => {
        self.prepareElement(child, index);
      });
    }
  },

  updateElement: function (el) {
    const self = this;
    if (el.elements && el.elements.length > 0) {
      el.elements.forEach((child) => {
        self.updateElement(child);
      });
    }
  },

  getRemoteData: function (nodeLevel, nodeModel) {
    const self = this;

    const datasource = DataSourceFactory.create(nodeLevel.dataRoute);

    return datasource.get(nodeModel).then((results) => {
      if (self.onRemoteDataLoad) {
        self.onRemoteDataLoad(results);
      }

      return results;
    });
  },

  getChildNodeData: function (element, node) {
    const childNodeLevel = element.getChildNodeLevelFromNode(node);

    // Check For Data
    if (element.nodeLevelHasData(childNodeLevel)) {
      return element.createNodes(
        childNodeLevel,
        filterNodeData(childNodeLevel.datasource.data, childNodeLevel.parentNodeLevelKey, node.Key),
        node
      );
    }
    // Get Remote
    else if (element.nodeLevelHasDataRoute(childNodeLevel)) {
      return element.getRemoteData(childNodeLevel, node.NodeModel).then((data) => {
        return element.createNodes(childNodeLevel, data, node);
      });
    }

    return $.when();
  },

  loadChildren: function (node) {
    const self = this;

    if (node.load_on_demand) {
      return this.getChildNodeData(this, node).then((results) => {
        self.loadData(node, results);
        node = self.getNodeById(node.id);
        node.load_on_demand = false;
        return node;
      });
    }

    return $.when(node);
  },

  toggle: function (node) {
    this.$element.tree("toggle", node);
    this.setToggle(node);
  },

  setToggle: function (node) {
    node.isOpen(node.is_open);
  },

  loadData: function (node, results) {
    this.$element.tree("loadData", results, this.getNodeById(node.id));
  },

  getTreeData: function () {
    return this.$element.tree("getTree").getData();
  },

  getSelectedNode: function () {
    return this.$element.tree("getSelectedNode");
  },

  selectNode: function (node) {
    this.$element.tree("selectNode", node);
  },

  getNodeById: function (id) {
    return this.$element.tree("getNodeById", id);
  },

  openNodeById: function (id) {
    const node = this.getNodeById(id);
    this.$element.tree("openNode", node, false);
    this.setToggle(node);
  },

  moveNodeAfter: function (node, targetNode) {
    this.$element.tree("moveNode", this.getNodeById(node.id), this.getNodeById(targetNode.id), "after");
  },

  moveNodeBefore: function (node, targetNode) {
    this.$element.tree("moveNode", this.getNodeById(node.id), this.getNodeById(targetNode.id), "before");
  },

  MoveNodeInside: function (node, targetNode) {
    this.$element.tree("moveNode", this.getNodeById(node.id), this.getNodeById(targetNode.id), "inside");
  },

  removeNode: function (node) {
    this.disposeNode(node);

    // Remove any Children
    if (node.children) {
      let i = node.children.length;
      while (i--) {
        this.removeNode(node.children[i]);
      }
    }

    // Remove Selection
    if (this.getSelectedNode() === node) {
      this.onTreeSelect(null);
    }

    this.$element.tree("removeNode", this.getNodeById(node.id));
  },

  refreshChildNodes: function (node) {
    const self = this;
    const nodeLevel = self.getNodeLevelFromNode(node);

    if (this.nodeLevelHasDataRoute(nodeLevel.childNodeLevel) && node.children) {
      // Remove child nodes
      const length = node.children.length - 1;
      for (let i = length; i >= 0; i--) {
        self.removeNode(node.children[i]);
      }

      node.load_on_demand = true;
      return self.loadChildren(node).then(self.restoreState.bind(self));
    }

    return $.when();
  },

  getNodeLevelFromNode: function (node) {
    return this.nodeLevelRepository[node.NodeDepth];
  },

  loadNodeLevelRepository: function (nodeLevel, depth) {
    nodeLevel.depth = depth;

    this.nodeLevelRepository[depth] = nodeLevel;

    if (nodeLevel.childNodeLevel) {
      this.loadNodeLevelRepository(nodeLevel.childNodeLevel, depth + 1);
    }

    // If child is expanded, copy to parent node level
    if (nodeLevel.childNodeLevel && nodeLevel.childNodeLevel.nodeExpanded) {
      nodeLevel.nodeExpanded = true;
    }
  },

  createRootNodeLevel: function (config) {
    return {
      elements: this.config.elements,
      displayElement: this.config.displayElement,
      bindings: [],
      features: this.config.features || [],
      childNodeLevel: this.config.rootNodeLevel,
      nodes: [],
      displayPropertyName: config.displayProperty,
      displayName: config.displayName,
      displayPropertyNameLabel: config.displayNameLabel
    };
  },

  nodeLevelHasDataRoute: function (nodeLevel) {
    if (nodeLevel && nodeLevel.dataRoute) {
      return true;
    } else {
      return false;
    }
  },

  nodeLevelHasData: function (nodeLevel) {
    if (nodeLevel && nodeLevel.datasource && nodeLevel.datasource.data) {
      return true;
    } else {
      return false;
    }
  },

  nodeHasChildren: function (nodeLevel, node) {
    if (!nodeLevel.childNodeLevel) {
      return false;
    }

    // Node has been loaded by jqtree so it has children prop
    if (node.children && node.children.length === 0) {
      return false;
    }

    if (this.nodeLevelHasData(nodeLevel.childNodeLevel) && !this.nodeWithDataHasChildren(node)) {
      return false;
    }

    // Can't determine if child nodes loaded via dataroute exist
    return true;
  },

  nodeWithDataHasChildren: function (node) {
    const childNodeLevel = this.getChildNodeLevelFromNode(node);

    if (node.NodeDepth === 0) {
      return true;
    }

    if (childNodeLevel && this.nodeLevelHasData(childNodeLevel)) {
      return filterNodeData(childNodeLevel.datasource.data, childNodeLevel.parentNodeLevelKey, node.Key).length > 0;
    }

    return false;
  },

  getChildNodeLevelFromNode: function (node) {
    return this.nodeLevelRepository[node.NodeDepth + 1];
  },

  // Apply a function to each Node in the tree
  applyToNodes: function (callback) {
    const self = this;
    const rootNodes = this.getTreeData();

    const process = function (nodes, parent) {
      let i = nodes.length;
      while (i--) {
        let node = nodes[i];

        // Make sure to get Node from Tree in case callback
        // contained functions called on tree
        if (parent) {
          node.parent = self.getNodeById(parent.id);
        }
        callback(node);
        node = self.getNodeById(node.id);
        if (node.children) {
          process(node.children, node);
        }
      }
    };

    process(rootNodes);
  },

  onModelChange: function (newValue) {
    const controller = this.controller;
    const node = this.node;
    const prop = this.prop;

    pubsub.publish("treeModelChange." + prop + "." + controller.config.id, node, newValue);
  },

  onTreeSelect: function (selectedNode) {
    pubsub.publish("selected.tree." + this.config.id, selectedNode);
  },

  // Asyc tree might not be loaded when plex.ready is called
  onTreeCreated: function (callback) {
    if (this.isTreeCreated) {
      callback(this);
    } else {
      this.treeCreatedCallbacks.push(callback);
    }
  },

  _treeCreated: function () {
    const self = this;
    this.isTreeCreated = true;

    this.treeCreatedCallbacks.forEach((callback) => {
      callback(self);
    });
  },

  restoreState: function () {
    const self = this;
    const state = pageHandler.restoreState(this.config.id);
    let rootNodes;

    // Recreate state for open nodes
    if (state && state.openNodes) {
      rootNodes = self.getTreeData();

      // Check state to see if current Node was open
      const nodeWasOpen = function (node) {
        return filterNodeData(filterNodeData(state.openNodes, "NodeDepth", node.NodeDepth), "Key", node.Key).length > 0;
      };

      const restoreStateForNodes = function (nodes) {
        if (nodes) {
          /* eslint no-use-before-define: "off" */
          $.when(...nodes.map(restoreStateForNode));
        }

        return $.when();
      };

      let restoreStateForNode = function restoreStateForNode(node) {
        if (nodeWasOpen(node)) {
          if (node.children && node.children.length > 0) {
            self.openNodeById(node.id);
            return restoreStateForNodes(node.children);
          } else {
            return self.loadChildren(node).then((loadedNode) => {
              self.openNodeById(node.id);
              return restoreStateForNodes(loadedNode.children);
            });
          }
        }

        return $.when();
      };

      return restoreStateForNodes(rootNodes);
    }

    return $.when();
  },

  saveState: function () {
    const self = this;
    const state = {};
    state.openNodes = [];

    // Get State from jqTree
    const treeData = self.getTreeData();

    const getNodeState = function (nodes) {
      nodes.forEach((node) => {
        if (ko.unwrap(node.isOpen) && node.children && node.children.length > 0) {
          state.openNodes.push({ Key: node.Key, NodeDepth: node.NodeDepth });
          getNodeState(node.children);
        }
      });
    };

    getNodeState(treeData);

    pageHandler.setState(this.config.id, state);
  },

  disposeNode: function (node) {
    let sub;

    while ((sub = node.subscriptions.pop())) {
      sub.dispose();
    }
  },

  dispose: function () {
    const self = this;
    const nodes = flattenTree(this.getTreeData());

    // Dispose Node Subscriptions
    nodes.forEach((node) => {
      self.disposeNode(node);
    });

    self.treeCreatedCallbacks = [];

    pageHandler.removeState(this.config.id);
  },

  toXml: function () {
    const data = this.getTreeData();
    const doc = new DocumentXml("plex-control-tree");

    data.forEach((node) => {
      createXmlFromData(doc, node);
    });

    return doc.serialize();
  }
};

TreeController.create = function (config, model) {
  return new TreeController(config, model);
};

ControllerFactory.register("Elements/_Tree", TreeController);

module.exports = TreeController;
plexExport("TreeController", TreeController);
