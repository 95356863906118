ï»¿const $ = require("jquery");
const columnBuilder = require("./plex-grid-column-builder");
const DefaultColumn = require("./plex-grid-column");
const plexExport = require("../../../global-export");

const TableColumn = DefaultColumn.extend({
  init: function (column) {
    const self = this;
    this._base.apply(this, arguments);
    column.composite = true;

    column.columns.forEach((child) => {
      columnBuilder.build(child, self.parent);
    });

    // intercept action executions and route to appropriate child
    column.executeAction = function (parentRecord, event) {
      if (!event) {
        return false;
      }

      let $el = $(event.target);
      if ($el[0].nodeName !== "a") {
        $el = $el.closest("a");
      }

      const colIndex = $el.data("child-col-index");
      const childColumn = this.columns[colIndex];
      if (!childColumn || !childColumn.executeAction) {
        // let bubble up
        return true;
      }

      const record = this.valueProvider.getRecord(parentRecord);

      const index = $el.closest("tr").attr("data-child-index");
      const tableRecords = this.valueProvider.getValue(record, index);
      return childColumn.executeAction(tableRecords[index], event);
    };
  }
});

module.exports = TableColumn;
plexExport("grid.columns.Table", TableColumn);
