const ko = require("knockout");

ko.semaphore = function (initialState) {
  const counter = ko.observable(0);

  function increment(value) {
    if (typeof value === "boolean") {
      value = value ? 1 : -1;
    }

    value = Math.max(0, counter.peek() + value);
    counter(value);
  }

  const semaphore = ko.pureComputed({
    read: function () {
      return counter() > 0;
    },

    write: increment
  });

  if (initialState) {
    increment(initialState);
  }

  return semaphore;
};
