const $ = require("jquery");

$.fn.scrollOnlyThis = function () {
  // Prevent scrolling of other scrollable containers except this
  // Based on http://stackoverflow.com/questions/7571370

  return this.each(function () {
    // eslint-disable-next-line no-invalid-this
    const $element = $(this);

    $element.on("mousewheel DOMMouseScroll", (e) => {
      const FIREFOX_SCROLL_FACTOR = 40;
      let scrollTo = null;

      if (e.type === "mousewheel") {
        scrollTo = e.deltaY * e.deltaFactor * -1;
      } else if (e.type === "DOMMouseScroll") {
        scrollTo = FIREFOX_SCROLL_FACTOR * e.originalEvent.detail;
      }

      if (scrollTo) {
        e.preventDefault();
        $element.scrollTop(scrollTo + $element.scrollTop());
      }
    });
  });
};
