ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const gridSelectionMode = require("../plex-grid-selectionmode");
const plexExport = require("../../../global-export");

const RowConfig = function (record, index, data, indexInGroup, group, options) {
  this.selectable = gridSelectionMode.isSelectable(options && options.selectionMode);
  this.checkable = gridSelectionMode.isCheckable(options && options.selectionMode);
  this.collapsible = options && options.collapsible;

  this.selected = this.selectable && ko.utils.peekObservable(record.$$selected);
  this.disabled = this.selectable && ko.utils.peekObservable(record.$$disabled);

  if (this.collapsible) {
    this.collapsed = this.collapsed && ko.utils.peekObservable(record.$$collapsed);
  }

  this.groups = options && options.groups;
  this.empty = data.length === 0;
  this.css = ["plex-grid-row"];
  this.features = null;
  this.css.push(index % 2 === 0 ? "plex-grid-row-odd" : "plex-grid-row-even");
};

module.exports = RowConfig;
plexExport("grid.RowConfig", RowConfig);
