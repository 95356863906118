ï»¿const TokenParser = require("../Tokens/plex-tokens-parser");
const valueProviderFactory = require("../Labels/plex-labels-value-providers-factory");
const compareUtils = require("../Utilities/plex-utils-comparer");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();

function ComparisonExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

ComparisonExpression.prototype = {
  constructor: ComparisonExpression,

  init: function (config) {
    if (!(config.operator in compareUtils.operators)) {
      throw new Error("Unable to find a comparison method for: " + config.operation);
    }

    // todo: passing in this object literal is clunky - can we be smarter about this?
    const sourceProvider = valueProviderFactory.create({ valueProvider: config.sourceValueProvider });
    const rightValueFn = tokenParser.compile(config.compareValue);
    const comparer = compareUtils.operators[config.operator].comparer;

    this.evaluator = function (record, index) {
      const left = sourceProvider.getValue(record, index);
      const right = rightValueFn.apply(null, arguments);

      return comparer(left, right);
    };
  },

  getValue: function () {
    return this.evaluator.apply(this, arguments);
  }
};

module.exports = ComparisonExpression;
plexExport("expressions.Comparison", ComparisonExpression);
