ï»¿const DataTransform = require("./plex-data-transform");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const plexExport = require("../../../global-export");

const CollapseData = DataTransform.extend({
  onTransform: function (data) {
    // this default is for backwards compatibility - it can be removed once all apps are updated
    const delimiter = "delimiter" in this.config ? this.config.delimiter : ",";
    const oldData = data;

    if (oldData && oldData.length > 0) {
      const arrayedData = {};
      const newDataObject = {};

      oldData.forEach((dataItem) => {
        for (const name in dataItem) {
          if (Object.prototype.hasOwnProperty.call(dataItem, name)) {
            if (arrayedData[name] && arrayedData[name].length > 0) {
              arrayedData[name].push(dataItem[name]);
            } else {
              arrayedData[name] = [dataItem[name]];
            }
          }
        }
      });

      for (const arrayItemName in arrayedData) {
        if (Object.prototype.hasOwnProperty.call(arrayedData, arrayItemName)) {
          let itemArray = arrayedData[arrayItemName];
          if (!this.config.includeDuplicates) {
            itemArray = arrayUtils.removeDuplicates(itemArray);
          }

          if (delimiter) {
            if (itemArray.length === 1) {
              newDataObject[arrayItemName] = itemArray[0];
            } else {
              newDataObject[arrayItemName] = itemArray.join(delimiter);
            }
          } else {
            // prevent this array from being flattened
            // todo: this is hacky - we only really to flatten picker/dropdown values
            // so it'd be nice if we could isolate those instead of flattening all
            // arrays by default
            itemArray.$$flatten = false;

            newDataObject[arrayItemName] = itemArray;

            // note: i'm not sure why the new object is converted to an array below - this causes
            // issues with the particular use case calling for an array
            return newDataObject;
          }
        }
      }

      data = [newDataObject];
    }

    return data;
  }
});

module.exports = CollapseData;
plexExport("data.transforms.CollapseData", CollapseData);
