const PluginBase = require("./plex-native-base");
const plexExport = require("../../global-export");
const logger = require("../Core/plex-logger");

const signatureCapture = PluginBase.extend({
  getSignature: function () {
    this.call("getSignature").then(
      // rpc success callback
      (_res) => {
        logger.log("GOT SIGNATURE!");
      },

      (_res) => {
        logger.log("GOT FAILURE!");
      }
    );
  }
});

module.exports = signatureCapture;
plexExport("native.plugins.signatureCapture", signatureCapture);
