ï»¿const submenu = require("../Dialogs/plex-submenu");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const SubmenuAction = Action.extend({
  onExecute: function (data, rawData, e) {
    /* eslint new-cap: "off" */
    submenu.Open(e, this, data, rawData);
  }
});

module.exports = SubmenuAction;
plexExport("actions.Submenu", SubmenuAction);
