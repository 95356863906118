ï»¿const $ = require("jquery");
const Cache = require("../../Core/plex-cache");
const ClientNotificationsProvider = require("../../ClientNotifications/plex-client-notifications-provider");
const clientNotificationsTopics = require("../../ClientNotifications/plex-client-notifications-topics");
const dataUtils = require("../../Utilities/plex-utils-data");
const documentProvider = require("./plex-printing-document-providers");
const domUtils = require("../../Utilities/plex-utils-dom");
const expressions = require("../../Expressions/plex-expressions-compiler");
const formatUtils = require("../../Globalization/plex-formatting");
const jsonUtils = require("../../Utilities/plex-utils-json");
const logger = require("../../Core/plex-logger");
const nav = require("../../Core/plex-navigate");
const { generateXmlFromPage } = require("./plex-printing-controller-xml-generator");
const plexExport = require("../../../global-export");
const plexImport = require("../../../global-import");

const ENGLISH_LANGUAGE_KEY = 1;
const DOCUMENT_DEFAULTS = {
  saveAsDefault: false,
  pageWidth: "8.5in",
  pageHeight: "11in",
  pageMargins: { margin: 7, top: 7, bottom: 4, left: 7, right: 7 },
  grayscaleOnly: false,
  reportDateRange: []
};

const GENERATION_STATUS = {
  success: "Succeed",
  error: "Error"
};

function getAppStateLanguage() {
  const appState = plexImport("appState");
  if (appState && appState.language && appState.language.languageKey) {
    return appState.language.languageKey;
  }

  return ENGLISH_LANGUAGE_KEY;
}

const log = (message) => {
  logger.info(`${new Date().toISOString()}: ${message}`);
};

const post = (uri, data) => nav.post(uri, data, { showOverlay: false });

const pollForGeneration = (request, deferred) => {
  const MAX_ATTEMPTS = 60;
  const DELAY_MS = 2000;
  let attempts = 0;

  const check = () => {
    // TODO: we may want to reject if max attempts fails - this would be 2+ minutes...
    if (deferred.state() !== "pending" || attempts++ === MAX_ATTEMPTS) {
      return;
    }

    post("/Platform/PrintManager/GetDocumentCreationStatus", request).then((res) => {
      const status = res.Data && res.Data.Status;
      log(`Document generation status: ${status}`);

      switch (status) {
        case GENERATION_STATUS.success:
          deferred.resolve({ downloadUrl: res.Data.DownloadUrl });
          break;
        case GENERATION_STATUS.error:
          deferred.reject();
          break;
        default:
          setTimeout(check, DELAY_MS);
      }
    });
  };

  check();
  return deferred.promise();
};

const PrintDocumentProvider = documentProvider.extend({
  onInit: function () {
    this.cache = new Cache();
    this._clientNotificationsProvider = new ClientNotificationsProvider({
      topic: clientNotificationsTopics.document.name
    });

    // defaults
    this.document = this.config.document || { printDocumentKey: 1 };
    this.documentProperty = $.extend({}, DOCUMENT_DEFAULTS, this.config.documentProperty);

    this.language = this.config.language || {};
    this.language.languageKey = this.language.languageKey || getAppStateLanguage();
    this.cacheXml = this.config.cacheXml;

    if (this.cacheXml == null) {
      this.cacheXml = true;
    }

    if (typeof this.documentProperty.landscape === "undefined" && this.document.xmlAddress) {
      this.documentProperty.landscape = false;
    }

    if (this.config.startDateFormatter) {
      this.startDateFormatter = formatUtils.getFormatter(this.config.startDateFormatter);
    }

    if (this.config.endDateFormatter) {
      this.endDateFormatter = formatUtils.getFormatter(this.config.endDateFormatter);
    }
  },

  onGetDocument: function (data, tokenizedData) {
    // check cache
    let key = this._getCacheKey();
    const cached = this.cache.get(key);

    if (cached) {
      return $.when(cached);
    }

    const $result = new $.Deferred();

    this._sendDocumentCreationRequest(data, tokenizedData).then((webDocument, creationRequestResponse) => {
      key = this._getCacheKey();

      const result = Object.assign({}, creationRequestResponse);

      if (webDocument) {
        const url = `${webDocument.downloadUrl}/${result.Filename}${result.WebFile.FileExtension}`;
        log(`Document URL: ${url}`);
        result.WebFile = Object.assign({}, result.WebFile, {
          Url: url
        });
      }

      if (result.IsRetainedDocument) {
        log("Starting retained document creation.");

        post(
          nav.buildUrl("/Platform/PrintManager/CreatePrintedRetentionDocument"),
          $.extend({}, jsonUtils.toCamelCaseKeys(result), this.config.createDocumentRequest)
        ).then((retainedDocument) => {
          log("Finished retained document creation.");

          if (retainedDocument.ValidationResult == null || retainedDocument.ValidationResult.Success) {
            if (!this.cache.has(key)) {
              this.cache.set(key, retainedDocument.Data);
            }

            $result.resolve(retainedDocument.Data);
          } else {
            $result.reject();
          }
        }, $result.reject);
      } else {
        if (!this.cache.has(key)) {
          this.cache.set(key, result);
        }

        $result.resolve(result);
      }
    }, $result.reject);

    return $result.promise();
  },

  getXml: function (data) {
    if ((!this.xml || !this.cacheXml) && this.document.xmlAddress) {
      log("Starting xml creation.");

      return $.post(this.document.xmlAddress, nav.serialize(dataUtils.cleanse(data)), null, "xml").then((response) => {
        log("Finished xml creation.");

        const xmls = new XMLSerializer();
        return xmls.serializeToString(response);
      });
    }

    try {
      return $.when(this.xml || this.createControllerXml());
    } catch (ex) {
      const $deferred = new $.Deferred();
      $deferred.reject(ex);
      return $deferred.promise();
    }
  },

  createControllerXml: function () {
    return generateXmlFromPage(this);
  },

  createCustomizationRequest: function () {
    const request = {};
    $.extend(request, this.language);
    $.extend(request, this.documentProperty);
    return request;
  },

  onDispose: function () {
    if (this._clientNotificationsProvider) {
      this._clientNotificationsProvider.dispose();
    }
  },

  _sendDocumentCreationRequest: function (data, tokenizedData) {
    const $notificationResult = new $.Deferred();
    const $creationRequestResult = new $.Deferred();

    this.getXml(tokenizedData || data).then((xml) => {
      this.xml = xml;
      this.config.createDocumentRequest = this._createRequest(data);

      this._clientNotificationsProvider.subscribe(
        clientNotificationsTopics.document.notifications.created,
        (document) => {
          const status = document.status || document.Status;
          const downloadUrl = document.downloadUrl || document.DownloadUrl;

          log(`Resolved document via client notifications (${status})`);
          if (downloadUrl && status === GENERATION_STATUS.success) {
            $notificationResult.resolve({ downloadUrl });
          } else {
            $notificationResult.reject();
          }
        }
      );

      this._clientNotificationsProvider
        .start()
        // even if the broker fails, we can still manually poll
        .always(() => {
          log("Sending document creation request.");

          post("/Platform/PrintManager/SendDocumentCreationRequest", this.config.createDocumentRequest).then(
            (response) => {
              log("Finished sending document creation request.");

              if (response.ValidationResult == null || response.ValidationResult.Success) {
                $creationRequestResult.resolve(response.Data);

                if (response.Data.Filepath) {
                  $notificationResult.resolve(null);
                } else if (response.Data.DocumentGenerationStatusUrl) {
                  pollForGeneration(response.Data, $notificationResult);
                }
              } else {
                $creationRequestResult.reject();
              }
            },
            $creationRequestResult.reject
          );
        });
    }, $creationRequestResult.reject);

    return $.when($notificationResult, $creationRequestResult).always(() => {
      this._stopNotificationsListening();
    });
  },

  _stopNotificationsListening: function () {
    this._clientNotificationsProvider.unsubscribe(clientNotificationsTopics.document.notifications.created);
    return this._clientNotificationsProvider.stop();
  },

  _getCacheKey: function () {
    // need to cache based on language key and document properties.
    // customization request happens to contain all of these.
    const request = this.createCustomizationRequest();
    return JSON.stringify(request);
  },

  _createRequest: function (data) {
    // date range
    const startDate = dataUtils.getValue(data, this.config.startDatePropertyName) || this.documentProperty.startDate;
    if (startDate && this.startDateFormatter) {
      this.documentProperty.reportDateRange.push(
        this.startDateFormatter.format(startDate, this.config.startDateFormatter)
      );
    }

    const endDate = dataUtils.getValue(data, this.config.endDatePropertyName) || this.documentProperty.endDate;
    if (endDate && this.endDateFormatter) {
      this.documentProperty.reportDateRange.push(this.endDateFormatter.format(endDate, this.config.endDateFormatter));
    }

    // bound properties
    this._setBoundProperty(this.config.reportCustomerPropertyName, "reportCustomer", data);
    this._setBoundProperty(this.config.thirdPartyCustomerPropertyName, "thirdPartyCustomer", data);
    this._setBoundProperty(this.config.buildingCodePropertyName, "buildingCode", data);
    this._setBoundProperty(this.config.customerNamePropertyName, "customerName", data);
    this._setBoundProperty(this.config.customerLogoPropertyName, "customerLogo", data);

    const printActionConfig = this.config.parent.config;

    let recordKey = null;
    if (printActionConfig.attachmentGroupKey) {
      if (
        printActionConfig.recordKeyPropertyName &&
        Object.prototype.hasOwnProperty.call(data, printActionConfig.recordKeyPropertyName)
      ) {
        recordKey = data[printActionConfig.recordKeyPropertyName];
      } else if (printActionConfig.recordKeyExpression) {
        recordKey = expressions.compile(printActionConfig.recordKeyExpression)(data);
      }
    }

    const request = {
      printDocumentKey: this.document.printDocumentKey,
      documentProperty: this.documentProperty,
      xml: this.xml,
      documentTitle: this._getDocumentTitle(data),
      languageKey: this.language.languageKey,
      attachmentGroupKey: printActionConfig.attachmentGroupKey,
      recordKey,
      clientId: this._clientNotificationsProvider.getClientId()
    };

    return dataUtils.cleanse(request, { flatten: false });
  },

  _getDocumentTitle: function (data) {
    let title;
    if (this.config.titleExpression) {
      const fn = expressions.compile(this.config.titleExpression);
      title = fn(data);
    }

    title = title || this.documentProperty.documentTitle;
    if (title) {
      return title;
    }

    // fallback to page title
    let $pageTitle = $(".plex-page-title");
    if (this.config.parent.config.parent && domUtils.isInDialog(this.config.parent.config.parent.$element)) {
      const $dialogTitle = this.config.parent.config.parent.$element.closest(".plex-dialog").find(".plex-dialog-title");
      if ($dialogTitle.length) {
        $pageTitle = $dialogTitle;
      }
    }

    return $pageTitle.length ? $pageTitle.text().trim() : "";
  },

  _setBoundProperty: function (srcPropertyName, destPropertyName, data) {
    const boundValue = dataUtils.getValue(data, srcPropertyName);
    if (boundValue) {
      this.documentProperty[destPropertyName] = boundValue;
    }
  }
});

module.exports = PrintDocumentProvider;
plexExport("printing.documentProviders.PrintDocumentProviderAsync", PrintDocumentProvider);
