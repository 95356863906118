ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const plexExport = require("../../global-export");

const DEFAULT_IDLE_TIMEOUT = 2000;
let lastActivity = Date.now();

$(window).on("resize mousemove keyup scroll", () => {
  lastActivity = Date.now();
});

function IdleTaskRunner(idleTimeout) {
  this.idleTimeout = idleTimeout || DEFAULT_IDLE_TIMEOUT;
  this.queue = [];
}

IdleTaskRunner.prototype = {
  constructor: IdleTaskRunner,

  enqueue: function (task) {
    this.queue.push(task);

    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(this.dequeue.bind(this), this.idleTimeout);
  },

  dequeue: function () {
    if (this.queue.length === 0) {
      return;
    }

    const diff = Date.now() - lastActivity;
    let timeout = this.idleTimeout;
    if (diff >= this.idleTimeout) {
      this.queue.shift()();
    } else {
      timeout = Math.max(100, this.idleTimeout - diff);
    }

    this.idleTimer = setTimeout(this.dequeue.bind(this), timeout);
  },

  clear: function () {
    this.queue.length = 0;
  }
};

module.exports = IdleTaskRunner;
plexExport("IdleTaskRunner", IdleTaskRunner);
