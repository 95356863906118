const ko = require("knockout");
const compiler = require("../Expressions/plex-expressions-compiler");
const jsUtils = require("./plex-utils-js");
require("../Polyfills/array.includes"); // eslint-disable-line import/no-unassigned-import
require("../Polyfills/number.isInteger"); // eslint-disable-line import/no-unassigned-import

const PROPERTY_PATH_TEST = /[.[\]]/;
const SQUARE_BRACE_PATTERN = /\[|\]/g;
const ERROR_DEFAULT_VALUE = "#ERROR#";

const symbolOrKey = (key) => {
  if (typeof Symbol === "function") {
    return Symbol(key);
  }

  return "$$" + key;
};

const proxyMarker = symbolOrKey("ko-proxy");
const observableMap = symbolOrKey("ko-map");
const touchedMarker = symbolOrKey("touched");
const modifiedMarker = symbolOrKey("modified");
const refMarker = symbolOrKey("refs");
const observableMarker = symbolOrKey("observable");
const resetSentinel = {};
let forceObservableCreation = false;
let includeMissing = false;

const attachProxy = (obj, proxy) => {
  Object.defineProperty(obj, proxyMarker, {
    value: proxy,
    // prevent symbol from being included when spreading, etc
    enumerable: false,
    configurable: true
  });
};

const isProxied = (obj) => {
  return !!obj && typeof obj === "object" && proxyMarker in obj;
};

const getProxy = (obj) => {
  return obj[proxyMarker];
};

const isInteger = (value) => {
  return Number.isInteger(value) || (typeof value === "string" && /^\d+$/.test(value));
};

const isLength = (value) => {
  return isInteger(value) && value >= 0;
};

const isModified = (obj) => {
  if (!obj || typeof obj !== "object") {
    return false;
  }

  const proxy = getProxy(obj) || obj;
  return proxy[modifiedMarker] || false;
};

const isPlainObject = (value) => {
  // check for plain object - avoid types like Date, RegExp, etc
  return !!value && Object.prototype.toString.call(value) === "[object Object]";
};

const shouldCreateObservable = (value) => {
  if (forceObservableCreation) {
    return true;
  }

  if (Array.isArray(value) || isPlainObject(value)) {
    return true;
  }

  // Checking for an undefined dependency count is a way for us to infer whether
  // the current context is checking for dependencies - it'd be nice if Knockout
  // exposed this explicitly, but have seen nothing in their code which does this.
  return ko.computedContext.getDependenciesCount() != null;
};

const shouldProxy = (obj) => {
  return !isProxied(obj) && (Array.isArray(obj) || isPlainObject(obj));
};

const getProxyForParent = (obj, key) => {
  let proxy = getProxy(obj);
  let propertyName = key;

  // Check for nested property path
  if (proxy && key && !(key in proxy) && PROPERTY_PATH_TEST.test(key)) {
    // Replace square braces with dots - so foo[0] becomes foo.0. - we then
    // split on dots and remove empty segments which can be created for nested
    // array/object paths. There are some edge cases that would not work with
    // this approach, but none that are exposed in current use cases.
    const keys = key.replace(SQUARE_BRACE_PATTERN, ".").split(".").filter(Boolean);
    propertyName = keys.pop();
    proxy = keys.reduce((o, k) => o && o[k] && getProxy(o[k]), proxy);
  }

  return [proxy, propertyName];
};

/**
 * Returns the underlying proxied observable if found, or null. Omit the key
 * to get the underlying observable for an array.
 *
 * @param {Object} obj
 * @param {String} key
 */
function getProxiedObservable(obj, key, createIfMissing) {
  const [proxy, propertyName] = getProxyForParent(obj, key);
  if (!proxy) {
    return null;
  }

  if (!propertyName && Array.isArray(proxy)) {
    return proxy[observableMarker];
  }

  if (propertyName && (propertyName in proxy || createIfMissing)) {
    includeMissing = createIfMissing;
    forceObservableCreation = true;
    // eslint-disable-next-line no-unused-expressions
    proxy[propertyName]; // call getter to force lazy observable creation
    forceObservableCreation = includeMissing = false;

    return proxy[observableMap][propertyName];
  }

  return null;
}

function setProxiedObservable(obj, key, observable) {
  const [proxy, propertyName] = getProxyForParent(obj, key);
  if (!proxy) {
    return null;
  }

  proxy[propertyName] = observable;
  return observable;
}

function returnOrProxyValue(value) {
  if (Array.isArray(value)) {
    return proxyArray(value);
  }

  if (isPlainObject(value)) {
    return proxyObject(value);
  }

  return value;
}

function returnOrProxyArrayValues(arr) {
  let proxied = false;

  const proxiedArray = arr.map((value) => {
    if (!shouldProxy(value)) {
      return value;
    }

    proxied = true;
    return returnOrProxyValue(value);
  });

  // if no values were proxied, no need to replace array
  return proxied ? proxiedArray : arr;
}

function createObservable(value, target) {
  if (compiler.isComputed(value)) {
    let evaluator;
    const valueObservable = ko.observable();

    const computed = ko.pureComputed(() => {
      // lazily compile evaluator
      evaluator = evaluator || compiler.compile(value);

      const computedValue = evaluator(target);
      if (jsUtils.isThenable(computedValue)) {
        computedValue.then(valueObservable);
      } else if (ko.isObservable(computedValue)) {
        valueObservable(computedValue());
      } else {
        valueObservable(computedValue);
      }

      let underlyingValue = valueObservable();
      if (typeof underlyingValue === "number") {
        if (isNaN(underlyingValue) || !isFinite(underlyingValue)) {
          underlyingValue = value.expression.divideByZeroValue || ERROR_DEFAULT_VALUE;
        }
      }

      return underlyingValue;
    });

    return computed;
  }
  return ko.observable(returnOrProxyValue(value));
}

// The ko-projections library replaces map/filter with special observable versions which we don't want to expose.
// We also avoid special functions like constructor - otherwise we want to forward any of the observable functions
const excludedObservableFunctions = ["map", "filter", "then", "constructor", "__ko_proto__"];

// We only care about mutators that can add items to an array - if items are added,
// make sure they are proxied if they should be. (Other mutators that remove or move
// items will already be proxied.)
const mutators = {
  push(obs) {
    return (...args) => obs.push(...args.map(returnOrProxyValue));
  },
  splice(obs) {
    return (...args) => {
      if (args.length <= 2) {
        return obs.splice(...args);
      }

      const [start, deleteCount, ...items] = args;
      return obs.splice(start, deleteCount, ...items.map(returnOrProxyValue));
    };
  },
  unshift(obs) {
    return (...args) => obs.unshift(...args.map(returnOrProxyValue));
  }
};

const mutateObservableLength = (observable, value) => {
  const length = observable.peek().length;
  if (value > length && isLength(value)) {
    // add empty items to end of array
    observable.push(...Array(value - length));
  } else if (value < length && isLength(value)) {
    observable.splice(value, length - value);
    // eslint-disable-next-line eqeqeq
  } else if (value != length) {
    // probably an error...
    observable().length = value;
    observable.notifySubscribers();
  }
};

function proxyArray(arr) {
  let updating = false;
  const underlyingArray = returnOrProxyArrayValues(arr);
  const observable = ko.observableArray(underlyingArray);
  const refs = new Set();

  const proxy = new Proxy(underlyingArray, {
    get(target, key) {
      switch (key) {
        case proxyMarker:
          return proxy;
        case observableMarker:
          return observable;
        case refMarker:
          return refs;
        default:
          break;
      }

      if (refs.has(key)) {
        return target[key];
      }

      if (Object.prototype.hasOwnProperty.call(mutators, key)) {
        return mutators[key](observable);
      }

      // If the array is being mutated, we need to make
      // sure to call through to the observable method
      // so updates are properly triggered.
      if (typeof observable[key] === "function" && !excludedObservableFunctions.includes(key)) {
        return observable[key].bind(observable);
      }

      return observable()[key];
    },

    set(target, key, value) {
      if (refs.has(key)) {
        target[key] = value;
        return true;
      }

      if (key === "length") {
        mutateObservableLength(observable, value);
      } else if (isInteger(key)) {
        let proxyValue;

        if (shouldProxy(value)) {
          proxyValue = returnOrProxyValue(value);
        }

        observable.splice(key, 1, proxyValue || value);

        if (proxyValue) {
          observable.notifySubscribers({ index: key, value: proxyValue, status: "created" }, "proxyUpdate");
        }
      } else {
        // not sure what this case would be, but
        // this will allow consumers to add additional
        // properties to the array
        observable()[key] = value;
      }

      return true;
    }
  });

  observable.subscribe(
    (changes) => {
      if (updating) {
        return;
      }

      updating = true;

      changes.forEach((entry) => {
        if (entry.moved != null) {
          // There will be an entry for deleted and added - we only
          // need to review one of them
          if (entry.status === "added") {
            observable.notifySubscribers(
              { newIndex: entry.moved, oldIndex: entry.index, status: "moved" },
              "proxyUpdate"
            );
          }
          return;
        }

        if (entry.status === "deleted") {
          if (isProxied(entry.value)) {
            observable.notifySubscribers({ index: entry.index, status: "removed" }, "proxyUpdate");
          }
        } else if (entry.status === "added" && isProxied(entry.value)) {
          observable.notifySubscribers({ index: entry.index, value: entry.value, status: "created" }, "proxyUpdate");
        } else if (shouldProxy(entry.value)) {
          // force update to go through proxy
          proxy[entry.index] = entry.value;
        }
      });

      updating = false;
    },
    null,
    "arrayChange"
  );

  attachProxy(arr, proxy);
  return proxy;
}

const deepCompareLeft = (left, right) => {
  if (Array.isArray(left)) {
    if (!Array.isArray(right) || left.length !== right.length) {
      return false;
    }

    if (left.length === 0) {
      return true;
    }

    return left.every((item, index) => deepCompareLeft(item, right[index]));
  }

  if (isPlainObject(left)) {
    if (!isPlainObject(right)) {
      return false;
    }

    const leftTouched = left[touchedMarker] || left;
    return Object.keys(leftTouched).every((key) => deepCompareLeft(left[key], right[key]));
  }

  // eslint-disable-next-line eqeqeq
  return left == right;
};

/**
 * Reset will clear the modified status of an existing proxy.
 *
 * @param {Object} obj - the object or proxy to reset
 * @param {Array<String>} [propertyNames] - the property names to reset. (If null will reset entry object)
 */
function resetProxy(obj, propertyNames) {
  const proxy = obj && getProxy(obj);
  if (!proxy) {
    return;
  }

  if (Array.isArray(propertyNames)) {
    const touched = proxy[touchedMarker];
    if (touched) {
      propertyNames.forEach((key) => {
        delete touched[key];
      });
    }

    // force a reset
    proxy[touchedMarker] = touched;
  } else {
    proxy[touchedMarker] = resetSentinel;
  }
}

/**
 * Wraps the provided object in a Proxy, which lazily wraps properties in
 * Knockout observables.
 * @param {Object} obj
 */
function proxyObject(obj, computedProperties) {
  if (typeof Proxy === "undefined") {
    if (Array.isArray(obj) || isPlainObject(obj)) {
      ko.track(obj);
    }

    return obj;
  }

  if (obj == null) {
    return obj;
  }

  if (isProxied(obj)) {
    return obj[proxyMarker];
  }

  if (Array.isArray(obj)) {
    return proxyArray(obj);
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const observables = Object.create(null);
  const refs = new Set();
  let touched = Object.create(null);

  // This may end up adding overhead that impacts performance
  // of large datasets - if so, we should consider making the
  // ability to track modified status optional.
  const modifiedTrigger = ko.observable();
  const modifiedObservable = ko.pureComputed(() => {
    modifiedTrigger();
    return !deepCompareLeft(touched, obj);
  });

  const proxy = new Proxy(obj, {
    get(target, key) {
      // eslint-disable-next-line default-case
      switch (key) {
        case observableMap:
          return observables;
        case proxyMarker:
          return proxy;
        case touchedMarker:
          return touched;
        case refMarker:
          return refs;
        case modifiedMarker:
          return modifiedObservable();
      }

      if (Array.isArray(computedProperties) && computedProperties.length > 0) {
        computedProperties.forEach((prop) => {
          if (!(prop.name in target)) {
            target[prop.name] = createObservable(prop, returnOrProxyValue(target));
          }
        });
      }

      if (!(key in target) && !includeMissing) {
        return undefined;
      }

      if (refs.has(key)) {
        return target[key];
      }

      if (key in observables) {
        return observables[key]();
      }

      const value = target[key];
      if (!shouldCreateObservable(value)) {
        return value;
      }

      const observable = createObservable(value, returnOrProxyValue(target));
      proxy[key] = observable;
      return observable();
    },

    set(target, key, value) {
      if (key === touchedMarker) {
        if (value === resetSentinel) {
          // Need to set initial values as they are now for the keys
          // that we are already observing.
          touched = Object.fromEntries(Object.entries(target).filter(([k]) => k in observables));
        } else {
          touched = value;
        }

        modifiedTrigger.notifySubscribers();
        return true;
      }

      if (refs.has(key)) {
        Object.defineProperty(target, key, { enumerable: false, configurable: true, writable: true, value });
        return true;
      }

      if (key in target && !(key in touched)) {
        // keep original value
        // TODO: does this handle objects correctly?
        touched[key] = target[key];
      }

      if (ko.isObservable(value)) {
        observables[key] = value;

        // Update target's property to keep them in sync
        Object.defineProperty(target, key, {
          get: value,
          set: ko.isWriteableObservable(value) ? value : undefined,
          enumerable: true,
          configurable: true
        });

        value.subscribe(() => {
          modifiedTrigger.notifySubscribers();
        });

        return true;
      }

      if (key in observables) {
        observables[key](returnOrProxyValue(value));
      }

      const previousValue = target[key];
      target[key] = value;

      if (previousValue !== value) {
        modifiedTrigger.notifySubscribers();
      }

      return true;
    },

    ownKeys(target) {
      return Object.keys(target).filter((key) => !refs.has(key));
    }
  });

  attachProxy(obj, proxy);
  return proxy;
}

/**
 * Will make sure the provided key is tracked
 * and return the associated observable.
 *
 * This method will create the property if it does
 * not already exists.
 *
 * @param {Object} obj
 * @param {String} key
 * @param {Observable} [observable]
 * @returns Observable
 */
const trackProperty = (obj, key, observable) => {
  if (ko.isObservable(observable)) {
    setProxiedObservable(obj, key, observable);
    return observable;
  }

  return getProxiedObservable(obj, key, true);
};

const arrayReaction = (arr, triggerOrKey, func, options) => {
  const disposables = [];

  // need to match disposer to index to support sparse arrays
  arr.forEach((x, i) => {
    disposables[i] = reaction(x, triggerOrKey, func, options);
  });

  const observable = getProxiedObservable(arr);
  const subscription = observable.subscribe(
    (entry) => {
      if (entry.status === "moved") {
        // just need to swap positions
        const old = disposables[entry.oldIndex];
        disposables[entry.oldIndex] = disposables[entry.newIndex];
        disposables[entry.newIndex] = old;
        return;
      }

      // if a disposer already exists, execute before removing
      disposables[entry.index]?.();
      if (entry.status === "created") {
        disposables[entry.index] = reaction(entry.value, triggerOrKey, func, options);
      } else {
        disposables[entry.index] = undefined;
      }
    },
    null,
    "proxyUpdate"
  );

  // eslint-disable-next-line no-use-before-define
  return () => {
    subscription.dispose();
    disposables.forEach((fn) => fn?.());
    disposables.length = 0;
  };
};

const objectReaction = (obj, triggerOrKey, func, options) => {
  let observable;
  if (typeof triggerOrKey === "function") {
    observable = ko.pureComputed(() => triggerOrKey(getProxy(obj)));
  } else {
    observable = getProxiedObservable(obj, triggerOrKey);
  }

  // can be set to null to always notify
  if (options?.equals !== undefined) {
    observable.equalityComparer = options.equals;
  }

  if (options?.fireImmediately) {
    func(observable());
  }

  const subscription = observable.subscribe(func);
  return () => subscription.dispose();
};

/**
 * Reaction options
 *
 * @typedef {Object} ReactionOptions
 * @property {Function} [equals] - comparer function which determines whether trigger should run
 * @property {boolean} [fireImmediately] - indicates that the reaction should run when created
 */

/**
 * Reaction will observe the provided object or array and based on the trigger
 * function or property value, will execute a provided function.
 *
 * @param {Object|Array} obj - The object or array to observe
 * @param {Function|String} triggerOrKey - A trigger value or property name to watch
 * @param {Function} func - Function to run when trigger changes
 * @param {ReactionOptions} [options]
 * @returns {Function} A function which stops the observation
 */
function reaction(obj, triggerOrKey, func, options) {
  if (Array.isArray(obj)) {
    return arrayReaction(obj, triggerOrKey, func, options);
  }

  return objectReaction(obj, triggerOrKey, func, options);
}

/**
 * Adds the value as a reference on the proxy object. A reference
 * will not have values tracked.
 *
 * @param {Object} obj - The proxy or target object
 * @param {String} key - The property name
 * @param {any} ref - The reference value
 */
const addRef = (obj, key, ref) => {
  const proxy = getProxy(obj);
  if (proxy == null) {
    obj[key] = ref;
    return;
  }

  const refs = proxy[refMarker];
  refs.add(key);
  proxy[key] = ref;
};

module.exports = {
  addRef,
  isModified,
  isProxied,
  proxyObject,
  resetProxy,
  getProxiedObservable: (obj, key) => getProxiedObservable(obj, key),
  getProxyOrNull(obj) {
    return obj?.[proxyMarker] || null;
  },
  reaction,
  trackProperty
};
