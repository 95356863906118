ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const DefaultValueProvider = require("./plex-grid-value-providers");
const dataUtils = require("../../Utilities/plex-utils-data");
const HtmlWriter = require("../../Utilities/plex-utils-html-writer");
const RowConfig = require("../Writers/plex-grid-row-config");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const plexExport = require("../../../global-export");
const actionHandler = require("../../Controls/plex-handler-action");

const DEFAULT_CSS_MAP = {
  "plex-int-text": {
    "text-align": "right"
  },
  "plex-numeric-text": {
    "text-align": "right"
  },
  "plex-date-text": {
    "text-align": "right"
  },
  "plex-boolean": {
    "text-align": "center",
    "padding-top": "3pt"
  }
};

function mapCss(node, css) {
  if (node && css && css.length > 0) {
    css.forEach((entry) => {
      if (entry in DEFAULT_CSS_MAP) {
        $.each(DEFAULT_CSS_MAP[entry], (key, value) => {
          node.addAttribute(key, value);
        });
      }
    });
  }
}

function MiniTableWriter(columns, options) {
  this.columns = columns;
  this.options = options;
}

MiniTableWriter.prototype = {
  constructor: MiniTableWriter,

  writeHeader: function (table) {
    if (!this.options.displayHeader) {
      return;
    }

    const thead = table.appendChild("thead");
    const tr = thead.appendChild("tr").addClass("plex-grid-header-row");
    let th, abbr, column;

    for (let colIndex = 0, ln = this.columns.length; colIndex < ln; colIndex++) {
      column = this.columns[colIndex];
      if (column.visible()) {
        th = tr.appendChild("th").addClass(ko.unwrap(column.headerCss));

        abbr = th.appendChild("abbr").addAttr("title", ko.unwrap(column.headerName));
        abbr.appendHtml(ko.unwrap(column.shortHeaderName));
        abbr.close();

        th.close();
      }
    }

    tr.close();
    thead.close();
  },

  writeRow: function (tbody, record, index, records, parentRowIndex, parentColumnIndex) {
    const tr = tbody.appendChild("tr").addAttr("data-index", parentRowIndex).addAttr("data-child-index", index);

    const rowConfig = new RowConfig(record, index, records);
    let column;

    if (!this.options.displayHeader && index === 0) {
      tr.addClass("plex-grid-first-row-no-header");
    }

    for (let colIndex = 0, ln = this.columns.length; colIndex < ln; colIndex++) {
      column = this.columns[colIndex];
      if (column.visible()) {
        this.writeCell(tr, column, record, index, records, rowConfig, colIndex, parentColumnIndex);
      }
    }

    tr.addClass(rowConfig.css);
    tr.close();
  },

  writeCell: function (tr, column, record, index, records, rowConfig, colIndex, parentColumnIndex) {
    const featureResult = column.featureProcessor.process(record, index, records, rowConfig);

    const td = tr.appendChild("td");
    td.addStyle(featureResult.style);
    td.addClass(featureResult.css);
    td.addAttr(featureResult.attr);

    if (column.css) {
      td.addClass(column.css);
    }

    if (featureResult.content) {
      td.appendHtml(featureResult.content);
    } else {
      const html = column.valueProvider.getHtml(record, index) || column.valueProvider.getEmptyHtml();
      if (column.action) {
        const linkUrl = actionHandler.buildLinkUrl(column.action, record, index);
        const a = td.appendChild("a").addClass("plex-grid-link");
        a.addAttr("data-col-index", parentColumnIndex);
        a.addAttr("data-child-col-index", colIndex);
        a.addAttr("href", linkUrl);

        a.appendHtml(html);

        a.close();
      } else {
        td.appendHtml(html);
      }
    }

    td.close();
  },

  write: function (records, parentRowIndex, parentColumnIndex) {
    const writer = new HtmlWriter();
    const container = writer.appendChild("div").addClass("plex-grid-wrapper");
    const table = container.appendChild("table").addClass("plex-grid-simple");

    this.writeHeader(table);

    const tbody = table.appendChild("tbody");
    if (!this.options.displayHeader && !this.options.hideBorder) {
      table.addClass("plex-grid-body-no-header");
    }

    if (!this.options.displayHeader && this.options.hideBorder) {
      table.addClass("plex-grid-body-no-border");
    }

    for (let i = 0, ln = records.length; i < ln; i++) {
      this.writeRow(tbody, records[i], i, records, parentRowIndex, parentColumnIndex);
    }

    tbody.close();
    table.close();
    container.close();
    return writer.toString();
  }
};

const TableValueProvider = DefaultValueProvider.extend({
  onInit: function () {
    const self = this;
    self.column.columns.forEach((childColumn) => {
      childColumn.visible = ko.isObservable(childColumn.visible)
        ? childColumn.visible
        : ko.observable(childColumn.clientVisible !== false);
      childColumn.printVisible = ko.isObservable(childColumn.printVisible)
        ? childColumn.printVisible
        : ko.observable(childColumn.printVisible !== false);
      childColumn.featureProcessor = new FeatureProcessor(childColumn.features, childColumn, self.parent);
    });

    this.writer = new MiniTableWriter(this.column.columns, this.column);
  },

  getFormattedValue: function () {
    const self = this;
    const records = this.getValue.apply(this, arguments);
    const value = [];

    if (records && records.length > 0) {
      records.forEach((row, recordIndex) => {
        const rowValues = [];
        self.column.columns.forEach((col) => {
          if (col.visible && col.visible()) {
            const cellValue = col.valueProvider.getFormattedValue(row, recordIndex);
            rowValues.push(cellValue);
          }
        });
        value.push(rowValues.join(" "));
      });
    }

    return value.join(",");
  },

  getHtml: function (record, index, colIndex) {
    const records = this.getValue.apply(this, arguments);
    if (records && records.length > 0) {
      // todo: we can probably unify these pathes by letting the framework wrap this in an observable but for now continue to use
      if (this.config.trackClientUpdates) {
        return this.renderTemplate("grid-simple", {
          data: records,
          columns: this.column.columns,
          options: this.column
        });
      } else {
        return this.writer.write(records, index, colIndex);
      }
    }

    return null;
  },

  getPrintValue: function (record) {
    const self = this;
    const node = new DocumentXml("grid-simple");

    const records = dataUtils.getValue(record, this.column.propertyName, this.config.trackClientUpdates);
    if (records && records.length > 0) {
      // header
      if (this.column.displayHeader) {
        const nodeHeader = node.createNode("grid-simple-header").createNode("grid-simple-row");

        this.column.columns.forEach((col) => {
          if (col.visible && col.visible()) {
            const elementHeader = nodeHeader.createNode("grid-simple-header-cell");

            if (col.headerCss) {
              const headerCss = ko.unwrap(col.headerCss);
              mapCss(elementHeader, headerCss.split(" "));
            }

            elementHeader.setValue(col.shortHeaderName());
          }
        });
      }

      // body
      const nodeBody = node.createNode("grid-simple-body");
      if (!self.column.displayHeader) {
        nodeBody.addAttribute(
          self.column.hideBorder ? "plex-grid-body-no-header-no-border" : "plex-grid-body-no-header",
          true
        );
      }

      records.forEach((row, recordIndex) => {
        const nodeRow = nodeBody.createNode("grid-simple-row");

        if (recordIndex === 0 && !self.column.displayHeader) {
          nodeRow.addAttribute("plex-grid-first-row-no-header", true);
        }

        self.column.columns.forEach((col) => {
          if (col.visible && col.visible()) {
            const featureResult = col.featureProcessor.process(row, recordIndex, records, { css: [] });
            if (featureResult) {
              const element = nodeRow.createNode("grid-simple-cell");

              // styles and attributes
              element.addAttribute(featureResult.style);
              element.addAttribute(featureResult.attr);

              // css
              mapCss(element, featureResult.css);

              if (!featureResult.getContent()) {
                element.appendXml(col.valueProvider.getPrintValue(row, recordIndex));
              }
            }
          }
        });
      });
    }

    return node.serialize();
  }
});

module.exports = TableValueProvider;
plexExport("grid.valueProviders.TableValueProvider", TableValueProvider);
