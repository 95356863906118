const plexExport = require("../../global-export");

const userAgent = window.navigator.userAgent;
const userAgentData = window.navigator.userAgentData;
const isIE = userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1;
const isEdge = userAgent.indexOf("Edge/") > -1;
const isFirefox = userAgent.indexOf("Firefox") > -1;

// check using userAgentData (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData) else fallback to userAgent strings
const isChrome =
  userAgentData?.brands?.some(({ brand }) => brand === "Google Chrome" && userAgent.indexOf("Edg") === -1) ||
  (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1);

const isChromiumEdge =
  userAgentData?.brands?.some(({ brand }) => brand === "Microsoft Edge") || userAgent.indexOf("Edg") > -1;

// applied for tablet and mobile devices but do not cover some types of other devices (touch-enabled laptops)
const isTouchscreen = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
// applied for cases when device has touchscreen but it also can use some other inputs (mouse, keyboard)
const hasTouchscreenCapability = detectTouchscreen();

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, "passive", {
    // eslint-disable-next-line getter-return
    get: function () {
      supportsPassive = true;
    }
  });

  window.addEventListener("test", null, opts);
} catch (e) {
  // ignore
}

// Track if `pageshow` event has fired for
// TRI-3822 fix related to autocomplete issue on chrome.
let _hasPageShowEventFired = false;
function hasPageShowEventFired() {
  return _hasPageShowEventFired;
}
window.addEventListener(
  "pageshow",
  () => {
    _hasPageShowEventFired = true;
  },
  { once: true }
);

function getExpectedDevicePixelRatio() {
  if (window.matchMedia) {
    const dpi = 96;
    let ratio = 2;
    while (ratio > 1) {
      ratio = Number(ratio.toFixed(1));
      // media query from https://css-tricks.com/snippets/css/retina-display-media-query/
      if (
        window.matchMedia(
          `only screen and (-webkit-min-device-pixel-ratio: ${ratio}), only screen and (min-resolution: ${Math.floor(
            ratio * dpi
          )}dpi`
        ).matches
      ) {
        return ratio;
      }

      ratio -= 0.1;
    }
  }

  return 1;
}

// extended implementation of detect touchscreen applied for more range of devices (touch-enabled laptops)
// more details on https://patrickhlauke.github.io/touch/touchscreen-detection/
function detectTouchscreen() {
  if (window.PointerEvent && "maxTouchPoints" in navigator) {
    return navigator.maxTouchPoints > 0;
  }

  if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
    return true;
  }

  if (window.TouchEvent || "ontouchstart" in window) {
    return true;
  }

  return false;
}

const browser = {
  // IE & Edge do not allow ctrl+p to be overridden, unless a native dialog is fired.
  canInterceptPrintHotkey: !isIE && !isEdge,

  // due to FF security controls, we don't have a way to print from within the browser.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=911444
  canPrint: !isFirefox,

  // due to FF worker API features, we can not use it for Expression writer because of cross-domain issue, that occurs in ace.js (external library).
  // it seems not all browsers can load workers with direct URL and should do it with blob URLs:
  // https://github.com/devbridge/BetterCMS/issues/1047
  canUseWorkerApi: !isFirefox,

  // mobile browsers, including Safari and IE on Surface, do not handle fixed elements
  // well when the browser is zoomed in
  hasFixedElementZoomBugs: isTouchscreen,

  // Firefox handles 'onClick' event for dropdown (it is included into 'details') and collapse 'details' section simultaneously.
  // It causes issue, when the user can not select option in dropdown list
  canSelectDropdownOption: !isFirefox,

  // http://www.javascripter.net/faq/keycodes.htm
  keyCodes: {
    arrowUp: 38,
    arrowDown: 40,
    escape: 27,
    plus: isFirefox ? 61 : 187,
    minus: isFirefox ? 173 : 189,
    numpad: {
      plus: 107,
      minus: 109
    }
  },

  // use in implementation of workaround for https://github.com/c3js/c3/issues/687
  isTouchscreen,

  hasTouchscreenCapability,

  supportsPassiveEvents: supportsPassive,

  defaultDevicePixelRatio: getExpectedDevicePixelRatio(),

  // Chrome incorrectly fills up input controls via autocomplete.
  // This results in data on the screen being incorrect and out of sync with internal data on grid (TRI-3822)
  // https://bugs.chromium.org/p/chromium/issues/detail?id=177694
  // Similar issues: 1057463, 1258293
  needsAutocompleteFix: isChrome || isChromiumEdge,
  hasPageShowEventFired
};

module.exports = browser;
plexExport("browser", browser);
