ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const dialogController = require("../Dialogs/plex-dialog-controller");
const jsUtils = require("../Utilities/plex-utils-js");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

const EmailAction = Action.extend({
  onExecute: function (data) {
    jsUtils.mixin(data, this.requestData || {});

    // IEmailSendActionData with support for EmailPropertyModel
    data.to = data.to || this.recipient;
    data.subject = data.subject || this.subject;
    data.composeEmailBodyText = data.composeEmailBodyText || this.message;
    data.cc = data.cc || this.cc;
    data.bcc = data.bcc || this.bcc;
    data.attachments = data.attachments || this.attachments;
    data.enforceEmailAllow = data.enforceEmailAllow || this.enforceEmailAllow;

    const deferred = new $.Deferred();
    dialogController.create({
      route: "/Platform/Send",
      httpMethod: "POST",
      routeData: nav.serialize(data),
      returnAction: function (dialogData) {
        self.dialogData = dialogData;
        deferred.resolve(dialogData);
      },
      closeAction: function () {
        deferred.reject();
      }
    });

    return deferred.promise();
  }
});

module.exports = EmailAction;
plexExport("actions.Email", EmailAction);
