ï»¿const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const ColumnPrintVisibleFeature = Feature.extend({
  onInit: function () {
    // conditionally set the print visibility

    const self = this;

    // we want to delay evaluation until the grid is rendering
    if (this.parent.isRendering) {
      this.parent.isRendering.subscribe((rendering) => {
        if (rendering) {
          // this feature should always be bound to a different view model so we do not need
          // to pass any record data to it.
          self.column.printVisible(self.conditionEvaluator());
        }
      });
    }

    this.column.printVisible(this.conditionEvaluator());
  }
});

module.exports = ColumnPrintVisibleFeature;
plexExport("grid.features.ColumnPrintVisible", ColumnPrintVisibleFeature);
