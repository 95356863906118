ï»¿const SumOperation = require("./plex-grid-operation-sum");
const plexExport = require("../../../global-export");

const AvgOperation = SumOperation.extend({
  getValue: function (records) {
    /// <summary>Gets the average for the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Number">The average.</returns>

    // use the base sum operation to get the total
    if (records.length > 0) {
      const total = this._base.apply(this, arguments);
      return total / records.length;
    } else {
      // just return blank when empty
      return "";
    }
  }
});

module.exports = AvgOperation;
plexExport("grid.aggregates.AvgOperation", AvgOperation);
