ï»¿const ko = require("knockout");
const $ = require("jquery");

const COLOR_CLASSES = {
  none: "",
  info: "progress-bar-info",
  success: "progress-bar-success",
  warning: "progress-bar-warning",
  danger: "progress-bar-danger"
};

const DEFAULT_OPTIONS = {
  colorClass: "none",
  indeterminateValue: "indeterminate",
  indeterminateText: "Loading...",
  completeText: "Complete!",
  hideValue: "hide",
  displayText: true
};

ko.bindingHandlers.progressBar = ko.bindingHandlers.progressbar = {
  init: function (element, valueAccessor, _allBindings, _viewModel, _bindingContext) {
    const $element = $(element);
    const data = valueAccessor();
    const options = $.extend({}, DEFAULT_OPTIONS, ko.unwrap(data.options));

    $element
      .addClass("progress")
      .append("<div class='progress-bar " + COLOR_CLASSES[options.colorClass || "none"] + "' style='width: 0%'></div>");

    if (options.displayText !== false) {
      $("<span></span>")
        .css({
          position: "absolute",
          left: "49%",
          "font-weight": "bold"
        })
        .appendTo($element);
    }
  },

  update: function (element, valueAccessor, _allBindings, _viewModel, _bindingContext) {
    const $element = $(element);
    const data = valueAccessor();
    const progress = ko.unwrap(data.value);
    const options = $.extend({}, DEFAULT_OPTIONS, ko.unwrap(data.options));

    let progressText;
    if (progress === undefined || progress === options.indeterminateValue) {
      progressText = options.indeterminateText;
    } else if (progress === 100) {
      progressText = options.completeText;
    } else {
      progressText = progress + "%";
    }

    $element.find("span").css({ top: $element.position().top }).text(progressText);

    if (progress === undefined || progress === options.indeterminateValue) {
      $element.addClass("progress-striped").find(".progress-bar").css("width", "100%");

      $element.find("span").css("color", "#fff");
    } else {
      $element
        .removeClass("progress-striped")
        .find(".progress-bar")
        .css("width", progress + "%");

      $element.find("span").css("color", progress > 55 ? "#fff" : "#000");
    }

    if (progress === undefined || progress === options.hideValue || progress < 0 || progress > 100) {
      $element.hide();
    } else {
      $element.show();
    }
  }
};
