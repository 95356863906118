const Action = require("./plex-actions");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const LoadAction = Action.extend({
  onExecute: function (data) {
    const target = plexImport("currentPage")[this.targetId];
    if (!target || typeof target.load !== "function") {
      return false;
    }

    target.load(data);
    return true;
  }
});

plexExport("actions.Load", LoadAction);
