ï»¿const ko = require("knockout");

// TODO: Rename this as unmemoization is now unnecessary.
// Similar implementation to knockout's default "html" binding, but it will unmemoize the resulting HTML.
ko.bindingHandlers.unmemoizedHtml = {
  init: function () {
    return { controlsDescendantBindings: true };
  },
  update: function (element, valueAccessor, _allBindingsAccessor, viewModel, _bindingContext) {
    let value;
    ko.ignoreDependencies(
      () => {
        value = valueAccessor();
      },
      viewModel,
      []
    );

    ko.utils.setHtml(element, value);
  }
};
