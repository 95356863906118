ï»¿const expressions = require("../Expressions/plex-expressions-compiler");
const FeatureProcessor = require("../Features/plex-feature-processor");
const FeatureResult = require("../Features/plex-feature-result");
const Graphic = require("./plex-graphics");
const plexImport = require("../../global-import");

const graphics = plexImport("graphics");

graphics.line = Graphic.extend({
  create: function (_config, paper, _data) {
    return paper.path();
  }
});

graphics.rect = Graphic.extend({
  create: function (config, paper, _data) {
    return paper
      .rect(config.x || 20, config.y || 20, config.width || 100, config.height || 50, config.radius || 10)
      .attr({
        fill: this.evaluateColor(config) || "#FFFFFF",
        stroke: config.borderColor || "#000",
        "stroke-width": config.borderWidth
      });
  }
});

graphics.circle = Graphic.extend({
  create: function (config, paper, _data) {
    return paper.circle(config.x || 20, config.y || 20, config.radius || 100).attr({
      fill: this.evaluateColor(config) || "#FFFFFF",
      stroke: config.borderColor || "#000",
      "stroke-width": config.borderWidth || 1
    });
  }
});

graphics.ellipse = Graphic.extend({
  create: function (config, paper, _data) {
    return paper.ellipse(config.x || 20, config.y || 20, config.radius || 50, config.radiusY || 30).attr({
      fill: this.evaluateColor(config) || "#FFFFFF",
      stroke: config.borderColor || "#000",
      "stroke-width": config.borderWidth || 1
    });
  }
});

graphics.text = Graphic.extend({
  create: function (config, paper, data) {
    let text = config.staticText || "text";

    if (config.textExpression) {
      text = expressions.compile(config.textExpression)(data);
    }

    return paper.text(config.x, config.y, text).attr({
      fill: this.evaluateColor(config) || "#000",
      "font-family": config.font.family || "Arial",
      "font-size": config.font.size || 12,
      "font-style": config.font.style || "normal",
      "font-weight": config.font.bold ? "bold" : 400
    });
  }
});

graphics.image = Graphic.extend({
  create: function (config, paper, data) {
    let address = config.address;

    if (config.addressPropertyName in data) {
      address = data[config.addressPropertyName];
    }

    return paper.image(address, config.x || 10, config.y || 10, config.width || 20, config.height || 20);
  }
});

graphics.textGroup = Graphic.extend({
  create: function (config, paper, data) {
    const self = this;

    let y = config.y;
    const set = paper.set();
    config.textCollection.forEach((textConfig) => {
      if (textConfig.features && textConfig.features.length > 0) {
        const featureProcessor = new FeatureProcessor(textConfig.features);
        textConfig.featuresResult = featureProcessor.process(data);
      } else {
        textConfig.featuresResult = new FeatureResult();
      }

      if (textConfig.featuresResult.render) {
        let text = textConfig.staticText || "text";
        if (textConfig.textExpression) {
          text = expressions.compile(textConfig.textExpression)(data);
        }

        const textObj = paper.text(config.x, y, text).attr({
          fill: self.evaluateColor(textConfig) || "#000",
          "text-anchor": config.alignment || "middle",
          "font-family": textConfig.font.family || "Arial",
          "font-size": textConfig.font.size || 12,
          "font-style": textConfig.font.style || "normal",
          "font-weight": textConfig.font.bold ? "bold" : 400
        });

        self.initAction(textConfig, textObj);
        y += textObj.getBBox().height + config.spacing || 0;
        set.push(textObj);
      }
    });

    return set;
  }
});

graphics.set = Graphic.extend({
  create: function (_config, paper, _data) {
    const self = this;
    self.graphics = [];
    return paper.set();
  },

  addGraphic: function (graphic) {
    const self = this;
    self.raphaelObject.push(graphic.raphaelObject);
    self.graphics.push(graphic);
  }
});
