ï»¿const plexExport = require("../../global-export");

const api = {
  create: function () {
    /// <summary>Creates a new GUID string.</summary>
    /// <returns type="String">The GUID.</returns>

    // source: http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  compare: function (guid1, guid2) {
    /// <summary>Compares two GUIDs and determines if they are equivalent.</summary>
    /// <param name="guid1">The first GUID.</param>
    /// <param name="guid2">The second GUID.</param>
    /// <returns type="Boolean">true if the GUIDs are equivalent; false otherwise.</returns>

    return String(guid1).toLowerCase() === String(guid2).toLowerCase();
  },

  isValid: function (guid) {
    /// <summary>Checks whether GUID is valid.</summary>
    /// <param name="guid">The GUID.</param>
    /// <returns type="Boolean">true if the GUID is valid; false otherwise.</returns>

    // Accepts any GUID that the .NET Guid.Parse would accept, not just rfc4122 version 4 compliant GUIDs
    // https://msdn.microsoft.com/en-us/library/system.guid.parse.aspx
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(String(guid));
  },

  isEmpty: function (guid) {
    /// <summary>Checks whether GUID is empty</summary>
    /// <param name="guid">The GUID.</param>
    /// <returns type="Boolean">true if the GUID is empty; false otherwise.</returns>

    return String(guid) === "00000000-0000-0000-0000-000000000000";
  }
};

module.exports = api;
plexExport("guid", api);
