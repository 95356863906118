ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const StyleFeature = Feature.extend({
  onExecute: function (record, _index, _records) {
    /// <summary>Executes the style feature on the given record.</summary>
    /// <param name="record">The record to evaluate.</param>
    /// <param name="index">The record index.</param>
    /// <param name="records">The array of records.</param>
    /// <returns type="PlainObject">The attributes to apply.</returns>

    const setup = this.config.styles;
    const style = {};

    $.each(setup, (css) => {
      const config = setup[css];
      const value = config && config.propertyName ? dataUtils.getValue(record, config.propertyName, true) : config;

      if (value) {
        style[css] = String(value);
      }
    });

    return {
      style
    };
  }
});

module.exports = StyleFeature;
plexExport("features.Style", StyleFeature);
