ï»¿const ko = require("knockout");

const templateBinding = ko.bindingHandlers.template;

function shouldDisplay(element, options) {
  let display = true;

  if (typeof options !== "string") {
    // Support "if"/"ifnot" conditions
    if ("if" in options) {
      display = ko.utils.unwrapObservable(options.if);
    }

    if (display && "ifnot" in options) {
      display = !ko.utils.unwrapObservable(options.ifnot);
    }

    // Don't show anything if an empty name is given
    const template = "name" in options ? options.name : element;

    if (display && !template) {
      display = false;
    }
  }

  return display;
}

ko.bindingHandlers.animatedTemplate = {
  init: function (element, valueAccessor) {
    return templateBinding.init(element, valueAccessor);
  },
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const options = ko.utils.unwrapObservable(valueAccessor());
    const afterAdd = options.afterAdd;
    const beforeRemove = options.beforeRemove;

    if (shouldDisplay(element, options)) {
      templateBinding.update(element, valueAccessor, allBindings, viewModel, bindingContext);

      if (afterAdd) {
        ko.utils.arrayForEach(ko.virtualElements.childNodes(element), (node) => afterAdd(node));
      }

      return;
    }

    if (beforeRemove) {
      ko.utils.arrayForEach(ko.virtualElements.childNodes(element), (node) =>
        beforeRemove(node, () => {
          templateBinding.update(element, valueAccessor, allBindings, viewModel, bindingContext);
        })
      );
    } else {
      templateBinding.update(element, valueAccessor, allBindings, viewModel, bindingContext);
    }
  }
};

ko.virtualElements.allowedBindings.animatedTemplate = true;
