ï»¿const plexExport = require("../../global-export");

const api = {
  escapeText: function (text) {
    // http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
    // eslint-disable-next-line no-useless-escape
    return text && text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }
};

module.exports = api;
plexExport("regex", api);
