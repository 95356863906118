ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const elementHandler = require("./plex-handler-element");
const controllerFactory = require("./plex-controller-factory");
const utils = require("../Utilities/plex-utils-data");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");

function prepareElement(el, index, rowIndex) {
  rowIndex = rowIndex || 0;
  el.id += "-repeatedelement-" + rowIndex + "-" + index;

  if (el.elements && el.elements.length > 0) {
    el.elements.forEach((child) => {
      prepareElement(child, index, rowIndex);
    });
  }
}

const ElementRepeaterController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

ElementRepeaterController.prototype = {
  constructor: ElementRepeaterController,

  init: function () {
    const self = this;

    self.$element = $("#" + this.config.id);
    self.elements = {};
    self.elementRows = self.config.elementRows = ko.observableArray();
    self.data = utils.trackProperty(this.model, this.config.parentCollection);
    self.resetValidationSetup = ko.observable();

    this.data.subscribe(this.reset, this);
    this.reset();
    self.initialized = true;
  },

  reset: function () {
    this.disposeElements();
    this.createElements(this.data());
    pubsub.publish("init." + this.config.id, this);
  },

  createElements: function (items) {
    if (!items || items.length === 0) {
      return;
    }

    const self = this;
    const rowIndex = self.config.parent.index;
    items.forEach((item, index) => {
      const clone = ko.toJS(self.config.element);
      prepareElement(clone, index, rowIndex);

      elementHandler.initElement(clone, item, null, self);
      self.elementRows.push({ data: item, options: clone });

      pubsub.publish("elementInit." + self.config.id, clone);
    });

    if (self.initialized) {
      self.resetValidationSetup(items.length);
    }

    pubsub.publish("elementsInit." + self.config.id, self);
  },

  disposeElements: function () {
    this.elementRows.removeAll();
    for (const el in this.elements) {
      if (Object.prototype.hasOwnProperty.call(this.elements[el], "controller")) {
        this.elements[el].controller.dispose?.();
      }
    }

    Object.keys(this.elements).forEach((el) => {
      // note: plex-validation.js -> unhighlight function doesn't know about labels, which
      // related with removed repeater rows. We should remove this labels
      this.removeErrorLabels(this.elements[el].id);
      delete this.elements[el];
    });
  },

  removeErrorLabels: function (id) {
    if (!id) {
      return;
    }

    const errorLabel = $("label[for='" + id + "']");

    if (errorLabel) {
      errorLabel.remove();
    }
  }
};

ElementRepeaterController.create = function (config, model) {
  return new ElementRepeaterController(config, model);
};

controllerFactory.register("Elements/_ElementRepeater", ElementRepeaterController);

module.exports = ElementRepeaterController;
plexExport("ElementRepeaterController", ElementRepeaterController);
