ï»¿const ko = require("knockout");
const $ = require("jquery");
const logger = require("../Core/plex-logger");
const valueProviders = require("../Labels/plex-labels-value-providers");

function getOrCreateValueProvider($element, options) {
  let provider = $element.data("valueProvider");
  if (provider) {
    return provider;
  }

  const address = options.valueProvider;
  if (!address || !(address in valueProviders)) {
    logger.error("ValueProvider not found for data label: '" + address + "'");
    provider = new valueProviders.DefaultDataLabelValueProvider($element[0]);
  } else {
    provider = new valueProviders[address]($element[0]);
  }

  provider.element = $element[0];
  provider.options = options || {};

  if (provider.init) {
    provider.init();
  }

  $element.data("valueProvider", provider);
  return provider;
}

ko.bindingHandlers.dataLabelValueProviderBinding = {
  update: function (element, valueAccessor) {
    const options = valueAccessor();
    const $element = $(element);
    const provider = getOrCreateValueProvider($element, options);
    const data = ko.unwrap(options.data);
    const index = ko.unwrap(options.index);
    const value = provider.getFormattedValue(data, index);

    $element.html(value == null ? "" : value);
  }
};
