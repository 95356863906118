ï»¿const api = (module.exports = {});

const $ = require("jquery");
const plexExport = require("../../global-export");

const kioskSpecificClass = ".plex-kiosk-window";

function checkIfKioskMode() {
  const isKioskPage = $(kioskSpecificClass).length > 0;
  return isKioskPage;
}

api.checkIfKioskMode = checkIfKioskMode;

plexExport("kiosk", api);
