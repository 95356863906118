ï»¿const ko = require("knockout");
const dataUtils = require("./plex-utils-data");
const plexExport = require("../../global-export");

function compareGroupValue(left, right, group, sortPropertyName, propertyName, ascending) {
  const a = ko.utils.peekObservable(left[sortPropertyName]);
  const b = ko.utils.peekObservable(right[sortPropertyName]);

  if (group.ascending === false || ((group.displayPropertyName || group.propertyName) === propertyName && !ascending)) {
    return dataUtils.compareValues(b, a);
  }

  return dataUtils.compareValues(a, b);
}

const api = {
  createComparer: function (propertyName, ascending) {
    /// <summary>Creates a comparer function which can be used with sorting, etc. based on the property passed in.</summary>
    /// <param name="propertyName">The property name of the object to comparer.</param>
    /// <param name="ascending">True if the comparer should reflect an ascending comparison; False otherwise (default true)</param>
    /// <returns type="Function">A comparer function.</returns>

    // default true
    if (ascending !== false) {
      return function (a, b) {
        return dataUtils.compareValues(a[propertyName], b[propertyName]);
      };
    }

    return function (a, b) {
      return dataUtils.compareValues(b[propertyName], a[propertyName]);
    };
  },

  createGroupComparer: function (groups, propertyName, ascending) {
    /// <summary>Creates a comparer function which can be used with sorting, etc. based on the groups and property passed in.</summary>
    /// <param name="groups">The groups to compare.</param>
    /// <param name="propertyName">The property name of the object to comparer. (If not assigned the comparer will only reflect the groups.)</param>
    /// <param name="ascending">True if the comparer should reflect an ascending comparison; False otherwise (default true)</param>
    /// <returns type="Function">A comparer function.</returns>

    const ln = groups.length;
    ascending = ascending !== false;

    return function (left, right) {
      let i = 0;
      let compare, sortPropertyName, group;

      for (; i < ln; i++) {
        group = groups[i];
        sortPropertyName = group.sortPropertyName || group.displayPropertyName || group.propertyName;
        compare = compareGroupValue(left, right, group, sortPropertyName, propertyName, ascending);

        if (compare === 0) {
          // if the value is equal do a subsort on display/sort property
          if (sortPropertyName !== (group.displayPropertyName || group.propertyName)) {
            sortPropertyName = group.displayPropertyName || group.propertyName;
            compare = compareGroupValue(left, right, group, sortPropertyName, propertyName, ascending);
          }

          if (compare === 0 && sortPropertyName !== group.propertyName) {
            sortPropertyName = group.propertyName;
            compare = compareGroupValue(left, right, group, sortPropertyName, propertyName, ascending);
          }
        }

        // only return if we're not equal, which would be a negative or positive number which is truthy
        if (compare) {
          return compare;
        }
      }

      return 0;
    };
  },

  createMergedComparer: function (comparers) {
    /// <summary>Creates a function that can combine results of several comparators. Uses with multiple compare criterias.</summary>
    /// <param name="comparers">Array of comparers.</param>
    /// <returns type="Function">A comparer function.</returns>

    return function (left, right) {
      let i = 0;
      let compareValue;

      do {
        compareValue = comparers[i](left, right);

        // keep going until we've evaluated all comparers or we find one that is not equal (ie, not 0)
      } while (++i < comparers.length && compareValue === 0);

      return compareValue;
    };
  }
};

module.exports = api;
plexExport("comparable", api);
