const ko = require("knockout");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const expressions = require("../../Expressions/plex-expressions-compiler");
const dataUtils = require("../../Utilities/plex-utils-data");
const RecordElementController = require("../plex-grid-controller-element");
const defaultValueProvider = require("./plex-grid-value-providers");
const elementValueProviderFactory = require("../../Labels/plex-labels-value-providers-factory");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const plexExport = require("../../../global-export");

// this covers labels, pickers, and bindable elements
const displayProperties = ["text", "displayPropertyName", "propertyName"];

function getElementValue(el, options) {
  const values = [];
  options = options || {};

  if (el.elementRows) {
    el.elementRows.forEach((elementRow) => {
      values.push(...getElementValue(elementRow.options, options));
    });
  } else if (el.elements?.length > 0) {
    el.elements.forEach((element) => {
      values.push(...getElementValue(element, options));
    });
  } else if (options.formattedValue) {
    if (el.formattedValue) {
      values.push(el.formattedValue());
    } else if (el.displayValue) {
      values.push(el.displayValue());
    } else {
      values.push("");
    }
  } else if (el.value) {
    values.push(el.value());
  } else if (el.boundValue) {
    values.push(el.boundValue());
  } else {
    values.push(el.originalText);
  }

  return values;
}

function evaluateInitialDisplay(el) {
  if (!el) {
    return;
  }

  // evaluate initial display
  if (el.displayValue) {
    el.displayValue();
  } else if (typeof el.initialDisplayValue === "undefined" && ko.isObservable(el.formattedValue)) {
    el.initialDisplayValue = el.formattedValue();
  }

  // evaluate children
  if (el.elements && el.elements.length > 0) {
    el.elements.forEach(evaluateInitialDisplay);
  }
}

const BindingValueProvider = defaultValueProvider.extend({
  init: function () {
    if (this.column.element) {
      // if there is just a single editable element
      // normalize parameters so that the processing can be simplified
      this.column.elements = this.column.elements || [];
      this.column.elements.push(this.column.element);

      // clear out so it won't get added again if reprocessed
      this.column.element = null;
    }

    // Removing empty objects from the serialized model is very
    // important for "auto" hiding column elements based on customer settings.
    arrayUtils.removeEmpty(this.column.elements);

    const elements = this.column.elements || [];
    const valuedElements = elements.filter((element) => {
      // todo: the server only returns the expression string, but if the expression object was return this could be more robust and allow derived expressions
      return "valueExpression" in element || "propertyName" in element;
    });

    const displayValueElements = elements.filter(
      (el) => el.valueProvider || displayProperties.some((prop) => prop in el)
    );
    if (displayValueElements.length > 0) {
      this.displayValueEvaluator = (record, index) => {
        return displayValueElements
          .map((el) => {
            if (el.valueProvider) {
              const provider = elementValueProviderFactory.create(el);
              return provider.getFormattedValue(record, index);
            }

            return dataUtils.getValue(record, el[ko.utils.arrayFirst(displayProperties, (prop) => prop in el)], true);
          })
          .join(" ");
      };
    }

    if (this.column.valueExpression) {
      this.valueEvaluator = expressions.compile(this.column.valueExpression);
    } else if (valuedElements.length > 0) {
      this.valueEvaluator = function (record) {
        return valuedElements
          .map((el) => {
            if ("parentCollection" in el) {
              return (dataUtils.getValue(record, el.parentCollection, true) || [])
                .map((child) => {
                  return dataUtils.getValue(child, el.valueExpression, true);
                })
                .join(" ");
            }

            return dataUtils.getValue(record, el.valueExpression || el.propertyName, true);
          })
          .join(" ");
      };
    }

    // Pass features to child elements that need to be applied, ie disabled, readonly
    this.features = this.column.features
      ? this.column.features.filter((feature) => {
          return feature.applyToChildren;
        })
      : [];
  },

  getValue: function (record, index) {
    if (this.valueEvaluator) {
      return this.valueEvaluator(record, index);
    }

    if (record && record.$$controller && record.$$controller.index !== undefined && record.$$controller.index != null) {
      const value = this.getRecordElementValue(record);
      if (value && value.length > 0) {
        // return the first element value in the list for now
        return value[0];
      }
    }

    return this._base.apply(this, arguments);
  },

  getValues: function (record, index) {
    if (this.valueEvaluator) {
      return this.valueEvaluator(record, index);
    }

    if (record?.$$controller?.index != null) {
      const value = this.getRecordElementValue(record);
      if (value && value.length > 0) {
        return value;
      }
    }

    return [this.getValue(record, index)];
  },

  getRecordElementValue: function (record, options, index, colIndex) {
    const values = [];
    const rowIndex = index == null ? record.$$controller.index : index;
    const col = this.column.master || this.column;

    if (index !== undefined && colIndex !== undefined) {
      this.process(record, index, colIndex);
    }

    if (col.elementCollection && col.elementCollection[rowIndex]) {
      col.elementCollection[rowIndex].forEach((el) => {
        values.push.apply(values, getElementValue(el, options));
      });
    }

    return values;
  },

  getFormattedValue: function (record, index, colIndex, _features) {
    if (this.displayValueEvaluator) {
      return this.displayValueEvaluator(record, index);
    }

    if (record && ((record.$$controller && record.$$controller.index != null) || (index != null && colIndex != null))) {
      const value = this.getRecordElementValue(record, { formattedValue: true }, index, colIndex);
      if (value && value.length > 0) {
        return value.join(" ");
      }
    }

    return this._base.apply(this, arguments);
  },

  getFormatter: function () {
    const baseFormatter = this._base.apply(this, arguments);
    if (!baseFormatter && this.column.elements.length === 1) {
      return this.column.elements[0].formatter;
    }

    return baseFormatter;
  },

  getHtml: function (record, index, colIndex) {
    const self = this;
    const html = ["<div class='plex-controls controls'"];
    if (this.column.width > 0) {
      html.push(" style='width:", this.column.width, "px;'");
    }

    html.push(">");
    const wasDirty = !record.$$dirtyFlag || record.$$dirtyFlag.isDirty();
    const elements = this.process(record, index, colIndex);

    if (elements.length > 0) {
      elements.forEach((element) => {
        element.inGrid = true;
        const args = { data: record, options: element, index };
        const options = { afterRender: element.afterRender.bind(element) };
        html.push(self.renderTemplate(element.clientViewName, args, options));
      });
    }

    if (!wasDirty) {
      record.$$dirtyFlag.reset();
    }

    html.push("</div>");
    return html.join("");
  },

  getPrintValue: function (record, index, colIndex, _features) {
    const node = new DocumentXml("plex-controls");

    const elements = this.process(record, index, colIndex);
    if (elements.length > 0) {
      elements.forEach((element) => {
        evaluateInitialDisplay(element);
        node.addControlElement(element);
      });
    }

    return node.serialize();
  },

  process: function (record, index, colIndex) {
    if (this.column.elements.length === 0) {
      return [];
    }

    let id = index;
    let ctrl;

    if (typeof index === "number") {
      ctrl = record.$$controller =
        record.$$controller || new RecordElementController(record, index, this.column, this.parent);
    } else if (index && index.id) {
      // store footer & header controllers here
      record.$$sections = record.$$sections || {};
      ctrl = record.$$sections[index.id] =
        record.$$sections[index.id] || new RecordElementController(record, index, this.column, this.parent);
      id = index.id;
    }

    ctrl = ctrl || new RecordElementController(record, index, this.column, this.parent);

    const self = this;
    const col = this.column.master || this.column;

    col.elementCollection = col.elementCollection || {};
    const elements = (col.elementCollection[String(id)] = this.column.elements.map((config) => {
      return ctrl.initElement(config, self.features, colIndex, index);
    }));

    // todo: this belongs somewhere else, preferrably after row has been rendered
    dataUtils.wrapObservables(record);
    return elements;
  }
});

module.exports = BindingValueProvider;
plexExport("grid.valueProviders.BindingValueProvider", BindingValueProvider);
