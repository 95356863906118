ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const Raphael = require("raphael");
const jsUtils = require("../Utilities/plex-utils-js");
const actionHandler = require("../Controls/plex-handler-action");
const expressions = require("../Expressions/plex-expressions-compiler");
const dataTokenUtils = require("../Utilities/plex-utils-datatoken");
const plexExport = require("../../global-export");

const Graphic = function () {
  this.raphaelObject = null;
  this.onDrag = [];
};

Graphic.prototype = {
  constructor: Graphic,

  init: function (config, controller, paper, data) {
    const self = this;

    self.config = config;
    self.controller = controller;
    self.paper = paper;
    self.data = data;
    self.raphaelObject = self.create(config, paper, data);
    self.initAction(config, self.raphaelObject);
    self.initTooltip(config, self.raphaelObject);
    self.connections = [];
  },

  create: function (_config, _paper, _data) {
    // overridden by extenders
  },

  initAction: function (config, raphaelObject, data) {
    const self = this;
    const disabled = config.featuresResult && config.featuresResult.disabled;
    if (config.action && !disabled) {
      actionHandler.initAction(config.action, self.controller);
      raphaelObject
        .attr("cursor", "pointer")
        .click(() => {
          actionHandler.executeAction(config.action, data || self.data);
        })
        .hover(
          () => {
            raphaelObject.attr("opacity", 0.5);
          },
          () => {
            raphaelObject.attr("opacity", 1);
          }
        );
    }
  },

  initTooltip: function (config, raphaelObject, data, parentData, childData) {
    const self = this;
    const tooltip = self.getTooltipText(config, data, parentData, childData);
    if (tooltip) {
      raphaelObject.hover(
        function () {
          const $tooltip = $("<div class='plex-chart-tooltip'><span></span></div>").appendTo(self.controller.$element);
          $tooltip.find("span").html(tooltip);
          $tooltip.attr({ id: self.controller.$element[0].id + "_tooltip" });
          $tooltip.css({
            left: arguments[1] + 15,
            top: arguments[2] + 15
          });
        },
        () => {
          $("#" + self.controller.$element[0].id + "_tooltip").remove();
        }
      );
    }
  },

  evaluateColor: function (config, data) {
    let color;
    const colorCondition =
      config.colorCondition ||
      (config.parentNode && config.parentNode.colorCondition) ||
      (config.childNode && config.childNode.colorCondition);
    if (colorCondition) {
      color = expressions.compile(colorCondition)(data || this.data);
    }

    return color || config.color;
  },

  getTooltipText: function (config, data, parentData, childData) {
    if (config.staticTooltip) {
      return config.staticTooltip;
    }

    if (config.tooltip) {
      return expressions.compile(config.tooltip)(data || this.data);
    }

    let tooltip = null;
    if (config.parentNode && config.parentNode.tooltip) {
      tooltip = expressions.compile(config.parentNode.tooltip)(parentData);
    }

    if (config.childNode && config.childNode.tooltip) {
      tooltip += (tooltip ? " - " : "") + expressions.compile(config.childNode.tooltip)(childData);
    }

    return tooltip;
  },

  remove: function () {
    this.raphaelObject.remove();
  },

  makeDraggable: function (parent) {
    const self = this;
    const shape = self.raphaelObject;

    shape.drag(
      (dx, dy) => {
        const moveShape = parent ? parent.raphaelObject : shape;
        let bb = moveShape.getBBox();
        const tx = moveShape.oX - bb.x + dx;
        const ty = moveShape.oY - bb.y + dy;

        moveShape.translate(tx, ty);
        $.each(parent ? parent.onDrag : self.onDrag, (_key, ondrag) => {
          ondrag();
        });

        bb = moveShape.getBBox();
        const viewBox = { width: self.paper.width, height: self.paper.height };
        if (bb.x2 > viewBox.width || bb.y2 > viewBox.height) {
          viewBox.width = bb.x2 > viewBox.width ? bb.x2 + 50 : viewBox.width;
          viewBox.height = bb.y2 > viewBox.height ? bb.y2 + 50 : viewBox.height;
          self.paper.setSize(viewBox.width, viewBox.height);
        }
      },
      () => {
        const moveShape = parent ? parent.raphaelObject : shape;
        const bb = moveShape.getBBox();
        moveShape.oX = bb.x;
        moveShape.oY = bb.y;
      },
      () => {
        let outOfBounds = false;
        const moveShape = parent ? parent.raphaelObject : shape;
        const bb = moveShape.getBBox();
        if (bb.x < 0) {
          outOfBounds = true;
          moveShape.translate(-bb.x, 0);
        }

        if (bb.y < 0) {
          outOfBounds = true;
          moveShape.translate(0, -bb.y);
        }

        if (outOfBounds) {
          $.each(parent ? parent.onDrag : self.onDrag, (_key, ondrag) => {
            ondrag();
          });
        }
      }
    );

    return self;
  },

  fill: function (value) {
    this.raphaelObject.attr("fill", value || "#FFFFFF");
    return this;
  },

  stroke: function (value) {
    this.raphaelObject.attr("stroke", value || "#000");
    return this;
  },

  cursor: function (value) {
    this.raphaelObject.attr("cursor", value || "default");
    return this;
  },

  strokeWidth: function (value) {
    this.raphaelObject.attr("stroke-width", value || 1);
    return this;
  },

  getBoundingBox: function (options) {
    const bb = this.raphaelObject.getBBox();
    options = options || {};
    const padding = options.padding || 0;
    return {
      x: bb.x - padding,
      y: bb.y - padding,
      x2: bb.x2 + padding,
      y2: bb.y2 + padding,
      width: bb.x2 - bb.x + 2 * padding,
      height: bb.y2 - bb.y + 2 * padding
    };
  },

  behind: function (obj) {
    this.raphaelObject.insertBefore(obj.raphaelObject);
    return this;
  },

  moveTo: function (x, y) {
    const b = this.raphaelObject.getBBox();
    this.raphaelObject.translate(x - b.x, y - b.y);
    return this;
  },

  frontOf: function (obj) {
    this.raphaelObject.insertAfter(obj.raphaelObject);
    return this;
  },

  toBack: function () {
    this.raphaelObject.toBack();
    return this;
  },

  toFront: function () {
    this.raphaelObject.toFront();
    return this;
  },

  between: function (parent, child) {
    const path = this.raphaelObject;

    const updatePath = function () {
      const from = parent.getBoundingBox();
      const to = child.getBoundingBox();

      path.attr({
        path: ["M", from.x + from.width / 2, from.y + from.height / 2, "L", to.x + to.width / 2, to.y + to.height / 2]
      });
    };

    updatePath();

    parent.onDrag.push(updatePath);
    child.onDrag.push(updatePath);
    parent.connections.push(this);

    return this;
  },

  connect: function (connectionConfig, toObject, connectionData) {
    const self = this;
    const from = self.raphaelObject;
    const to = toObject.raphaelObject;

    let colorData = connectionData;
    if (!colorData && connectionConfig.parentNode && connectionConfig.parentNode.colorCondition) {
      colorData = self.data;
    } else if (!colorData && connectionConfig.childNode && connectionConfig.childNode.colorCondition) {
      colorData = toObject.data;
    }

    const attr = {
      stroke: self.evaluateColor(connectionConfig, colorData) || "#000",
      "stroke-width": connectionConfig.width || 2,
      "arrow-end": connectionConfig.useArrow ? "block" : "none"
    };

    const line = self.paper.connection(from, to, attr.stroke, attr);
    line.bg.toBack();
    line.line.toBack();

    const fnDrag = function () {
      self.paper.connection(line);
    };

    self.onDrag.push(fnDrag);
    toObject.onDrag.push(fnDrag);

    self.initConnectionAction(connectionConfig, toObject, line, connectionData);
    self.initTooltip(connectionConfig, line.bg || line, connectionData || {}, self.data, toObject.data);
    self.connections.push(line);
  },

  initConnectionAction: function (connectionConfig, toObject, connection, connectionData) {
    const self = this;

    if (connectionConfig.action) {
      const parentTokenData = dataTokenUtils.applyTokens(
        self.data,
        connectionConfig.action.tokens.filter((t) => {
          return t.childDataToken === false;
        })
      );

      const childTokenData = dataTokenUtils.applyTokens(
        toObject.data,
        connectionConfig.action.tokens.filter((t) => {
          return t.childDataToken;
        })
      );

      const data = $.extend({}, parentTokenData, childTokenData);
      $.each(data, (key) => {
        connectionConfig.action.tokens.push({ propertyName: key, alias: "" });
      });

      self.initAction(connectionConfig, connection.bg || connection, $.extend(data, connectionData || {}));
    }
  }
};

jsUtils.makeExtendable(Graphic);

module.exports = Graphic;
plexExport("graphics.Graphic", Graphic);

/* Copied from http://raphaeljs.com/graffle.js */
/* eslint-disable */
Raphael.fn.connection = function (obj1, obj2, line, bg) {
  if (obj1.line && obj1.from && obj1.to) {
    line = obj1;
    obj1 = line.from;
    obj2 = line.to;
  }

  const bb1 = obj1.getBBox();
  const bb2 = obj2.getBBox();
  let res;
  const p = [
    { x: bb1.x + bb1.width / 2, y: bb1.y - 1 },
    { x: bb1.x + bb1.width / 2, y: bb1.y + bb1.height + 1 },
    { x: bb1.x - 1, y: bb1.y + bb1.height / 2 },
    { x: bb1.x + bb1.width + 1, y: bb1.y + bb1.height / 2 },
    { x: bb2.x + bb2.width / 2, y: bb2.y - 1 },
    { x: bb2.x + bb2.width / 2, y: bb2.y + bb2.height + 1 },
    { x: bb2.x - 1, y: bb2.y + bb2.height / 2 },
    { x: bb2.x + bb2.width + 1, y: bb2.y + bb2.height / 2 }
  ];
  const d = {};
  const dis = [];

  for (let i = 0; i < 4; i++) {
    for (let j = 4; j < 8; j++) {
      const dx = Math.abs(p[i].x - p[j].x);
      const dy = Math.abs(p[i].y - p[j].y);
      if (
        i === j - 4 ||
        (((i !== 3 && j !== 6) || p[i].x < p[j].x) &&
          ((i !== 2 && j !== 7) || p[i].x > p[j].x) &&
          ((i !== 0 && j !== 5) || p[i].y > p[j].y) &&
          ((i !== 1 && j !== 4) || p[i].y < p[j].y))
      ) {
        dis.push(dx + dy);
        d[dis[dis.length - 1]] = [i, j];
      }
    }
  }

  if (dis.length === 0) {
    res = [0, 4];
  } else {
    res = d[Math.min.apply(Math, dis)];
  }

  const x1 = p[res[0]].x;
  const y1 = p[res[0]].y;
  const x4 = p[res[1]].x;
  const y4 = p[res[1]].y;

  const dx = Math.max(Math.abs(x1 - x4) / 2, 10);
  const dy = Math.max(Math.abs(y1 - y4) / 2, 10);

  const x2 = [x1, x1, x1 - dx, x1 + dx][res[0]].toFixed(3);
  const y2 = [y1 - dy, y1 + dy, y1, y1][res[0]].toFixed(3);
  const x3 = [0, 0, 0, 0, x4, x4, x4 - dx, x4 + dx][res[1]].toFixed(3);
  const y3 = [0, 0, 0, 0, y1 + dy, y1 - dy, y4, y4][res[1]].toFixed(3);

  const path = ["M", x1.toFixed(3), y1.toFixed(3), "C", x2, y2, x3, y3, x4.toFixed(3), y4.toFixed(3)].join(",");

  if (line && line.line) {
    if (line.bg.attrs["arrow-end"] === "none") {
      line.bg && line.bg.attr({ path });
    } else {
      const arrow = line.bg.attrs["arrow-end"];
      line.bg && line.bg.attr({ "arrow-end": "", path });
      line.bg && line.bg.attr({ "arrow-end": arrow });
    }

    line.line.attr({ path });
  } else {
    const color = typeof line === "string" ? line : "#000";
    const settings = { fill: "none" };

    // copy attributes forward
    if (bg) {
      for (const prop in bg) {
        if (bg.hasOwnProperty(prop)) {
          settings[prop] = bg[prop];
        }
      }
    }

    return {
      bg: this.path(path).attr(settings),
      line: this.path(path).attr({ stroke: color, fill: "none" }),
      from: obj1,
      to: obj2
    };
  }

  return null;
};
/* eslint-enable */
