ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const toastr = require("toastr");
const glossary = require("../Globalization/plex-glossary-handler");
const stringUtils = require("../Utilities/plex-utils-strings");
const jsUtils = require("../Utilities/plex-utils-js");
const domUtils = require("../Utilities/plex-utils-dom");
const PageState = require("../Core/plex-pagestate");
const ready = require("../Controls/plex-handler-ready");
const plexExport = require("../../global-export");

let elementHandler;

$.extend($.expr[":"], {
  visibleBannerParent: function (banner) {
    return $(banner).parent(":hidden").length === 0;
  }
});

const selector = ".plex-banner:visibleBannerParent";
const bannerTemplate = `<div class="plex-banner" data-bind="css:messageCss">
  <button type="button" class="close plex-banner-close" data-bind="click:cancel,visible:canCancel">&times;</button>
  <span data-bind="html:message"></span>
</div>`;

let globalBanner;

function BannerMessage(parentBanner, message, status, publisherName) {
  this.message = message;
  this.status = status;
  this.css = "plex-banner-" + status;
  this.publisherName = publisherName;
  this.parent = parentBanner;
}

BannerMessage.prototype = {
  constructor: BannerMessage,

  clear: function () {
    this.parent.messages.remove(this);
  }
};

const BannerController = function (el, options) {
  this.$element = $(el);
  this.init(options);
};

const bannerStates = {
  warning: "warning",
  success: "success",
  error: "error",
  info: "info",
  default: "warning"
};

const defaultOptions = {
  status: bannerStates.default,
  isHtml: false, // For cases where we need to send custom html on banner messages.
  // toast's options
  toast: false,
  positionClass: "toast-top-full-width",
  closeButton: true,
  tapToDismiss: true,
  timeOut: 0,
  extendedTimeOut: 0,
  newestOnTop: true
};

BannerController.prototype = {
  constructor: BannerController,

  init: function () {
    const self = this;

    this.messages = ko.observableArray();
    this.position = ko.observable(0);
    this.showing = false;
    this.$toast = null;

    const current = ko.computed(() => {
      return self.messages()[self.position()];
    });

    this.message = ko.computed(() => {
      return current() && current().message;
    });

    this.messageCss = ko.computed(() => {
      return current() && current().css;
    });

    this.canCancel = ko.computed(() => {
      // only allow cancel when all messages have been seen
      return self.position() === self.messages().length - 1;
    });

    this.hasLess = ko.computed(() => {
      return self.position() > 0;
    });

    this.hasMore = ko.computed(() => {
      const ln = self.messages().length;
      return ln > 0 && self.position() < ln - 1;
    });

    // todo: this looks to be obsolete
    this.hasError = ko.observable(true);

    ko.applyBindings(this, this.$element[0]);
    this.bannerFromPreviousPage = false;
  },

  show: function () {
    if (this.showing) {
      return;
    }

    this.showing = true;
    this.$element.slideDown(200);
    if (!this.$element.isOnScreen()) {
      this.scrollTo();
    }
  },

  scrollTo: function () {
    const $scrollParent = this.$element.scrollParent();
    if ($scrollParent[0] === document) {
      $("html, body").animate(
        {
          scrollTop: this.$element.offset().top - $.fixedOffset()
        },
        200
      );
    } else {
      $scrollParent.animate(
        {
          scrollTop: 0
        },
        200
      );
    }
  },

  setMessage: function (message, options) {
    // do not replace unseen messages
    if (!this.hasMore()) {
      this.reset();
    }

    this.addMessage(message, options);
  },

  addMessage: function (message, options) {
    // todo: switch this to use options for glossarize option
    if (message && typeof message === "object") {
      if (message.autoGlossarize) {
        return glossary
          .getCustomerWordAsync({ text: message.text, tokens: message.tokens })
          .then((result) => this.addMessage(result, options));
      }

      message = stringUtils.format(message.text, message.tokens);
    }

    // account for success message option
    // todo: obsolete this case
    if (options === true) {
      options = { status: bannerStates.success };
    }

    options = $.extend({}, defaultOptions, options);
    options = globalBanner.setCustomOptions(options);
    if (options.toast === true) {
      // we are not using the title property
      if (options.status in toastr) {
        this.$toast = toastr[options.status](message, "", options);
      } else {
        this.$toast = toastr[defaultOptions.status](message, "", options);
      }

      if (typeof options.onClick === "function") {
        this.$toast.on("click", options.onClick);
      }

      return {
        clear: () => {
          toastr.clear(this.$toast);
          this.$toast = null;
        }
      };
    }

    if (typeof options.onClick === "function") {
      this.$element.on("click", options.onClick);
    }
    const msg = new BannerMessage(this, message, options.status, options.publisherName);
    if (options.element) {
      elementHandler.initElement(options.element, {}, null, this);
      msg.element = options.element;
    }

    this.messages.push(msg);

    this.setBannerState(options);
    this.bannerFromPreviousPage = false;

    this.show();
    return msg;
  },

  prev: function () {
    if (this.hasLess()) {
      this.position(this.position() - 1);
    }
  },

  next: function () {
    if (this.hasMore()) {
      this.position(this.position() + 1);
    }
  },

  reset: function () {
    this.position(0);
    this.messages.removeAll();
  },

  cancel: function (options, event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.$element) {
      this.$element.off("click");
    }
    const autoCancel = options === true || (options && options.autoCancel);

    if (autoCancel && this.bannerFromPreviousPage) {
      return;
    }

    if (this.$toast) {
      toastr.clear(this.$toast);
      this.$toast = null;
      return;
    }

    const publisherName = options && options.publisherName;
    if (publisherName) {
      this.messages()
        .filter((message) => message.publisherName === publisherName)
        .forEach((message) => message.clear());
    } else {
      this.reset();
    }

    if (this.messages().length === 0) {
      this.showing = false;
      this.$element.slideUp(200);
    }
  },

  setBannerState: function (options) {
    if (options && options.status) {
      this.bannerState = options.status;
    } else if (options === true) {
      this.bannerState = bannerStates.success;
    }
  }
};

function getOrCreateBanner(elementOrSelector, settings) {
  const $el = $(elementOrSelector);
  let banner;

  if ($el.length > 0) {
    banner = $el.data("banner");
    if (!banner) {
      $el.data("banner", (banner = new BannerController($el[0], settings)));
    }
  }

  return banner;
}

$.fn.banner = function (options) {
  /// <summary>
  /// Attaches events & data gathering functions to the banner.
  /// </summary>
  /// <param name="options"></param>
  /// <returns>The jQuery object that called banner.</returns>

  const settings = $.extend(
    {
      // These are the defaults.
    },
    options
  );

  return this.each(function () {
    getOrCreateBanner(this, settings);
    return this;
  });
};

const previousPageBannerId = "previousPageBanner";

function BannerProvider() {
  // constructor
}

BannerProvider.prototype = {
  constructor: BannerProvider,

  states: bannerStates,

  findClosest: function ($element) {
    // look for a child banner first
    let $bannerElement = $element.find(selector);
    if ($bannerElement.length > 0) {
      return getOrCreateBanner($bannerElement);
    }

    // look for previous sibling banner in parent chain
    // this will find a banner that is associated with a given grid
    let $current = $element;
    while ($current.length > 0 && $current[0] !== document) {
      $bannerElement = $current.prev(selector);
      if ($bannerElement.length > 0) {
        return getOrCreateBanner($bannerElement);
      }

      $current = $current.parent();
    }

    if (domUtils.isInDialog($element)) {
      $bannerElement = $element.closest(".plex-dialog").addBack().find(selector);
      if ($bannerElement.length === 0) {
        // dynamically create banner in dialog if it does not exists
        $bannerElement = $(bannerTemplate).prependTo($element.find(".dialog-inner-content"));
      }

      return getOrCreateBanner($bannerElement);
    }

    return getOrCreateBanner(selector);
  },

  getPageBanner: function () {
    return globalBanner.findClosest($(document.body));
  },

  getBanner: function () {
    return this.findClosest(domUtils.getTopDialog() || $(document.body));
  },

  setBanner: function (config, $element) {
    const banner = $element ? this.findClosest($element) : this.getPageBanner();
    if (banner && config && config.isEmpty === false) {
      banner.setMessage(config.htmlMessage, { status: this.states[config.status] });
    }
  },

  showPreviousBanner: function () {
    const previousBanner = PageState.getLastKnownValue(previousPageBannerId);
    const $pageElement = domUtils.getTopDialog(true) || $(document.body);
    const banner = globalBanner.findClosest($pageElement);

    if (previousBanner && banner && banner.setMessage) {
      banner.setMessage(previousBanner.message, { status: previousBanner.bannerState });
      banner.bannerFromPreviousPage = true;
    }

    PageState.clearStateByKey(previousPageBannerId);
  },

  setPreviousBanner: function () {
    const banner = globalBanner.getBanner();

    if (banner && banner.showing && banner.bannerState === "success" && !banner.bannerFromPreviousPage) {
      const previousBanner = {
        message: banner.message(),
        bannerState: banner.bannerState
      };

      PageState.addToCurrentState(previousPageBannerId, previousBanner);
    }
  },

  setOptions: function (options) {
    $.extend(defaultOptions, options);
  },

  setCustomOptions: function (options) {
    return options;
  }
};

jsUtils.makeExtendable(BannerProvider);

// auto-instantiate Banner
$(() => {
  $(".plex-banner").each(function () {
    $(this).banner();
  });
});

// handle previous banner
ready.onReady(() => {
  globalBanner && globalBanner.showPreviousBanner();
});

module.exports = globalBanner = new BannerProvider();
plexExport("BannerProvider", BannerProvider);
plexExport("banner", globalBanner);

elementHandler = require("../Controls/plex-handler-element");
