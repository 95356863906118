ï»¿const $ = require("jquery");
const DataTransform = require("./plex-data-transform");
const plexExport = require("../../../global-export");
const dataTokens = require("../../Utilities/plex-utils-datatoken");
const SidetabsMenuUrlTokenManager = require("../../Controls/SidetabsMenu/plex-sidetabs-menu-url-token-manager");

function compareObject(menuData, data) {
  const keys = Object.keys(menuData);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (SidetabsMenuUrlTokenManager.isInternalTokenName(key) || menuData[key] == null) {
      continue;
    }

    if (!(key in data) || data[key] == null) {
      return false;
    }

    if (data[key].toString() !== menuData[key].toString()) {
      return false;
    }
  }

  return true;
}

function getInternalData(actionData) {
  const keys = Object.keys(actionData);
  const resultData = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (!SidetabsMenuUrlTokenManager.isInternalTokenName(key)) {
      continue;
    }

    resultData[key] = actionData[key];
  }

  return resultData;
}

const SidetabsMenuTokenData = DataTransform.extend({
  onTransform: function (data) {
    const menuItem = this.config.menuItem;

    if (data && menuItem.action.tokens && menuItem.action.tokens.length > 0) {
      const menuData = dataTokens.applyTokens(menuItem.action.data, menuItem.action.tokens);
      if (menuData) {
        if (compareObject(menuData, data)) {
          const internalData = getInternalData(menuItem.action.data);
          const result = $.extend(internalData, menuData, data);

          return result;
        }
      }
    }

    return data;
  }
});

module.exports = SidetabsMenuTokenData;
plexExport("data.transforms.SidetabsMenuTokenData", SidetabsMenuTokenData);
