ï»¿const repository = require("../Controls/plex-model-repository");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

// todo: we should be able to cache function compilations - at least for now they contain no state

const computedTypeRgx = /^Plex.Web.UI.Data.ComputedProperty/i;

function wrapEvaluator(evaluator, sourceId, sourceObject) {
  if (sourceObject || sourceId) {
    return function () {
      const source = sourceObject || repository.get(sourceId);
      if (source) {
        return evaluator(source);
      }
      return undefined;
    };
  }

  return evaluator;
}

function createEvaluator(config) {
  const node = config.rootNode || config;
  const type = node.expressionType;
  const root = plexImport("expressions");

  if (!(type in root)) {
    throw new TypeError("Unknown or unsupported type: " + type);
  }

  const expr = new root[type](node);

  // `getValue` is all that will be needed to perform an evaluation
  return expr.getValue.bind(expr);
}

const api = {
  isComputed: function (obj) {
    return obj && typeof obj === "object" && computedTypeRgx.test(obj.$type);
  },

  compile: function (expression, sourceId, source) {
    /// <summary>Compiles an expression into an executable function.</summary>
    /// <param name="expression">The expression to compile.</param>
    /// <param name="sourceId">
    /// If specified, the data passed to the function when executed will be the
    /// data found at the given id
    /// </param>
    /// <returns type="Function">The compiled function.</returns>

    if (this.isComputed(expression)) {
      return this.compile(expression.expression, sourceId, source);
    }

    let evaluator;
    if (expression.rootNode || expression.expressionType) {
      evaluator = createEvaluator(expression);
    } else {
      try {
        // make a copy of the array and pass in the body as the last
        // item - this way we can pass in variable length parameters into
        // the function construction
        const params = expression.parameters ? expression.parameters.slice(0) : [];
        params.push("with(arguments[0]){return " + expression.expressionText + ";}");

        evaluator = Function.apply(null, params);
      } catch (ex) {
        throw new Error('Unable to parse the expression "' + expression.expressionText + '"');
      }
    }

    return wrapEvaluator(evaluator, sourceId, source);
  }
};

module.exports = api;
plexExport("expressions.Compiler", api);
