ï»¿const parseJSON = require("../Core/plex-parsing-json");
const plexExport = require("../../global-export");

const keyRgx = /"([^"]+)":/g;

const isNestedObject = (value) => {
  // check for nested object - avoid types like Date, RegExp, etc
  return !!value && Object.prototype.toString.call(value) === "[object Object]";
};

/// <summary>Converts all keys of json object to camelcase.</summary>
/// <param name="text">The json object to convert.</param>
/// <returns type="String">The converted json object.</returns>
function toCamelCaseKeys(obj) {
  return parseJSON(
    JSON.stringify(obj).replace(keyRgx, ($0, $1) => {
      return '"' + $1.charAt(0).toLowerCase() + $1.substr(1) + '":';
    })
  );
}

/// <summary>Converts all keys of json object to titlecase.</summary>
/// <param name="text">The json object to convert.</param>
/// <returns type="String">The converted json object.</returns>
function toTitleCaseKeys(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = key[0].toUpperCase() + key.substr(1);
    // handle nested objects
    if (isNestedObject(obj[key])) {
      newObj[newKey] = toTitleCaseKeys(obj[key]);
    } else {
      newObj[newKey] = obj[key];
    }
    return newObj;
  }, {});
}

const api = {
  toCamelCaseKeys,
  toTitleCaseKeys
};

module.exports = api;
plexExport("json", api);
