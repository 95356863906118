ï»¿const api = (module.exports = {});

const $ = require("jquery");
const actionHandler = require("../Controls/plex-handler-action");
const navbar = require("./plex-navbar");
const hotkeys = require("./plex-hotkeys");
const dialogs = require("../Dialogs/plex-dialogs");
const DialogController = require("../Dialogs/plex-dialog-controller");
const helpSidePanelController = require("../Controls/HelpPanel/plex-controller-help-panel");
const nav = require("../Core/plex-navigate");
const domUtils = require("../Utilities/plex-utils-dom");
const plexExport = require("../../global-export");
const plexBanner = require("../Plugins/plex-banner");
const session = require("../Core/plex-session");
const pageState = require("../Core/plex-pagestate");
const kiosk = require("../Form/plex-kiosk");
const { parseUrl } = require("../Core/plex-parsing-url");
const Confirm = require("../Dialogs/plex-dialogs-confirm");
const plexImport = require("../../global-import");

const favoriteHotkeys = [];

function getHotKeyData(oCallback) {
  $.getJSON(nav.buildUrl("/Platform/Favorites/GetHotKeys"), (data) => {
    oCallback(data);
  });
}

function loadFavoriteHotKeys() {
  if (session.hasSession()) {
    while (favoriteHotkeys.length) {
      const key = favoriteHotkeys.pop();
      hotkeys.removeHotKey(key);
    }

    getHotKeyData((data) => {
      const oNavigate = function (action, value) {
        return function () {
          if (
            action.type === "Controller" &&
            action.additionalData &&
            action.additionalData.isFavoriteGroup &&
            action.additionalData.isFavoriteGroup === true
          ) {
            action.parent = navbar;
            actionHandler.executeAction(action, action.additionalData);
          } else {
            actionHandler.executeAction(action, value);
          }
        };
      };

      for (const link of data.links) {
        hotkeys.addHotKey(link.hotKey.toLowerCase(), { bindGlobal: true }, oNavigate(link.action, link.additionalData));
        favoriteHotkeys.push(link.hotKey.toLowerCase());
      }
    });
  }
}
api.loadFavoriteHotKeys = loadFavoriteHotKeys;

function preventBackspaceNavigation() {
  hotkeys.addHotKey("backspace", { bindGlobal: true }, (e) => {
    const focusedElement = e.target;

    if (
      (focusedElement.tagName === "INPUT" && focusedElement.type !== "checkbox" && focusedElement.type !== "radio") ||
      focusedElement.tagName === "TEXTAREA"
    ) {
      return true;
    }

    return false;
  });
}

api.refreshPage = function () {
  window.location.reload();
};

api.refreshPageWithBanner = function (bannerMessage, bannerStatus) {
  const banner = {
    message: bannerMessage,
    bannerState: bannerStatus
  };
  pageState.addToCurrentState("previousPageBanner", banner);

  api.refreshPage();
};

api.openNewWindow = function () {
  pageState.markPageHistoryToCopy();
  open(window.location.href);
  pageState.unmarkPageHistoryToCopy();
};

api.chooseLanguage = function () {
  // noop
};

api.openNewWindowInEnvironment = function (_data, config) {
  if (config.authorized && config.url) {
    open(config.url);
  }
};

api.openKnowledgeCenter = function () {
  open(
    nav.buildUrl("/Platform/Help/Documentation/KnowledgeCenter/", null, {
      externalLink: true
    })
  );
};

// tsch 06/28/16 PC-634 Link to Developer Portal for access to APIs
api.openDeveloperPortal = function () {
  open("https://developers.plex.com");
};

api.launchDialog = function (_data, additionalData) {
  // OBSOLETE: DO NOT USE.
  // eslint-disable-next-line no-new
  new DialogController({
    route: nav.buildUrl(additionalData),
    autoShow: true
  });
};

api.loadEmbeddedPMCDocumentation = function (data) {
  helpSidePanelController.show(data);
};

const spaOnText = "You are turning SPA rendering on for all screens within this tenant (PCN).";
const spaOffText = "You are turning SPA rendering off for all screens within this tenant (PCN).";

api.toggleRenderMode = () => {
  const url = parseUrl();
  const optout = url.query.__fromspa === "1";
  const spa = !!plexImport("spa", true)?.isSpaRendering?.();
  const text = spa ? spaOffText : spaOnText;

  return Confirm(text).then(() => {
    $(document).block();

    return $.ajax({
      method: "POST",
      url: "/Platform/Navigation/ToggleRenderMode",
      data: JSON.stringify({ useSpa: !(spa || optout) })
    }).then(
      () => {
        // remove qs for opt-out screen
        delete url.query.__fromspa;

        if (spa) {
          // remove first segment of URL, which will be a SPA designator
          const [, path] = /^\/[^/]+(.*)$/.exec(url.path);
          url.path = path;
        } else {
          url.path = "/-" + url.path;
        }

        window.location.replace(url.toRelativeString());
      },
      () => {
        $(document).unblock();
      }
    );
  });
};

api.signOut = (baseUrl) => {
  $(document).block();
  const url = nav.buildUrl(baseUrl);
  window.location = url;
};

$(() => {
  const isKioskMode = kiosk.checkIfKioskMode();

  if (navbar.getCurrentNavbar().length > 0 && !isKioskMode) {
    hotkeys.addHotKey("ctrl+u", { bindGlobal: true }, () => {
      /* eslint new-cap: "off" */
      dialogs.LookupUserSupport();
    });

    hotkeys.addHotKey("ctrl+m", { bindGlobal: true }, () => {
      const topDialog = domUtils.getTopDialog();
      const banner = plexBanner.getBanner();

      if (topDialog && topDialog.length > 0) {
        if (banner.showing === false) {
          banner.setMessage("Please, close the foreground window in order to initiate a Menu Search.", {
            status: plexBanner.states.info
          });
        }
      } else {
        navbar.openSearch();
      }
    });

    loadFavoriteHotKeys();
  }

  preventBackspaceNavigation();
});

plexExport("navigation", api);
