ï»¿const ko = require("knockout");
const expressions = require("../../Expressions/plex-expressions-compiler");
const repository = require("../../Controls/plex-model-repository");
const dataUtils = require("../../Utilities/plex-utils-data");
const jsUtils = require("../../Utilities/plex-utils-js");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const GridTitleFeature = Feature.extend({
  onInit: function () {
    const self = this;

    self.titleTextEvaluator = expressions.compile(self.config.expression);

    self.$$gridTitle = ko.observable();

    self.$$gridTitle.subscribe((title) => {
      if (ko.isObservable(self.element.controller.header.title)) {
        self.element.controller.header.title(ko.unwrap(title));
      } else {
        self.element.controller.header.title = ko.unwrap(title);
      }
    });
  },

  onExecute: function (record, index, records) {
    let data = this.config.sourceId ? repository.get(this.config.sourceId) : records;
    if (dataUtils.isEmpty(data)) {
      return;
    }

    let firstRecord = data;
    if (Array.isArray(data) || data[0]) {
      firstRecord = data[0];
    } else {
      data = [data];
    }

    const computedValue = this.titleTextEvaluator(firstRecord, null, data, this.element);
    if (jsUtils.isPromise(computedValue)) {
      computedValue.then(this.$$gridTitle);
    } else {
      this.$$gridTitle(ko.unwrap(computedValue));
    }
  }
});

module.exports = GridTitleFeature;
plexExport("grid.features.GridTitle", GridTitleFeature);
