ï»¿const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const FormatFeature = Feature.extend({
  onExecute: function (_record, _index, _records) {
    this.column.formatter = this.config.formatter;
  }
});

module.exports = FormatFeature;
plexExport("grid.features.Format", FormatFeature);
