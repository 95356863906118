ï»¿const $ = require("jquery");
const notify = require("../Core/plex-notify");
const dataUtils = require("../Utilities/plex-utils-data");
const dialogController = require("../Dialogs/plex-dialog-controller");
const Action = require("./plex-actions");
const ActionHandler = require("../Controls/plex-handler-action");
const nav = require("../Core/plex-navigate");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");

const subscriptions = Object.create(null);

function getActionData(inputData, outputData, mergeData) {
  if (mergeData) {
    return $.extend({}, inputData, outputData);
  }

  return outputData;
}

function setAutoSearchOption(data, options) {
  if (data && data.Search) {
    const autoSearch = data.Search.toString().toLowerCase();
    if (autoSearch === "1" || autoSearch === "true") {
      options.autoSearch = true;
    }
  }
}

function executePostDialogAction(action, actionData, parentAction) {
  action.data = actionData;
  if (action.type === "Search") {
    action.publishSearch = true;
  }

  ActionHandler.executeAction(action, actionData, null, parentAction);
}

const DialogAction = Action.extend({
  onExecute: function (data, rawData) {
    const self = this;

    if (!this.address) {
      notify.error("This feature is coming soon!");
      return false;
    }

    const $deferred = new $.Deferred();
    const urlOptions = { pageMode: this.mode };
    let url, routeData;

    // only pass data when it has been tokenized
    data = this.tokens && this.tokens.length > 0 ? data : {};

    if (this.httpMethod === "POST") {
      setAutoSearchOption(data, urlOptions);
      url = nav.buildUrl(this.address, null, urlOptions);
      routeData = dataUtils.cleanse(data);
    } else {
      url = nav.buildUrl(this.address, data, urlOptions);
    }

    dialogController.create({
      height: self.initialHeight,
      width: self.initialWidth,
      route: url,
      routeData: routeData || null,
      httpMethod: this.httpMethod,
      parentDialogId: this.parentDialogId,
      reusePreviousDialog: this.reusePreviousDialog,
      returnAction: function (dialogData) {
        const actionData = getActionData(data, dialogData, self.mergeData);

        $deferred.resolve(actionData).then(() => {
          self.onReturnAction(dialogData, data, rawData);

          if (self.returnAction) {
            executePostDialogAction(self.returnAction, actionData, self);
          }
        });
      },
      closeAction: function (dialogData) {
        $deferred.reject();
        self.onCloseAction(dialogData, data, rawData);

        if (self.closeAction) {
          // For close actions, we merge the data provided by the dialog itself about its state
          // with the data from the execution, in case it is needed for any reason.
          const actionData = getActionData(data, dialogData, true);
          executePostDialogAction(self.closeAction, actionData, self);
        }
      },
      forceRedirectsToStayInDialog: this.forceRedirectsToStayInDialog
    });

    return $deferred.promise();
  },

  onReturnAction: function (_dialogData, _data, _rawData) {
    // this can be overridden by inheritors
  },

  onCloseAction: function (_dialogData, _data, _rawData) {
    // this can be overridden by inheritors
  }
});

const DialogReturnAction = Action.extend({
  onExecute: function (data) {
    if (this.parent && this.parent.$element) {
      const ctrl = dialogController.find(this.parent.$element);
      if (ctrl) {
        // It is inside a dialog box.
        ctrl.data = data;
      }
    }

    return true;
  }
});

DialogAction.configure = function (action, data) {
  if (action.allowOpenInNewWindow && (action.returnAction || action.closeAction)) {
    // actions can be instatiated multiple times, so clean up previous subscription if it exists
    if (action.id in subscriptions) {
      subscriptions[action.id].dispose();
    }

    subscriptions[action.id] = pubsub.subscribe("action.closing." + action.id, (result) => {
      const nextAction = result && !result.cancelling ? action.returnAction : action.closeAction;
      if (nextAction) {
        const mergeData = action.mergeData || nextAction === action.closeAction;
        executePostDialogAction(nextAction, result.data, data, { mergeData }, action);
      }
    });
  }
};

module.exports = DialogAction;
plexExport("actions.Dialog", DialogAction);
plexExport("actions.DialogReturn", DialogReturnAction);
