ï»¿const ko = require("knockout");

// note: this will be a built-in binding in a future version of knockout
if (!("let" in ko.bindingHandlers)) {
  ko.bindingHandlers.let = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
      // Make a modified binding context, with extra properties, and apply it to descendant elements
      const innerContext = bindingContext.extend(valueAccessor);
      ko.applyBindingsToDescendants(innerContext, element);

      return { controlsDescendantBindings: true };
    }
  };

  ko.virtualElements.allowedBindings.let = true;
}
