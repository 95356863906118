ï»¿const ko = require("knockout");
const $ = require("jquery");
const jsUtils = require("./plex-utils-js");
const plexExport = require("../../global-export");

let uid = 0;
const memos = {};
const memoMatcher = /^\[pl_memo:(.*?)\]$/;
const slice = Array.prototype.slice;

const timer = window.performance && window.performance.now ? window.performance.now.bind(window.performance) : Date.now;

function getMemo(node) {
  const match = memoMatcher.exec(node.nodeValue);
  let id;

  if (match && (id = match[1])) {
    if (id in memos) {
      return memos[match[1]].fn.bind(null, node);
    }

    // This could be a sign of a leak - an event not being
    // unsubscribed when it should be, for example. However there
    // are likely edge cases where the timing of events could cause
    // this to be called before disposing. Best case is probably
    // to put a warning visible to developers.
    // eslint-disable-next-line no-console
    console.warn("Unable to find function for node:", id);
    return null;
  }

  id = ko.memoization.parseMemoText(node.nodeValue);
  if (id) {
    return ko.memoization.unmemoize.bind(ko.memoization, id, [node]);
  }

  return null;
}

function memoize(fn, owner) {
  let memoId = String(++uid);
  memos[memoId] = { owner, fn };

  ko.utils.domNodeDisposal.addDisposeCallback(owner, () => {
    delete memos[memoId];
    memoId = owner = null;
  });

  return "<!--[pl_memo:" + memoId + "]-->";
}

function unmemoize(element, timeout) {
  const it = getIterator(element);
  const $deferred = new $.Deferred();
  iterate(it, timeout, $deferred.resolve);
  return $deferred.promise();
}

function iterate(it, timeout, resolve) {
  const then = timer();
  let result = it.next();

  while (!result.done) {
    result.value();

    if (timeout > 0 && timer() - then > timeout) {
      jsUtils.defer(() => iterate(it, timeout, resolve));
      return;
    }

    result = it.next();
  }

  it.return();
  resolve();
}

function getIterator(element) {
  let queue;

  // may pass in array of child nodes
  if (!("nodeType" in element) && element.length) {
    queue = slice.call(element);
  } else {
    queue = [element];
  }

  return {
    next: function () {
      let memo;

      while (queue.length > 0) {
        const current = queue.shift();
        if (current.nodeType === 8) {
          memo = getMemo(current);
          if (memo) {
            return {
              done: false,
              value: memo
            };
          }
        } else if (current.nodeType === 1) {
          queue.unshift.apply(queue, current.childNodes);
        }
      }

      return {
        done: true
      };
    },

    return: function () {
      queue.length = 0;
    }
  };
}

const api = {
  memoize,

  unmemoize,

  getMemoizedTemplate: function (templateName, data, options, owner) {
    return memoize((node) => {
      ko.renderTemplate(templateName, data, options, node, "replaceNode");
    }, owner);
  },

  releaseMemoized: function (owner) {
    Object.keys(memos)
      .filter((key) => memos[key].owner === owner)
      .forEach((key) => delete memos[key]);
  }
};

module.exports = api;
plexExport("html", api);
