const $ = require("jquery");
const js = require("../Utilities/plex-utils-js");

/**
 * Creates a new promise that resolves when all passed in deferred objects
 * are settled. This is similar to `$.when` with the difference being that
 * this ignores rejections - the value for rejections will be undefined.
 *
 * @param {Array<any>} [...any] Objects to wait for resolution.
 * @returns {Deferred} Returns a new Deferred object.
 */
$.whenAll = function () {
  if (arguments.length === 0) {
    return $.when();
  }

  const resolvedValues = [];
  const deferred = new $.Deferred();
  let remaining = arguments.length;

  function resolve(index, value) {
    resolvedValues[index] = value;
    if (--remaining === 0) {
      deferred.resolve(resolvedValues);
    }
  }

  function reject(index) {
    resolve(index);
  }

  for (let i = 0, ln = arguments.length; i < ln; i++) {
    const arg = arguments[i];
    if (js.isPromise(arg)) {
      arg.then(resolve.bind(null, i), reject.bind(null, i));
    } else {
      resolve(i, arg);
    }
  }

  return deferred;
};
