ï»¿const Feature = require("./plex-features");
const dateUtils = require("../Core/plex-dates");
const plexExport = require("../../global-export");

const StandardColors = Feature.extend({
  getStandardTaskColor: function (plannedEnd, actualEnd) {
    if (plannedEnd && actualEnd) {
      const diff = dateUtils.compareDays(actualEnd, plannedEnd);

      if (diff > 14) {
        return "plex-task-danger";
      } else if (diff > 0 && diff <= 14) {
        return "plex-task-warning";
      } else if (diff > -14 && diff <= 0) {
        return "plex-task-success";
      } else if (diff <= -14) {
        return "plex-task-info";
      }
    }
    return "";
  },

  onExecute: function (record) {
    const cssClass = this.getStandardTaskColor(record.planned_end, record.$$started ? record.end_date : new Date());

    return {
      css: cssClass
    };
  }
});

module.exports = StandardColors;
plexExport("features.StandardColors", StandardColors);
