ï»¿/* eslint-disable import/no-unassigned-import */
const $ = require("jquery");
require("raphael");
require("canvg");
const ko = require("knockout");
const ControllerFactory = require("./plex-controller-factory");
const GraphicsRenderer = require("../Graphics/plex-graphics-renderer");
const pubsub = require("../Core/plex-pubsub");
const printing = require("../Core/plex-printing");
const post = require("../Core/plex-navigate");
const notify = require("../Core/plex-notify");
const Glossary = require("../Globalization/plex-glossary-handler");
const DataResult = require("../Data/plex-data-result");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const nodeDefaults = {
  left: 10,
  top: 20,
  horizontalSpacing: 20,
  verticalSpacing: 20
};

const TreeChartController = function () {
  // constructor
};

TreeChartController.prototype = {
  constructor: TreeChartController,
  init: function (el, config, data) {
    const self = this;
    self.config = config;
    self._subscriptions = [];
    self.searched = ko.observable(false).extend({ notify: "always" });

    const relationships = config.data || data || [];
    if (config.trackClientUpdates) {
      self.data = ko.observableArray(relationships);
    } else {
      self.data = relationships;
    }

    if (self.config.searchActionId) {
      self.captionText = ko.computed(() => {
        const searched = self.searched();
        if (!searched) {
          return self.config.startSearchText;
        }

        if (ko.unwrap(self.data).length === 0) {
          return self.config.emptyText;
        }

        return "";
      });

      self._subscriptions.push(
        pubsub.subscribe("searched." + self.config.searchActionId, self.renderSearchResult, self)
      );
    }

    ko.applyBindings(self, $("#" + self.config.id + "_Content")[0]);

    self.graphicRepository = new GraphicRepository();
    self.graphicRepository.init(self.config);

    self.renderer = new GraphicsRenderer(this);
    self.renderChart();

    if (config.trackClientUpdates) {
      self.dataChanges = self.data.extend({ rateLimit: { timeout: 1000, method: "notifyWhenChangesStop" } });
      self._subscriptions.push(
        self.dataChanges.subscribe(() => {
          self.renderChart();
        })
      );
    }

    self.glossary = {};
    plexImport("currentPage")[self.config.id] = this;
  },

  renderSearchResult: function (searchResult) {
    const self = this;
    if (!(searchResult instanceof DataResult)) {
      searchResult = new DataResult(searchResult);
    }

    // get raw array
    const results = ko.utils.peekObservable(searchResult.data[0].data) || [];
    if (ko.isObservable(self.data)) {
      self.data(results);
    } else {
      self.data = results;
      self.renderChart();
    }

    self.searched(true);
  },

  renderChart: function () {
    const self = this;
    const data = ko.unwrap(self.data);

    self.viewBox = { width: 100, height: 100 };

    self.rootGraphics = [];
    self.graphicRepository.dispose();

    let rootGraphic;
    self._getRootNodes(data).forEach((node) => {
      if (self.graphicRepository.has(node) === false) {
        rootGraphic = self._renderNode(node);
        if (rootGraphic) {
          self.rootGraphics.push(rootGraphic);
        }
      }
    });

    data.forEach((relationship) => {
      self._initRelationship(relationship);
      const parent = self._renderNode(relationship.parent);
      const child = self._renderNode(relationship.child);

      if (parent) {
        self._initPosition(parent);
        if (!parent.$$children) {
          parent.$$children = [];
        }

        if (!parent.$$connectionConfigs) {
          parent.$$connectionConfigs = [];
        }
      }

      if (child) {
        if (parent) {
          parent.$$children.push(child);
        }

        self._initPosition(child);
        if (relationship.connection && parent) {
          parent.$$connectionConfigs.push({
            childId: relationship.child[relationship.child.$$layout.identityPropertyName],
            data: relationship.connection
          });
        }

        if (self.config.autoArrange === false && parent) {
          self.renderer.renderConnection(self._getConnectionConfig(parent.data, child.data), parent, child);
        }
      }
    });

    if (self.config.autoArrange) {
      self._autoArrange();
    }
  },

  saveNodePositions: function () {
    const self = this;
    const positionModels = [];

    $.each(self.graphicRepository.repository, (layoutName, nodeGraphics) => {
      const layout = self._findLayoutByName(layoutName);
      if (layout && layout.positionModel && layout.positionModel.filterId && layout.positionModel.treeType) {
        const positionModel = $.extend({}, layout.positionModel);
        positionModel.positionCollection = [];

        $.each(nodeGraphics, (id, graphic) => {
          const box = graphic.getBoundingBox();
          positionModel.positionCollection.push({
            id,
            left: box.x,
            top: box.y
          });
        });

        positionModels.push(positionModel);
      }
    });

    if (positionModels.length === 0) {
      self._displayMessage("Invalid position model.", "error");
      return;
    }

    const promise = post("/Platform/TreeChart/SaveNodePositions", positionModels);

    promise
      .done((result) => {
        if (result && result.Success) {
          self._displayMessage("Successfully saved the node positions.", "success");
          return;
        }

        let error = "";
        result.CollectionValidationResults.filter((r) => {
          return r.Success === false;
        }).forEach((failed) => {
          error += failed.Message + "\r\n";
        });

        notify.error(error);
      })
      .fail(() => {
        self._displayMessage("Failed to save node positions.", "error");
      });
  },

  _initRelationship: function (relationship) {
    if (relationship.parent && !relationship.parent.$$layout) {
      relationship.parent.$$layout = this._findLayoutByName(relationship.parentLayoutName);
    }

    if (relationship.child && !relationship.child.$$layout) {
      relationship.child.$$layout = this._findLayoutByName(relationship.childLayoutName);
    }
  },

  _renderNode: function (node) {
    const self = this;
    let graphic = null;
    if (node && !(graphic = self.graphicRepository.get(node))) {
      graphic = self.renderer.renderNode(node.$$layout, node);
      if (graphic) {
        self.graphicRepository.add(node, graphic);
      }
    }

    return graphic;
  },

  _findLayoutByName: function (name) {
    const self = this;
    const found = self.config.nodeLayoutCollection.filter((layout) => {
      return layout.name === name;
    });

    return found.length > 0 ? found[0] : self.config.defaultNodeLayout;
  },

  _getConnectionConfig: function (parent, child) {
    const self = this;
    const found = self.config.connectionLineCollection.filter((connection) => {
      return (
        parent.$$layout.name === connection.parentNode.layoutName &&
        child.$$layout.name === connection.childNode.layoutName
      );
    });

    return found.length > 0 ? found[0] : self.config.defaultConnectionLine;
  },

  _initPosition: function (graphic) {
    let left = null;
    let top = null;
    const node = graphic.data;

    const positionModel = node.$$layout.positionModel;
    if (!positionModel) {
      return;
    }

    if (positionModel.positionLeftPropertyName && positionModel.positionTopPropertyName) {
      left = node[positionModel.positionLeftPropertyName];
      top = node[positionModel.positionTopPropertyName];
    } else if (positionModel.positionCollection) {
      const position = positionModel.positionCollection.filter((p) => {
        return p.id === node[node.$$layout.identityPropertyName];
      });

      if (position.length > 0) {
        left = position[0].left;
        top = position[0].top;
      }
    }

    // move the graphics to the position described in the position model and update the view box
    if (left && top && left > 0 && top > 0) {
      graphic.moveTo(left, top);
      graphic.$$autoArranged = true;

      const box = graphic.getBoundingBox();
      if (box.x2 > this.viewBox.width) {
        this.viewBox.width = box.x2 + 50;
      }

      if (box.y2 > this.viewBox.height) {
        this.viewBox.height = box.y2 + 50;
      }
    }
  },

  _getRootNodes: function (data) {
    const self = this;
    const rootNodes = [];

    data.forEach((r) => {
      self._initRelationship(r);
      let isParentChild = false;
      data.forEach((r1) => {
        self._initRelationship(r1);
        if (r1.child && r.parent) {
          if (
            r1.child.$$layout.name === r.parent.$$layout.name &&
            r1.child[r1.child.$$layout.identityPropertyName] === r.parent[r.parent.$$layout.identityPropertyName]
          ) {
            isParentChild = true;
          }
        }
      });

      if (isParentChild === false && r.parent) {
        rootNodes.push(r.parent);
      }
    });

    return rootNodes;
  },

  _autoArrange: function () {
    const self = this;

    const nodeDepths = [];
    nodeDepths.push({ left: nodeDefaults.left, top: nodeDefaults.top, height: 0 });

    // calc the depth top
    const fnCalcTop = function (depth) {
      return depth
        ? nodeDepths[depth - 1].top + nodeDepths[depth - 1].height + nodeDefaults.verticalSpacing
        : nodeDefaults.top;
    };

    // move the graphics node
    const fnMoveGraphic = function (graphic, depth) {
      if (depth >= nodeDepths.length) {
        nodeDepths.push({ left: nodeDefaults.left, top: fnCalcTop(depth), height: 0 });
      }

      graphic.moveTo(nodeDepths[depth].left, nodeDepths[depth].top);
      graphic.$$autoArranged = true;

      fnMoveChildren(graphic, depth);
    };

    // move the children nodes
    function fnMoveChildren(graphic, depth) {
      fnUpdateNodeDepth(graphic, depth);
      if (graphic.$$children) {
        const childDepth = depth + 1;
        graphic.$$children.forEach((child) => {
          if (!child.$$autoArranged) {
            fnMoveGraphic(child, childDepth);
          }

          self.renderer.renderConnection(self._getConnectionConfig(graphic.data, child.data), graphic, child);
        });
      }
    }

    // update node depth
    function fnUpdateNodeDepth(graphic, depth) {
      const box = graphic.getBoundingBox();
      nodeDepths[depth].left += box.width + nodeDefaults.horizontalSpacing;

      if (box.height > nodeDepths[depth].height) {
        nodeDepths[depth].height = box.height;
      }

      // update the view box
      if (box.x2 > self.viewBox.width) {
        self.viewBox.width = box.x2 + 50;
      }

      if (box.y2 > self.viewBox.height) {
        self.viewBox.height = box.y2 + 50;
      }
    }

    // arrange nodes and connect them
    let nodeDepth = 0;
    nodeDepths[nodeDepth].top = nodeDefaults.top;
    $.each(self.graphicRepository.repository, (layoutName, nodeGraphics) => {
      $.each(nodeGraphics, (id, graphic) => {
        if (graphic.$$autoArranged) {
          fnMoveChildren(graphic, nodeDepth);
        } else {
          fnMoveGraphic(graphic, nodeDepth);
        }
      });

      nodeDepth++;
    });

    self.renderer.setPaperSize(self.viewBox);
  },

  dispose: function () {
    let sub;
    while ((sub = this._subscriptions.pop())) {
      sub.dispose();
    }

    this.graphicRepository.dispose();

    if (this.config.id in plexImport("currentPage")) {
      delete plexImport("currentPage")[this.config.id];
    }
  },

  _displayMessage: function (message, type) {
    const self = this;
    if (self.glossary[message]) {
      notify[type](self.glossary[message]);
    } else {
      Glossary.getCustomerWordAsync(message).done((glossarizesMessage) => {
        self.glossary[message] = glossarizesMessage;
        notify[type](glossarizesMessage);
      });
    }
  },

  toDocumentXml: function () {
    const uri = printing.svgToDataUri($("#" + this.config.id), { images: false });

    const $chart = $("<plex-chart />");
    $chart.text(uri);
    return $chart[0].outerHTML;
  }
};

function GraphicRepository() {
  // eslint-disable-next-line no-invalid-this
  this.repository = {};
}

GraphicRepository.prototype = {
  constructor: GraphicRepository,
  init: function (config) {
    this.config = config;
  },

  get: function (node) {
    return this.repository[node.$$layout.name]
      ? this.repository[node.$$layout.name][node[node.$$layout.identityPropertyName]]
      : null;
  },

  add: function (node, graphic) {
    if (!this.repository[node.$$layout.name]) {
      this.repository[node.$$layout.name] = {};
    }

    this.repository[node.$$layout.name][node[node.$$layout.identityPropertyName]] = graphic;
  },

  has: function (node) {
    return (
      this.repository[node.$$layout.name] &&
      !!this.repository[node.$$layout.name][node[node.$$layout.identityPropertyName]]
    );
  },

  dispose: function () {
    const self = this;
    $.each(self.repository, (layoutName, nodeGraphics) => {
      $.each(nodeGraphics, (id, graphic) => {
        if (graphic) {
          if (graphic.connections) {
            graphic.connections.forEach((connection) => {
              $.each(connection, (child, childConnection) => {
                childConnection.remove();
              });
            });
          }

          graphic.remove();
        }
      });

      self.repository[layoutName] = {};
    });
  }
};

TreeChartController.create = function () {
  return new TreeChartController();
};

ControllerFactory.register("Charts/_TreeChart", TreeChartController);

module.exports = TreeChartController;
plexExport("TreeChartController", TreeChartController);
