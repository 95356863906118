ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const actionHandler = require("../Controls/plex-handler-action");
const dataToken = require("../Utilities/plex-utils-datatoken");
const nav = require("../Core/plex-navigate");
const stringUtils = require("../Utilities/plex-utils-strings");
const plexExport = require("../../global-export");

const api = {};

const Submenu = function (targetElement, tokenizedData, data) {
  this.$targetElement = $(targetElement);
  this.tokenizedData = tokenizedData;
  this.data = data;
  this.autoResizeHeight = true;
  this.defaults = {
    left: "200px",
    top: "200px",
    width: "auto",
    height: "auto",
    maxHeight: "none",
    title: "Title",
    pointer: { innerLeft: "-47px", innerTop: "2px", outerLeft: "-50px", outerTop: "0px" },
    buttonText: "Close",
    groupCount: 9,
    items: []
  };
  this.init();
};

Submenu.prototype = {
  constructor: Submenu,

  setData: function (tokenizedData, data) {
    this.tokenizedData = tokenizedData;
    this.data = data;
  },

  // Initializes the ko model.
  init: function () {
    const self = this;

    self.$element = $("<div>").appendTo(document.body);

    self.left = ko.observable(self.defaults.left);
    self.top = ko.observable(self.defaults.top);
    self.width = ko.observable(self.defaults.width);
    self.height = ko.observable(self.defaults.height);
    self.maxHeight = ko.observable(self.defaults.maxHeight);
    self.title = ko.observable(self.defaults.title);
    self.groupCount = ko.observable(self.defaults.groupCount);
    self.items = ko.observableArray(self.defaults.items);
    self.pointer = ko.observable(self.defaults.pointer);
    self.buttonText = ko.observable(self.defaults.buttonText);
    self.isLoading = ko.observable(false);
    self.separatorVisible = function (index) {
      const i = index() + 1;
      if (i >= this.groupCount() && i < this.items().length) {
        return i % this.groupCount() === 0;
      }
      return false;
    };

    self.close = api.Close;
    self.navigate = function (item) {
      actionHandler.executeAction(item.action, self.data);
      /* eslint new-cap: "off" */
      api.Close();
    };

    ko.renderTemplate("dialog-submenu", self, {}, self.$element[0]);

    $("div[id^='Submenu']").on("click", (event) => {
      event.stopPropagation(); // do not bubble the events
    });
  },

  // Displays the submenu.
  render: function () {
    $("div[id^='Submenu']").fadeIn(50, () => {
      const submenu = $(document.body).data("submenu");
      if (submenu) {
        // if not already rendered, adjust the coordinates
        let left = Number(submenu.left().replace("px", ""));
        if (left < 0) {
          left += 10040;
          submenu.relocate(left);
        }
      }
    });
  },

  // Relocates and auto-adjust the coordinates for both, dialog and the pointer for better ui experience.
  relocate: function (left, top, resizeEvent) {
    const self = this;

    // elements
    const $dialog = $("#SubmenuDialog");
    const $win = $(window);
    const $outerPointer = $("#SubmenuDialogOuterPointer");
    const $innerPointer = $("#SubmenuDialogInnerPointer");

    // classes
    const outerPointerRightClass = "plex-submenu-outer-pointer-right";
    const outerPointerLeftClass = "plex-submenu-outer-pointer-left";
    const innerPointerRightClass = "plex-submenu-inner-pointer-right";
    const innerPointerLeftClass = "plex-submenu-inner-pointer-left";

    const currentZIndex = self.$targetElement.zIndex();

    // get the grid header or the parent form to clip the submenu
    let $parentContainer = self.$targetElement.parents(".plex-grid-container").find(".plex-grid-header-wrapper");
    if ($parentContainer.length === 0) {
      $parentContainer = self.$targetElement.closest("form");
    }

    // get the pointer coords and update them, and get the top (at this time the top is the clicked y coordinate)
    let fixedPosition = false;
    const p = self.pointer();
    top = top || Number(self.top().replace("px", ""));
    const pointerTop = top - 10;
    p.outerTop = pointerTop + "px";
    p.innerTop = pointerTop + 1 + "px";

    // if the top (or the e.target top) is not in view, do not adjust the height - this can happen during resizing
    if (top < $parentContainer.offset().top + $parentContainer.outerHeight()) {
      top -= 20;
      $dialog.css("position", "absolute");
    } else if (top > $win.scrollTop() + $win.height()) {
      top = top - $dialog.height() + 20;
      $dialog.css("position", "absolute");
    } else {
      top = self.autoAdjustHeight(top, $parentContainer, $dialog, $outerPointer, resizeEvent);
      $dialog.css("position", "fixed");
      fixedPosition = true;
    }

    // calculate the left position, if outside the window view, adjust to clip it and flip the pointer
    const horizontalScroll = left + $dialog.outerWidth() - ($win.scrollLeft() + $win.width());
    if (horizontalScroll > 0) {
      left = self.$targetElement.offset().left - $dialog.outerWidth() - 10;
      p.outerLeft = left + $dialog.outerWidth() - 1 + "px";
      p.innerLeft = left + $dialog.outerWidth() - 1 + "px";
      if ($outerPointer.hasClass(outerPointerLeftClass)) {
        $outerPointer.removeClass(outerPointerLeftClass).addClass(outerPointerRightClass);
      }
      if ($innerPointer.hasClass(innerPointerLeftClass)) {
        $innerPointer.removeClass(innerPointerLeftClass).addClass(innerPointerRightClass);
      }
    } else {
      p.outerLeft = left - $outerPointer.outerWidth() + 1 + "px";
      p.innerLeft = left - $innerPointer.outerWidth() + 1 + "px";
      if ($outerPointer.hasClass(outerPointerRightClass)) {
        $outerPointer.removeClass(outerPointerRightClass).addClass(outerPointerLeftClass);
      }
      if ($innerPointer.hasClass(innerPointerRightClass)) {
        $innerPointer.removeClass(innerPointerRightClass).addClass(innerPointerLeftClass);
      }
    }

    // if fixed positioning is used, convert the absolute position to fixed position for the dialog
    // this keeps the dialog stationary during the scroll
    if (fixedPosition) {
      top -= $win.scrollTop();
      left -= $win.scrollLeft();
    }

    $dialog.zIndex(currentZIndex + 10);
    $outerPointer.zIndex(currentZIndex + 15);
    $innerPointer.zIndex(currentZIndex + 20);

    // update the ko observable data
    self.left(left + "px");
    self.top(top + "px");
    self.pointer(p);
  },

  // Auto-adjust the dialog height.
  autoAdjustHeight: function (top, $parentContainer, $dialog, $outerPointer, _resizeEvent) {
    const self = this;

    const $win = $(window);
    const $dialogContent = $(".plex-submenu-content");

    const pointerTop = top - 10;
    const gridTop = $parentContainer.offset().top + $parentContainer.height();
    const gridHeight =
      $win.height() - ($parentContainer.offset().top - $win.scrollTop() + $parentContainer.height()) - 20;
    const renderableHeight = (gridHeight * 90) / 100;

    if (self.autoResizeHeight === true) {
      const contentHeight = renderableHeight - 100;
      $dialogContent.css("max-height", contentHeight + "px");
      top = gridTop + (gridHeight - $dialog.height()) / 2;
    } else {
      $dialogContent.css("max-height", self.maxHeight()); // firefox
      if ($dialog.height() <= renderableHeight) {
        top = gridTop + (gridHeight - $dialog.height()) / 2;
      } else {
        top = gridTop + (gridHeight * 5) / 100;
      }
    }

    if (pointerTop < top) {
      top = pointerTop - 10;
    }

    if (pointerTop + $outerPointer.outerHeight() > top + $dialog.outerHeight()) {
      top = top + (pointerTop - (top + $dialog.height())) + 30;
    }

    return top;
  },

  // Update the ko model with the new supplied action.
  update: function (event, action) {
    const self = this;
    const e = event || window.event;
    self.isLoading(false);

    if (e) {
      self.$targetElement = $(e.target);

      const x = e.pageX - 10000; // renders the submenu off screen, we don't know the exact dimensions beforehand, if not supplied
      const y = e.pageY;

      self.left(x + "px");
      self.top(y + "px");
      e.stopPropagation();
    }

    if (action) {
      self.width(action.width && action.width > 0 ? action.width + "px" : self.defaults.width);
      self.height(action.height && action.height > 0 ? action.height + "px" : self.defaults.height);
      self.maxHeight(action.maxHeight && action.maxHeight > 0 ? action.maxHeight + "px" : self.defaults.maxHeight);
      self.autoResizeHeight = self.height() === "auto" && self.maxHeight() === "none";
      self.groupCount(action.groupCount || self.defaults.groupCount);
      self.buttonText(action.buttonText || self.defaults.buttonText);
      self.title(self.defaults.title);

      self.load({
        title: action.title,
        titleTokens: action.titleTokens,
        items: action.items,
        tokens: action.tokens
      });

      self.render(event);

      if (action.address) {
        self.isLoading(true);
        self.height("80px");
        $.getJSON(nav.buildUrl(action.address, self.tokenizedData), (result) => {
          self.height(self.defaults.height);
          self.isLoading(false);
          self.load(result);
          self.relocate(e.pageX + 40, e.pageY);
        });
      }
    }
  },

  // loads the submenu.
  load: function (action) {
    const self = this;

    if (action.title) {
      let title = action.title;
      if (action.titleTokens && self.data && action.titleTokens.length > 0) {
        const titleTokenizedData = dataToken.applyTokens(self.data, action.titleTokens);
        title = stringUtils.format(
          title,
          $.map(titleTokenizedData, (value) => {
            return value;
          })
        );
      }

      self.title(title);
    }

    if (action.items) {
      self.items.removeAll();
      action.items.forEach((i) => {
        let item = i;
        // If a link isn't properly serialized, it can crash the submenu. Ignore empty links.
        if ($.isEmptyObject(item) === false) {
          if (action.tokens && action.tokens.length > 0) {
            // add/override tokens
            item = $.extend(true, {}, i);
            $.merge(item.action.tokens, action.tokens);
          }

          actionHandler.initAction(item.action);
          item.href = "javascript:void(0);";
          if (item.action.type === "Redirect" && item.action.address) {
            item.href = nav.buildUrl(
              item.action.address,
              actionHandler.getTokenData(item.action, self.data),
              item.action.externalLink ? { externalLink: true } : {}
            );
          }

          self.items.push(item);
        }
      });
    }
  }
};

// Constructs the ko model and attaches it to the document body.
$.fn.CreateSubmenu = function (event, method, action, tokenizedData, data) {
  const $body = $(document.body);
  let submenu = $body.data("submenu");
  if (submenu) {
    submenu.setData(tokenizedData, data);
  } else {
    $body.data("submenu", (submenu = new Submenu(event.target, tokenizedData, data)));
  }

  if (method in submenu) {
    submenu[method](event, action);
  }
};

// Closes the submenu if user clicks outside the submenu dialog.
$(() => {
  $(document).on("click", (event) => {
    api.Close(event);
  });

  $(window).on("resizeCompleted scroll", (event) => {
    if ($("#SubmenuDialog").is(":visible")) {
      const submenu = $(document.body).data("submenu");
      if (submenu.$targetElement) {
        const $el = submenu.$targetElement;
        if ($el && $el.length > 0) {
          const elementOffset = $el.offset();
          const left = ((elementOffset && elementOffset.left) || 0) + $el.width() + 30;
          const top = ((elementOffset && elementOffset.top) || 0) + $el.height() - 10;

          submenu.relocate(left, top, event);
        }
      }
    }
  });
});

// Closes the submenu.
api.Close = function (_event) {
  if ($("#SubmenuDialog").is(":visible")) {
    $("div[id^='Submenu']").fadeOut(10);
  }
};

// Intitializes (and/or updates) and opens the submenu.
api.Open = function (event, action, tokenizedData, data) {
  $().CreateSubmenu(event, "update", action, tokenizedData, data);
};

module.exports = api;
plexExport("Submenu", api);
