ï»¿const stringUtils = require("./plex-utils-strings");
const plexExport = require("../../global-export");

const slice = Array.prototype.slice;

const StringBuilder = function () {
  // eslint-disable-next-line no-invalid-this
  this._strings = [];
};

StringBuilder.prototype = {
  constructor: StringBuilder,

  append: function (/* args */) {
    let i = 0;
    const ln = arguments.length;

    for (; i < ln; i++) {
      this._strings.push(String(arguments[i]));
    }
  },

  appendFormat: function (text /* , args */) {
    const args = slice.call(arguments, 1);
    this.append(stringUtils.format(text, args));
  },

  clear: function () {
    this._strings = [];
  },

  dispose: function () {
    this._strings = null;
  },

  toString: function () {
    return this._strings.join("");
  }
};

module.exports = StringBuilder;
plexExport("utils.StringBuilder", StringBuilder);
