const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const uploads = plexImport("uploads");

const FileUploadHandlerFactory = {
  create: function (config, controller) {
    if (!(config.type in uploads)) {
      throw new Error("FileUpload not found: " + config.type);
    }

    const handler = new uploads[config.type]();
    handler.init(config, controller);
    return handler;
  }
};

module.exports = FileUploadHandlerFactory;
plexExport("uploads.FileUploadHandlerFactory", FileUploadHandlerFactory);
