ï»¿const DefaultXmlProvider = require("./plex-printing-xml-provider");
const plexExport = require("../../../global-export");

function minifyValue(htmlString) {
  return htmlString?.replace(/&nbsp;/gi, "&#160;").replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, "$1$2") || "";
}

const RichTextEditorXmlProvider = DefaultXmlProvider.extend({
  onGetXml: function () {
    return this.node.setValue(minifyValue(this.initialDisplayValue));
  }
});

module.exports = RichTextEditorXmlProvider;

plexExport("printing.xmlProviders.richtexteditor", RichTextEditorXmlProvider);
