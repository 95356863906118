/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const CustomerDate = require("../Globalization/plex-customer-date");
const culture = require("../Globalization/plex-culture-datetime");
const controllerFactory = require("./plex-controller-factory");
const Controller = require("./plex-controller-base");
const DateTimeModel = require("./plex-datetime-model");
const dateUtils = require("../Core/plex-dates");
const dateRanges = require("../Core/plex-dates-ranges");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const actionHandler = require("./plex-handler-action");
const elementHandler = require("./plex-handler-element");
const validation = require("../Core/plex-validation");
const banner = require("../Plugins/plex-banner");
const FeatureProcessor = require("../Features/plex-feature-processor");
const repository = require("./plex-model-repository");
const dataUtils = require("../Utilities/plex-utils-data");
const pubsub = require("../Core/plex-pubsub");
const expressions = require("../Expressions/plex-expressions-compiler");
const jsUtils = require("../Utilities/plex-utils-js");
const pageHandler = require("./plex-handler-page");
const DataResult = require("../Data/plex-data-result");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

let uid = 0;

const selectionFlags = {
  none: 0,
  single: 1,
  multiselect: 2
};

const tdSelector = "tbody > tr:nth-child(2) > td:first";

function CalendarPostModelRow(date) {
  this.Date = new CustomerDate(date);
  this.Delete = date.$$deleted();
  this.Data = dataUtils.cleanse(date.data);

  return this;
}

function getDayHeaders() {
  const firstDay = culture.firstDay();
  const names = culture.getDayNames();
  const headers = [];
  for (let i = 0; i < names.length; i++) {
    headers.push(names[(i + firstDay) % 7]);
  }

  return headers;
}

function getStartDate(date) {
  return new CustomerDate(dateRanges.CurrentMonth.getStartDate(date));
}

function getEndDate(date) {
  return new CustomerDate(dateRanges.CurrentMonth.getEndDate(date));
}

function flattenAndFilter(array, fn) {
  return array.reduce(Function.prototype.apply.bind(Array.prototype.concat)).filter(fn);
}

function getUniqueId(id) {
  return "_" + String(++uid) + "_" + id;
}

function isValidDay(day, validDays) {
  return validDays.some((vd) => {
    const customerStartDate = new CustomerDate(vd.startDate);
    const customerEndDate = new CustomerDate(dateUtils.offsetEndDay(vd.endDate));
    return dateUtils.inRange(customerStartDate, customerEndDate, day) === true;
  });
}

const CalendarController = Controller.extend({
  onInit: function () {
    let defaultCalendarDate;
    const self = this;

    self.id = self.config.id;
    self.lockIconUrl = self.config.lockIconUrl;
    self.$form = self.$element.closest("form");
    self.imageOnSelect = self.config.imageOnSelect;
    self.dayHeadersViewModel = getDayHeaders();
    self.filterParameters = {};
    self.tdHeight = ko.observable();

    self.config.usesFixedActionbar = true;

    self.elementHeight = ko.computed(() => {
      return self.tdHeight() * 0.9;
    });

    if (self.config.defaultCalendarDate) {
      defaultCalendarDate = new CustomerDate(self.config.defaultCalendarDate);
    }

    const state = this.restoreState();

    self.dateTimeModel = new DateTimeModel(defaultCalendarDate || state.startDate, {
      dateTokenString: self.config.dateTokenString
    });
    self.setDateFilter();
    self.dayElements = self.config.dayElements;
    self.validDays = self.config.validDays;
    self.footerElements = self.config.footerElements;
    self.titleElement = self.config.titleElement;
    self.selectionMode = self.config.selectionMode;
    self.selectWhen = self.config.selectionExpression ? expressions.compile(self.config.selectionExpression) : null;

    self._disposables = [];
    self._onDateClickCallbacks = [];

    self.isLoading = ko.observable(false);
    self.spannedOverlays = [];
    const validationModel = self.config.validationModel || {};
    self.validator = validation.createValidator(self.$form, validationModel, self);

    if (self.config.dataSource) {
      self.setupDataSourceBindings();
    }

    self._disposables.push(
      self.dateTimeModel.displayDate.subscribe(() => {
        pageHandler.setState(self.config.id, self.getState());
      })
    );

    self.dataSource = dataSourceFactory.create(this.config.dataSource);

    // Subscribe to Filter Searching so Filter critera matches calendar start/end dates
    self._disposables.push(
      pubsub.subscribe(
        "searching." + self.config.searchActionId,
        function (criteria) {
          const filterDates = dataUtils.cleanse(this.filterParameters);
          criteria[self.config.filterStartDateProperty] = filterDates.FilterStartDate;
          criteria[self.config.filterEndDateProperty] = filterDates.FilterEndDate;
        },
        self
      )
    );

    // Subscribe to Filter Searched
    self._disposables.push(pubsub.subscribe("searched." + self.config.searchActionId, self.load, self));

    // If populated data selection is range based
    self.rangedData = !!self.config.toDateKeyPropertyName;

    self.banner = banner.getPageBanner();

    if (self.config.features && self.config.features.length > 0) {
      self.featureProcessor = new FeatureProcessor(self.config.features, self.config, self);
    }

    if (self.config.monthChangeActions && self.config.monthChangeActions.length > 0) {
      self.config.monthChangeActions.forEach((action) => {
        actionHandler.initAction(action, self);
      });
    }

    self.validate = function () {
      let valid = true;

      if (!this.config.validationModel) {
        return true;
      }

      if (this.selected().length > 0) {
        valid = this.validator.validateJustValidationControllers.apply(this, this.selected());
      }

      return valid;
    };

    const triggerOnChange = function (observable) {
      return ko.computed({
        read: function () {
          const fn = ko.unwrap(observable);
          return typeof fn === "function" ? fn() : [];
        },
        write: function (fn) {
          observable(fn);
        }
      });
    };

    const selected = ko.observable();
    self.selected = triggerOnChange(selected);

    const dirty = ko.observable();
    self.dirty = triggerOnChange(dirty);

    self.dateTimeModel.daysViewModel.subscribe(() => {
      self.initDateTimeModel();
      jsUtils.defer(function () {
        this.$firstRowTd = this.$element.find(tdSelector);
        self.setCalendarHeight();
      }, self);
    });

    self.initDateTimeModel();

    // If using Filter dont get data
    if (!self.config.searchActionId) {
      self.getData();
    }

    self.bindActions();

    // Aggregate Model
    self.aggregateData = {};

    const selectedDayCountObservable = ko.observable(null);
    self.aggregateData.SelectedDayCount = ko.computed({
      read: function () {
        const selectedDayCount = ko.unwrap(selectedDayCountObservable);
        if (selectedDayCount === null) {
          return self.selected().length;
        }
        return selectedDayCount;
      },
      write: function (value) {
        selectedDayCountObservable(value);
      }
    });

    dataUtils.trackProperty(self.aggregateData, "SelectedDayCount");

    // Setup Title Element
    if (self.titleElement) {
      elementHandler.initElement(self.titleElement, self.aggregateData, null, self);
    }

    // Setup Footer Elements
    self.footerElements.forEach((el) => {
      elementHandler.initElement(el, self.aggregateData, null, self);
    });

    // Selection Notification
    self._disposables.push(
      self.selected.subscribe((select) => {
        pubsub.publish("selected.calendar." + self.config.id, select || null);
      })
    );

    repository.add(self.config.id, self.selected);

    ko.renderTemplate("calendar-template", self, {}, self.$element[0]);

    self.$firstRowTd = self.$element.find(tdSelector);
    self.setCalendarHeight();
  },

  postInit: function () {
    // Overide base, we don't want data property set
    this.data = null;
  },

  setupDataSourceBindings: function () {
    const self = this;
    const parameters = self.config.dataSource.parameters;

    parameters.forEach((parameter) => {
      const targetKey = parameter.binding.targetKey;
      const targetPropertyName = parameter.binding.targetPropertyName;

      if (
        targetKey === self.config.id &&
        (targetPropertyName === "FilterStartDate" || targetPropertyName === "FilterEndDate")
      ) {
        parameter.binding.target = self.filterParameters;
      }
    });
  },

  bindActions: function () {
    const self = this;
    let $buttons;

    if (self.config.calendarActions && self.config.calendarActions.length > 0) {
      $buttons = self.$element.parent().find(".plex-calendar-buttons");

      self.config.calendarActions.forEach((el) => {
        el.parent = self;
        elementHandler.initElement(el, {}, null, self);
        el.executeAction = function () {
          const postData = self.dirty().map((day) => {
            return new CalendarPostModelRow(day);
          });

          const postModel = {
            rows: postData,
            customRevisionTracking: true,
            __revisionTrackingData: [self.getRevisionTrackingRecords(postData)]
          };

          if (postModel.rows.length === 0 && el.action.type !== "Back") {
            self.banner.setMessage({ text: "No records have been modified", autoGlossarize: true });
            return;
          }

          $.when(actionHandler.executeAction(el.action, postModel)).done((result) => {
            if (result.ValidationResult && result.ValidationResult.Success) {
              self.applyToEachDay((day) => {
                day.$$dirty(false);
                day.$$overlays([]);
              });

              // Reload to get data for for added records.
              self.getData();
            }
          });
        };
      });

      ko.applyBindings(self, $buttons[0]);
    }
  },

  restoreState: function () {
    return pageHandler.restoreState(this.config.id) || {};
  },

  getState: function () {
    const state = {};
    state.startDate = getStartDate(this.dateTimeModel.displayDate());
    return state;
  },

  setDateFilter: function () {
    this.filterParameters.FilterStartDate = getStartDate(this.dateTimeModel.displayDate());
    this.filterParameters.FilterEndDate = getEndDate(this.dateTimeModel.displayDate());
  },

  changeMonthTo: function (change) {
    const self = this;

    self.banner.cancel();
    change();
    self.setDateFilter();
    self.getData();

    if (self.config.monthChangeActions && self.config.monthChangeActions.length > 0) {
      this.config.monthChangeActions.forEach((action) => {
        actionHandler.executeAction(action, self.filterParameters);
      });
    }
  },

  nextMonth: function () {
    const self = this;
    this.changeMonthTo(() => {
      self.dateTimeModel.next();
    });
  },

  previousMonth: function () {
    const self = this;
    this.changeMonthTo(() => {
      self.dateTimeModel.previous();
    });
  },

  // This will initilize the days for the calendar and
  // will execute on month changes
  initDateTimeModel: function () {
    const self = this;

    this.applyToEachDay(self.initDay);

    self.selected(() => {
      return flattenAndFilter(self.dateTimeModel.daysViewModel(), (day) => {
        if (day.$$selected && day.$$selected()) {
          return true;
        }

        return false;
      });
    });

    self.dirty(() => {
      return flattenAndFilter(self.dateTimeModel.daysViewModel(), (day) => {
        if (day.$$dirty && day.$$dirty()) {
          return true;
        }

        return false;
      });
    });
  },

  initDay: function (day) {
    const self = this;

    const selected = ko.observable(false);
    const dirty = ko.observable(false);

    day.$$imageElement = ko.observable();
    day.$$overlays = ko.observableArray([]);
    day.data = ko.observableArray([]);
    day.$$elementConfigs = {};
    day.$elements = {};

    day.data.subscribe(
      function (changes) {
        changes
          .filter((change) => {
            return change.status === "added";
          })
          .map((change) => {
            return change.value;
          })
          .forEach(this._initilizeRecord.bind(self));
      },
      self,
      "arrayChange"
    );

    // Create the validator for the day
    if (this.config.validationModel) {
      day.validator = validation.createValidator(this.$form, this.config.validationModel, day);
    }

    if (this.config.validDays && this.config.validDays.length > 0) {
      day.isValid = isValidDay(day, this.config.validDays);
    } else {
      day.isValid = true;
    }

    day.validate = function (suppressBanner) {
      return day.validator ? day.validator.isValid(suppressBanner) : true;
    };

    day.controllerInitsPropertyValidation = true;

    if (self.imageOnSelect) {
      const clone = elementHandler.cloneElement(self.imageOnSelect, getUniqueId(self.id));
      elementHandler.initElement(clone, day);
      day.$$imageElement(clone);
    }

    day.$$selected = ko.computed({
      read: function () {
        return ko.unwrap(selected);
      },
      write: function (value) {
        if (self.selectWhen && day.data.length) {
          // May need to handle multiple records on a day
          selected(self.selectWhen(day.data[0]));
        } else {
          selected(value);
        }
      }
    });

    day.$$deleted = ko.computed({
      read: function () {
        return !ko.unwrap(selected);
      }
    });

    day.$$dirty = ko.computed({
      read: function () {
        // Is not dirty if data didn't exist and we didn't select
        if (!day.$$selected() && !day.data().length) {
          return false;
        }

        const dataIsDirty = day.data().some((record) => {
          if (!record.$$initilized) {
            throw new Error("Record not initilzed, use addRecordToDay or data.push.");
          }
          return ko.unwrap(record.$$dirtyFlag.isDirty);
        });

        return ko.unwrap(dirty) || dataIsDirty;
      },
      write: function (value) {
        dirty(value);
      }
    });
  },

  // Gets data from the datasource
  getData: function () {
    const self = this;

    if (this.dataSource.isLoading()) {
      this.currentRequest.abort();
    }

    self.isLoading(true);
    this.currentRequest = this.dataSource.get(null, { cacheResponse: false });

    this.currentRequest.then(this.load.bind(this)).done(() => {
      self.isLoading(false);
    });
  },

  // Loads data into the calendar
  load: function (data) {
    const self = this;
    const dateKeyProp = self.config.dateKeyPropertyName;
    if (!(data instanceof DataResult)) {
      data = new DataResult(data);
    }

    // Remove any data currently bound to each day
    self.applyToEachDay(self.removeDataFromDay);

    // Clear Spanned Overlays
    self.spannedOverlays = [];

    const rows = data.data || [];

    const processRecords = function (records, fnToApply) {
      records.forEach((record) => {
        self.applyToEachDay((day) => {
          const dataDate = new CustomerDate(record[dateKeyProp]);
          const dayOffset = new CustomerDate(dateUtils.offsetEndDay(day));

          if (self.inRange(day, dayOffset, dataDate)) {
            fnToApply.call(self, day, record, self.config.selectWithData);
          }
        });
      });
    };

    processRecords(rows, self.addRecordToDay);

    // Process any overlays that spanned days
    if (self.rangedData) {
      processRecords(self.spannedOverlays, self.addOverlayToDay);
    }

    // Init Validation on each day
    if (self.config.validationModel) {
      self.applyToEachDay(self.initDayValidation);
    }
  },

  initDayValidation: function (day) {
    // Get Validatable Properties
    $.each(day.$$elementConfigs, (_index, element) => {
      if (!element.propertyName) {
        return;
      }

      const propertyName = element.propertyName;
      day.$elements[propertyName] = day.$elements[propertyName] || [];
      const $el = $("#" + element.id);

      if ($el.length > 0) {
        day.$elements[propertyName].push($el);
        day.validator.initPropertyValidation(propertyName);
      }
    });
  },

  addElementsToDay: function (day, data) {
    const self = this;
    // Add elements
    if (this.dayElements.length > 0) {
      this.dayElements.forEach((el) => {
        const clone = elementHandler.cloneElement(el, getUniqueId(self.id));
        elementHandler.initElement(clone, data, null, day);
        data.$$elements.push(clone);
        day.$$elementConfigs[clone.id] = clone;
      });
    }
  },

  addOverlayToDay: function (day, data) {
    const self = this;
    const fromDateProp = this.config.dateKeyPropertyName;
    const toDateProp = this.config.toDateKeyPropertyName;
    const overlayData = [];

    // Make a Copy
    data = $.extend({}, data);

    // Create overlays for data that spans a day
    const generateNextDay = function (thisDay) {
      const nextDay = $.extend({}, thisDay);
      const offsetBegin = new CustomerDate(dateUtils.offsetBeginDay(thisDay[fromDateProp]));
      const offsetEnd = new CustomerDate(dateUtils.offsetEndDay(thisDay[fromDateProp]));

      if (self.inRange(offsetBegin, offsetEnd, thisDay[toDateProp]) === false) {
        thisDay[toDateProp] = new CustomerDate(dateUtils.offsetEndDay(thisDay[fromDateProp]));
        nextDay[fromDateProp] = new CustomerDate(
          dateUtils.offsetBeginDay(new CustomerDate(dateUtils.addDays(thisDay[fromDateProp], 1)))
        );
        generateNextDay(nextDay);
      }

      overlayData.push(thisDay);
    };

    if (this.rangedData) {
      generateNextDay(data);
    } else {
      overlayData.push(data);
    }

    overlayData.forEach((overlay) => {
      const dayOffset = new CustomerDate(dateUtils.offsetEndDay(day));
      if (self.inRange(day, dayOffset, overlay[fromDateProp], day)) {
        day.$$overlays.push(
          ko.deferredComputed(() => {
            return self.featureProcessor.process(overlay);
          })
        );
      } else {
        // will process this overlay later
        self.spannedOverlays.push(overlay);
      }
    });
  },

  addRecordToDay: function (day, record, selectWithData) {
    this._initilizeRecord(record);

    if (this.featureProcessor) {
      this.addOverlayToDay(day, record);
    }

    this.addElementsToDay(day, record);

    if (selectWithData) {
      day.$$selected(true);
    }

    day.data.push(record);
  },

  _initilizeRecord: function (record) {
    const fromDateProp = this.config.dateKeyPropertyName;
    const toDateProp = this.config.toDateKeyPropertyName;

    if (record.$$initilized) {
      return;
    }

    record.$$elements = ko.observableArray([]);

    if (record[fromDateProp]) {
      record[fromDateProp] = new CustomerDate(record[fromDateProp]);
    }

    if (record[toDateProp]) {
      record[toDateProp] = new CustomerDate(record[toDateProp]);
    }

    dataUtils.trackObject(record);
    record.$$dirtyFlag = ko.dirtyFlag(record);
    record.$$initilized = true;
  },

  removeDataFromDay: function (day) {
    day.data.removeAll();
    day.$$elementConfigs = {};
    day.$$overlays([]);
    day.$elements = {};

    if (this.config.selectWithData) {
      day.$$selected(false);
      day.$$dirty(false);
    }
  },

  applyToEachDay: function (fn) {
    const self = this;
    // eslint-disable-next-line array-callback-return
    self.dateTimeModel.daysViewModel().some((week) => {
      week.forEach((day) => {
        fn.call(self, day);
      });
    });
  },

  addEmptyRecordToDay: function (day, record) {
    const emptyRecord = $.extend(true, {}, this.config.emptyRecord, record);
    this.addRecordToDay(day, emptyRecord, false);

    if (this.config.validationModel) {
      this.initDayValidation(day);
    }
  },

  setCalendarHeight: function () {
    this.$element.height($(window).height() * 0.8);
    // IE11 doesnt respect div 100% height within a TD
    this.tdHeight(this.$firstRowTd[0].clientHeight);
  },

  clearSelections: function () {
    this.selected().forEach((selectedDay) => {
      selectedDay.$$selected(false);
    });
  },

  click: function (day, event) {
    if (!$(event.target).is(":input")) {
      this.selectDate(day);
      this._onDateClickCallbacks.forEach((callback) => {
        callback(day);
      });
    }
  },

  selectDate: function (day) {
    if (
      this.selectionMode === selectionFlags.none ||
      !this.isDayInCurrentMonth(day) ||
      (this.validDays && this.validDays.length > 0 && !day.isValid)
    ) {
      return;
    }

    // Add an empty record and init the element
    if (this.config.bindElementsOnSelect && day.data().length === 0) {
      this.addEmptyRecordToDay(day);
    }

    day.$$dirty(true);

    if (this.selectionMode === selectionFlags.single) {
      if (day.$$selected()) {
        this.clearSelections();
        return;
      } else {
        this.clearSelections();
        day.$$selected(true);
        return;
      }
    }

    day.$$selected(!day.$$selected());
  },

  onDateClick: function (callback) {
    this._onDateClickCallbacks.push(callback.bind(this));
  },

  selectMonth: function (month) {
    const self = this;
    this.changeMonthTo(() => {
      self.dateTimeModel.displayDate(month.SelectedMonth);
    });
  },

  isDayInCurrentMonth: function (day) {
    return dateUtils.compareMonths(day, this.filterParameters.FilterStartDate) === 0;
  },

  showAbbrMonth: function (date) {
    const lastDayOfMonth = new CustomerDate(dateUtils.getLastDayOfMonth(date));

    if (date.getDate() === 1 || dateUtils.compareDays(lastDayOfMonth, date) === 0) {
      return true;
    } else {
      return false;
    }
  },

  // In Range is inclusive of end date, but we want it exclusive
  inRange: function (startDate, endDate, date) {
    endDate = new CustomerDate(dateUtils.addMilliseconds(endDate, -1));
    return dateUtils.inRange(startDate, endDate, date);
  },

  getRevisionTrackingRecords: function (records) {
    return {
      applicationKey: plexImport("currentApplication").ApplicationKey,
      identityName: this.dateTimeModel.displayDate(),
      gridRecord: false,
      revisionTrackingEntries: this.getRevisionTrackingEntries(records)
    };
  },

  getRevisionTrackingEntries: function (records) {
    return records.map((record) => {
      return {
        OriginalValue: record.Delete ? record.Date : "",
        RevisionValue: record.Delete ? "" : record.Date,
        Field: "Date"
      };
    });
  },

  toDocumentXml: function () {
    const doc = new DocumentXml("plex-calendar");
    const calendarTable = doc.createNode("plex-calendar-table");
    const header = calendarTable.createNode("calendar-table-header");
    const body = calendarTable.createNode("calendar-table-body");

    this.addCalendarRowsFor("thead > tr", header);
    this.addCalendarRowsFor("tbody > tr", body);

    return doc.serialize();
  },

  addCalendarRowsFor: function (selector, node) {
    const self = this;

    $(selector, this.$element).each(function () {
      const tr = node.createNode("calendar-table-row");
      $(this)
        .children()
        .each(function () {
          self.addCalendarCellElement($(this), tr);
        });
    });
  },

  addCalendarCellElement: function ($element, node) {
    const cell = node.createNode("calendar-table-cell");
    let label = "";
    let dayNode, elementNode;
    let bgcolor = "#f9f9f9";

    if ($element.is("th")) {
      cell._$node.text($element.text());
    } else {
      // Add Overlays
      $(".plex-calendar-overlay", $element).each(function () {
        const overlayNode = cell.createNode("calendar-overlay");
        $.each(ko.contextFor(this).$data.style, (key, value) => {
          if (key.localeCompare("backgroundColor") === 0) {
            bgcolor = value;
          }

          overlayNode.addAttribute(cell.hyphenateAttributeName(key), value);
        });
      });

      // Add the calendar day label
      dayNode = cell.createNode("calendar-day-label");
      $(".plex-calendar-day-label span", $element).each(function () {
        const $label = $(this);
        if ($label.is(":visible")) {
          label = label + " " + $label.text();
        }
        dayNode._$node.text(label);
      });

      // Add Elements
      $(".plex-calendar-elements .plex-controls", $element).each(function () {
        elementNode = cell.createNode("calendar-element");
        elementNode.addControlElement(ko.contextFor(this).$data);
      });

      // Add Checkmarks
      $("input", $element).each(function () {
        if ($(this).prop("checked")) {
          cell.appendXml($("<checkmark />"));
        }
      });
    }

    if ($element.hasClass("plex-calendar-inactive")) {
      cell.addAttribute("background-color", "#808080");
    }

    $(".plex-calendar-overlay-container", $element).each(function () {
      bgcolor = bgcolor || $(this).css("background-color");
      if (bgcolor) {
        cell.addAttribute("background-color", bgcolor);
      }
    });

    const colspan = $element.attr("colspan");
    if (colspan && parseInt(colspan, 10) > 1) {
      cell.addAttribute("colspan", colspan);
    }
  }
});

// factory method
CalendarController.create = function (config, model) {
  return new CalendarController(config, model);
};

controllerFactory.register("_Calendar", CalendarController);

module.exports = CalendarController;
plexExport("CalendarController", CalendarController);
