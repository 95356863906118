ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const columnBuilder = require("./plex-grid-column-builder");
const plexExport = require("../../../global-export");

function resetColumns(columns) {
  let i = columns.length;

  // cleanup by removing spacers and reseting colspans
  while (i--) {
    if (columns[i] instanceof Spacer) {
      columns.splice(i, 1);
    } else {
      columns[i].colspan = 0;
    }
  }
}

function RowLayout(config, grid) {
  this.sourceColumns = config.columns;
  this.config = grid.config;
  this.primaryLayout = grid.header;
  this.features = config.features;
  this.grid = grid;
  this.primary = false;
  this.init();
}

RowLayout.prototype = {
  constructor: RowLayout,

  init: function () {
    const self = this;
    arrayUtils.removeEmpty(this.sourceColumns);

    this.columns = this.sourceColumns.slice(0);

    this.columns.forEach((column) => {
      columnBuilder.build(column, self);
    });
  },

  reset: function () {
    resetColumns(this.columns);
    const primaryColumns = ko.unwrap(this.primaryLayout.columns);

    let index = 0;
    let position = 0;
    const primaryLength = primaryColumns.length;
    let spacer, currentColumn, primaryColumn;

    while (position < primaryLength) {
      primaryColumn = primaryColumns[position];
      currentColumn = currentColumn || this.columns[index];

      // end of columns - space out the rest
      if (!currentColumn) {
        if (!spacer) {
          // see if there is a prior dynamic column/spacer that we can use
          spacer = new Spacer();
          this.columns.push(spacer);
        }

        spacer.colspan += primaryColumn.colspan;
        position++;
        continue;
      }

      // fixed column span
      if (currentColumn.columnSpan.span) {
        const end = Math.min(position + currentColumn.columnSpan.span, primaryLength);
        do {
          currentColumn.colspan += primaryColumn.colspan;
          primaryColumn = primaryColumns[position++];
        } while (position < end);

        currentColumn = this.columns[++index];
        continue;
      }

      // anchored column
      if (currentColumn.columnSpan.anchorColumnId) {
        if (currentColumn.columnSpan.anchorColumnId !== primaryColumn.id) {
          // if we haven't reached the anchor yet, add space and continue
          if (!spacer) {
            spacer = new Spacer();
            this.columns.splice(index - 1, 0, spacer);
            index++;
          }

          spacer.colspan += primaryColumn.colspan;
          position++;
          continue;
        }

        currentColumn.colspan += primaryColumn.colspan;
        if (
          currentColumn.columnSpan.endAnchorColumnId &&
          currentColumn.columnSpan.endAnchorColumnId !== currentColumn.columnSpan.anchorColumnId
        ) {
          // if there is an end anchor advance until we reach it
          do {
            primaryColumn = primaryColumns[++position];
            currentColumn.colspan += primaryColumn.colspan;
          } while (position < primaryLength && primaryColumn.id !== currentColumn.columnSpan.endAnchorColumnId);
        }

        spacer = null;
        currentColumn = this.columns[++index];
        position++;
        continue;
      }

      spacer = currentColumn;
      spacer.colspan += primaryColumn.colspan;

      currentColumn = this.columns[++index];
      position++;
    }
  }
};

function Spacer() {
  this.colspan = 0;
  this.empty = true;

  this.visible = this.printVisible = function () {
    return this.colspan > 0;
  };
}

module.exports = RowLayout;
plexExport("grid.RowLayout", RowLayout);
