ï»¿const domUtils = require("../Utilities/plex-utils-dom");
const dialogController = require("../Dialogs/plex-dialog-controller");
const Action = require("./plex-actions");
const nav = require("../Core/plex-navigate");
const env = require("../Core/plex-env");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");

const BackAction = Action.extend({
  onExecute: function (data) {
    if (env.parentActionId) {
      pubsub.publish("action.closing." + env.parentActionId, { cancelling: this.cancelling, data });
    }

    if (this.parent && domUtils.isInDialog(this.parent.$element)) {
      const ctrl = dialogController.find(this.parent.$element);
      if (ctrl) {
        if (this.cancelling) {
          ctrl.cancel();
        } else {
          ctrl.close(data);
        }

        return false;
      }
    }

    nav.goBack(this.cancelling);
    return !this.cancelling;
  }
});

module.exports = BackAction;
plexExport("actions.Back", BackAction);
