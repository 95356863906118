const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");

const MaxOperation = Operation.extend({
  getValue: function (records) {
    /// <summary>Gets the maximum value from the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Number">The maximum value.</returns>
    const recordValues = this.getRecordValues(records);

    return recordValues.length ? Math.max.apply(Math, recordValues) : "";
  }
});

module.exports = MaxOperation;
plexExport("grid.aggregates.MaxOperation", MaxOperation);
