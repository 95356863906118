ï»¿const expressions = require("../../Expressions/plex-expressions-compiler");
const logger = require("../../Core/plex-logger");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const ExpressionValueProvider = defaultValueProvider.extend({
  init: function () {
    this.evaluator = expressions.compile(this.column.expression);
  },
  getValue: function () {
    try {
      return this.evaluator.apply(null, arguments);
    } catch (ex) {
      logger.error('Unable to evaluate expression "' + this.column.expression + '"');
    }

    return "#error#";
  }
});

module.exports = ExpressionValueProvider;
plexExport("grid.valueProviders.ExpressionValueProvider", ExpressionValueProvider);
