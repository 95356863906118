ï»¿const repository = require("./plex-model-repository");
const dataUtils = require("../Utilities/plex-utils-data");
const plexExport = require("../../global-export");

function updateBinding(binding, source) {
  let value;
  binding.target = binding.target || repository.get(binding.targetKey);

  if (binding.target) {
    if (Array.isArray(source)) {
      // If array is empty, pass null to clear binding target
      if (source.length === 0) {
        updateBinding(binding, null);
      }

      const values = source.map((item) => {
        return item[binding.sourcePropertyName];
      });

      if (values.length === 1) {
        value = values[0];
      } else {
        value = values.join(",");
      }
    } else {
      value = source ? dataUtils.getValue(source, binding.sourcePropertyName) : null;
    }

    dataUtils.setValue(binding.target, binding.targetPropertyName, value);
  }
}

function updateTarget(binding, target) {
  const model = repository.get(binding.sourceKey);
  if (model) {
    const value = dataUtils.getValue(model, binding.sourcePropertyName);
    if (Array.isArray(target)) {
      target.forEach((t) => {
        updateTarget(binding, t);
      });
    } else {
      dataUtils.setValue(target, binding.targetPropertyName, value);
    }
  }
}

const api = {
  update: function (binding, source) {
    if (Array.isArray(binding)) {
      binding.forEach((b) => {
        updateBinding(b, source);
      });
    } else {
      updateBinding(binding, source);
    }
  },

  updateTarget: function (binding, target) {
    if (Array.isArray(binding)) {
      binding.forEach((b) => {
        updateTarget(b, target);
      });
    } else {
      updateTarget(binding, target);
    }
  }
};

module.exports = api;
plexExport("BindingHandler", api);
