import React from "react";
import ReactDOM from "react-dom";
import { features } from "../Core/plex-env";

const sectionStyle = {
  position: "fixed",
  bottom: 55,
  left: 5,
  right: 5,
  padding: 10,
  backgroundColor: "#ddd",
  opacity: 0.8,
  border: "2px solid #000",
  zIndex: 5000
};

const tableStyle = {
  width: "100%",
  border: "1px solid black",
  borderCollapse: "collapse",
  marginBottom: 5
};

const cellStyle = {
  textAlign: "right",
  border: "1px solid black",
  padding: "3px",
  fontWeight: "normal",
  fontFamily: "Helvetica 'Trebuchet MS' Arial sans-serif",
  fontSize: "11px"
};

const updateCounter = (counters, { key, value, unit }) => {
  if (key in counters) {
    counters[key].value += value;
    counters[key].count += 1;
  } else {
    counters[key] = { value, unit, count: 1 };
  }
};

const resetCounters = (counters) => {
  return Object.keys(counters).reduce((values, key) => {
    values[key] = { value: 0, count: 0, unit: counters[key].unit };
    return values;
  }, {});
};

const toTitleCase = (key) => {
  return (
    key[0].toUpperCase() +
    key
      .substr(1)
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/(\s)([a-z])/g, (_, $1, $2) => `${$1}${$2.toUpperCase()}`)
  );
};

const loadCounters = () => {
  return JSON.parse(sessionStorage.getItem("perf-counters") || "{}");
};

const saveCounters = (values) => {
  sessionStorage.setItem("perf-counters", JSON.stringify(values));
};

const CounterHeader = ({ counterKey }) => {
  const title = toTitleCase(counterKey);
  return (
    <>
      <th style={cellStyle}>{`Total ${title}`}</th>
      <th style={cellStyle}>{`Avg ${title}`}</th>
      <th style={cellStyle}>{`${title} Count`}</th>
    </>
  );
};

const CounterValue = ({ counter }) => {
  const avg = counter.count === 0 ? 0 : counter.value / counter.count;
  const unit = counter.unit || "";
  return (
    <>
      <td style={cellStyle}>{`${Math.round(counter.value).toLocaleString()} ${unit}`}</td>
      <td style={cellStyle}>{`${Math.round(avg).toLocaleString()} ${unit}`}</td>
      <td style={cellStyle}>{counter.count.toLocaleString()}</td>
    </>
  );
};

const hasPerformanceObserver = typeof window.PerformanceObserver === "function";

const PerfMonitor = ({ measures = [] }) => {
  // This will happen in environments where PerformanceObserver is not available
  // ie IE... not worth the effort in approximating, so we'll display nothing
  const [visible, setVisible] = React.useState(hasPerformanceObserver);
  const [counters, updateCounters] = React.useState(loadCounters);
  const measuresRef = React.useRef(measures);
  const keys = Object.keys(counters);

  React.useEffect(() => {
    measuresRef.current = measures;
  });

  React.useEffect(() => {
    if (!visible) {
      return undefined;
    }

    const processCounters = (entries) => {
      updateCounters((values) => {
        entries.forEach((entry) => {
          switch (entry.entryType) {
            case "measure":
              if (measuresRef.current.includes(entry.name)) {
                updateCounter(values, { key: entry.name, value: entry.duration, unit: "ms" });
              }
              break;
            default:
              updateCounter(values, { key: "transferSize", value: entry.transferSize, unit: "kb" });
              updateCounter(values, { key: entry.entryType, value: entry.duration, unit: "ms" });
              break;
          }
        });

        // We're creating a new object to trigger a render
        return { ...values };
      });
    };

    const entryTypes = ["navigation", "resource", "measure"];

    // load entries already in buffer - we will have to dedupe them later
    const buffer = [].concat(...entryTypes.map((entryType) => performance.getEntriesByType(entryType)));

    const observer = new window.PerformanceObserver((list) => {
      const entries = Array.from(list.getEntries());
      if (buffer.length > 0) {
        entries.push(...buffer.filter((entry) => !entries.includes(entry)));
        buffer.length = 0;
      }

      processCounters(entries);
    });

    observer.observe({ entryTypes });
    return () => observer.disconnect();
  }, [visible]);

  React.useEffect(() => {
    // Whenever counters change, persist in session so they can accumulate over time
    saveCounters(counters);
  }, [counters]);

  if (!visible) {
    return null;
  }

  return (
    <div style={sectionStyle}>
      <table style={tableStyle}>
        <thead>
          <tr>
            {keys.map((key) => (
              <CounterHeader key={key} counterKey={key} />
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {keys.map((key) => (
              <CounterValue key={key} counter={counters[key]} />
            ))}
          </tr>
        </tbody>
      </table>
      <div style={{ float: "right" }}>
        <button type="button" onClick={() => updateCounters(resetCounters)}>
          Reset
        </button>
        <button type="button" onClick={() => setVisible(false)}>
          Close
        </button>
      </div>
    </div>
  );
};

if (features.measure) {
  let rootNode = document.getElementById("__PERFMON");
  if (!rootNode) {
    rootNode = document.createElement("section");
    rootNode.id = "__PERFMON";
    document.body.appendChild(rootNode);
  }

  ReactDOM.render(<PerfMonitor measures={["page ready"]} />, rootNode);
}
