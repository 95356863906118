const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");
const env = require("../../Core/plex-env");

const documentProviders = plexImport("printing.documentProviders");

const DocumentProviderFactory = {
  create: function (item, ctrl) {
    if (!(item.documentProvider in documentProviders)) {
      throw new Error("No handler for the given document provider: " + item.documentProvider);
    }

    item.parent = item.parent || ctrl;
    let providerName = item.documentProvider;

    // TODO: remove feature flag once async doc services are stable and performance
    if (providerName === "PrintDocumentProvider" && env.features["async-print"]) {
      providerName = "PrintDocumentProviderAsync";
    }

    const provider = new documentProviders[providerName]();

    provider.init(item);
    return provider;
  }
};

module.exports = DocumentProviderFactory;
plexExport("printing.documentProviders.DocumentProviderFactory", DocumentProviderFactory);
