const Globalize = require("globalize");
const js = require("../Utilities/plex-utils-js");
const dataUtils = require("../Utilities/plex-utils-data");
const glossary = require("./plex-glossary-handler");
const mathUtils = require("../Utilities/plex-utils-math");
const stringUtils = require("../Utilities/plex-utils-strings");
const regexUtils = require("../Utilities/plex-utils-regex");
const plexExport = require("../../global-export");

// need to declare this above overrides to deal with circular reference
const api = (module.exports = {});

// make sure this runs first
require("./plex-globalize-overrides"); // eslint-disable-line import/no-unassigned-import

// these are late-bound
let UNIT_MISMATCH_TEXT, DIGIT_PATTERN, SYMBOL_MAP;
const DEFAULT_SCALE = 2;
const DEFAULT_FORMAT = "n";
const CURRENCY_SYMBOL_PLACEHOLDER = "\u00A4";
const CURRENCY_SYMBOL_PATTERN = /\u00A4/g;

// Globalize will throw if scale is higher
const MAX_SCALE = 20;

const dotNetFormats = ["n", "d", "c", "p"];
const dotNetMatcher = new RegExp(`^(${dotNetFormats.join("|")})(\\d*)$`, "i");

const numericFormats = {
  number: "n",
  integer: "d",
  currency: "c",
  percent: "p"
};

const currencyHtmlEntities = {
  "&cent;": "&#162;",
  "&pound;": "&#163;",
  "&curren;": "&#164;",
  "&yen;": "&#165;",
  "&euro;": "&#8364;"
};

// Of course there are many more symbols, but we're assuming the
// only symbols we need to deal with are currency related.
const currencyHtmlEntitiesToSymbols = {
  "&cent;": "Â¢",
  "&pound;": "Â£",
  "&curren;": "Â¤",
  "&yen;": "Â¥",
  "&euro;": "â¬"
};

const symbolKeywords = ["decimal", "group", "percentSign", "plusSign", "minusSign"];
const symbolReverseMap = {
  ".": "decimal",
  ",": "group",
  "%": "percentSign",
  "+": "plusSign",
  "-": "minusSign",
  ":": "timeSeparator"
};

// try/catch here for test purposes only
try {
  // prefetch this term so it can be syncronously used when formatting.
  // todo: this should be supplied from the server, probably as a property of the formatter
  glossary
    .getCustomerWordAsync("Not available. Multiple units of measure.")
    .then((text) => (UNIT_MISMATCH_TEXT = text));
} catch (err) {
  UNIT_MISMATCH_TEXT = "Not available. Multiple units of measure.";
}

const isNumeric = (value) => {
  // this is a copy of the $.isNumeric function which has been deprecated - this is just meant to
  // retain current behavior.
  const type = typeof value;
  return (type === "string" || type === "number") && !isNaN(value - parseFloat(value));
};

const getFormatter = js.memoize((options) => {
  return Globalize.numberFormatter(options);
});

const getParser = js.memoize((options) => {
  return Globalize.numberParser(options);
});

const isNumericFormat = (config) => config.format === numericFormats.number;
const isIntegerFormat = (config) => config.format === numericFormats.integer;
const isCurrencyFormat = (config) => config.format === numericFormats.currency;
const isPercentFormat = (config) => config.format === numericFormats.percent;

const getScaleOrDefault = (scale) => {
  return isNumeric(scale) && scale >= 0 ? scale : DEFAULT_SCALE;
};

const getNumericFormatString = js.deprecate(function (config, scaleOrEmpty) {
  const format = config.format || DEFAULT_FORMAT;
  let scale = getScaleOrDefault(arguments.length === 1 ? config.scale : scaleOrEmpty);

  if (isPercentFormat(config)) {
    if (scale >= 2) {
      scale -= 2;
    } else if (scale === 1) {
      // Raw decimals have 0's cut off at end. When this happens, naturalScale gets a value of 1, and
      // our config objects scale gets set to 1 as well. This will catch those end cases.
      // 10 -> 0.10 -> 0.1
      scale = 0;
    }
  }

  return format + scale.toString();
}, "`getNumericFormatString` is deprecated - use individual options to format a string.");

const rescaleCldrString = (pattern, valueScale, displayTrailingZeroes) => {
  const digitPlaceholder = displayTrailingZeroes ? "0" : "#";
  const decimalDigits = valueScale > 0 ? "." + stringUtils.repeat(valueScale, digitPlaceholder) : "";
  return pattern
    .split(";")
    .map((pt) => pt.replace(/\.(#+|0*\d|0+\d?)/g, decimalDigits))
    .join(";");
};

const rescaleIntegerPattern = (pattern, valueScale, numberGroupSeparator) => {
  let i = pattern.length;
  let s = valueScale;
  let scaledPattern = "";
  let groupCnt;

  while (--i >= 0) {
    switch (pattern[i]) {
      case "0":
      case "#":
        scaledPattern = (s > 0 ? "0" : pattern[i]) + scaledPattern;
        s--;

        if (groupCnt != null) {
          groupCnt++;
        }

        break;
      case numberGroupSeparator:
        scaledPattern = numberGroupSeparator + scaledPattern;
        groupCnt = 0;
        break;
      default:
        scaledPattern = pattern[i] + scaledPattern;
        break;
    }
  }

  while (s > 0) {
    // TODO: need to take group sizes into consideration.
    if (groupCnt === 3) {
      scaledPattern = numberGroupSeparator + scaledPattern;
      groupCnt = 0;
    }

    scaledPattern = "0" + scaledPattern;
    s--;
    groupCnt++;
  }

  return scaledPattern;
};

const getIntegerPattern = (pattern, scale, numberGroupSeparator) => {
  return pattern
    .split(";")
    .map((pt) => {
      return scale > 0
        ? rescaleIntegerPattern(pt.replace(/\.(#+|0*\d|0+\d?)/g, ""), scale, numberGroupSeparator)
        : pt.replace(/\.(#+|0*\d|0+\d?)/g, "");
    })
    .join(";");
};

const deriveConfigFromString = (configString) => {
  let scale, format;

  const dotNetMatch = dotNetMatcher.exec(configString);
  if (dotNetMatch) {
    let scaleStringValue;
    [, format, scaleStringValue] = dotNetMatch;
    scale = parseInt(scaleStringValue, 10);
    if (!scale) {
      scale = format === "d" ? 0 : DEFAULT_SCALE;
    }
    format = format.toLowerCase();
  } else {
    format = configString;
  }

  return { scale, format };
};

const getCldrNumericFormat = (configOrString = { format: DEFAULT_FORMAT, scale: DEFAULT_SCALE }) => {
  let config = configOrString;
  if (typeof config === "string") {
    config = deriveConfigFromString(config);
  }

  let scale, displayTrailingZeroes, format;
  ({ scale, displayTrailingZeroes, format } = config);
  format = format?.toLowerCase();

  const cldr = Globalize.locale();
  const numberingSystem = cldr.main("numbers/defaultNumberingSystem");

  let pattern;
  switch (format) {
    case "c":
      pattern = cldr.main(["numbers/currencyFormats-numberSystem-" + numberingSystem, "accounting"]);
      displayTrailingZeroes = displayTrailingZeroes == null || displayTrailingZeroes;
      break;

    case "p":
      pattern = cldr.main(["numbers/percentFormats-numberSystem-" + numberingSystem, "standard"]);
      break;

    case "d":
    case "n":
      pattern = cldr.main(["numbers/decimalFormats-numberSystem-" + numberingSystem, "standard"]);

      if (format === "d") {
        const numberGroupSeparator = cldr.main(["numbers/symbols-numberSystem-" + numberingSystem, "group"]);
        pattern = getIntegerPattern(pattern, scale, numberGroupSeparator);
        scale = 0;
      }

      break;

    default:
      return cldr.main(["numbers/currencyFormats-numberSystem-" + numberingSystem, format]);
  }

  pattern = rescaleCldrString(pattern, getScaleOrDefault(scale), displayTrailingZeroes);

  // add a default negative pattern if not contained within string
  if (pattern.indexOf(";") === -1) {
    pattern += ";-" + pattern;
  }

  return pattern;
};

const globalizeOptionBuilder = {
  c: (config) => {
    let currencyPattern = getCldrNumericFormat({ ...config, scale: getScaleOrDefault(config.scale) });
    let currencySymbol = config.currencySymbol?.symbolText || "";

    // $ has special meaning in js replace function, so need to double it to escape it
    if (currencySymbol.indexOf("$") >= 0) {
      currencySymbol = currencySymbol.replace(/\$/g, "$$$$");
    }

    // HACK: decode symbol to fix IP-5518
    // monitor https://github.com/globalizejs/globalize/issues/761 for appropriate fix
    // First replace named html entities
    currencySymbol = Object.keys(currencyHtmlEntitiesToSymbols).reduce((value, entity) => {
      const entityPattern = new RegExp(entity, "g");
      return value.replace(entityPattern, currencyHtmlEntitiesToSymbols[entity]);
    }, currencySymbol);

    // Then replace symbols by charcode value
    currencySymbol = currencySymbol.replace(/&#(\d{1,3});/g, (_, numStr) => {
      const num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
    // ENDHACK

    // replace placeholder "Â¤" (\u00A4) with escaped currency symbol (escape symbols using single quotes in Globalize)
    currencyPattern = currencyPattern.replace(CURRENCY_SYMBOL_PATTERN, currencySymbol ? `'${currencySymbol}'` : "");

    return {
      raw: currencyPattern,
      round: config.truncateToScale ? "truncate" : "round"
    };
  },
  d: (config) => {
    return {
      minimumIntegerDigits: config.scale || undefined,
      maximumFractionDigits: 0,
      useGrouping: false,

      // note - this seems a bit questionable, but matches the previous behavior
      round: "truncate"
    };
  },
  n: (config) => {
    const valueScale = getScaleOrDefault(config.scale);
    return {
      minimumFractionDigits: (config.displayTrailingZeroes && valueScale) || 0,
      maximumFractionDigits: valueScale,
      round: config.truncateToScale ? "truncate" : "round"
    };
  },
  p: (config) => {
    const options = globalizeOptionBuilder.n(config);
    options.style = "percent";
    return options;
  }
};

const numericFormatter = js.memoize((value, config) => {
  let numberValue = Number(value);

  const format = (config.format || "n").toLowerCase();
  if (!(format in globalizeOptionBuilder)) {
    throw Error(`Unknown numeric format type "${config.format}"`);
  }

  if (config.precision) {
    numberValue = mathUtils.floorOrCeilIntegerPart(numberValue, config.precision, getScaleOrDefault(config.scale));
  }

  const formatOptions = globalizeOptionBuilder[format](config);

  if (formatOptions.maximumFractionDigits) {
    formatOptions.maximumFractionDigits = Math.min(formatOptions.maximumFractionDigits, MAX_SCALE);
  }

  if (formatOptions.minimumFractionDigits) {
    formatOptions.minimumFractionDigits = Math.min(formatOptions.minimumFractionDigits, MAX_SCALE);
  }

  let unitText = "";
  if (config.unit) {
    unitText = " " + config.unit.unitText;
    if (config.unit.exponent && config.unit.exponent > 1) {
      unitText += `<sup>${config.unit.exponent}</sup>`;
    }
  }

  return getFormatter(formatOptions)(numberValue) + unitText;
});

const getSymbol = (keyword) => {
  const resolvedKeyword = symbolReverseMap[keyword] || keyword;

  if (!SYMBOL_MAP) {
    const cldr = Globalize.locale();
    const numberingSystem = cldr.main("numbers/defaultNumberingSystem");
    SYMBOL_MAP = cldr.main("numbers/symbols-numberSystem-" + numberingSystem);
  }

  return SYMBOL_MAP[resolvedKeyword];
};

const compileDigitRegex = () => {
  const symbolPatternSegment = symbolKeywords.map(getSymbol).filter(Boolean).join("");
  return new RegExp(`[0-9${regexUtils.escapeText(symbolPatternSegment)}]`);
};

const normalizeNumericString = (value) => {
  if (!value) {
    return value;
  }

  const symbolsToKeep = [".", "+", "-"].map(getSymbol).map(regexUtils.escapeText);
  const pattern = new RegExp("[^\\d" + symbolsToKeep.join("") + "]", "g");
  return String(value).replace(pattern, "");
};

const getConfigWithBoundOverrides = (config, record, trackClientUpdates) => {
  // copy config object so we don't change the original
  const copy = { ...config };

  if (copy.scalePropertyName) {
    copy.scale = api.getBoundScale(copy, record, trackClientUpdates);
  }

  if (copy.currencySymbol?.propertyName) {
    copy.currencySymbol.symbolText = api.getBoundCurrencySymbol(copy.currencySymbol, record, trackClientUpdates);
  }

  if (copy.unit?.propertyName) {
    copy.unit.unitText = api.getBoundUnit(copy.unit, record, trackClientUpdates);
  }

  return copy;
};

/**
 * Determines whether the given character can be used within a numeric value. This can only be used
 * to validate a single digit.
 *
 * @param {String} value - the digit to analyze.
 * @returns {Boolean} true of the value is a valid numeric digit; false otherwise.
 */
api.isValidNumericDigit = (value) => {
  const stringValue = String(value);
  if (!stringValue) {
    return false;
  }

  if (stringValue.length > 1) {
    throw Error(
      "`isValidNumericDigit` only whether a single digit can be contained within a numeric expression - it does not ensure the validity of an entire expression."
    );
  }

  DIGIT_PATTERN = DIGIT_PATTERN || compileDigitRegex();
  return DIGIT_PATTERN.test(stringValue);
};

/**
 * Gets the CLDR format string from the given format object.
 *
 * @param {String|Object} config - The .Net format string or UX format config object.
 * @returns {String} The CLDR format string.
 */
api.getCldrNumericFormat = getCldrNumericFormat;

/**
 * Gets the culture specific symbol based on the current culture.
 *
 * @param {String} keyword - The keyword to lookup. Can either be the CLDR keyword or the English representative symbol.
 * @returns {String|undefined} The resolved symbol, or undefined if the symbol is not found.
 */
api.getSymbol = getSymbol;

/**
 * Parses a string and returns the parsed numeric value. Will return NaN if the value cannot be parsed.
 *
 * @param {String} value - The value to be parsed.
 * @param {Object} [options] - The options to be used for parsing. Can contain a:
 *    `format`: format string to define the expected numeric format
 *    `strict`: if false, the text value will have numeric values removed before parsing
 * @returns {Number} The parsed numeric value.
 */
api.parseNumber = (value, options) => {
  let parseOptions;
  let stringValue = value;

  if (options && options.format in globalizeOptionBuilder) {
    parseOptions = globalizeOptionBuilder[options.format](options);
  } else if (!options?.strict) {
    stringValue = normalizeNumericString(value);
  }

  stringValue = String(stringValue).trim();

  let multiplier = 1;
  if (stringValue[0] === "-") {
    // hack: Globalize is far too strict with parsing
    // if the number format is expecting parentheses
    // to represent negative numbers the negative number
    // will cause the parsing to fail.
    // see IP-5535
    stringValue = stringValue.substring(1);
    multiplier = -1;
  }

  return getParser(parseOptions)(stringValue) * multiplier;
};

/**
 * @see api.formatNumber
 */
api.format = (...args) => {
  return api.formatNumber(...args);
};

/**
 * Formats the given numeric value into a culture specific string.
 *
 * @param {Number} value - The value to format.
 * @param {String|Object} configOrFormat - The .Net format string, CLDR format string, or UX format config object.
 * @param {Object} [record] - The associated record. This is necessary if the formatting is dependent on properties within the record.
 * @param {Boolean} [trackClientUpdates=false] - Determines whether dependencies are registered during formatting.
 * @returns {String} The formatted string.
 */
api.formatNumber = (value, configOrFormat, record, trackClientUpdates) => {
  let config = configOrFormat;
  if (!config || !isNumeric(value)) {
    return value == null ? "" : String(value);
  }

  if (typeof config === "string") {
    if (dotNetMatcher.test(config)) {
      config = getCldrNumericFormat(config);
    }

    return getFormatter({ raw: config })(value);
  }

  // handle any bound properties first
  // this will allow us to memoize the formatting results
  if (api.hasBoundProperties(config)) {
    config = getConfigWithBoundOverrides(config, record, trackClientUpdates);

    // this indicates a unit mismatch
    if (config.unit?.unitText === false) {
      return UNIT_MISMATCH_TEXT;
    }
  }

  return numericFormatter(value, config);
};

api.hasBoundProperties = (config) => {
  return !!(config.scalePropertyName || config.unit?.propertyName || config.currencySymbol?.propertyName);
};

api.getBoundScale = (config, recordOrArray, trackClientUpdates) => {
  const propertyName = config.scalePropertyName;
  let record = recordOrArray;

  // if a scale expression was specified use that for the value.
  if (Array.isArray(record)) {
    record = record[0];
  }

  if (record) {
    const scaleValue = dataUtils.getValue(record, propertyName, !!trackClientUpdates);
    if (Array.isArray(scaleValue)) {
      return scaleValue.length > 0 ? scaleValue[0] : null;
    } else {
      return scaleValue;
    }
  }

  // This is sometimes null and if we change to default value now
  // it will modify existing behavior/expectations
  return config.scale;
};

api.getCurrencySymbol = (config, record, trackClientUpdates) => {
  if (!config.currencySymbol) {
    return "";
  }

  if (config.currencySymbol.symbolText) {
    return config.currencySymbol.symbolText;
  }

  if (config.currencySymbol.propertyName) {
    return api.getBoundCurrencySymbol(config.currencySymbol, record, trackClientUpdates);
  }

  return "";
};

api.getBoundCurrencySymbol = (config, recordOrArray, trackClientUpdates) => {
  let record = recordOrArray;

  if (Array.isArray(record)) {
    record = record[0];
  }

  const currencySymbol = dataUtils.getValue(record, config.propertyName, !!trackClientUpdates);

  // replace named html code with numeric html entity if the association exists.
  // it's necessary because of "n" symbol in html entity that breaks number format in globalize.js
  return currencyHtmlEntities[currencySymbol] || currencySymbol;
};

api.getUnit = (config, record, trackClientUpdates) => {
  if (!config) {
    return "";
  }

  if (config.propertyName) {
    return api.getBoundUnit(config, record, trackClientUpdates);
  }

  return config.unitText || "";
};

api.getBoundUnit = (config, record, trackClientUpdates) => {
  if (Array.isArray(record)) {
    let lastAggregateUnit;
    let aggregateUnit = "";

    for (let i = 0; i < record.length; i++) {
      aggregateUnit = dataUtils.getValue(record[i], config.propertyName, !!trackClientUpdates);
      if (i > 0 && aggregateUnit !== lastAggregateUnit) {
        // units differ so no unit should be displayed
        return false;
      }

      lastAggregateUnit = aggregateUnit;
    }

    return aggregateUnit;
  }

  return dataUtils.getValue(record, config.propertyName, !!trackClientUpdates);
};

api.getScale = (config, record, trackClientUpdates) => {
  // This is sometimes null and if we change to default value now
  // it will modify existing behavior/expectations
  let scale = config.scale;

  if (config.scalePropertyName) {
    scale = api.getBoundScale(config, record, trackClientUpdates);
  }

  if (isNumeric(scale) && api.isPercentFormat(config)) {
    return scale + 2;
  }

  return scale;
};

api.getScaleOrDefault = (config) => getScaleOrDefault(config?.scale);
api.getFormat = getNumericFormatString;
api.getUnitSuperscript = (config) => {
  return config.exponent && config.exponent > 1 ? `<sup>${config.exponent}</sup>` : "";
};
api.isNumericFormat = isNumericFormat;
api.isIntegerFormat = isIntegerFormat;
api.isCurrencyFormat = isCurrencyFormat;
api.isPercentFormat = isPercentFormat;
api.numericFormats = numericFormats;

/**
 * The currency symbol placeholder used within CLDR format strings.
 * @member {String}
 */
api.CURRENCY_SYMBOL_PLACEHOLDER = CURRENCY_SYMBOL_PLACEHOLDER;

// let's only expose what we need to in this namespace
plexExport("culture.formatNumber", api.formatNumber);
plexExport("culture.parseNumber", api.parseNumber);
plexExport("culture.getSymbol", getSymbol);
