ï»¿const ko = require("knockout");
const DefaultValueProvider = require("./plex-grid-value-providers");
const expressions = require("../../Expressions/plex-expressions-compiler");
const dataUtils = require("../../Utilities/plex-utils-data");
const stringUtils = require("../../Utilities/plex-utils-strings");
const plexExport = require("../../../global-export");

const HtmlColumnValueProvider = DefaultValueProvider.extend({
  oninit: function () {
    if (this.column.valueExpression) {
      this.valueEvaluator = expressions.compile(this.column.valueExpression);
    }
  },

  getValue: function (record, index) {
    if (this.valueEvaluator) {
      return this.valueEvaluator(record, index);
    }

    return dataUtils.getValue(record, this.column.propertyName, this.config.trackClientUpdates);
  },

  getHtml: function (_record, _index, _colIndex, _features) {
    if (this.config.trackClientUpdates) {
      return this.getTrackedHtml.apply(this, arguments);
    }

    return stringUtils.unescapeHtml(this.getFormattedValue.apply(this, arguments));
  },

  getTrackedHtml: function (record, _index, _colIndex, _features) {
    const self = this;
    const args = arguments;
    const value = ko.computed(() => {
      return stringUtils.unescapeHtml(self.getValue.apply(self, args));
    });

    return ko.renderTemplate(this.templateName, { value, formatter: this.column.formatter, data: record });
  }
});

module.exports = HtmlColumnValueProvider;
plexExport("grid.valueProviders.HtmlColumnValueProvider", HtmlColumnValueProvider);
