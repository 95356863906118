const $ = require("jquery");
const ko = require("knockout");
const regexUtils = require("../Utilities/plex-utils-regex");
const DataSource = require("./plex-datasource");
const plexExport = require("../../global-export");
const jsUtils = require("../Utilities/plex-utils-js");

function createMatcher(param) {
  // create cached matcher object
  const matcher = {};
  $.each(param, (prop) => {
    const value = param[prop];

    // ignore null, undefined, empty strings
    if (value == null || value === "") {
      return;
    }

    if (typeof value === "string") {
      // create a regex to match the string
      // this is roughly equivalent to a LIKE searchText + '%' match in SQL
      matcher[prop] = new RegExp("^" + regexUtils.escapeText(param[prop]), "i");
    } else {
      matcher[prop] = value;
    }
  });

  if ($.isEmptyObject(param)) {
    return function () {
      return true;
    };
  }

  return function (record) {
    let prop, value;
    for (prop in matcher) {
      if (prop in record) {
        value = ko.utils.peekObservable(record[prop]);
        if (matcher[prop] instanceof RegExp) {
          if (!matcher[prop].test(value)) {
            return false;
          }
        } else if (value !== matcher[prop]) {
          // use coercive check here just in case the types don't match
          return false;
        }
      }
    }

    return true;
  };
}

const LocalDataSource = DataSource.extend({
  get: function (params) {
    // note: we are only used a deferred here so the api matches the remote datasource
    const self = this;
    const deferred = new $.Deferred();
    if ((!params || $.isEmptyObject(params)) && this.request.params.length === 0) {
      this.clearFilter();
      deferred.resolve(this.raw);

      return deferred.promise();
    } else {
      // use the provided data object to filter the source
      if (this.request.params.length > 0) {
        params = this.request.build(params);
      }

      // value tokens could result in a promise being returned.
      if (jsUtils.isPromise(params)) {
        return params.then((r) => {
          self._filterSource(r);

          return self.source();
        });
      } else {
        this._filterSource(params);
        deferred.resolve(this.source());

        return deferred.promise();
      }
    }
  },

  _filterSource: function (params) {
    this.source(this.raw.filter(createMatcher(params)));
  },

  reset: function () {
    // keep raw data
    this.source([]);
    this.recordCount(0);
  }
});

module.exports = LocalDataSource;
plexExport("LocalDataSource", LocalDataSource);
