const $ = require("jquery");
const Cache = require("../Core/plex-cache");
const nav = require("../Core/plex-navigate");
const DataResult = require("./plex-data-result");
const DataSource = require("./plex-datasource");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const slice = Array.prototype.slice;
const responseCache = new Cache();

// default to 10-seconds - the purpose isn't so much for caching results
// but from preventing multiple equivalent requests from firing in succession.
// at some point we may want to make this configurable.
const RESPONSE_CACHE_TIMEOUT = 10 * 1000;

const getDefaults = {
  ignoreEmptyData: false,
  cacheResponse: true
};

const RemoteDataSource = DataSource.extend({
  init: function (options) {
    this.url = options.url;

    // note: there can be multiple data sources that use the same url (for example, dropdowns in grids)
    // that is why we are looking for an existing cache - this will prevent a request firing off for *each* row
    // (unless the parameters really are different)
    this.cache = responseCache.get(this.url) || responseCache.set(this.url, new Cache());
    this._base.apply(this, arguments);
  },

  get: function (data, options) {
    options = $.extend({}, getDefaults, this.options, options);
    this.reset();
    this.isLoading(true);

    const request = this.request.build(data);
    return this._get(request, options);
  },

  _get: function (request, options) {
    if (jsUtils.isPromise(request)) {
      return request.then((req) => this._get(req, options));
    }

    const self = this;
    const deferred = new $.Deferred();
    const promise = deferred.promise();

    if (options.additionalParams) {
      $.extend(request, options.additionalParams);
    }

    if (this.request.isValid(request)) {
      const cacheKey = JSON.stringify(request);
      let cached = false;
      let response;

      if (this.cache.has(cacheKey) && options.cacheResponse) {
        cached = true;
        response = this.cache.get(cacheKey);
      }

      const url = nav.buildUrl(this.url, request, options);
      response = response || $.getJSON(url);
      response
        .then((results) => {
          const dataResult = new DataResult(results, request);

          self.recordCount(dataResult.recordCount);

          // create a new copy of the array if it is cached
          self.load(cached ? slice.call(dataResult.data) : dataResult.data, false, dataResult.outputParameters);
          deferred.resolveWith(promise, options.resolveRows === false ? [results] : [dataResult.data]);
        })
        .fail(() => {
          self.isLoading(false);
          deferred.rejectWith(promise);
        });

      // tack on an abort function so the underlying ajax request can be halted.
      promise.abort = response.abort;
      if (!cached) {
        this.cache.set(cacheKey, response, Cache.slidingExpiration(RESPONSE_CACHE_TIMEOUT));
      }
    } else {
      deferred.rejectWith(promise);
    }

    return promise;
  }
});

module.exports = RemoteDataSource;
plexExport("RemoteDataSource", RemoteDataSource);
