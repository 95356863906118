ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const elementHandler = require("./plex-handler-element");
const actionHandler = require("./plex-handler-action");
const bindingHandler = require("./plex-handler-bindings");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const controllerFactory = require("./plex-controller-factory");
const utils = require("../Utilities/plex-utils-data");
const domUtils = require("../Utilities/plex-utils-dom");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");

// todo: restore state
function match(data, selected, config) {
  const propertyName = config.valuePropertyName || config.propertyName;

  // note: since these are javascript objects the instances of selected objects will
  // not be the same instance. We need to find that instance in the data collection
  // and include it in the selected array
  return selected.some((b) => {
    return data[propertyName] === b[propertyName];
  });
}

const ListViewController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

ListViewController.prototype = {
  constructor: ListViewController,

  init: function () {
    const self = this;

    // make commonly used properties easier to access
    self.bindings = self.config.bindings;
    self.selectAction = self.config.selectAction;
    self._disposables = [];
    self.dataSource = self.config.dataSource;
    self.selected = self.config.selected || ko.observableArray();
    self.multiSelect = self.config.multiSelect || false;
    self.$element = $(document.getElementById(self.config.id));
    self.elements = {};
    self.elementRows = ko.observableArray();
    self.defaultColumnCount = self.config.defaultColumnCount;
    self.enableDynamicColumns = self.config.enableDynamicColumns || false;
    self.canResizeColumns = self.enableDynamicColumns && domUtils.isInDialog(self.$element);
    self.columnCount = ko.observable(self.defaultColumnCount);

    self.setupDataSource();
    self.reset();

    if (self.canResizeColumns) {
      self.$element.closest(".ui-resizable").on("resizeCompleted", self._calculateColumns.bind(this));
    }

    self.cellCount = ko.pureComputed(() => {
      if (self.config.alignment === "vertical") {
        return 1;
      }

      const data = self.data();
      let columnCount = self.columnCount();
      if (data) {
        // if there isn't enough to create a full row
        // let the first row expand to full width
        columnCount = Math.min(data.length, columnCount);
      }

      return Math.max(columnCount, 1);
    });

    self.listItemWidth = ko.pureComputed(() => {
      // offset 10px width for margin
      return `calc((100% / ${self.cellCount()}) - 10px)`;
    });

    if (self.selectAction) {
      actionHandler.initAction(self.selectAction, this);
    }

    if (self.bindings && self.bindings.length > 0) {
      self._disposables.push(
        self.selected.subscribe((items) => {
          bindingHandler.update(self.config.bindings, items && items.length > 0 ? items : null);
        })
      );
    }
  },

  setupDataSource: function () {
    const self = this;

    if (!self.dataSource) {
      return;
    }

    const ds = self.dataSource.route || self.dataSource;

    self.datasource = dataSourceFactory.create(ds);
    self.data = self.datasource.data;
  },

  reset: function () {
    const self = this;

    self.disposeElements();
    self.createElements(self.data());
  },

  createElements: function (items) {
    const self = this;

    if (!items || items.length === 0) {
      return;
    }

    let alreadySelected = false;
    const itemsToAdd = [];

    items.forEach((item, _index) => {
      const clone = elementHandler.cloneElement(self.config.templateElement, "-listview-item");
      let selected = false;
      elementHandler.initElement(clone, item, null, self);
      utils.trackObject(item);

      if (match(item, self.selected(), self.config)) {
        if (self.multiSelect) {
          selected = true;
        } else if (alreadySelected === false && !self.multiSelect) {
          selected = true;
          alreadySelected = true;
        }
      }

      itemsToAdd.push({ data: item, options: clone, selected: ko.observable(selected) });
    });

    self.elementRows(self.elementRows().concat(itemsToAdd));

    self.selected(
      ko.utils.unwrapObservable(self.elementRows).filter((row) => {
        return ko.unwrap(row.selected) === true;
      })
    );
  },

  toggleSelection: function (row) {
    const self = this;

    if (self.multiSelect === false) {
      // remove all selected items
      self.selected.removeAll();
      self.elementRows.forEach((elementRow) => {
        if (row !== elementRow) {
          elementRow.selected(false);
        }
      });
    }

    // toggle selected for CSS
    row.selected(!row.selected());

    if (row.selected()) {
      // row selected. push to selected array
      self.selected.push(row.data);
    } else {
      // deselected row. pop off selected array
      self.selected.pop(row.data);
    }

    // let world know when we've changed our selected items
    // this is particularly important for the actionbar
    pubsub.publish("selected.listView." + self.config.id, self.selected() || null);

    if (self.config.selectAction && row.selected()) {
      actionHandler.executeAction(self.config.selectAction, row.data);
    }

    return true;
  },

  disposeElements: function () {
    this.elementRows.removeAll();
    for (const el in this.elements) {
      if (Object.prototype.hasOwnProperty.call(this.elements[el], "controller")) {
        this.elements[el].controller.dispose?.();
      }
    }

    Object.keys(this.elements).forEach((el) => {
      delete this.elements[el];
    });
  },

  _calculateColumns: function () {
    // todo: what does this 70 represent!?
    const modalWidth = this.$element.parents().closest(".modal-dialog").width() - 70;
    if (!$.isNumeric(this.columnWidth)) {
      this.columnWidth = this.$element.find(".plex-listview-container").width() / this.columnCount();
    }

    const cells = Math.floor(modalWidth / this.columnWidth);
    if (cells === this.columnCount()) {
      return;
    }

    this.columnCount($.isNumeric(cells) ? cells : this.defaultColumnCount);
  }
};

ListViewController.create = function (config, model) {
  return new ListViewController(config, model);
};

controllerFactory.register("Elements/_ListView", ListViewController);

module.exports = ListViewController;
plexExport("ListViewController", ListViewController);
