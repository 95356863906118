ï»¿const dataUtils = require("../../Utilities/plex-utils-data");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const HelpKeyValueProvider = defaultValueProvider.extend({
  getValue: function (record) {
    const key = dataUtils.getValue(record, this.column.propertyName, this.config.trackClientUpdates);
    return key;
  }
});

module.exports = HelpKeyValueProvider;
plexExport("grid.valueProviders.HelpKeyValueProvider", HelpKeyValueProvider);
