ï»¿const ko = require("knockout");
const DefaultXmlProvider = require("./plex-printing-xml-provider");
const plexExport = require("../../../global-export");

const TextXmlProvider = DefaultXmlProvider.extend({
  onGetXml: function () {
    this.node.setValue(ko.unwrap(this.text));
  }
});

module.exports = TextXmlProvider;

plexExport("printing.xmlProviders.link", TextXmlProvider);
plexExport("printing.xmlProviders.label", TextXmlProvider);
