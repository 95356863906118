ï»¿const ko = require("knockout");
const $ = require("jquery");

const PRIOR_CSS_ATTRIBUTE = "__ko_binding_className_priorCss";

ko.bindingHandlers.className = {
  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor()) || "";
    const $el = $(element);
    const priorClass = $el.data(PRIOR_CSS_ATTRIBUTE) || "";
    const priorClasses = priorClass.split(/\s+/g);
    const classesToAdd = value.split(/\s+/g);
    const classesToRemove = [];

    if (priorClasses.length > 0) {
      // check if any of the classes used in the update were removed
      // and make sure they get removed now
      priorClasses.forEach((css) => {
        if (classesToAdd.indexOf(css) === -1) {
          classesToRemove.push(css);
        }
      });
    }

    if (classesToRemove.length > 0) {
      $el.removeClass(classesToRemove.join(" "));
    }

    if (classesToAdd.length > 0) {
      $el.addClass(classesToAdd.join(" "));
    }

    $el.data(PRIOR_CSS_ATTRIBUTE, value);
  }
};
