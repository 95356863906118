ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

ko.bindingHandlers.valueChange = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    viewModel = bindingContext.$data.config || bindingContext.$data;

    const subscriptions = [];
    let record = null;
    let parent = bindingContext.$root || viewModel.parent;

    while (!record && parent) {
      record = parent.record || parent.data;
      parent = parent.parent;
    }

    const property = viewModel.propertyName || element.name;
    if (property && record) {
      const props = [property];
      if (viewModel.secondaryPropertyName) {
        props.push(viewModel.secondaryPropertyName);
      }

      const bindings = allBindingsAccessor();
      props.forEach((prop) => {
        let observable = dataUtils.getObservable(record, prop);
        if (!observable) {
          record[prop] = observable = ko.observable(record[prop]);
          dataUtils.trackProperty(record, prop);
        }

        observable.subscribe((value) => bindings.valueChange.call(viewModel, value, element));
      });

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        let sub;
        while ((sub = subscriptions.pop())) {
          sub.dispose();
        }
      });
    }
  }
};
