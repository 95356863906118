ï»¿const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../../Utilities/plex-utils-data");
const valueProviderFactory = require("../../Grid/ValueProviders/plex-grid-value-providers-factory");
const elementHandler = require("../plex-handler-element");
const actionHandler = require("../plex-handler-action");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const GroupColumn = require("./plex-grid-column-group");
const SelectionState = require("../plex-selection-state");
const plexExport = require("../../../global-export");
const notify = require("../../Core/plex-notify");

const HEADER_ELEMENT_IDENTIFIER = "_pivot_header_";

function getRecordForEachBucket(records) {
  const buckets = [];
  if (records.length > 0 && !records[0].$$buckets) {
    notify.error("The Key property or Pivot Key property is not set on the crosstab grid.");
    return buckets;
  }
  const indices = ko.utils.range(0, records[0].$$buckets.length - 1);
  let i = records.length;
  let record, bucketIndex, j;

  while (indices.length && i--) {
    record = records[i];
    j = indices.length;

    while (j--) {
      bucketIndex = indices[j];
      if (record.$$buckets[bucketIndex].$$data && !record.$$buckets[bucketIndex].$$data.$$empty) {
        buckets[bucketIndex] = record.$$buckets[bucketIndex].$$data;
        indices.splice(j, 1);
      }
    }
  }

  return buckets;
}

const PivotColumn = GroupColumn.extend({
  init: function (column) {
    this._base.apply(this, arguments);
    this.parent.pivotColumn = column;
    this.parent.isCrosstab = true;

    // todo: i wonder if we can push the matching of header actions & features onto the server
    if (column.headerActions && column.headerActions.length > 0) {
      column.headerActions.forEach((config) => {
        // find matching column
        const child = column.columns.filter((c) => {
          return c.id === config.columnId;
        })[0];
        if (child && config.action && config.action.disabled === false) {
          child.headerAction = config.action;
        }
      });
    }

    if (column.headerFeatures && column.headerFeatures.length > 0) {
      column.headerFeatures.forEach((config) => {
        const child = column.columns.filter((c) => {
          return c.id === config.columnId;
        })[0];
        if (child) {
          if (!child.headerFeatures) {
            child.headerFeatures = [];
          }

          child.headerFeatures.push(config.feature);
        }
      });
    }

    column.generate = this.generateCrosstabColumns.bind(this);
  },

  generateCrosstabColumns: function () {
    const columns = [];
    const config = this.parent.config;
    const sourceColumns = this.parent.sourceColumns;
    const datasource = this.parent.datasource;
    let i, ln, column, data;

    for (i = 0, ln = sourceColumns.length; i < ln; i++) {
      column = sourceColumns[i];
      if (column.type === "Pivot") {
        const pivotColumns = [];
        if (config.pivotColumns && config.pivotColumns.length > 0) {
          // pivots are pre-defined on the server
          this.addCrosstabPivotColumns(pivotColumns, column, config.pivotColumns, config);
        } else if (datasource.data().length === 0) {
          // for empty data, just display the empty column
          columns.push(column);
          continue;
        } else {
          // note - we do not want to assign this until we need it to avoid knockout
          // dependencies that we don't need in the case of predefined columns
          data = datasource.data();

          // get unique pivots from the current recordset and create pivots
          this.addCrosstabPivotColumns(pivotColumns, column, getRecordForEachBucket(data.source || data), config);
        }

        const clone = $.extend({}, column);
        clone.columns = pivotColumns;
        clone.colSpan = ko.pureComputed(() => this.getColSpan(clone.columns));
        clone.headerCss += " plex-pivot-column";

        if (column.groupHeaderVisible && column.headerElementSource) {
          // if a header element is defined, replace the clone's element and apply appropriate attributes
          clone.headerElement = dataUtils.clone(column.headerElementSource);
          this.applyAttributes(clone);
        }

        if (column.groupHeaderVisible || column.headerElementSource.title) {
          columns.push(clone);
        } else {
          columns.push(...pivotColumns);
        }
      } else {
        columns.push(column);
      }
    }

    return columns;
  },

  addCrosstabPivotColumns: function (columns, column, buckets) {
    const self = this;
    buckets.forEach((bucket, bucketIndex) => {
      const bucketClone = dataUtils.cleanse(bucket, { flatten: false, keepObservables: true, ignoreEmpty: false });
      const clone = dataUtils.clone(column, { exclude: ["columns", "headerElement", "valueProvider"] });

      clone.bucketIndex = bucketIndex;
      clone.$$bucket = bucketClone;

      self.recreateValueProviderForColumn(column, clone, bucketIndex);

      // setup dynamic pivot header element
      const pivotHeader = elementHandler.cloneElement(
        column.pivotHeaderElement,
        HEADER_ELEMENT_IDENTIFIER + bucketIndex
      );
      elementHandler.initElement(pivotHeader, bucketClone);

      // replace child array and clone all children
      clone.columns = column.columns.map((child) => {
        // if there is only one pivot, ignore the cloned element and replace it with the dynamic pivot header element
        const headerElement = column.columns.length === 1 ? pivotHeader : dataUtils.clone(child.headerElementSource);
        return self.cloneChildColumn(child, clone, bucketIndex, bucketClone, headerElement);
      });

      // Reset colSpan deffered to attach to appropriate column references
      clone.colSpan = ko.pureComputed(() => {
        return self.getColSpan(clone.columns);
      });

      if (clone.columns.length > 1) {
        // if there are more than one column under the pivot, keep the dynamic element at this level
        clone.headerElement = pivotHeader;
      }

      self.applyAttributes(clone);
      columns.push(clone);
    });
  },

  cloneChildColumn: function (column, parent, bucketIndex, bucket, headerElement) {
    const self = this;
    const clone = dataUtils.clone(column, {
      exclude: ["writer", "parent", "headerElement", "printWriter", "valueProvider"]
    });

    clone.headerElement = headerElement;
    clone.$$headerData = bucket;
    self.recreateValueProviderForColumn(column, clone, bucketIndex);

    if (clone.type === "Master") {
      if (clone.defaultColumn) {
        self.recreateValueProviderForColumn(column.defaultColumn, clone.defaultColumn, bucketIndex);
      }
    }

    if (clone.enableToggleAll) {
      clone.selectionState = new SelectionState(
        self.getRecordsByBucketIndex(bucketIndex, { addEmptyRecord: true }),
        clone.toggleAllColumn ? clone.toggleAllColumn : clone,
        self.parent.grid
      );
    }

    clone.parent = parent;
    clone.writer = null;
    clone.printWriter = column.printWriter;

    // copy forward computed to maintain border - would be better to reapply?
    clone.headerCss = column.headerCss;

    // need to apply attributes before processing features
    self.applyAttributes(clone, bucket);

    if (clone.type === "Master") {
      if (clone.defaultColumn) {
        self.applyAttributes(clone.defaultColumn);
        self.setupFeatures(clone.defaultColumn);
      }

      clone.columns.forEach((col) => {
        self.applyAttributes(col.column);
        self.setupFeatures(col.column);
      });
    }

    self.reinitActionsFor(column, clone);

    if (clone.headerFeatures && clone.headerFeatures.length > 0) {
      const featureProcessor = new FeatureProcessor(clone.headerFeatures, clone, this.parent.grid);
      clone.headerFeaturesResult = featureProcessor.process(bucket);
    }

    const disabled = clone.headerFeaturesResult && clone.headerFeaturesResult.disabled;
    if (clone.headerAction && !disabled) {
      actionHandler.initAction(clone.headerAction, this.parent.grid);
      clone.headerAction.executeAction = function (col, e) {
        // the header action is bound to the bucket data
        actionHandler.executeAction(clone.headerAction, bucket, e);
      };
    }

    return clone;
  },

  recreateValueProviderForColumn: function (originalColumn, clone, bucketIndex) {
    const self = this;
    clone.bucketIndex = bucketIndex;

    if (originalColumn.valueProvider && originalColumn.valueProvider.config) {
      // copy and reset the valueprovider configuration
      clone.valueProvider = dataUtils.clone(originalColumn.valueProvider.config);
    }

    clone.valueProvider = valueProviderFactory.create(clone, self.parent.config);

    // replace getRecord to get corresponding record
    clone.valueProvider.getRecord = function (record) {
      if (!record.$$buckets) {
        return record;
      }

      const emptyRecord = this.parent.emptyRecord;
      const columnBucketIndex = this.column.bucketIndex;

      if (!record.$$buckets[columnBucketIndex].$$data) {
        record.$$buckets[columnBucketIndex].$$data = $.extend(true, {}, emptyRecord);
        record.$$buckets[columnBucketIndex].$$data.$$empty = true;
      }

      return record.$$buckets[columnBucketIndex].$$data;
    };

    // clone valueProvider for elements
    if (clone.elements && clone.elements.length > 0) {
      clone.elements.forEach((element, index) => {
        const originalElement = originalColumn.elements[index];
        self.recreateValueProviderForElement(element, originalElement);
      });
    }

    if (clone.composite) {
      clone.columns.forEach((col, i) => {
        const original = originalColumn.columns[i].column || originalColumn.columns[i];
        self.recreateValueProviderForColumn(original, col.column || col, bucketIndex);
      });
    }
  },

  recreateValueProviderForElement: function (clone, original) {
    const self = this;
    if (original.valueProvider) {
      clone.valueProvider = dataUtils.clone(original.valueProvider);
    }
    if (clone.element) {
      self.recreateValueProviderForElement(clone.element, original.element);
    }
    if (clone.elements && clone.elements.length > 0) {
      clone.elements.forEach((elment, index) => {
        const originalElement = original.elements[index];
        self.recreateValueProviderForElement(elment, originalElement);
      });
    }
  },

  getRecordsByBucketIndex: function (bucketIndex, options) {
    const self = this;
    const records = self.parent.datasource.data();
    options = options || {};

    const bucketRecords = [];
    records.forEach((record) => {
      if (!record.$$buckets[bucketIndex].$$data && options.addEmptyRecord) {
        record.$$buckets[bucketIndex].$$data = $.extend(true, {}, self.parent.config.emptyRecord);
        record.$$buckets[bucketIndex].$$data.$$empty = true;
      }

      bucketRecords.push(record.$$buckets[bucketIndex].$$data);
    });

    return bucketRecords;
  },

  setupExecuteAction: function (originalColumn, clone) {
    clone.executeAction = function () {
      originalColumn.executeAction.apply(clone, arguments);
    };
  },

  reinitActionsFor: function (originalColumn, clone) {
    const self = this;

    if (originalColumn.executeAction) {
      self.setupExecuteAction(originalColumn, clone);
    }

    if (originalColumn.action) {
      clone.action = originalColumn.action;
      // overwrite so that it uses the correct value provider
      actionHandler.initAction(clone.action, this.parent.grid);
      clone.executeAction = function (record, e) {
        record = clone.valueProvider.getRecord(record);
        actionHandler.executeAction(clone.action, record, e);
      };
    }

    if (clone.composite) {
      clone.columns.forEach((col, index) => {
        const originalCol = originalColumn.columns[index].column || originalColumn.columns[index];
        const cloneCol = col.column || col;

        self.reinitActionsFor(originalCol, cloneCol);
      });
    }

    // reinit a master column's default column's actions
    if (clone.defaultColumn) {
      self.reinitActionsFor(originalColumn.defaultColumn, clone.defaultColumn);
    }
  }
});

module.exports = PivotColumn;
plexExport("grid.columns.Pivot", PivotColumn);
