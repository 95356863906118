ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const plexExport = require("../../global-export");
const ControllerFactory = require("./plex-controller-factory");
const elementHandler = require("./plex-handler-element");
const actionHandler = require("./plex-handler-action");

const SplitButtonDropdownController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

SplitButtonDropdownController.prototype = {
  constructor: SplitButtonDropdownController,

  init: function () {
    const self = this;
    const mainAction = ko.observable(self.config.action);
    const additionalActions = self.config.additionalActions;

    actionHandler.initAction(mainAction, null, null, self.config.parent);
    self.setupAdditionalActions(additionalActions, self.config.parent);
  },

  setupAdditionalActions: function (actionItems, parent) {
    const self = this;
    actionItems.forEach((actionItem) => {
      elementHandler.initElement(actionItem, null, null, parent);
      self.setupClick(actionItem);
    });
  },

  setupClick: function (item) {
    const self = this;
    const $element = $("#" + item.id);
    const $button = $("#" + self.config.id);

    $element.click(() => {
      const newText = item.text;
      item.text = self.config.text;
      self.config.text = newText;

      $element.text(item.text());
      $button.text(self.config.text());

      const newAction = $.extend(true, {}, item.action);
      item.action = self.config.action;
      self.config.action = newAction;
    });
  }
};

SplitButtonDropdownController.create = function (config, model) {
  return new SplitButtonDropdownController(config, model);
};

ControllerFactory.register("Elements/_SplitButtonDropdown", SplitButtonDropdownController);

module.exports = SplitButtonDropdownController;
plexExport("SplitButtonDropdown", SplitButtonDropdownController);
