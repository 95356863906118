ï»¿const dataUtils = require("../Utilities/plex-utils-data");
const plexExport = require("../../global-export");

function PropertyExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

PropertyExpression.prototype = {
  constructor: PropertyExpression,

  init: function (config) {
    this.evaluator = function (record) {
      return dataUtils.getValue(record, config.propertyName, true);
    };
  },

  getValue: function () {
    return this.evaluator.apply(this, arguments);
  }
};

module.exports = PropertyExpression;
plexExport("expressions.PropertyExpression", PropertyExpression);
