ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const pickernullfilter = Feature.extend({
  onInit: function () {
    const self = this;
    if (self && self.element && self.element.controller) {
      const picker = self.element.controller;
      ["Value", "LowerTolerance", "UpperTolerance"].forEach((propertyName) => {
        picker.datasource.onChange(propertyName, function (value) {
          // if a value is assigned then select the record
          // eslint-disable-next-line no-invalid-this
          this.$$selected(value != null);
        });
      });
    }
  },
  onExecute: function () {
    // noop
  }
});

module.exports = pickernullfilter;
plexExport("features.pickernullfilter", pickernullfilter);
