ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const jsUtils = require("../Utilities/plex-utils-js");
const http = require("./plex-navigate");
const plexExport = require("../../global-export");

const RESOLVE_URL_ROUTE = "/platform/application/resolveurl/";
const externalMatcher = /^https?:\/\//;

function UrlRequest(actionKeyOrUrl) {
  if (typeof actionKeyOrUrl === "number" || /\d+/.test(String(actionKeyOrUrl))) {
    this.actionKey = Number(actionKeyOrUrl);
  } else {
    if (actionKeyOrUrl[0] === "/") {
      actionKeyOrUrl = actionKeyOrUrl.substr(1);
    }

    const segments = actionKeyOrUrl.split("/");

    if (segments.length === 1) {
      this.actionName = segments[0];
    } else {
      this.areaName = segments[0];
      this.controllerName = segments[1];
      this.actionName = segments[2];
    }
  }
}

UrlRequest.prototype.areSame = function (request) {
  if (this.actionKey && request.actionKey) {
    return this.actionKey === request.actionKey;
  }

  return (
    (this.areaName || "").toLowerCase() === (request.areaName || "").toLowerCase() &&
    (this.controllerName || "").toLowerCase() === (request.controllerName || "").toLowerCase() &&
    (this.actionName || "").toLowerCase() === (request.actionName || "").toLowerCase()
  );
};

UrlRequest.prototype.update = function (response) {
  $.extend(this, response);
};

let requests = [];
let pending = [];

function resolveResponses(urls) {
  urls.forEach((url) => {
    let i = requests.length;
    while (i--) {
      if (requests[i].request.areSame(url)) {
        requests[i].resolve(url);
      }
    }
  });
}

function executeBatch() {
  const toRequest = pending.splice(0, pending.length);
  requests.push.apply(requests, toRequest);

  const request = toRequest.map((r) => {
    return r.request;
  });

  $.ajax({
    url: http.buildUrl(RESOLVE_URL_ROUTE, null),
    type: "POST",
    data: http.serialize(request)
  }).then(resolveResponses);
}

function areSameAs(request) {
  return function (a) {
    return a.request.areSame(request);
  };
}

function tryResolveRequest(request) {
  return ko.utils.arrayFirst(requests, areSameAs(request)) || ko.utils.arrayFirst(pending, areSameAs(request));
}

function resolveOrEnqueue(request) {
  const resolved = tryResolveRequest(request);
  if (resolved) {
    return resolved.response;
  }

  const deferred = new $.Deferred();
  const task = {
    request,
    response: deferred.promise(),
    resolve: function (url) {
      $.extend(request, url);
      deferred.resolve(url);
    }
  };

  pending.push(task);
  if (pending.length === 1) {
    jsUtils.defer(executeBatch);
  }

  return task.response;
}

const api = {
  getUrl: function (actionKeyOrUrl) {
    if (typeof actionKeyOrUrl === "string" && externalMatcher.test(actionKeyOrUrl)) {
      // todo: should we try to authorize these requests?
      return $.when({
        url: actionKeyOrUrl,
        authorized: true
      });
    }

    return resolveOrEnqueue(new UrlRequest(actionKeyOrUrl));
  },

  clear: function () {
    // clears the cache for testing purposes
    pending = [];
    requests = [];
  }
};

module.exports = api;
plexExport("urlProvider", api);
