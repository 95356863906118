ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const http = require("../Core/plex-navigate");
const plexExport = require("../../global-export");
const dataResultUtils = require("../Utilities/plex-utils-data-result");

const PostAction = Action.extend({
  onExecute: function (data) {
    const $deferred = new $.Deferred();

    const postData = dataUtils.cleanse(data, { ignoreEmpty: true, trimWhitespace: !this.allowWhiteSpace });
    const promise = http.post(this.address, postData, {
      ajax: this.ajax
    });

    if (this.ajax || this.postAction) {
      promise
        .done((results) => {
          if (!this.validateResponse(results)) {
            $deferred.reject();
            return;
          }

          if (results.CustomDataResults) {
            dataResultUtils.mergeCustomData(results);
          }

          $deferred.resolve(results.Data || results).then(() => {
            // do not mark parent as `saved` until after resolving
            // so subsequent actions have a chance to work with the
            // save set of data
            this.parent?.saved?.(true);

            // reset form to original state
            if (this.resetFormAfterApply) {
              this.parent?.reset?.();
            }
          });
        })
        .fail($deferred.reject);
    }

    return $deferred.promise();
  }
});

module.exports = PostAction;
plexExport("actions.Post", PostAction);
