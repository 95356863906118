ï»¿const ko = require("knockout");
const $ = require("jquery");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

// TODO - For backwards compatiability.
// Will be deleted by 1/4/16.  Only left to support pages versions < 2.17.0.
const getFieldHelp = function (fieldHelpKey, callback) {
  $.getJSON(nav.buildUrl("/Platform/FieldLevelHelp/GetFieldHelp", { fieldHelpKey }), callback);
};

const getHelpDescription = function (fieldHelpKeys, callback) {
  $.getJSON(
    nav.buildUrl("/Platform/FieldLevelHelp/GetHelpDescription", { fieldHelpKeys: fieldHelpKeys.join() }),
    callback
  );
};

ko.bindingHandlers.showHelp = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    const $element = $(element);
    const options = valueAccessor();

    const fieldHelpKeys = options.helpKeys;
    const fieldHelpKey = options.helpKey;

    const $scriptContainer = $element.next();
    const $helpContainer = $($scriptContainer.html());

    const loadHelp = function (oCallback) {
      // TODO - For backwards compatiability.
      // Will be deleted by 1/4/16.  Only left to support pages versions < 2.17.0.
      if (fieldHelpKey > 0) {
        getFieldHelp(fieldHelpKey, (data) => {
          if (data.Rows.length > 0) {
            $helpContainer
              .find(".plex-help-popover-content")
              .text(data.Rows[0].Description || "Help is not available for this field.");
            $helpContainer.find(".plex-help-popover-header").text(data.Rows[0].DatabaseTableColumn);
          } else {
            $helpContainer.find(".plex-help-popover-content").text("Help is not available for this field.");
          }
          oCallback();
        });
      } else if (fieldHelpKeys && fieldHelpKeys.length > 0) {
        getHelpDescription(fieldHelpKeys, (data) => {
          $helpContainer.children("div").remove();
          for (let i = 0; i < data.Rows.length; i++) {
            const row = data.Rows[i];

            const header = $("<div>").addClass("plex-help-popover-header").text(row.DatabaseTableColumn);

            const content = $("<div>")
              .addClass("plex-help-popover-content")
              .text(row.Description || "Help is not available for this field.");

            const container = $("<div>").append(header).append(content);

            $helpContainer.append(container);
          }

          if (data.Rows.length <= 0) {
            $helpContainer.find(".plex-help-popover-content").text("Help is not available for this field.");
          }

          oCallback();
        });
      } else {
        oCallback();
      }
    };

    const newValueAccessor = function () {
      return {
        panel: $helpContainer,
        dock: "BottomHelp",
        callback: loadHelp,
        appendToBody: true
      };
    };

    ko.bindingHandlers.popover.init(element, newValueAccessor, allBindingsAccessor, viewModel, bindingContext);
  }
};

plexExport("getFieldHelp", getFieldHelp);
plexExport("getHelpDescription", getHelpDescription);
