ï»¿const expressions = require("../Expressions/plex-expressions-compiler");
const actionHandler = require("../Controls/plex-handler-action");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ConditionAction = Action.extend({
  onInit: function () {
    if (this.action) {
      actionHandler.initAction(this.action, this.parent);
    }

    this.conditionEvaluator = this.condition
      ? expressions.compile(this.condition)
      : function () {
          return true;
        };
  },

  onExecute: function (data, sourceData, event) {
    if (this.action && this.conditionEvaluator(data, null, null, this)) {
      return actionHandler.executeAction(this.action, data, event, this);
    }

    return true;
  }
});

module.exports = ConditionAction;
plexExport("actions.Condition", ConditionAction);
