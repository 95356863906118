ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const browser = require("../Core/plex-browser");
const js = require("../Utilities/plex-utils-js");
const mathUtils = require("../Utilities/plex-utils-math");
const GridSearch = require("../Grid/plex-grid-search");
const env = require("../Core/plex-env");
require("../Plugins/plex-jquery"); // eslint-disable-line import/no-unassigned-import

const SCROLLBAR_WIDTH = $.getScrollBarSize().width;
const GRID_SEARCH_FEATURE_FLAG = "fix-tri-4244-grid-ctrlf-search-enhancements";

const defaults = {
  fixedClass: "fixed-element",
  fixWhenOnScreenOnly: false,
  parentContainer: null,
  vertical: true,
  horizontal: false
};

const scrollers = new WeakMap();

function FixedElementContainer($el) {
  this.$element = $el;
  this.fixedOffset = 0;
  this.priorTop = $el.scrollTop();
  this.priorLeft = $el.scrollLeft();
  this.fixedElements = [];
  this.positionType = "fixed";
  this.active = true;

  if ($el[0] !== window) {
    this.positionType = "absolute";

    this.originalPosition = this.$element[0].style.position;
    if (this.$element.css("position") === "static") {
      this.$element[0].style.position = "relative";
    }

    // this is likely the safest and most efficient way to found out the true "top" of the scrolled content
    // any element within the container could have it's positioning impacted by any number of things
    // so injecting this element gives us a reliable indicator of what is the top
    this.$positionMarker = $("<div class='fixed-element-position-marker'>").appendTo(this.$element);
  }
}

FixedElementContainer.prototype = {
  constructor: FixedElementContainer,

  start: function () {
    this.$element.on("scroll.fixedElementContainer", js.throttleRender(this.process.bind(this)));
    this.$element.on("resizeCompleted.fixedElementContainer", this.handleResize.bind(this));
  },

  stop: function () {
    this.$element.off("scroll.fixedElementContainer");
    this.$element.off("resizeCompleted.fixedElementContainer");
  },

  adjustNextFixedElements: function (fixedElement) {
    let i = this.fixedElements.indexOf(fixedElement) + 1;
    for (; i < this.fixedElements.length; i++) {
      const nextElement = this.fixedElements[i];
      if (nextElement.isFixed) {
        nextElement.$element.css("top", (_, currentTop) => {
          currentTop = parseInt(currentTop, 10);
          if (!fixedElement.options.fixWhenOnScreenOnly) {
            currentTop -= fixedElement.getBounds().height;
          }

          return currentTop;
        });
      }
    }
  },

  adjustOffset: function (fixedElement, direction = 1) {
    if (fixedElement.options.fixWhenOnScreenOnly) {
      // since the element is only fixed when it's container is on the screen
      // it is very unlikely that something will be fixed while that container
      // is on the screen (this is the use case for grid headers)
      // adding them to the fixedOffset impacts any elements that might be
      // fixed at the same time (think of side by side grids) so we will not
      // be offseting for them. This assumption may have edge cases, but i cannot
      // think of any at the moment.
      return;
    }

    // sanity check
    if (this.fixedElements.indexOf(fixedElement) === -1) {
      return;
    }

    this.fixedOffset += fixedElement.getBounds().height * direction;
  },

  allowFixing: function (_viewport) {
    if (!browser.hasFixedElementZoomBugs || !window.devicePixelRatio) {
      return true;
    }

    // if the screen is zoomed in, fixed elements can get wacky, so
    // we remove fixing when this happens
    // the second argument of floor10 - exponent - should be kept in sync
    // with the scale of the number that browser.defaultDevicePixelRatio returns
    return mathUtils.floor10(window.devicePixelRatio, -1) === browser.defaultDevicePixelRatio;
  },

  getActualFixedOffset: function () {
    const actualOffsets = this.fixedElements
      .filter((el) => el.isFixed)
      .map((el) => el.$element[0].getBoundingClientRect().bottom);

    return Math.max(this.fixedOffset, ...actualOffsets);
  },

  getViewport: function () {
    const scrollTop = this.$element.scrollTop();
    const scrollLeft = this.$element.scrollLeft();
    const scrollWidth = getScrollWidth(this.$element[0]);
    const windowWidth = getWindowWidth(window);

    if (this.positionType === "absolute") {
      const rect = this.$element[0].getBoundingClientRect();
      return {
        height: rect.height,
        width: rect.width,

        scrollTop,
        scrollLeft,
        scrollWidth,

        windowTop: rect.top,
        windowLeft: rect.left,
        windowYOffset: this.$positionMarker[0].getBoundingClientRect().top,
        windowWidth,

        // need to offset the position of the element by the scroll position
        // when absolutely positioning the elements
        positionOffset: scrollTop
      };
    }

    return {
      height: this.$element.height(),
      width: windowWidth,
      scrollTop,
      scrollLeft,
      scrollWidth,
      windowTop: 0,
      windowLeft: 0,
      windowYOffset: -window.pageYOffset,
      windowWidth,
      positionOffset: 0
    };
  },

  process: function () {
    const viewport = this.getViewport();
    if (!this.allowFixing(viewport)) {
      this.reset(false, viewport);
      return;
    }

    if (viewport.scrollTop !== this.priorTop) {
      if (viewport.scrollTop > this.priorTop) {
        this.processScrollDown(viewport);
      } else {
        this.processScrollUp(viewport);
      }

      if (this.positionType === "absolute") {
        // need to adjust all absolute tops for every scroll
        let top = viewport.scrollTop;
        this.fixedElements
          .filter((el) => el.isFixed && el.options.vertical)
          .forEach((el) => {
            el.$element[0].style.top = top - el.getPositionOffset(viewport) + "px";

            // only offset for additional elements when the element is not fixed within a container
            top += el.options.fixWhenOnScreenOnly ? 0 : el.getBounds().height;
          });
      }
    }

    if (viewport.scrollLeft !== this.priorLeft) {
      this.fixedElements.filter((el) => el.options.horizontal).forEach((el) => el.fixX(viewport));
    }

    this.priorTop = viewport.scrollTop;
    this.priorLeft = viewport.scrollLeft;
  },

  processScrollDown: function (viewport) {
    this.fixedElements
      .filter((el) => el.options.vertical)
      .forEach((el) => {
        if (el.isFixed && el.shouldUnfix(viewport)) {
          el.unfix(viewport);
          this.adjustNextFixedElements(el);
        } else if (!el.isFixed && el.shouldFix(viewport)) {
          el.fix(viewport);
        }
      });
  },

  processScrollUp: function (viewport) {
    let i = this.fixedElements.length;

    // we need to do this in reverse order so we unfix the elements in correct sequence
    // the fixed elements are sorted in position order.
    while (i--) {
      const el = this.fixedElements[i];
      if (!el.options.vertical) {
        continue;
      }

      if (!el.isFixed && el.shouldFix(viewport)) {
        el.fix(viewport);
      } else if (el.isFixed && el.shouldUnfix(viewport)) {
        el.unfix(viewport);
      }
    }
  },

  handleResize: function () {
    const viewport = this.getViewport();
    if (this.allowFixing(viewport) !== this.active) {
      this.reset(!this.active, viewport);
    }

    // note: since resizing can cause an elements width to resize
    // this is only known to impact horizontal fixing
    this.fixedElements.filter((el) => el.options.horizontal).forEach((el) => el.resize());
  },

  reset: function (active, viewport) {
    this.priorTop = this.priorLeft = null;
    this.active = active;

    viewport = viewport || this.getViewport();
    this.fixedElements.filter((el) => el.isFixed).forEach((el) => el.unfix(viewport));
  },

  addListener: function (fixedElement) {
    this.fixedElements.push(fixedElement);

    // resort elements by their position
    this.fixedElements.sort((a, b) => {
      const aOffset = a.$element.closest(":visible").offset();
      const bOffset = b.$element.closest(":visible").offset();

      const aTop = aOffset ? aOffset.top : 0;
      const bTop = bOffset ? bOffset.top : 0;

      return aTop - bTop;
    });

    if (this.fixedElements.length === 1) {
      this.start();
    }
  },

  removeListener: function (fixedElement) {
    this.fixedElements.remove(fixedElement);
    if (this.fixedElements.length === 0) {
      this.stop();
    }
  },

  dispose: function () {
    this.reset(false);

    scrollers.delete(this.$element[0]);
    this.$element.off("scroll.fixedElementContainer");
    this.$element.off("resizeCompleted.fixedElementContainer");

    if (this.$positionMarker) {
      this.$positionMarker.remove();
      this.$element[0].style.position = this.originalPosition;
    }
  }
};

function FixedElement(el, options) {
  /// <summary>Constructor function to create a fixed element object.</summary>
  /// <param name="el" type="Element">The element to fix.</param>
  /// <param name="options" type="PlainObject">Options: fixedClass: the css class to apply when fixed. (default 'fixed-element')</param>

  this.$element = $(el);
  this.options = $.extend({}, defaults, options);
  this.init();
}

FixedElement.prototype = {
  constructor: FixedElement,

  init: function () {
    this.fixedContainer = getOrCreateScroller(this.$element);
    this.fixedContainer.addListener(this);

    this.$element.wrapAll("<div class='fixed-element-wrapper'>");
    this.$parent = this.$element.parent();
    this.isFixed = false;

    if (this.options.fixWhenOnScreenOnly) {
      this.$parentContainer = $(this.options.parentContainer);
    }

    // keep any applicable inline styles so they can be restored
    this.originalStyle = {
      position: this.$element[0].style.position,
      left: this.$element[0].style.left,
      top: this.$element[0].style.top,
      zIndex: this.$element[0].style.zIndex
    };
  },

  getBounds: function (viewport) {
    if (this._bounds && !viewport) {
      return this._bounds;
    }

    viewport = viewport || this.fixedContainer.getViewport();
    const rect = this.$element[0].getBoundingClientRect();

    // cache this value - some of these calculations will be altered after being positioned
    this._bounds = {
      height: rect.height,
      width: rect.width,
      top: rect.top - viewport.windowYOffset,
      bottom: rect.bottom - viewport.windowYOffset,
      positionOffset: this.getPositionOffset(viewport)
    };

    return this._bounds;
  },

  inViewport: function (viewport) {
    const containerBounds = this.$parentContainer[0].getBoundingClientRect();
    if (containerBounds.top > viewport.windowTop + viewport.height) {
      return false;
    }

    const fixedOffset = this.fixedContainer.fixedOffset + this.getBounds().height;
    if (containerBounds.bottom < viewport.windowTop + fixedOffset) {
      return false;
    }

    return true;
  },

  getPositionOffset: function (viewport) {
    if (this.fixedContainer.positionType !== "absolute") {
      return 0;
    }

    const $offsetContainer = this.$element.offsetParent();
    if ($offsetContainer[0] === this.fixedContainer.$element[0] || $offsetContainer[0] === document.documentElement) {
      // if the offset container is the scroll window then we do not need to apply any offset
      return 0;
    }

    // since the element has an intermediate container that it is positioning against,
    // we need to offset for that container to pull the element outside of it into the
    // desired position
    const containerOffset = $offsetContainer[0].getBoundingClientRect().top;
    const scrollerOffset = viewport.windowYOffset;

    return containerOffset - scrollerOffset;
  },

  shouldFix: function (viewport) {
    if (this.options.fixWhenOnScreenOnly && !this.inViewport(viewport)) {
      return false;
    }

    // when multiple grid are present and search is active on one of them
    // allow fixing for that grid only
    if (env.features[GRID_SEARCH_FEATURE_FLAG] && !this._isElementActiveSearchGrid()) {
      return false;
    }

    const bounds = this.getBounds(viewport);
    return viewport.scrollTop + this.fixedContainer.fixedOffset >= bounds.top;
  },

  shouldUnfix: function (viewport) {
    if (this.options.fixWhenOnScreenOnly && !this.inViewport(viewport)) {
      return true;
    }

    // when multiple grid are present and search is active on one of them
    // allow fixing for that grid only, unfix the others
    if (env.features[GRID_SEARCH_FEATURE_FLAG] && !this._isElementActiveSearchGrid()) {
      return true;
    }

    let offset = this.fixedContainer.fixedOffset;
    if (this.options.fixWhenOnScreenOnly) {
      // since the fixed offset doesn't include elements which are fixed within containers
      // we need to account for that here
      offset += this.getBounds().height;
    }

    // the parent wrapper div should be taking up the position that the element used to be in
    const parentRect = this.$element[0].parentNode.getBoundingClientRect();
    const bottom = parentRect.bottom - viewport.windowYOffset;

    return viewport.scrollTop + offset < bottom;
  },

  fix: function (viewport) {
    /// <summary>Fix the element to the top of the screen.</summary>

    viewport = viewport || this.fixedContainer.getViewport();
    const bounds = this.getBounds(viewport);
    const zIndex = this.$element.zIndex() + 10;
    const top = viewport.positionOffset + this.fixedContainer.fixedOffset + bounds.positionOffset;

    this.$parent.height(bounds.height);

    this.$element
      .css({
        position: this.fixedContainer.positionType,
        top,
        zIndex
      })
      .addClass(this.options.fixedClass);

    this.fixedContainer.adjustOffset(this);

    this.isFixed = true;
    this.fixX(viewport);

    this.$element.trigger("fixed.plex");
  },

  unfix: function (viewport) {
    /// <summary>Returns the element back to it's prior position.</summary>

    // reset css properties
    this.$element
      .css({
        position: this.originalStyle.position,
        top: this.originalStyle.top,
        zIndex: this.originalStyle.zIndex
      })
      .removeClass(this.options.fixedClass);

    this.fixedContainer.adjustOffset(this, -1);

    this.isFixed = false;

    // may need to adjust X offset after unfixing
    this.fixX(viewport);

    this.$element.trigger("unfixed.plex");
  },

  fixX: function (viewport) {
    if (!this.options.horizontal) {
      return;
    }

    if (this.isFixed && this.fixedContainer.positionType !== "absolute") {
      this.unfixX();
      return;
    }

    viewport = viewport || this.fixedContainer.getViewport();

    const originalLeft = parseInt(this.originalStyle.left, 10) || 0;
    let left = originalLeft + viewport.scrollLeft;
    const width = this.$element[0].offsetWidth;

    // if the element is bigger than the window, then we need to let it scroll naturally
    // up until it reaches the edge of the window
    if (width > viewport.width) {
      // note: this should probably use offset.left + width
      // though that use case hasn't presented itself yet
      const elementDiff = width - viewport.width + SCROLLBAR_WIDTH;
      if (viewport.scrollLeft < elementDiff) {
        this.unfixX();
        return;
      }

      left -= elementDiff;
    }

    // make sure to contain the element to the document size or it will cause the document
    // to grow endlessly as you scroll
    left = Math.min(left, viewport.scrollWidth - width);

    if (left === originalLeft) {
      this.unfixX(viewport);
      return;
    }

    if (this.$element.css("position") === "static") {
      // need to at least have relative positioning to respect the positioning
      this.$element[0].style.position = "relative";
    }

    this.$element[0].style.left = left + "px";
  },

  unfixX: function () {
    if (!this.options.horizontal) {
      return;
    }

    this.$element[0].style.left = this.originalStyle.left;

    if (!this.isFixed) {
      this.$element[0].style.position = this.originalStyle.position;
    }
  },

  resize: function () {
    if (!this._bounds) {
      return;
    }

    // the width may have changed, but we do not want to lose the rest of the cached info
    // depending on other impacts resizing has, more may need to be handled here
    const rect = this.$element[0].getBoundingClientRect();
    this._bounds.width = rect.width;
  },

  dispose: function () {
    if (this.isFixed) {
      this.unfix();
    }

    this.$element.css(this.originalStyle);
    this.fixedContainer.removeListener(this);
    this.$element.unwrap();
    this.$element.removeData("fixedElement");
  },

  _isElementActiveSearchGrid: function () {
    const activeSearch = GridSearch.getActiveSearch();

    if (activeSearch === null || !this.$parentContainer || this.$parentContainer?.[0] === null) {
      return true;
    }

    return activeSearch?.grid.$element[0] === this.$parentContainer?.[0];
  }
};

function getScrollParent(el) {
  const $element = $(el);
  if ($element[0] === document || $element[0] === window) {
    return $(window);
  }

  const $scrollParent = $element.scrollParent();
  if ($scrollParent[0] === document) {
    return $(window);
  }

  return $scrollParent;
}

function getOrCreateScroller(el) {
  const $scrollParent = getScrollParent(el);
  let scroller = scrollers.get($scrollParent[0]);
  if (!scroller) {
    scroller = new FixedElementContainer($scrollParent);
    scrollers.set($scrollParent[0], scroller);
  }

  return scroller;
}

function getScrollWidth(element) {
  if (element === document || element === window) {
    element = document.body;
  }

  return element.scrollWidth;
}

function getWindowWidth(element) {
  if (element === document || element === window) {
    element = window;
  }

  return element.clientWidth + SCROLLBAR_WIDTH || element.innerWidth;
}

$.fn.fixedElement = function (options) {
  /// <summary>Fixes an element to the top of the screen when it begins to scroll out of view.</summary>
  /// <param name="options" type="PlainObject">Options: fixedClass: the css class to apply when fixed. (default 'fixed-element')</param>

  return this.each(function () {
    const $this = $(this);
    const data = $this.data("fixedElement");

    if (!data) {
      $this.data("fixedElement", new FixedElement(this, options));
    }
  });
};

$.fn.fixedElement.Constructor = FixedElement;

$.fixedOffset = function () {
  return getOrCreateScroller(window).getActualFixedOffset();
};

$.fn.fixedOffset = function () {
  const $scrollContainer = getScrollParent(this);
  const scroller = scrollers.get($scrollContainer[0]);
  return scroller ? scroller.getActualFixedOffset() : 0;
};

// find any that are predefined as fixed (ie, the navbar)
$(() => {
  if (document.getElementsByClassName("plex-env-persistent-banner-container").length === 0) {
    $("[data-position='fixed']").each(function () {
      getOrCreateScroller(window).fixedOffset += $(this).outerHeight() - 1;
    });
  }
});
