ï»¿const printing = require("../../Core/plex-printing");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const xmlProviders = plexImport("printing.xmlProviders");

const XmlProviderFactory = {
  create: function (element) {
    if (!element) {
      throw new Error("No xml provider for the given element: " + element);
    }

    const nodeName = printing.resolveViewName(element.viewName);
    const provider =
      nodeName in xmlProviders ? new xmlProviders[nodeName](element) : new xmlProviders.DefaultXmlProvider(element);

    provider.init();

    // to avoid binding such element which is inappropriate to serialize it
    if (provider instanceof xmlProviders.DefaultXmlProvider && provider?.isInvalidNodeName()) {
      // eslint-disable-next-line no-console
      console.log("the element is not appropriate for xml to bind");
      return undefined;
    }

    return provider;
  }
};

module.exports = XmlProviderFactory;
plexExport("printing.xmlProviders.XmlProviderFactory", XmlProviderFactory);
