ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const repository = require("../Controls/plex-model-repository");
const domUtils = require("../Utilities/plex-utils-dom");
const PageHandler = require("../Controls/plex-handler-page");
const banner = require("../Plugins/plex-banner");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");
const dialogState = require("../Core/plex-dialogstate");
const enterpriseManager = require("../Core/plex-enterprise");
const nav = require("../Core/plex-navigate");
const browser = require("../Core/plex-browser");
const urlParser = require("../Core/plex-parsing-url");
const js = require("../Utilities/plex-utils-js");

const DIALOG_Z_INDEX = 1000;

// TODO: Add logic to not let the same dialog open more than once at a time.
const dialogStack = plexExport("dialogStack", []);

function getRouteHtml(dialogController, callback) {
  $(document).block();
  PageHandler.reset();

  let data = dialogController.routeData;
  if (data && typeof data === "object" && dialogController.httpMethod.toLowerCase() === "post") {
    data = nav.serialize(data);
  }

  $.ajax({
    url: dialogController.route,
    data,
    dataType: "html",
    type: dialogController.httpMethod
  })
    .done((responseText) => {
      const replaceContents = dialogController.dialogElement != null;
      domUtils.injectHtml(responseText, dialogController.dialogElement, replaceContents).then(($dialog) => {
        $(document).unblock();

        dialogController.dialogElement = dialogController.dialogElement || $dialog;
        $dialog.data("plex.dialog", dialogController);

        ko.applyBindings(dialogController, dialogController.dialogElement[0]);
        callback();
      });
    })
    .fail(() => {
      $(document).unblock();
      dialogController.dispose();
    });
}

const DialogController = function (config) {
  /// <summary>
  /// Instantiates an instance of the Dialog Controller.
  /// </summary>
  /// <param name="config">
  ///   route: (required) The route that contains the content you want to display.
  ///   show: (default: true) Display the modal dialog immediately upon instantiation.
  /// </param>

  this.config = config;
  this.init();
};

DialogController.prototype = {
  constructor: DialogController,

  init: function () {
    /// <summary>
    /// Initializes a dialog object.
    /// </summary>

    const self = this;

    // get the currently focused field to return focus to it after the dialog will be closed
    self.previouslyFocusedElement = $(":focus");

    self._stateSubscriptions = self._stateSubscriptions || [];
    self.route = getRouteUrl(self.config.route);
    self.routeData = self.config.routeData;
    self.httpMethod = self.config.httpMethod || "GET";
    self.dialogElement = self.config.route && !self.config.dialogElement ? null : self.config.dialogElement;

    self.id = self.route || self.config.dialogElement[0].id;
    self.autoShow = self.config.autoShow !== false;
    self.dialogHeight = self.config.height || "auto";
    self.dialogWidth = self.config.width || "auto";
    self.dialogBackdrop = self.config.backdrop || typeof self.config.backdrop === "undefined" ? "static" : false;
    self.returnAction = self.config.returnAction || self.returnAction;
    self.closeAction = self.config.closeAction;
    self.closingAction = self.config.closingAction;
    self.cancelled = false;
    self.closing = false;
    self.data = null;

    if (self.dialogElement) {
      $(self.dialogElement).data("plex.dialog", self);
    }

    if (self.config.reusePreviousDialog || self.config.parentDialogId) {
      const parentDialog = self.config.reusePreviousDialog
        ? dialogStack[dialogStack.length - 1]
        : dialogStack.filter((d) => d.config.id === self.config.parentDialogId)[0];

      if (parentDialog) {
        parentDialog.dispose();
        self.dialogElement = parentDialog.dialogElement;
      }
    }

    if (!repository.get(self.id)) {
      repository.add(self.id, self);
    }

    if (self.autoShow) {
      self.render();
    }

    if (self.config.forceRedirectsToStayInDialog) {
      self.configureMultiplePagesSupport();
    }

    if (self.config.route) {
      const crossPcn = urlParser.parseUrl(self.config.route).query[enterpriseManager.crossPcnTokenName];
      if (crossPcn) {
        self.restoreCrossPcn = enterpriseManager.setCrossPcn(crossPcn);
      }
    }

    dialogStack.push(this);
  },

  configureMultiplePagesSupport: function () {
    const self = this;

    if (self._stateSubscriptions.length === 0) {
      self._stateSubscriptions.push(
        pubsub.subscribe("history.navButtonClick", (stateObject) => {
          // should restore previous dialog
          if (stateObject) {
            this.launchPageInCurrentDialog(stateObject, { skipState: true });
          }
        })
      );

      self._stateSubscriptions.push(
        pubsub.subscribe(
          "history.dialogRootReached",
          () => {
            // should close dialog
            self.close();
          },
          self
        )
      );
    }

    if (!self.config.skipState) {
      dialogState.addState(self.config);
    }
  },

  render: function () {
    /// <summary>
    /// Renders and displays the dialog.
    /// </summary>

    const self = this;

    if (repository.get(self.id) !== self) {
      return;
    }

    if (self.route) {
      getRouteHtml(self, () => this.open());
    } else {
      this.open();
    }
  },

  open: function () {
    const self = this;

    self.dialogElement.one("shown.bs.modal", function () {
      // eslint-disable-next-line no-invalid-this
      const $modalElement = $(this);
      $modalElement.find(".plex-dialog-body .plex-banner").banner();

      self.contentController = PageHandler.init({ setFocus: $modalElement });

      if (self.dialogHeight !== "auto") {
        // since the size is fixed we should use the resize overrides
        self.$content.addClass("resized");
      }
    });

    self.dialogElement.one("show.bs.modal", function () {
      // eslint-disable-next-line no-invalid-this
      self.$content = $(this).find(".modal-content");
      self.$content.css({
        height: self.dialogHeight,
        width: self.dialogWidth
      });
    });

    self.dialogElement.on("keydown", (event) => {
      if (event.which === browser.keyCodes.escape) {
        self.cancelled = true;
      }
    });

    self.dialogElement.modal({ backdrop: self.dialogBackdrop, keyboard: true });

    if (self.closingAction) {
      let closing = false;
      self.dialogElement.on("hide.bs.modal", (e) => {
        if (closing) {
          // We triggered the hide, so let it continue
          return;
        }

        const close = () => {
          closing = true;
          self.dialogElement.modal("hide");
        };

        const reset = () => {
          self.closing = false;
          self.cancelled = false;
        };

        const result = self.closingAction();
        if (result === false) {
          e.preventDefault();
          reset();
          return;
        }

        if (js.isPromise(result)) {
          e.preventDefault();
          result.then((res) => {
            if (res === false) {
              reset();
            } else {
              close();
            }
          }, reset);
        }
      });
    }

    self.dialogElement.one("hidden.bs.modal", () => {
      self.dispose();
      self.dialogElement.remove();

      if (self.config.forceRedirectsToStayInDialog) {
        self.resetState();
      }

      if (!self.cancelled) {
        self.returnAction(self.data);
      } else if (self.closeAction) {
        self.closeAction();
      }

      self.closing = false;
    });

    if (self.dialogElement && self.dialogElement[0]) {
      pubsub.publish("rendered." + self.dialogElement[0].id);
    }
  },

  launchPageInCurrentDialog: function (dialogConfig, options = {}) {
    const self = this;

    self.config = $.extend({}, dialogConfig, { dialogElement: self.dialogElement });

    self.dispose();
    self.dialogElement = null;

    self.config.skipState = options.skipState;
    self.config.forceRedirectsToStayInDialog = true;

    if (options.address) {
      self.config.route = getRouteUrl(options.address);
    }

    if (options.data) {
      self.config.routeData = options.data;
    }

    self.init();
  },

  close: function (data) {
    this.closing = true;
    this.data = this.data || data;

    if (this.dialogElement) {
      this.dialogElement.modal("hide");
    }

    if (this.restoreCrossPcn) {
      enterpriseManager.restoreCrossPcn();
    }

    if (!this.cancelled) {
      banner.setPreviousBanner();
      banner.showPreviousBanner();
    }

    if (this.config.forceRedirectsToStayInDialog) {
      this.resetState();
    }

    if (this.dialogElement[0]) {
      pubsub.publish("closeDialog" + this.dialogElement[0].id);
    }
  },

  cancel: function () {
    this.cancelled = true;
    this.close();
  },

  resetState: function () {
    let sub;
    while ((sub = this._stateSubscriptions.pop())) {
      sub.dispose();
    }

    dialogState.reset();
  },

  returnAction: function (_data) {
    // can be replaced by consumers
  },

  dispose: function () {
    repository.remove(this.id);
    dialogStack.remove(this);

    if (this.contentController && "dispose" in this.contentController) {
      this.contentController.dispose();
    }

    this.contentController = null;

    if (this.dialogElement) {
      $(".modal-dialog .ui-draggable:first").off("drag.dialog");
      this.dialogElement.off();
      this.dialogElement.modal("removeBackdrop");
      ko.cleanNode(this.dialogElement[0]);

      if (this.dialogElement.parent().hasClass("dialog-wrapper")) {
        this.dialogElement.parent().remove();
      }
    }

    // return focus to the previously focused field (before dialog was opened)
    if (this.previouslyFocusedElement) {
      this.previouslyFocusedElement.focus();
    }
  }
};

DialogController.create = function (config) {
  /// <summary>
  /// Creates a new instance of a Dialog Controller.
  /// </summary>
  /// <param name="config">
  ///   route: (required) The route that contains the content you want to display.
  ///   show: (default: true) Display the modal dialog immediately upon instantiation.
  /// </param>
  /// <returns type="">An instance of the Dialog Controller.</returns>

  return new DialogController(config);
};

/**
 * Finds the dialog controller for the given child element. If not
 * found null is returned.
 *
 * @param {String|Element} elementOrId
 * @returns The dialog controller or null
 */
DialogController.find = function (elementOrId) {
  let element = elementOrId;
  if (typeof element === "string" && element[0] !== "#" && element[0] !== ".") {
    element = document.getElementById(element) || element;
  }

  const $dialog = $(element).closest(".plex-dialog");
  if ($dialog.length > 0) {
    return ko.dataFor($dialog[0]);
  }

  return null;
};

function getRouteUrl(url) {
  if (!url || url.indexOf("__dialog") >= 0) {
    return url;
  }

  return url + (url.indexOf("?") >= 0 ? "&" : "?") + "__dialog=1";
}

$(document).on("shown.bs.modal", (event) => {
  const modalController = $(event.target).data("bs.modal");

  if (modalController.$backdrop) {
    modalController.$backdrop.css("z-index", DIALOG_Z_INDEX + 10 * $(".modal.fade.in").length);
  }

  if (modalController.$element) {
    modalController.$element.css("z-index", DIALOG_Z_INDEX + 10 + 10 * $(".modal.fade.in").length);
  }
});

// to prevent scrollbar appearing when closing second modal
$(document).on("hidden.bs.modal", ".modal", () => {
  if ($(".modal:visible").length > 0) {
    $(document.body).addClass("modal-open");
  }
});

module.exports = DialogController;
plexExport("DialogController", DialogController);
