ï»¿const goto = require("../Goto/plex-goto");
const Action = require("./plex-actions");
const FilterController = require("../Controls/plex-controller-filter");
const plexExport = require("../../global-export");

const GotoAction = Action.extend({
  onInit: function () {
    // can be overriden
  },

  onExecute: function () {
    if (!(this.parent instanceof FilterController)) {
      throw new Error("Source for Goto action must be filter model.");
    }

    this.gotoController.gotoExecuting = true;

    // Update Grid Filter Model
    this.parent.data[this.config.startRecordProperty] = this.startRecordValue();
    this.gotoController.saveState();

    // Call Search on Filter
    this.parent.search();
  }
});

GotoAction.configure = function (action, data, actionBar) {
  if (!action.gotoController) {
    // action.disabled(true);
    action.actionBar = actionBar;
    action.gotoController = goto.create(action);
  }
};

module.exports = GotoAction;
plexExport("actions.Goto", GotoAction);
