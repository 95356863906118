ï»¿/* eslint-disable no-invalid-this */
const plexExport = require("../../global-export");

const ConstantToken = function (value) {
  this.getValue = function () {
    return value;
  };

  this.setValue = function (text) {
    value = text;
  };
};

module.exports = ConstantToken;
plexExport("tokens.constant", ConstantToken);
