ï»¿const compiler = require("../Expressions/plex-expressions-compiler");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const noop = function () {
  // noop
};
const propAlias = {
  visible: "render",
  clientVisible: "render"
};

function setFeatureValue(result, entry, value) {
  if (entry.type === "prop") {
    // todo: write no properties are written directly to the result
    // it'd be a lot cleaner to have a `prop` object for these
    result[propAlias[entry.id] || entry.id] = value;
  } else {
    result[entry.type][entry.id] = value;
  }
}

const DynamicFeature = Feature.extend({
  onInit: function () {
    this.config.entries.forEach((entry) => {
      entry.evaluator = entry.valueExpression ? compiler.compile(entry.valueExpression) : noop;
    });
  },

  onExecute: function () {
    const args = arguments;
    const self = this;

    const result = { style: {}, attr: {} };

    this.config.entries.forEach((entry) => {
      let value = entry.evaluator.apply(self, args);
      value = value == null ? entry.value : value;

      if (value !== undefined) {
        setFeatureValue(result, entry, value);
      }
    });

    return result;
  },

  onConditionFailed: function () {
    const args = arguments;
    const result = { style: {}, attr: {} };

    this.config.entries.forEach((entry) => {
      let value = entry.evaluator.apply(self, args);
      value = value == null ? entry.value : value;

      if (typeof value === "boolean") {
        setFeatureValue(result, entry, !value);
      }
    });

    return result;
  }
});

module.exports = DynamicFeature;
plexExport("features.Dynamic", DynamicFeature);
