ï»¿const ko = require("knockout");
const defaultValueProvider = require("./plex-grid-value-providers");
const { getAttachmentCount } = require("../../Actions/attachment-repository");
const { getAttachmentsCountTokenName } = require("../../Utilities/plex-utils-dcs-attachments");
const plexExport = require("../../../global-export");

const AttachmentsColumnValueProvider = defaultValueProvider.extend({
  templateName: "grid-attachment",

  getValue: function (record) {
    const attachmentProperty = getAttachmentsCountTokenName(this.column.attachmentGroupKey);
    return ko.unwrap(record[attachmentProperty]);
  },

  getHtml: function () {
    return this.getTrackedHtml.apply(this, arguments);
  },

  getTrackedHtml: function (record, index, colIndex, features) {
    const attachmentProperty = getAttachmentsCountTokenName(this.column.attachmentGroupKey);
    if (!ko.isObservable(record[attachmentProperty])) {
      const observable = (record[attachmentProperty] = ko.observable(0));
      getAttachmentCount(this.column, record).then(observable);
    }

    return this.renderTemplate(this.templateName, {
      value: record[attachmentProperty],
      options: this.column,
      data: record,
      disabled: features.disabled
    });
  }
});

module.exports = AttachmentsColumnValueProvider;
plexExport("grid.valueProviders.AttachmentsColumnValueProvider", AttachmentsColumnValueProvider);
