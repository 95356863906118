ï»¿const dataSourceFactory = require("./plex-datasource-factory");
const plexExport = require("../../global-export");

const routes = {
  get(name) {
    return this[name];
  },

  load(config) {
    if (!config) {
      return;
    }

    Object.keys(config).forEach((name) => {
      this[name] = dataSourceFactory.create(config[name]);
    });
  },

  clear() {
    Object.keys(this).forEach((name) => {
      if (!/^(?:get|load|clear)$/.test(name)) {
        delete this[name];
      }
    });
  }
};

module.exports = routes;
plexExport("data.routes", routes);
