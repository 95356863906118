ï»¿const $ = require("jquery");
const jsUtils = require("../Utilities/plex-utils-js");
const stringUtils = require("../Utilities/plex-utils-strings");
const pubsub = require("../Core/plex-pubsub");
const plexExport = require("../../global-export");

require("./plex-mixins-disposable"); // eslint-disable-line import/no-unassigned-import

const EVENT_FORMAT = "event.{0}.{1}";
const defaultOptions = { async: true };
const slice = Array.prototype.slice;
let uid = 0;

function Eventable() {
  // constructor
}

Eventable.prototype = {
  constructor: Eventable,

  events: ["disposing"],

  on: function (eventName, callback, options) {
    /// <summary>Subscribes the callback to the given event name. If the event name is not supported an error is thrown.</summary>
    /// <param name="eventName">The event name.</param>
    /// <param name="callback">The callback function.</param>
    /// <returns type="Object">The disposable subscription object.</returns>
    let event;
    if (this.events.indexOf(eventName) === -1) {
      event = this.events.find((eve) => {
        if (jsUtils.isObjectLike(eve)) {
          return eve.eventName === eventName;
        }
        return false;
      });
      if (!event) {
        throw new Error("Event is not supported: " + eventName);
      }
    }
    const eventOptions = jsUtils.isObjectLike(event) ? event.defaultOptions : defaultOptions;
    options = $.extend({}, eventOptions, options);
    eventName = stringUtils.format(EVENT_FORMAT, eventName, getEventId(this));
    return this.addDisposable(pubsub.subscribe(eventName, callback, null, options));
  },

  one: function (eventName, callback, options) {
    /// <summary>Subscribes the callback to the given event name for a single execution. If the event name is not supported an error is thrown.</summary>
    /// <param name="eventName">The event name.</param>
    /// <param name="callback">The callback function.</param>
    /// <returns type="Object">The disposable subscription object.</returns>

    let subscription;
    const wrappedCallback = function () {
      if (!subscription.disposed) {
        // eslint-disable-next-line no-invalid-this
        callback.apply(this, arguments);
        subscription.dispose();
      }

      subscription = null;
    };

    subscription = this.on(eventName, wrappedCallback, options);
    return subscription;
  },

  fire: function (eventName /* , args */) {
    /// <summary>Fires the given event name. Any additional arguments are passed to the registered callbacks.</summary>
    /// <param name="eventName">The name of the event.</param>

    eventName = stringUtils.format(EVENT_FORMAT, eventName, getEventId(this));
    pubsub.publish.apply(pubsub, [eventName].concat(slice.call(arguments, 1)));
  },

  dispose: function () {
    this.fire("disposing");
    this._base.apply(this, arguments);
  }
};

function getEventId(target) {
  if (target.config && target.config.id) {
    return target.config.id;
  }

  if (target._eventId != null) {
    return target._eventId;
  }

  target._eventId = ++uid;
  return target._eventId;
}

jsUtils.mixin(Eventable, "disposable");

module.exports = Eventable;
plexExport("mixins.events", Eventable);
