ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const jsUtils = require("../../Utilities/plex-utils-js");
const plexExport = require("../../../global-export");

const DefaultHandler = function () {
  // constructor
};

DefaultHandler.prototype = {
  constructor: DefaultHandler,

  init: function (draggable) {
    this.config = draggable.config;
    this.$element = draggable.$element;
    this.controller = draggable.controller;
    this.draggable = draggable;
  },

  getHelper: function () {
    /// <summary>Creates the draggable clone and sets the draggable data.</summary>
    /// <returns type="function">The function that creates the draggable clone.</returns>

    const self = this;
    return function (_e) {
      self.draggable.data = [];
      if (self.controller && self.controller.elements && self.controller.data) {
        const element = self.controller.elements[self.$element[0].id];
        if (element) {
          self.draggable.data = self.controller.data[element.propertyName];
        }
      }

      return self.$element.clone().addClass("plex-draggable-item-dragging");
    };
  },

  getDraggables: function () {
    /// <summary>Returns the draggable items for the current element.</summary>
    /// <returns type="jQuery Object">The draggable element or items inside the element depending on the configuration.</returns>

    const self = this;
    if (self.config.draggableItem !== "self") {
      return self.$element.find(self.getItemSelector());
    }

    return self.$element.closest(".plex-controls, .plex-control-group");
  },

  getItemSelector: function () {
    return this.config.draggableItem + ":not(.ui-draggable)";
  },

  destroyCurrentDraggables: function () {
    /// <summary>Destroys current draggables.</summary>

    const self = this;
    if (self.draggable.$draggables && self.$element) {
      self.$element.find(".ui-draggable").each(function () {
        if ($(this).data("ui-draggable")) {
          $(this).draggable("destroy");
        }

        $(this).removeClass("plex-draggable-item");
      });

      if (self.$element.hasClass("ui-draggable") && self.$element.data("ui-draggable")) {
        self.$element.draggable("destroy");
      }

      self.$element.removeClass("plex-draggable-item");
    }
  },

  subscribeToDomChanges: function (fnCreateDraggables) {
    fnCreateDraggables();

    // We don't have the listen to dom changes in default mode.
    // The default handler is handling element itself and not the items inside of it.
  }
};

jsUtils.makeExtendable(DefaultHandler);

module.exports = DefaultHandler;
plexExport("draggables.handlers.DefaultHandler", DefaultHandler);
