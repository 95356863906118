ï»¿const TokenParser = require("../Tokens/plex-tokens-parser");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();

function OutputExpression(expr) {
  // eslint-disable-next-line no-invalid-this
  this.init(expr);
}

OutputExpression.prototype = {
  constructor: OutputExpression,

  init: function (expr) {
    this.evaluator = tokenParser.compile(expr.body);
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
};

module.exports = OutputExpression;
plexExport("expressions.Output", OutputExpression);
