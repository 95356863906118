ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const comparableUtils = require("../Utilities/plex-utils-comparable");
const knockoutUtils = require("./plex-utils-knockout");

const KEY_ERROR = "The key property or record identifiers must be set when creating a crosstab grid.";

ko.extenders.crosstab = function (target, config) {
  /// <summary>Returns a read-only computed crosstab of the underlying data. The target observable must return an array.</summary>
  /// <param name="target">The target observable.</param>
  /// <param name="config">
  /// A configuration object. This should contain two properties:
  ///   - keyPropertyName: the unique key for a row of data.
  ///   - pivotKeyPropertyName: the property the data will be pivoted on.
  /// </param>
  /// <returns type="Observable">The crosstabbed data.</returns>

  const newArray = ko.observableArray();
  const observable = ko.pureComputed({ read: newArray }).extend({ trackArrayChanges: true });
  observable.$$source = newArray;
  let currentComparer = null;

  if (!config.keyPropertyName && (!config.recordIdentifiers || config.recordIdentifiers.length === 0)) {
    throw new Error(KEY_ERROR);
  }

  target.subscribe(
    () => {
      const source = target.peek();
      newArray(pivotData(source, config, currentComparer));
    },
    null,
    "arrayChange"
  );

  // the array is read-only but sortable
  observable.sort = observable.mergeSort = observable.stableSort = function (comparer) {
    currentComparer = comparer;
    newArray.stableSort(comparer);
    return observable;
  };

  observable.reverse = function () {
    if (currentComparer) {
      currentComparer = reverseComparer(currentComparer);
    }

    newArray.reverse();
    return observable;
  };

  knockoutUtils.addNonMutatingArrayMethods(observable);
  return observable;
};

function pivotData(source, config, currentComparer) {
  const results = [];
  const buckets = getBuckets(source, config);
  let currentRecord = null;
  const pivotComparer = createComparer(config);
  const dirtyTracking = source.length > 0 && "$$dirtyFlag" in source[0];
  let record;

  // the sort should be stable, because of the issues like VP-1087
  source = arrayUtils.stableSort(source, pivotComparer);

  for (let i = 0, ln = source.length; i < ln; i++) {
    record = source[i];
    if (!currentRecord || pivotComparer(record, currentRecord) !== 0) {
      addEmptyBuckets(record, buckets);
      results.push(record);
      currentRecord = record;
    }

    addToBucket(currentRecord, record, config, dirtyTracking);
  }

  if (currentComparer) {
    return arrayUtils.stableSort(results, currentComparer);
  }

  return results;
}

function getBuckets(source, config) {
  if (config.buckets) {
    return config.buckets;
  }

  if (config.pivotColumns && config.pivotColumns.length > 0) {
    // just need the keys in the existing sort order
    // these can be stored on the config since they won't be changing
    return (config.buckets = config.pivotColumns.map((record) => {
      return record[config.pivotKeyPropertyName];
    }));
  }

  let keys = [];
  const keySet = {};

  for (let i = 0, ln = source.length; i < ln; i++) {
    const key = source[i][config.pivotKeyPropertyName];
    if (!(key in keySet)) {
      keys.push(key);
      keySet[key] = source[i];
    }
  }

  if (config.pivotSortOrder && config.pivotSortOrder.length > 0) {
    const sortArray = [];

    keys.forEach((k) => {
      const sortObject = { key: k };
      config.pivotSortOrder.forEach((sortOrder) => {
        // declare only properties defining sort order
        sortObject[sortOrder.propertyName] = keySet[k][sortOrder.propertyName];
      });
      sortArray.push(sortObject);
    });

    const comparers = config.pivotSortOrder.map((sortOrder) => {
      return comparableUtils.createComparer(sortOrder.propertyName, sortOrder.ascending);
    });

    // if all predefined comparers returned 0, compare pivot keys
    comparers.push(comparableUtils.createComparer("key"));

    sortArray.sort(comparableUtils.createMergedComparer(comparers));
    keys = sortArray.map((sortItem) => {
      return sortItem.key;
    });
  } else {
    // todo: might need a more robust sort or allow user to sort on header text
    keys.sort(dataUtils.compareValues);
  }

  return keys;
}

function addEmptyBuckets(record, buckets) {
  record.$$buckets = [];
  buckets.forEach((key) => {
    record.$$buckets.push({ $$key: key });
  });
}

function addToBucket(record, pivotRecord, config, dirtyTracking) {
  // todo: this might end up being a decent micro-optimization to switch this from an array
  // to an object literal - it'll take some IA though
  const key = pivotRecord[config.pivotKeyPropertyName];
  const bucket = ko.utils.arrayFirst(record.$$buckets, (b) => {
    return b.$$key === key;
  });

  if (bucket) {
    if (bucket.$$data) {
      throw new Error("Duplicate pivot data found. There should be a unique record per record key/pivot key.");
    }

    if (dirtyTracking) {
      pivotRecord.$$dirtyFlag = ko.dirtyFlag(pivotRecord);
    }

    bucket.$$data = pivotRecord;
    copyMetadata(record, pivotRecord);
  }
}

function createComparer(config) {
  if (config.keyPropertyName) {
    return comparableUtils.createComparer(config.keyPropertyName);
  }

  if (config.recordIdentifiers && config.recordIdentifiers.length > 0) {
    return comparableUtils.createGroupComparer(config.recordIdentifiers);
  }

  // should never get here
  throw new Error(KEY_ERROR);
}

function reverseComparer(comparer) {
  return (a, b) => comparer(b, a);
}

function copyMetadata(parent, child) {
  child.$$rendered = parent.$$rendered;
}
