ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

function getCssValue(options, propertyName, record) {
  if (options[propertyName]) {
    return dataUtils.getValue(record, options[propertyName], true);
  }

  return null;
}

ko.bindingHandlers.icon = {
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const wrappedAccessor = function () {
      const css = [];
      const config = valueAccessor();
      const iconCss = getCssValue(config.options, "propertyName", config.data) || config.options.iconClassName;
      const colorCss = getCssValue(config.options, "colorPropertyName", config.data) || config.options.colorClassName;

      if (iconCss) {
        css.push(iconCss);
      }

      if (colorCss) {
        css.push(colorCss);
      }

      if (config.options.size) {
        css.push(config.options.size);
      }

      return css.join(" ");
    };

    // we're just going to leverage the className binding to handle changes
    return ko.bindingHandlers.className.update.call(
      this,
      element,
      wrappedAccessor,
      allBindings,
      viewModel,
      bindingContext
    );
  }
};
