ï»¿const ko = require("knockout");

function indexArray(arr, propertyName, offset) {
  arr.forEach((record, i) => {
    if (ko.isObservable(record[propertyName])) {
      record[propertyName](i + offset);
    } else {
      record[propertyName] = i + offset;
    }
  });
}

ko.extenders.indexed = function (target, propertyNameOrOptions) {
  let indexing = false;
  let moving = false;
  const subs = [];
  let offset = 0;
  let propertyName;

  if (typeof propertyNameOrOptions === "string") {
    propertyName = propertyNameOrOptions;
  } else {
    propertyName = propertyNameOrOptions.propertyName;
    offset = propertyNameOrOptions.offset || offset;
  }

  subs.push(
    target.subscribe((values) => {
      indexing = true;
      indexArray(values, propertyName, offset);
      indexing = false;
    })
  );

  subs.push(
    target.subscribe(
      (changes) => {
        changes.forEach((change) => {
          if ("moved" in change || moving) {
            return;
          }

          if (change.status === "deleted") {
            // if the record has been deleted go ahead and delete the sub
            let i = subs.length;
            let sub;

            while (i--) {
              // the subscription may be undefined now
              sub = subs[i];
              if (!sub || sub.record === change.value) {
                subs.splice(i, 1);
                if (sub) {
                  sub.dispose();

                  break;
                }
              }
            }
          } else if (change.status === "added") {
            // add a subscription, only if the property is observable
            // todo: also look at wrapped observables
            if (ko.isObservable(change.value[propertyName])) {
              subs.push(listenForIndexChange(change.value));
            }
          }
        });
      },
      null,
      "arrayChange"
    )
  );

  // set initial values
  indexArray(target.peek(), propertyName, offset);
  target.forEach(listenForIndexChange);

  function listenForIndexChange(record) {
    if (!ko.isObservable(record[propertyName])) {
      return;
    }

    const sub = record[propertyName].subscribe((newIndex) => {
      if (indexing || moving) {
        return;
      }

      newIndex -= offset;
      const currentIndex = target.indexOf(record);
      if (currentIndex === newIndex) {
        return;
      }

      moving = true;
      target.remove(record);
      target.splice(newIndex, 0, record);
      indexArray(target(), propertyName, offset);
      moving = false;
    });

    sub.record = record;
    subs.push(sub);
  }

  let originalDispose;
  target.dispose = function () {
    let sub;
    while ((sub = subs.pop())) {
      sub.dispose();
    }

    if (originalDispose) {
      originalDispose.call(target);
    }
  };

  return target;
};
