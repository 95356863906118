const ko = require("knockout");
const $ = require("jquery");
const Popover = require("./plex-popover");

ko.bindingHandlers.popover = {
  init: function (element, valueAccessor, _allBindingsAccessor, _viewModel, bindingContext) {
    const popoverInfo = valueAccessor();
    let popover = new Popover(element, popoverInfo, bindingContext);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      popover.dispose();
      popover = null;
    });
  }
};

ko.bindingHandlers.closePopover = {
  init: function (element, valueAccessor, _allBindingsAccessor, _viewModel, _bindingContext) {
    const options = valueAccessor();
    const $element = $(element);
    const $popoverElement = $(options.popoverElement);

    const closePopover = function () {
      const instance = $popoverElement.data("popover-instance");
      if (instance) {
        instance.close();
      }
    };

    $element.on("click.closePopover", closePopover);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $element.off("click.closePopover", closePopover);
      closePopover();
    });
  }
};
