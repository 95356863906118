ï»¿const ContainerLabelPrintController = require("./Controllers/plex-labelprinting-controller-container");
const plexExport = require("../../../global-export");

const printContainerLabel = function (containerSerials, options) {
  const controller = new ContainerLabelPrintController();
  const promise = controller.print(containerSerials, options);
  return promise;
};

module.exports = printContainerLabel;
plexExport("barcoding.labelPrinting.containerLabels.printContainerLabel", printContainerLabel);
