const ko = require("knockout");
const $ = require("jquery");
const dom = require("../Utilities/plex-utils-dom");

ko.bindingHandlers.advanceOnEnter = {
  init: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    if (value) {
      $(element).on("keypress.plex.advanceOnEnter", (e) => {
        if (e.which !== 13) {
          return;
        }
        dom.advanceFocus(element, e.shiftKey);
      });

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        $(element).off("keypress.plex.advanceOnEnter");
      });
    }
  }
};
