ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const PickerTrackRequestUpdates = Feature.extend({
  onExecute: function () {
    const self = this;
    if (self.element && self.element.controller && self.element.controller.isValidRequest) {
      return { disabled: self.element.controller.isValidRequest() !== true };
    }

    return {};
  }
});

module.exports = PickerTrackRequestUpdates;
plexExport("features.PickerTrackRequestUpdates", PickerTrackRequestUpdates);
