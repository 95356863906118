ï»¿const $ = require("jquery");
const ko = require("knockout");
const gridUtils = require("../plex-grid-utils");
const recordTargets = require("../plex-grid-recordtarget");
const RecordElementController = require("../plex-grid-controller-element");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const ElementRowCellProcessor = require("./plex-grid-row-element-cell-processor");
const Writer = require("./plex-grid-writer-base");
const plexExport = require("../../../global-export");

// todo: this does not implement all of the functionality of the default footer/header writer
// for example, having updatable features is not supported here - this is
function createAggregateContext(sectionContext, column, target) {
  let targetData, data;
  if (target && target === recordTargets.group) {
    targetData = gridUtils.aggregateData(sectionContext.group, true);
  } else {
    targetData = sectionContext.data;
  }

  data = column.valueProvider.getRecords(targetData);
  if ("bucketIndex" in column) {
    // we could safely run map this against any kind of column but to reduce overhead
    // we're only doing this when we know we need this, which is with pivot columns
    data = data.map(column.valueProvider.getRecord, column.valueProvider);
  }

  return {
    id: sectionContext.id,
    section: sectionContext.section,
    group: sectionContext.group,
    data
  };
}

const GridSectionWriter = Writer.extend({
  init: function (grid, group, section) {
    this._base.apply(this, arguments);

    this.group = group;
    this.hasGroup = this.group !== this.grid;
    this.section = section;

    if (section.features && section.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(section.features, section, this.parent);
    }

    this.reset();
  },

  prerender: function (_record, _index, _data) {
    if (this.section.clientVisible === false) {
      return false;
    }

    return this._base.apply(this, arguments);
  },

  render: function (writer, data, config) {
    const self = this;
    const groupData = gridUtils.aggregateData(data, false);
    let i = 0;
    let colspan = config.checkable ? 1 : 0;
    const firstRecord = groupData[0];
    const sectionId = getSectionId(data, this.section);
    const sectionContext = {
      id: sectionId,
      section: this.section,
      group: data,
      data: groupData
    };

    let ctrl, td;

    const tr = this.writeRowStart(writer, data);

    // this is where footer/header controllers will be stored
    if (firstRecord) {
      firstRecord.$$sections = firstRecord.$$sections || {};
      ctrl = firstRecord.$$sections[sectionId] =
        firstRecord.$$sections[sectionId] ||
        new RecordElementController(firstRecord, sectionContext, null, this.grid.options);
    }

    this.grid.columns.forEach((column, colIndex) => {
      const cell = self.cells[i];
      const elements = (cell.elements && cell.elements) || (cell.element ? [cell.element] : []);
      let visible = column.visible();
      let features, aggregateContext;

      if (visible) {
        colspan++;
      }

      if (i < self.cells.length && (cell.colIndex === colIndex || cell.endColIndex === colIndex)) {
        if (cell.endColIndex > 0 && cell.endColIndex !== colIndex) {
          // if an end index exists wait until the end column is reached until we render
          return;
        }

        // if the current column is not visible but a colspan exists that indicates that one of the columns
        // is visible across the columns this cell spans so it should still render
        visible = visible || colspan > 0;

        if (visible) {
          td = tr.appendChild("td");
          features = getFeatures(cell, colspan, groupData);
          self.writeAttributes(td, features.attr);
          self.writeCss(td, features.css);
          self.writeStyle(td, features.style);
        }

        if (cell.operator && groupData.length > 0) {
          // todo: this would be much, much better if the aggregate was just another value provider/element
          // and it could go through the normal element processing
          aggregateContext = firstRecord.$$sections[sectionId].elements[cell.id] = {
            value: ko.pureComputed(() => {
              return cell.operator.getValue(groupData, self.section, data);
            }),
            formattedValue: ko.pureComputed(() => {
              // todo: update this to use the value observable to prevent potential duplicate processing
              return cell.operator.getFormattedValue(groupData, self.section, data);
            })
          };

          if (visible) {
            td.appendChild("span")
              .addAttr("id", cell.id + "_" + sectionId)
              .appendHtml(ko.renderTemplate("grid-text", { value: aggregateContext.formattedValue }))
              .close();
          }
        }

        if (!cell.operator && elements.length > 0) {
          if (visible) {
            aggregateContext = createAggregateContext(sectionContext, column, getRecordTarget(elements));
            td.appendHtml(
              cell.valueProvider.getHtml(
                aggregateContext.data[0] || $.extend({}, self.grid.options.emptyRecord),
                aggregateContext,
                colIndex,
                features
              ) || cell.valueProvider.getEmptyHtml()
            );
          } else if (ctrl) {
            // just initialize the element - this will add it to the controller and make it accessible if any other aggregates depend on it
            elements.forEach((el) => {
              ctrl.initElement(el);
            });
          }
        }

        if (visible && ((!cell.operator && elements.length === 0) || (cell.operator && groupData.length === 0))) {
          td.appendHtml("&nbsp;");
        }

        if (visible) {
          td.close();
        }

        colspan = 0;
        i++;
      }
    });

    // this probably won't get hit anymore with the spacers in place
    if (colspan) {
      tr.appendChild("td").addAttr("colspan", colspan).appendHtml("&nbsp;").close();
    }

    tr.close();
  },

  writeRowStart: function (writer, data) {
    const tr = writer.appendChild("tr");
    tr.addClass("plex-grid-" + this.section.type);

    if (Array.isArray(data)) {
      // todo: do we need the total css?
      tr.addClass("plex-grid-" + this.section.type + "-total");
    } else {
      tr.addClass("plex-grid-group-" + this.section.type);
      tr.addClass("plex-grid-group-level-" + this.section.index);
      tr.addAttr("data-group-" + this.section.type, this.section.index + "." + data.index);
      // todo: normalize the css between footer/header so we can simplify this
    }

    return tr;
  },

  reset: function () {
    const elementRowCellProcessor = new ElementRowCellProcessor(this.grid, this.parent);
    this.cells = elementRowCellProcessor.process(this.section.cells);
  }
});

function getFeatures(cell, colspan, groupData) {
  // this looks like Function.prototype.apply, but really isn't...
  let features = cell.featureProcessor
    ? // eslint-disable-next-line no-useless-call
      ko.unwrap(cell.featureProcessor.apply(cell, [groupData[0], null, groupData]))
    : {};
  features = features || {};

  if (cell.operator && cell.operator.css) {
    features.css = cell.operator.css;
  }

  if (cell.alignment) {
    features.css = features.css || [];
    features.css.push("plex-grid-cell-align-" + cell.alignment);
  }

  if (colspan > 1) {
    features.attr = features.attr || {};
    features.attr.colspan = colspan;
  }

  return features;
}

function getSectionId(group, section) {
  let suffix = section.type + "." + section.index;
  if (!Array.isArray(group)) {
    // sub total
    suffix += "." + group.index;
  }

  return suffix;
}

function getRecordTarget(elements) {
  let target = null;
  for (let i = 0; i < elements.length; i++) {
    const aggregate = elements[i].aggregate;
    if (aggregate) {
      if (aggregate.target === recordTargets.group) {
        target = recordTargets.group;
      } else if (aggregate.target === recordTargets.record) {
        target = recordTargets.record;
        break;
      }
    }
  }

  return target;
}

module.exports = GridSectionWriter;
plexExport("grid.writers.ElementRowWriter", GridSectionWriter);
plexExport("grid.writers.GridSectionWriter", GridSectionWriter);
