ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");

const states = {
  indeterminate: 2,
  checked: 1,
  unchecked: 0
};

const defaultOptions = {
  tristate: true
};

function TristateCheckbox(el, options) {
  this.$element = $(el);
  this.options = $.extend({}, defaultOptions, options);
}

TristateCheckbox.prototype = {
  constructor: TristateCheckbox,

  setState: function (state) {
    const currentState = this.getState();
    state = typeof state === "string" ? states[state.toLowerCase()] : state;

    if (!this.options.tristate && state === states.indeterminate) {
      state = states.checked;
    }

    // the states cycle through indeterminate > checked > unchecked
    switch (state) {
      // unchecked, going indeterminate
      case states.indeterminate:
        this.$element.data("checked", state).prop("indeterminate", true);
        break;

      // indeterminate, going checked
      case states.checked:
        this.$element.data("checked", state).prop("indeterminate", false).prop("checked", true);
        break;

      // checked, going unchecked
      case states.unchecked:
        this.$element.data("checked", state).prop("indeterminate", false).prop("checked", false);
        break;

      default:
        throw new Error("Invalid state selected for TristateCheckbox");
    }

    // note: only fire event if the state has changed, however allow the updates
    // to occur in case the state changed outside of the plugin and not all
    // attributes were set.
    if (currentState !== state) {
      this.$element.trigger("stateChanged.tristateCheckbox", [state]);
    }
  },

  getState: function () {
    const state = this.$element.data("checked");

    if (state !== undefined) {
      return state;
    }

    // state is not set - we need to derive state based on element's attributes
    if (this.$element.prop("indeterminate")) {
      return states.indeterminate;
    }

    return this.$element.prop("checked") ? states.checked : states.unchecked;
  },

  setOption: function (key, value) {
    this.options[key] = value;
  }
};

$.fn.tristateCheckbox = function (options) {
  const args = $.makeArray(arguments);

  return this.each(function () {
    const $this = $(this);
    let data = $this.data("tristateCheckbox");

    if (!data) {
      // set the attribute - this is what the global click event is looking for
      $this.attr("data-tristate", "true");
      $this.data("tristateCheckbox", (data = new TristateCheckbox(this, options)));
    }

    if (typeof options === "string" && options in data) {
      data[options].apply(data, args.slice(1));
    }
  });
};

$.fn.tristateCheckbox.Constructor = TristateCheckbox;
$.tristateCheckbox = {
  states
};

// inspired by: http://css-tricks.com/indeterminate-checkboxes/
$(document).on("click", "input[type='checkbox'][data-tristate]", function (_e) {
  const $el = $(this);
  let state = $el.data("checked");

  if (state === undefined) {
    // attribute not set yet so base it on the *prior* state
    if ($el.prop("indeterminate")) {
      state = states.unchecked;
    } else {
      state = this.checked ? states.indeterminate : states.checked;
    }
  }

  // advance to next state
  $el.tristateCheckbox("setState", state === states.unchecked ? states.indeterminate : state - 1);
});
