ï»¿const stringUtils = require("../Utilities/plex-utils-strings");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

const SpaceToken = Token.extend({
  getValue: function () {
    let count = this.getAttr("value", arguments);
    if (count !== 0) {
      count = count || 1;
      return stringUtils.repeat(count, "&nbsp;");
    }

    return "";
  }
});

module.exports = SpaceToken;
plexExport("tokens.sp", SpaceToken);
