ï»¿const bindingHandler = require("../plex-grid-binding-handler");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const EditableFeature = Feature.extend({
  onExecute: function (record, _index, _records) {
    const results = { css: [] };
    results.css.push(this._currentRowCss);

    if (this.column.type) {
      switch (this.column.type) {
        case "Boolean":
          if (this.column.propertyName) {
            // convert value into a boolean
            if (typeof record[this.column.propertyName] === "string") {
              record[this.column.propertyName] = record[this.column.propertyName] === "1";
              record.$$dirtyFlag.reset();
            }
          }
          results.content = bindingHandler.addCheckbox(record, this.column.propertyName);
          break;
        // TODO: add more types as needed default is the textbox
        default:
          results.content = bindingHandler.addTextbox(record, this.column.propertyName);
          break;
      }
    }

    return results;
  }
});

module.exports = EditableFeature;
plexExport("grid.features.Editable", EditableFeature);
