ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const VisibleFeature = Feature.extend({
  onConditionFailed: function () {
    // The visible feature causes issues on sorting grids
    if (this.parent) {
      this.parent.canSync = false;
    }

    return {
      render: false
    };
  }
});

module.exports = VisibleFeature;
plexExport("features.Visible", VisibleFeature);
