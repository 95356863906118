ï»¿const ko = require("knockout");
const $ = require("jquery");
const Controller = require("./plex-controller-base");
const dataUtils = require("../Utilities/plex-utils-data");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

// todo:
// - save/restore state
// - mastergrid support
// - maybe allow different kinds of comparisons (starts with, etc)

const LiveFilter = Controller.extend({
  onInit: function () {
    this.config.elements.forEach(this.initElement, this);
    this.initElement(this);
    ko.applyBindings(this, this.$element[0]);
  },

  onPostInit: function () {
    const self = this;

    // todo: why am i having to do this?
    self.parent = plexImport("currentPage")[self.config.parentId];
    self.filterValues = ko.computed(() => {
      return dataUtils.cleanse(self.data, { detectDependencies: true });
    });

    self._disposables.push(self.filterValues.subscribe(self.applyFilter, self));
  },

  applyFilter: function (values) {
    if ($.isEmptyObject(values)) {
      this.parent.datasource.clearFilter();
    } else {
      this.parent.datasource.filter((record) => {
        let prop;
        let result = true;
        for (prop in values) {
          // ignore case for strings
          if (typeof values[prop] === "string") {
            const value = values[prop].toLowerCase();
            const propValue = String(record[prop] || "").toLowerCase();
            result = result && (value === propValue || propValue.indexOf(value) !== -1);
          } else {
            // just do coercive comparison in case types don't match
            // eslint-disable-next-line eqeqeq
            result = result && values[prop] == record[prop];
          }
        }
        return result;
      });
    }
  }
});

module.exports = LiveFilter;
plexExport("LiveFilter", LiveFilter);
