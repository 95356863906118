ï»¿const jsUtils = require("../Utilities/plex-utils-js");
const ConstantToken = require("./plex-tokens-constant");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const tokenRgx = /^\s*\{\s*(\w+)\s*:?\s*(.+)?\}\s*$/;
const root = plexImport("tokens");

function Token() {
  // constructor
}

Token.parse = function (text) {
  function getTokenClass(value) {
    const match = tokenRgx.exec(value);

    const tokenName = match[1];

    if (tokenName in root) {
      return root[tokenName];
    }

    return null;
  }

  const T = getTokenClass(text);

  if (T !== null) {
    const token = new T();

    token.parse(text);

    return token;
  }

  return null;
};

Token.prototype = {
  constructor: Token,

  tokenRgx,

  getValue: function (_record, _i, _records) {
    // this should be overriden
  },

  getAttr: function (key, args) {
    key = key.toLowerCase();
    if (this.attr && key in this.attr) {
      return this.attr[key].getValue.apply(this.attr[key], args);
    }

    return null;
  },

  setAttr: function (key, token) {
    this.attr = this.attr || {};
    this.attr[key.toLowerCase()] = token;
  },

  parse: function (text) {
    const match = tokenRgx.exec(text);
    const tokenAttributesString = match[2];

    if (tokenAttributesString) {
      this.parseAttributes(tokenAttributesString);
    }
  },

  parseAttributes: function (tokenAttributesString) {
    // do not share this regex due to recursive calls to this function
    const attrRgx = /(?:^\s*|,\s*|\s*)(?:(\w+)\s*=\s*'\s*(.+?)(?:',))/g;

    let implicitValue = true;
    let attrMatch, key, value, attrValue;

    const tokenAttributeStringModified =
      tokenAttributesString + (tokenAttributesString.charAt(tokenAttributesString.length - 1) === "," ? "" : ",");

    while ((attrMatch = attrRgx.exec(tokenAttributeStringModified))) {
      key = attrMatch[1];
      value = attrMatch[2];

      attrValue = this.parseAttributeValue(key, value);

      if (attrValue != null) {
        this.setAttr(key, attrValue);
      }

      implicitValue = false;
    }

    if (implicitValue) {
      this.setAttr("value", new ConstantToken(tokenAttributesString));
    }
  },

  parseAttributeValue: function (_key, value) {
    if (this.tokenRgx.test(value)) {
      return Token.parse(value);
    }

    return new ConstantToken(value);
  }
};

jsUtils.makeExtendable(Token);

module.exports = Token;
plexExport("tokens.Token", Token);
