ï»¿const ko = require("knockout");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const ChildrenDependentVisibility = Feature.extend({
  onExecute: function () {
    const self = this;
    const element = self.element;

    if (!element.elements) {
      return { render: true };
    }

    // make sure all of the elements are visible
    let controlIndex = element.elements.length;
    let hasVisible = false;

    // note: this currently only goes one level deep - we may want to revisit this
    while (controlIndex--) {
      // do not break out so we get subscribed to every observable
      if (ko.isObservable(element.elements[controlIndex].visible)) {
        hasVisible = element.elements[controlIndex].visible() || hasVisible;
      }
    }

    // return hasVisible;
    return { render: hasVisible };
  }
});

module.exports = ChildrenDependentVisibility;
plexExport("features.ChildrenDependentVisibility", ChildrenDependentVisibility);
