ï»¿const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

const defaultOptions = {
  disabled: false,
  visible: true,
  readOnly: false,
  size: "mini"
};

function createBindingData(record, propertyName, options) {
  logger.warn("OBSOLETE: Use the `renderTemplate` method on grid value providers to render elements dynamically`");

  options = $.extend({}, defaultOptions, options);
  options.propertyName = propertyName;
  dataUtils.trackProperty(record, propertyName);

  return { data: record, options };
}

const BindingHandler = {
  addTextbox: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-input", data);
  },

  addPasswordTextbox: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-password", data);
  },

  addCheckbox: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-checkbox", data);
  },

  addNumberbox: function (record, propertyName, options) {
    // todo: get rid of this or make it smarter
    options.formatter = options;
    options.formatter.address = "NumericFormatter";
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-numberbox", data);
  },

  addTextarea: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-textarea", data);
  },

  addSelect: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-select", data);
  },

  addExpander: function (record, propertyName, options) {
    const data = createBindingData(record, propertyName, options);
    return ko.renderTemplate("elements-expander", data);
  }
};

module.exports = BindingHandler;
plexExport("grid.BindingHandler", BindingHandler);
