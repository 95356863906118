ï»¿const $ = require("jquery");
const ab = require("ab");
const PluginBase = require("./plex-native-base");
const banner = require("../Plugins/plex-banner");
const plexExport = require("../../global-export");
const filesComponent = require("./plex-files-component");

const pluginFailedError = {
  text:
    "There was a problem using the local file system. Please ensure that you have the Plex Websocket browser plugin installed and running. Check the Knowledge Center for further details.",
  isGlossarized: false
};

const documentControlSystem = PluginBase.extend({
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // TODO: switch platform-actions-open-local-file-picker.js to use openLocalFilePicker() instead of openLocalDocumentFilePicker()  (promise instead of callback)
  openLocalDocumentFilePicker: function (oCallback) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.openLocalFilePicker().then(oCallback);
      }

      try {
        return this.call("openLocalDocumentFilePicker").then(
          // RPC success callback
          (res) => {
            return oCallback(res);
          },
          // RPC error callback
          (error, _desc) => {
            banner.getBanner().cancel();
            banner.getBanner().setMessage("Error opening file picker.", { status: banner.states.warning });
            /* eslint new-cap: "off" */
            return ab.Deferred().reject(error);
          }
        );
      } catch (exception) {
        banner.getBanner().cancel();
        banner.getBanner().setMessage("Error opening file picker.", { status: banner.states.warning });
        return ab.Deferred().reject(exception);
      }
    });
  },
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  copyFile: function (sourceFileName, destinationFileName, deleteSourceFile) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.copyFile(sourceFileName, destinationFileName, deleteSourceFile);
      }

      const $deferred = new $.Deferred();
      this.call("copyLocalDocument", sourceFileName, destinationFileName, deleteSourceFile).then(
        // RPC success callback
        (res) => {
          // this function provides true or false as a result
          if (res) {
            $deferred.resolve(); // return success
          } else {
            $deferred.reject({ text: "Error during copy", isGlossarized: false });
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          // TODO: figure out if "error" or "desc" contain any useful information
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  getFileSize: function (fileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.getFileSize(fileName);
      }

      const $deferred = new $.Deferred();
      this.call("getLocalDocumentFileSize", fileName).then(
        // RPC success callback
        (res) => {
          // this function provides an integer as a result
          if (res === -1) {
            $deferred.reject({
              text: 'Error reading file size of "{1}"',
              tokens: [fileName],
              isGlossarized: false
            }); // TODO: glossary
          } else {
            $deferred.resolve(res); // return file size
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  openLocalFilePicker: function () {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.openLocalFilePicker();
      }

      const $deferred = new $.Deferred();
      this.call("openLocalDocumentFilePicker").then(
        // RPC success callback
        (res) => {
          // this function provides a string as a result
          if (res) {
            $deferred.resolve(res); // return full path of file that was selected
          } // user canceled the file picker
          else {
            $deferred.reject({ text: "User canceled the file picker", hideError: true });
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  downloadFile: function (downloadHandler, authenticationToken, destinationFileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.downloadFile(downloadHandler, authenticationToken, destinationFileName);
      }

      const $deferred = new $.Deferred();
      const cookie = document.cookie + authenticationToken;

      this.call("downloadDocument", cookie, downloadHandler, destinationFileName).then(
        // RPC success callback
        (res) => {
          let results, externalError, internalError;
          // A successful call to "downloadDocument" will return true in plugin versions earlier
          // than 7.016, and will return an empty string starting with version 7.016.
          // TODO: remove old implementation when we know that the plugin updates itself, i.e. the "res === true" part
          if (res === true || res === "") {
            $deferred.resolve(); // return success
          } else {
            try {
              results = JSON.parse(res);
              externalError = typeof results.ErrorMessage === "string" ? results.ErrorMessage : "";
              internalError =
                typeof results.ErrorObject === "object" && typeof results.ErrorObject.Message === "string"
                  ? results.ErrorObject.Message
                  : "";
            } catch (e) {
              externalError = "";
              internalError = "";
            }

            if (externalError) {
              // this result is coming from the download handler, but isn't being glossarized
              $deferred.reject({ text: externalError, isGlossarized: false }); // TODO: have download handler glossarize message
            } else if (internalError) {
              // this result is coming from the download handler, but the error might not be a customer facing error message
              // just show the generic error message
              $deferred.reject({ text: "Error during download", isGlossarized: false }); // TODO: add to glossary
            } else {
              // if the plugin fails to write the file, we won't get a JSON response from the server at all
              // just show the generic error message
              $deferred.reject({ text: "Error during download", isGlossarized: false });
            }
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  uploadFile: function (uploadHandler, authenticationToken, fileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.uploadFile(uploadHandler, authenticationToken, fileName);
      }

      const $deferred = new $.Deferred();
      const cookie = document.cookie + authenticationToken;

      this.call("uploadDocument", cookie, fileName, uploadHandler).then(
        // RPC success callback
        (res) => {
          let success, externalError, internalError, results;
          // A successful call to "uploadDocument" in plugin versions starting with version 7.016 return
          // a string containing the complete result from the server.
          // TODO: remove old implementation when we know that the plugin updates itself
          if (typeof res === "string") {
            // TODO: new implementation
            try {
              results = JSON.parse(res);
              success = typeof results.ValidationResult === "object" && results.ValidationResult.Success;
              externalError =
                typeof results.ValidationResult === "object" && typeof results.ValidationResult.Message === "string"
                  ? results.ValidationResult.Message
                  : "";
              internalError =
                typeof results.ErrorObject === "object" && typeof results.ErrorObject.Message === "string"
                  ? results.ErrorObject.Message
                  : "";
            } catch (e) {
              success = false;
              externalError = "";
              internalError = "";
            }

            if (success) {
              $deferred.resolve();
            } else {
              /* eslint no-lonely-if: "off" */
              if (externalError) {
                // this result is coming from the upload handler and should already be glossarized
                $deferred.reject({ text: externalError, isGlossarized: true });
              } else if (internalError) {
                // this result is coming from the upload handler, but the error might not be a customer facing error message
                // just show the generic error message
                $deferred.reject({ text: "Error during upload", isGlossarized: false }); // TODO: add to glossary
              } else {
                // if the plugin fails to find the file, or can't read the file, we won't get a JSON response from the server at all
                $deferred.reject({
                  text: 'The local document "{1}" does not exist or could not be read.',
                  tokens: [fileName],
                  isGlossarized: false
                });
              }
            }
          } else if (res && res.length >= 3) {
            // TODO: old implementation
            // A successful call to "uploadDocument" in plugin versions earlier than 7.016 will return
            // an array with 3 fields.
            // When used by Classic code, these fields are:
            // ServerFileName, UserFileName, FileSize
            // When used in this code, these fields are:
            // <blank>, <validation message from handler>, <success flag (0: fail, 1: success)>
            if (res[2] === "1") {
              $deferred.resolve();
            } else {
              if (res[1].length > 0) {
                $deferred.reject({ text: res[1], isGlossarized: true }); // this result is coming from the upload handler and should already be glossarized
              } else {
                $deferred.reject({
                  text: 'The local document "{1}" does not exist or could not be read.',
                  tokens: [fileName],
                  isGlossarized: false
                }); // if the plugin fails to find the file, res[1] won't contain a response from the hander, and instead will be ""
                // NOTE: this result will also happen because AddRevision can throw a 500 error if the file is uploaded without a Description, and Track_Revisions is on.
              }
            }
          } else {
            $deferred.reject({ text: "Error during upload", isGlossarized: false });
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  openFile: function (fileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.openFile(fileName);
      }

      const $deferred = new $.Deferred();
      this.call("openLocalFile", fileName).then(
        // RPC success callback
        (res) => {
          if (res) {
            $deferred.resolve();
          } else {
            $deferred.reject({
              text: 'The local document "{1}" does not exist or could not be read.',
              tokens: [fileName],
              isGlossarized: false
            });
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  verifyReadAccess: function (fileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.verifyReadAccess(fileName);
      }

      const $deferred = new $.Deferred();
      this.call("validateLocalDocumentReadAccess", fileName).then(
        // RPC success callback
        (res) => {
          if (res) {
            $deferred.resolve();
          } else {
            $deferred.reject({});
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  },

  verifyWriteAccess: function (fileName) {
    return filesComponent.isEnabled().then((enabled) => {
      if (enabled) {
        return filesComponent.verifyWriteAccess(fileName);
      }

      const $deferred = new $.Deferred();
      this.call("validateLocalDocumentWriteAccess", fileName).then(
        // RPC success callback
        (res) => {
          if (res) {
            $deferred.resolve();
          } else {
            $deferred.reject({
              text:
                'The local document "{1}" could not be written.  Please verify that the file is not already opened elsewhere and the folder can be written to.',
              tokens: [fileName],
              isGlossarized: false
            }); // TODO: glossary
          }
        },
        // RPC error callback (such as autobahn not connected)
        (_error, _desc) => {
          $deferred.reject(pluginFailedError);
        }
      );

      return $deferred.promise();
    });
  }
});

module.exports = documentControlSystem;
plexExport("native.plugins.documentControlSystem", documentControlSystem);
