ï»¿const $ = require("jquery");
const parseJSON = require("./plex-parsing-json");
const pubsub = require("./plex-pubsub");
const pageState = require("./plex-pagestate");
const plexExport = require("../../global-export");
const sessionStorage = require("./plex-sessionstorage");

const stateTokenId = "__psid";

function getPageStack() {
  return sessionStorage.getItem("page-stack");
}

// Fires on back/forward browser button click
$(window).on("popstate", (event) => {
  const state = event.originalEvent.state || {};
  const stateObject = sessionStorage.getItem(state[stateTokenId]);
  if (
    stateObject &&
    stateObject.data &&
    typeof stateObject.data === "string" &&
    stateObject.data.indexOf("dialog") > -1
  ) {
    pubsub.publish("history.navButtonClick", parseJSON(stateObject.data));
  } else if (state[stateTokenId] === pageState.getCurrentState().id) {
    pubsub.publish("history.dialogRootReached");
  }
});

const api = {
  reset: function () {
    const rootStatePageIndex = getPageStack().indexOf(pageState.getCurrentState().id);
    pageState.updateForRedirect(rootStatePageIndex);
  },
  addState: function (stateObject) {
    if (stateObject && typeof stateObject === "object") {
      const currentState = $.extend({ dialog: true }, stateObject);
      pageState.addState(JSON.stringify(currentState));
    }
  }
};

module.exports = api;
plexExport("DialogState", api);
