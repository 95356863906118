ï»¿const ko = require("knockout");
const dialogAction = require("./plex-actions-dialog");
const parsing = require("../Core/plex-parsing-url");
const dcsAttachments = require("../Utilities/plex-utils-dcs-attachments");
const plexExport = require("../../global-export");

const AttachmentDialog = dialogAction.extend({
  onReturnAction: function () {
    this.updateAttachmentCount.apply(this, arguments);
  },

  onCloseAction: function () {
    this.updateAttachmentCount.apply(this, arguments);
  },

  updateAttachmentCount: function (dialogData, data, rawData) {
    const self = this;
    const record = rawData || self.data || (self.parent ? self.parent.data : null);
    const qs = parsing.parseQueryString(self.address);

    if ("AttachmentGroupKey" in qs && record) {
      const attachmentGroupKey = parseInt(qs.AttachmentGroupKey, 10);
      const attachmentCountObservable = record[dcsAttachments.getAttachmentsCountTokenName(attachmentGroupKey)];

      if (ko.isObservable(attachmentCountObservable)) {
        const attachmentContext = dcsAttachments.createAttachmentContextFromAttachmentDialogResults(
          attachmentGroupKey,
          data
        );
        dcsAttachments.getAttachmentsCount(attachmentContext).then(attachmentCountObservable);
      }
    }
  }
});

module.exports = AttachmentDialog;
plexExport("actions.AttachmentDialog", AttachmentDialog);
