ï»¿const TokenParser = require("../../Tokens/plex-tokens-parser");
const DefaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const TokenValueProvider = DefaultValueProvider.extend({
  init: function () {
    const parser = new TokenParser();
    this.evaluator = parser.compile(this.column.text);
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
});

module.exports = TokenValueProvider;
plexExport("grid.valueProviders.TokenValueProvider", TokenValueProvider);
