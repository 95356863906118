ï»¿/* eslint-disable no-invalid-this */
const controllerFactory = require("./plex-controller-factory");
const dialogController = require("../Dialogs/plex-dialog-controller");
const plexExport = require("../../global-export");

// constructor
const SignatureController = function (config, model) {
  this.config = config;
  this.model = model;
};

SignatureController.prototype = {
  constructor: SignatureController,

  pick: function () {
    const self = this;
    dialogController.create({
      route: "/Platform/Graphic/GetSignatureGraphicDialog",
      httpMethod: "POST",
      returnAction: function (dialogData) {
        self.model[self.config.propertyName] = dialogData;
      }
    });
  }
};

// factory method
SignatureController.create = function (config, model) {
  return new SignatureController(config, model);
};

controllerFactory.register("Elements/_SignatureGraphicPicker", SignatureController);

module.exports = SignatureController;
plexExport("SignatureController", SignatureController);
