ï»¿const ko = require("knockout");
const $ = require("jquery");

["title", "placeholder", "id", "href", { attr: "for", alias: "labelFor" }, "tabindex"].forEach((name) => {
  let attr = name;
  if (name && typeof name === "object") {
    attr = name.attr;
    name = name.alias;
  }

  ko.bindingHandlers[name] = {
    // process after `attr` binding so that this binding takes precedence
    after: ["attr"],
    update: function (element, valueAccessor) {
      const value = ko.unwrap(valueAccessor());
      $(element).attr(attr, value);
    }
  };
});
