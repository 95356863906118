ï»¿const $ = require("jquery");
const Feature = require("./plex-features");
const droppables = require("../DragAndDrop/plex-droppables");
const plexExport = require("../../global-export");

const DroppableFeature = Feature.extend({
  onInit: function () {
    /// <summary>Sets up the droppable.</summary>

    droppables.setupDroppable(this.config, this.parent.controller || this.parent, $("#" + this.element.id)[0]);
  }
});

module.exports = DroppableFeature;
plexExport("features.Droppable", DroppableFeature);
