ï»¿const NumericFormatter = require("./plex-culture-number");
const DateFormatter = require("./plex-culture-datetime");
const PersonNameFormatter = require("./plex-culture-name");
const plexExport = require("../../global-export");

const api = {};

api.PersonNameFormatter = PersonNameFormatter;
api.NumericFormatter = NumericFormatter;
api.DateTimeFormatter = DateFormatter;

api.formatValue = function (value, config, record, trackClientUpdates) {
  /// <summary>
  /// Formats a value using customer specific culture formatting for appropriate value types.
  /// </summary>
  /// <param name="value">The value to format.</param>
  /// <param name="config">The config options for formatting.
  /// This may include a `format` property with a standard format string
  /// (See http://msdn.microsoft.com/en-us/library/dwhawy9k(v=vs.110).aspx for supported formats.)
  /// and a `scale` property for numeric formatting.
  /// </param>
  /// <returns type="String">The formatted value.</returns>

  if (value == null) {
    return "";
  }

  if (!config) {
    return String(value);
  }

  return this.getFormatter(config).format(value, config, record || {}, trackClientUpdates);
};

api.getFormatter = function (config) {
  /// <summary>
  /// Gets the formatter that will be used to format values using customer specific culture.
  /// </summary>
  /// <param name="config">The config options for formatting.</param>

  if (!(config.address in api)) {
    throw new Error("Formatter not found for: " + config.address);
  }

  return api[config.address];
};

module.exports = api;
plexExport("formatting", api);
