ï»¿const plexExport = require("../../../global-export");

class Code {
  constructor(value, isModifierRequired = false, modifierExceptions = []) {
    this.value = value;
    this.isModifierRequired = isModifierRequired;
    this.modifierExceptions = modifierExceptions;
  }
}

class HotKeysManager {
  constructor(modifiers = [], codes = []) {
    this.modifiers = modifiers;
    this.codes = codes.map((item) => new Code(item.codeKey, item.isModifierRequired, item.modifierExceptions));
  }

  getModifiers() {
    return [...this.modifiers];
  }

  getCodes(modifier) {
    let result;

    if (modifier) {
      result = this.codes.filter((code) => !code.modifierExceptions.some((value) => value === modifier));
    } else {
      result = this.codes.filter((code) => !code.isModifierRequired);
    }

    return result.map((code) => code.value);
  }
}

const alt = ["Alt"];
const ctrl = ["Ctrl"];
const shift = ["Shift"];
const altAndCtrl = ctrl.concat(alt);
const shiftAndCtrl = shift.concat(ctrl);
const allModifiers = shiftAndCtrl.concat(alt);

const allCodes = [
  // functional buttons
  { codeKey: "F1" },
  { codeKey: "F2" },
  { codeKey: "F3", isModifierRequired: true, modifierExceptions: shift },
  { codeKey: "F4", modifierExceptions: altAndCtrl },
  { codeKey: "F5", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "F6", isModifierRequired: true },
  { codeKey: "F7" },
  { codeKey: "F8" },
  { codeKey: "F9" },
  { codeKey: "F10" },
  { codeKey: "F11", isModifierRequired: true },
  // digits
  { codeKey: "1", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "2", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "3", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "4", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "5", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "6", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "7", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "8", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "9", isModifierRequired: true, modifierExceptions: ctrl },
  { codeKey: "0", isModifierRequired: true },
  // letters
  { codeKey: "A", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "B", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "C", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "D", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "E", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "G", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "H", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "I", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "J", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "K", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "L", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "M", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "N", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "O", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "P", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "Q", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "R", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "S", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "T", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "U", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "V", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "W", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "X", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "Y", isModifierRequired: true, modifierExceptions: shiftAndCtrl },
  { codeKey: "Z", isModifierRequired: true, modifierExceptions: shiftAndCtrl }
];

const hotKeyManager = new HotKeysManager(allModifiers, allCodes);
plexExport("HotKeysManager", hotKeyManager);
module.exports = hotKeyManager;
