ï»¿const ko = require("knockout");
const $ = require("jquery");
const nav = require("../Core/plex-navigate");

const DCS_IMAGE_PATH = "/Platform/Images/GetImage";

ko.bindingHandlers.dcsImage = {
  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    if (value) {
      const url = nav.buildUrl(DCS_IMAGE_PATH, { imagePath: value });
      $(element).prop("src", url).show();
    } else {
      $(element).hide();
    }
  }
};
