ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const pageState = require("../Core/plex-pagestate");
const plexExport = require("../../global-export");

const scriptTypePattern = /(?:text|application)\/(?:java|ecma)script/i;

function executeScripts(scripts, callback) {
  if (scripts.length > 0) {
    const script = scripts.shift();
    if (script.src) {
      const $script = $("<script>", {
        src: script.src,
        crossorigin: script.crossorigin
      }).on("load error", function () {
        this.parentNode.removeChild(this);
        executeScripts(scripts, callback);
      });

      document.head.appendChild($script[0]);
    } else {
      $.globalEval(script.innerHTML);
      executeScripts(scripts, callback);
    }
  } else {
    callback();
  }
}

const api = {
  injectScripts(scripts) {
    const sources = scripts.map((s) => {
      return typeof s === "string" ? { src: s, crossorigin: "anonymous" } : s;
    });

    const deferred = new $.Deferred();
    executeScripts(sources, deferred.resolve);
    return deferred.promise();
  },

  advanceFocus(currentElement, reverse) {
    /// <summary>Advances the focus to the next focusable element.</summary>
    /// <param name="currentElement">The current element - if not supplied the document's activeElement will be used.</param>
    /// <param name="reverse">Value indicating if the shift key was pressed.</param>

    const $current = $(currentElement || document.activeElement);

    if ($current.length > 0) {
      // getting the element once again to work around problems with how pickers are working inside grid rows
      const $actualCurrent = $($current[0]);

      // add delay so events have time to fire
      // in particular if a field is coming out of a disable state based on
      // the result of this field changing this will give it a chance to become available
      setTimeout(() => {
        const inputs = $(":tabbable").toArray();
        let currentElementIndex, nextIndex, i;

        // no need to advance focus if there are no tabbable elements on the page or just one element
        if (inputs.length <= 1) {
          return;
        }

        // combine DOM-based tab-order (k) with explicitly defined tab-order, as explicitly defined
        // tab-order may have duplicated indices, in this case DOM-based order will be used to resolve ambiguity
        const tabbableElements = inputs.map((el, k) => ({
          domIndex: k,
          tabIndex: el.tabIndex,
          element: el
        }));

        // DOM-based elements are already in required order (based on how jQuery processes ':tabbable' selector)
        // but need to sort explicitly defined tabIndex elements by their tabIndex values and then DOM-based order
        if (tabbableElements.length > 0) {
          tabbableElements.sort((a, b) => {
            if (!a.tabIndex || !b.tabIndex) {
              return 0;
            }

            if (a.tabIndex < b.tabIndex) {
              return -1;
            }

            if (a.tabIndex > b.tabIndex) {
              return 1;
            }

            if (a.tabIndex === b.tabIndex) {
              if (a.domIndex === b.domIndex) {
                return 0;
              }

              if (a.domIndex < b.domIndex) {
                return -1;
              }

              if (a.domIndex > b.domIndex) {
                return 1;
              }
            }
            return 0;
          });
        }

        // find the index (not the element itself) of the element in the tabbable order array of elements
        for (i = 0; i < tabbableElements.length; i++) {
          if ($actualCurrent.is(tabbableElements[i].element) || $actualCurrent.id === tabbableElements[i].element.id) {
            currentElementIndex = i;
            break;
          }
        }

        // found the element, but it is the last element in the tabbable order, so, proceed to the next one
        if (currentElementIndex === tabbableElements.length - 1) {
          nextIndex = reverse ? currentElementIndex - 1 : 0;
        }
        // found the element, but it is the first element in the tabbable order, so, proceed to the next one
        else if (currentElementIndex === 0) {
          nextIndex = reverse ? tabbableElements.length - 1 : currentElementIndex + 1;
        }
        // element is in the 'middle' of the tabbable order list, proceed to the next
        else if (currentElementIndex !== -1) {
          nextIndex = reverse ? currentElementIndex - 1 : currentElementIndex + 1;
        }

        // if element was found, proceed with moving focus on it, if not - do nothing, browser will focus on the first tabbable element
        if (nextIndex) {
          tabbableElements[nextIndex].element.focus();
        }
      }, 0);
    }
  },

  setInitialFocus(container) {
    const $tabbableInput = $(container || document)
      .find(":tabbable:input:not([type=search])")
      .first();
    const offset = $tabbableInput.offset();
    const previousScrollPosition = pageState.getCurrentState().scrollPosition;
    const windowHeight = $(window).height();
    const windowTop = previousScrollPosition || 0;
    const windowBottom = windowTop + windowHeight;

    // only place focus if the input is currently in view
    if (offset && offset.top >= windowTop && offset.top < windowBottom) {
      $tabbableInput.focus();
    }
  },

  getLabelText(inputElement) {
    /// <summary>Gets the text of the label associated with the provided element.</summary>
    /// <param name="inputElement">The input to evaluate.</param>
    /// <returns type="String">The label text; empty if not found.</returns>

    const $input = $(inputElement);
    let $label;

    if ($input.length > 0) {
      // look for matching label
      // todo: what if the label is a link?
      $label = $("label[for='" + $input[0].id + "']");

      if ($label.length > 0) {
        return $label.text().trim();
      }
    }

    return "";
  },

  getTopDialog(excludeClosing) {
    let $result;
    let highestIdx = 0;

    $(".plex-dialog").each(function () {
      // eslint-disable-next-line no-invalid-this
      const $this = $(this);

      if (excludeClosing) {
        const controller = $this.data("plex.dialog");
        if (controller && controller.closing) {
          return;
        }
      }

      const curIdx = parseInt($this.css("zIndex"), 10);
      if (curIdx > highestIdx) {
        highestIdx = curIdx;
        $result = $this;
      }
    });

    return $result;
  },

  injectHtml(html, target, options) {
    /// <summary>Injects html into the page, ensuring that any embedded scripts are executed in sequence.</summary>
    /// <param name="html">The html string to inject.</param>
    /// <param name="target">The target element to load the html into. Defaults to document.body</param>
    /// <param name="options">
    /// An options object
    /// - replaceContents: when true, the target element is emptied and the html is added as children of the target
    /// - ignoreScripts: An array of scripts paths to ignored when processing scripts
    /// </param>
    /// <returns type="String">The label text; empty if not found.</returns>

    options = options || {};

    // backwards compatibility
    if (typeof options === "boolean") {
      options = { replaceContents: options };
    }

    html = "<div>" + html.trim() + "</div>";
    let $content = $($.parseHTML(html, true));
    const $target = $(target || document.body);

    const ignoreScripts = (options.ignoreScripts || []).map((src) => {
      return src && src.toLowerCase();
    });

    // get the content for each of the script tags
    const scripts = $content
      .find("script")
      .map(function () {
        // retain scripts tags that are not javascript (ie templates)
        if (this.type && !scriptTypePattern.test(this.type)) {
          return null;
        }

        // remove this tag from content - we will evaluate ourselves
        this.parentNode.removeChild(this);

        if (this.src && ignoreScripts.indexOf(this.src.toLowerCase()) >= 0) {
          return null;
        }

        return this;
      })
      .get();

    // get contents of div we wrapped it in
    $content = $content.children();
    if (options.replaceContents) {
      $target.empty().append($content.children());
    } else {
      $target.append($content);
    }

    return api.injectScripts(scripts).then(() => $content);
  },

  isInDialog(element) {
    /// <summary>Indicates whether the given element is contained within a dialog.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within a dialog; false otherwise.</returns>

    return $(element).closest(".plex-dialog").length > 0;
  },

  isInPicker(element) {
    /// <summary>Indicates whether the given element is contained within a picker.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within a picker; false otherwise.</returns>

    return $(element).closest(".plex-picker").length > 0;
  },

  isInForm(element) {
    /// <summary>Indicates whether the given element is contained within a form.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within a form; false otherwise.</returns>
    return $(element).closest(".plex-form-content").length > 0;
  },

  isInElementList(element) {
    /// <summary>Indicates whether the given element is contained within an element list.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within an element list; false otherwise.</returns>
    return $(element).closest(".plex-element-list").length > 0;
  },

  isFormContainer(element) {
    /// <summary>Indicates whether the given element is contained within a form container.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within a form; false otherwise.</returns>
    return $(element).find(".plex-form-row-container").length > 0;
  },

  isInWidget(element) {
    /// <summary>Indicates whether the given element is contained within a widget.</summary>
    /// <param name="element">The element to evaluate.</param>
    /// <returns type="Boolean">True if contained within a widget; false otherwise.</returns>
    return $(element).closest(".plex-widget-body").length > 0;
  },

  /**
   * Returns the parent page DOM element for the given element. If the element is in a dialog
   * it will return the dialog container, otherwise it will return the document.
   *
   * @param {HtmlElement} element The element to evaluate.
   * @returns {HtmlElement} The parent page element.
   */
  getParentPage(element) {
    return $(element).closest(".plex-dialog").first()[0] || document;
  }
};

module.exports = api;
plexExport("dom", api);
