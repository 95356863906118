ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const jsUtils = require("../Utilities/plex-utils-js");
const pubsub = require("../Core/plex-pubsub");

const $win = $(window);
const priorSize = {
  w: $win.width(),
  h: $win.height()
};

function handleWindowResize(e) {
  if (e.target !== this) {
    return;
  }

  const w = $win.width();
  const h = $win.height();

  if (w !== priorSize.w) {
    pubsub.publish("resizeCompleted-x");
    $win.triggerHandler("resizeCompleted-x");
  }

  if (h !== priorSize.y) {
    pubsub.publish("resizeCompleted-y");
    $win.triggerHandler("resizeCompleted-y");
  }

  pubsub.publish("resizeCompleted");
  $win.triggerHandler("resizeCompleted");

  // reset
  priorSize.w = w;
  priorSize.h = h;
}

function handleModalResize(e, ui) {
  if (!ui || e.target !== this) {
    // do not bubble
    return;
  }

  const $el = $(this);
  if (ui.originalSize.width !== ui.size.width) {
    $el.triggerHandler("resizeCompleted-x");
  }

  if (ui.originalSize.height !== ui.size.height) {
    $el.triggerHandler("resizeCompleted-y");
  }

  $el.triggerHandler("resizeCompleted");
}

$win.on("resize", jsUtils.debounce(handleWindowResize, 100));
$(document).on("resizestop", ".ui-resizable", handleModalResize);
