ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const revisionTracking = require("../Core/plex-revisiontracking");
const plexExport = require("../../global-export");
const SearchAction = require("./plex-actions-search");
const notify = require("../Core/plex-notify");

const UPDATE_EXECUTION_TYPE = 10;

const DataSourceAction = SearchAction.extend({
  onInit: function () {
    this.publishSearch = this.publishSearch || false;
    this._base(...arguments);
  },

  createRequest: function (data) {
    const self = this;
    const requests = [];

    (Array.isArray(data) ? data : [data]).forEach((record) => {
      requests.push(dataUtils.cleanse($.extend({}, self.request, record)));
    });

    // use the unmodified original request
    if (requests.length === 0) {
      requests.push(dataUtils.cleanse(self.request));
    }

    return requests;
  },

  onExecute: function () {
    if (!this.parent) {
      notify.error("The data source has not been set on the Execute Data Source Action.");
      return undefined;
    }

    const response = this._base(...arguments);

    if (typeof this.parent.saved === "function" && this.executionType === UPDATE_EXECUTION_TYPE) {
      // save parent state after vp update action
      response.then(() => this.parent.saved(true));
    }

    return response;
  },

  onSuccessfulExecute: function (data) {
    this._base(...arguments);

    if (this.trackRevisions && this.identityProperty) {
      (Array.isArray(data) ? data : [data]).forEach(function (record) {
        if (record && this.identityProperty in record) {
          const identityKey = parseInt(record[this.identityProperty], 10);
          if (isNaN(identityKey)) {
            revisionTracking.recordRevisions({
              identityName: record[this.identityProperty],
              revisionType: this.revisionType
            });
          } else {
            revisionTracking.recordRevisions({ identityKey, revisionType: this.revisionType });
          }
        }
      }, this);
    }
  }
});

module.exports = DataSourceAction;
plexExport("actions.DataSource", DataSourceAction);
