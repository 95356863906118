ï»¿const nav = require("../Core/plex-navigate");
const DialogController = require("./plex-dialog-controller");
const urlProvider = require("../Core/plex-url-provider");
const plexImport = require("../../global-import");

const Dialogs = plexImport("Dialogs");
Dialogs.Authenticate = require("./plex-dialogs-authenticate");
Dialogs.PINValidate = require("./plex-dialogs-pinvalidate");
Dialogs.PINVerify = require("./plex-dialogs-pinverify");

const userSupportDialogLink = "/Platform/SupportManager/ViewLookupForm";
let userSupportDialogUrl;

// TODO: Move this out of plex-dialogs.js
Dialogs.ShowUserSupportDialog = function () {
  DialogController.create({
    route: nav.buildUrl(userSupportDialogLink),
    autoShow: true,
    returnAction: function (_data) {
      // noop
    }
  });
};

Dialogs.LookupUserSupport = function () {
  if (!userSupportDialogUrl) {
    userSupportDialogUrl = urlProvider.getUrl(userSupportDialogLink);
  }

  userSupportDialogUrl.done((response) => {
    if (response.authorized) {
      /* eslint new-cap: "off" */
      Dialogs.ShowUserSupportDialog();
    }
  });
};

Dialogs.AddUserSupport = function () {
  DialogController.create({
    route: nav.buildUrl("/Platform/UserSupportDialog/Add"),
    autoShow: true
  });
};

module.exports = Dialogs;
