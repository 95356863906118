ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ToggleAction = Action.extend({
  onExecute: function (data) {
    const $deferred = new $.Deferred();
    const current = !!dataUtils.getValue(data, this.address, false);

    dataUtils.setValue(data, this.address, !current);

    $deferred.resolve(data);
    return $deferred.promise();
  }
});

module.exports = ToggleAction;
plexExport("actions.Toggle", ToggleAction);
