ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.enterKey = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    $(element).on("keypress.plex.enterKey", (e) => {
      if (e.which !== 13) {
        return true;
      }

      const target = e.target;
      target.blur();

      // assume that we are cancelling the event unless true is specifically returned
      return valueAccessor().call(viewModel, viewModel, target, element) === true;
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(element).off("keypress.plex.enterKey");
    });
  }
};
