const $ = require("jquery");
const ko = require("knockout");

const menuState = require("./plex-menu-state");
const pageState = require("../../Core/plex-pagestate");
const dataUtils = require("../../Utilities/plex-utils-data");
const nav = require("../../Core/plex-navigate");

const plexImport = require("../../../global-import");

function initNode(node) {
  const currentApplication = plexImport("currentApplication");
  if (node.$$initialized) {
    return;
  }

  if (node.Buttons && node.Buttons.length > 0) {
    // go ahead and track each object in case menu node gets edited
    dataUtils.trackObject(node.Buttons);
    if (currentApplication && currentApplication.ActionKey) {
      node.Buttons.forEach((button) => {
        button.$$isCurrentAction = ko.computed(() => {
          return button.CloudApplicationActionKey && button.CloudApplicationActionKey === currentApplication.ActionKey;
        });
      });
    }
  }

  node.$$initialized = true;
}

function getMenuNode(menuNodeKey, callback) {
  $.getJSON(nav.buildUrl("/Platform/Navigation/GetMenuNode", { menuNodeKey }), callback);
}

class MainMenu {
  constructor() {
    this.lastKnownValueKey = menuState.stateKeys.mainMenu;
    this.currentNodeUrl = "/Platform/Navigation/GetCurrentMenuNode";

    this.currentButton = ko.observable();
    this.breadcrumb = ko.observableArray();
    this.buttons = ko.observableArray();

    this.loading = ko.observable(false);
  }

  loadInitialMenu() {
    const self = this;

    if (this.initialMenu) {
      this.setCurrentMenu(this.initialMenu);
      return;
    }

    this.loading(true);
    this.getCurrentMenuNode().done((node) => {
      self.initialMenu = node;
      self.setCurrentMenu(node);
      self.loading(false);
    });
  }

  setCurrentMenu(node) {
    initNode(node);

    this.currentMenuNodeKey = node.MenuNodeKey;

    this.breadcrumb(node.Breadcrumb);
    this.buttons(node.Buttons);
    this.currentButton(node.CurrentButton);
  }

  getCurrentMenuNode() {
    let params = {};

    const currentState = pageState.getCurrentState();
    if (currentState.isSearchNode) {
      params = { lastMenuNodeKey: currentState.selectedNodeKey };
      pageState.removeFromCurrentState("isSearchNode");
    } else {
      const currentApplication = plexImport("currentApplication", true);
      if (currentApplication) {
        params = {
          lastMenuNodeKey: pageState.getLastKnownValue(this.lastKnownValueKey),
          currentActionKey: currentApplication.ActionKey,
          currentApplicationKey: currentApplication.ApplicationKey
        };

        if (currentState.selectedNodeKey) {
          pageState.removeFromCurrentState("selectedNodeKey");
        }
      }
    }

    return $.getJSON(nav.buildUrl(this.currentNodeUrl, params));
  }

  selectBreadcrumb(data) {
    const self = this;

    if (data.IsMenuLink) {
      this.selectMenuNode(data);
      return;
    }

    if (data.Url) {
      this._setMenuState(data.ParentMenuNodeKey || self.currentMenuNodeKey);
      nav.navigate(data.Url);
    }
  }

  selectMenuNode(data) {
    const self = this;
    if (data.IsMenuLink) {
      self.currentMenuNodeKey = data.MenuNodeKey;

      self.loading(true);
      getMenuNode(data.MenuNodeKey, (model) => {
        self.setCurrentMenu(model);
        self.loading(false);
      });
    } else if (data.Url) {
      let options = {};

      if (data.IsExternal) {
        options = { newWindow: true };
      }
      this._setMenuState(data.ParentMenuNodeKey || self.currentMenuNodeKey);
      nav.navigate(data.Url, data, options);
    }
  }

  reset() {
    this.initialMenu = null;
  }

  _setMenuState(value) {
    menuState.setMenuState(this.lastKnownValueKey, value);
  }
}

module.exports = MainMenu;
