ï»¿const GenericLabelPrintController = require("./Controllers/plex-labelprinting-controller-generic");
const plexExport = require("../../../global-export");

const printGenericLabel = function (applicationFilePath, parameters) {
  let requests = [
    {
      LabelFormat: { ApplicationPath: applicationFilePath },
      LabelParameters: parameters
    }
  ];
  const options = {
    TargetPcn: parameters && parameters.TargetPcn
  };

  // if the caller provided an array as the first argument, assume that is a set of Label Format requests
  if (Array.isArray(arguments[0])) {
    requests = arguments[0];
  }

  const controller = new GenericLabelPrintController();
  return controller.getPrintResults(requests, options).then(controller.processResults.bind(controller));
};

module.exports = printGenericLabel;
plexExport("barcoding.labelPrinting.genericLabels.printGenericLabel", printGenericLabel);
