ï»¿const $ = require("jquery");
const browser = require("../../Core/plex-browser");
const hotkeys = require("../../Navigation/plex-hotkeys");
const plexExport = require("../../../global-export");

const horizontalDistanceThreshold = browser.defaultDevicePixelRatio >= 2 ? 30 : 60;
const smallScreenMatchMedia = window.matchMedia("(max-width: 1024px)");
const sidetabsMenuCssClass = ".plex-sidetabs-menu-for-submenu";
const sectionsMenuCssClass = ".plex-sidetabs-menu-for-form-sections";

function isInputElement(e) {
  const target = e.target;

  if (
    (target.tagName === "INPUT" && target.type !== "checkbox" && target.type !== "radio") ||
    target.tagName === "TEXTAREA"
  ) {
    return true;
  }

  return false;
}

function SideMenusManager() {
  // constructor
}

SideMenusManager.hasDraggingItems = function () {
  return (
    SideMenusManager.sideMenus.filter((listener) => {
      return listener.dragging();
    }).length > 0
  );
};

SideMenusManager.onLeftRightArrowPressed = function (e, collapseFunc) {
  if (isInputElement(e)) {
    return true;
  }

  if (!SideMenusManager.hasDraggingItems()) {
    collapseFunc();
  }

  return false;
};

SideMenusManager.checkCollapsedState = function (collapsed) {
  if (SideMenusManager.collapsing || !smallScreenMatchMedia.matches || collapsed) {
    return;
  }

  SideMenusManager.collapsing = true;

  const conflictItems = SideMenusManager.sideMenus.filter((menu) => {
    return menu.collapsed() === collapsed;
  });

  if (conflictItems.length > 0) {
    conflictItems.forEach((menu) => {
      menu.setCollapsedState(!collapsed);
    });
  }

  SideMenusManager.collapsing = false;
};

SideMenusManager.collapse = function (collapse) {
  for (let i = SideMenusManager.sideMenus.length - 1; i >= 0; i--) {
    const sideMenu = SideMenusManager.sideMenus[i];

    if (sideMenu.collapsed() === !collapse && sideMenu.visible()) {
      sideMenu.setCollapsedState(collapse);
      break;
    }
  }
};

SideMenusManager.collapseNext = function () {
  SideMenusManager.collapse(true);
};

SideMenusManager.expandNext = function () {
  SideMenusManager.collapse(false);
};

SideMenusManager.onTouchStart = function (e) {
  if (SideMenusManager.hasDraggingItems()) {
    return;
  }

  const touch = e.originalEvent.touches[0];

  SideMenusManager.xFirstTouch = touch.clientX;
  SideMenusManager.yFirstTouch = touch.clientY;
};

SideMenusManager.onTouchMove = function (e) {
  if (
    SideMenusManager.xFirstTouch == null ||
    SideMenusManager.yFirstTouch == null ||
    SideMenusManager.hasDraggingItems()
  ) {
    return;
  }

  const touch = e.originalEvent.touches[0];
  const xDiff = SideMenusManager.xFirstTouch - touch.clientX;
  const yDiff = SideMenusManager.yFirstTouch - touch.clientY;

  if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > horizontalDistanceThreshold) {
    if (xDiff > 0) {
      SideMenusManager.collapseNext();
    } else {
      SideMenusManager.expandNext();
    }

    SideMenusManager.xFirstTouch = null;
    SideMenusManager.yFirstTouch = null;
  }
};

SideMenusManager.addSideMenu = function (ctrl) {
  if (!SideMenusManager.sideMenus) {
    SideMenusManager.sideMenus = [];

    hotkeys.addHotKey("left", (e) => {
      return SideMenusManager.onLeftRightArrowPressed(e, SideMenusManager.collapseNext);
    });

    hotkeys.addHotKey("right", (e) => {
      return SideMenusManager.onLeftRightArrowPressed(e, SideMenusManager.expandNext);
    });

    $(`${sidetabsMenuCssClass}, ${sectionsMenuCssClass}`).on({
      touchstart: SideMenusManager.onTouchStart,
      touchmove: SideMenusManager.onTouchMove
    });
  }

  if (SideMenusManager.sideMenus.length > 0) {
    if (smallScreenMatchMedia.matches) {
      SideMenusManager.sideMenus.forEach((menu) => {
        if (
          menu.isCollapsedStateSaved === false ||
          (!menu.collapsed() && ctrl.isCollapsedStateSaved !== false && !ctrl.collapsed())
        ) {
          menu.collapsed(true);
        } else if (!menu.collapsed() && ctrl.isCollapsedStateSaved === false) {
          ctrl.collapsed(true);
        }
      });
    }

    ctrl.visible = SideMenusManager.sideMenus[0].visible;
  }

  SideMenusManager.sideMenus.push(ctrl);
};

SideMenusManager.removeSideMenu = function (ctrl) {
  if (SideMenusManager.sideMenus) {
    for (let i = 0; i < SideMenusManager.sideMenus.length; i++) {
      if (SideMenusManager.sideMenus[i].config.id === ctrl.config.id) {
        SideMenusManager.sideMenus.splice(i, 1);
        break;
      }
    }
  }
};

module.exports = SideMenusManager;
plexExport("SideMenusManager", SideMenusManager);
