ï»¿const ko = require("knockout");
const Controller = require("./plex-controller-base");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const plexExport = require("../../global-export");

// todo: need a better way for independent elements like this to have bindings
const PanelController = Controller.extend({
  onInit: function () {
    this.prepareData();
    this.initElement(this.config);
    ko.applyBindings(this, this.$element[0]);
  },

  toDocumentXml: function () {
    if (!this.config.visible()) {
      return "";
    }

    const doc = new DocumentXml("plex-panel");
    this.config.elements.forEach((element) => {
      doc.addControlElement(element);
    });

    return doc.serialize();
  }
});

PanelController.create = function (config, model) {
  return new PanelController(config, model);
};

module.exports = PanelController;
plexExport("PanelController", PanelController);
