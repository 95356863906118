ï»¿// todo: maybe switch to have pre-post overrides and move out of framework
if (!window.when) {
  // swap out defer usage for use in autobahn plugin - must be loaded before autobahn is loaded
  // autobahn is looking for when.js, but will work with jQuery's deferred implementation.
  // to avoid an extra dependency and when's requirement of a module system we will hack this
  // for now.
  window.when = {
    // eslint-disable-next-line no-undef
    defer: $.Deferred
  };
}
