ï»¿const $ = require("jquery");
const ko = require("knockout");
const logger = require("../Core/plex-logger");
const pageHandler = require("./plex-handler-page");
const elementHandler = require("./plex-handler-element");
const repository = require("./plex-model-repository");
const jsUtils = require("../Utilities/plex-utils-js");
const dataUtils = require("../Utilities/plex-utils-data");
const DataProvider = require("../Data/plex-data-provider");
const parseJSON = require("../Core/plex-parsing-json");
const remoteStateStorage = require("./plex-remote-state-storage");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const arrayUtils = require("../Utilities/plex-utils-arrays");

require("../Mixins/plex-mixins-events"); // eslint-disable-line import/no-unassigned-import

// #region helpers

function getObservable(data, propertyName) {
  if (!dataUtils.hasProperty(data, propertyName)) {
    throw new Error(propertyName + " does not exist in the model.");
  }

  let observable = dataUtils.getObservable(data, propertyName);
  if (observable) {
    return observable;
  }

  const index = propertyName.lastIndexOf(".");
  if (index !== -1) {
    const parentPath = propertyName.substr(0, index);
    propertyName = propertyName.substr(index + 1);
    data = dataUtils.getValue(data, parentPath);
  }

  data[propertyName] = observable = ko.observable(data[propertyName]);
  dataUtils.trackProperty(data, propertyName);
  return observable;
}

function findElement(el, query) {
  if (query(el)) {
    return el;
  }

  if (el.elements && el.elements.length > 0) {
    let i = el.elements.length;
    let match;

    while (i--) {
      match = findElement(el.elements[i], query);
      if (match) {
        return match;
      }
    }
  }

  return null;
}

// #endregion

function Controller() {
  // constructor
}

Controller.prototype = {
  constructor: Controller,

  onPreInit: function () {
    // empty but may be overriden by inheritors
  },

  init: function (el, config) {
    this.onPreInit();

    this.$element = $(el);
    this.config = config;
    this.elements = {};
    this.elements[config.id] = config;

    this.config.usesFixedActionbar = false;

    const data = { ...config.customData, ...config.data };
    if (config.customDataResults) {
      config.customDataResults.forEach((result) => {
        if (data[result.forPropertyName] && result.keyPropertyNames) {
          data[result.forPropertyName] = arrayUtils.leftJoin(
            data[result.forPropertyName] || [],
            result.rows || [],
            result.keyPropertyNames,
            { emptyRecord: result.emptyRecord }
          );
        }
      });
    }

    if (config.dataProvider) {
      this.data = new DataProvider(config.dataProvider, data, {
        singleRecord: true
      }).data;
      dataUtils.trackProperty(this, "data");
      this.hasProvider = true;
    } else {
      this.data = data;
      this.hasProvider = false;
    }

    this._disposables = [];
    this.pendingSubscriptionEvents = ko.observableArray([]);

    // this property will indicate when updates have been committed to the server
    this.saved = ko.observable(false).extend({ notify: "always" });

    this._disposables.push(this.saved.subscribe(this.onSave, this));

    // get saved state from page state
    this.onPreStateRestore();
    this.savedState = pageHandler.restoreState(config.id);

    // save to page lookup
    plexImport("currentPage")[config.id] = this;

    logger.debug(config.id + " has been initialized.");

    this.onInit();

    // mark the controller as busy if any child control is busy or loading
    const self = this;
    self.isBusy = ko.pureComputed(() => {
      const checkForBusy = function (element) {
        if (element.controller) {
          return ["isBusy", "isLoading"].some((obs) => {
            return ko.unwrap(element.controller[obs]);
          });
        }

        return false;
      };

      return Object.keys(self.elements)
        .map((key) => self.elements[key])
        .some(checkForBusy);
    });
  },

  postInit: function () {
    this.onPostInit();
    if (this.data) {
      this.data.$$dirtyFlag = ko.dirtyFlag(this.data);
    }
  },

  onPreStateRestore: function () {
    // empty but may be overriden by inheritors
  },

  onInit: function () {
    // empty but may be overriden by inheritors
  },

  onPostInit: function () {
    // empty but may be overriden by inheritors
  },

  saveOriginalData: function () {
    this.originalData = ko.toJS(this.data);
    this.originalState = this.getState(false);
  },

  prepareData: function () {
    if (!this.hasProvider) {
      // register data with knockout
      if (Array.isArray(this.data)) {
        this.data.forEach((record) => {
          dataUtils.trackObject(record);
        });
      } else {
        dataUtils.trackObject(this.data);

        // also track the data object so swapping it out will trigger a rerender
        // todo: we should probably handle this more elegantly - this isn't going
        // to work as seemlessly as i would like.
        dataUtils.trackProperty(this, "data");
      }
    }

    // save data to repository
    this.data.$$controller = this;
    repository.add(this.config.id, this.data);
  },

  onChange: function (propertyName, callback, context, options) {
    /// <summary>Register a callback to be executed when a record has a change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="context">The context (ie `this`) of the callback. The default will be the associated element's model.</param>
    /// <param name="options">Options include `async` - if set to false the callback will be excuted synchronously.(optional - default to true)</param>
    /// <returns type="Object">Returns the subscription object, which has a `dispose` function to cancel the subscription.</returns>

    options = options || {};
    return this._subscribeToEvent(propertyName, callback, context, "change", options);
  },

  beforeChange: function (propertyName, callback, context, options) {
    /// <summary>Register a callback to be executed when a record is about to change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="context">The context (ie `this`) of the callback. The default will be the associated element's model.</param>
    /// <param name="options">Options include `async` - if set to false the callback will be excuted synchronously.(optional - default to true)</param>
    /// <returns type="Object">Returns the subscription object, which has a `dispose` function to cancel the subscription.</returns>

    options = options || {};
    return this._subscribeToEvent(propertyName, callback, context, "beforeChange", options);
  },

  _subscribeToEvent: function (propertyName, callback, context, eventName, options) {
    const self = this;
    if (!callback) {
      throw new Error("Callback must be provided for change notifications.");
    }

    const observable = getObservable(this.data, propertyName);
    if (!observable || !ko.isObservable(observable)) {
      throw new Error("Property is not an observable. Change notifications are not available.");
    }

    // default context to be the element associated with this element
    context = context || this.elements[propertyName];
    options = options || {};

    // default to true
    const async = options.async !== false;

    const ev = observable.subscribe(
      function () {
        let promise;
        if (async) {
          const defer = options.promise ? jsUtils.deferWithPromise : jsUtils.defer;
          promise = defer(callback, context, arguments, options.delay);
        } else {
          promise = callback.apply(context, arguments);
        }

        if (promise && options.promise) {
          if (
            self.pendingSubscriptionEvents.some((prop) => {
              return prop === propertyName;
            }) === false
          ) {
            self.pendingSubscriptionEvents.push(propertyName);
          }

          promise.always(() => {
            self.pendingSubscriptionEvents.splice(self.pendingSubscriptionEvents.indexOf[propertyName], 1);
          });
        }
      },
      null,
      eventName
    );

    this._disposables.push(ev);
    return ev;
  },

  getState: function (ignoreNull, checkForExcludedElements) {
    const self = this;
    const state = {};
    const ignoredElements = "elements-datalabel";

    // default to true
    ignoreNull = ignoreNull !== false;

    $.each(this.elements, (prop) => {
      // prefer the element's propertyName to identify the state,
      // otherwise the "autoID" element name may be used, which
      // causes issues when elements are toggled, re-ordered, etc.
      const propName = self.elements[prop].propertyName || prop;
      if (propName in state) {
        return;
      }

      const ctrl = self.elements[prop].controller;
      let ctrlState, value;

      if (checkForExcludedElements) {
        if ((ctrl && ctrl.excludeFromDefaults) || ignoredElements.indexOf(self.elements[prop].clientViewName) > -1) {
          return;
        }
      }

      if (ctrl && "getState" in ctrl) {
        // if a controller exists for this element
        // then let it handle creating the state
        // and dealing with null/undefined values.
        ctrlState = ctrl.getState();
        if (ctrlState) {
          state[propName] = ctrlState;
        }
      } else {
        value = ko.utils.peekObservable(self.data[prop]);

        // skip null/undefined
        if (ignoreNull && value == null) {
          return;
        }

        state[propName] = value;
      }
    });

    return state;
  },

  load: function (data) {
    // handle data result instances
    if (data && Array.isArray(data)) {
      data = data.data;
    }

    data = Array.isArray(data) && data.length === 1 ? data[0] : data;
    dataUtils.updateObject(this.data, data);
  },

  restoreState: function (state) {
    const self = this;

    $.each(state, (prop) => {
      const els = [];

      $.each(self.elements, (elem) => {
        const instance = self.elements[elem];
        if ((instance.propertyName === prop || elem === prop) && els.indexOf(instance) === -1) {
          els.push(instance);
        }
      });

      if (els.length === 0) {
        return;
      }

      const value = dataUtils.getValue(state, prop, false);
      els.forEach((el) => {
        const ctrl = el.controller;

        if (ctrl && "restoreState" in ctrl) {
          // Pass in Value not Reference
          ctrl.restoreState($.extend(true, {}, value));
        } else {
          dataUtils.setValue(self.data, prop, value);
        }
      });
    });
  },

  loadElementDefaults: function () {
    const self = this;

    const loadElements = function (data) {
      pageHandler.setState(self.config.id, data);
      self.restoreState(data);
    };

    const loadSaveAsDefaultCallback = function () {
      if (self.config.filterDefaultJson) {
        loadElements(parseJSON(self.config.filterDefaultJson));
      }
    };

    // prefer favorites, but if that fails load saved defaults
    this.loadFavoriteElementDefaults().then(loadElements, loadSaveAsDefaultCallback);
  },

  loadFavoriteElementDefaults: function () {
    return remoteStateStorage.getState().then((state) => state.filterState);
  },

  reset: function () {
    this.restoreState(this.originalState);
  },

  getElementById: function (id) {
    return this.getElement((el) => {
      return el.id === id;
    });
  },

  getElement: function (queryFn) {
    for (const id in this.elements) {
      if (Object.prototype.hasOwnProperty.call(this.elements, id)) {
        const el = findElement(this.elements[id], queryFn);
        if (el) {
          return el;
        }
      }
    }

    return null;
  },

  initElement: function (el, _parentElement) {
    el.parent = this;
    elementHandler.initElement(el, this.data, this.savedState, this);
  },

  setupEvents: function (el) {
    elementHandler.setupEvents(el, this.data, this);
  },

  setupFeatures: function (el) {
    elementHandler.setupFeatures(el, this.data, this);
  },

  onSave: function () {
    this.resetInitialValues();
  },

  resetInitialValues: function () {
    Object.keys(this.elements).forEach((elementName) => {
      const el = this.elements[elementName];
      if (el.propertyName) {
        el.initialDisplayValue = el.displayValue();
      }
    });
  },

  dispose: function () {
    let sub;
    while ((sub = this._disposables.pop())) {
      sub.dispose();
    }

    const currentPage = plexImport("currentPage");
    if (this.config.id && this.config.id in currentPage) {
      delete currentPage[this.config.id];
    }

    // dispose all elements
    Object.keys(this.elements).forEach((id) => {
      if ("controller" in this.elements[id]) {
        const ctrl = this.elements[id].controller;
        ctrl.dispose?.();
      }
    });

    this.elements = {};

    repository.remove(this.config.id);
    pageHandler.removeState(this.config.id);
    this.onDispose();
  },

  onDispose: function () {
    // empty but may be overriden by inheritors for additional cleanup
  }
};

jsUtils.mixin(Controller, "events");
jsUtils.mixin(Controller, "disposable");
jsUtils.makeExtendable(Controller);

module.exports = Controller;
plexExport("Controller", Controller);
