ï»¿// this is a wrapper for the sessionStorage browser property
const parseJSON = require("./plex-parsing-json");

const sessionStorage = window.sessionStorage;

const api = {
  /// <summary>
  /// Stores the item in sessionStorage with the given key.
  /// </summary>
  /// <param name="key">The key to be used in sessionStorage for the item.</param>
  /// <param name="item">The raw item to store in sessionStorage. JSON.stringify should NOT
  ///     be executed on this item yet as setItem executes it internally.
  /// </param>
  setItem: function (key, item) {
    sessionStorage.setItem(key, JSON.stringify(item));
  },

  /// <summary>
  /// Gets the object stored in sessionStorage for the specified key.
  /// </summary>
  /// <param name="key">The key for the object to retrieve in session storage.</param>
  /// <param name="options">
  /// Options object literal - available options include:
  /// - cacheParsers: A boolean stating if a cached reviver or default reviver should be used before returning the parsed value
  /// </param>
  /// <returns type="Object">The item stored in sessionStorage.</returns>
  getItem: function (key, options) {
    const storedValue = sessionStorage.getItem(key);
    let cacheParsers = true;

    if (options && options.cacheParsers !== undefined && options.cacheParsers !== null) {
      cacheParsers = options.cacheParsers;
    }

    return parseJSON(storedValue, cacheParsers);
  },

  /// <summary>
  /// Removes the item with given key from sessionStorage
  /// </summary>
  /// <param name="key">The key for the object to remove from sessionStorage.</param>
  removeItem: function (key) {
    sessionStorage.removeItem(key);
  }
};

module.exports = api;
