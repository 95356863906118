const ko = require("knockout");
const plexExport = require("../../global-export");

function Repository() {
  // eslint-disable-next-line no-invalid-this
  this.items = Object.create(null);
}

Repository.prototype = {
  add: function (key, item) {
    this.items[key] = item;
  },

  get: function (key) {
    return ko.unwrap(this.items[key]);
  },

  remove: function (key) {
    delete this.items[key];
  },

  clear: function () {
    this.items = Object.create(null);
  }
};

module.exports = Repository;
plexExport("Repository", Repository);
