ï»¿const ko = require("knockout");
const PrintWriter = require("./plex-grid-print-writer-base");
const cellWriterFactory = require("../plex-grid-writer-factory");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");

const RowPrintWriter = PrintWriter.extend({
  init: function (grid, layout) {
    const self = this;
    self._base.apply(self, arguments);
    self.config = layout.config;

    this.rowFeatures = layout.features;
    this.columns = ko.unwrap(layout.columns || grid.options.columns);

    this.columns.forEach((column) => {
      initializeWriter(column, grid);
    });

    if (this.rowFeatures && this.rowFeatures.length > 0) {
      this.featureProcessor = new FeatureProcessor(this.rowFeatures, this.config, grid);
    }
  },

  getNodeName: function () {
    return "grid-table-row";
  },

  prePrint: function (record, index, data, rowConfig) {
    const results = this._base.apply(this, arguments);
    if (results === false) {
      return false;
    }

    rowConfig.features = this.features;
    return true;
  },

  print: function (record, index, data, rowConfig, groupIndex, group) {
    const self = this;
    const empty = rowConfig.empty;

    if (group && group.config && group.config.printBreak) {
      if (
        (group.config.printBreak.attribute === "break-before" &&
          (!group.config.headers || group.config.headers.length === 0) &&
          groupIndex === 0) ||
        (group.config.printBreak.attribute === "break-after" &&
          (!group.config.footers || group.config.footers.length === 0) &&
          group.data &&
          groupIndex === group.data.length - 1)
      ) {
        self.node.addAttribute(group.config.printBreak.attribute, group.config.printBreak.condition);
      }
    }

    self.writeCss(self.node, self.features.css.concat(rowConfig.css));
    self.writeAttributes(self.node, self.features.attr);
    self.writeStyle(self.node, self.features.style);

    self.columns.forEach((col, colIndex) => {
      if (col.printVisible() === false) {
        return;
      }

      if (!empty && !col.empty) {
        const currentRecord = col.valueProvider.getRecord(record);
        if (!col.visible()) {
          col.valueProvider.process(currentRecord, index, colIndex);
        }

        if (col.printWriter.prePrint(currentRecord, index, data, rowConfig, groupIndex, group, colIndex) !== false) {
          self.node.appendXml(col.printWriter.print(currentRecord, index, data, colIndex, groupIndex, group));
        }
      } else if (col.empty && col.colspan > 1) {
        self.node.createNode("grid-table-cell", { colspan: col.colspan });
      } else {
        self.node.createNode("grid-table-cell");
      }
    });

    return self.toPrintXml();
  }
});

function initializeWriter(column, grid) {
  if (column.empty) {
    return;
  }

  // account for master column layouts, which will have a `column` property
  if (column.column) {
    initializeWriter(column.column, grid);
    return;
  }

  // init writer
  column.printWriter = cellWriterFactory.create(column.printWriterProvider, grid, grid.options, column);

  // look for default column on master column
  if (column.defaultColumn) {
    initializeWriter(column.defaultColumn, grid);
  }

  // add writers for all child columns
  if (column.columns && column.columns.length > 0) {
    column.columns.forEach((col) => {
      initializeWriter(col, grid);
    });
  }
}

module.exports = RowPrintWriter;
plexExport("grid.writers.RowPrintWriter", RowPrintWriter);
