ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const banner = require("../../Plugins/plex-banner");
const browser = require("../../Core/plex-browser");
const hotkeys = require("../../Navigation/plex-hotkeys");
const jsUtils = require("../../Utilities/plex-utils-js");
const nav = require("../../Core/plex-navigate");
const parseJSON = require("../../Core/plex-parsing-json");
const plexExport = require("../../../global-export");
const plexImport = require("../../../global-import");
const pubsub = require("../../Core/plex-pubsub");
const SideMenusManager = require("./plex-side-menus-manager");

const defaultIconClassName = "plex-menu-icon-cloud";
const sideMenusStateId = "side-menus-state";
const defaultScrollDelta = 100;
const defaultScrollButtonHeight = 20;

function parseDuration(stringValue) {
  if (stringValue) {
    const value = parseFloat(stringValue);

    if (stringValue.endsWith("s")) {
      return value * 1000;
    } else if (stringValue.endsWith("ms")) {
      return value;
    } else {
      return value;
    }
  } else {
    return 0;
  }
}

function SideMenuItem(config, ctrl) {
  this.init(config, ctrl);
}

SideMenuItem.prototype = {
  init: function (config, ctrl) {
    const self = this;

    this.config = config;
    this.iconClassName = ko.observable(config.iconClassName ? config.iconClassName : defaultIconClassName);
    this.text = ko.observable(config.text);
    this.name = config.name;
    this.hasError = ko.observable();
    this.focused = ko.observable(false);
    this.hasFocus = ko.observable(false);
    this.active = ko.observable();
    this.action = config.action;
    this.highlightFocused = true;
    this.hasTouched = ko.observable(false);

    const activeSubscription = this.active.subscribe((newValue) => {
      if (newValue) {
        ctrl.selectItem(self);

        ctrl.onItemSelected(self);
      }
    });

    this.hasFocus.subscribe((newValue) => {
      if (newValue) {
        if (self.highlightFocused) {
          self.focused(newValue);
        }

        self.highlightFocused = true;
        ctrl.clearFocusForAllExcept(self);
      } else {
        self.focused(newValue);
      }
    });

    this.active(config.active);
    this.draggable = ko.observable(config.draggable !== false);

    this.data = $.extend({}, config.data);

    ctrl.initializeClickAction(self);

    this.href = ctrl.getHref(self, ctrl.config);

    this.executeClickAction = function (item, e) {
      item.highlightFocused = false;
      item.hasFocus(true);

      if (
        ctrl.isTouchscreen ||
        (e.originalEvent && e.originalEvent.sourceCapabilities && e.originalEvent.sourceCapabilities.firesTouchEvents)
      ) {
        item.hasTouched(true);
      }

      ctrl.executeClickAction(self, e);
    };

    this.visible = ko.observable(ctrl.isItemVisible(config));

    this._disposables = [activeSubscription];
  },

  dispose: function () {
    let disposable;
    while ((disposable = this._disposables.pop())) {
      disposable.dispose();
    }
  }
};

function SideMenuControllerBase() {
  // constructor
}

SideMenuControllerBase.prototype = {
  constructor: SideMenuControllerBase,

  init: function (el, config) {
    const self = this;

    this.$element = $(el);
    this.config = config;

    this._disposables = [];

    this.visible = ko.observable(false);
    this.hasFocus = ko.observable(false);
    this.visible.subscribe((newValue) => {
      if (newValue) {
        self.onDisplayed();
      }
    });

    this.isTouchscreen = browser.hasTouchscreenCapability;

    this.sideMenuItems = ko.observableArray();
    this.dragging = ko.observable(false);
    this.draggableSelector = ko.observable("");

    if (this.isTouchscreen) {
      this.draggableSelector(".plex-sidetabs-menu-item-help-info");
    }

    this.collapsed = ko.observable(this._loadCollapsedState(config));

    this.collapsed.subscribe((newValue) => {
      const transitionDuration = $(".plex-sidetabs-menu").css("transition-duration");
      let usedDuration = 300;

      if (transitionDuration) {
        const transitionDurations = transitionDuration.split(",");

        if (newValue) {
          usedDuration = parseDuration(transitionDurations[1]);
        } else {
          usedDuration = parseDuration(transitionDurations[0]);
        }
      }

      setTimeout(() => {
        pubsub.publish("sidetabsMenuToggle");
      }, usedDuration);
    });

    this.hasScroll = ko.observable(false);
    this.canScrollUp = ko.observable(false);
    this.canScrollDown = ko.observable(false);

    SideMenusManager.addSideMenu(this);
    this._subscribeToUpDownArrows();

    ko.renderTemplate("sidetabs-menu", this, {}, this.$element[0]);

    plexImport("currentPage")[config.id] = this;

    this.onInit(config);

    this.$listElement = this.$element.find("ul");
    this.$listElement.on("scroll", () => {
      self._updateScrollButtonsVisibility();
    });

    this.listElement = this.$listElement.get(0);

    this.scrollTimer = 0;

    this.hasScroll.subscribe((newValue) => {
      if (!newValue) {
        self.stopScroll(self);
      }
    });

    this.canScrollUp.subscribe((newValue) => {
      if (!newValue) {
        self.stopScroll(self);
      }
    });

    this.canScrollDown.subscribe((newValue) => {
      if (!newValue) {
        self.stopScroll(self);
      }
    });

    $(window).on("resize", () => {
      self.resize();
    });
  },

  resize: function () {
    const targetElement = this.listElement;

    if (targetElement) {
      const self = this;

      self.hasScroll(self._hasScroll(targetElement));

      self._updateScrollButtonsVisibility();
    }
  },

  startScroll: function (direction, source) {
    source.hasFocus(true);
    source._scroll(direction);

    source.scrollTimer = setInterval(() => {
      source._scroll(direction);
    }, 100);
  },

  stopScroll: function (source) {
    clearInterval(source.scrollTimer);
  },

  _scroll: function (direction) {
    if (this.$listElement) {
      let newScrollPosition = 0;

      if (direction === "up") {
        newScrollPosition = this.$listElement.scrollTop() - defaultScrollDelta;
      } else if (direction === "down") {
        newScrollPosition = this.$listElement.scrollTop() + defaultScrollDelta;
      }

      this.$listElement.scrollTop(newScrollPosition);
    }
  },

  onInit: function (_config) {
    // empty but may be overriden by inheritors
  },

  addSideMenuItem: function (config) {
    const newItem = new SideMenuItem(config, this);

    this.sideMenuItems.push(newItem);

    return newItem;
  },

  addSideMenuItems: function (items) {
    const self = this;

    this.sideMenuItems.pushAll(
      items.map((item) => {
        return new SideMenuItem(item, self);
      })
    );
  },

  clearFocusForAllExcept: function (focusedItem) {
    this.sideMenuItems().forEach((item) => {
      if (item.name !== focusedItem.name) {
        item.hasFocus(false);
        item.hasTouched(false);
      }
    });
  },

  initializeClickAction: function (_item) {
    // empty but may be overriden by inheritors
  },

  executeClickAction: function (_item, _e) {
    // empty but may be overriden by inheritors
  },

  selectItem: function (item) {
    this.selectedItem = item;
  },

  onItemSelected: function (_item) {
    // empty but may be overriden by inheritors
  },

  isItemVisible: function (item) {
    return item && item.visible;
  },

  toggleCollapsed: function () {
    this.setCollapsedState(!this.collapsed());
  },

  setCollapsedState: function (collapsed) {
    SideMenusManager.checkCollapsedState(collapsed, this);

    this.collapsed(collapsed);

    let sideMenusState = localStorage.getItem(sideMenusStateId);

    if (sideMenusState) {
      sideMenusState = parseJSON(sideMenusState);
    } else {
      sideMenusState = [];
    }

    let savedMenuState;

    for (let i = 0; i < sideMenusState.length; i++) {
      if (sideMenusState[i].submenuKey === this.config.submenuKey) {
        savedMenuState = sideMenusState[i];
        break;
      }
    }

    if (savedMenuState) {
      this.updateMenuState(savedMenuState);
    } else {
      sideMenusState.push($.extend({ submenuKey: this.config.submenuKey }, this.createMenuState()));
    }

    localStorage.setItem(sideMenusStateId, JSON.stringify(sideMenusState));

    this.isCollapsedStateSaved = true;
  },

  getCollapsedState: function (_menuState) {
    return false;
  },

  createMenuState: function () {
    return {};
  },

  updateMenuState: function (_sideMenuState) {
    // can be overriden
  },

  onBeforeMove: function (_arg, _event, _ui) {
    // can be overriden
  },

  onAfterMove: function (arg, _event, _ui) {
    const updateUrl = nav.buildUrl(this.getSortOrderPath());
    const data = this.getSortOrderData(arg);

    nav.post(updateUrl, data).done((result) => {
      if (result.ValidationResult && !result.ValidationResult.Success) {
        banner.getPageBanner().setMessage(result.ValidationResult.Message, result.ValidationResult.Success);
      }
    });
  },

  onDragStart: function () {
    this.dragging(true);
  },

  onDragStop: function () {
    this.dragging(false);
  },

  getSortOrderPath: function () {
    return "";
  },

  getSortOrderData: function (_arg) {
    return {};
  },

  getHref: function (_item, _sideMenuConfig) {
    return "javascript:void(0);";
  },

  addDisposable: function (disposable) {
    if (disposable) {
      this._disposables.push(disposable);
    }
  },

  dispose: function () {
    SideMenusManager.removeSideMenu(this);

    let disposable;
    while ((disposable = this._disposables.pop())) {
      disposable.dispose();
    }

    this._disposables = [];

    this.sideMenuItems().forEach((item) => {
      item.dispose();
    });

    this.sideMenuItems.removeAll();

    delete plexImport("currentPage")[this.config.id];
  },

  onDispose: function () {
    // empty but may be overriden by inheritors
  },

  onDisplayed: function () {
    // can be overriden
  },

  _subscribeToUpDownArrows: function () {
    const self = this;
    const options = {
      preventDefault: true
    };
    const el = this.$element.get(0);

    hotkeys.addElementHotKey(el, "down", options, () => {
      self._onUpDownArrowPressed(1);
    });

    hotkeys.addElementHotKey(el, "up", options, () => {
      self._onUpDownArrowPressed(-1);
    });
  },

  _onUpDownArrowPressed: function (step) {
    if (!SideMenusManager.hasDraggingItems()) {
      this._setNextFocusedItem(step);
    }
  },

  _setNextFocusedItem: function (step) {
    const sideMenuItems = this.sideMenuItems();
    let focused = -1;

    for (let i = 0; i < sideMenuItems.length; i++) {
      if (sideMenuItems[i].hasFocus()) {
        focused = i;
        break;
      }
    }

    let next = focused + step;

    if (next < 0) {
      next = sideMenuItems.length - 1;
    } else if (next > sideMenuItems.length - 1) {
      next = 0;
    }

    sideMenuItems[next].hasFocus(true);
  },

  _loadCollapsedState: function (config) {
    const sideMenusState = localStorage.getItem(sideMenusStateId);
    let collapsed;

    if (sideMenusState) {
      const menuState = parseJSON(sideMenusState).filter((menu) => {
        return menu.submenuKey === config.submenuKey;
      })[0];

      if (menuState) {
        collapsed = this.getCollapsedState(menuState);
      }
    }

    if (collapsed == null) {
      this.isCollapsedStateSaved = false;

      return false;
    }

    return collapsed;
  },

  _hasScroll: function (element) {
    if (element) {
      return element.scrollHeight > $(element).height();
    } else {
      return false;
    }
  },

  _updateScrollButtonsVisibility: function () {
    this.canScrollUp(this.$listElement.scrollTop() > defaultScrollButtonHeight);
    this.canScrollDown(
      this.$listElement.scrollTop() <
        this.listElement.scrollHeight - this.$listElement.height() - defaultScrollButtonHeight
    );
  }
};

jsUtils.makeExtendable(SideMenuControllerBase);

module.exports = SideMenuControllerBase;
plexExport("SideMenuControllerBase", SideMenuControllerBase);
