ï»¿const $ = require("jquery");
const valueProviderFactory = require("../../Grid/ValueProviders/plex-grid-value-providers-factory");
const columnBuilder = require("./plex-grid-column-builder");
const SelectionState = require("../plex-selection-state");
const DefaultColumn = require("./plex-grid-column");
const plexExport = require("../../../global-export");

const MasterColumn = DefaultColumn.extend({
  init: function (column) {
    let i = column.columns.length;
    let child;

    while (i--) {
      child = column.columns[i];
      if ($.isEmptyObject(child.column)) {
        // remove empty
        column.columns.splice(i, 1);
      } else {
        this.initChild(child.column, column);
      }
    }

    if (column.defaultColumn) {
      this.initChild(column.defaultColumn, column);
    }

    column.valueProvider = valueProviderFactory.create(column, this.parent.config);

    if (column.enableToggleAll) {
      column.selectionState = new SelectionState(
        this.parent.datasource.source,
        column.toggleAllColumn,
        this.parent.grid
      );
    }

    this.applyAttributes(column);
    this.applyCss(column);

    column.css = column.css || this.getCss(column);
    column.composite = true;
    column.$$initialized = true;
  },

  initChild: function (column, master) {
    column.master = master;

    // combine features from master
    column.features = column.features.concat(master.features);

    columnBuilder.build(column, this.parent);

    // apply toggle all to the master
    if (column.enableToggleAll) {
      master.enableToggleAll = true;
      master.toggleAllColumn = column;
    }

    if (column.executeAction && !master.executeAction) {
      master.executeAction = function (data, e) {
        const self = this;

        const record = self.valueProvider.getRecord(data);
        self.valueProvider.getCurrentValueProvider(record);
        const currentColumn = self.valueProvider.currentColumn();
        if (currentColumn && currentColumn.executeAction) {
          return currentColumn.executeAction(data, e);
        }
        return undefined;
      };
    }
  },

  getCss: function (master) {
    let column = null;
    if (master.columns) {
      let i = master.columns.length;
      while (i--) {
        column = master.columns[i].column;

        // use boolean css if toggle all feature is enabled
        if (column.enableToggleAll) {
          return column.css;
        }
      }
    }

    // grab css from default or first column
    column = master.defaultColumn || column;
    if (column) {
      return column.css;
    }

    return "";
  }
});

module.exports = MasterColumn;
plexExport("grid.columns.Master", MasterColumn);
