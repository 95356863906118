ï»¿const dataUtils = require("../Utilities/plex-utils-data");
const dialogAction = require("./plex-actions-dialog");
const plexExport = require("../../global-export");

const ImageDialog = dialogAction.extend({
  onReturnAction: function (dialogData, data, rawData) {
    const self = this;
    const record = rawData || self.data || (self.parent ? self.parent.data : null);
    if (self.propertyName && record) {
      const filename = self.getImageFilename(dialogData);
      dataUtils.setValue(record, self.propertyName, "");
      dataUtils.setValue(record, self.propertyName, filename);
    }
  },

  getImageFilename: function (data) {
    if (this.actionType === "upload") {
      if (data && data.length > 0) {
        const uploadData = data[0].Data;
        if (uploadData && uploadData.UploadFileResults && uploadData.UploadFileResults.length > 0) {
          return uploadData.UploadFileResults[0].Filename;
        }
      }
    }

    return data.Filename || "";
  }
});

module.exports = ImageDialog;
plexExport("actions.ImageDialog", ImageDialog);
