ï»¿const ko = require("knockout");
const jsUtils = require("../Utilities/plex-utils-js");

const slice = Array.prototype.slice;
const states = {
  pending: "pending",
  fulfilled: "fulfilled",
  rejected: "rejected"
};

function createResolver(callback, resolveObservable, stateObservable, expectedState) {
  let fired = false;
  let resolvedValue;

  return ko.computed(() => {
    if (stateObservable() === expectedState) {
      if (callback && !fired) {
        fired = true;
        resolvedValue = callback(resolveObservable());
      }

      // if the callback does not return a value pass the original value
      if (resolvedValue === undefined) {
        resolvedValue = resolveObservable();
      }

      return resolvedValue;
    }

    return undefined;
  });
}

function writeOnceObservable() {
  // only allow the observable to be written to once - all subsequent writes will be ignored
  const underlyingObservable = ko.observable();
  return ko.computed({
    read: underlyingObservable,
    write: function (value) {
      if (underlyingObservable() === undefined) {
        underlyingObservable(value);
      }
    }
  });
}

function promiseToObservable(promise) {
  const valueObservable = ko.observable();
  const rejectObservable = ko.observable();

  promise.then(valueObservable, rejectObservable);

  // wrap the value observable in a computed so that it is functionally readonly
  return koToPromise(ko.computed({ read: valueObservable }), rejectObservable);
}

function koToPromise(valueObservable, rejectObservable, alreadyResolved) {
  rejectObservable = rejectObservable || ko.observable();

  let priorState = alreadyResolved ? states.fulfilled : states.pending;
  const state = (valueObservable.state = ko.pureComputed(() => {
    // after a state is set it is unchangable
    if (priorState !== states.pending) {
      return priorState;
    }

    if (valueObservable() !== undefined) {
      return (priorState = states.fulfilled);
    }

    if (rejectObservable() !== undefined) {
      return (priorState = states.rejected);
    }

    return states.pending;
  }));

  valueObservable.then = function (onFulfilled, onRejected) {
    return koToPromise(
      createResolver(onFulfilled, valueObservable, state, states.fulfilled),
      createResolver(onRejected, rejectObservable, state, states.rejected)
    );
  };

  valueObservable.done = function (onFulfilled) {
    return this.then(onFulfilled, onFulfilled);
  };

  valueObservable.catch = valueObservable.fail = function (onRejected) {
    return this.then(null, onRejected);
  };

  valueObservable.always = valueObservable.finally = function (onResolved) {
    // add to both queues so it fires whether it's fulfilled or rejected
    return this.then(onResolved, onResolved);
  };

  const originalDispose = valueObservable.dispose;
  valueObservable.dispose = function () {
    state.dispose();

    if (rejectObservable.dispose) {
      rejectObservable.dispose();
    }

    if (originalDispose) {
      originalDispose.call(valueObservable);
    }
  };

  return valueObservable;
}

function valueToPromise(value) {
  return koToPromise(ko.observable(value), ko.observable(), true);
}

// entry point
const koPromise = (ko.promise = function (obj, scope) {
  if (jsUtils.isPromise(obj)) {
    // if it is already an observable promise, just return the object
    if (ko.isObservable(obj)) {
      return obj;
    }

    return promiseToObservable(obj);
  }

  if (ko.isObservable(obj)) {
    return koToPromise(obj);
  }

  if (typeof obj === "function") {
    return koToPromise(ko.computed(obj, scope));
  }

  return valueToPromise(obj);
});

ko.computedPromise = function (evaluator) {
  const valueObservable = ko.observable();

  return ko.pureComputed(() => {
    const value = evaluator();

    if (jsUtils.isPromise(value)) {
      value.then(valueObservable);
    } else {
      valueObservable(value);
    }

    return valueObservable();
  });
};

// static methods
koPromise.all = koPromise.when = function (promises) {
  // if an array isn't passed in convert the arguments to an array
  // this allows either an array or multiple arguments to be used
  promises = Array.isArray(promises) ? promises : slice.call(arguments);
  const values = [];
  const pendingCount = ko.observable(promises.length);
  const rejectValue = ko.observable();

  const resolvedValues = ko.pureComputed(() => {
    // only return values once all promises are resolved
    // returning a value will in effect resolve the `when` promise
    if (pendingCount() === 0) {
      return values;
    }

    return undefined;
  });

  const resolver = function (value, index) {
    values[index] = value;
    pendingCount(pendingCount() - 1);
  };

  promises.forEach((current, index) => {
    if (jsUtils.isPromise(current) || ko.isObservable(current)) {
      koPromise(current).then((value) => {
        resolver(value, index);
      }, rejectValue);
    } else {
      resolver(current, index);
    }
  });

  return koToPromise(resolvedValues, rejectValue);
};

koPromise.race = function (promises) {
  promises = Array.isArray(promises) ? promises : slice.call(arguments);
  const resolver = writeOnceObservable();
  const rejector = writeOnceObservable();
  let current;

  for (let i = 0, ln = promises.length; i < ln; i++) {
    current = promises[i];
    if (jsUtils.isPromise(current) || ko.isObservable(current)) {
      koPromise(current).then(resolver, rejector);
    } else {
      // if we have a plain value there is no need to continue processing as this will win
      resolver(current);
      break;
    }
  }

  return koToPromise(resolver, rejector);
};
