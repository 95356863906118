ï»¿const ab = require("ab");
const $ = require("jquery");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

// private members
const api = {};
let _pluginInstance = null;
let _session = null;

function Init() {
  const wsuri = "wss://plugin.plex.com:32493";
  /* eslint new-cap: "off" */
  const deferred = $.Deferred();
  // eslint-disable-next-line no-invalid-this
  const self = this;

  // Autobahn onConnect handler

  function abConnect(pluginSession) {
    logger.log("Autobahn Connected");
    _session = pluginSession;
    // Check the plugin version. If we are working with an old version, show a dialog that notifies the user and takes them to PC Setup.
    // The plugin version will need to be hard coded and updated every time a new version is released.
    pluginSession.call("getVersion").then(
      (res) => {
        if (res.charAt(0) === "v") {
          res = res.slice(1);
        }

        const outdated = false;

        if (outdated === false) {
          deferred.resolve(pluginSession);
        }
      },
      (_error) => {
        logger.warn("Could not retrieve Plex plugin release version. You may be working with an outdated plugin.");
        self.DefineApiMethodsForSession(pluginSession);
        deferred.resolve(pluginSession);
      }
    );
  }

  // Autobahn onHangup handler
  function abHangup(code, failMsg) {
    logger.warn("Autobahn could not connect: ", failMsg);
    deferred.reject(code, failMsg);
  }

  // Initialize Autobahn connection
  ab.connect(wsuri, abConnect, abHangup);

  // todo need to return the promise()
  return deferred.promise();
}

api.getInstance = function () {
  if (_pluginInstance === null) {
    // start the plugin
    _pluginInstance = Init();
  } else if (_session !== null && _session._websocket !== null && _session._websocket.readyState < 2) {
    // todo return null if not connected or out of date.
    // make sure pluginInstance promise is a resolved! otherwise the old promise will be stuck in the failed state.
    _pluginInstance = ab.Deferred().resolve(_session);
    return _pluginInstance;
  } else {
    _session = null;
    _pluginInstance = Init(); // restart the plugin if the current instance is not connected.
  }

  return _pluginInstance;
};

// DefineAPIMethodsForSession has been replaced. Each module can register it's own methods.
api.plugins = {};

module.exports = api;
plexExport("native", api);
