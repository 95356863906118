ï»¿const notify = require("../Core/plex-notify");
const plexData = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const dialogController = require("../Dialogs/plex-dialog-controller");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

const SaveToDCS = Action.extend({
  onInit: function () {
    this.cancelAction =
      this.cancelAction === undefined
        ? function () {
            // noop
          }
        : this.cancelAction;
    if (this.cancelAction instanceof Function === false) {
      this.cancelAction = function () {
        // noop
      };
    }
  },

  onExecute: function (data, _additionalData, nextAction) {
    const self = this;

    if (plexData.isEmpty(data.ParentNode)) {
      // get node no of folder to save to
      dialogController.create({
        route: nav.buildUrl("/Platform/DocumentControlSystem/ViewDocumentPickerDialog", {
          DocumentPickerDialogSelectionContext: "AnyFolderUserCanAddTo"
        }),
        autoShow: true,
        returnAction: function (documentPickerResponse) {
          self.createNodeAndUploadFile(
            documentPickerResponse.NodeNo,
            data.Filename,
            data.DocStorage,
            nextAction,
            data.WebFile
          );
        },
        closeAction: function (_closeData) {
          self.cancelAction();
        }
      });
    } else {
      // if parent node passed - just create and upload
      self.createNodeAndUploadFile(data.ParentNode, data.Filename, data.DocStorage, nextAction, data.WebFile);
    }
  },

  createNodeAndUploadFile: function (parentNodeNo, filename, docStorage, nextAction, webFile) {
    const self = this;

    // call doc properties dialog in order to create node
    dialogController.create({
      route: nav.buildUrl("/Platform/DocumentControlSystem/ViewDocumentPropertiesDialog", {
        DocStorage: docStorage,
        CurrentNode: parentNodeNo,
        NodeNo: 0
      }),
      autoShow: true,
      returnAction: function (dialogData) {
        // if storage is plex server - upload file
        if (docStorage === "Plexus Server") {
          const postData = plexData.cleanse(dialogData);
          postData.Filename = filename;
          postData.WebFile = webFile;
          nav.post(nav.buildUrl("/Platform/DocumentControlSystem/AddReportSnapshot"), postData).done((postResult) => {
            if (postResult.ValidationResult.Success) {
              notify.success(postResult.ValidationResult.Message);
            } else {
              notify.error(postResult.ValidationResult.Message);
            }
          });
        }

        if (nextAction instanceof Function) {
          nextAction(dialogData);
        }
      },

      closeAction: function (_closeData) {
        self.cancelAction();
      }
    });
  }
});

module.exports = SaveToDCS;
plexExport("actions.SaveToDCS", SaveToDCS);
