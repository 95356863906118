ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const expressions = require("../Expressions/plex-expressions-compiler");
const gridUtils = require("../Grid/plex-grid-utils");
const actionHandler = require("./plex-handler-action");
const elementHandler = require("./plex-handler-element");
const logger = require("../Core/plex-logger");
const hotkeys = require("../Navigation/plex-hotkeys");
const repository = require("./plex-model-repository");
const elementRepository = require("./plex-element-repository");
const PageHandler = require("./plex-handler-page");
const pubsub = require("../Core/plex-pubsub");
const dcsAttachments = require("../Utilities/plex-utils-dcs-attachments");
const browser = require("../Core/plex-browser");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

require("../Plugins/plex-actionbar"); // eslint-disable-line import/no-unassigned-import

const selectionFlags = {
  none: 0,
  any: 1,
  one: 2,
  many: 4,
  group: 8,
  modified: 16
};

const sources = {};

// #region helpers

function initializeGroups(configGroups) {
  const actionList = new ActionList();

  cleanGroups(configGroups).forEach((configGroup) => {
    const actionGroup = actionList.addGroup();
    actionGroup.addActions(configGroup.actions);
  });

  return actionList;
}

function ActionList() {
  this.groups = ko.observableArray();
}

ActionList.prototype = {
  addGroup: function () {
    const group = new ActionGroup();
    this.groups.push(group);

    return group;
  },

  removeGroup: function (group) {
    this.groups.remove(group);
  }
};

function ActionGroup() {
  this.actions = ko.observableArray();
}

ActionGroup.prototype = {
  addActions: function (actions) {
    ko.utils.arrayPushAll(this.actions, actions);
  },

  addAction: function (action, actionbar) {
    const cleaned = cleanAction(action);
    if (cleaned) {
      initializeAction(cleaned, actionbar);
      this.actions.push(cleaned);
    }

    return cleaned;
  },

  removeAction: function (action) {
    this.actions.remove(action);
  }
};

function cleanGroups(groups) {
  const cleanedGroups = $.extend(true, [], groups);

  cleanArray(cleanedGroups, (group, groupIndex) => {
    cleanArray(group.actions, (action, actionIndex) => {
      if (action.subActions) {
        cleanArray(action.subActions, (subActionGroup, subActionGroupIndex) => {
          cleanArray(subActionGroup.actions);

          if (subActionGroup.actions.length === 0) {
            action.subActions.splice(subActionGroupIndex, 1);
          }
        });

        if (action.subActions.length === 0) {
          group.actions.splice(actionIndex, 1);
        }
      }
    });

    if (group.actions.length === 0) {
      cleanedGroups.splice(groupIndex, 1);
    }
  });

  return cleanedGroups;
}

function cleanArray(array, extraArrayProcessing) {
  if (array && array.length > 0) {
    let arrayItemIndex = array.length;
    while (arrayItemIndex--) {
      const arrayItem = array[arrayItemIndex];

      if ($.isEmptyObject(arrayItem)) {
        array.splice(arrayItemIndex, 1);
        continue;
      }

      if (typeof extraArrayProcessing === "function") {
        extraArrayProcessing(arrayItem, arrayItemIndex);
      }
    }
  }
}

function cleanAction(action) {
  let cleanedAction = $.extend(true, {}, action);
  if (cleanedAction.subActions) {
    cleanArray(cleanedAction.subActions, (subActionGroup, subActionGroupIndex) => {
      cleanArray(subActionGroup.actions);

      if (subActionGroup.actions.length === 0) {
        cleanedAction.subActions.splice(subActionGroupIndex, 1);
      }
    });

    if (cleanedAction.subActions.length === 0) {
      cleanedAction = null;
    }
  }
  return cleanedAction;
}

function initializeActions(groups, actionbar, el) {
  // empty object may be included within the collection
  // due non-visible items being removed during serialization
  if (groups && groups.length > 0) {
    groups.forEach((group) => {
      // HACK: Doing this temporarily, until I can get subactions into an observable.
      const actions = typeof group.actions === "function" ? group.actions() : group.actions;

      if (actions && actions.length > 0) {
        group.actions.forEach((action) => {
          initializeAction(action, actionbar, el);
        });
      }
    });
  }
}

function initializeAction(el, actionbar, parentAction) {
  // todo: if we switch action to a regular element we would get a lot more flexibility here
  actionbar.elements = actionbar.elements || {};
  actionbar.elements[el.id] = el;
  if (el.action) {
    el.action.isSingleSelection = selectionFlags.one === el.selectionContext;
  }

  const visibleObservable = ko.observable(el.clientVisible);
  el.visible = ko.pureComputed({
    read: function () {
      if (el.action && !ko.unwrap(el.action.authorized)) {
        // if the action is not authorized, never display it
        return false;
      }

      return visibleObservable();
    },
    write: visibleObservable
  });

  elementHandler.setupFeatures(el, null, actionbar);

  if (el.enableExpression) {
    el.enableEvaluator = expressions.compile(el.enableExpression);
  }

  // determine if action is enabled based on the selected items
  el.isEnabled = ko.pureComputed(function () {
    // force a dependency so all computed get rerun on reset
    actionbar.resetTrigger();

    if (isActionDisabled(el)) {
      return false;
    }

    const selected = actionbar.getActionItems(this.action, this);
    const isSelected = selected ? 1 : 0;
    const selectedCount = Array.isArray(selected) ? selected.length : isSelected;

    if (parentAction && !parentAction.isEnabled()) {
      return false;
    }

    if (this.selectionContext === selectionFlags.one && selectedCount !== 1) {
      return false;
    }

    if (this.selectionContext > selectionFlags.one && selectedCount === 0) {
      return false;
    }

    if (this.selectionContext === selectionFlags.group) {
      if (!actionbar.selectedGroup()) {
        return false;
      }
    }

    // note: the enable action might not exist yet - it will have to be retriggered
    // manually when added using reset()
    if (el.enableAction && el.enableAction.address && el.enableAction.address in actionbar) {
      return actionHandler.executeAction(el.enableAction, selected);
    }

    if (el.enableEvaluator) {
      if (selectedCount === 0) {
        return false;
      }

      let i = selectedCount;

      const items = Array.isArray(selected) ? selected : [selected];
      while (i--) {
        if (!el.enableEvaluator(items[i])) {
          return false;
        }
      }
    }

    return this.selectionContext !== selectionFlags.none;
  }, el);

  if (el.action) {
    actionHandler.initAction(el.action, actionbar.boundController, null, actionbar);
    el.href =
      el.href ||
      ko.pureComputed(() => {
        let href;

        // need to make sure that dependencies get registered before the disabled check
        if (typeof el.action.href === "function") {
          let selected = actionbar.getActionItems(el.action, el);
          if (Array.isArray(selected) && el.selectionContext === selectionFlags.one) {
            selected = selected[0];
          }

          href = el.action.href(selected);
        } else {
          href = el.action.href;
        }

        if (!el.isEnabled()) {
          return "";
        }

        return href;
      });
  }

  if (el.enableAction) {
    actionHandler.initAction(el.enableAction, actionbar);
  }

  // wrap up controller call to executeAction
  el.executeAction = function (vm, event) {
    return actionbar.executeAction(el, event);
  };

  if (el.subActions && el.subActions.length > 0) {
    initializeActions(el.subActions, actionbar, el);
  }

  if (el.action && el.action.sourceId) {
    if (!(el.action.sourceId in sources) && el.action.sourceId !== actionbar.config.parentId) {
      // todo: be smarter about this - we shouldn't need to reset the entire actionbar
      // what we might want to do is change from having selectedItem(s) and instead
      // just have each action individually listen for data (when required)
      sources[el.action.sourceId] = pubsub.subscribe("selected.*." + el.action.sourceId, actionbar.reset, actionbar);
    }

    if (!el.action.data) {
      // todo: should this go into the actionHandler.init?
      el.action.data = repository.get(el.action.sourceId);
    }
  }
}

function isActionDisabled(el) {
  if (el.action) {
    return ko.unwrap(el.action.disabled);
  }

  let i;
  if (el.subActions && el.subActions.length > 0) {
    i = el.subActions.length;
    while (i--) {
      if (!isActionDisabled(el.subActions[i])) {
        return false;
      }
    }
  }

  if (el.actions && el.actions.length > 0) {
    i = el.actions.length;
    while (i--) {
      if (!isActionDisabled(el.actions[i])) {
        return false;
      }
    }
  }

  return true;
}

function setupAttachmentActions(actionbar) {
  actionbar.actionList.groups().forEach((group) => {
    const actions = typeof group.actions === "function" ? group.actions() : group.actions;
    if (actions && actions.length > 0) {
      actions.forEach((action) => {
        if (action.attachmentGroupKey && action.clientViewName === "actionbar-attachments-action-item") {
          // since this is called from post init, all controllers are already initialized
          const currentPage = plexImport("currentPage");
          const source = currentPage[action.sourceId || actionbar.config.parentId];

          if (
            source &&
            source.data &&
            source.config &&
            (source.config.clientViewName === "elements-formcontent" || source.config.clientViewName === "form")
          ) {
            const attachmentProperty = dcsAttachments.getAttachmentsCountTokenName(action.attachmentGroupKey);
            if (attachmentProperty in source.data) {
              return;
            }

            const attachmentCountObservable = (source.data[attachmentProperty] = ko.observable());
            ko.applyBindings(
              { value: source.data[attachmentProperty] },
              $("#" + action.id + " .plex-actions-attachment-count")[0]
            );

            const attachmentContext = dcsAttachments.createAttachmentContext(source.data, action);
            dcsAttachments.getAttachmentsCount(attachmentContext).then(attachmentCountObservable);
          }
        }
      });
    }
  });
}

function actionOrChildMatches(action, predicate) {
  if (!action) {
    return false;
  }

  if (predicate(action)) {
    return true;
  }

  if (action.type === "Ask") {
    return action.choices.some((choice) => actionOrChildMatches(choice.action, predicate));
  }

  return actionOrChildMatches(action.postAction, predicate);
}

function setupPrintHotKey(actionbar) {
  // note that this doesn't look at menu items, though that's probably ok for our purposes.
  const groups = actionbar.actionList.groups();
  if (groups && groups.length > 0) {
    for (let j = 0; j < groups.length; j++) {
      const actions = ko.unwrap(groups[j].actions);
      if (actions && actions.length > 0) {
        for (let i = 0; i < actions.length; i++) {
          if (actionOrChildMatches(actions[i].action, (action) => action.type === "Print")) {
            addHotKey(actions[i]);
            return;
          }
        }
      }
    }
  }

  function addHotKey(action) {
    hotkeys.addHotKey(["command+p", "ctrl+p"], { bindGlobal: true }, () => {
      if (!isActionDisabled(action)) {
        if (browser.canInterceptPrintHotkey) {
          action.executeAction();
          // eslint-disable-next-line no-alert
        } else if (confirm("Would you like to use the plex print dialog?")) {
          // IE does not allow ctrl + p hotkey to be overridden. Firing native confirm dialog will prevent IE's print dialog from displaying (UX-763)
          // eslint-disable-next-line no-alert

          action.executeAction();
        }
      }
    });
  }
}

function emptyElement(element) {
  while (element.firstChild) {
    ko.removeNode(element.firstChild);
  }
}

// #endregion

function ActionBarController() {
  // constructor
}

ActionBarController.prototype = {
  constructor: ActionBarController,

  init: function (el, config) {
    this.$element = $(el);
    this.config = config;

    // Collection of objects such as subscriptions to the actionbar's parent controller
    // for disposal when the actionbar is disposed.
    this._disposables = this._disposables || [];

    // either one of these is populated depending on whether
    // the actionbar is linked to a multi-select grid/single-select grid/form
    this.selectedItems = ko.observableArray();
    this.modifiedItems = ko.observableArray();
    this.selectedItem = ko.observable(null);
    this.selectedGroup = ko.observable(null);
    this.resetTrigger = ko.observable();
    this.actionList = initializeGroups(this.config.groups);

    plexImport("currentPage")[this.config.id] = this;

    if (this.config.parentId) {
      this._disposables.push(pubsub.subscribe("selected.*." + this.config.parentId, this.onSelected, this));
      this._disposables.push(pubsub.subscribe("modified.*." + this.config.parentId, this.onModified, this));
      this._disposables.push(pubsub.subscribe("selectedGroup.*." + this.config.parentId, this.onSelectedGroup, this));
      this._disposables.push(pubsub.subscribe("layoutChanged.*." + this.config.parentId, this.reset, this));
    } else {
      logger.warn("The actionbar does not have a parent assigned to it.");
    }
  },

  postInit: function () {
    if (this.initialized) {
      return;
    }

    const currentPage = plexImport("currentPage");
    let parent;
    if (this.config.parentId) {
      parent = currentPage[this.config.parentId];
      if (!parent) {
        const element = elementRepository.get(this.config.parentId);
        if (element && element.controller) {
          parent = element.controller;
        }
      }
    } else {
      // let's see if we can deduce the parent
      const ids = Object.keys(currentPage);
      if (ids.length === 2) {
        // if there are only 2 components on the page, then we know that the other one is our parent
        // this will be the case for forms & wizards
        const id = ko.utils.arrayFirst(ids, (x) => currentPage[x] !== this);
        parent = currentPage[id];
      }
    }

    this.initialized = true;
    this.boundController = parent;

    initializeActions(this.actionList.groups(), this);

    if (parent) {
      if (parent.data && !$.isEmptyObject(parent.data)) {
        // get initial selections for form
        this.onSelected(parent.data);
      } else if (ko.isObservable(parent.selected) && parent.selected().length > 0) {
        // get initial selections for grid
        this.onSelected(parent.selected());
      }

      if (typeof parent.actionbarController !== "undefined") {
        parent.actionbarController = this;
      }
    }

    // Only subscribe if the parent has also subscribed.
    // This prevents executing searches for invisible grids.
    if (
      parent &&
      parent.config &&
      parent.config.searchActionId &&
      pubsub.hasSubscriptions(`searched.${parent.config.searchActionId}`)
    ) {
      this._disposables.push(pubsub.subscribe("searched." + parent.config.searchActionId, this.reset, this));
    }

    ko.applyBindings(this, this.$element[0]);
    this.$actionbar = this.$element.actionbar({ controller: this }).data("actionbar");
    pubsub.publish("actionbarInit", { actionbarId: this.config.id });
    setupAttachmentActions(this);
    setupPrintHotKey(this);
  },

  load: function (config) {
    this.config = config;

    this.actionList = initializeGroups(this.config.groups);
    initializeActions(this.actionList.groups(), this);

    emptyElement(this.$element[0]);
    ko.renderTemplate("actionbar", this, {}, this.$element[0]);

    setupAttachmentActions(this);
  },

  onSelected: function (selectedItems) {
    // case for listView which data is observable array
    selectedItems = ko.unwrap(selectedItems);

    if (selectedItems == null || (Array.isArray(selectedItems) && selectedItems.length === 0)) {
      // null would indicate no selections so clear both
      this.selectedItem(null);
      this.selectedItems([]);
      return;
    }

    if (Array.isArray(selectedItems)) {
      this.selectedItems(selectedItems);
    } else {
      this.selectedItem(selectedItems);
    }
  },

  onModified: function (modifiedItems) {
    if (modifiedItems) {
      this.modifiedItems(modifiedItems);
    } else {
      this.modifiedItems([]);
    }
  },

  onSelectedGroup: function (selectedGroup) {
    this.selectedGroup(selectedGroup);
  },

  getActionItems: function (action, config) {
    let sourceId;

    // Check for Source if Action
    if (action && action.sourceId) {
      sourceId = action.sourceId;
    }
    // Check for Source if Menu
    else if (config && config.sourceId) {
      sourceId = config.sourceId;
    }

    if (sourceId) {
      return repository.get(sourceId);
    }

    // when dealing with group selection, return the first record from the selected
    // group if it is set. This is what the action will use for execution
    if (config.selectionContext === selectionFlags.group && this.selectedGroup()) {
      return gridUtils.getFirstRecord(this.selectedGroup());
    }

    if (config.selectionContext === selectionFlags.modified) {
      return this.modifiedItems();
    }

    return this.selectedItem() || this.selectedItems();
  },

  executeAction: function (action, event) {
    if (!action.isEnabled()) {
      return false;
    }

    let selected = this.getActionItems(action.action, action);
    if (Array.isArray(selected) && action.selectionContext === selectionFlags.one) {
      if (selected.length > 1) {
        throw new Error("This action only supports a single selection.");
      }

      selected = selected[0];
    }

    action.action.data = selected;
    return actionHandler.executeAction(action.action, selected, event);
  },

  reset: function () {
    this.resetTrigger.notifySubscribers();
  },

  dispose: function () {
    // Dispose of objects such as subscriptions to the actionbar's parent controller.
    if (this._disposables) {
      let sub;
      while ((sub = this._disposables.pop())) {
        sub.dispose();
      }
    }

    // Dispose of the actionbar element.
    if (this.$actionbar) {
      this.$actionbar.dispose();
      this.$actionbar = null;
    }

    hotkeys.removeHotKey(["command+p", "ctrl+p"]);

    repository.remove(this.config.id);
    PageHandler.removeState(this.config.id);
    delete plexImport("currentPage")[this.config.id];
  }
};

ActionBarController.create = function () {
  return new ActionBarController();
};

module.exports = ActionBarController;
plexExport("ActionBarController", ActionBarController);
