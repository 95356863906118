/* global plex */
// TODO: should use imports instead of plex global
import React from "react";
import $ from "jquery";
import { SidePanel, SideTab, MountSide, ActionSection, Button, Link, Tabs, Spinner } from "@plex/react-components";
import { post, buildUrl } from "../../Core/plex-navigate";
import OpenLocalFileFromDCS from "../../Actions/plex-actions-open-local-file-from-dcs";
import handlerAction from "../plex-handler-action";
import pubsub from "../../Core/plex-pubsub";
import DialogController from "../../Dialogs/plex-dialog-controller";
import glossary from "../../Globalization/plex-glossary-handler";
import PageState from "../../Core/plex-pagestate";

export const helpSidePanelStateKeyState = "helpSidePanelState";
export const helpSidePanelStateKeyLastKnownTopicPathname = "helpSidePanelLastKnownTopicPathname";
export const helpSidePanelStateKeyLastKnownTopicSearchCriteria = "helpSidePanelLastKnownTopicSearchCriteria";

const viewAttachment = (attachment) => {
  if (attachment.hasAction) {
    if (attachment.docStorage === "Local File") {
      const action = new OpenLocalFileFromDCS();
      action.execute({ filename: attachment.filename });
    } else if (attachment.docStorage === "Web Address") {
      window.open(attachment.filename);
    } else {
      DialogController.create({
        route: buildUrl("/Platform/AttachmentSystem/ViewDocumentDialog", { NodeNo: attachment.nodeNo }),
        autoShow: true
      });
    }
  }
};

const getDocumentation = (cloudApplicationKey) => {
  return post("/Platform/Navigation/GetEmbeddedPMCDocumentation", {
    RecordKeyValue: cloudApplicationKey,
    ReadOnly: false
  });
};

const manageInstructions = (action) => {
  handlerAction.initAction(action);
  handlerAction.executeAction(action);
};

const HelpPanelTitle = ({ title }) => {
  return (
    <>
      <i className="plex-icon-iiot-help" />
      <div className="side-panel-title">{title}</div>
    </>
  );
};

const TabTitle = ({ title }) => {
  return <div className="tab-title">{title}</div>;
};

const SideTabTitle = ({ title }) => {
  return (
    <div className="plex-help-side-tab-header-title">
      <div className="plex-help-side-tab-title-text">{title}</div>
    </div>
  );
};

export const HelpSidePanel = ({
  cloudApplicationKey,
  onClose,
  onCollapse,
  onExpand,
  panelContainerClassName,
  tabContainerClassName
}) => {
  const [state, setState] = React.useState({
    helpTopics: {
      url: "",
      errorMessage: "",
      title: "",
      isLoaded: false
    },
    workInstructions: {
      manageInstructions: {
        action: "",
        buttonText: ""
      },
      attachments: [],
      infoMessage: "",
      title: ""
    },
    isLoaded: false,
    isResizing: false,
    selectedTabIndex: 0,
    title: ""
  });

  const onManageInstructionsClick = React.useCallback(() => {
    manageInstructions(state.workInstructions.manageInstructions.action);
  }, [state.workInstructions.manageInstructions.action]);

  const onViewAttachmentClick = (attachment) => {
    viewAttachment(attachment);
  };

  const onIframeLoad = (_iframe) => {
    setState((prev) => ({ ...prev, helpTopics: { ...prev.helpTopics, isLoaded: true } }));

    const helpIframe = $(".side-panel-iframe")[0];
    if (helpIframe && helpIframe.contentDocument && helpIframe.contentDocument.location) {
      // The ability to reopen the previously opened topic in the new documentation site after navigating to a new cloud application
      // will need to be disabled due to same-origin iframe policies. Tech Docs will work on a replacement solution contained within the new site.
      const currentHelpTopicPathname = helpIframe.contentDocument.location.pathname;
      const currentHelpTopicSearchCriteria = helpIframe.contentDocument.location.search;

      PageState.addToCurrentState(helpSidePanelStateKeyLastKnownTopicPathname, currentHelpTopicPathname);
      PageState.addToCurrentState(helpSidePanelStateKeyLastKnownTopicSearchCriteria, currentHelpTopicSearchCriteria);
    }
  };

  const onResize = (resizeInfo) => {
    setState((prev) => ({ ...prev, isResizing: resizeInfo ? resizeInfo.isResizing : false }));

    pubsub.publish("helpPanelResized");
  };

  React.useEffect(() => {
    const renderedSubscription = pubsub.subscribe("rendered.DocumentControlSystemAttachmentsDialog", () => {
      plex.banner.setCustomOptions = function (options) {
        return $.extend({}, options, { toast: false });
      };
    });

    const subscription = pubsub.subscribe("closeDialogDocumentControlSystemAttachmentsDialog", () => {
      plex.banner.setCustomOptions = function (options) {
        return options;
      };
      setState((prev) => ({ ...prev, isLoaded: false }));
    });

    glossary
      .getCustomerWordBulkAsync([
        "Help",
        "Help Topics",
        "Work Instructions",
        "You do not have access to the Help Topics",
        "Manage Instructions",
        "No documents have been added for this screen"
      ])
      .then((result) => {
        setState((prev) => ({
          ...prev,
          title: result.Help,
          helpTopics: {
            ...prev.helpTopics,
            title: result["Help Topics"],
            errorMessage: result["You do not have access to the Help Topics"]
          },
          workInstructions: {
            ...prev.workInstructions,
            title: result["Work Instructions"],
            infoMessage: result["No documents have been added for this screen"],
            manageInstructions: {
              ...prev.workInstructions.manageInstructions,
              buttonText: result["Manage Instructions"]
            }
          }
        }));
      });

    onResize();

    return function () {
      pubsub.unsubscribe(subscription);
      pubsub.unsubscribe(renderedSubscription);
    };
  }, []);

  React.useEffect(() => {
    if (!state.isLoaded) {
      getDocumentation(cloudApplicationKey).then((e) => {
        let topicToLoad = e.helpTopicsUrl;

        // User has changed the Help Topic AND navigated to a new UX Cloud App with the Help Panel open
        // Possible change: Should we let the user know they are looking at a different article than what the current application is tied to?
        const lastKnownHelpTopicPathname = PageState.getLastKnownValue(helpSidePanelStateKeyLastKnownTopicPathname);
        const lastKnownHelpTopicSearchCriteria = PageState.getLastKnownValue(
          helpSidePanelStateKeyLastKnownTopicSearchCriteria
        );

        if (lastKnownHelpTopicPathname && lastKnownHelpTopicPathname !== e.helpTopicsUrl) {
          topicToLoad = lastKnownHelpTopicPathname + lastKnownHelpTopicSearchCriteria;
        }

        setState((prev) => ({
          ...prev,
          helpTopics: {
            ...prev.helpTopics,
            url: topicToLoad,
            isLoaded: true
          },
          workInstructions: {
            ...prev.workInstructions,
            attachments: e.attachments,
            visible: e.workInstructionsVisibility,
            manageInstructions: { ...prev.workInstructions.manageInstructions, action: e.manageInstructionsAction }
          },
          isLoaded: true
        }));
      });
    }
  }, [state.isLoaded, cloudApplicationKey]);

  return (
    <>
      <SidePanel
        title={<HelpPanelTitle title={state.title} />}
        containerClassName={panelContainerClassName}
        onCollapse={onCollapse}
        onClose={onClose}
        onResize={onResize}
        mountSide={MountSide.right}
      >
        <Tabs
          selectedIndex={state.selectedTabIndex}
          onSelect={(index) => {
            setState((prev) => ({ ...prev, selectedTabIndex: index }));
          }}
        >
          <Tabs.TabList className="side-panel-tabs-list">
            <Tabs.Tab>
              <TabTitle title={state.helpTopics.title} />
            </Tabs.Tab>
            {state.workInstructions.visible && (
              <Tabs.Tab>
                <TabTitle title={state.workInstructions.title} />
              </Tabs.Tab>
            )}
          </Tabs.TabList>
          <Tabs.TabPanel keepMounted>
            <>
              {state.helpTopics.url ? (
                <>
                  {state.isResizing && <div className="resize-back" />}
                  <iframe className="side-panel-iframe" src={state.helpTopics.url} onLoad={onIframeLoad} />
                </>
              ) : (
                state.helpTopics.isLoaded && (
                  <div className="info-message">
                    <p>{state.helpTopics.errorMessage}</p>
                  </div>
                )
              )}
              {!state.helpTopics.isLoaded && (
                <div className="info-message">
                  <div>
                    <Spinner show type="gear" />
                  </div>
                </div>
              )}
            </>
          </Tabs.TabPanel>

          {state.workInstructions.visible && (
            <Tabs.TabPanel>
              {state.workInstructions.attachments.length > 0 ? (
                <ul className="side-panel-list">
                  {state.workInstructions.attachments.map((attachment) => (
                    <li
                      className="side-panel-list-item"
                      key={attachment.nodeNo}
                      onClick={() => {
                        onViewAttachmentClick(attachment);
                      }}
                    >
                      <Link className="side-panel-list-item-button">{attachment.name}</Link>
                      {attachment.note && <h4 className="side-panel-list-item-text">{attachment.note}</h4>}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="info-message">
                  <p>{state.workInstructions.infoMessage}</p>
                </div>
              )}
              {state.workInstructions.manageInstructions.action && (
                <ActionSection className="side-panel-action-section">
                  <Button type="button" onClick={onManageInstructionsClick}>
                    {state.workInstructions.manageInstructions.buttonText}
                  </Button>
                </ActionSection>
              )}
            </Tabs.TabPanel>
          )}
        </Tabs>
      </SidePanel>
      <SideTab
        title={<SideTabTitle title={state.title} />}
        onClose={onClose}
        onExpand={onExpand}
        className={tabContainerClassName}
        mountSide={MountSide.right}
      />
    </>
  );
};
