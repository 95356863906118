ï»¿const ko = require("knockout");
const $ = require("jquery");
const Glossary = require("../Globalization/plex-glossary-handler");
const stringUtils = require("../Utilities/plex-utils-strings");

const GLOSSARY_REQUEST_TOKEN = "__ko_binding_glossaryText";
let requestCounter = 0;

function unwrapTokens(tokens) {
  // unwrap all the tokens to get underlying values
  // and to make sure dependencies are registered
  return tokens.map(ko.unwrap);
}

function updateTarget(text, $element, target) {
  text = text || "";
  target = target.toLowerCase();

  if (target === "text") {
    if ($element[0].nodeType === 8) {
      ko.utils.setTextContent($element[0], text);
    } else {
      // glossary terms can include markup so need to update the html of the element.
      $element.html(text);
    }
  } else {
    $element.attr(target, text);
  }
}

ko.bindingHandlers.glossarizedText = {
  init: function (element) {
    // we only care about this for virtual nodes
    return { controlDescendantBindings: element.nodeType === 8 };
  },

  update: function (element, valueAccessor) {
    const $element = $(element);
    let target = "text";
    let tokens = [];
    const options = ko.unwrap(valueAccessor());
    let enabled = true;
    let requestId, text;

    if (options && typeof options === "object") {
      text = ko.unwrap(options.text);
      tokens = options.tokens ? unwrapTokens(options.tokens) : tokens;
      target = options.target || target;
      enabled = options.autoGlossarize !== false; // default true
    } else {
      text = options;
    }

    if (text && enabled) {
      // assign a unique id to this request which will replace any previous incomplete requests
      $element[0][GLOSSARY_REQUEST_TOKEN] = requestId = ++requestCounter;

      // if there are no tokens go ahead and set the initial text to avoid a lag since most of the time
      // the initial text will be all we need
      if (tokens.length === 0) {
        updateTarget(text, $element, target);
      }

      Glossary.getCustomerWordAsync({ text, tokens }, { batch: true }).then((result) => {
        // check that this is the current request - due to this being
        // async it's possible for another request to come through
        // and the later one to finish first causing the earlier update to
        // be applied when it should not be.
        const currentRequest = $element[0][GLOSSARY_REQUEST_TOKEN];
        if (currentRequest && currentRequest === requestId) {
          updateTarget(result, $element, target);
          delete $element[0][GLOSSARY_REQUEST_TOKEN];
        }
      });
    } else {
      updateTarget(stringUtils.format(text || "", tokens), $element, target);
    }
  }
};

ko.virtualElements.allowedBindings.glossarizedText = true;
