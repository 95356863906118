/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const domUtils = require("../Utilities/plex-utils-dom");
const jsUtils = require("../Utilities/plex-utils-js");
const formatting = require("../Globalization/plex-formatting");
const CustomerDate = require("../Globalization/plex-customer-date");

const REPORT_SERVER_CONNECTION_TYPE = 6;
const PERSISTENT_BANNER_SELECTOR = ".plex-persistent-banner-container:first";
const banners = [];

function BannerInstance(serverInfo, owner, bannerControl) {
  this.server = serverInfo;
  this.bannerControl = bannerControl;
  this.owners = ko.observableArray([owner]);
  this.subscriptions = [];
  this.visible = ko.pureComputed(() =>
    this.owners().some((o) => ko.unwrap((o.config && o.config.visible) || o.visible))
  );

  this.toggle(this.visible());
  this.subscriptions.push(this.visible.subscribe(this.toggle, this));
  this.subscriptions.push(
    this.owners.subscribe((owners) => {
      if (owners.length === 0) {
        this.dispose();
      }
    }, this)
  );
}

BannerInstance.prototype = {
  constructor: BannerInstance,

  toggle: function (visible) {
    if (jsUtils.isPromise(this.banner)) {
      this.banner.then(() => this.toggle(visible));
      return;
    }

    if (visible) {
      const dt = new CustomerDate(this.server.SnapshotDate);
      const displayDate = formatting.DateTimeFormatter.format(dt, { format: "g" });

      this.banner =
        this.banner ||
        this.bannerControl.addMessage(
          {
            autoGlossarize: true,
            text: "Report Server - Data as of {1}",
            tokens: [displayDate]
          },
          { status: "info" }
        );

      if (jsUtils.isPromise(this.banner)) {
        this.banner.then((bnr) => (this.banner = bnr));
      }
    } else if (this.banner) {
      this.banner.clear();
      this.banner = null;
    }
  },

  dispose: function () {
    this.visible.dispose();
    this.subscriptions.forEach((s) => s && s.dispose());
    this.subscriptions.length = 0;
    this.toggle(false);

    banners.remove(this);
  }
};

function tryUseExistingByServerKey(serverKey, owner) {
  const instance = ko.utils.arrayFirst(banners, (b) => b.server.ServerKey === serverKey);
  if (instance) {
    instance.owners.push(owner);
    return true;
  }

  return false;
}

function tryUseExistingByServiceName(serviceName, owner) {
  const instance = ko.utils.arrayFirst(banners, (b) => b.server.ServiceName === serviceName);
  if (instance) {
    instance.owners.push(owner);
    return true;
  }

  return false;
}

module.exports = {
  /**
   * Creates a Db server snapshot info banner if the info qualifies for display.
   *
   * @param {Object} serverInfo The server info from a data source response.
   * @param {HtmlElement} ownerElement The element which owns the message.
   * @void
   */
  setBanner: function (serverInfo, owner) {
    if (
      !serverInfo ||
      !serverInfo.SnapshotDate ||
      !(
        (serverInfo.ServiceName && serverInfo.ServiceName.endsWith("_report")) ||
        serverInfo.ConnectionType === REPORT_SERVER_CONNECTION_TYPE
      )
    ) {
      return;
    }

    if (serverInfo.ServiceName && tryUseExistingByServiceName(serverInfo.ServiceName, owner)) {
      return;
    }

    if (serverInfo.ServerKey && tryUseExistingByServerKey(serverInfo.ServerKey, owner)) {
      return;
    }

    const page = domUtils.getParentPage(owner.$element || owner.element);
    const bannerControl = $(page).find(PERSISTENT_BANNER_SELECTOR).data("banner");
    if (bannerControl) {
      const instance = new BannerInstance(serverInfo, owner, bannerControl);
      banners.push(instance);
    }
  },

  /**
   * Removes server info banner bound to given element. (If there are other elements
   * bound to the same banner the banner will not be removed until all have called
   * to remove the element.
   *
   * @param {HtmlElement} ownerElement The element which owns the message.
   * @void
   */
  removeBanner: function (owner) {
    const instance = ko.utils.arrayFirst(banners, (b) => b.owners.indexOf(owner) >= 0);
    if (instance) {
      instance.owners.remove(owner);
    }
  }
};
