ï»¿// exporting at top to resolve an issue with cyclical reference between this & glossary
// todo: split this up into multiple files for better separation
const api = (module.exports = {});

const $ = require("jquery");
const ko = require("knockout");
const env = require("./plex-env");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const pageState = require("./plex-pagestate");
const pageHandler = require("../Controls/plex-handler-page");
const sessionManager = require("./plex-session");
const enterpriseManager = require("./plex-enterprise");
const parseURL = require("./plex-parsing-url");
const notify = require("./plex-notify");
const banner = require("../Plugins/plex-banner");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const ClientError = require("./plex-client-error");
const urlQueryParmsConstants = require("../Constants/url-related-constants");
require("../Polyfills/object.assign"); // eslint-disable-line import/no-unassigned-import

api.AppStateTokenName = "__asid";
api.FavoriteActionKeyTokenName = "__fak";
api.PageModeTokenName = "__mode";
api.AutoSearchTokenName = "search";
api.SessionTokenName = "__si";

api.pageModes = {
  normal: "normal",
  update: "update"
};

api.domainHosts = {
  cloud: "cloud",
  cdn: "cdn",
  api: "api"
};

const currentUrl = parseURL.parseUrl();

const navigateDefaults = {
  showOverlay: true,
  keepAppState: true,
  ignoreEmptyData: true,
  newWindow: false,
  pageMode: "normal",
  host: "cloud",
  autoSearch: false,
  replaceHistory: false,
  includeCrossPcn: true,
  resolveToAbsolute: true
};

const domainRoot = parseURL.getDomainRoot();
const activeRequests = [];

const appendToForm = function (form, data, arrayKey, arrayIndex) {
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((obj, index) => {
        appendToForm(form, obj, key, index);
      });
    } else if (arrayKey) {
      const name = `${arrayKey}[${arrayIndex}].${key}`;
      $("<input />")
        .css("display", "none")
        .attr("type", "text")
        .attr("name", name)
        .attr("value", data[key])
        .appendTo(form);
    } else {
      $("<input />").attr("type", "hidden").attr("name", key).attr("value", data[key]).appendTo(form);
    }
  });
};

const executePost = function (url, data, options) {
  if (options.showOverlay) {
    $(document).block();
  }

  if (options.ajax === false) {
    const deferred = new $.Deferred();
    if (options.createForm) {
      const $form = $("<form />");
      appendToForm($form, data);
      $form.prependTo(document.body);
    }

    const form = $("form")[0];
    if (!form) {
      throw new Error("Unable to post - no form was found.");
    }

    form.action = url;
    form.method = options.method;
    form.submit();

    deferred.resolve();

    if (options.showOverlay) {
      $(document).unblock();
    }

    return deferred.promise();
  } else {
    const promise = $.ajax({
      url: api.buildUrl(url, null, options),
      type: options.method,
      data: options.standardArrays ? JSON.stringify(data) : api.serialize(data)
    });

    if (options.showOverlay) {
      promise.always(() => {
        $(document).unblock();
      });
    }

    return promise;
  }
};

// #region private functions

function resolve(response, requests) {
  for (let i = 0, ln = requests.length; i < ln; i++) {
    if (requests[i].tryResolve(response)) {
      // eliminate so that it doesn't get resolved again
      requests.splice(i, 1);
      return;
    }
  }
}

function makeRequest(url, mappedRequests) {
  const dataArray = mappedRequests.map((request) => {
    return request.data;
  });
  const options = mappedRequests[0].options;

  api.post(url, dataArray, options).then(
    (responses) => {
      responses = responses.Data || responses;
      if (!Array.isArray(responses)) {
        throw Error("Unsupported response returned from batch request - expected an Array.");
      }

      responses.forEach((response) => {
        resolve(response, mappedRequests);
      });
    },
    (reason) => {
      mappedRequests.forEach((request) => {
        request.reject(reason);
      });
    }
  );
}

function dequeue() {
  const mappedRequests = activeRequests.splice(0, activeRequests.length).reduce((acc, request) => {
    if (!(request.url in acc)) {
      acc[request.url] = [];
    }

    acc[request.url].push(request);
    return acc;
  }, {});

  for (const url in mappedRequests) {
    if (Object.prototype.hasOwnProperty.call(mappedRequests, url)) {
      makeRequest(url, mappedRequests[url]);
    }
  }
}

function enqueue(url, data, keySelector, options) {
  if (activeRequests.length === 0) {
    jsUtils.defer(dequeue);
  }

  const similarRequests = activeRequests.filter((request) => {
    return request.url === url && dataUtils.compareObjects(request.data, data);
  });

  if (similarRequests.length > 0) {
    return similarRequests[0].promise;
  }

  const deferred = new $.Deferred();

  const newRequest = {
    url,
    data,
    options,
    promise: deferred.promise(),
    tryResolve: function (response) {
      if (keySelector(response)) {
        deferred.resolve(response);
        return true;
      }

      return false;
    },
    reject: function (reason) {
      deferred.reject(reason);
    }
  };

  activeRequests.push(newRequest);

  return newRequest.promise;
}

function serializeData(data) {
  if (!data || !Array.isArray(data)) {
    return data;
  }

  const serialized = {};
  let i = data.length;
  while (i--) {
    if (data[i] !== null && typeof data[i] === "object") {
      // eslint-disable-next-line no-loop-func
      $.each(data[i], (prop) => {
        // mvc expects list params to be prefixed with an index
        serialized["[" + i + "]." + prop] = data[i][prop];
      });
    } else {
      // don't look for properties if element is of primitive type
      serialized["[" + i + "]"] = data[i];
    }
  }

  return serialized;
}

// #endregion

// #region public functions

/**
 * Converts an object to a JSON string, with special handling in place to handle arrays for posting to MVC.
 *
 * @param {any} obj - The object to serialize.
 * @param {boolean} [stringify=true] - Determines whether to serialize value to string.
 * @returns The serialized object.
 */
api.serialize = function (obj, stringify = true) {
  const serialized = serializeData(obj);
  if (stringify !== false) {
    return JSON.stringify(serialized);
  }

  return serialized;
};

api.buildUrl = (url, data, options) => {
  if (!url) {
    throw new ClientError('param "url" is required');
  }

  let qs;
  options = Object.assign({}, navigateDefaults, options);

  if (options.resolveToAbsolute && parseURL.isAbsolutePath(url)) {
    switch (options.host) {
      case api.domainHosts.cdn:
        url = parseURL.getStaticDomainRoot() + url;
        break;
      default:
        url = domainRoot + url;
        break;
    }
  }

  if (data) {
    if (typeof data === "string") {
      qs = parseURL.parseQueryString(data);
    } else if (Object.keys(data).length > 0) {
      qs = serializeData(
        dataUtils.cleanse(data, {
          toString: true,
          ignoreEmpty: options.ignoreEmptyData
        })
      );
    }
  }

  // append in with existing query string values
  const parsedUrl = parseURL.parseUrl(url);
  qs = Object.assign({}, parsedUrl.query, qs);

  // if autoSearch option is supplied
  if (options.autoSearch) {
    qs[api.AutoSearchTokenName] = true;
  }

  // add pageMode if it exists and is not normal (default)
  if (options.pageMode && !(api.PageModeTokenName in qs) && options.pageMode !== api.pageModes.normal) {
    qs[api.PageModeTokenName] = options.pageMode;
  }

  if (
    options.keepAppState &&
    !(sessionManager.appStateTokenName in qs) &&
    !options.externalLink &&
    sessionManager.getAppStateID()
  ) {
    // app state ID may not exist if using an HMAC session
    qs[sessionManager.appStateTokenName] = sessionManager.getAppStateID();
  }

  urlQueryParmsConstants.PERSISTEDQUERYPARAMS.filter((item) => item !== api.AppStateTokenName).forEach((token) => {
    if (token in currentUrl.query) {
      qs[token] = currentUrl.query[token];
    }
  });

  // append the current cross pcn if the window has one
  if (options.includeCrossPcn && !(enterpriseManager.crossPcnTokenName in qs)) {
    if (enterpriseManager.getCrossPcn()) {
      qs[enterpriseManager.crossPcnTokenName] = enterpriseManager.getCrossPcn();
    }
  }

  // get base url and append new query string
  url = parsedUrl.url;
  if (Object.keys(qs).length > 0) {
    url += "?" + $.param(qs);
  }

  return url;
};

let hooks = {
  navigate(baseUrl, data, options) {
    options = Object.assign({}, navigateDefaults, options);

    const url = !data && options?.externalLink ? baseUrl : api.buildUrl(baseUrl, data, options);

    if (options.newWindow) {
      if (options.externalLink) {
        window.open(url.indexOf("://") === -1 ? "http://" + url : url);
      } else {
        window.open(url);
      }
    } else {
      if (options.showOverlay) {
        $(document).block({ delay: 750 });
      }

      if (options.replaceHistory) {
        window.location.replace(url);
      } else {
        window.location = url;
      }

      pageState.updateForRedirect();
    }
  },
  loadHtml(targetElementOrId, url, data, options) {
    let $target;
    if (typeof targetElementOrId === "object") {
      $target = $(targetElementOrId);
    } else {
      $target = $("#" + targetElementOrId);
    }
    const mergedOptions = $.extend({ method: "get" }, options);

    // dispose controller if found on element or child elements
    $target
      .find(":data(controller)")
      .addBack()
      .each(function () {
        // eslint-disable-next-line no-invalid-this
        const ctrl = $(this).data("controller");
        if (ctrl && "dispose" in ctrl) {
          ctrl.dispose();
        }
      });

    // if already has blocker don't add another
    if ($target.closest(".plex-blocked").length < 1) {
      $target.block();
    }

    url = api.buildUrl(url, null);

    data = dataUtils.cleanse(data, { toString: true });
    data = mergedOptions.method.toLowerCase() === "get" ? serializeData(data) : api.serialize(data);

    pageHandler.reset();
    return $.ajax({
      url,
      data,
      method: mergedOptions.method,
      dataType: "html"
    }).then((html) => {
      ko.cleanNode($target[0]);
      $target.html(html).unblock();

      return pageHandler.init();
    });
  },
  back(cancelling) {
    const dialogStack = plexImport("dialogStack");
    if (dialogStack && dialogStack.length !== 0) {
      const dialogContr = dialogStack[dialogStack.length - 1];
      if (dialogContr) {
        dialogContr.close();
        return;
      }
    }

    if (!cancelling) {
      banner.setPreviousBanner();
    }

    if (history.length === 1) {
      // go to dashboard if no history exists
      // unless the window has a parent
      let url = "/";
      if (env.parentWindowId) {
        url = document.referrer;
      }

      api.navigate(url);
      return;
    }

    $(document).block();
    history.back();
  },
  post(url, data, options) {
    const mergedOptions = Object.assign({}, navigateDefaults, options);
    mergedOptions.method = mergedOptions.method || "post";

    return executePost(url, data, mergedOptions);
  }
};

api.applyNavigationOverrides = (hook) => {
  const priorHook = hooks;
  hooks = Object.assign({}, priorHook, {
    navigate: hook.navigate && ((...args) => hook.navigate(...args, priorHook.navigate)),
    back: hook.back && ((...args) => hook.back(...args, priorHook.back)),
    loadHtml: hook.loadHtml && ((...args) => hook.loadHtml(...args)),
    post: hook.post && ((...args) => hook.post(...args))
  });
};

api.navigate = (url, data, options) => {
  /// <summary>Navigates to the provided url.</summary>
  /// <param name="url">The url to navigate to.</param>
  /// <param name="data">Data to be appended to the url. (optional)</param>
  /// <param name="options">
  /// Navigation options. Options include
  ///   - `showOverlay` (default true),
  ///   - `keepAppState` (default true),
  ///   - `ignoreEmptyData` (default true),
  ///   - `newWindow` (default false)
  /// </param>

  hooks.navigate(url, data, options);
};

api.navigateToVisionPlex = function (screenKey, params, options) {
  /// <summary>Navigates to the provided VisionPlex screen key.</summary>
  /// <param name="url">The screen key to navigate to.</param>
  /// <param name="data">Data to be appended to the url. (optional)</param>
  /// <param name="options">
  /// Navigation options. Options include `showOverlay` (default true),
  /// `keepAppState` (default true), `ignoreEmptyData` (default true), `newWindow` (default false)</param>

  api.navigate("/Rendering/Screen?__screenKey=" + screenKey, params, options);
};

api.navigateToVisionPlexAdd = function (screenKey, params, options) {
  if (!params) {
    params = {};
  }

  params.PageMode = "Add";

  api.navigateToVisionPlex(screenKey, params, options);
};

api.navigateToVisionPlexUpdate = function (screenKey, params, options) {
  if (!params) {
    params = {};
  }

  params.PageMode = "Update";

  api.navigateToVisionPlex(screenKey, params, options);
};

api.post = (url, data, options) => {
  return hooks.post(url, data, options);
};

api.enqueuePost = function (url, data, keySelector, options) {
  url = url.replace("/?", "?").toLowerCase();

  if (url.slice(-1) === "/") {
    url = url.slice(0, -1);
  }

  return enqueue(url, data, keySelector, options);
};

api.loadHtml = function (targetElement, url, data, options) {
  /// <summary>Loads a page fragment into the target element using the provided url.</summary>
  /// <param name="targetElement">The target element. The content of this element will be replaced.</param>
  /// <param name="url">The url to load the content from.</param>
  /// <param name="data">The data parameters to serialize and pass to the url.</param>
  /// <param name="options">
  /// An options object to apply against the provided object.
  /// - method: The HTTP method for the request. (default get)
  /// </param>
  /// <returns type="Promise">Returns a promise which will be resolved once the request is completed.</returns>
  return hooks.loadHtml(targetElement, url, data, options);
};

api.goBack = (cancelling) => {
  /// <summary>Method which invokes a browser back</summary>
  hooks.back(cancelling);
};

api.featureComingSoon = function () {
  notify.error("This feature is coming soon!");
};

// #endregion

Object.keys(api).forEach((name) => {
  plexExport(name, api[name]);
});
