const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const graphics = plexImport("graphics");

const GraphicFactory = {
  create: function (config, controller, paper, data) {
    if (!(config.type in graphics)) {
      throw new Error("Graphic not found: " + config.type);
    }

    const graphic = new graphics[config.type]();
    graphic.init(config, controller, paper, data);

    return graphic;
  }
};

module.exports = GraphicFactory;
plexExport("graphics.GraphicFactory", GraphicFactory);
