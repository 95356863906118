ï»¿const ko = require("knockout");

function canFlatten(value) {
  return Array.isArray(value) && (value.length === 0 || typeof value[0] !== "object");
}

ko.extenders.flatten = function (target) {
  const wrappedComputed = ko.deferredComputed(() => {
    const value = target();
    if (canFlatten(value)) {
      return value.join(",");
    }

    return value;
  });

  const originalDispose = target.dispose;
  target.dispose = function () {
    wrappedComputed.dispose();
    if (originalDispose) {
      originalDispose.call(target);
    }
  };

  return wrappedComputed;
};
