ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const GridController = require("../Controls/plex-controller-grid");
const onReady = require("../Controls/plex-handler-page").onReady;
const expressions = require("../Expressions/plex-expressions-compiler");
const plexExport = require("../../global-export");

const api = {};

const Droppable = function (config, controller, el) {
  this.config = config;
  this.controller = controller;
  this.$element = $(el);
};

Droppable.prototype = {
  constructor: Droppable,

  init: function () {
    const self = this;
    if (self.config.droppableItem === "self") {
      self.$droppables = self.$element;
    } else {
      self.$droppables = self.$element.find(self.config.droppableItem + ":not(.ui-droppable)");
    }

    self.$droppables.droppable({
      hoverClass: "ui-state-hover",
      tolerance: "pointer",
      over: function (event) {
        self.$element.trigger("dropOver.plex.drag-and-drop", {
          $dropTarget: $(event.target)
        });
      },
      out: function (event) {
        self.$element.trigger("dropOut.plex.drag-and-drop", {
          $dropTarget: $(event.target)
        });
      },
      drop: function (event, _ui) {
        const resultCallback = function (success) {
          api.dropSource.$dragging.draggable("option", "revert", !success);
        };

        if (api.dropSource) {
          self.$element.trigger("dropped.plex.drag-and-drop", {
            setDropResult: resultCallback,
            operation: api.dropSource.config.operation,
            $dropSource: api.dropSource.$element,
            dropData: api.dropSource.data,
            $dropTarget: $(event.target),
            mappedData: self._mapData(api.dropSource.controller, api.dropSource.data)
          });
        }
      }
    });
  },

  _mapData: function (controller, data) {
    const self = this;
    const mappedData = [];
    const sourceData = Array.isArray(data) ? data : [data];

    const dropSourceConfigs = self.config.dropSourceCollection.filter((d) => {
      return d.sourceId === controller.config.id;
    });

    if (dropSourceConfigs.length > 0) {
      sourceData.forEach((record) => {
        const mappedRecord = {};
        dropSourceConfigs[0].modelMappingCollection.forEach((mapping) => {
          mappedRecord[mapping.targetProperty] = expressions.compile(mapping.targetValue)(record);
        });

        mappedData.push(mappedRecord);
      });
    }

    return mappedData;
  },

  destroy: function () {
    const self = this;

    if (self.$droppables) {
      self.$element.find(".ui-droppable").each(function () {
        if ($(this).data("ui-droppable")) {
          $(this).droppable("destroy");
        }
      });

      if (self.$element.hasClass("ui-droppable") && self.$element.data("ui-droppable")) {
        self.$element.droppable("destroy");
      }
    }
  },

  reset: function () {
    this.destroy();
    this.init();
  },

  subscribeToDomChanges: function () {
    const self = this;
    if (self.controller && self.controller instanceof GridController) {
      self.$element.on("rendered.plex.grid renderCancelled.plex.grid", () => {
        self.reset();
      });
    }
  }
};

// drop source is set during dragging
api.dropSource = null;
api.Droppable = Droppable;

api.setupDroppable = function (config, controller, el) {
  const droppable = new Droppable(config, controller, el);
  onReady(() => {
    droppable.init();
    droppable.subscribeToDomChanges();
  });
};

module.exports = api;
plexExport("droppables", api);
