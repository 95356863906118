ï»¿const $ = require("jquery");
const ko = require("knockout");
const actionHandler = require("../Controls/plex-handler-action");
const jsUtils = require("../Utilities/plex-utils-js");
const Repository = require("../Core/plex-repository");
const plexExport = require("../../global-export");

const actionResults = new Repository();
plexExport("actionResults", actionResults);

const Action = function () {
  // constructor
};

Action.prototype = {
  constructor: Action,

  init: function (action, parentAction) {
    this.config = action;
    this.parentAction = parentAction;

    if (action) {
      actionResults.remove(action.id);
    }

    // just copy all the properties onto `this`
    $.extend(this, action);
    if (typeof this.onInit === "function") {
      this.onInit();
    }
  },

  execute: function (data, rawData, e) {
    if (this.parent && ko.unwrap(this.parent.isBusy)) {
      return this.parent.isBusy.then(() => this.execute(data, rawData, e));
    }

    if (!this.isValid()) {
      return new $.Deferred().reject();
    }

    const executionResult = this.onExecute.apply(this, arguments);
    if (jsUtils.isPromise(executionResult)) {
      return executionResult.then((result) => {
        this.$$result = result;
        actionResults.add(this.id, result);

        if (this.postAction) {
          this.executePostAction(result || data, rawData, e);
        }

        return result || data;
      });
    } else if (executionResult) {
      actionResults.add(this.id, data);
      if (this.postAction) {
        this.executePostAction(data, rawData, e);
      }
    }

    return executionResult;
  },

  isValid: function () {
    if (this.validatable && this.parent && this.parent.validator && !this.parent.validator.validateAll()) {
      return false;
    }

    return true;
  },

  onExecute: function (_data, _rawData, _e) {
    // this should be overridden by inheritors
  },

  executePostAction: function (data, _rawData, e) {
    if (this.postAction) {
      actionHandler.executeAction(this.postAction, data, e, this);
    }
  },

  validateResponse: function (response) {
    if (this.parent && this.parent.validator) {
      const validationResult = response.ValidationResult || response;
      return this.parent.validator.validateResults(validationResult);
    }

    return true;
  }
};

jsUtils.makeExtendable(Action);
module.exports = Action;
plexExport("actions.Action", Action);
