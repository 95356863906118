/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const strings = require("../Utilities/plex-utils-strings");
const regex = require("../Utilities/plex-utils-regex");
const http = require("../Core/plex-navigate");
const menuState = require("./Menu/plex-menu-state");
const urlBuilder = require("../Utilities/plex-utils-url-builder");
const plexImport = require("../../global-import");

const spaOnUrlPrefix = "/-/";

function SearchMethod(name, options) {
  options = $.extend({}, options);

  this.name = name;
  this.title = options.title || strings.capitalize(name);
  this.route = options.route || strings.format("/Platform/Search/Get{0}Matches", name);
  this.cache = [];
  this.closeSearchMenu = ko.observable(false);
}

function appendPrefixForMenuUrls(item, prefixUrl) {
  if (typeof item === "object" && item?.Url) {
    const address = item.Url;
    item.Url = urlBuilder.prefixUrl(address, prefixUrl);
  }
}

SearchMethod.prototype = {
  constructor: SearchMethod,

  // this method's logic should be kept in sync with Cloud.UI.Platform\Search\UnifiedSearch\Models\SearchNodeComparer.cs
  search: function (query) {
    this.closeSearchMenu(false);
    const key = query.toLowerCase();

    const cachedResponse = this.cache
      .filter((item) => strings.startsWith(key, item.key))
      // sort the longest to the top, which will be the best match
      .sort((a, b) => b.key.length - a.key.length)[0];

    if (cachedResponse) {
      if (cachedResponse.key !== key) {
        const matcher = new RegExp(regex.escapeText(key), "i");

        const flatten = function (node) {
          const nodes = [];

          while (node != null) {
            nodes.push(node);
            node = node.ParentNode;
          }

          return nodes.reverse();
        };

        const compareResultRow = function (x, y) {
          const [xText, yText] = [x.Text.toLowerCase(), y.Text.toLowerCase()];
          const [xTextEqualKey, yTextEqualKey] = [xText === key, yText === key];

          if (xTextEqualKey && !yTextEqualKey) {
            return -1;
          } else if (!xTextEqualKey && yTextEqualKey) {
            return 1;
          } else {
            const [xTextStartWithKey, yTextStartWithKey] = [
              strings.startsWith(xText, key),
              strings.startsWith(yText, key)
            ];

            if (xTextStartWithKey && !yTextStartWithKey) {
              return -1;
            } else if (!xTextStartWithKey && yTextStartWithKey) {
              return 1;
            } else {
              if (xTextStartWithKey && yTextStartWithKey && xText.length !== yText.length) {
                if (key.length + 1 === xText.length) {
                  return -1;
                } else if (key.length + 1 === yText.length) {
                  return 1;
                }
              }

              const [flatX, flatY] = [flatten(x), flatten(y)];

              for (let i = 0; i < Math.max(flatX.length, flatY.length); i++) {
                if (i === flatX.length) {
                  return -1;
                }

                if (i === flatY.length) {
                  return 1;
                }

                const [valueA, valueB] = [flatX[i].Text.toLowerCase(), flatY[i].Text.toLowerCase()];
                if (valueA > valueB) {
                  return 1;
                } else if (valueA < valueB) {
                  return -1;
                }
              }
            }
          }

          return 0;
        };

        return cachedResponse.results.then((items) =>
          items.filter((item) => matcher.test(item.Text)).sort(compareResultRow)
        );
      }

      return cachedResponse.results;
    }

    const response = http.post(this.route, { query }, { showOverlay: false }).then((res) => {
      const result = res.Data || [];
      if (result.length > 0) {
        const spa = plexImport("spa");
        result.forEach((item) => {
          if (spa?.isSpaRendering?.()) {
            appendPrefixForMenuUrls(item, spaOnUrlPrefix);
          }
        });
      }
      return result;
    });

    // todo: limit the size of the cache
    this.cache.push({ key, results: response });
    return response;
  },

  navigate: function (data) {
    if (data.NavigationMenuNodeKey) {
      menuState.setMenuState(menuState.stateKeys.mainMenu, data.NavigationMenuNodeKey);
    }

    http.navigate(data.Url);
    this.close();
  },

  close: function () {
    this.cache = [];
    this.closeSearchMenu(true);
  }
};

module.exports = SearchMethod;
