ï»¿/* global plex */
// TODO: should use imports instead of plex global
const $ = require("jquery");
const ko = require("knockout");
const actionHandler = require("./plex-handler-action");
const dataUtils = require("../Utilities/plex-utils-data");
const domUtils = require("../Utilities/plex-utils-dom");
const Compiler = require("../Expressions/plex-expressions-compiler");
const PageHandler = require("./plex-handler-page");
const plexBanner = require("../Plugins/plex-banner");
const nav = require("../Core/plex-navigate");
const dataToken = require("../Utilities/plex-utils-datatoken");
const Controller = require("./plex-controller-base");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const FormController = require("../Controls/plex-controller-form");
const GridController = require("../Controls/plex-controller-grid");
const pubsub = require("../Core/plex-pubsub");

let wizardId;
let historyPush = false;

function pushHistoryState(index) {
  if (history.pushState) {
    historyPush = true;
    history.pushState({ wizardActionIndex: index }, "");
  }
}

function setWizardButtonStates(controller) {
  const index = controller.wizardActions().indexOf(controller.activePage());
  const wizardConfig = controller.config;

  if (index === controller.firstActivePageIndex() || index === -1) {
    // TODO: Move the opacity to a better place.
    controller.elements.PreviousWizardPage.disabled(true);
    $("#PreviousWizardPage").css("opacity", "0.6");
  } else {
    controller.elements.PreviousWizardPage.disabled(false);
    $("#PreviousWizardPage").css("opacity", "1");
  }

  if (index === controller.lastAuthorizedPageIndex() || index === -1) {
    controller.elements.NextWizardPage.disabled(true);
    $("#NextWizardPage").css("opacity", "0.6");
  } else {
    controller.elements.NextWizardPage.disabled(false);
    $("#NextWizardPage").css("opacity", "1");
  }

  let isDisabledFinish = false;
  if (wizardConfig.finishAction) {
    const disabled = ko.unwrap(wizardConfig.finishAction.disabled);
    if (disabled) {
      isDisabledFinish = disabled;
    } else if (controller.activePage && controller.activePage()) {
      const currentPage = controller.activePage();
      if (currentPage.disableFinishAction) {
        isDisabledFinish = currentPage.disableFinishAction;
      } else if (currentPage.disableFinishEvaluator) {
        isDisabledFinish = currentPage.disableFinishEvaluator(controller.data);
      }
    }
  } else {
    isDisabledFinish = true;
  }

  if (index === -1 || isDisabledFinish) {
    controller.elements.FinishWizard.disabled(true);
    $("#FinishWizard").css("opacity", "0.6");
  } else if (isDisabledFinish === false) {
    controller.elements.FinishWizard.disabled(false);
    $("#FinishWizard").css("opacity", "1");
  }
}

function initializeWizardActions(wizard) {
  // setup up array of actions.
  wizard.wizardActions = ko.observableArray();

  wizard.config.wizardPages.forEach((wizardPage) => {
    initializeWizardAction(wizardPage, wizard);

    wizard.wizardActions.push(wizardPage);
  });

  wizard.updateButtonStates = ko.computed(() => {
    setWizardButtonStates(wizard);
  });
}

function initializeWizardAction(wizardPage, wizard) {
  if (wizardPage.enableExpression) {
    wizardPage.enableEvaluator = Compiler.compile(wizardPage.enableExpression);
  }

  if (wizardPage.disableFinishExpression) {
    wizardPage.disableFinishEvaluator = Compiler.compile(wizardPage.disableFinishExpression);
  }

  wizardPage.isEnabled = ko.computed(() => {
    wizard.dummy();

    if (wizardPage.enableEvaluator) {
      if (!wizardPage.enableEvaluator(wizard.data)) {
        return false;
      }
    }

    if (!wizardPage.authorized) {
      return false;
    }

    return true;
  });
}

const WizardController = Controller.extend({
  onInit: function () {
    const self = this;

    function loadPage(page) {
      if (self.activePageController && "dispose" in self.activePageController) {
        self.activePageController.dispose();
      }

      setWizardButtonStates(self);

      let tokenizedData = null;
      const isPost = page.httpMethod === "POST";
      if (self.data && page.tokens && page.tokens.length > 0) {
        tokenizedData = dataToken.applyTokens(self.data, page.tokens);
      }

      tokenizedData = tokenizedData && dataUtils.cleanse(tokenizedData);
      const url = nav.buildUrl(page.address, isPost ? {} : tokenizedData);

      self.$wizard.block();
      pubsub.publish("wizardContentLoadingStarted");

      $.ajax({
        url,
        data: isPost && JSON.stringify(tokenizedData || dataUtils.cleanse(self.data)),
        dataType: "html",
        type: page.httpMethod
      }).done((responseText) => {
        PageHandler.reset();

        const target = self.$wizardPagePanel;
        target.empty();
        domUtils.injectHtml(responseText, target).done(() => {
          self.$wizard.unblock();
          pubsub.publish("wizardContentLoadingCompleted");
        });
      });
    }

    self.$wizard = self.$element.parents(".plex-wizard");
    wizardId = self.$element.attr("id");
    self.$wizardPagePanel = self.$element.find(".plex-wizard-page-container");
    self.activePage = ko.observable();
    self.activePageController = null;
    self.activeElementController = null;
    self.validator = null;

    self.activePage.subscribe(loadPage);

    self.actionbarController = null;

    self.wizardPageActionGroup = null;

    // HACK: Temporary solution to help EPAM get by until a more permanent solution is in place.
    self.dummy = ko.observable();

    this.prepareData();

    this.initElement(this.config);
    self.bindFormActions();

    initializeWizardActions(self);

    ko.applyBindings(self, self.$element[0]);
    this._handlePersistentBanner();

    if (history.state && history.state.wizardActionIndex) {
      const selectedPage = self.wizardActions()[history.state.wizardActionIndex];
      if (selectedPage.isEnabled()) {
        self.activePage(selectedPage);
      }
    }

    if (!self.activePage()) {
      const firstPageIndex = self.firstActivePageIndex();
      if (firstPageIndex !== null) {
        self.activePage(self.wizardActions()[firstPageIndex]);
      }

      if (history.replaceState) {
        history.replaceState({ wizardActionIndex: firstPageIndex }, "");
      }
    }

    $(window).on("popstate.wizard", (event) => {
      if (event.originalEvent.state && historyPush) {
        const index = event.originalEvent.state.wizardActionIndex;

        if (typeof index !== "undefined" && self.wizardActions()[index].isEnabled() === true) {
          self.activePage(self.wizardActions()[index]);
        }
      }
    });

    setWizardButtonStates(self);

    if (!self.activePage()) {
      self.$wizard.find(".plex-banner").banner();
      const banner = plexBanner.getPageBanner();
      if (banner) {
        banner.setMessage(self.config.noPermissionsText, false);
      }
    }
  },

  bindFormActions: function () {
    const self = this;
    let $buttons;

    if (self.config.formActions && self.config.formActions.length > 0) {
      $buttons = this.$wizard.find(".plex-form-buttons");
      if ($buttons.length === 0) {
        throw new Error("Unable to find the button section to bind the actions to.");
      }

      self.config.formActions.forEach((button) => {
        self.initElement(button);
      });

      ko.applyBindings(self, $buttons[0]);
    }
  },

  navigatePage: function (data) {
    const self = this;

    if (data.isEnabled() === true) {
      const navigateToPage = function () {
        self.activePage(data);
        pushHistoryState(self.wizardActions().indexOf(self.activePage()));
      };

      self._executeActivePagePostAction(false).done(() => {
        navigateToPage();
      });
    }
  },

  firstActivePageIndex: function () {
    const self = this;
    let firstEnabledActionIndex = 0;

    for (firstEnabledActionIndex; firstEnabledActionIndex < self.wizardActions().length; firstEnabledActionIndex++) {
      if (self.wizardActions()[firstEnabledActionIndex].isEnabled()) {
        return firstEnabledActionIndex;
      }
    }

    return null;
  },

  lastAuthorizedPageIndex: function () {
    const self = this;
    let lastEnabledActionIndex = self.wizardActions().length - 1;

    for (lastEnabledActionIndex; lastEnabledActionIndex >= 0; lastEnabledActionIndex--) {
      if (self.wizardActions()[lastEnabledActionIndex].authorized) {
        return lastEnabledActionIndex;
      }
    }

    return null;
  },

  nextPage: function () {
    const self = this;
    if (self.activePage()) {
      const getNextEnabledPageIndex = function () {
        self.dummy.notifySubscribers();

        let nextEnabledActionIndex = self.wizardActions().indexOf(self.activePage()) + 1;
        for (nextEnabledActionIndex; nextEnabledActionIndex < self.wizardActions().length; nextEnabledActionIndex++) {
          if (self.wizardActions()[nextEnabledActionIndex].isEnabled()) {
            return nextEnabledActionIndex;
          }
        }

        return null;
      };

      const navigateToNextPage = function () {
        const index = getNextEnabledPageIndex();
        if (index >= 0) {
          self.activePage(self.wizardActions()[index]);
          pushHistoryState(index);
        }
      };

      self._executeActivePagePostAction(true).done(() => {
        navigateToNextPage();
      });
    }
  },

  previousPage: function () {
    const self = this;
    if (self.activePage()) {
      const getPreviousEnabledPageIndex = function () {
        self.dummy.notifySubscribers();

        let previousEnabledActionIndex = self.wizardActions().indexOf(self.activePage()) - 1;
        // eslint-disable-next-line for-direction
        for (
          previousEnabledActionIndex;
          previousEnabledActionIndex < self.wizardActions().length;
          previousEnabledActionIndex--
        ) {
          if (self.wizardActions()[previousEnabledActionIndex].isEnabled()) {
            return previousEnabledActionIndex;
          }
        }

        return null;
      };

      const navigateToPreviousPage = function () {
        const index = getPreviousEnabledPageIndex();
        if (index >= 0) {
          self.activePage(self.wizardActions()[index]);
          pushHistoryState(index);
        }
      };

      self._executeActivePagePostAction(false).done(() => {
        navigateToPreviousPage();
      });
    }
  },

  finish: function () {
    const self = this;

    const finishAction = function () {
      if (self.config.finishAction) {
        actionHandler.initAction(self.config.finishAction, self);

        actionHandler.executeAction(self.config.finishAction);
      }
    };

    self._executeActivePagePostAction(false).done(() => {
      finishAction();
    });
  },

  cancel: function () {
    $(window).off("popstate.wizard");

    $(window).on("popstate.wizard", () => {
      // Keep going back until we get out of the wizard.
      history.back();
    });

    history.back();
  },

  setPageController: function (element, config) {
    const self = this;

    // Init any new banners
    self.$wizard.find(".plex-banner").banner();
    const banner = plexBanner.getPageBanner();
    if (banner && config.banner) {
      banner.setMessage(config.banner.htmlMessage, !config.banner.error);
    }

    if (self.actionbarController) {
      if (self.wizardPageActionGroup && self.wizardPageActionGroup.actions().length > 0) {
        self.actionbarController.$actionbar.clearMoreMenu();
        self.actionbarController.actionList.removeGroup(self.wizardPageActionGroup);
        self.wizardPageActionGroup = null;
      }

      if (!self.wizardPageActionGroup && self.actionbarController) {
        self.wizardPageActionGroup = self.actionbarController.actionList.addGroup();
      }
    }

    self.activePageController = PageHandler.init(config);

    if (self.actionbarController) {
      if (config.actions && config.actions.length > 0) {
        config.actions.forEach((action) => {
          self.wizardPageActionGroup.addAction(action, self.actionbarController);
        });
      }

      self.actionbarController.$actionbar.resize(true);
    }

    self.activeElementController = plexImport("currentPage")[config.elements[0].id];

    if (self.activeElementController && self.activeElementController.validator) {
      self.validator = self.activeElementController.validator;
    }

    const navigationPostAction = self.activePage() ? self.activePage().navigationPostAction : null;
    if (navigationPostAction) {
      navigationPostAction.parent = self.activeElementController;
      actionHandler.initAction(navigationPostAction, self.activeElementController);

      navigationPostAction.executeAction = function () {
        return actionHandler.executeAction(navigationPostAction, self.activeElementController.data);
      };
    }
  },

  _handlePersistentBanner: function () {
    // todo: this should likely be handled within the banner code or the framework itself
    const bannerEnvElement = this.$wizard.prevAll(".plex-env-persistent-banner-container:first");
    const bannerElement = this.$wizard.prevAll(".plex-persistent-banner-container:first");
    const bannerControl = bannerElement.data("banner");
    const $wizard = this.$wizard;

    if (!bannerControl) {
      return;
    }

    const originalTop = $wizard.position().top;
    const bannerEnvElementHeight = typeof bannerEnvElement.height() === "undefined" ? 0 : bannerEnvElement.height();
    bannerControl.messages.subscribeAndCall(() => {
      $wizard.css("top", originalTop + bannerEnvElementHeight + bannerElement.height() + "px");
    });
  },

  _isAuthorizedAction: function (action) {
    if (action) {
      if (action.authorized === false) {
        return false;
      }

      if (action.authorizableApplicationLinks && action.authorizableApplicationLinks.length > 0) {
        const hasUnauthorizedLink = action.authorizableApplicationLinks.some((link) => {
          return link.authorized === false;
        });
        if (hasUnauthorizedLink) {
          return false;
        }
      }

      if (action.postAction) {
        return this._isAuthorizedAction(action.postAction);
      }
    }

    return true;
  },

  _executeActivePagePostAction: function (validate) {
    const controller = this.activeElementController;
    const navigationPostAction = this.activePage() ? this.activePage().navigationPostAction : null;

    const executeAction = function () {
      if (validate) {
        if (!controller.validate || controller.validate()) {
          return navigationPostAction.executeAction();
        }

        return new $.Deferred().reject();
      } else {
        return navigationPostAction.executeAction();
      }
    };

    if (controller && navigationPostAction) {
      const authorizedAction = this._isAuthorizedAction(navigationPostAction);

      if (authorizedAction) {
        return executeAction();
      } else if (this._isDirty(controller, true)) {
        return plex.Dialogs.Confirm(
          "You don't have security rights to edit this page. Your changes will not be saved. Proceed?"
        );
      }
    }

    return new $.Deferred().resolve();
  },

  _isDirty: function (controller, lookupChildElements) {
    if (controller instanceof FormController) {
      return this._isFromDirty(controller, lookupChildElements);
    } else if (controller instanceof GridController) {
      return this._isGridDirty(controller);
    }

    return undefined;
  },

  _isFromDirty: function (formController, lookupChildElements) {
    if (formController.data && "$$dirtyFlag" in formController.data && formController.data.$$dirtyFlag.isDirty()) {
      return true;
    }

    if (lookupChildElements && formController.elements) {
      for (const element in formController.elements) {
        if (Object.prototype.hasOwnProperty.call(formController.elements, element)) {
          if (this._isDirty(formController.elements[element], false)) {
            return true;
          }
        }
      }
    }

    return false;
  },

  _isGridDirty: function (gridController) {
    if (gridController && "results" in gridController) {
      return gridController.results().some((record) => {
        return "$$dirtyFlag" in record && record.$$dirtyFlag.isDirty();
      });
    }

    return false;
  }
});

WizardController.create = function () {
  return new WizardController();
};

WizardController.setPageController = function (element, config) {
  plexImport("currentPage")[wizardId].setPageController(element, config);
};

module.exports = WizardController;
plexExport("WizardController", WizardController);
