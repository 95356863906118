ï»¿const ko = require("knockout");
const PrintWriter = require("./plex-grid-print-writer-base");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const gridUtils = require("../../plex-grid-utils");
const ElementRowCellProcessor = require("../plex-grid-row-element-cell-processor");
const plexExport = require("../../../../global-export");

function createAggregateContext(sectionContext, column) {
  let data = column.valueProvider.getRecords(sectionContext.data);
  if ("bucketIndex" in column) {
    // we could safely run map this against any kind of column but to reduce overhead
    // we're only doing this when we know we need this, which is with pivot columns
    data = data.map(column.valueProvider.getRecord, column.valueProvider);
  }

  return {
    id: sectionContext.id,
    section: sectionContext.section,
    group: sectionContext.group,
    data
  };
}

const ElementRowPrintWriter = PrintWriter.extend({
  init: function (grid, group, section) {
    this.group = group;
    this.section = section;
    this.hasGroup = this.group !== grid.options;

    this._base.apply(this, arguments);

    if (section.features && section.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(section.features, section, this.parent);
    }

    const elementRowCellProcessor = new ElementRowCellProcessor(grid, this.parent);
    this.cells = elementRowCellProcessor.process(this.section.cells);
  },

  getNodeName: function () {
    return this.section.type === "footer" && this.hasGroup ? "grid-group-footer-row" : "grid-table-row";
  },

  prePrint: function (_record, _index, _data) {
    if (this.section.clientVisible === false) {
      return false;
    }

    return this._base.apply(this, arguments);
  },

  print: function (data) {
    const self = this;
    const groupData = gridUtils.aggregateData(data, false);
    let i = 0;
    let colspan = 0;
    const sectionId = getSectionId(data, this.section);
    const sectionContext = {
      id: sectionId,
      section: this.section,
      group: data,
      data: groupData
    };

    if (this.hasGroup && this.section.printBreak) {
      this.node.addAttribute(this.section.printBreak.attribute, this.section.printBreak.condition);
    }

    this.grid.columns.forEach((column, colIndex) => {
      const cell = self.cells[i];
      const element = cell.element || (cell.elements && cell.elements[0]);
      let visible = column.printVisible();
      let features, aggregateContext;

      if (visible) {
        colspan++;
      }

      if (i < self.cells.length && (cell.colIndex === colIndex || cell.endColIndex === colIndex)) {
        // if an end index exists wait until the end column is reached until we render
        if (cell.endColIndex > 0 && cell.endColIndex !== colIndex) {
          return;
        }

        // if the current column is not visible but a colspan exists that indicates that one of the columns
        // is visible across the columns this cell spans so it should still render
        visible = visible || colspan > 0;
        if (visible) {
          const cellNode = self.node.createNode("grid-table-cell");

          features = getFeatures(cell, colspan);

          self.writeCss(cellNode, features.css);
          self.writeAttributes(cellNode, features.attr);
          self.writeStyle(cellNode, features.style);

          if (cell.operator && groupData.length > 0) {
            cellNode.appendXml(cell.operator.getFormattedValue(groupData, self.section, data));
          } else if (element && groupData.length > 0) {
            aggregateContext = createAggregateContext(sectionContext, column);
            cellNode.appendXml(
              cell.valueProvider.getPrintValue(aggregateContext.data[0], aggregateContext, colIndex, features) || ""
            );
          }
        }

        colspan = 0;
        i++;
      }
    });

    return self.toPrintXml();
  }
});

function getFeatures(cell, colspan) {
  let features = cell.featureProcessor ? ko.unwrap(cell.featureProcessor.apply(cell)) : {};
  features = features || {};

  if (cell.operator && cell.operator.css) {
    features.css = cell.operator.css;
  }

  if (cell.alignment) {
    features.css = features.css || [];
    features.css.push("plex-grid-cell-align-" + cell.alignment);
  }

  if (colspan > 1) {
    features.attr = features.attr || {};
    features.attr.colspan = colspan;
  }

  return features;
}

function getSectionId(group, section) {
  let suffix = section.type + "." + section.index;
  if (!Array.isArray(group)) {
    // sub total
    suffix += "." + group.index;
  }

  return suffix;
}

module.exports = ElementRowPrintWriter;
plexExport("grid.writers.ElementRowPrintWriter", ElementRowPrintWriter);
