ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const raphael = require("raphael");
const ControllerFactory = require("./plex-controller-factory");
const plexExport = require("../../global-export");

const makeDraggable = function (_options) {
  const self = this;

  self.onDrag = [];
  const shape = self.raphaelObject;

  shape.drag(
    (dx, dy) => {
      const bb = shape.getBBox();
      const tx = shape.oX - bb.x + dx;
      const ty = shape.oY - bb.y + dy;

      shape.translate(tx, ty);

      $.each(self.onDrag, (_key, ondrag) => {
        ondrag();
      });
    },
    () => {
      const bb = shape.getBBox();
      shape.oX = bb.x;
      shape.oY = bb.y;
    }
  );

  return self;
};

const fill = function (options) {
  this.raphaelObject.attr("fill", options.fill || "#FFFFFF");

  return this;
};

const strokeWidth = function (options) {
  this.raphaelObject.attr("stroke-width", options.strokeWidth || 1);

  return this;
};

const getBoundingBox = function (options) {
  const bb = this.raphaelObject.getBBox();

  options = options || {};

  const padding = options.padding || 0;

  return {
    x: bb.x - padding,
    y: bb.y - padding,
    x2: bb.x2 + padding,
    y2: bb.y2 + padding,
    width: bb.x2 - bb.x + 2 * padding,
    height: bb.y2 - bb.y + 2 * padding
  };
};

const behind = function (obj) {
  this.raphaelObject.insertBefore(obj.raphaelObject);

  return this;
};

const between = function (object1, object2) {
  const path = this.raphaelObject;

  const updatePath = function () {
    const from = object1.getBoundingBox();
    const to = object2.getBoundingBox();

    path.attr({
      path: ["M", from.x + from.width / 2, from.y + from.height / 2, "L", to.x + to.width / 2, to.y + to.height / 2]
    });
  };

  updatePath();

  object1.onDrag.push(updatePath);
  object2.onDrag.push(updatePath);

  return this;
};

//
// Rectangle
//
const Rectangle = function (obj) {
  this.raphaelObject = obj;
};

Rectangle.prototype = {
  makeDraggable,
  getBoundingBox,
  fill,
  behind
};

//
// Text
//
const Text = function (obj) {
  this.raphaelObject = obj;
};

Text.prototype = {
  getBoundingBox,
  behind
};

//
// Line
//
const Line = function (obj) {
  this.raphaelObject = obj;
};

Line.prototype = {
  behind,
  between,
  strokeWidth
};

//
// Set
//
const Set = function (obj) {
  this.raphaelObject = obj;
  const children = [];

  this.push = function (child) {
    obj.push(child.raphaelObject);
    children.push(child);
  };
};

Set.prototype = {
  makeDraggable,
  getBoundingBox
};

//
// Graph
//
const Graph = function (id, width, height) {
  const paper = raphael(id, width, height);

  this.addRectangle = function (options) {
    const rect = paper.rect(
      options.x || 20,
      options.y || 20,
      options.width || 100,
      options.height || 40,
      options.radius
    );

    return new Rectangle(rect);
  };

  this.addText = function (options) {
    return new Text(paper.text(options.x || 20, options.y || 20, options.text || "text"));
  };

  this.addLine = function (_options) {
    return new Line(paper.path());
  };

  this.createSet = function (children) {
    const newSet = new Set(paper.set());

    $.each(children, (_key, child) => {
      newSet.push(child);
    });

    return newSet;
  };
};

//
// Graph Controller
//

const GraphController = function (config, model) {
  this.config = config;
  this.model = model;
  this.graph = null;
  this.init();
};

GraphController.prototype = {
  constructor: GraphController,
  init: function () {
    const self = this;

    self.graph = new Graph(self.config.id, self.config.width, self.config.height);
  }
};

GraphController.create = function (config, model) {
  return new GraphController(config, model);
};

ControllerFactory.register("Elements/_Graph", GraphController);

module.exports = GraphController;
plexExport("GraphController", GraphController);
