ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const plexExport = require("../../global-export");

const PasswordInput = function (config) {
  this.config = config;
  this.setup();
};

PasswordInput.prototype = {
  setup: function () {
    if (this.config.canToggle) {
      this._addToggleElement();
    }
  },

  toggle: function () {
    const $input = this._getInput();
    const $div = $input.parent().find("div.toggle-element");

    if (this.config.isVisible) {
      $input.attr("type", "text");
      $div.removeClass("plex-icon-eye5").addClass("plex-icon-eye4");
    } else {
      $input.attr("type", "password");
      $div.removeClass("plex-icon-eye4").addClass("plex-icon-eye5");
    }
  },

  _getInput: function () {
    return $(`#${this.config.id}`);
  },

  _addToggleElement: function () {
    this.config.isVisible = false;
    this._getInput().addClass("toggle-password");
    this._initToggleElement();
    this.toggle();
  },

  _initToggleElement: function () {
    const self = this;
    const $input = this._getInput().after("<div class='toggle-element plex-icon-eye5' ></div>");
    const $div = $input.parent().find("div.toggle-element");

    $div.on("click", () => {
      self._onClickToggle();
    });

    $div.css({ top: $input.outerHeight() / 2 - $div.outerHeight() / 2, left: $input.outerWidth() });
  },

  _onClickToggle: function () {
    this.config.isVisible = !this.config.isVisible;
    this.toggle();
  }
};

$.fn.passwordInput = function () {
  return new PasswordInput(this.get(0));
};

module.exports = PasswordInput;
plexExport("passwordInput", PasswordInput);
