const ko = require("knockout");
const cellWriterFactory = require("./plex-grid-writer-factory");
const htmlUtils = require("../../Utilities/plex-utils-html");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const Writer = require("./plex-grid-writer-base");
const plexExport = require("../../../global-export");

const RowWriter = Writer.extend({
  init: function (grid, layout) {
    this._base.apply(this, arguments);
    this.config = layout.config;

    this.rowFeatures = layout.features;

    // backwards compatibility
    this.columns = ko.unwrap(layout.columns || grid.options.columns);

    if (this.rowFeatures && this.rowFeatures.length > 0) {
      this.featureProcessor = new FeatureProcessor(this.rowFeatures, this.config, grid);
    }

    this.onReset();
  },

  prerender: function (record, index, data, rowConfig) {
    const results = this._base.apply(this, arguments);
    if (results === false) {
      return false;
    }

    if (rowConfig.selectable) {
      if (!rowConfig.disabled && record.$$selectable !== false) {
        rowConfig.css.push("selectable");
      }

      if (rowConfig.selected) {
        rowConfig.css.push("plex-grid-row-selected");
      }
    }

    if (ko.isObservable(results)) {
      rowConfig.features = results();
    }

    return true;
  },

  render: function (writer, record, index, data, rowConfig, groupIndex, group) {
    const empty = rowConfig.empty;

    const tr = writer.appendChild("tr");

    if (!rowConfig.empty) {
      tr.addAttr("data-index", index);
    }

    if (group && group.config && group.config.printBreak) {
      if (
        (group.config.printBreak.attribute === "break-before" &&
          (!group.config.headers || group.config.headers.length === 0) &&
          groupIndex === 0) ||
        (group.config.printBreak.attribute === "break-after" &&
          (!group.config.footers || group.config.footers.length === 0) &&
          group.data &&
          groupIndex === group.data.length - 1)
      ) {
        tr.addAttr("data-print-break", JSON.stringify(group.config.printBreak));
      }
    }

    if (group) {
      if ("$$collapsed" in record && record.$$collapsed) {
        this.features.css.push("plex-grid-row-collapsed");
      }
    }

    this.writeCss(tr, this.features.css.concat(rowConfig.css));
    this.writeAttributes(tr, this.features.attr);
    this.writeStyle(tr, this.features.style);

    if (this.config.ranking) {
      this._renderDragCell(tr);
    }

    if (rowConfig.checkable) {
      if (rowConfig.rowIndex === 0) {
        this._renderSelectionCell(tr, record);
      } else {
        tr.appendChild("td").appendHtml("&nbsp;").close();
      }
    }

    this.columns.forEach((col, colIndex) => {
      let currentRecord;
      if (!col.visible()) {
        if (!empty) {
          // this is required for VP to account for dependencies on hidden columns
          currentRecord = col.valueProvider.getRecord(record);
          col.valueProvider.process(currentRecord, index, colIndex);
        }

        return;
      }

      if (empty || col.empty) {
        const td = tr.appendChild("td").appendHtml("&nbsp;");
        if (col.colspan > 1) {
          td.addAttr("colspan", col.colspan);
        }

        td.close();
      } else {
        currentRecord = col.valueProvider.getRecord(record);
        if (col.writer.prerender(currentRecord, index, data, rowConfig, groupIndex, group, colIndex) !== false) {
          col.writer.render(tr, currentRecord, index, data, colIndex, groupIndex, group);
        }
      }
    });

    if ("$$rendered" in record) {
      record.$$rendered(true);
    }

    tr.close();
  },

  _renderSelectionCell: function (writer, record) {
    const td = writer.appendChild("td").addAttr("class", "plex-grid-selection-cell");

    if (this.features.disabled) {
      record.$$disabled(true);
    }

    if (record.$$selectable === false) {
      td.appendHtml("&nbsp;");
    } else {
      const args = {
        data: record,
        options: { propertyName: "$$selected", disabled: record.$$disabled, visible: true }
      };
      td.appendHtml(htmlUtils.getMemoizedTemplate("elements-checkbox", args, null, this.grid.$element[0]));
    }

    td.close();
  },

  _renderDragCell: function (writer) {
    writer
      .appendChild("td")
      .addAttr("class", "plex-grid-drag-cell")
      .appendChild("div")
      .addAttr("class", "plex-grid-drag-element")
      .close()
      .close();
  },

  onReset: function () {
    const self = this;
    this.columns.forEach((column) => {
      initializeWriter(column, self.grid);
    });
  }
});

function initializeWriter(column, grid) {
  // account for master column layouts, which will have a `column` property
  if (column.column) {
    initializeWriter(column.column, grid);
    return;
  }

  // always Re-Init writer to allow features to subscribe to new grid observables
  column.writer = cellWriterFactory.create(column.writerProvider, grid, grid.options, column);

  // look for default column on master column
  if (column.defaultColumn) {
    initializeWriter(column.defaultColumn, grid);
  }

  // add writers for all child columns
  if (column.columns && column.columns.length > 0) {
    column.columns.forEach((col) => {
      initializeWriter(col, grid);
    });
  }
}

module.exports = RowWriter;
plexExport("grid.writers.RowWriter", RowWriter);
