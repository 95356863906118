ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.block = {
  update: function (element, valueAccessor) {
    let value = ko.unwrap(valueAccessor());
    let fullScreen = false;

    if (value && typeof value === "object") {
      fullScreen = ko.unwrap(value.fullScreen);
      value = ko.unwrap(value.value);
    }

    $(fullScreen ? document : element)[value ? "block" : "unblock"]();
  }
};
