ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

const primitiveTypes = { number: true, string: true, boolean: true };

function shallowComparer(a, b) {
  // treat null & undefined as same
  const aIsNull = a == null;
  const bIsNull = b == null;

  if (aIsNull && bIsNull) {
    return true;
  }

  if (aIsNull || bIsNull) {
    return false;
  }

  if (typeof a in primitiveTypes) {
    return a === b;
  }

  // treat equal dates as equal
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  // this is the default behavior that knockout uses for objects
  // since an property within the object may have changed knockout
  // treats replacing an object as different, even if that object
  // is the same. This posting gives a little bit of insight:
  // http://stackoverflow.com/questions/12714761/why-does-knockout-jss-default-equality-comparer-treat-non-primitive-types-as-no
  return false;
}

ko.extenders.equality = function (target, options) {
  if (typeof options === "function" || options == null) {
    target.equalityComparer = options;
  } else {
    switch (options) {
      case "deep":
        target.equalityComparer = function (a, b) {
          return dataUtils.compareObjects(a, b, false);
        };
        break;

      case "shallow":
        target.equalityComparer = shallowComparer;
        break;

      case "none":
        target.equalityComparer = null;
        break;

      default:
      // leave default comparer
    }
  }

  return target;
};
