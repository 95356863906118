const data = require("../Utilities/plex-utils-data");
const guid = require("../Utilities/plex-utils-guid");
const parseJson = require("./plex-parsing-json");
const plexExport = require("../../global-export");

const messagePrefix = "__tabq.";
const localIdentifier = guid.create();
const messageIdentifier = messagePrefix + localIdentifier;

const listeners = [];

function storageEventHandler(e) {
  if (!e.newValue || !e.key || e.key.indexOf(messagePrefix) !== 0) {
    return;
  }

  if (e.key.indexOf(messageIdentifier) === 0) {
    // ignore messages that this session initiated
    return;
  }

  const payload = parseJson(e.newValue, false);
  if (payload) {
    listeners.filter((l) => l.channel === payload.channel).forEach((l) => l.handle(payload.args));
  }
}

function addListener(listener) {
  if (listeners.length === 0) {
    window.addEventListener("storage", storageEventHandler, false);
  }

  listeners.push(listener);
}

function removeListener(listener) {
  listeners.remove(listener);
  if (listeners.length === 0) {
    window.removeEventListener("storage", storageEventHandler, false);
  }
}

function serialize(obj) {
  const sanitized = data.cleanse(obj, {
    flatten: false,
    keepEmpty: true,
    serializableOnly: true,
    trimWhitespace: false,
    recursive: true
  });

  return JSON.stringify(sanitized);
}

window.addEventListener("unload", () => localStorage.removeItem(messageIdentifier));

class CrossTabMessenger {
  constructor(channel) {
    this.channel = channel;
    this.callbacks = [];

    addListener(this);
  }

  postMessage(...args) {
    const payload = {
      source: localIdentifier,
      channel: this.channel,
      args
    };

    localStorage.setItem(messageIdentifier, serialize(payload));
  }

  handle(args = []) {
    this.callbacks.forEach((cb) => cb(...args));
  }

  addListener(callback) {
    this.callbacks.push(callback);
  }

  removeListener(callback) {
    const index = this.callbacks.indexOf(callback);
    if (index >= 0) {
      this.callbacks.splice(index, 1);
    }
  }

  dispose() {
    removeListener(this);
  }
}

plexExport("CrossTabMessenger", CrossTabMessenger);
module.exports = CrossTabMessenger;
