ï»¿const LabelPrintController = require("./plex-labelprinting-controller-base");
const plexExport = require("../../../../global-export");

const GenericLabelPrintController = LabelPrintController.extend({
  controllerAction: "/Labels/Print",
  getRequestData: function (dataModel, labelConfigModel) {
    // _base is a reference to getRequestData on LabelPrintController
    const data = this._base(dataModel, labelConfigModel);
    data.LabelFormat = {
      LabelFormatKey: labelConfigModel.labelFormatKey,
      ApplicationPath: labelConfigModel.labelApplicationFilePath
    };

    return data;
  }
});

module.exports = GenericLabelPrintController;
plexExport("barcoding.labelPrinting.GenericLabelPrintController", GenericLabelPrintController);
