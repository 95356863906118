ï»¿const ko = require("knockout");

function peeker(target) {
  const value = target.peek();
  if (value && Array.isArray(value)) {
    return value.slice(0);
  }

  return value;
}

ko.extenders.commitEdit = function (target, triggers) {
  const previousValue = ko.observable(peeker(target));
  const cachedValue = ko.observable(peeker(target));

  const commitTriggerSubscription = triggers.commitTrigger.subscribe(() => {
    previousValue(cachedValue());
    cachedValue(peeker(target));
  });

  const rollbackTriggerSubscription =
    triggers.rollbackTrigger &&
    triggers.rollbackTrigger.subscribe(() => {
      cachedValue(previousValue());
    });

  const wrappedValue = ko.pureComputed({
    read: cachedValue,
    write: ko.isWritableObservable(target) ? target : undefined
  });

  const originalDispose = wrappedValue.dispose;
  wrappedValue.dispose = function () {
    if (originalDispose) {
      originalDispose.call(wrappedValue);
    }

    commitTriggerSubscription.dispose();
    rollbackTriggerSubscription && rollbackTriggerSubscription.dispose();
  };

  wrappedValue._actual = target;
  return wrappedValue;
};
