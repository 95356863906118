ï»¿const globalExport = require("../../global-export");

const isoRgx = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(?::[0-5]\d(?:\.\d+)?)?(?:Z|[-+][0-2]\d:[0-5]\d)?$/;
const MSDateFormat = /\/Date\((-?\d+)(-\d*)?\)\//;

globalExport("parsing.ISODateFormat", isoRgx);
globalExport("parsing.MSDateFormat", MSDateFormat);

function parseISODate(value) {
  if (!value) {
    return null;
  }

  return new Date(value);
}

function defaultParser(value) {
  return value;
}

function getParsingFn(value) {
  if (value && typeof value === "string") {
    if (isoRgx.test(value) && !isNaN(Date.parse(value))) {
      return parseISODate;
    }
  }

  return defaultParser;
}

function defaultReviver(key, value) {
  return getParsingFn(value)(value);
}

function createCachingReviver() {
  const parsingFns = {};
  return function (key, value) {
    if (!value) {
      return value;
    }

    if (key in parsingFns) {
      return parsingFns[key](value);
    }

    const fn = (parsingFns[key] = getParsingFn(value));
    return fn(value);
  };
}

function parseJSON(text, cacheParsers) {
  const reviver = cacheParsers === false ? defaultReviver : createCachingReviver();
  return JSON.parse(text, reviver);
}

module.exports = parseJSON;
globalExport("parsing.parseJSON", parseJSON);
