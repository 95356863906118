ï»¿const Serializer = require("./plex-exporter-serializers");
const plexExport = require("../../global-export");

const escapeRequiredRgx = /[",\r\n]/;
const utfPrefix = "\ufeff";

const CsvExporter = Serializer.extend({
  mimeType: "text/csv",
  extension: ".csv",
  altExtension: ".txt",
  recordDelimiter: "\n",
  delimiter: ",",

  serializeBegin: function (data) {
    const self = this;

    if (self.parent.exportHeader === false) {
      return utfPrefix;
    }

    const record = this.transformRecord(data[0]);
    const row = [];

    record.forEach((col) => {
      row.push(self.escapeValue(col.header));
    });

    return utfPrefix + row.join(this.delimiter) + this.recordDelimiter;
  },

  serializeRecord: function (record) {
    const self = this;
    const row = [];

    record.forEach((col) => {
      row.push(self.escapeValue(col.value));
    });

    return row.join(this.delimiter);
  },

  escapeValue: function (value) {
    if (value == null) {
      return "";
    }

    if (typeof value === "string") {
      return escapeString(value);
    }

    return String(value);
  }
});

function escapeString(value) {
  if (escapeRequiredRgx.test(value)) {
    // add double quotes for quoted text
    return '"' + value.replace(/"/g, '""') + '"';
  }

  if (value.charAt(0) === "0" && !isNaN(parseFloat(value)) && isFinite(value)) {
    // escape strings of just a number started with 0 (e.g. "0008175")
    // problem is described in https://jira-plex.atlassian.net/browse/UX-450
    return '"' + value + '"';
  }

  if (value.indexOf(".") !== -1 && !isNaN(parseFloat(value)) && isFinite(value)) {
    // escape numeric strings with dot (e.g. "147.00")
    return '"' + value + '"';
  }

  return value;
}

module.exports = CsvExporter;
plexExport("exporter.serializers.csv", CsvExporter);
