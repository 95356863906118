/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const jsUtils = require("./plex-utils-js");
const koUtils = require("../Knockout/plex-utils-knockout");
const IdleTaskRunner = require("./plex-utils-idle-task-runner");
const HtmlWriter = require("./plex-utils-html-writer");
const htmlUtils = require("./plex-utils-html");
const browser = require("../Core/plex-browser");
const plexExport = require("../../global-export");
const env = require("../Core/plex-env");

const GRID_SEARCH_FEATURE_FLAG = "fix-tri-4244-grid-ctrlf-search-enhancements";

const defaultOptions = {
  initialSize: 30,
  bufferSize: 100,
  placeholderTag: "tr",
  threshold: 10,
  count: 0
};

const DEFAULT_ROW_SIZE = 30;

const slice = Array.prototype.slice;

class WindowContainer {
  getBounds() {
    return {
      top: 0,
      bottom: window.innerHeight
    };
  }
}

class ElementContainer {
  constructor(element) {
    this.container = element;
  }

  getBounds() {
    const { top, bottom } = this.container.getBoundingClientRect();
    return { top, bottom };
  }
}

function VirtualHtmlWriter(target, options) {
  HtmlWriter.call(this, target);
  this.options = $.extend({}, defaultOptions, options);

  this.$scrollParent = this.$target.scrollParent();
  this.inScrollableContainer = this.$scrollParent[0] !== document;

  if (this.inScrollableContainer) {
    // this will be the case for dialogs/forms, which have their own scrollable containers
    this.$container = this.$scrollParent;

    this.$resizeParent = this.$target.closest(".ui-resizable");
    if (this.$resizeParent.length === 0) {
      this.$resizeParent = this.$container;
    }

    this.window = new ElementContainer(this.$scrollParent[0]);
  } else {
    // this will be the case for standard full-page filter/grid
    this.$container = this.$resizeParent = $(window);
    this.window = new WindowContainer();
  }

  this.batchCallback = this.options.batchCallback || $.noop;
  this.renderer = this.options.renderer;
  this.taskRunner = new IdleTaskRunner();
  this.htmlCache = [];
  this.sizeCache = [];
  this.virtual = true;
}

VirtualHtmlWriter.prototype = Object.create(HtmlWriter.prototype);
VirtualHtmlWriter.prototype.constructor = VirtualHtmlWriter;

VirtualHtmlWriter.prototype.getCurrentRange = function () {
  const scrollTop = this.$scrollParent.scrollTop();
  const top = -this.$target[0].getBoundingClientRect().top + this.window.getBounds().top;

  const start = this.getProjectedIndex(top, 0);
  const end = this.getProjectedIndex(this.bounds.height, start);

  return {
    start,
    end,
    scrollPosition: scrollTop
  };
};

VirtualHtmlWriter.prototype.handleScroll = function () {
  this.scrollInvalidated = true;

  if (this.resetScheduled || !this.inViewPort()) {
    return;
  }

  const current = this.getCurrentRange();
  if (this._lastRange && this._lastRange.scrollPosition === current.scrollPosition) {
    return;
  }

  this._lastRange = current;

  let shouldReset = false;
  let dir = 1;

  // check to see whether the estimated visible is within the defined thresholds
  if (this.batch.end < this.options.count && current.end + this.options.threshold > this.batch.end) {
    shouldReset = true;
  } else if (this.batch.start > 0 && current.start - this.options.threshold < this.batch.start) {
    dir = -1;
    shouldReset = true;
  }

  if (shouldReset) {
    this.resetScheduled = true;
    this.reset();

    this.taskRunner.enqueue(() => this.seedCache(dir === -1 ? this.batch.start : this.batch.end, dir));
  }
};

VirtualHtmlWriter.prototype.reset = jsUtils.throttleRender(function (callback) {
  if (!document.body.contains(this.$target[0])) {
    // Bail out since container is no longer in DOM - this
    // could happen due to timing due to throttling. (Can
    // also occur if scroll event is not properly unsubscribed.)
    return $.when();
  }

  const current = this.getCurrentRange();
  this.scrollInvalidated = false;
  if (this._lastRange && current.scrollPosition !== this._lastRange.scrollPosition) {
    // wait until user is done scrolling
    // todo: might want to force the update if skipped often enough
    this._lastRange = current;

    // the reset method is throttled already, so we should be able to call it "immediately"
    // though we may end up wanting to add a timeout if this becomes a bottleneck
    return this.reset(callback);
  }

  this.resetScheduled = false;
  return this.renderBatch(current).then(callback || $.noop);
});

VirtualHtmlWriter.prototype.resize = function () {
  const priorRowHeight = this.rowHeight;

  this.bounds.height = this.$container.innerHeight();

  // Row height will be reset when the rows are measured based on the current batch
  this.rowHeight = null;
  this.measureRows();

  if (priorRowHeight !== this.rowHeight) {
    this.resizePlaceholders();
  }

  const currentRange = this.getCurrentRange();
  if (currentRange.start < this.batch.start || currentRange.end > this.batch.end) {
    // we only need to reset if the bounds have grown
    this._lastRange = null;
    this.reset();
  }
};

VirtualHtmlWriter.prototype.appendToDOM = function (node) {
  // create a copy of the nodes we are about to insert for unmemoization
  const nodes = slice.call(node.childNodes);
  this.$target[0].appendChild(node);
  return htmlUtils.unmemoize(nodes, 1000);
};

VirtualHtmlWriter.prototype.clear = function () {
  // the call to clean jquery data is expensive and we shouldn't need it
  // so temporarily removing it before cleaning node
  const originalCleanExternal = ko.utils.domNodeDisposal.cleanExternalData;
  ko.utils.domNodeDisposal.cleanExternalData = $.noop;
  ko.cleanNode(this.$target[0]);
  ko.utils.domNodeDisposal.cleanExternalData = originalCleanExternal;

  this.$target.empty();
};

VirtualHtmlWriter.prototype.render = function () {
  const recordCount = this.options.count;
  let batchSize = Math.min(recordCount, this.options.initialSize);

  // Matches prior behavior when table isn't visible at render time.
  // This could be optimized to defer virtualization until grid becomes
  // visible.
  if (this.$target.is(":hidden")) {
    batchSize = recordCount;
  }

  let index = 0;
  while (this.nodes.length < batchSize && index < recordCount) {
    this.renderer(this, index++);
  }

  const nodes = this.nodes.splice(0, this.nodes.length);

  // go ahead and render initial batch so that we can estimate the row height
  const fragment = document.createDocumentFragment();
  $(fragment).append(nodes.join(""));
  this.appendToDOM(fragment);

  if (index >= recordCount) {
    // no virtualization required
    return;
  }

  // todo: this has been converted to a sync call to address issues found in ATL-7127
  // however this will impact the user experience and should be evaluated as to a better
  // way to resolve this issue
  // this.setupCallback = jsUtils.defer(() => this.setup(index));
  this.setup(index);
};

VirtualHtmlWriter.prototype.setup = function (nextIndex) {
  this.bounds = {
    height: this.$container.innerHeight()
  };

  const size = this.options.count;

  // need to use native event support to use passive event listening if supported
  const eventOptions = browser.supportsPassiveEvents ? { passive: true } : false;
  this.scrollHandler = jsUtils.debounce(() => this.handleScroll(), 200);
  this.$scrollParent[0].addEventListener("scroll", this.scrollHandler, eventOptions);

  this.$resizeParent.on("resizeCompleted-y.plex.virtual-html-writer", this.resize.bind(this));
  this.$container.on("columnResized.plex.virtual-html-writer", this.resize.bind(this));

  this.sizeCache = new Array(size);

  this.batch = {
    start: 0,
    end: nextIndex
  };

  this.measureRows();
  const batchHeight = this.options.bufferSize * this.rowHeight;
  const top = this.$target.offset().top;
  const visibleHeight = this.bounds.height - top;
  const batchCount = Math.ceil(visibleHeight / batchHeight) + 1;
  const end = Math.min(size, batchCount * this.options.bufferSize);

  const html = this.getHtml(nextIndex, end);
  const $fragment = $(document.createDocumentFragment());
  $fragment.append(html);
  $fragment.append(this.getPlaceholder(this.getProjectedHeight(end)));

  this.batch = {
    start: 0,
    end
  };

  this.appendToDOM($fragment[0]).then(() => {
    this.measureRows();
    this.resizePlaceholders();
  });

  this.taskRunner.enqueue(this.seedCache.bind(this, end));
};

VirtualHtmlWriter.prototype.dispose = function () {
  if (this.scrollHandler) {
    const eventOptions = browser.supportsPassiveEvents ? { passive: true } : false;
    this.$scrollParent[0].removeEventListener("scroll", this.scrollHandler, eventOptions);
    this.scrollHandler = null;
  }

  this.$resizeParent.off("resizeCompleted-y.plex.virtual-html-writer");
  this.$container.off("columnResized.plex.virtual-html-writer");

  if (this.setupCallback) {
    this.setupCallback.cancel();
    this.setupCallback = null;
  }

  this.taskRunner.clear();
  this.htmlCache.length = this.sizeCache.length = 0;
};

VirtualHtmlWriter.prototype.renderBatch = function (range) {
  let { start, end } = range;
  const { count: size, bufferSize } = this.options;

  let batchIndex = Math.floor(start / bufferSize);
  const batchStop = Math.ceil(end / bufferSize);

  start = Math.max(0, batchIndex * bufferSize);
  end = Math.min(size, batchStop * bufferSize + bufferSize);

  if (this.batch.start === start && this.batch.end === end) {
    return $.when();
  }

  this.clear();

  let current, html;
  const $fragment = $(document.createDocumentFragment());
  let next = start;
  let resetStart = true;

  while (batchIndex <= batchStop) {
    current = this.htmlCache[batchIndex];
    if (current) {
      resetStart = true;
      $fragment[0].appendChild(current.node.cloneNode(true));
      if (!current.reusable) {
        this.htmlCache[batchIndex] = null;
      }
    } else {
      html = this.getHtml(next, next + bufferSize, resetStart);
      resetStart = false;

      const reusable = !koUtils.hasMemoization(html);
      if (reusable) {
        const node = document.createDocumentFragment();
        $(node).append(html);
        this.htmlCache[batchIndex] = { node, reusable: true };
        $fragment[0].appendChild(node.cloneNode(true));
      } else {
        $fragment.append(html);
      }
    }

    next += bufferSize;
    batchIndex++;
  }

  if (start > 0) {
    const height = this.getProjectedHeight(0, start);
    $fragment.prepend(this.getPlaceholder(height));
  }

  const nextEnd = Math.min(next, size);
  if (nextEnd < size) {
    const height = this.getProjectedHeight(nextEnd);
    $fragment.append(this.getPlaceholder(height));
  }

  this.batch = {
    start,
    end: nextEnd
  };

  // If there is another pending render, we want to throw away
  // the measurement call, because the UI might be in an inconsistent state
  const renderTimestamp = (this.renderTimestamp = Date.now());

  const promise = this.appendToDOM($fragment[0])
    .then(() => {
      if (renderTimestamp === this.renderTimestamp) {
        this.measureRows();
      }
    })
    .then(this.batchCallback);

  // restore cursor position - this will be lost by the "clear" call
  // we are doing this immediately instead of waiting for the grid to
  // propogate - we are expecting the grid be rendered *enough* to take
  // the expected height - this can be moved into after the grid has
  // been unmemoized if this ends up causing scroll jumping
  if (!this.scrollInvalidated) {
    this.$scrollParent.scrollTop(range.scrollPosition);
  }

  return promise;
};

VirtualHtmlWriter.prototype.ensureRendered = function (rowIndex, callback) {
  if (rowIndex < this.batch.start || rowIndex > this.batch.end) {
    if (env.features[GRID_SEARCH_FEATURE_FLAG] && this._lastRange) {
      const height = this.getProjectedHeight(0, rowIndex);
      const rect = this.$target[0].getBoundingClientRect();
      this.$scrollParent.scrollTop(this._lastRange.scrollPosition + height + rect.top);
    } else {
      const bottom = this.getProjectedHeight(0, rowIndex);
      this.$scrollParent.scrollTop(bottom);
    }
    this.reset(callback);
  } else {
    callback();
  }
};

VirtualHtmlWriter.prototype.getHtml = function (start, end, normalizeStart) {
  let i = start;
  const size = this.options.count;
  const nextEnd = Math.min(size, end);

  if (normalizeStart && start > 0) {
    // need to render prior record to ensure suppress repeating values is in valid state
    this.renderer(this, i - 1);
    this.nodes.pop();
  }

  // get rest of body
  while (i < nextEnd) {
    this.renderer(this, i++);
  }

  return this.nodes.splice(0, this.nodes.length).join("");
};

VirtualHtmlWriter.prototype.inViewPort = function () {
  const { top: containerTop, bottom: containerBottom } = this.window.getBounds();
  const { top: targetTop, bottom: targetBottom } = this.$target[0].getBoundingClientRect();

  return (
    // The element is fully visible in the container
    (targetTop >= containerTop && targetBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (targetTop < containerTop && containerTop < targetBottom) ||
    (targetTop < containerBottom && containerBottom < targetBottom)
  );
};

VirtualHtmlWriter.prototype.getProjectedHeight = function (start, end) {
  let h = 0;
  for (let i = start, l = end ?? this.sizeCache.length; i < l; i++) {
    h += this.sizeCache[i] || this.rowHeight;
  }

  return h;
};

VirtualHtmlWriter.prototype.getProjectedIndex = function (height, start) {
  if (height < 0) {
    return 0;
  }

  const size = this.sizeCache.length;
  let total = 0;
  let i = start;

  while (i < size) {
    total += this.sizeCache[i++] || this.rowHeight;
    if (total > height) {
      return i;
    }
  }

  return size;
};

VirtualHtmlWriter.prototype.measureRows = function () {
  const rows = this.$target.children(":not(.plex-virtual-placeholder)");
  const { start, end } = this.batch;
  const count = end - start;

  if (count <= 0 || rows.length === 0) {
    // sanity check - these conditions shouldn't occur
    return;
  }

  if (rows.length === count) {
    // Ideal scenario - no group headers/footers or extraneous rows - we can store actual values
    rows.each((i, tr) => {
      this.sizeCache[i + start] = tr.offsetHeight;
    });
  } else {
    // We'll have to use an estimate here and apply the average to all rows in range
    let h = 0;
    rows.each((_, tr) => {
      h += tr.offsetHeight;
    });

    const avg = h / count;
    let i = start;
    while (i < end) {
      this.sizeCache[i++] = avg;
    }
  }

  // Row height may not be set if first render or resizing - use sized rows as reference
  if (!this.rowHeight) {
    const sizedRows = this.sizeCache.filter((x) => x > 0);
    if (sizedRows.length > 0) {
      this.rowHeight = sizedRows.reduce((a, b) => a + b) / sizedRows.length;
    } else {
      // If no rows have height, we'll use a default size. This should get reevaluated as
      // the visibility changes.
      this.rowHeight = DEFAULT_ROW_SIZE;
    }
  }

  // Can't use reduce here because array may be sparse - we will fill all with values while
  // we calculate the total height
  let sum = 0;
  for (let i = 0; i < this.sizeCache.length; i++) {
    let h = this.sizeCache[i];
    if (!h) {
      h = this.sizeCache[i] = this.rowHeight;
    }

    sum += h;
  }

  // Recalculate average row height
  this.rowHeight = sum / this.sizeCache.length;
};

VirtualHtmlWriter.prototype.resizePlaceholders = function () {
  if (!this.batch) {
    return;
  }

  const $children = this.$target.children();
  if (this.batch.start > 0) {
    const $top = $children.first();
    const height = this.getProjectedHeight(0, this.batch.start);
    if ($top.hasClass("plex-virtual-placeholder")) {
      $top[0].style.height = height + "px";
    } else {
      this.$target.prepend(this.getPlaceholder(height));
    }
  }

  if (this.batch.end < this.options.count) {
    const $bottom = $children.last();
    const height = this.getProjectedHeight(this.batch.end);
    if ($bottom.hasClass("plex-virtual-placeholder")) {
      $bottom[0].style.height = height + "px";
    } else {
      this.$target.append(this.getPlaceholder(height));
    }
  }
};

VirtualHtmlWriter.prototype.getPlaceholder = function (height) {
  if (!height) {
    return "";
  }

  // adding width so that background takes entire table width - may need to keep this in sync when resizing, though this should only be visible when
  // the user is dragging the scrollbar
  return `<${
    this.options.placeholderTag
  } class='plex-virtual-placeholder' style='height:${height}px;width:${this.$target.width()}px;'></{0}>`;
};

VirtualHtmlWriter.prototype.seedCache = function (seedStart, directionOrEmpty) {
  const { count: size, bufferSize } = this.options;
  let start = seedStart;
  let end;

  const direction = directionOrEmpty || 1;
  if (direction === -1) {
    end = start;
    start = Math.max(0, end - bufferSize);
  } else {
    end = Math.min(size, start + bufferSize);
  }

  if (start < 0 || end > size || start === end) {
    // todo: rollover?
    return;
  }

  const batchIndex = Math.floor(start / this.options.bufferSize);
  if (!this.htmlCache[batchIndex]) {
    const fragment = document.createDocumentFragment();
    const html = this.getHtml(start, end, true);
    const reusable = !koUtils.hasMemoization(html);

    $(fragment).append(html);
    this.htmlCache[batchIndex] = { node: fragment, reusable };
  }

  if (direction === -1 && start > 0) {
    this.taskRunner.enqueue(this.seedCache.bind(this, start, direction));
  } else if (direction === 1 && end <= size) {
    this.taskRunner.enqueue(this.seedCache.bind(this, end, direction));
  }
};

module.exports = VirtualHtmlWriter;
plexExport("VirtualHtmlWriter", VirtualHtmlWriter);
