ï»¿const $ = require("jquery");
const pubsub = require("../Core/plex-pubsub");
const dataUtils = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const Ask = require("../Dialogs/plex-dialogs-ask");
const http = require("../Core/plex-navigate");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");

const DeleteAction = Action.extend({
  onExecute: function (data, rawData) {
    const self = this;

    const $deferred = new $.Deferred();
    // default to assigned model but fallback to parent when publishing delete action
    const parentId = self.modelId || (self.parent && self.parent.config && self.parent.config.id);
    const question = self.confirmationMessage || "Are you sure you want to delete this record?";
    // if the message has been preset, it will already be glossarized
    const options = { autoGlossarize: !self.confirmationMessage };

    /* eslint new-cap: "off" */
    Ask(question, ["Yes", "No"], "No", options)
      .done((answer) => {
        if (answer.answerText !== "Yes") {
          $deferred.reject();
          return;
        }

        if (!self.address) {
          notify.error("A data source has not been specified for the Delete action.");
          $deferred.reject();
          return;
        }

        let criteria;
        if (self.request || self.dataSourceKey) {
          criteria = [];
          (Array.isArray(data) ? data : [data]).forEach((record) => {
            criteria.push(dataUtils.cleanse($.extend({}, self.request, record)));
          });
        } else {
          criteria = dataUtils.cleanse(data, { ignoreEmpty: false });
        }

        http
          .post(self.address, criteria, { standardArrays: true })
          .done((results) => {
            if (!self.validateResponse(results)) {
              $deferred.reject();
              return;
            }

            if (parentId) {
              pubsub.publish("deleted." + parentId, rawData);
            }

            $deferred.resolve();
          })
          .fail($deferred.reject);
      })
      .fail($deferred.reject);

    return $deferred.promise();
  }
});

module.exports = DeleteAction;
plexExport("actions.Delete", DeleteAction);
