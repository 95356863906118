ï»¿const ko = require("knockout");
const $ = require("jquery");
const knockoutUtils = require("./plex-utils-knockout");
const formatUtils = require("../Globalization/plex-formatting");
const mathUtils = require("../Utilities/plex-utils-math");
const culture = require("../Globalization/plex-culture-number");

ko.bindingHandlers.numericValue = {
  init: function (element, valueAccessor, allBindings) {
    const formatter = allBindings.get("formatter");
    const numericFormatter = formatUtils.getFormatter(formatter);
    const data = allBindings.get("data");

    const parseNumericValue = (numberBoxValue) => {
      let currencySymbol;
      if (formatter.currencySymbol) {
        currencySymbol = formatter.currencySymbol.symbolText
          ? formatter.currencySymbol.symbolText
          : numericFormatter.getBoundCurrencySymbol(formatter.currencySymbol, data);
      }

      let unit;
      if (formatter.unit) {
        unit = formatter.unit.unitText ? formatter.unit.unitText : numericFormatter.getBoundUnit(formatter.unit, data);
      }

      if (!unit && !currencySymbol) {
        return numberBoxValue;
      }

      const currencyIndex = numberBoxValue.indexOf(currencySymbol);
      const unitIndex = numberBoxValue.indexOf(unit);
      if (currencyIndex !== -1 && unitIndex !== -1) {
        return numberBoxValue.slice(currencyIndex + currencySymbol.length, unitIndex);
      } else if (currencyIndex !== -1 && unitIndex === -1) {
        return numberBoxValue.slice(currencyIndex + currencySymbol.length);
      } else if (currencyIndex === -1 && unitIndex !== -1) {
        return numberBoxValue.slice(0, unitIndex);
      }

      return numberBoxValue;
    };

    // catch non-numeric characters before they're displayed
    $(element).on("keypress", (e) => {
      // note that which evaluates to 0 for tab in Firefox
      // which is the reason for the keyCode fallback
      const charCode = e.which || e.keyCode;
      if (checkSpecialControlsCharCode(e, charCode)) {
        return true;
      }

      const character = String.fromCharCode(charCode);
      return culture.isValidNumericDigit(character);
    });

    $(element).on("change.plex.numericValue", (e) => {
      const numericValue = parseNumericValue(e.target.value);
      let value = culture.parseNumber(numericValue);

      const accessor = valueAccessor();

      if (!$.isNumeric(value)) {
        value = null;
      }

      // Convert Percentage values
      if (numericFormatter.isPercentFormat(formatter)) {
        value /= 100;
      }

      const currentValue = ko.utils.peekObservable(accessor);
      value = getFormattedValue(element, allBindings, value);

      // Coercive check to handle initial undefined value of the bound property
      // eslint-disable-next-line eqeqeq
      if (currentValue == value) {
        // eslint-disable-line eqeqeq
        // the values are equal but we want to preserve the formatting
        e.target.value = formatUtils.formatValue(value, formatter, data);
      } else if (ko.isObservable(accessor)) {
        // update the model
        accessor(value);
      } else {
        // need to update underlying model manually
        knockoutUtils.updateTwoWayBinding(allBindings, "numericValue", value);
      }

      return true;
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(element).off("change.plex.numericValue");
    });
  },

  update: function (element, valueAccessor, allBindings) {
    const accessor = valueAccessor();
    let value = ko.unwrap(accessor);
    value = getFormattedValue(element, allBindings, value);

    if (!$.isNumeric(value)) {
      $(element).val("");
      updateModelValue(null, accessor, allBindings);
      return;
    }

    updateModelValue(value, accessor, allBindings);
  }
};

function getFormattedValue(element, allBindings, value) {
  const formatConfig = allBindings.get("formatter");
  const data = allBindings.get("data");
  const numericFormatter = formatUtils.getFormatter(formatConfig);
  const scale = numericFormatter.getScale(formatConfig, data, true);

  if (typeof value === "string") {
    value = culture.parseNumber(value);
  }

  if (!$.isNumeric(value)) {
    return value;
  }

  // Update the text box with the formatted value
  const formattedValue = numericFormatter.format(value, formatConfig, data);
  $(element).val(formattedValue);

  // todo: these operations mimic what Globalize does when it formats numeric values,
  // so that the numeric value is in sync with the display value, however this logic
  // does not belong here - it would be more appropriate to parse the formatted value
  // which should give a true representation of the numeric value, however that does
  // not always end up being the case in my testing so more research would be required
  if (formatConfig.truncateToScale) {
    value = mathUtils.truncateDecimal(value, scale);
  }

  if (formatConfig.precision) {
    value = mathUtils.floorOrCeilIntegerPart(value, formatConfig.precision, scale);
  }

  if (numericFormatter.isIntegerFormat(formatConfig)) {
    // Truncate decimals like Globalize
    value = mathUtils.truncateDecimal(value, 0);
  } else if (numericFormatter.isPercentFormat(formatConfig)) {
    value = mathUtils.roundPrecision(value, scale);
  } else {
    value = mathUtils.roundPrecision(value, numericFormatter.getScaleOrDefault({ scale }));
  }

  return value;
}

function updateModelValue(value, accessor, allBindings) {
  if (ko.isObservable(accessor)) {
    // update the model
    accessor(value);
  } else {
    // need to update underlying model manually
    knockoutUtils.updateTwoWayBinding(allBindings, "numericValue", value);
  }
}

function checkSpecialControlsCharCode(event, charCode) {
  // ctrl+a
  if (
    ((charCode === 65 || charCode === 97) && (event.ctrlKey === true || event.metaKey === true)) ||
    // ctrl+c
    ((charCode === 67 || charCode === 99) && (event.ctrlKey === true || event.metaKey === true)) ||
    // ctrl+x
    ((charCode === 88 || charCode === 120) && (event.ctrlKey === true || event.metaKey === true)) ||
    // ctrl+v
    ((charCode === 86 || charCode === 118) && (event.ctrlKey === true || event.metaKey === true)) ||
    // home, end, left, right
    (charCode >= 35 && charCode <= 39) ||
    // backspace, tab, enter
    charCode === 8 ||
    charCode === 9 ||
    charCode === 13 ||
    // delete, insert
    charCode === 46 ||
    charCode === 45
  ) {
    return true;
  }

  return false;
}

ko.expressionRewriting._twoWayBindings.numericValue = true;
