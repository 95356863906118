ï»¿const $ = require("jquery");
const ko = require("knockout");
const logger = require("../Core/plex-logger");
const stringUtils = require("../Utilities/plex-utils-strings");
const Token = require("./plex-tokens-token-base");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

function findValue(obj, prop) {
  if (prop in obj) {
    return obj[prop];
  }

  const propLower = prop.toLowerCase();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && key.toLowerCase() === propLower) {
      return obj[key];
    }
  }

  return null;
}

function evaluatePossiblyDeferred(config, fn) {
  // do not cause a dependency on isLoading unless the element is currently loading
  if (config.controller && ko.utils.peekObservable(config.controller.isLoading)) {
    const deferred = new $.Deferred();
    config.controller.isLoading.subscribeOnce(() => deferred.resolve(fn()));
    return deferred.promise();
  }

  return fn();
}

// todo: this should be exposed somewhere
const GRID_ELEMENT_FORMAT = "{0}_grid_control_{1}";
const ElementToken = Token.extend({
  getValue: function (record, index, _records) {
    const id = this.getAttr("name", arguments);
    const config = this.getElementConfig(record, id, index);

    if (config) {
      return evaluatePossiblyDeferred(config, () => this.getElementValue(config, ["value", "boundValue"]));
    }

    if (!record) {
      // if there is no record and we got this far this is more than likely an empty grid
      return "";
    }

    const domId = this.getElementId(record, index);
    const $el = $(document.getElementById(domId));
    if ($el.length > 0) {
      // todo: this will be a formatted value - it will be better to get the underlying value
      // getting it from the controller is optimal but maybe we can get it from the knockout
      // context as well

      logger.warn("Unable to find the source for element '" + id + "' instead using DOM element: " + domId);
      return $el.is(":input") ? $el.val() : $el.text();
    }

    logger.error("Unable to find element: " + id);
    return "";
  },

  getElementValue: function (config, props) {
    const key = ko.utils.arrayFirst(props, (prop) => prop in config);
    return ko.unwrap(config[key]);
  },

  getElementConfig: function (record, id, index) {
    if (!record) {
      return this.getElementFromPage(id);
    }

    let ctrl, elConf;
    if (index && typeof index === "object" && index.id && "$$sections" in record && index.id in record.$$sections) {
      ctrl = record.$$sections[index.id];

      // try to find within current section (header, footer) of the group
      elConf = this.getElement(ctrl, id);
      if (elConf) {
        return elConf;
      } else {
        // try to find in all sections (header, footer) of the group
        for (const prop in record.$$sections) {
          if (Object.prototype.hasOwnProperty.call(record.$$sections, prop)) {
            elConf = this.getElement(record.$$sections[prop], id);
            if (elConf) {
              return elConf;
            }
          }
        }
      }
    } else if ("$$controller" in record) {
      ctrl = record.$$controller;
      elConf = this.getElement(ctrl, id);
      if (elConf) {
        return elConf;
      }
    }

    return this.getElementFromPage(id);
  },

  getElement: function (ctrl, id) {
    if (ctrl && ctrl.elements) {
      if (id in ctrl.elements) {
        return ctrl.elements[id];
      }

      const propLower = id.toLowerCase();
      for (const prop in ctrl.elements) {
        if (Object.prototype.hasOwnProperty.call(ctrl.elements, prop) && prop.toLowerCase() === propLower) {
          return ctrl.elements[prop];
        }
      }
    }

    return null;
  },

  getElementFromPage: function (id) {
    let section, ctrl, el;
    const currentPage = plexImport("currentPage");
    for (section in currentPage) {
      if (Object.prototype.hasOwnProperty.call(currentPage, section)) {
        ctrl = currentPage[section];
        el = this.getElement(ctrl, id);

        if (el) {
          return el;
        }
      }
    }

    return null;
  },

  getElementId: function (record, index) {
    const id = this.getAttr("name", arguments);
    if (index != null) {
      return stringUtils.format(GRID_ELEMENT_FORMAT, id, index);
    }

    return id;
  }
});

const ElementTextToken = ElementToken.extend({
  getValue: function (record, index) {
    const id = this.getAttr("name", arguments);
    const config = this.getElementConfig(record, id, index);
    let prop, ctrl;

    if (config && "controller" in config) {
      ctrl = config.controller;
      prop = config.displayPropertyName;

      if (prop && "selected" in ctrl) {
        return ctrl
          .selected()
          .map((item) => {
            return item[prop];
          })
          .join(",");
      }

      return this.getElementValue(config, ["displayValue", "boundDisplayValue"]);
    }

    return this._base.apply(this, arguments);
  }
});

const ElementPropertyToken = ElementToken.extend({
  getValue: function (record, index) {
    const id = this.getAttr("name", arguments);
    const prop = this.getAttr("property", arguments);

    if (id && prop) {
      const config = this.getElementConfig(record, id, index);
      if (config) {
        let value = null;
        if ("controller" in config) {
          value = ko.unwrap(findValue(config.controller, prop));
        }

        if (value == null) {
          value = ko.unwrap(findValue(config, prop));
        }

        return value;
      }
    }

    return null;
  }
});

plexExport("tokens.element", ElementToken);
plexExport("tokens.filtervalue", ElementToken);
plexExport("tokens.elementvalue", ElementToken);

plexExport("tokens.elementtext", ElementTextToken);
plexExport("tokens.elementdisplayvalue", ElementTextToken);

plexExport("tokens.elementproperty", ElementPropertyToken);
