/* eslint-disable */
const jQuery = require("jquery");

// this is a monkey-patch improvement of jQuery by queuing async calls within promise resolution
// follow https://github.com/jquery/jquery/pull/3228 for an official resolution

function Identity(v) {
  return v;
}
function Thrower(ex) {
  throw ex;
}

const queue = [];
function enqueue(cb) {
  queue.push(cb);
  if (queue.length === 1) {
    setTimeout(processQueue);
  }
}

function processQueue() {
  const cbs = queue.splice(0, queue.length);
  cbs.forEach((cb) => cb());
}

jQuery.extend({
  Deferred: function (func) {
    var tuples = [
        // action, add listener, callbacks,
        // ... .then handlers, argument index, [final state]
        ["notify", "progress", jQuery.Callbacks("memory"), jQuery.Callbacks("memory"), 2],
        ["resolve", "done", jQuery.Callbacks("once memory"), jQuery.Callbacks("once memory"), 0, "resolved"],
        ["reject", "fail", jQuery.Callbacks("once memory"), jQuery.Callbacks("once memory"), 1, "rejected"]
      ],
      state = "pending",
      promise = {
        state: function () {
          return state;
        },
        always: function () {
          deferred.done(arguments).fail(arguments);
          return this;
        },
        finally: function () {
          deferred.done(arguments).fail(arguments);
          return this;
        },
        catch: function (fn) {
          return promise.then(null, fn);
        },

        // Keep pipe for back-compat
        pipe: function (/* fnDone, fnFail, fnProgress */) {
          let fns = arguments;

          return jQuery
            .Deferred((newDefer) => {
              jQuery.each(tuples, (i, tuple) => {
                // Map tuples (progress, done, fail) to arguments (done, fail, progress)
                const fn = jQuery.isFunction(fns[tuple[4]]) && fns[tuple[4]];

                // deferred.progress(function() { bind to newDefer or newDefer.notify })
                // deferred.done(function() { bind to newDefer or newDefer.resolve })
                // deferred.fail(function() { bind to newDefer or newDefer.reject })
                deferred[tuple[1]](function () {
                  const returned = fn && fn.apply(this, arguments);
                  if (returned && jQuery.isFunction(returned.promise)) {
                    returned.promise().progress(newDefer.notify).done(newDefer.resolve).fail(newDefer.reject);
                  } else {
                    newDefer[tuple[0] + "With"](this, fn ? [returned] : arguments);
                  }
                });
              });
              fns = null;
            })
            .promise();
        },
        then: function (onFulfilled, onRejected, onProgress) {
          let maxDepth = 0;
          function resolve(depth, deferred, handler, special) {
            return function () {
              var that = this,
                args = arguments,
                mightThrow = function () {
                  let returned, then;

                  // Support: Promises/A+ section 2.3.3.3.3
                  // https://promisesaplus.com/#point-59
                  // Ignore double-resolution attempts
                  if (depth < maxDepth) {
                    return;
                  }

                  returned = handler.apply(that, args);

                  // Support: Promises/A+ section 2.3.1
                  // https://promisesaplus.com/#point-48
                  if (returned === deferred.promise()) {
                    throw new TypeError("Thenable self-resolution");
                  }

                  // Support: Promises/A+ sections 2.3.3.1, 3.5
                  // https://promisesaplus.com/#point-54
                  // https://promisesaplus.com/#point-75
                  // Retrieve `then` only once
                  then =
                    returned &&
                    // Support: Promises/A+ section 2.3.4
                    // https://promisesaplus.com/#point-64
                    // Only check objects and functions for thenability
                    (typeof returned === "object" || typeof returned === "function") &&
                    returned.then;

                  // Handle a returned thenable
                  if (jQuery.isFunction(then)) {
                    // Special processors (notify) just wait for resolution
                    if (special) {
                      then.call(
                        returned,
                        resolve(maxDepth, deferred, Identity, special),
                        resolve(maxDepth, deferred, Thrower, special)
                      );

                      // Normal processors (resolve) also hook into progress
                    } else {
                      // ...and disregard older resolution values
                      maxDepth++;

                      then.call(
                        returned,
                        resolve(maxDepth, deferred, Identity, special),
                        resolve(maxDepth, deferred, Thrower, special),
                        resolve(maxDepth, deferred, Identity, deferred.notifyWith)
                      );
                    }

                    // Handle all other returned values
                  } else {
                    // Only substitute handlers pass on context
                    // and multiple values (non-spec behavior)
                    if (handler !== Identity) {
                      that = undefined;
                      args = [returned];
                    }

                    // Process the value(s)
                    // Default process is resolve
                    (special || deferred.resolveWith)(that, args);
                  }
                },
                // Only normal processors (resolve) catch and reject exceptions
                process = special
                  ? mightThrow
                  : function () {
                      try {
                        mightThrow();
                      } catch (e) {
                        if (jQuery.Deferred.exceptionHook) {
                          jQuery.Deferred.exceptionHook(e, process.stackTrace);
                        }

                        // Support: Promises/A+ section 2.3.3.3.4.1
                        // https://promisesaplus.com/#point-61
                        // Ignore post-resolution exceptions
                        if (depth + 1 >= maxDepth) {
                          // Only substitute handlers pass on context
                          // and multiple values (non-spec behavior)
                          if (handler !== Thrower) {
                            that = undefined;
                            args = [e];
                          }

                          deferred.rejectWith(that, args);
                        }
                      }
                    };

              // Support: Promises/A+ section 2.3.3.3.1
              // https://promisesaplus.com/#point-57
              // Re-resolve promises immediately to dodge false rejection from
              // subsequent errors
              if (depth) {
                process();
              } else {
                // Call an optional hook to record the stack, in case of exception
                // since it's otherwise lost when execution goes async
                if (jQuery.Deferred.getStackHook) {
                  process.stackTrace = jQuery.Deferred.getStackHook();
                }
                enqueue(process);
              }
            };
          }

          return jQuery
            .Deferred((newDefer) => {
              // progress_handlers.add( ... )
              tuples[0][3].add(
                resolve(0, newDefer, jQuery.isFunction(onProgress) ? onProgress : Identity, newDefer.notifyWith)
              );

              // fulfilled_handlers.add( ... )
              tuples[1][3].add(resolve(0, newDefer, jQuery.isFunction(onFulfilled) ? onFulfilled : Identity));

              // rejected_handlers.add( ... )
              tuples[2][3].add(resolve(0, newDefer, jQuery.isFunction(onRejected) ? onRejected : Thrower));
            })
            .promise();
        },

        // Get a promise for this deferred
        // If obj is provided, the promise aspect is added to the object
        promise: function (obj) {
          return obj != null ? jQuery.extend(obj, promise) : promise;
        }
      },
      deferred = {};

    // Add list-specific methods
    jQuery.each(tuples, (i, tuple) => {
      const list = tuple[2],
        stateString = tuple[5];

      // promise.progress = list.add
      // promise.done = list.add
      // promise.fail = list.add
      promise[tuple[1]] = list.add;

      // Handle state
      if (stateString) {
        list.add(
          () => {
            // state = "resolved" (i.e., fulfilled)
            // state = "rejected"
            state = stateString;
          },

          // rejected_callbacks.disable
          // fulfilled_callbacks.disable
          tuples[3 - i][2].disable,

          // progress_callbacks.lock
          tuples[0][2].lock
        );
      }

      // progress_handlers.fire
      // fulfilled_handlers.fire
      // rejected_handlers.fire
      list.add(tuple[3].fire);

      // deferred.notify = function() { deferred.notifyWith(...) }
      // deferred.resolve = function() { deferred.resolveWith(...) }
      // deferred.reject = function() { deferred.rejectWith(...) }
      deferred[tuple[0]] = function () {
        deferred[tuple[0] + "With"](this === deferred ? undefined : this, arguments);
        return this;
      };

      // deferred.notifyWith = list.fireWith
      // deferred.resolveWith = list.fireWith
      // deferred.rejectWith = list.fireWith
      deferred[tuple[0] + "With"] = list.fireWith;
    });

    // Make the deferred a promise
    promise.promise(deferred);

    // Call given func if any
    if (func) {
      func.call(deferred, deferred);
    }

    // All done!
    return deferred;
  }
});
