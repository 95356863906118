ï»¿const Action = require("./plex-actions");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");
const domUtils = require("../Utilities/plex-utils-dom");
const DialogController = require("../Dialogs/plex-dialog-controller");
const SidetabsMenuController = require("../Controls/SidetabsMenu/plex-controller-sidetabs-menu");

const MIDDLE_CLICK = 2;

const RedirectAction = Action.extend({
  onExecute: function (data, rawData, event) {
    this.navigate(data, event);

    // prevent href from being used - no post actions can occur
    return false;
  },

  navigate: function (data, event) {
    let newWindow = this.newWindow;
    if (event && event.which === MIDDLE_CLICK) {
      newWindow = true;
    }

    // only pass data for redirects when it has been tokenized
    data = this.tokens && this.tokens.length > 0 ? data : {};

    if (this.parent && domUtils.isInDialog(this.parent.$element)) {
      const topDialogController = DialogController.find(this.parent.$element);
      const stayInDialog = topDialogController && topDialogController.config.forceRedirectsToStayInDialog;

      if (stayInDialog) {
        topDialogController.launchPageInCurrentDialog(null, { address: this.address, data });
        return false;
      }
    }

    nav.navigate(this.address, data, {
      externalLink: this.externalLink,
      newWindow,
      pageMode: this.mode,
      replaceHistory: this.replaceHistory
    });
    return false;
  }
});

RedirectAction.configure = function (action) {
  if (action.parent && !(action.parent instanceof SidetabsMenuController)) {
    const sidetabsMenuController = SidetabsMenuController.find();

    if (
      sidetabsMenuController &&
      action.parent.$element &&
      action.parent.$element.closest(".plex-sidetabs-menu-page").length > 0
    ) {
      const menuItem = sidetabsMenuController.findMenuItem(action.address);

      if (menuItem) {
        if (!action.dataTransforms) {
          action.dataTransforms = [];
        }

        action.dataTransforms.push({
          name: "SidetabsMenuTokenData",
          menuItem
        });
      }
    }
  }
};

module.exports = RedirectAction;
plexExport("actions.Redirect", RedirectAction);
