ï»¿const $ = require("jquery");
const PrintWriter = require("./plex-grid-print-writer-base");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");

const CellPrintWriter = PrintWriter.extend({
  init: function (parent, _options, column) {
    this._base.apply(this, arguments);

    this.column = column;
    this.parent = parent;
    if (column.features && column.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(column.features, column, parent);
    }
  },

  getNodeName: function () {
    return "grid-table-cell";
  },

  prePrint: function (_record, index, _data) {
    const self = this;
    if (self._base.apply(this, arguments)) {
      if (self.column.features && self.column.features.length > 0) {
        self.column.features.forEach((feature) => {
          if (feature.$$resultsCollection && feature.$$resultsCollection[index]) {
            self.features.merge(feature.$$resultsCollection[index]);
          }
        });
      }

      return this.features.render !== false;
    }

    return false;
  },

  print: function (record, index, _data, colIndex, _groupIndex, _group) {
    const self = this;
    let cellXml;

    const css = [].concat(self.features.css);
    if (self.column.css) {
      css.push(self.column.css);
    }

    if (self.column.colspan > 1) {
      self.features.attr.colspan = this.column.colspan;
    }

    self.writeCss(self.node, css);
    self.writeAttributes(self.node, self.features.attr);
    self.writeStyle(self.node, self.features.style);

    if (self.features.content) {
      cellXml =
        self.features.printContent?.length > 1
          ? String(
              self.features.printContent
                .filter((x) => {
                  return x != null;
                })
                .join("\n")
            )
          : String(self.features.content).replace(/<br[^>]*>/gi, "\n");

      // strip out any html
      let text = $("<div/>").html(cellXml).text();

      // replace &nbsp; with its XML equivalent
      text = text.replace(/&nbsp;/gi, "&#160;");

      self.node.setValue(text);
    } else {
      cellXml = self.column.valueProvider.getPrintValue(record, index, colIndex, self.features) || "";
      self.node.appendXml(cellXml.replace(/(<plex-controls>|<\/plex-controls>)/g, ""));
    }

    return self.toPrintXml();
  }
});

module.exports = CellPrintWriter;
plexExport("grid.writers.CellPrintWriter", CellPrintWriter);
