ï»¿const ko = require("knockout");
const knockoutUtils = require("./plex-utils-knockout");

ko.extenders.slice = function (target, options) {
  const begin = options.begin || 0;
  const end = options.end;
  const newArray = ko.observableArray(target.peek().slice(begin, end));
  const observable = ko.computed({ read: newArray });

  target.subscribe(
    (changes) => {
      let endingIndex = end;
      const underlyingArray = target.peek();
      let hasUpdates = false;

      if (endingIndex === undefined) {
        // see if relative size of array has changed
        if (newArray.peek().length - begin === underlyingArray.length - begin) {
          endingIndex = underlyingArray.length;
        } else {
          hasUpdates = true;
        }
      }

      if (!hasUpdates) {
        // account for negative ending index which by definition
        // rolls back into the start of the array
        if (endingIndex < 0) {
          endingIndex += underlyingArray.length + 1;
        }

        hasUpdates = changes.some((change) => {
          return change.index >= begin && change.index < endingIndex;
        });
      }

      if (hasUpdates) {
        newArray(underlyingArray.slice(begin, end));

        // todo: we may want to limit this to just the changes that apply
        // but for our purposes this is probably fine
        observable.notifySubscribers(changes, "arrayChange");
      }
    },
    null,
    "arrayChange"
  );

  // sort the underlying array and trigger notifications so that a new slice is done
  observable.sort = observable.mergeSort = observable.stableSort = target.stableSort.bind(target);
  observable.reverse = target.reverse.bind(target);

  knockoutUtils.addNonMutatingArrayMethods(observable);
  return observable;
};
