const DateRangeFilter = require("./plex-dates-range-filter");
const dateUtils = require("./plex-dates");
const CustomerDate = require("../Globalization/plex-customer-date");
const culture = require("../Globalization/plex-culture-datetime");
const plexExport = require("../../global-export");

const api = {
  CurrentDay: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(date);
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  DaysFromToday: new DateRangeFilter(
    (date, options) => {
      const daysFromToday = options.daysFromToday < 0 ? options.daysFromToday : 0;
      const begin = new date.constructor(dateUtils.addDays(date, daysFromToday));
      return dateUtils.offsetBeginDay(begin);
    },
    (date, options) => {
      const daysFromToday = options.daysFromToday > 0 ? options.daysFromToday : 0;
      const end = new date.constructor(dateUtils.addDays(date, daysFromToday));
      return dateUtils.offsetEndDay(end, options);
    }
  ),

  PreviousDay: new DateRangeFilter(
    (date) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetBeginDay(yesterday);
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  NextDay: new DateRangeFilter(
    (date) => {
      const tomorrow = new date.constructor(dateUtils.addDays(date, 1));
      return dateUtils.offsetBeginDay(tomorrow);
    },
    (date, options) => {
      const tomrrow = new date.constructor(dateUtils.addDays(date, 1));
      return dateUtils.offsetEndDay(tomrrow, options);
    }
  ),

  CurrentWeek: new DateRangeFilter(
    (date, options) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.getFirstDayOfWeek(date, options)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(dateUtils.getLastDayOfWeek(date, options)), options);
    }
  ),

  NextWeek: new DateRangeFilter(
    (date, options) => {
      let workingDate = new date.constructor(dateUtils.getFirstDayOfWeek(date, options));
      workingDate = new date.constructor(dateUtils.addDays(workingDate, 7));
      return dateUtils.offsetBeginDay(workingDate, date.getTimezoneOffset());
    },
    (date, options) => {
      let workingDate = new date.constructor(dateUtils.getLastDayOfWeek(date, options));
      workingDate = new date.constructor(dateUtils.addDays(workingDate, 7));
      return dateUtils.offsetEndDay(workingDate, options);
    }
  ),

  PreviousWeek: new DateRangeFilter(
    (date, options, weeks) => {
      const days = (weeks || 1) * 7;
      let workingDate = new date.constructor(dateUtils.getFirstDayOfWeek(date, options));
      workingDate = new date.constructor(dateUtils.subtractDays(workingDate, days));
      return dateUtils.offsetBeginDay(workingDate, date.getTimezoneOffset());
    },
    (date, options) => {
      let workingDate = new date.constructor(dateUtils.getLastDayOfWeek(date, options));
      workingDate = new date.constructor(dateUtils.subtractDays(workingDate, 7));
      return dateUtils.offsetEndDay(workingDate, options);
    }
  ),

  Previous2Weeks: new DateRangeFilter(
    (date, options) => {
      return api.PreviousWeek.getStartDate(date, options, 2);
    },
    (date, options) => {
      return api.PreviousWeek.getEndDate(date, options);
    }
  ),

  PreviousWeekMonday: new DateRangeFilter(
    (date) => {
      const monday = new date.constructor(dateUtils.offsetBeginDay(date));
      monday.setDate(monday.getDate() - ((monday.getDay() + 6) % 7));
      return dateUtils.subtractDays(monday, 7);
    },
    (date, options) => {
      const sunday = new date.constructor(date);
      sunday.setDate(sunday.getDate() - ((sunday.getDay() + 7) % 7));
      return new date.constructor(dateUtils.offsetEndDay(sunday, options));
    }
  ),

  NextSaturday: new DateRangeFilter(
    (date) => {
      return new date.constructor(dateUtils.getNextSaturday(date));
    },
    (date, options) => {
      const saturday = new date.constructor(dateUtils.getNextSaturday(date));
      return new date.constructor(dateUtils.offsetEndDay(saturday, options));
    }
  ),

  NextSunday: new DateRangeFilter(
    (date) => {
      return new date.constructor(dateUtils.getNextSunday(date));
    },
    (date, options) => {
      const sunday = new date.constructor(dateUtils.getNextSunday(date));
      return new date.constructor(dateUtils.offsetEndDay(sunday, options));
    }
  ),

  ThisWeekend: new DateRangeFilter(
    (date) => {
      return new date.constructor(dateUtils.getNextSaturday(date));
    },
    (date, options) => {
      const sunday = new date.constructor(dateUtils.getNextSunday(date));
      return new date.constructor(dateUtils.offsetEndDay(sunday, options));
    }
  ),

  NextWeekend: new DateRangeFilter(
    (date) => {
      const saturday = new date.constructor(dateUtils.getNextSaturday(date));
      return new date.constructor(dateUtils.addDays(saturday, 7));
    },
    (date, options) => {
      let sunday = new date.constructor(dateUtils.getNextSunday(date));
      sunday = new date.constructor(dateUtils.offsetEndDay(sunday, options));
      return new date.constructor(dateUtils.addDays(sunday, 7));
    }
  ),

  CurrentMonth: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear(), date.getMonth(), 1));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(dateUtils.getLastDayOfMonth(date)), options);
    }
  ),

  PreviousMonth: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear(), date.getMonth() - 1, 1));
    },
    (date, options) => {
      const lastMonth = new date.constructor(date.getFullYear(), date.getMonth() - 1, 1);
      const lastDayOfMonth = new date.constructor(dateUtils.getLastDayOfMonth(lastMonth));
      return dateUtils.offsetEndDay(lastDayOfMonth, options);
    }
  ),

  MonthToDate: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear(), date.getMonth(), 1));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  PreviousYear: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear() - 1, 0, 1));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(date.getFullYear() - 1, 11, 31), options);
    }
  ),

  PreviousSevenDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 7)));
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  LastSevenDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 6)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  PreviousThirtyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 30)));
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  LastThirtyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 29)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  PreviousSixtyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 60)));
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  LastSixtyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 59)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  PreviousNinetyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 90)));
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  LastNinetyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 89)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  Previous365Days: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 365)));
    },
    (date, options) => {
      const yesterday = new date.constructor(dateUtils.subtractDays(date, 1));
      return dateUtils.offsetEndDay(yesterday, options);
    }
  ),

  Last365Days: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(dateUtils.subtractDays(date, 364)));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  YearToDate: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear(), 0, 1));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(date, options);
    }
  ),

  CurrentYear: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(new date.constructor(date.getFullYear(), 0, 1));
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(dateUtils.getLastDayOfYear(date)), options);
    }
  ),

  NextThirtyDays: new DateRangeFilter(
    (date) => {
      return dateUtils.offsetBeginDay(date);
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(dateUtils.addDays(date, 29)), options);
    }
  ),

  MinStartDate: new DateRangeFilter(
    () => {
      return new CustomerDate(culture.MIN_DATE);
    },
    (date, options) => {
      return dateUtils.offsetEndDay(new date.constructor(dateUtils.addDays(date, 0)), options);
    }
  ),

  MaxEndDate: new DateRangeFilter(
    (date) => {
      return date;
    },
    () => {
      return new CustomerDate(culture.MAX_DATE);
    }
  )
};

module.exports = api;
plexExport("dates.DateRange", api);
