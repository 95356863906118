ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.readOnly = {
  update: function (element, valueAccessor) {
    const value = !!ko.unwrap(valueAccessor());
    const $element = $(element);

    $element.prop("readonly", value);
    $element.toggleClass("readonly", value);
  }
};
