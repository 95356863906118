const ko = require("knockout");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const ImageValueProvider = require("./plex-grid-value-providers-image");
const plexExport = require("../../../global-export");

const RouteImageValueProvider = ImageValueProvider.extend({
  getUrlComponents: function (record) {
    let imagePath;

    const path = this.getValue(record);

    if (!path) {
      return null;
    }

    const url = this.column.rootUrl || "";

    imagePath = this.column.filePath || "";
    imagePath += path;
    imagePath += this.column.fileExtension ? "." + this.column.fileExtension : "";

    const data = { imagePath };
    if (this.column.disableCaching) {
      data.requestId = new Date().getTime();
    }

    return {
      url,
      data
    };
  },

  getHtml: function (record) {
    if (this.config.trackClientUpdates) {
      return this.getTrackedHtml.apply(this, arguments);
    }

    const alt =
      (this.column.alternateTextPropertyName && record[this.column.alternateTextPropertyName]) ||
      this.column.alternateText;

    return "<img src='" + this.getUrl.apply(this, arguments) + "' alt='" + (alt || "") + "' />";
  },

  getTrackedHtml: function (record) {
    return ko.renderTemplate("elements-image", { data: record, options: this.column });
  },

  getPrintValue: function (_record) {
    const urlComponents = this.getUrlComponents.apply(this, arguments);

    if (!urlComponents || !urlComponents.data) {
      return "";
    }

    return new DocumentXml("plex-control-image").addAttribute("imagepath", urlComponents.data.imagePath).serialize();
  }
});

module.exports = RouteImageValueProvider;
plexExport("grid.valueProviders.RouteImageValueProvider", RouteImageValueProvider);
