ï»¿const DefaultColumn = require("./plex-grid-column");
const plexExport = require("../../../global-export");

const PivotAggregateColumn = DefaultColumn.extend({
  init: function (column) {
    const self = this;
    this._base.apply(self, arguments);

    if (!column.elements || column.elements.length < 1 || !column.elements[0].aggregate) {
      throw new Error("The aggregate did not exist");
    }

    // var clone = plex.data.clone(column, { exclude: ["writer", "parent", "headerElement", "printWriter"] });
    // clone.valueProvider.address = "BindingValueProvider";
    // clone.valueProvider = valueProviderFactory.create(clone);

    // var op = aggregateFactory.create(clone, column.elements[0].aggregate);
    // column.valueProvider.operation = op;
  }
});

module.exports = PivotAggregateColumn;
plexExport("grid.columns.PivotAggregate", PivotAggregateColumn);
