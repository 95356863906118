ï»¿const $ = require("jquery");
const pubsub = require("../../Core/plex-pubsub");
const defaultHandler = require("./plex-draggables-handler-default");
const draggables = require("../plex-draggables");
const plexExport = require("../../../global-export");

const gridHandler = defaultHandler.extend({
  getHelper: function () {
    const self = this;
    return function (e) {
      self.draggable.data = [];
      const grid = self.controller.$grid;
      const gridData = grid.getData();

      if (self.config.draggableItem === "self") {
        self.draggable.data = gridData;
        return self.$element.clone().addClass("plex-draggable-item-dragging");
      }

      const $helper = $("<table class='plex-grid plex-draggable-item-dragging'><tbody></tbody></table>");
      $helper.width($(e.currentTarget).width());
      if (self.config.selectionMode === draggables.selectionModes.defaultMode) {
        $helper.height($(e.currentTarget).height());
      }

      const index = parseInt($(e.currentTarget).attr("data-index"), 10);
      const record = gridData[index];

      // clone only selected items
      if (self.config.selectionMode === draggables.selectionModes.selected) {
        self.$element
          .find(self.config.draggableItem + ".ui-draggable")
          .clone()
          .appendTo($helper.find("tbody"));

        self.draggable.data = self.controller.selected();
      }

      // clone the deepest group items
      else if (self.config.selectionMode === draggables.selectionModes.group && grid.groups && grid.groups.length > 0) {
        self._buildGroupHelper($helper, record);
      }

      // clone the current item
      else {
        $(e.currentTarget).clone().appendTo($helper.find("tbody"));
        self.draggable.data = [record];
      }

      return $helper;
    };
  },

  getItemSelector: function () {
    switch (this.config.selectionMode) {
      case draggables.selectionModes.selected:
        return this.config.draggableItem + ".plex-grid-row-selected:not(.ui-draggable)";

      default:
        return this.config.draggableItem + ".plex-grid-row:not(.ui-draggable)";
    }
  },

  subscribeToDomChanges: function (fnCreateDraggables) {
    const self = this;
    self._createDraggables(fnCreateDraggables);

    self.$element.on("rendered.plex.grid renderCancelled.plex.grid", () => {
      self.destroyCurrentDraggables();
      self._createDraggables(fnCreateDraggables);
    });

    if (self.config.selectionMode === draggables.selectionModes.selected) {
      pubsub.subscribe(
        "selected.grid." + self.$element[0].id,
        () => {
          self.destroyCurrentDraggables();
          self._createDraggables(fnCreateDraggables);
        },
        this
      );
    }
  },

  _createDraggables: function (fnCreateDraggables) {
    if (this.controller.datasource.source && this.controller.datasource.source().length > 0) {
      fnCreateDraggables.apply(this);
    }
  },

  _buildGroupHelper: function ($helper, record) {
    const self = this;
    const grid = self.controller.$grid;
    const gridData = grid.getData();

    const propertyName = grid.groups[grid.groups.length - 1].propertyName;

    if (record && propertyName in record) {
      const groupKey = record[propertyName];

      gridData
        .filter((data) => {
          return data[propertyName] === groupKey;
        })
        .forEach((data) => {
          self.$element
            .find(self.config.draggableItem + ".plex-grid-row:nth-child(" + (data.$$index + 1) + ")")
            .clone()
            .appendTo($helper.find("tbody"));
          self.draggable.data.push(data);
        });
    }
  }
});

module.exports = gridHandler;
plexExport("draggables.handlers.grid", gridHandler);
