ï»¿const $ = require("jquery");
const ko = require("knockout");
const DocumentGalleryItem = require("./plex-document-gallery-item-base");
const nav = require("../../Core/plex-navigate");
const banner = require("../../Plugins/plex-banner");
const downloadFile = require("../../Plugins/plex-filedownload");
const plexExport = require("../../../global-export");
const env = require("../../Core/plex-env");

// todo: have some create default images to be used for popular file types. Ex. fake excel icon
const DEFAULT_FILEIMAGE = nav.buildUrl("/Content/Images/icons/DocumentGallery/misc.png", null, {
  host: nav.domainHosts.cdn
});
const fileImages = {
  pdf: nav.buildUrl("/Content/Images/icons/DocumentGallery/pdf.png", null, { host: nav.domainHosts.cdn }),
  document: nav.buildUrl("/Content/Images/icons/DocumentGallery/document.png", null, { host: nav.domainHosts.cdn }),
  spreadsheet: nav.buildUrl("/Content/Images/icons/DocumentGallery/spreadsheet.png", null, {
    host: nav.domainHosts.cdn
  }),
  html: nav.buildUrl("/Content/Images/icons/DocumentGallery/html.png", null, { host: nav.domainHosts.cdn }),
  archive: nav.buildUrl("/Content/Images/icons/DocumentGallery/archive.png", null, { host: nav.domainHosts.cdn })
};

const DocumentGalleryItemFile = DocumentGalleryItem.extend({
  onPreInit: function () {
    if (!this.config.imageUrl && this.config.fileType) {
      this.config.imageUrl = fileImages[this.config.fileType];
    }

    this.config.imageUrl = this.config.imageUrl || DEFAULT_FILEIMAGE;
  },

  onInit: function () {
    this.fileUrl = ko.observable(this.config.fileUrl);

    if (env.features["feat-mo-8880-document-gallery-pdf-preview"]) {
      if (this.config.fileType === "pdf") {
        this.isPdf(true);
        this.displayExpand(true);
        this.imageUrl(this.config.fileUrl + "#toolbar=0&navpanes=0&scrollbar=0");
      }
    }

    this.backgroundSize = ko.computed(() => {
      return "50%";
    });
  },

  onDownload: function () {
    const self = this;
    let $deferred = new $.Deferred();

    if (self.config.nodeNo) {
      // Download from DCS
      const url = "/Platform/DocumentControlSystem/DownloadDocument";
      const options = { data: { nodeNo: self.config.nodeNo } };

      $deferred = downloadFile(url, options);

      $deferred.fail((result) => {
        banner.findClosest().setMessage(result.message, { autoGlossarize: false });
      });
    } else {
      // TODO: Implement non-DCS downloading.
    }

    return $deferred.promise();
  },

  getXml: function () {
    // TODO: Implement XML for embedding PDF.
  }
});

module.exports = DocumentGalleryItemFile;
plexExport("documentGallery.DocumentGalleryItemFile", DocumentGalleryItemFile);
