ï»¿const stringUtils = require("../Utilities/plex-utils-strings");
const formatUtils = require("../Globalization/plex-formatting");
const valueProviderFactory = require("../Labels/plex-labels-value-providers-factory");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const HtmlDisplayFeature = Feature.extend({
  onExecute: function (record, index, _records) {
    /// <summary>Executes the html display feature on the given record, basically does not escape the html tags.</summary>
    /// <param name="record">The record to evaluate.</param>
    /// <param name="index">The record index.</param>
    /// <param name="records">The array of records.</param>

    let valueProvider = this.element.valueProvider;
    if (valueProvider && !valueProvider.getValue) {
      valueProvider = valueProviderFactory.create(this.element);
    }

    if (valueProvider && typeof valueProvider.getValue === "function") {
      let value = valueProvider.getValue(record, index);
      let printValues = [];
      if (valueProvider.getValues && typeof valueProvider.getValues === "function") {
        printValues = valueProvider.getValues(record, index);
      }
      const formatter = this._getFormatter(valueProvider);
      if (formatter) {
        value = formatUtils.formatValue(value, formatter, record);
      }

      return {
        content: stringUtils.unescapeHtml(value),
        printContent: stringUtils.unescapeHtml(printValues)
      };
    }

    return {};
  },

  _getFormatter: function (valueProvider) {
    if (valueProvider.getFormatter && typeof valueProvider.getFormatter === "function") {
      return valueProvider.getFormatter();
    }

    if (valueProvider.options) {
      return valueProvider.options.formatter;
    }

    return null;
  }
});

module.exports = HtmlDisplayFeature;
plexExport("features.HtmlDisplay", HtmlDisplayFeature);
