ï»¿const $ = require("jquery");
const dataUtils = require("./plex-utils-data");
const StringBuilder = require("./plex-utils-stringbuilder");
const plexExport = require("../../global-export");

const defaultOptions = {
  writeProperyAsAttibute: false,
  includeXmlHeader: false,
  rootTag: "root",
  rowTag: "row",
  propertyInclusions: null,
  propertyExclusions: null,
  propertyMap: null
};

function objectToXml(obj, options) {
  /// <summary>
  /// This function takes an object or an array of objects
  /// and convert them to xml string.
  /// </summary>
  /// <param name="obj">The object or array to convert.</param>
  /// <param name="options">
  /// An options object to apply against the provided object.
  /// - writeProperyAsAttibute: will write properties as attributes vs. elements. (default false)
  /// - includeXmlHeader: includes standard xml documnet header. (default false)
  /// - rootTag: root element tag. (default 'root)
  /// - rowTag: xml tag to use if initial object is an array, not used if initial is an object. (default 'row')
  /// - propertyInclusions: list of object prperties to include.  Take precedence over exclution. (default null)
  /// - propertyExclusions: list of object prperties to exclude. (default null)
  ///   example - propertyExclusions: {
  ///      'prop2': true,
  ///      'child1': true
  ///   }
  /// - propertyMap: map properties to attributes or elements with different names. (default null)
  ///   example - propertyMap: {
  ///      'array1': 'new_array1',
  ///     'child2': 'new_child2'
  ///   }
  /// </param>
  /// <returns type="string">The xml string.</returns>

  const writer = new StringBuilder();
  const data = dataUtils.cleanse(obj, { flatten: false, keepObservables: false, ignoreEmpty: false, toString: true });
  options = $.extend({}, defaultOptions, options);

  if (options.includeXmlHeader) {
    writer.append('<?xml version="1.0" encoding="utf-8"?>');
  }

  if (data == null) {
    writer.append("<" + options.rootTag + "/>");
    return writer.toString();
  }

  if (Array.isArray(data)) {
    writer.append("<" + options.rootTag + ">");
    data.forEach((record) => {
      options.writeProperyAsAttibute
        ? writeObjectWithAttr(writer, options.rowTag, record, options)
        : writeObject(writer, options.rowTag, record, options);
    });
    writer.append("</" + options.rootTag + ">");
  } else {
    options.writeProperyAsAttibute
      ? writeObjectWithAttr(writer, options.rootTag, data, options)
      : writeObject(writer, options.rootTag, data, options);
  }

  return writer.toString();
}

function writeObject(writer, propTag, obj, options) {
  writer.append("<" + mapProperty(propTag, options) + ">");

  if (typeof obj === "object" && obj) {
    Object.keys(obj).forEach((propertyName) => {
      if (includeProperty(propertyName, options)) {
        let value = obj[propertyName];
        if (value == null) {
          value = "";
        }

        if (Array.isArray(value)) {
          value.forEach((record) => {
            writeObject(writer, propertyName, record, options);
          });
        } else {
          writeProperty(writer, propertyName, value, options);
        }
      }
    });
  }

  writer.append("</" + mapProperty(propTag, options) + ">");
}

function writeProperty(writer, propTag, value, options) {
  writer.append("<" + mapProperty(propTag, options) + ">");
  writer.append(value);
  writer.append("</" + mapProperty(propTag, options) + ">");
}

function writeObjectWithAttr(writer, propTag, obj, options) {
  writer.append("<" + mapProperty(propTag, options));

  // write simple object properties
  if (typeof obj === "object" && obj) {
    Object.keys(obj).forEach((propertyName) => {
      if (includeProperty(propertyName, options)) {
        let value = obj[propertyName];
        if (value == null) {
          value = "";
        }

        if (!Array.isArray(value)) {
          writePropertyAsAttr(writer, propertyName, value, options);
        }
      }
    });
  }

  writer.append(">");

  // if any arrays, write them as elements with attributes
  if (typeof obj === "object" && obj) {
    Object.keys(obj).forEach((propertyName) => {
      if (includeProperty(propertyName, options)) {
        let value = obj[propertyName];
        if (value == null) {
          value = "";
        }

        if (Array.isArray(value)) {
          value.forEach((record) => {
            writeObjectWithAttr(writer, propertyName, record, options);
          });
        }
      }
    });
  }

  writer.append("</" + propTag + ">");
}

function writePropertyAsAttr(writer, propTag, value, options) {
  writer.append(" " + mapProperty(propTag, options) + '="' + value + '"');
}

function includeProperty(propTag, options) {
  if (options.propertyInclusions && !options.propertyInclusions[propTag]) {
    return false;
  }

  if (options.propertyExclusions && options.propertyExclusions[propTag]) {
    return false;
  }

  return true;
}

function mapProperty(propTag, options) {
  if (options.propertyMap) {
    return options.propertyMap[propTag] || propTag;
  }

  return propTag;
}

const api = {
  objectToXml
};

module.exports = api;
plexExport("xml", api);
