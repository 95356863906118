ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const banner = require("../Plugins/plex-banner");
const DocumentControlSystem = require("../Native/plex-native-dcs");
const plexExport = require("../../global-export");

const OpenLocalFileFromDCS = Action.extend({
  onExecute: function (data, additionalData, _nextAction) {
    const defaultOptions = {
      isPendingRevision: false
    };

    const currentAdditionalData = $.extend({}, defaultOptions, additionalData);

    const dcs = new DocumentControlSystem();
    dcs
      .openFile(
        currentAdditionalData.isPendingRevision === true ? this.getPendingFileName(data.filename) : data.filename
      )
      .catch(this.handleDocumentControlSystemErrors);

    return false;
  },

  getFileExtension: function (fileName) {
    return fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase();
  },

  getPendingFileName: function (fileName) {
    const fileExtension = this.getFileExtension(fileName);
    const fileStatus = "." + fileExtension;
    return fileName.slice(0, fileName.length - fileStatus.length) + ".tmp." + fileExtension;
  },

  successResult: function () {
    return $.when().resolve().promise();
  },

  handleDocumentControlSystemErrors: function (error) {
    const errorMessage = {
      text: "",
      autoGlossarize: true,
      tokens: null
    };

    const errorMessageOptions = {
      status: banner.states.error
    };

    let showError = true;

    if (error) {
      if (error.ValidationResult) {
        errorMessage.text = error.ValidationResult;
        errorMessage.autoGlossarize = false;
      } else if (error.text) {
        errorMessage.text = error.text;
        errorMessage.autoGlossarize = error.isGlossarized !== true;
        errorMessage.tokens = error.tokens;
      } else {
        errorMessage.text = error;
      }

      if (error.status) {
        errorMessageOptions.status = error.status;
      }

      showError = error.hideError !== true;
    }

    if (showError) {
      if (typeof errorMessage.text !== "string" || errorMessage.text.length === 0) {
        errorMessage.text = "An error occurred. Contact Plex if the problem persists.";
        errorMessage.autoGlossarize = true;
      }

      banner.getBanner().setMessage(errorMessage, errorMessageOptions);
    }

    return this.successResult();
  }
});

module.exports = OpenLocalFileFromDCS;
plexExport("actions.OpenLocalFileFromDCS", OpenLocalFileFromDCS);
