ï»¿const Feature = require("../Features/plex-features");
const plexExport = require("../../global-export");

const FilterToggle = Feature.extend({
  onExecute: function () {
    if (!this.element.searched() && !this.parent.filtersShowing()) {
      return { css: ["plex-filter-hidden"] };
    } else if (this.element.searched()) {
      return { css: ["plex-filter-searched"] };
    }

    return {};
  }
});

module.exports = FilterToggle;
plexExport("features.FilterToggle", FilterToggle);
