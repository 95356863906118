ï»¿const ko = require("knockout");

const segmentRgx = /^\s*(-?\d+\.?\d*)?\s*(\w+|%)\s*$/;
const DEFAULT_UNIT = "px";

ko.extenders.styleMeasurement = function (target, options) {
  // protect against being applied twice
  if (target.$$value && target.$$unit) {
    return target;
  }

  const valueObservable = ko.observable();
  const unitObservable = ko.observable();
  const defaultUnit = (options && typeof options === "object" ? options.defaultUnit : DEFAULT_UNIT) || DEFAULT_UNIT;
  const subscriptions = [];
  let updating;

  function updateSegments(value) {
    if (updating) {
      return;
    }

    updating = true;
    if (value != null && value !== "") {
      const segments = segmentRgx.exec(value);
      const num = segments[1] || "";
      const unit = segments[2];

      valueObservable(num);
      unitObservable(unit);
    } else {
      valueObservable("");
      unitObservable(defaultUnit);
    }

    updating = false;
  }

  function updateTarget() {
    // set flag to prevent circular updates
    updating = true;
    const length = valueObservable();
    if (length == null || length === "") {
      target("");
    } else {
      target(length + unitObservable());
    }

    updating = false;
  }

  subscriptions.push(valueObservable.subscribe(updateTarget));
  subscriptions.push(unitObservable.subscribe(updateTarget));
  subscriptions.push(target.subscribe(updateSegments));

  updateSegments(target.peek());

  const originalDispose = target.dispose;
  target.dispose = function () {
    let sub;
    while ((sub = subscriptions.pop())) {
      sub.dispose();
    }

    if (originalDispose) {
      originalDispose.call(target);
    }
  };

  // tack these on so they can be bound individually
  target.$$value = valueObservable;
  target.$$unit = unitObservable;
  return target;
};
