ï»¿const ko = require("knockout");
const $ = require("jquery");
const pubsub = require("../Core/plex-pubsub");
const requiredStates = require("../Core/plex-validation").requiredStates;

function isInGrid(context) {
  return context.options && context.options.inGrid;
}

ko.bindingHandlers.required = {
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const value = ko.unwrap(valueAccessor());
    if (value == null || value === requiredStates.notRequired) {
      return;
    }

    const $element = $(element);
    let $controlLabel;

    if (isInGrid(bindingContext.$data)) {
      $controlLabel = $element.closest(".plex-controls");
    } else {
      const $controlGroup = $element.parents(".plex-control-group");

      // Find the label elements
      $controlLabel = $controlGroup.find(
        ".plex-control-label label, .plex-control-label .plex-text-link, .plex-controls-element label, .plex-controls-element .plex-text-link"
      );
      if ($controlLabel.length > 1 && element.id) {
        // multiple cells or fieldpair, try get the correct label
        $controlLabel = $controlLabel.filter("[for='" + element.id + "']");
      }

      if ($controlLabel.length === 0) {
        $controlLabel = $element.closest(".plex-controls");
      }
    }

    const clearErrors = function () {
      $element.removeClass("plex-error");
      $element.parent().removeClass("plex-error");

      // Get the Error Labels
      const $errorLabel = $element.closest(".plex-controls").find("label.plex-error");
      $errorLabel.remove();
    };

    const publishChange = function () {
      const $filter = $element.closest(".plex-filter-content");
      if ($filter.length > 0) {
        ko.ignoreDependencies(() => pubsub.publish("requiredChanged_" + $filter[0].id, value));
      }
    };

    $controlLabel.removeClass("required");
    $controlLabel.removeClass("plex-require-group");
    clearErrors();

    switch (value) {
      case requiredStates.required:
        $controlLabel.addClass("required");
        publishChange();
        break;

      case requiredStates.requireGroup:
        $controlLabel.addClass("plex-require-group");
        publishChange();
        break;

      default:
        // ignore
        break;
    }
  }
};
