ï»¿const Serializer = require("./plex-exporter-serializers");
const plexExport = require("../../global-export");

const JsonSerializer = Serializer.extend({
  mimeType: "application/json",
  extension: ".json",
  // for ie9
  altExtension: ".txt",
  recordDelimiter: ",",

  serializeBegin: function () {
    return "[";
  },

  serializeEnd: function () {
    return "]";
  },

  serializeRecord: function (record) {
    const row = {};

    record.forEach((col) => {
      row[col.header + (col.headerExists === 0 ? "" : col.headerExists)] = col.value;
    });

    return JSON.stringify(row);
  }
});

module.exports = JsonSerializer;
plexExport("exporter.serializers.json", JsonSerializer);
