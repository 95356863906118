ï»¿const compiler = require("../Expressions/plex-expressions-compiler");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

const ExpressionValueProvider = DefaultDataLabelValueProvider.extend({
  init: function () {
    this.evaluator = compiler.compile(this.options.valueProvider.expression);
  },

  getValue: function () {
    try {
      return this.evaluator.apply(null, arguments);
    } catch (e) {
      logger.error(e);

      return "#error#";
    }
  }
});

module.exports = ExpressionValueProvider;
plexExport("labels.valueProviders.ExpressionValueProvider", ExpressionValueProvider);
