ï»¿const action = require("./plex-actions");
const printLabel = require("../Barcoding/LabelPrinting/plex-labelprinting-common");
const notify = require("../Core/plex-notify");
const plexExport = require("../../global-export");

const PrintLabel = action.extend({
  onExecute: function (data) {
    if (this.labelFormatConfiguration) {
      return printLabel(data, this.labelFormatConfiguration);
    } else {
      notify.error("This feature is coming soon!");
      return false;
    }
  }
});

module.exports = PrintLabel;
plexExport("actions.PrintLabel", PrintLabel);
