ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.draggable = (function () {
  const events = ["create", "drag", "resize", "start", "stop"];

  // by default prevent a click on an anchor from triggering a move
  // this allows dialogs to be closed on tablets, which otherwise
  // gets overriden by the touch events and applied to dragging
  const draggableDefaults = { cancel: "a,button" };

  return {
    init: function (element, valueAccessor, allBindings, viewModel) {
      let options = valueAccessor();
      if (options === false) {
        return;
      }

      // true indicates default options
      if (options === true) {
        options = draggableDefaults;
      }

      options = $.extend({}, draggableDefaults, options);

      const $el = $(element);
      wrapEvents(options, events, viewModel);

      $el.draggable(options);
    }
  };
})();

ko.bindingHandlers.droppable = (function () {
  const events = ["activate", "create", "deactivate", "drop", "out", "over"];

  return {
    init: function (element, valueAccessor, allBindings, viewModel) {
      let options = valueAccessor();
      if (options === false) {
        return;
      }

      // true indicates default options
      if (options === true) {
        options = {};
      }

      const $el = $(element);
      wrapEvents(options, events, viewModel);
      $el.droppable(options);
    }
  };
})();

ko.bindingHandlers.resizable = (function () {
  const events = ["create", "resize", "start", "stop"];
  const resizableDefaults = {
    handles: "e,s,w,se,sw"
  };

  return {
    init: function (element, valueAccessor, allBindings, viewModel) {
      let options = valueAccessor();
      if (options === false || (options && options.disabled)) {
        return;
      }

      let $el = $(element);
      options = $.extend({}, resizableDefaults, options);
      wrapEvents(options, events, viewModel);

      if (!("zIndex" in options)) {
        // use current z-index as basis, so overlay is always on-top
        options.zIndex = $el.zIndex() + 1;
      }

      if (element.tagName === "TEXTAREA") {
        // textarea sizing is unreliable in browsers, which is why we
        // are using this plugin - instead of sizing the input, size
        // a wrapped div

        // get derived min dimensions and apply to parent
        const css = $el.css(["minWidth", "minHeight", "maxWidth", "maxHeight"]);

        options.minWidth = firstValidNumber(options.minWidth, css.minWidth);
        options.maxWidth = firstValidNumber(options.maxWidth, css.maxWidth);
        options.minHeight = firstValidNumber(options.minHeight, css.minHeight);
        options.maxHeight = firstValidNumber(options.maxHeight, css.maxHeight);

        // outerWidth and outerHeight may be less than minimum allowed - set min values
        const width = Math.max($el.outerWidth(), options.minWidth);
        const height = Math.max($el.outerHeight(), options.minHeight);

        // reset any inline measurements
        ["width", "minWidth", "maxWidth", "height", "minHeight", "maxHeight"].forEach(
          (prop) => (element.style[prop] = "")
        );

        $el.wrap("<div class='ui-textarea-wrapper'/>");

        $el = $el
          .parent()
          // copy the current size to the wrapped div
          .css({ height, width });
      } else if (!("helper" in options) && !options.ghost && !options.animate) {
        // default to using helper if no helper methods are found and the object is not a control
        options.helper = "ui-resizable-helper";
      }

      $el.resizable(options);
      $el.one("resizestop.resizable", () => $el.addClass("resized"));

      if (/se/.test(options.handles)) {
        // add icon to se corner
        // note: jquery only does this if you ONLY have "se" as a handle, which is lame
        $el.find(".ui-resizable-se").addClass("ui-icon ui-icon-gripsmall-diagonal-se");
      }

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        $el.off("resizestop.resizable");
      });
    }
  };
})();

ko.bindingHandlers.maximizable = {
  after: ["resizable", "draggable"],

  init: function (element, valueAccessor) {
    const $element = $(element);
    const options = valueAccessor();

    const maximizedClass = options.maximizedClass || "maximized";
    const $target = $(options.target || document.body);
    const $handle = $element
      .find(options.handle)
      // add back in case the handle is the element itself
      .addBack(options.handle);

    const resizable = $element.hasClass("ui-resizable") && typeof $element.resizable === "function";
    const draggable = $element.hasClass("ui-draggable") && typeof $element.draggable === "function";

    let maximized = false;
    let left, top, height, width;
    const toggle = () => {
      maximized = !maximized;

      // retain positioning info while toggling between states
      if (maximized) {
        left = element.style.left;
        top = element.style.top;
        height = element.style.height;
        width = element.style.width;

        element.style.left = element.style.top = element.style.height = element.style.width = "";
      } else {
        element.style.left = left;
        element.style.top = top;
        element.style.height = height;
        element.style.width = width;
      }

      $target.toggleClass(maximizedClass, maximized);

      if (resizable) {
        $element.resizable("option", "disabled", maximized);
      }

      if (draggable) {
        $element.draggable("option", "disabled", maximized);
      }
    };

    $handle.on("dblclick.maximizable", toggle);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      if (maximized) {
        // restore to normal state
        toggle();
      }

      $handle.off("dblclick.maximizable", toggle);
    });
  }
};

function firstValidNumber(...values) {
  return ko.utils.arrayFirst(values, $.isNumeric);
}

function wrapEvents(options, events, vm) {
  events.forEach((eventName) => {
    if (typeof options[eventName] === "function") {
      options[eventName] = options[eventName].bind(vm, vm);
    }
  });
}
