ï»¿import logger from "../Core/plex-logger";
import { buildUrl } from "../Core/plex-navigate";
import ActionHandler from "../Controls/plex-handler-action";
import GlossaryHandler from "../Globalization/plex-glossary-handler";
import Action from "./plex-actions";
import { startsWith, endsWith } from "../Utilities/plex-utils-strings";
import { cleanse as cleanseData } from "../Utilities/plex-utils-data";
import plexExport from "../../global-export";

const eventIsCorrect = (event) => {
  // This is just a sanity check to validate and prevent unexpected usages of UploadAction
  return (
    !!event &&
    typeof event === "object" &&
    Array.isArray(event.files) &&
    typeof event.fileRejected === "function" &&
    typeof event.fileAccepted === "function"
  );
};

const isAllowedType = (file, acceptedTypes) => {
  if (!Array.isArray(acceptedTypes) || acceptedTypes.length === 0) {
    return true;
  }

  return acceptedTypes.filter(Boolean).every((type) => {
    // .png
    if (type.at(0) === ".") {
      return endsWith(file.name, type);
    }

    // images/*
    if (type.at(-1) === "*") {
      return startsWith(file.type, type.substring(0, type.length - 1));
    }

    // image/png
    return type.toLowerCase() === file.type?.toLowerCase();
  });
};

const Upload = Action.extend({
  onInit() {
    if (this.dataProcessingAction) {
      ActionHandler.initAction(this.dataProcessingAction, this.parent);
    }
  },

  onExecute: function (data, _rawData, event) {
    if (!this.address) {
      logger.error("No upload URL has been specified");
      return false;
    }

    if (!eventIsCorrect(event)) {
      logger.error("The event passed to the UploadAction is missing or incorrect");
      return false;
    }

    return this.validateFiles(event)
      .then(() => this.processData(data, event))
      .then((x) => this.handleUpload(x, event))
      .then(() => data);
  },

  validateFiles(event) {
    const { maxFileSizeInBytes = 0, acceptedFileTypes = [] } = this.config;

    return Promise.all(
      event.files.map((file) => {
        if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
          return GlossaryHandler.getCustomerWordAsync({
            text: "The file {1} is too large. The maximum allowed is {2} bytes.",
            tokens: [file.name, maxFileSizeInBytes]
          }).then((message) => {
            event.fileRejected(file, message);
            return Promise.reject(message);
          });
        }

        if (!isAllowedType(file, acceptedFileTypes)) {
          return GlossaryHandler.getCustomerWordAsync({
            text: "The file {1} is not an accepted type.",
            tokens: [file.name]
          }).then((message) => {
            event.fileRejected(file, message);
            return Promise.reject(message);
          });
        }

        return Promise.resolve();
      })
    );
  },

  processData(data, event) {
    if (this.dataProcessingAction) {
      return ActionHandler.executeAction(this.dataProcessingAction, data, event, this);
    }

    return Promise.resolve(data);
  },

  handleUpload(data, event) {
    const { tokens = [], address, filePropertyName = "File" } = this;
    const postData = tokens.length > 0 ? cleanseData(data) : {};
    const url = buildUrl(address);

    return Promise.all(
      event.files.map((file) => {
        const body = new FormData();
        Object.entries(postData).forEach(([key, value]) => body.append(key, value));
        body.append(filePropertyName, file);

        return fetch(url, {
          method: "POST",
          body
        })
          .then(
            (response) => {
              if (!response.ok) {
                return GlossaryHandler.getCustomerWordAsync({
                  text: "The file {1} failed to upload.",
                  tokens: [file.name]
                }).then((message) => {
                  event.fileRejected(file, message);
                  return Promise.reject(message);
                });
              }

              return response.json();
            },
            () => {
              return GlossaryHandler.getCustomerWordAsync({
                text: "The file {1} failed to upload.",
                tokens: [file.name]
              }).then((message) => {
                event.fileRejected(file, message);
                return Promise.reject(message);
              });
            }
          )
          .then((response) => {
            if (this.validateResponse(response)) {
              event.fileAccepted(response.Data);
              return Promise.resolve();
            }

            const message = response.DataResult.Message;
            event.fileRejected(file, message);
            return Promise.reject(message);
          });
      })
    );
  }
});

module.exports = Upload;
plexExport("actions.Upload", Upload);
