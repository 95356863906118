ï»¿const ko = require("knockout");
const manager = require("./HotKeys/plex-hotkeys-manager");
const EditableComponent = require("./plex-component-editable-base");

const SEPARATOR = "+";
const EMPTY_DISPLAY_VALUE = "-";

class EditableHotkeyComponent extends EditableComponent {
  constructor(params) {
    super(params);

    this.separator = params.separator || SEPARATOR;
    this.emptyDisplayValue = params.emptyDisplayValue || EMPTY_DISPLAY_VALUE;

    this.code = ko.observable(null);
    this.modifier = ko.observable(null);

    this.lastFocus = [false, false];
    this.codeFocus = ko.observable(false);
    this.modifierFocus = ko.observable(false);

    this.availableCodes = ko.computed(() => manager.getCodes(this.modifier()));
    this.availableModifiers = manager.getModifiers();

    this.addDisposable(this.availableCodes);
    this.addDisposable(ko.computed(this._getValue, this));
    this.addDisposable(ko.computed(this._setValue, this));
    this.addDisposable(ko.computed(this._setFocus, this));
    this.addDisposable(ko.computed(this._onFocusChange, this).extend({ throttle: 50 }));

    // need for the favorites-mass-edit screen
    this.shouldValidate = true;
  }

  editCode() {
    this.lastFocus = [true, false];
    super.edit();
  }

  editModifier() {
    this.lastFocus = [false, true];
    super.edit();
  }

  _getValue() {
    const value = this.value();
    if (!value || value.length === 0) {
      this.code(null);
    } else {
      const parts = value.split(this.separator);
      this.code(parts.pop());
      this.modifier(this.code.peek() ? parts.pop() : null);
    }
  }

  _setValue() {
    const code = this.code();
    if (code) {
      const modifier = this.modifier();
      this.value(modifier ? modifier + this.separator + code : code);
    } else {
      this.value(null);
    }
  }

  _setFocus() {
    if (this.editing()) {
      this.codeFocus(this.lastFocus[0]);
      this.modifierFocus(this.lastFocus[1]);
    }
  }

  _onFocusChange() {
    const codeFocus = this.codeFocus();
    const modifierFocus = this.modifierFocus();
    const focus = codeFocus || modifierFocus;

    if (focus) {
      this.lastFocus = [codeFocus, modifierFocus];
    } else if (this.editing.peek()) {
      this.commit();
    }
  }
}

ko.components.register("plex-component-editable-hotkey", {
  template: "<!-- ko template: 'components-editable-hotkey' --><!-- /ko -->",
  viewModel: EditableHotkeyComponent
});
