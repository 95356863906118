ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const parseURL = require("../../Core/plex-parsing-url");
const plexExport = require("../../../global-export");
const strings = require("../../Utilities/plex-utils-strings");

const SUBMENU_ACTION_KEY_TOKEN_NAME = "__sak";
const SUBMENU_KEY_TOKEN_NAME = "__sk";
const SUBMENU_CUSTOMER_ACTION_KEY_TOKEN_NAME = "__scak";
const SUBMENU_COMMON_TOKENS_TOKEN_NAME = "__st";
const SUBMENU_LOAD_ITEMS_ACTION_TOKEN_NAME = "__cak";
const SUBMENU_TOKEN_PREFIX = "__t";
const ACTION_KEY_TOKEN_NAME = "__actionKey";

function SidetabsMenuUrlTokenManager(currentTokens) {
  const self = this;
  self.urlData = SidetabsMenuUrlTokenManager.parseUrl(window.location.href);
  self.commonTokens = [];
  self.currentTokens = currentTokens;

  const submenuActionKey = self.urlData.query[SUBMENU_ACTION_KEY_TOKEN_NAME];
  if (submenuActionKey) {
    self.urlData.submenuActionKey = parseInt(submenuActionKey, 10);
  }

  const submenuCustomerActionKey = self.urlData.query[SUBMENU_CUSTOMER_ACTION_KEY_TOKEN_NAME];
  if (submenuCustomerActionKey) {
    self.urlData.submenuCustomerActionKey = parseInt(submenuCustomerActionKey, 10);
  }

  self.urlData.submenuLoadItemsActionKey = self.urlData.query[SUBMENU_LOAD_ITEMS_ACTION_TOKEN_NAME];

  const commonTokens = self.urlData.query[SUBMENU_COMMON_TOKENS_TOKEN_NAME];
  if (commonTokens) {
    self.commonTokens = commonTokens.split("|");
  }
}

SidetabsMenuUrlTokenManager.prototype = {
  constructor: SidetabsMenuUrlTokenManager,

  isCurrentItem: function (itemConfig) {
    return (
      (this.urlData.submenuActionKey && this.urlData.submenuActionKey === itemConfig.submenuActionKey) ||
      (this.urlData.submenuCustomerActionKey &&
        this.urlData.submenuCustomerActionKey === itemConfig.submenuCustomerActionKey)
    );
  },

  buildMenuData: function (item) {
    const self = this;
    const data = {};

    if (self.menuData == null) {
      self.menuData = self._getMenuTokensData();
    }

    if (item) {
      if (item.data.submenuActionKey) {
        data[SUBMENU_ACTION_KEY_TOKEN_NAME] = item.data.submenuActionKey;
      } else if (item.data.submenuCustomerActionKey) {
        data[SUBMENU_CUSTOMER_ACTION_KEY_TOKEN_NAME] = item.data.submenuCustomerActionKey;
      }
    }

    $.extend(data, self.menuData);

    return data;
  },

  buildMenuTokens: function (action, data) {
    const self = this;
    const tokens = [];

    if (self.commonTokens.length > 0) {
      tokens.push(self._createToken(SUBMENU_COMMON_TOKENS_TOKEN_NAME));
    }

    const commonTokenList = self.commonTokens.map((tokenName) => self._createToken(tokenName));
    tokens.push(...commonTokenList);

    action.tokens = action.tokens.filter(
      (token) => SidetabsMenuUrlTokenManager.findCommonToken(token, commonTokenList, false) == null
    );

    $.each(data || action.data, (prop) => {
      if (!SidetabsMenuUrlTokenManager.isInternalTokenName(prop)) {
        const foundTokens = $.grep(action.tokens, (token) => {
          return token.propertyName === prop;
        });

        if (foundTokens.length === 0) {
          let tokenAlias = null;

          if (self.commonTokens.indexOf(prop) < 0) {
            tokenAlias = SidetabsMenuUrlTokenManager.buildDataTokenName(prop);
          }

          tokens.push(self._createToken(prop, tokenAlias));
        }
      }
    });

    if (self.urlData.submenuLoadItemsActionKey) {
      tokens.push(self._createToken(SUBMENU_LOAD_ITEMS_ACTION_TOKEN_NAME));
    }

    return tokens;
  },

  updateCurrentTokens: function (currentTokens) {
    this.currentTokens = currentTokens;
    this.menuData = null;
  },

  _createToken: function (propName, alias) {
    return {
      propertyName: propName,
      directOutput: false,
      alias
    };
  },

  _getMenuTokensData: function () {
    const self = this;
    const data = self._getRequiredData();

    self.currentTokens.forEach((token) => {
      const value = self.urlData.query[token.alias] || self.urlData.query[token.propertyName];

      if (value) {
        data[token.propertyName] = value;
      }
    });

    $.each(self.urlData.query, (tokenName, val) => {
      if (SidetabsMenuUrlTokenManager.isDataTokenName(tokenName)) {
        const propName = tokenName.substring(SUBMENU_TOKEN_PREFIX.length);
        if (data[propName] == null) {
          data[propName] = val;
        }
      } else if (self.commonTokens.indexOf(tokenName) >= 0) {
        data[tokenName] = val;
      }
    });

    return data;
  },

  _getRequiredData: function () {
    const self = this;
    const data = {};

    data[SUBMENU_KEY_TOKEN_NAME] = self.urlData.submenuKey;

    if (self.urlData.submenuLoadItemsActionKey) {
      data[SUBMENU_LOAD_ITEMS_ACTION_TOKEN_NAME] = self.urlData.submenuLoadItemsActionKey;
    }

    if (self.commonTokens.length > 0) {
      data[SUBMENU_COMMON_TOKENS_TOKEN_NAME] = self.commonTokens.join("|");
    }

    return data;
  }
};

SidetabsMenuUrlTokenManager.parseUrl = function (address) {
  if (!parseURL.isValidUrl(address)) {
    return null;
  }

  const sharp = address.indexOf("#");
  if (sharp > -1) {
    address = address.substring(0, sharp);
  }

  const urlData = parseURL.parseUrl(address);

  const actionKey = urlData.query[ACTION_KEY_TOKEN_NAME];
  if (actionKey) {
    urlData.actionKey = parseInt(actionKey, 10);
  }

  const submenuKey = urlData.query[SUBMENU_KEY_TOKEN_NAME];
  if (submenuKey != null) {
    urlData.submenuKey = parseInt(submenuKey, 10);
  }

  return urlData;
};

SidetabsMenuUrlTokenManager.isDataTokenName = function (tokenName) {
  return strings.startsWith(tokenName, SUBMENU_TOKEN_PREFIX);
};

SidetabsMenuUrlTokenManager.buildDataTokenName = function (tokenName) {
  if (!SidetabsMenuUrlTokenManager.isDataTokenName(tokenName)) {
    return SUBMENU_TOKEN_PREFIX + tokenName;
  }

  return tokenName;
};

SidetabsMenuUrlTokenManager.isInternalTokenName = function (tokenName) {
  return strings.startsWith(tokenName, "__");
};

SidetabsMenuUrlTokenManager.findCommonToken = function (token, commonTokens, includePropertyName) {
  for (let index = 0; index < commonTokens.length; ++index) {
    const commonToken = commonTokens[index];
    const commonTokenName = commonToken.alias || commonToken.propertyName;
    if (token.alias && token.alias === commonTokenName) {
      return commonToken;
    }

    if (includePropertyName && token.propertyName === commonTokenName) {
      return commonToken;
    }

    if (token.alias === SidetabsMenuUrlTokenManager.buildDataTokenName(commonTokenName)) {
      return commonToken;
    }
  }

  return null;
};

module.exports = SidetabsMenuUrlTokenManager;
plexExport("SidetabsMenuUrlTokenManager", SidetabsMenuUrlTokenManager);
