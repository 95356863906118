ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const controllerFactory = require("./plex-controller-factory");
const galleryItemFactory = require("./DocumentGallery/plex-document-gallery-item-factory");
const expressionUtils = require("../Expressions/plex-expressions-compiler");
const http = require("../Core/plex-navigate");
const LightBoxController = require("../Images/plex-lightbox");
const plexExport = require("../../global-export");

const resizeableConfig = {
  aspectRatio: true,
  minWidth: 160,
  maxWidth: 545
};

function getDocuments(attachmentGroupKey, recordKey, _callback) {
  return http.post(
    "/Platform/AttachmentSystem/GetDocumentItemAttachments/",
    { attachmentGroupKey, recordKeyValue: recordKey },
    { showOverlay: false }
  );
}

const DocumentGalleryController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

DocumentGalleryController.prototype = {
  constructor: DocumentGalleryController,

  init: function () {
    const self = this;
    self._subscriptions = [];

    self._lightbox = new LightBoxController();

    self.isBusy = ko.observable(false);
    self.index = ko.observable(0);
    self.gallery = ko.observableArray([]);

    if (self.config.attachmentGroupKey && self.config.recordKeyExpression) {
      let record = expressionUtils.compile(self.config.recordKeyExpression)(this.model);

      record = ko.unwrap(record);

      getDocuments(self.config.attachmentGroupKey, record).then((response) => {
        const documents = response.data || [];

        documents.forEach((item) => {
          self.gallery.push(galleryItemFactory.create(item));
        });
      });
    } else if (self.config.gallery && self.config.gallery.length > 0) {
      self.config.gallery.forEach((item) => {
        self.gallery.push(galleryItemFactory.create(item));
      });
    }

    self.item = ko.computed(() => {
      return self.gallery()[self.index()];
    });

    self.displayExpand = ko.pureComputed(() => {
      let display = false;
      if (self.item()) {
        display = self.item().displayExpand();
      }

      return display;
    });

    self.hasNext = ko.computed(() => {
      return self.index() < self.gallery().length - 1;
    });

    self.hasPrev = ko.computed(() => {
      return self.index() > 0;
    });

    // handle transition
    if (this.config.animateTransition) {
      self._subscriptions.push(self.item.subscribe($.proxy(self._onBeforeItemChange, self), null, "beforeChange"));
      self._subscriptions.push(self.item.subscribe($.proxy(self._onAfterItemChange, self)));
    }
  },

  changeAttachmentContext: function (attachmentGroupKeyNew, recordKeyValueNew) {
    const self = this;
    self.gallery.removeAll();
    self.config.attachmentGroupKey = attachmentGroupKeyNew;
    self.reset();

    getDocuments(self.config.attachmentGroupKey, recordKeyValueNew).then((response) => {
      const documents = response.data || [];

      documents.forEach((item) => {
        self.gallery.push(galleryItemFactory.create(item));
      });
    });
  },

  // this will be executed once the dom is ready (via template binding)
  afterRender: function () {
    const self = this;
    self.$element = self.config.resizeable
      ? $("#" + self.config.id).resizable(resizeableConfig)
      : $("#" + self.config.id);

    self.$transition = self.$element.find(".plex-document-gallery-transition");
  },

  reset: function () {
    this.index(0);
    this.isBusy(false);
  },

  next: function () {
    if (this.hasNext()) {
      this.index(this.index() + 1);
    }
  },

  prev: function () {
    if (this.hasPrev()) {
      this.index(this.index() - 1);
    }
  },

  download: function () {
    const self = this;
    if (self.item()) {
      self.isBusy(true);
      self
        .item()
        .download()
        .always(() => {
          self.isBusy(false);
        });
    }
  },

  expand: function (_data, event) {
    const self = this;

    event.stopPropagation();
    if (self.item().isPdf()) {
      self._lightbox.showObject(self.item().fileUrl(), self.item().description());
    } else {
      self._lightbox.showImage(self.item().imageUrl(), self.item().description());
    }
  },

  dispose: function () {
    let sub;
    while ((sub = this._subscriptions.pop())) {
      sub.dispose();
    }
  },

  _onBeforeItemChange: function () {
    const self = this;

    if (self.$transition) {
      self.$transition.empty();
      self.$element.find(".plex-document-gallery-item").clone().appendTo(self.$transition);
      self.$transition.removeClass("transition-out");
    }
  },

  _onAfterItemChange: function () {
    const self = this;
    if (self.$transition) {
      window.setTimeout(() => {
        self.$transition.addClass("transition-out");
      });
    }
  }
};

DocumentGalleryController.create = function (config, model) {
  return new DocumentGalleryController(config, model);
};

controllerFactory.register("Elements/_DocumentGallery", DocumentGalleryController);

module.exports = DocumentGalleryController;
plexExport("DocumentGalleryController", DocumentGalleryController);
