ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const dialogController = require("../Dialogs/plex-dialog-controller");
const jsUtils = require("../Utilities/plex-utils-js");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

const FaxAction = Action.extend({
  onExecute: function (data) {
    jsUtils.mixin(data, this.RequestData || {});

    const deferred = new $.Deferred();
    dialogController.create({
      route: "/Platform/Fax",
      httpMethod: "POST",
      routeData: nav.serialize(data),
      returnAction: function (dialogData) {
        self.dialogData = dialogData;
        deferred.resolve(dialogData);
      },
      closeAction: function () {
        deferred.reject();
      }
    });

    return deferred.promise();
  }
});

module.exports = FaxAction;
plexExport("actions.Fax", FaxAction);
