const $ = require("jquery");
const ko = require("knockout");

ko.extenders.xmlColumn = function (target, config) {
  const newArray = ko.observableArray();
  const observable = ko.pureComputed({ read: newArray }).extend({ trackArrayChanges: true });
  const xmlColumns = config.columns
    ? config.columns.filter((column) => {
        return column.type === "Xml";
      })
    : [];

  target.subscribe(
    () => {
      const source = target.peek();
      newArray(convertXmlColumnsData(source, xmlColumns));
    },
    null,
    "arrayChange"
  );

  observable.sort = observable.mergeSort = observable.stableSort = function (comparer) {
    newArray.stableSort(comparer);
  };

  newArray(convertXmlColumnsData(target.peek(), xmlColumns));
  return observable;
};

function convertXmlColumnsData(source, xmlColumnsConfig) {
  if (source && source.length > 0 && xmlColumnsConfig && xmlColumnsConfig.length > 0) {
    return source.map((record) => convertXmlColumnsRecord(record, xmlColumnsConfig));
  }

  return source;
}

function convertXmlColumnsRecord(record, xmlColumnsConfig) {
  return xmlColumnsConfig.reduce((rec, columnConfig) => {
    rec[columnConfig.propertyName] = convertXmlValue(rec[columnConfig.propertyName], columnConfig);
    return rec;
  }, record);
}

function convertXmlValue(value, columnConfig) {
  value = "<rows>" + value + "</rows>";
  const xmlDoc = $.parseXML(value);
  const $xml = $(xmlDoc);
  const $rows = $xml.find("row");

  return $.map($rows, (row) => {
    const formattedRow = {};

    $.each(row.childNodes, (i, childNode) => {
      const foundConfig = columnConfig.nodeNames.filter(
        (nodeName) => nodeName.propertyName.toLowerCase() === childNode.nodeName.toLowerCase()
      );

      if (foundConfig.length > 0) {
        // user property as user defined it even if case is not matches node
        formattedRow[foundConfig[0].propertyName] = childNode.textContent;
      }
    });

    return formattedRow;
  });
}
