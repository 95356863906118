ï»¿const ko = require("knockout");
const $ = require("jquery");
const knockoutUtils = require("./plex-utils-knockout");

ko.bindingHandlers.colorValue = {
  init: function (element, valueAccessor, allBindings) {
    const value = ko.unwrap(valueAccessor());
    const $el = $(element);

    const disablePicker =
      (ko.isObservable(allBindings().disable) && allBindings().disable()) ||
      (ko.isObservable(allBindings().readOnly) && allBindings().readOnly());

    $el.spectrum({
      color: value,
      allowEmpty: true,
      preferredFormat: "hex",
      showInput: true,
      disabled: !!disablePicker,

      // todo: these need to be glossarized
      cancelText: "Cancel",
      chooseText: "Choose"
    });

    $el.on("change.plex.colorValue", (e, color) => {
      const model = valueAccessor();

      // color is null when "clear color selection" button is hit in color picker
      const colorString = color ? color.toHexString() : "";
      if (ko.isObservable(model)) {
        model(colorString);
      } else {
        knockoutUtils.updateTwoWayBinding(allBindings, "colorValue", colorString);
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $el.off("change.plex.colorValue");
      $el.spectrum("destroy");
    });
  },
  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    $(element).spectrum("set", value);
  }
};

ko.expressionRewriting._twoWayBindings.colorValue = true;
