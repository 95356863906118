const ko = require("knockout");
const GroupHeaderRowWriter = require("./plex-grid-group-header-row-writer");
const DataProvider = require("../../Data/plex-data-provider");
const compiler = require("../../Expressions/plex-expressions-compiler");
const jsUtils = require("../../Utilities/plex-utils-js");
const htmlUtils = require("../../Utilities/plex-utils-html");
const utils = require("../plex-grid-utils");
const plexExport = require("../../../global-export");

const targets = [];
const returnTrue = function () {
  return true;
};

function triggerUnmemoization(targetElement) {
  if (targets.indexOf(targetElement) >= 0) {
    return;
  }

  // defer and limit the unmemoize requests - since we are scanning the entire grid body
  // instead of targetting the actual header rows this ends up being a bit expensive so
  // this will help limit the damage
  targets.push(targetElement);

  jsUtils.defer(() => {
    targets.remove(targetElement);
    htmlUtils.unmemoize(targetElement);
  });
}

function isEmpty(data) {
  if (!data) {
    return true;
  }

  if (Array.isArray(data) && data.length === 0) {
    return true;
  }

  return false;
}

const BoundGroupRowWriter = GroupHeaderRowWriter.extend({
  init: function () {
    this._base.apply(this, arguments);

    // either there is a single provider with an expression to "join" the data on
    // or there is a provider for every group
    if (this.config.joinExpression) {
      this.joinEvaluator = compiler.compile(this.config.joinExpression);
      this.provider = new DataProvider(this.config.dataProvider);
    } else {
      this.joinEvaluator = returnTrue;
      this.providers = Object.create(null);
    }
  },

  render: function (writer, group, config) {
    if (this.providers) {
      const firstRecord = utils.getFirstRecord(group);

      // todo: we may want to clear this collection on grid re-rendering
      this.providers[group.key] = new DataProvider(this.config.dataProvider, firstRecord);
    }

    return this._base(writer, group, config);
  },

  getHeaderHtml: function (record, group) {
    const self = this;
    const getHtml = this._base.bind(this);
    let boundData = this.getHeaderData(record, group);
    if (boundData) {
      // if the data is already resolved just pass in the first record to the base method
      return getHtml(boundData, group);
    }

    const value = ko.pureComputed(() => {
      // just pass empty string until we have a value until we have data;
      let html = "";

      boundData = self.getHeaderData(record, group);
      if (boundData) {
        triggerUnmemoization(self.grid.$tbody[0]);
        ko.ignoreDependencies(() => {
          // need to ignore dependencies otherwise this will end up causing
          // a slow but deadly infinite loop
          html = getHtml(boundData, group);
        });
      }

      return html;
    });

    return htmlUtils.getMemoizedTemplate("grid-text", { value }, null, this.grid.$element[0]);
  },

  getHeaderData: function (record, group) {
    const data = ko.unwrap(this.provider ? this.provider.data : this.providers[group.key].data);
    const evaluator = this.joinEvaluator;

    if (isEmpty(data)) {
      return null;
    }

    if (Array.isArray(data)) {
      // for now we will always assume that there is just one record that we care about
      // for binding to the group header until that becomes untrue
      return ko.utils.arrayFirst(data, (row) => {
        return evaluator(record, row);
      });
    }

    return data;
  }
});

module.exports = BoundGroupRowWriter;
plexExport("grid.writers.BoundGroupRowWriter", BoundGroupRowWriter);
