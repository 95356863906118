ï»¿/* eslint-disable no-invalid-this */
const stringUtils = require("./plex-utils-strings");
const dataUtils = require("./plex-utils-data");
const CustomerDate = require("../Globalization/plex-customer-date");
const plexExport = require("../../global-export");

const slice = Array.prototype.slice;
const trueTest = /^(?:true|1|y(?:es)?|on)$/i;
const operators = {};
const api = {};

// #region Comparison Operators

api.lessThanOrEqual = operators.LESSEQUAL = operators["<="] = { symbol: "<=", comparer: oneOf(less, equals) };
api.greaterThanOrEqual = operators.GREATEREQUAL = operators[">="] = {
  symbol: ">=",
  comparer: oneOf(greater, equals)
};
api.equal = api.equals = operators.EQUAL = operators["=="] = operators["="] = {
  symbol: "==",
  comparer: equals
};
operators.NOTEQUAL = operators["!="] = operators["<>"] = { symbol: "!=", comparer: not(equals) };
api.lessThan = operators.LESS = operators["<"] = { symbol: "<", comparer: less };
api.greaterThan = operators.GREATER = operators[">"] = { symbol: ">", comparer: greater };

// these don't really have operators but are supported by the framework
api.contains = operators.CONTAINS = { comparer: contains };
operators.NOTCONTAINS = { comparer: not(contains) };
api.startsWith = operators.STARTSWITH = { comparer: startsWith };
api.isEmpty = operators.ISEMPTY = { comparer: isEmpty };

// #endregion

// #region Conditional Operators

operators.AND = operators["&&"] = { symbol: "&&", comparer: allOf() };
operators.OR = operators["||"] = { symbol: "||", comparer: oneOf() };
operators.XOR = operators["^"] = { symbol: "^", comparer: xor };

// #endregion

function isEmpty(value) {
  return dataUtils.isEmpty(value);
}

function startsWith(expected, actual) {
  return stringUtils.startsWith(expected, actual, false);
}

function contains(expected, actual) {
  return stringUtils.contains(expected, actual, false);
}

function xor(a, b) {
  return !a ^ !b;
}

function allOf() {
  if (arguments.length === 0) {
    return function (a, b) {
      return a && b;
    };
  }

  const comparers = slice.call(arguments);
  const ln = comparers.length;

  return function (a, b) {
    for (let i = 0; i < ln; i++) {
      if (!comparers[i](a, b)) {
        return false;
      }
    }

    return true;
  };
}

function oneOf() {
  if (arguments.length === 0) {
    return function (a, b) {
      return a || b;
    };
  }

  const comparers = slice.call(arguments);
  const ln = comparers.length;

  return function (a, b) {
    for (let i = 0; i < ln; i++) {
      if (comparers[i](a, b)) {
        return true;
      }
    }

    return false;
  };
}

function not(comparerFn) {
  return function (a, b) {
    return !comparerFn(a, b);
  };
}

function less(a, b) {
  return greater(b, a);
}

function greater(a, b) {
  // todo: pretty sure that js will handle the conversions appropriately here
  return a > b;
}

function equals(a, b) {
  /// <summary>
  /// This will check equality between two values - it will do some conversions,
  /// for example convert "true" string to a boolean if the expected value is a boolean.
  /// </summary>
  /// <param name="expected">The expected value.</param>
  /// <param name="actual">The actual value.</param>
  /// <returns type="Boolean">`true` if the values are equal-ish, `false` otherwise.</returns>

  // todo: this method is more forgiving, but these should probably be merged
  if (dataUtils.compareValues(a, b) === 0) {
    // `compareValues` expects the types to be the same so only exit if they are purely equal
    return true;
  }

  if (typeof a === "boolean" || typeof b === "boolean") {
    return compareBool(a, b);
  }

  if (typeof a === "number" || typeof b === "number") {
    return compareNumber(a, b);
  }

  if (a instanceof Date || b instanceof Date) {
    return compareDate(a, b);
  }

  // do coercive check
  // eslint-disable-next-line eqeqeq
  return a == b;
}

function compareBool(a, b) {
  if (typeof a === "boolean" && typeof b === "boolean") {
    return a === b;
  }

  if (typeof a === "string") {
    a = trueTest.test(a);
  }

  if (typeof b === "string") {
    b = trueTest.test(b);
  }

  return !!a === !!b;
}

function compareNumber(a, b) {
  return Number(a) === Number(b);
}

function compareDate(a, b) {
  if (a == null && b == null) {
    return true;
  }

  if (a == null || b == null) {
    return false;
  }

  // always wrap in customer date
  return new CustomerDate(a).getTime() === new CustomerDate(b).getTime();
}

// comparer instance
function Comparer(expected) {
  // default to true
  this.expected = expected !== false;
  this.operators = operators;
}

Comparer.prototype.constructor = Comparer;
Object.keys(api).forEach((key) => {
  Comparer.prototype[key] = function (a, b) {
    return api[key].comparer(a, b) === this.expected;
  };
});

// add a couple special checks
Comparer.prototype.between = function (value, low, high) {
  /// <summary>
  /// Indicates whether the given value is between the two provided values. This is an inclusive check.
  /// (If low or high is not specified the check will check whether the value is above
  /// or below the value.)
  /// </summary>
  /// <param name="value">The value to evaluate.</param>
  /// <param name="low">The low threshold. (optional)</param>
  /// <param name="high">The high threshold. (optional)</param>
  /// <returns type="Boolean">`true` if the value is between the two values; `false` otherwise.</returns>

  if (low == null && high == null) {
    throw new Error("A `low` or `high` value must be supplied.");
  }

  if (low != null && high != null) {
    return (value >= low && value <= high) === this.expected;
  }

  if (low != null) {
    return value >= low === this.expected;
  }

  return value <= high === this.expected;
};

Comparer.prototype.compare = function (a, b, operator) {
  let comparer;
  if (!operator) {
    comparer = equals;
  } else if (typeof operator === "string") {
    if (!(operator in operators) || !(comparer = operators[operator].comparer)) {
      throw new Error("Unable to find comparison operator for: " + operator);
    }
  }

  return comparer(a, b) === this.expected;
};

const globalComparer = new Comparer();

// tack on `not` to enable fluent-y type checks
globalComparer.not = new Comparer(false);

module.exports = globalComparer;
plexExport("comparer", globalComparer);
