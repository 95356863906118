const Token = require("./plex-tokens-token-base");
const CustomerDate = require("../Globalization/plex-customer-date");
const parseJSON = require("../Core/plex-parsing-json");
const plexExport = require("../../global-export");

const JsonToken = Token.extend({
  getValue: function (...args) {
    let value = this.getAttr("value", args);

    value = value ? parseJSON(value) : null;

    if (value instanceof Date) {
      return new CustomerDate(value);
    }

    return value;
  }
});

module.exports = JsonToken;
plexExport("tokens.json", JsonToken);
