ï»¿const ko = require("knockout");

const slice = Array.prototype.slice;
const textInputBinding = ko.bindingHandlers.textInput;

function forwardToTextInputBinding(args) {
  return textInputBinding.init.apply(textInputBinding, args);
}

ko.bindingHandlers.delayedTextInput = {
  init: function (element, valueAccessor) {
    const args = slice.call(arguments);
    let source = valueAccessor();
    let delay = 200;

    if (source && !ko.isObservable(source)) {
      delay = source.delay || delay;
      source = source.value;
    }

    if (!ko.isWritableObservable(source)) {
      // just use normal text input with the original arguments
      return forwardToTextInputBinding(arguments);
    }

    let delayMethod = "notifyAtFixedRate";
    if (delay === "change") {
      delayMethod = "notifyWhenChangesStop";
      delay = 0;
    }

    // create a dummy observable that will be used in the binding
    // we can then subscribe to that and limit the updates per the delay
    const exposedObservable = source.extend({ rateLimit: { timeout: delay, method: delayMethod } });
    args[1] = function () {
      return exposedObservable;
    };
    return forwardToTextInputBinding(args);
  }
};
