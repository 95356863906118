ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const dom = require("../Utilities/plex-utils-dom");
const pubsub = require("../Core/plex-pubsub");

const Form = function (el, options) {
  this.$element = $(el);
  this.options = options;
  this.init();
};

Form.prototype = {
  init: function () {
    const self = this;

    self._inModal = dom.isInDialog(self.$element);
    self._isFormContainer = dom.isFormContainer(self.$element);
    self._inElementList = dom.isInElementList(self.$element);

    self._initEvents();
    self._handlePersistentBanner();

    self._render();
  },

  _render: function () {
    const self = this;

    if (self._inModal) {
      self.$element.find(".plex-form-content").css({
        "max-height": $(window).height() * 0.8
      });
    }
  },

  _initEvents: function () {
    const self = this;

    if (self._inModal) {
      self._handleModalResizing();
    }
  },

  _handlePersistentBanner: function () {
    const bannerEnvElement = this.$element.prevAll(".plex-env-persistent-banner-container:first");
    const bannerElement = this.$element.prevAll(".plex-persistent-banner-container:first");
    const banner = bannerElement.data("banner");
    const $form = this.$element;

    if (banner) {
      const originalTop = this.$element.position().top;
      const bannerEnvElementHeight = typeof bannerEnvElement.height() === "undefined" ? 0 : bannerEnvElement.height();
      const adjustTop = () => {
        $form.css("top", originalTop + bannerEnvElementHeight + bannerElement.height() + "px");
      };

      banner.messages.subscribe(adjustTop);
      adjustTop();
    }
  },

  _handleModalResizing: function () {
    const self = this;
    const $resizer = this.$element.closest(".ui-resizable");
    const $parent = this.$element.closest(".modal-dialog");
    const $modalFormContent = this.$element.find(".plex-form-content");
    const $modalFormContentWrapper = this.$element.find(".plex-form-content-wrapper");
    const $modalBody = $parent.find(".modal-body");
    const $modalActionBar = $parent.find(".plex-actions-wrapper");
    const $modalFormActions = this.$element.find(".plex-form-buttons");

    if (!$resizer.length) {
      return;
    }

    const resizeForm = function () {
      $modalFormContent.css({
        "max-height": ""
      });

      const modalBodyHeight = $modalBody.outerHeight();
      const actionBarHeight = $modalActionBar.outerHeight(true);
      const formActionHeight = $modalFormActions.outerHeight(true);
      const formMargin = parseInt(self.$element.css("margin-top"), 10);

      // form y-axis scrollbar height
      const formScrollbar = $modalFormContentWrapper.outerHeight() - $modalFormContent.outerHeight();

      const availableFormContentHeight =
        modalBodyHeight - actionBarHeight - formActionHeight - formMargin - formScrollbar;

      if (self._isFormContainer) {
        // Always get thse elements on resize in case user reloaded with fragment
        const $formRowContainers = self.$element.find(".plex-form-row-container");
        const $formContainerWrapper = self.$element.find(".plex-form-content-wrapper");
        const currentFormContentHeight = $formContainerWrapper.height();

        $formRowContainers.each(function () {
          const $formRowContainer = $(this);
          $formRowContainer.height(
            ($formRowContainer.height() / currentFormContentHeight) * availableFormContentHeight
          );
        });
      } else if (self._inElementList) {
        // try adjust the last element's height
        const $elementList = self.$element.closest(".plex-element-list");
        const currentListHeight = $elementList.height();

        let $item = $elementList.find(".plex-form-content, .plex-grid").last();
        if ($item.length && $item.hasClass("plex-grid")) {
          $item = $item.closest(".plex-grid-wrapper");
          if ($item.length) {
            $item.css({
              "max-height": $item.height() + availableFormContentHeight - currentListHeight + "px",
              height: "auto"
            });
          }
        } else if ($item.length) {
          $item.css({ "max-height": "" });
          $item.height($item.height() + availableFormContentHeight - currentListHeight);
        }
      } else {
        // Single Form
        $modalFormContent.height(availableFormContentHeight);
      }
    };

    pubsub.subscribe("open.dialog", resizeForm);
    $resizer.on("resize", resizeForm);
  }
};

$.fn.form = function (options) {
  return this.each(function () {
    const $this = $(this);
    const data = $this.data("form");

    if (!data) {
      $this.data("form", new Form(this, options));
    }
  });
};

$.fn.form.Constructor = Form;
