const ko = require("knockout");
const $ = require("jquery");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const plexImport = require("../../../global-import");

const generateForLegacyRendering = (provider) => {
  const currentPage = plexImport("currentPage");

  // Convert object to plain array (it makes things easier)
  let controllers = $.map(currentPage, (controller) => {
    return controller;
  });

  // Filter controllers by context)
  controllers = controllers.filter((controller) => {
    return (
      controller.$element && provider.config.parent && provider.config.parent.$context.find(controller.$element).length
    );
  });

  const doc = new DocumentXml("plex-document");

  // only include those that can be serialized into xml
  controllers = controllers.filter((controller) => {
    return typeof controller.toDocumentXml === "function";
  });

  // make sure controller does not exist inside another controller
  controllers = controllers.filter((controller, index, ctrlArray) => {
    for (let i = 0; i < ctrlArray.length; i++) {
      if ($.contains(ctrlArray[i].$element[0], controller.$element[0])) {
        return false;
      }
    }

    return ko.unwrap(controller.config.printVisible);
  });

  // order array by element position.
  controllers = controllers.sort((a, b) => {
    if (
      a.$element.offset().top < b.$element.offset().top ||
      (a.$element.offset().top === b.$element.offset().top && a.$element.offset().left < b.$element.offset().left)
    ) {
      return -1;
    }

    return 1;
  });

  // serialize the valid controllers
  controllers.forEach((controller) => {
    doc.appendXml(controller.toDocumentXml());
  });

  return $.when(doc.serialize());
};

const shouldPrintLandscape = () => {
  const currentPage = plexImport("currentPage");
  let landscape = false;

  // this works for both rendering
  for (const id in currentPage) {
    if (Object.prototype.hasOwnProperty.call(currentPage, id)) {
      const ctrl = currentPage[id];

      // determine if a grid/calendar exists and default to landscape mode
      switch (ctrl.config?.viewName?.toLowerCase()) {
        case "_form":
        case "elements/_formcontent":
        case "elements/_formcontainer":
          // always use portrait for forms
          return false;
        case "_grid":
        case "_calendar":
          landscape = true;
          break;
        default:
        // continue
      }
    }
  }

  return landscape;
};

export const generateXmlFromPage = (provider) => {
  provider.documentProperty.landscape = provider.documentProperty.landscape ?? shouldPrintLandscape();

  const spa = plexImport("spa");
  if (spa?.isSpaRendering?.()) {
    return spa.getDocumentXml();
  }

  return generateForLegacyRendering(provider);
};
