ï»¿const Action = require("./plex-actions");
const nav = require("../Core/plex-navigate");
const dialogController = require("../Dialogs/plex-dialog-controller");
const plexExport = require("../../global-export");

const ViewDocument = Action.extend({
  onExecute: function (data) {
    // only pass data when it has been tokenized
    dialogController.create({
      route: nav.buildUrl("/Platform/DocumentControlSystem/ViewDocumentDialog", { NodeNo: data.NodeNo }),
      autoShow: true
    });
  }
});

module.exports = ViewDocument;
plexExport("actions.ViewDocument", ViewDocument);
