ï»¿const $ = require("jquery");
const plexExport = require("../../global-export");

const jsStyleRgx = /-[a-z]/gi;
function getJavaScriptStyleName(css) {
  return css.replace(jsStyleRgx, (match, index) => {
    return css.charAt(index + 1).toUpperCase();
  });
}

function toJavaScriptStyle(style) {
  /// <summary>Converts the style object to js style for the ko bindings.</summary>
  /// <param name="style">The style object.</param>

  const jsStyle = {};
  $.each(style, (css, value) => {
    jsStyle[getJavaScriptStyleName(css)] = value;
  });

  return jsStyle;
}

function toDashStyle(camelCaseString) {
  return camelCaseString.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

const api = {
  toJavaScriptStyle,

  toDashStyle,

  injectRule: function (rule) {
    /// <summary>Injects a new css rule into the document. The rule should be a valid css rule as a string.</summary>
    /// <param name="rule">The css rule</param>

    // https://css-tricks.com/snippets/javascript/inject-new-css-rules/
    $("<div />", {
      html: "&shy;<style>" + rule + "</style>"
    }).appendTo("body");
  },

  loadStylesheet: function (url, media) {
    // see if it already is loaded
    if ($("link[href='" + url + "']").length > 0) {
      return;
    }

    media = media || "screen";

    $("<link>")
      .attr("rel", "stylesheet")
      .attr("type", "text/css")
      .attr("href", url)
      .attr("media", media)
      .appendTo("head");
  }
};

module.exports = api;
plexExport("styles", api);
