const pageState = require("../../Core/plex-pagestate");
const plexExport = require("../../../global-export");

const stateKeys = {
  mainMenu: "lastMenuNodeKey",
  roleBasedMenu: "lastRoleBasedMenuNodeKey"
};

const api = {
  stateKeys,

  clearStates: function () {
    for (const key in stateKeys) {
      if (Object.prototype.hasOwnProperty.call(stateKeys, key)) {
        pageState.clearStateByKey(stateKeys[key]);
      }
    }
  },

  setMenuState: function (state, value) {
    for (const key in stateKeys) {
      if (Object.prototype.hasOwnProperty.call(stateKeys, key) && stateKeys[key] === state) {
        pageState.addToCurrentState(state, value);
        return;
      }
    }

    throw new Error("Invalid state for Menu State.");
  }
};

module.exports = api;
plexExport("MenuState", api);
