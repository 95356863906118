ï»¿const api = (module.exports = {});

const $ = require("jquery");
const jsUtils = require("../Utilities/plex-utils-js");
const stringUtils = require("../Utilities/plex-utils-strings");
const nav = require("../Core/plex-navigate");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const defaultOptions = {
  batch: false
};

let wordCache = {}; // private cache
let pendingWords = {}; // keep track of words that are pending
const glossaryActionRoute = "/Platform/Glossary/Glossarize";
const requestStack = [];

const addCacheCustomerWord = function (plexWord, customerWord) {
  /// <summary>
  /// Adds the specified glossary entry (plexus and customer word) to the glossary cache.
  /// </summary>
  /// <param name="plexWord">The plex word.</param>
  /// <param name="customerWord">The glossarized word for the current customer.</param>

  const key = getKey(plexWord);
  if (typeof key === "string") {
    wordCache[key] = customerWord || "";

    // remove from pending cache
    delete pendingWords[key];
  }
};

const loadWordCache = function (glossarizedWords) {
  /// <summary>
  /// Adds the specified glossary entries (plexus and customer word) to the glossary cache.
  /// </summary>
  /// <param name="glossarizedWords">Dictionary of plexus words mapped to customer words.</param>

  if (glossarizedWords) {
    Object.keys(glossarizedWords).forEach((plexusWord) => {
      addCacheCustomerWord(plexusWord, glossarizedWords[plexusWord] || plexusWord);
    });
  }
};

const getCacheCustomerWord = function (plexWord) {
  /// <summary>
  /// Gets the specified glossary entry from the glossary cache.
  /// </summary>
  /// <param name="plexusWord">The plexus word.</param>

  let customerWord;
  if (typeof plexWord !== "string") {
    return "";
  }

  const cacheWord = wordCache[getKey(plexWord)];
  if (cacheWord && cacheWord.trim() !== "") {
    customerWord = cacheWord;
  } else {
    customerWord = "";
  }

  return customerWord;
};

const replaceTokens = function (word, tokenData) {
  /// <summary>
  /// Replaces the provided tokenized string with the token data provided. The tokens are numerical
  /// representations with a 1-based index.
  /// </summary>
  /// <param name="word">The word to update.</param>
  /// <param name="tokenData">An array of strings to replace the tokens contained within the string.</param>
  /// <returns>Returns the word with the tokens replaced by their proper values. Returns an empty string if the tokenized word is invalid.</returns>

  // verify word is valid
  if (typeof word !== "string" || !word) {
    return "";
  }

  return stringUtils.format(word, tokenData);
};

const createResultsFromCache = function (plexWords, iterationCallback) {
  /// <summary>
  /// Creates a glossary result dictionary from the specified plex words array.
  /// </summary>
  /// <param name="plexWords">The plex words array.</param>
  /// <param name="iterationCallback">Callback method executed for each element in plexWords.</param>
  /// <returns>A dictionary of customer words keyed by the specified plex words.</returns>

  const glossarizedWords = {};

  plexWords.forEach((value) => {
    let plexWord = "";
    let tokens = [];

    if (typeof value === "string") {
      plexWord = value;
    } else {
      plexWord = value.text;
      tokens = value.tokens || [];
    }

    const cacheWord = getCacheCustomerWord(plexWord);
    const customerWord = cacheWord || plexWord;
    glossarizedWords[plexWord] = tokens.length === 0 ? customerWord : replaceTokens(customerWord, tokens);

    if (typeof iterationCallback === "function") {
      iterationCallback(plexWord, cacheWord, customerWord);
    }
  });

  return glossarizedWords;
};

function wordIsPending(word) {
  const key = getKey(word);
  return key in pendingWords && !pendingWords[key].batched;
}

function wordIsCached(word) {
  return getKey(word) in wordCache;
}

function generateRequest(request, words, resolver, batch) {
  if (resolver) {
    requestStack.push({ words, resolve: resolver });
  }

  // if requests are pending for the given words, do not make an additional request
  // just add it to the request stack and it will be resolved once the original
  // request is returned
  if (!words.every(wordIsPending)) {
    request.plexWords.forEach((word) => {
      pendingWords[getKey(word)] = { word, batched: !!batch };
    });

    if (batch) {
      jsUtils.defer(generateBatchRequest);
    } else {
      nav.post(glossaryActionRoute, { glossaryRequest: request }, { showOverlay: false }).then((data) => {
        loadWordCache(data);
        resolvePendingRequests();
      });
    }
  }
}

function generateBatchRequest() {
  // get all unbatched requests
  const words = Object.keys(pendingWords)
    .filter((key) => pendingWords[key].batched)
    .map((key) => pendingWords[key].word);

  if (words.length > 0) {
    generateRequest({ plexWords: words, application: plexImport("currentApplication") }, words);
  }
}

function resolvePendingRequests() {
  // resolves all pending requests that can be resolved
  let i = 0;
  let request;

  // note that we are intentionally not caching length because it will change
  // we are also intentionally processing these FIFO
  while (i < requestStack.length) {
    request = requestStack[i];
    if (request.words.every(wordIsCached)) {
      request.resolve(createResultsFromCache(request.words));
      requestStack.splice(i, 1);
      continue;
    }

    i++;
  }
}

function getKey(word) {
  if (typeof word === "string") {
    return word.toLowerCase();
  }

  return word.text.toLowerCase();
}

api.load = loadWordCache;
api.clear = function () {
  /// <summary>Clears the client glossary cache</summary>

  // the main purpose of this method is to clear the cache for testing
  wordCache = {};
  pendingWords = {};
};

api.getCustomerWordBulkAsync = function (plexWords, options) {
  /// <summary>
  /// Gets the customer words for the specified array of plex words.  If any of the plex words are not already in cache an ajax call to glossary
  /// is first made to look them up before adding them to the cache and returning.
  /// </summary>
  /// <param name="plexWords">
  /// An array of plex words to glossarize.
  /// Each array element can either be a plain string like "Foo", or it can be an object
  /// of the form '{ text: "This is {1} {2}.", tokens: ["Foo", "Bar"] }' if the string is tokenized.
  /// </param>
  /// <param name="options">
  /// Additional options that can be used when generating the glossary request. They include
  ///   - application: the application associated with the glossary terms (default plex.currentApplication)
  ///   - batch: will batch sequential requests into a single request (default: false)
  /// </param>
  /// <returns>A dictionary of customer words keyed by the specified plex words.</returns>

  const defer = new $.Deferred();
  let cached = true;
  const postWords = [];
  let postData;

  // convert single arguments to an array for consistency.
  const plexWordArray = Array.isArray(plexWords) ? plexWords : [plexWords];

  options = $.extend({}, defaultOptions, options);
  options.application = options.application || plexImport("currentApplication");

  const glossarizedWords = createResultsFromCache(plexWordArray, (plexWord, cacheWord) => {
    if (!cacheWord) {
      postWords.push(plexWord);
      cached = false;
    }
  });

  // todo: the error page does not have current application injected so glossary checks fail
  // this is a short term fix - the error page should probably have the current application
  // injected which would represent the error application
  if (cached || !options.application || Object.keys(options.application).length === 0) {
    defer.resolve(glossarizedWords);
  } else {
    // composite object of all plex words to glossarize and the application they will be mapped to.
    postData = { plexWords: postWords, application: options.application };
    generateRequest(postData, plexWordArray, defer.resolve, options.batch);
  }

  return defer.promise();
};

api.getCustomerWordAsync = function (plexWord, options) {
  /// <summary>
  /// Gets the customer word for the specified plex word.  If the plex word is not already in cache an ajax call to glossary
  /// is first made to look it up before adding it to the cache and returning.
  /// </summary>
  /// <param name="plexWord">
  /// The plex word to glossarize.
  /// This can either be a plain string like "Foo", or it can be an object
  /// of the form '{ text: "This is {1} {2}.", tokens: ["Foo", "Bar"] }' if the string is tokenized.
  /// </param>
  /// <param name="options">
  /// Additional options that can be used when generating the glossary request. They include
  ///   - application: the application associated with the glossary terms (default plex.currentApplication)
  ///   - batch: will batch sequential requests into a single request (default: false)
  /// </param>
  /// <returns>The customer word for specified plex word.</returns>

  return this.getCustomerWordBulkAsync(plexWord, options).then((results) => {
    return results[typeof plexWord === "string" ? plexWord : plexWord.text];
  });
};

plexExport("globalization.Glossary", api);
