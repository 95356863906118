ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

// todo: this provides a low level way to attach computed properties to records
// this currently leads to redundant processing, so we need to find out other areas
// that add computed properties and remove redundant calls - this will require some IA
ko.extenders.computedProperties = function (target, config) {
  if (!Array.isArray(config) || config.length === 0) {
    return target;
  }

  const properties = config.map((cp) => {
    return cp.name;
  });
  const obj = target.peek();
  let sub;

  if (Array.isArray(obj)) {
    // track initial records
    obj.forEach((record) => {
      dataUtils.trackObject(record, config, properties);
    });

    // make sure that new records that get added also get tracked
    sub = target.subscribe(
      (changes) => {
        const newRecords = changes
          .filter((change) => {
            return change.status === "added" && !("moved" in change);
          })
          .map((change) => {
            return change.value;
          });

        newRecords.forEach((record) => {
          dataUtils.trackObject(record, config, properties);
        });
      },
      null,
      "arrayChange"
    );
  } else {
    dataUtils.trackObject(obj, config, properties);
    sub = target.subscribe((value) => {
      if (value && typeof value === "object") {
        dataUtils.trackObject(value, config, properties);
      }
    });
  }

  const originalDispose = target.dispose;
  target.dispose = function () {
    sub.dispose();
    if (originalDispose) {
      originalDispose.call(target);
    }
  };

  return target;
};
