ï»¿const ko = require("knockout");
const $ = require("jquery");
const expressions = require("../../Expressions/plex-expressions-compiler");
const featureApplicator = require("../../Features/plex-feature-applicator");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const CellWriter = require("./plex-grid-cell-writer");
const plexExport = require("../../../global-export");

function getElementId(column, index) {
  // create a predictable id that will be the same across children
  return "grid_mastercolumn_" + column.master.id + "_" + index;
}

function addColumnCss(column, features) {
  if (column.css) {
    features.css = features.css || [];
    features.css.push(column.css);
  }
}

function wrapFeatures(id, featureObservable) {
  const wrapped = ko.computed(() => {
    // todo: the conditional logic is because the feature evaluation may
    // just return a boolean. It would be a lot simple if it always returned the same
    // type or at least not `true` if empty, which is the issue here.
    const results = ko.isObservable(featureObservable) ? featureObservable() : {};
    results.attr = results.attr || {};
    results.attr.id = id;
    return results;
  });

  if (ko.isComputed(featureObservable)) {
    const originalDispose = wrapped.dispose;
    wrapped.dispose = function () {
      featureObservable.dispose();
      originalDispose.call(wrapped);
    };
  }

  return wrapped;
}

function updateFeatureProcessorId(id, featureProcessor) {
  if (featureProcessor != null) {
    featureProcessor.setElementId(id);
  }
}

const MasterCellWriter = CellWriter.extend({
  init: function () {
    const self = this;
    this._base.apply(this, arguments);

    this.trackClientUpdates = this.column.valueProvider.config.trackClientUpdates;
    this.subscriptions = [];

    this.columns = (this.column.columns || []).slice(0);
    if (this.column.defaultColumn) {
      this.columns.push({ column: this.column.defaultColumn });
    }

    // make sure to account for features that are applied to child columns
    this.columns.forEach((config) => {
      const child = config.column;
      if (child.features && child.features.length > 0) {
        child.featureProcessor = new FeatureProcessor(child.features, child, self.parent);
      }

      // the default column will not have an expression so just return true
      config.evaluator = config.expression
        ? expressions.compile(config.expression)
        : function () {
            return true;
          };
    });

    // preserve the reference to the master column
    // we will be swapping this out for each row
    this.masterColumn = this.column;
  },

  prerender: function (record, index, records, rowConfig, groupIndex, group, colIndex) {
    const self = this;
    // update to the current layout
    this.setCurrentLayout(record, index);
    if (!this.trackClientUpdates) {
      return this._base.apply(this, arguments);
    }

    const args = arguments;
    const id = getElementId(this.column, index);
    let currentColumn;
    let baseMethod = this._base;
    updateFeatureProcessorId(id, this.featureProcessor);
    let featureObservable = baseMethod.apply(this, arguments);
    if (featureObservable !== false) {
      // the id needs to be set to something predictable so we can conditionally replace the content
      this.features.attr.id = id;

      // this will serve as a trigger to dynamically swap out layout and force content to change
      currentColumn = ko.computed(() => {
        return self.getCurrentColumn(record, index);
      });

      // add id to feature
      featureObservable = wrapFeatures(id, featureObservable);

      const subscription = currentColumn.subscribe((column) => {
        const $el = $(document.getElementById(id));
        let features = featureObservable();

        // the layout is switching so we need to stop
        // observing changes for any existing features
        featureObservable.dispose();

        self.disposeByIndex(index);

        if ($el.length === 0) {
          // the element is gone so we can dispose and get out
          currentColumn.dispose();
          baseMethod = null;
          return;
        }

        // unapply any prior features
        addColumnCss(self.column, features);
        featureApplicator.remove($el, features);

        self.column = column;
        self.featureProcessor = column.featureProcessor;
        updateFeatureProcessorId(id, self.featureProcessor);
        // replace with the new observable provided by the prerender
        featureObservable = wrapFeatures(id, baseMethod.apply(self, args));

        features = featureObservable();
        addColumnCss(column, features);

        // replace content with cell content
        features.content = features.content || column.writer.getInnerHtml(record, index, colIndex);
        featureApplicator.update($el, features);
      });

      if (index in this.subscriptions) {
        this.subscriptions[index].dispose();
      }

      this.subscriptions[index] = subscription;
    }

    return featureObservable;
  },

  setCurrentLayout: function (record, index) {
    this.column = this.getCurrentColumn(record, index);
    this.featureProcessor = this.column.featureProcessor;
  },

  getCurrentColumn: function (record, index) {
    if (record) {
      let i = 0;
      const ln = this.columns.length;
      let layout;

      for (; i < ln; i++) {
        layout = this.columns[i];
        if (layout.evaluator(record, index)) {
          return layout.column;
        }
      }
    }

    // should never get here
    return this.masterColumn.defaultColumn;
  },

  disposeByIndex: function (index) {
    if (this.masterColumn.elementCollection && this.masterColumn.elementCollection[index]) {
      this.masterColumn.elementCollection[index].forEach((el) => {
        if (el.controller && "dispose" in el.controller) {
          el.controller.dispose();
        }
      });

      delete this.masterColumn.elementCollection[index];
    }
  }
});

module.exports = MasterCellWriter;
plexExport("grid.writers.MasterCellWriter", MasterCellWriter);
