ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const bindingHandler = require("./plex-handler-bindings");
const jsUtils = require("../Utilities/plex-utils-js");
const dataUtils = require("../Utilities/plex-utils-data");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const expressionUtils = require("../Expressions/plex-expressions-compiler");
const controllerFactory = require("./plex-controller-factory");
const repository = require("./plex-model-repository");
const onReady = require("./plex-handler-page").onReady;
const PickerController = require("./plex-controller-picker");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");
require("../Knockout/knockout-semaphore"); // eslint-disable-line import/no-unassigned-import

function filterSelectedItems(data, selected, elementConfig) {
  const propertyName = elementConfig.valuePropertyName || elementConfig.propertyName;

  // note: since these are javascript objects the instances of selected objects will
  // not be the same instance. We need to find that instance in the data collection
  // and include it in the selected array.
  return data.filter((a) => {
    return selected.some((b) => {
      // note: equality check needs to ignore type for use with visionplex array-based data sources
      // eslint-disable-next-line eqeqeq
      return a[propertyName] == b[propertyName];
    });
  });
}

function populateData(dataSource, model) {
  let promise = null;
  if (dataSource.propertyName) {
    if (dataSource.sourceId) {
      if (repository.get(dataSource.sourceId)) {
        dataSource.data = dataUtils.getValue(repository.get(dataSource.sourceId), dataSource.propertyName);
      } else {
        const deferred = new $.Deferred();
        onReady(() => {
          deferred.resolve(dataUtils.getValue(repository.get(dataSource.sourceId), dataSource.propertyName) || []);
        });

        promise = deferred.promise();
      }
    } else {
      dataSource.data = dataUtils.getValue(model, dataSource.propertyName);
    }
  }

  dataSource.data = dataSource.data || [];
  return promise;
}

const SelectController = function (config, model) {
  if (config.DataSource && config.DataSource.length >= 10) {
    return new PickerController(config);
  }

  this.config = config;
  this.model = model;
  this.init();
  return this;
};

SelectController.prototype = {
  constructor: SelectController,

  init: function () {
    const self = this;
    this.selected = this.config.selected;
    this.isLoading = ko.semaphore(false);

    const selected = this.selected();
    this._disposables = [];

    if (self.config.includeEmpty) {
      self.optionsCaption = ko.observable(self.config.placeholder);
    }

    if (!self.config.dataSource) {
      // todo: throw?
      notify.error({
        text: "The data value has not been set on the {1} element.",
        tokens: [self.config.handle || self.config.displayPropertyName]
      });
      return;
    }

    if (this.config.displayTextExpression) {
      this.displayTextEvaluator = expressionUtils.compile(this.config.displayTextExpression);
    } else {
      this.displayTextEvaluator = function (item) {
        return item[self.config.displayPropertyName];
      };
    }

    this.setupDataSource();

    if (selected.length > 0) {
      if (this.remoteData) {
        // keep a copy - we will want to apply these when data is initially loaded
        this.initialSelections = selected.slice(0);
        this.datasource.load(selected.slice(0));
      }

      this.config.selected(filterSelectedItems(this.data(), selected, this.config));
    }

    // this is a pointer to the array used by the binding for non-multi-select dropdowns
    // the main purpose of this is to allow the `allowValueUnset` feature to work as it
    // does not work with the `selectedOptions` binding
    this.selectedValue = ko.computed({
      read: function () {
        // see if dummy item was selected and if so remove it
        if (self.selected().length > 0 && self.selected()[0] === undefined) {
          self.selected().shift();
        }

        return filterSelectedItems(self.data(), self.selected(), self.config)[0];
      },
      write: function (value) {
        if (!value) {
          self.selected.removeAll();
        } else if (self.selected.indexOf(value) === -1) {
          self.selected([value]);
        }
      }
    });

    this.config.displayValue = ko.computed(this.getSelectedValue, this);
    this._disposables.push(this.config.displayValue);
    this._disposables.push(this.config.boundValue);
    this._disposables.push(this.selected.subscribe(this.onSelectedChanged, this));
    this._disposables.push(this.datasource.source.subscribe(this._initData, this));

    // make sure that there is an item selected
    if (this.config.selected().length === 0 && !this.config.includeEmpty && this.data().length > 0) {
      this.config.selected.push(this.data()[0]);
    }

    // when first item is selected, make sure model is updated to selected value
    jsUtils.defer(self.applyBindings, self);
  },

  onSelectedChanged: function (values) {
    if (this.config.bindings && this.config.bindings.length > 0) {
      bindingHandler.update(this.config.bindings, values && values.length > 0 ? values : null);
    }
  },

  setupDataSource: function () {
    if (!this.config.dataSource) {
      return;
    }

    // use route for external config if exists
    const self = this;
    const ds = this.config.dataSource.route || this.config.dataSource;

    const promise = populateData(ds, this.model);
    if (promise) {
      const selected = ko.utils.peekObservable(this.selected) || [];
      promise.done((data) => {
        self.datasource.data(data);
        if (selected.length > 0) {
          self.selected(filterSelectedItems(self.data(), selected, self.config));
        }
      });
    }

    ds.initializer = this._initData.bind(this);

    this.datasource = dataSourceFactory.create(ds);
    this.data = this.datasource.data;
    this.remoteData = this.config.dataSource.route != null;

    if (this.remoteData) {
      // delay execution so dependencies have a chance to process
      onReady(() => {
        // if the dropdown is re-rendered need to restore selected values
        if (self.selected().length > 0 && !self.initialSelections) {
          self.initialSelections = self.selected().slice(0);
          // when first item is selected, make sure model is updated to selected value
          jsUtils.defer(self.applyBindings, self);
        }

        // wrap execution in computed - this will allow us to trigger a reload whenever a dependency changes
        const loader = ko.computed(self.load, self);

        // whenever a dependency changes, trigger a load
        loader.subscribe(self.load, self);
        self._disposables.push(loader);
      });
    }
  },

  prepareRecords: function (data) {
    const self = this;
    data
      .filter((record) => {
        return !record.$$initialized;
      })
      .forEach((record) => {
        // setup viewable text
        if (ko.isObservable(record.$$displayText) && typeof record.$$displayText.dispose === "function") {
          record.$$displayText.dispose();
        }

        record.$$initialized = true;
        dataUtils.trackObject(record);

        record.$$displayText = ko.computed(() => {
          return self.displayTextEvaluator(record);
        });
      });
  },

  _initData: function (data) {
    const self = this;

    self.prepareRecords(data);
  },

  load: function () {
    const self = this;
    this.isLoading(true);

    this.datasource
      .get()
      .done((results) => {
        if (results.length === 0) {
          self.selected.removeAll();
        }

        if (results.length > 0 && self.initialSelections) {
          // now that we have data we can apply the initial selections
          self.selected(filterSelectedItems(results, self.initialSelections, self.config));
          self.config.initialDisplayValue = self.getSelectedValue();
          self.initialSelections = null;
        }

        if (self.selected().length === 0 && results.length > 0 && !self.config.includeEmpty) {
          // make sure that something is selected
          if (self.config.multiSelect) {
            self.selected.push(self.datasource.source()[0]);
          } else {
            self.selected([self.datasource.source()[0]]);
          }
        }
      })
      .always(() => {
        self.isLoading(false);
      });
  },

  getSelectedValue: function () {
    const self = this;
    return this.selected()
      .map((item) => {
        return item.$$displayText ? item.$$displayText() : self.displayTextEvaluator(item);
      })
      .join(self.config.displayValueDelimiter || ", ");
  },

  setValue: function (value) {
    if (dataUtils.isEmpty(value)) {
      this.selected.removeAll();
      return;
    }

    const arrayOfValue = Array.isArray(value) ? value : [value];
    const propertyName = this.config.valuePropertyName || this.config.propertyName;

    const normalizedValues = arrayOfValue.map((item) => {
      if (typeof item !== "object") {
        // if a key is passed in use that to resolve value
        return { [propertyName]: item };
      }

      if (!(propertyName in item) && "boundValue" in item) {
        // values coming from initial value feature will be in boundValue/boundDisplayValue format
        return { [propertyName]: item.boundValue };
      }

      return item;
    });

    const selected = filterSelectedItems(this.data(), normalizedValues, this.config);
    this.selected(selected);

    jsUtils.defer(this.applyBindings, this);
  },

  applyBindings: function () {
    if (this.config.bindings && this.config.bindings.length > 0 && this.selected().length > 0) {
      bindingHandler.update(this.config.bindings, this.selected());
    }
  },

  getState: function () {
    return {
      selected: this.selected()
    };
  },

  restoreState: function (state) {
    const self = this;
    const propertyName = self.config.valuePropertyName || self.config.propertyName;

    const setSelected = function (delayedByLoading) {
      if (self.data().length > 0 && state && state.selected && state.selected.length > 0) {
        if (self.remoteData && !delayedByLoading) {
          self.initialSelections = state.selected.slice(0);
          self.datasource.load(state.selected.slice(0));
        } else {
          self.selected(
            self.data().filter((item) => {
              return state.selected.some((stateItem) => {
                // just do coersive check in case we lose type during serialization
                return stateItem[propertyName] == item[propertyName]; // eslint-disable-line eqeqeq
              });
            })
          );
        }
      } else {
        self.selected.removeAll();
      }
    };

    if (this.isLoading()) {
      this.isLoading.subscribeOnce(() => {
        setSelected(true);
      });
    } else {
      setSelected(false);
    }
  },

  dispose: function () {
    let subscription;
    while ((subscription = this._disposables.pop())) {
      subscription.dispose();
    }
  }
};

// factory method
SelectController.create = function (config, model) {
  if (!config.propertyName) {
    notify.error({
      text: "The data value has not been set on the {1} element.",
      tokens: [config.handle || config.displayPropertyName]
    });

    return undefined;
  }

  // if we have too many a picker will be rendered
  if (config.exceedsRecordLimit) {
    config.autoPick = true;
    config.autoSelectSingleResult = true;

    // setup display property parameter
    // todo: verify this is working
    config.dataSource.parameters = [];
    config.dataSource.parameters.push({
      name: config.displayPropertyName,
      binding: {
        targetKey: config.id,
        targetPropertyName: config.displayPropertyName
      }
    });

    if (config.selected && config.selected().length > 0) {
      populateData(config.dataSource);
      const selected = filterSelectedItems(config.dataSource.data, config.selected(), config);
      if (selected.length === 0 && !config.includeEmpty) {
        config.selected([config.dataSource.data[0]]);
      } else if (selected.length === config.selected().length) {
        // pause to avoid firing change events
        // technically the "value" is the same
        // even though the underlying object is
        // different
        config.selected.pause();
        config.selected(selected);
        config.selected.resume();
      } else {
        config.selected(selected);
      }
    } else if (!config.includeEmpty) {
      populateData(config.dataSource);
      // Set Default Selection to first item similiar to drop down behavior.
      config.selected([config.dataSource.data[0]]);
    }

    config.columns = [config.displayColumn];
    return PickerController.create.apply(null, arguments);
  }

  return new SelectController(config, model);
};

controllerFactory.register("Elements/_Select", SelectController);

module.exports = SelectController;
plexExport("SelectController", SelectController);
