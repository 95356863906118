ï»¿const $ = require("jquery");
const Mousetrap = require("mousetrap");
const plexExport = require("../../global-export");

const defaults = {
  bindGlobal: false
};

const bindToElementDefaults = {
  preventDefault: true
};

const hotkeys = {
  addHotKey: function (svKey, options, oFunctionToCall) {
    if (typeof options === "function") {
      oFunctionToCall = options;
      options = {};
    }

    let mousestrapFunc;
    const settings = $.extend(defaults, options);

    if (settings.bindGlobal) {
      mousestrapFunc = Mousetrap.bindGlobal;
    } else {
      mousestrapFunc = Mousetrap.bind;
    }

    if (svKey === "f1" && "onhelp" in window) {
      // https://stackoverflow.com/questions/3405412
      // Disable default F1 help popup in IE
      window.onhelp = function () {
        return false;
      };
    }

    mousestrapFunc(svKey, (e) => {
      const results = oFunctionToCall(e) || false;

      if (!results) {
        // prevent default
        if (e.preventDefault) {
          e.preventDefault();
        }

        e.returnValue = results;
      }

      return results;
    });
  },

  addElementHotKey: function (element, key, options, functionToCall) {
    const settings = $.extend(bindToElementDefaults, options);
    const mousetrap = new Mousetrap(element);

    mousetrap.bind(key, (e) => {
      const results = functionToCall(e) || false;

      if (settings.preventDefault || !results) {
        // prevent default
        if (e.preventDefault) {
          e.preventDefault();
        }

        e.returnValue = false;
      }

      return results;
    });
  },

  removeHotKey: function (svKey) {
    Mousetrap.unbind(svKey);

    if (svKey === "f1" && "onhelp" in window) {
      window.onhelp = null;
    }
  }
};

module.exports = hotkeys;
plexExport("hotkeys", hotkeys);
