ï»¿const $ = require("jquery");
const StringBuilder = require("../../Utilities/plex-utils-stringbuilder");
const actionHandler = require("../../Controls/plex-handler-action");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const Writer = require("./plex-grid-writer-base");
const plexExport = require("../../../global-export");

const CellWriter = Writer.extend({
  init: function (parent, _options, column) {
    this.column = column;
    this.parent = parent;
    if (column.features && column.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(column.features, column, this.parent);
    }
  },

  render: function (writer, record, index, _data, colIndex, groupIndex, group) {
    if (this.column.css) {
      this.features.css.push(this.column.css);
    }

    if (group && "$$collapsed" in group && colIndex === 0) {
      $.extend(this.features.attr, { "data-indentation-level": group.groupIndex + 1 });
      $.extend(this.features.style, { "padding-left": (group.groupIndex + 1) * 15 + "px" });
    }

    const td = writer.appendChild("td").addAttr("data-col-index", colIndex);
    if (this.column.colspan > 1 && !("colspan" in this.features.attr)) {
      this.features.attr.colspan = this.column.colspan;
    }

    td.addFeature(this.features);

    if (groupIndex === 0) {
      this._renderGroupToggleAll(writer, group);
    }

    if (this.features.content) {
      td.appendHtml(this.features.content);
    } else {
      td.appendHtml(this.getInnerHtml(record, index, colIndex));
    }

    td.close();
  },

  renderDiv: function (writer, record, index, _data, colIndex, groupIndex, group) {
    if (this.column.css) {
      this.features.css.push(this.column.css);
    }

    const div = writer.appendChild("div").addAttr("data-col-index", colIndex);

    this.writeCss(div, this.features.css);
    this.writeAttributes(div, this.features.attr);
    this.writeStyle(div, this.features.style);

    if (groupIndex === 0) {
      this._renderGroupToggleAll(div, group);
    }

    if (this.features.content) {
      div.appendHtml(this.features.content);
    } else {
      div.appendHtml(this.getInnerHtml(record, index, colIndex));
    }

    div.close();
  },

  getInnerHtml: function (record, index, colIndex) {
    const html = formatText(this.column.valueProvider.getHtml(record, index, colIndex, this.features));
    let displayHtml = html || this.column.valueProvider.getEmptyHtml();

    if (
      this.column.action &&
      !this.column.action.disabled() &&
      (!this.features || (this.features && !this.features.disabled)) &&
      displayHtml
    ) {
      displayHtml = this._getLinkHtml(displayHtml, record, index, colIndex);
    }

    if (this.column.asidePropertyName) {
      displayHtml += this._generateAside(record);
    }

    return displayHtml;
  },

  _generateAside: function (record) {
    const value = record[this.column.asidePropertyName];
    if (value) {
      return "<div class='plex-aside'>" + value + "</div>";
    }

    return "";
  },

  _getLinkHtml: function (value, record, index, colIndex) {
    const href = actionHandler.buildLinkUrl(this.column.action, record, index);
    if (!href) {
      // no url so just return value
      return value;
    }

    const writer = new StringBuilder();
    writer.append("<a href='");
    writer.append(href);

    writer.append("' class='plex-grid-link' data-col-index='");
    writer.append(colIndex);
    writer.append("'>");

    writer.append(value);

    writer.append("</a>");
    return writer.toString();
  },

  _renderGroupToggleAll: function (writer, group) {
    const toggleGroup = this._getToggleAllGroup(group);
    if (toggleGroup) {
      writer.appendHtml(this.column.valueProvider.getToggleAllHtml(toggleGroup));
      toggleGroup.renderedToggleAll = true;
    }
  },

  _getToggleAllGroup: function (group) {
    const self = this;

    if (group) {
      if (group.selectionState && !group.renderedToggleAll && self.column.propertyName === group.config.propertyName) {
        return group;
      } else if (group.parent) {
        return self._getToggleAllGroup(group.parent);
      }
    }

    return null;
  }
});

function formatText(text) {
  if (text && typeof text === "string") {
    return text.replace(/\r?\n/g, "<br />");
  }

  if (text == null) {
    return "";
  }

  return String(text);
}

module.exports = CellWriter;
plexExport("grid.writers.CellWriter", CellWriter);
