const autobahn = require("autobahn");
const $ = require("jquery");
const logger = require("../Core/plex-logger");
const { isAuthorizationError } = require("./wamp-errors");

const CONNECTION_TIMEOUT = 10 * 1000; // 10 seconds
const HANDLER_TARGET_ID = "plex_protocol_handler_target";
let versionWarned = false;

function normalizer() {
  if (arguments.length !== 1) {
    return new autobahn.Results([...arguments]);
  }

  const arg = arguments[0];
  return arg instanceof autobahn.Result ? arg : new autobahn.Result([arg]);
}

/**
 * Creates a random token.
 */
function createToken() {
  const browserCrypto = window.crypto || window.msCrypto;

  if (browserCrypto) {
    const arr = new Uint8Array(11);
    browserCrypto.getRandomValues(arr);
    return [].map.call(arr, (n) => n.toString(16)).join("");
  }

  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

// TODO: may want to normalize this based on implementations like the one
// found on https://gist.github.com/aaronk6/d801d750f14ac31845e8
let invokeHandler = (uri) => {
  let target = document.getElementById(HANDLER_TARGET_ID);
  if (!target) {
    // Firefox seems to close all websocket traffic
    // when it detects a link being invoked - we are
    // circumventing that by targeting the link into
    // a hidden iframe.
    target = document.createElement("iframe");
    target.name = target.id = HANDLER_TARGET_ID;
    target.style.display = "none";
    document.body.appendChild(target);
  }

  const link = document.createElement("a");
  link.href = uri;
  link.target = HANDLER_TARGET_ID;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

if (typeof navigator.msLaunchUri === "function") {
  // This will be used by IE10+
  invokeHandler = (uri) => {
    navigator.msLaunchUri(
      uri,
      () => {},
      () => {
        logger.warn("Failed to launch handler for " + uri);
      }
    );
  };
} else if (window.chrome) {
  // This will be used by Chrome & modern Edge
  invokeHandler = (uri) => {
    window.location.assign(uri);
  };
}

const userTokenPlaceholder = {};

const openConnection = (connection) => {
  const deferred = $.Deferred();

  try {
    connection.onopen = (session, details) => {
      deferred.resolve([session, details]);
    };

    connection.onclose = (reason) => {
      deferred.reject(new Error(reason));
    };

    if (!connection.isRetrying) {
      connection.open();
    }
  } catch (err) {
    deferred.reject(err);
  }

  return deferred.promise();
};

// This class is only needed to support fallback connections since we no longer need wss and can use localhost.
// Autobahn has a notion of multiple transports, but does not treat them as fallbacks. If they address this issue
// we can remove this logic and rely on their fallback mechanism. (Monitor: https://github.com/crossbario/autobahn-js/issues/191)
class WampConnectionManager {
  constructor(urls, realm) {
    this.urls = urls;
    this.realm = realm;

    this.connections = [];
  }

  _getConnections() {
    if (this.connections.length === 0) {
      this.connections = this.urls.map(
        // eslint-disable-next-line camelcase
        (url) => new autobahn.Connection({ url, realm: this.realm, use_deferred: $.Deferred })
      );
    }

    return this.connections;
  }

  open() {
    const connections = this._getConnections();
    const currentConnection = connections.find((x) => x.isOpen);

    if (currentConnection) {
      this._connectionPromise = null;
      return $.when([currentConnection.session]);
    }

    if (!this._connectionPromise) {
      let validConnection = null;

      // autobahn doesn't gracefully handle a call to `open` while a previous
      // call is still pending - so we will cache the promise to resolve
      // with the prior request. (This will also prevent repeated retries
      // in the case of connection failures.)
      const deferred = $.Deferred();
      const promise = (this._connectionPromise = deferred.promise());

      const timeout = setTimeout(() => {
        if (deferred.state() === "pending") {
          deferred.reject(new Error("Connection timed out"));
        }
      }, CONNECTION_TIMEOUT);

      connections.forEach((connection) => {
        // Ignore rejection - let timeout indicate rejection
        openConnection(connection).then((args) => {
          validConnection = connection;
          deferred.resolve(args);
        });

        promise.always(() => {
          if (connection !== validConnection) {
            // Stop retrying failed connections
            connection.close();
          }
        });
      });

      promise.always(() => {
        clearTimeout(timeout);
        this._connectionPromise = null;
      });
    }

    return this._connectionPromise;
  }
}

class WampClient {
  constructor(connectionManager) {
    this.connectionManager = connectionManager;

    this.userToken = null;
    this.userTokenPlaceholder = userTokenPlaceholder;
    this.routedWithUserTokenOpts = Object.freeze({
      _routes: Object.freeze({
        strings: Object.freeze([userTokenPlaceholder])
      })
    });
  }

  /**
   * Checks if PCH version reported from last successful connection is at least ver. PCH Version
   * is only reported starting from v1.4.0. Pre-release suffixes are ignored in the comparison.
   * If called before the first successful connection, or when connecting to pre-v1.4.0 host, then
   * the version acts like v0.0.0.
   * @param {string} ver numbers separated by . without leading v.
   * @return {boolean} true if reported PCH version is at least as high as ver in each position.
   */
  pchVersionAtLeast(ver) {
    if (!this.pchVersion || typeof this.pchVersion !== "string") {
      return false;
    }

    if (this.pchVersion === "unknown") {
      // using a local build - assume latest
      return true;
    }

    const verNums = ver.split(".").map(Number);
    const pchNums = this.pchVersion.match(/\d+/g)?.map(Number);

    if (pchNums == null) {
      // may want to throw here - for now assume really early version
      if (!versionWarned) {
        versionWarned = true;
        logger.warn(`An unknown version of PCH "${this.pchVersion}" is detected.`);
      }

      return false;
    }

    for (let i = 0; i < verNums.length; i++) {
      const compare = (pchNums[i] || 0) - verNums[i];
      if (compare > 0) {
        return true; // PCH has higher version in position
      } else if (compare < 0) {
        return false; // PCH has lower version in position
      }
    }

    return true; // version was equal for all checked positions
  }

  /**
   * Returns an active WAMP session for the Component Host. This can fail if the
   * Component Host is not installed or accessible.
   * @returns {Promise<Session>} The session.
   */
  getSession() {
    return this.connectionManager.open().then(([session, details]) => {
      this.pchVersion = this.pchVersion ?? details?._pchVersion;
      return session;
    });
  }

  /**
   * Make an RPC call to the component host. These arguments are passed directly to Autobahn. See
   * https://github.com/crossbario/autobahn-js/blob/master/doc/reference.md#call
   *
   * @param {String} procedure
   * @param {Array<any>} args
   * @param {Object} kwargs
   * @param {Object} options
   * @returns {Promise<autobahn.Result>} A promise for the RPC result.
   */
  rpc(procedure, args, kwargs, options) {
    logger.info(`calling ${procedure} as user action? ${!!this.userToken}`);

    let keywordArgs = kwargs;
    if (this.userToken) {
      // eslint-disable-next-line camelcase
      keywordArgs = Object.assign({ user_token: this.userToken }, kwargs || {});
    }

    let optsPromise;
    const strings = options?._routes?.strings;
    if (Array.isArray(strings) && strings.indexOf(userTokenPlaceholder) !== -1) {
      if (this.pchVersionAtLeast("1.4")) {
        // avoid mutation of opts arg and nested objects/arrays
        const newOpts = {
          ...options,
          _routes: {
            ...options._routes,
            strings: [...strings]
          }
        };
        optsPromise = this.getPersistentUserToken().then((userToken) => {
          const newStrings = newOpts._routes.strings;
          newStrings[newStrings.indexOf(userTokenPlaceholder)] = userToken;
          return newOpts;
        });
      } else {
        // pre-1.4 does not support _routes, don't bother looking up
        // the persistent user token or sending the _routes obj.
        const { _routes: _, ...optsWithoutRoutes } = options;
        optsPromise = $.when(optsWithoutRoutes);
      }
    } else {
      optsPromise = $.when(options);
    }

    return optsPromise.then((opts) => {
      return this.getSession()
        .then((session) => session.call(procedure, args, keywordArgs, opts))
        .then(normalizer, (wampError) => {
          if (isAuthorizationError(wampError)) {
            // to distinguish the case of the status app not running vs. the cached persistent
            // token is invalid, we'd need to re-query the value of the token. For now, just clear
            // the token from the cache, and leave it up to the caller to handle.
            this.clearCachedPersistentUserToken();
          }

          return $.Deferred().reject(wampError);
        });
    });
  }

  /**
   * Invokes the "plex:" protocol handler with the provided URI and sends
   * a request to the associated plugin to get the a result. The plugin should
   * support a "get_handler_result" RPC endpoint.
   *
   * @param {String} uri
   * @param {String} pluginNs
   * @returns {Promise<autobahn.Result>} A promise for the RPC result.
   */
  invokeHandler(uri, pluginNs) {
    invokeHandler(uri);
    return this.rpc(`${pluginNs}.get_handler_result`, [uri]);
  }

  startUserInteraction(userToken) {
    logger.info("starting user interaction", userToken);
    this.userToken = userToken;
    invokeHandler(`plex:listen?token=${userToken}`);
  }

  endUserInteraction() {
    if (this.userToken) {
      logger.info("ending user interaction");
      this.userToken = null;
    }
  }

  /**
   * Invokes a protocol handler with some query params passed by promise. If any outPromise is
   * rejected, the entire return promise is rejected.
   * @param {string} protocolAndPath part of the uri before '?'
   * @param {object} inPromises hash of parameter names to values that should be passed by
   * promise.
   * @param {Array<string>} outPromises list of parameter names to be returned by promise
   * @param {any} params optional; additional static URI parameters
   * @return {Promise<object>} hash of the names from outPromises to the resolved promise value.
   */
  invokeHandlerPromises(protocolAndPath, inPromises, outPromises, params) {
    // I wish this lint wouldn't trigger unless the function actually references arguments:
    // eslint-disable-next-line no-param-reassign
    outPromises = outPromises || [];

    const setError = $.Deferred();
    let pendingSets = 0;
    const queryParams = $.extend({}, params);
    Object.keys(inPromises).forEach((key) => {
      pendingSets++;
      const pid = createToken();
      queryParams[key] = pid;
      this.rpc("com.plex.promises.set", [pid, inPromises[key]]).then(
        () => {
          pendingSets--;
          if (pendingSets === 0) {
            setError.resolve();
          }
        },
        (err) => {
          logger.warn("error setting promise for parameter " + key, err);
          setError.reject(err);
        }
      );
    });
    if (pendingSets === 0) {
      setError.resolve();
    }

    const outPromiseCalls = outPromises.map((key) => {
      const pid = createToken();
      queryParams[key] = pid;
      return this.rpc("com.plex.promises.get", [pid]);
    });

    invokeHandler(protocolAndPath + "?" + $.param(queryParams));

    return $.when(setError, ...outPromiseCalls).then((_set, ...args) => {
      const ret = {};
      args.forEach((callResult, i) => {
        ret[outPromises[i]] = callResult.args[0];
      });
      return ret;
    });
  }

  /**
   * @returns {Promise<string>}
   */
  getPersistentUserToken() {
    if (!this.pchVersionAtLeast("1.4")) {
      throw new Error("Installed version of PCH does not implement persistent user tokens.");
    }

    const userToken = window.sessionStorage.getItem("pch-user-token");
    if (userToken) {
      return $.when(userToken);
    }

    // consider: one of the failure modes of automatic routing is the case where two users are
    // detected as the same user (i.e. to the router it appears as if there are two instances of
    // the status app for the same user), and the presumption is invoking the handler will be a
    // more accurate mechanism for determining user.
    return this.rpc("com.plex.user.get_token")
      .then(
        (callRes) => callRes.args[0],
        () => {
          // fall back to protocol handler
          // - consider: fall back to requiring user gesture to launch handler more reliably
          return this.invokeHandlerPromises("plex:user/getToken", {}, ["promiseId"]).then((obj) => obj.promiseId);
        }
      )
      .then((token) => {
        if (!token) {
          // router reported 1.4, but perhaps status app is pre 1.4? Prevent caching an empty
          // value.
          const ret = $.Deferred();
          ret.reject(new Error("Could not get persistent user token"));
          return ret;
        }

        window.sessionStorage.setItem("pch-user-token", token);
        return token;
      });
  }

  clearCachedPersistentUserToken() {
    window.sessionStorage.removeItem("pch-user-token");
  }
}

// use a static instance of the client
module.exports = new WampClient(
  new WampConnectionManager(["wss://plugin.plex.com:31237", "ws://127.0.0.1:31237", "ws://localhost:31237"], "plex")
);
