const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const features = plexImport("features");
const gridFeatures = plexImport("grid.features");

const FeatureFactory = {
  create: function (el, config, parent) {
    /// <summary>Creates the Feature based on the provided configuration.</summary>
    /// <param name="el">The element instance.</param>
    /// <param name="config">The Feature configuration.</param>
    /// <param name="parent">The element's parent.</param>
    /// <returns type="Object">The Feature instance.</returns>

    if (!(config.name in features || config.name in gridFeatures)) {
      throw new Error("Feature not found: " + config.name);
    }

    // we might want to get rid of the grid namespace to avoid confusion
    const Ctor = features[config.name] || gridFeatures[config.name];
    const feature = new Ctor(el, config);

    // todo: get rid of column
    feature.column = feature.element = el;
    feature.config = config;
    feature.parent = parent;
    feature.init();

    return feature;
  }
};

module.exports = FeatureFactory;
plexExport("features.FeatureFactory", FeatureFactory);
