ï»¿const Notify = require("../Dialogs/plex-dialogs-notify");
const printManager = require("../Controls/plex-controller-printmanager");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const PrintAction = Action.extend({
  onExecute: function (data) {
    if (this.preventWhenModified && this.isDirty(data)) {
      /* eslint new-cap: "off" */
      Notify("There are currently uncommitted changes. Changes must be saved before printing this document.");
      return false;
    }

    return printManager.create(this.config, data).then(() => data);
  },

  isDirty: function (data) {
    if (data && "$$dirtyFlag" in data) {
      return data.$$dirtyFlag.isDirty();
    }

    // account for master grid
    const parent = (this.parent && this.parent.grid) || this.parent;
    if (parent && "results" in parent) {
      return parent.results().some((record) => {
        return "$$dirtyFlag" in record && record.$$dirtyFlag.isDirty();
      });
    }

    return false;
  }
});

module.exports = PrintAction;
plexExport("actions.Print", PrintAction);
