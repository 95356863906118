import React from "react";
import ReactDOM from "react-dom";
import { FilePicker } from "@plex/react-components";
import ControllerFactory from "./plex-controller-factory";
import DataProvider from "../Data/plex-data-provider";
import { useSubscribable } from "../React/use-subscribable";
import { ComponentLocale } from "../React/Components/ComponentLocale";
import ActionHandler from "./plex-handler-action";
import { mixin } from "../Utilities/plex-utils-js";
import modelRepository from "./plex-model-repository";

const filterResults = (results, searchText) => {
  const data = results.data || results || [];
  if (!searchText) {
    return data;
  }

  const text = searchText.toLowerCase();
  return data.filter((x) => x.name.toLowerCase().indexOf(text) !== -1);
};

const _FilePicker = ({ config, ctrl }) => {
  const visible = useSubscribable(config.visible);
  const readOnly = useSubscribable(config.readOnly);
  const disabled = useSubscribable(config.disabled);
  const className = useSubscribable(config.css);
  const style = useSubscribable(config.style);
  const selectedFiles = useSubscribable(config.selected);

  // TODO: wire these up
  const {
    // multiSelect,
    // maxFileSizeInBytes,
    acceptedFileTypes = []
  } = config;

  if (!visible) {
    return null;
  }

  // Presence of upload handler indicates whether upload is supported, so pass
  // undefined if upload isn't present or authorized.
  const uploadHandler = ctrl.canUpload ? (e) => ctrl.uploadFiles(e) : undefined;

  const accept = acceptedFileTypes.join(",") || undefined;

  return (
    <ComponentLocale>
      <FilePicker
        accept={accept}
        onSearch={(e) => ctrl.search(e)}
        onFileUpload={uploadHandler}
        disabled={disabled || readOnly}
        selectedFiles={selectedFiles}
        onSelectionChanged={config.selected}
        className={className}
        style={style}
      />
    </ComponentLocale>
  );
};

class FilePickerController {
  constructor(config, model) {
    this.config = config;
    this.model = model;
    this.init();
  }

  init() {
    const { id, dataProvider, uploadAction, maxFileSizeInBytes, acceptedFileTypes } = this.config;

    this._dataProvider = new DataProvider(dataProvider, this.model, { deferLoading: true });

    // using legacy rendering
    const container = document.getElementById(id + "_Container");
    if (container) {
      const root = ReactDOM.createRoot(container);
      root.render(<_FilePicker config={this.config} ctrl={this} />);
      this.addDisposable(() => root.unmount());
    }

    this.canUpload = false;
    if (uploadAction) {
      this._uploadAction = { ...uploadAction, maxFileSizeInBytes, acceptedFileTypes };
      ActionHandler.initAction(this._uploadAction);
      this.canUpload = this._uploadAction.authorized;
    }

    // This is used by data provider to include search text
    // with request parameters.
    this.params = { SearchText: "" };
    modelRepository.add(id, this.params);
  }

  search(event) {
    this.params.SearchText = event.searchText;

    // If the data provider is remote this will end up making a request on every search.
    // We could optimize this by determining if the existing results can be used filtered
    // in memory. For example, the initial results will be an open search. If the record
    // limit isn't exceeded, all subsequent searches can be in memory.
    return this._dataProvider.get(this.model).then((results) => {
      event.searchComplete(filterResults(results, event.searchText), results.recordLimitExceeded);
    });
  }

  uploadFiles(event) {
    if (!this.canUpload) {
      return Promise.reject(new Error("Upload has not been configured or is not authorized"));
    }

    return ActionHandler.executeAction(this._uploadAction, this.model, event);
  }
}

mixin(FilePickerController, "disposable");

FilePickerController.create = (config, model) => new FilePickerController(config, model);
ControllerFactory.register("Elements/_FilePicker", FilePickerController);
