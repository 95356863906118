ï»¿const $ = require("jquery");
const ko = require("knockout");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const dataUtils = require("../Utilities/plex-utils-data");
const featureFactory = require("../Features/plex-feature-factory");
const valueProviderFactory = require("../Grid/ValueProviders/plex-grid-value-providers-factory");
const exporter = require("../Exports/plex-exporter");
const actionHandler = require("../Controls/plex-handler-action");
const Action = require("./plex-actions");
const GridHeader = require("../Controls/Grid/plex-grid-header");
const banner = require("../Plugins/plex-banner");
const guid = require("../Utilities/plex-utils-guid");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const ProgressBarController = require("../Controls/plex-controller-progressbar");
const environment = require("../Core/plex-env");

const FEATURE_FLAG = "feat-tri-4751-streamsaver-progress-bar";
const EXPORT_ERROR_MSG = "Export failed. Error occurred, so the file was not created.";
const EXPORT_NO_DATA_MSG = "Export failed. No data exists, so the file was not created.";

const ExportAction = Action.extend({
  onInit: function () {
    const self = this;
    if (this.searchAction) {
      actionHandler.initAction(this.searchAction, this.parent);

      // we don't want to publish the search as that will trigger grid updates
      this.searchAction.publishSearch = false;
      this.searchAction.validate = false;
      this.searchAction.applyRecordLimits = false;
    }

    // todo: this would be better somewhere else - there should probably be a
    // `GridLayout` class which can be shared with the grid controller to do this
    if (this.exportModel) {
      this.exportModel.originalColumns = this.exportModel.originalColumns || this.exportModel.columns;

      // initialize all value providers
      this.exportModel.columns = this.exportModel.originalColumns.map((originalColumn) => {
        const column = $.extend(true, {}, originalColumn);

        if (!column.valueProvider || typeof column.valueProvider.getValue !== "function") {
          column.valueProvider = valueProviderFactory.create(column, self);
        }

        // keep surface the same as grid
        if (!ko.isObservable(column.headerName)) {
          column.headerName = ko.observable(column.headerName);
        }

        if (!ko.isObservable(column.visible)) {
          column.visible = ko.observable(column.clientVisible);
        }

        if (!ko.isObservable(column.printVisible)) {
          column.printVisible = ko.observable(column.printVisible);
        }

        return column;
      });
    }
  },

  onExecute: function (criteria) {
    const self = this;
    let data;
    const serializers = plexImport("exporter.serializers");
    if (!(this.format in serializers)) {
      throw new Error("Serializer not found for format type: " + this.format);
    }

    const progressBarConfig = {
      title: exporter.glossary["Exporting..."],
      limitNotSetMessage: exporter.glossary["{1}% complete"]?.replace("{1}", "0")
    };

    const progressBar = new ProgressBarController(progressBarConfig);
    if (environment.features[FEATURE_FLAG]) {
      progressBar.setLimit(1);
      progressBar.setCustomProgressMessage(exporter.glossary["{1}% complete"].replace("{1}", "0"));
      progressBar.show();
    }

    const parent = this.getParent();
    data = this.getLocalData();
    const deferred = new $.Deferred();

    if (data) {
      if (data.length === 0) {
        // don't do anything if the recordset is empty
        if (environment.features[FEATURE_FLAG]) {
          progressBar.hide();
        }
        deferred.reject();
      } else {
        try {
          this.exportData(data, progressBar);
          deferred.resolve(data);
        } catch (error) {
          if (environment.features[FEATURE_FLAG]) {
            progressBar.hide();
          }
          const message = EXPORT_ERROR_MSG;
          banner.getPageBanner().setMessage(message);
          deferred.reject();
        }
      }
    } else if (this.searchAction) {
      criteria = parent.searchCriteria || criteria;

      // need to execute search again without record limits
      actionHandler
        .executeAction(this.searchAction, criteria)
        .then((results) => {
          data = results.data || results;

          // we need to regenerate new columns via grid header to account for cross-tab grids
          const config = $.extend({}, self.parent.grid ? self.parent.grid.config : self.parent.config);

          if (self.exportModel) {
            config.columns = self.exportModel.columns;
            config.exportHeader = self.exportModel.exportHeader;
          } else {
            // todo: cloning is expensive - we need to determine if/why this is needed and determine
            // if there is a cheaper way to accomplish the same task
            config.columns = config.originalColumns.map((col) => {
              return dataUtils.clone(col);
            });
          }

          const datasource = dataSourceFactory.create(config, data);
          const header = new GridHeader(
            {
              columns: config.columns,
              writerProvider: config.rowWriterProvider,
              printWriterProvider: config.rowPrintWriterProvider,
              features: config.rowFeatures || [],
              primary: true
            },
            datasource,
            { config }
          );

          self.exportModel = { columns: header.columns(), exportHeader: config.exportHeader };
          data = datasource.data();

          datasource.dispose();
          if (self.searchAction.id in self.searchAction.parent.actions) {
            delete self.searchAction.parent.actions[self.searchAction.id];
          }

          if (data.length === 0) {
            const message = EXPORT_NO_DATA_MSG;
            banner.getPageBanner().setMessage(message);
            deferred.reject();
          } else {
            try {
              self.exportData(data, progressBar);
              deferred.resolve(data);
            } catch (error) {
              if (environment.features[FEATURE_FLAG]) {
                progressBar.hide();
              }
              const message = EXPORT_ERROR_MSG;
              banner.getPageBanner().setMessage(message);
              deferred.reject();
            }
          }
        })
        .fail(() => {
          if (environment.features[FEATURE_FLAG]) {
            progressBar.hide();
          }
          const message = EXPORT_ERROR_MSG;
          banner.getPageBanner().setMessage(message);
          deferred.reject();
        });
    }

    return deferred.promise();
  },

  exportData: function (data, progressBar) {
    this.processFeatures(data);

    const serializer = new (plexImport("exporter.serializers")[this.format])();
    serializer.parent = this.getParent();

    exporter.saveAs(data, serializer, this.filename, progressBar);
  },

  getLocalData: function () {
    if (!this.exportModel) {
      // account for master grid
      const grid = this.getParent();
      if (grid && grid.datasource && "data" in grid.datasource) {
        // use datasource data that contains the manipulated data from the ko extenders for cross-tabs and group columns
        if (!grid.recordLimitExceeded()) {
          return grid.datasource.data();
        } else if (!this.searchAction) {
          const searchAction = actionHandler.getAction(this.parent.config.searchActionId);
          if (searchAction) {
            this.searchAction = $.extend({}, searchAction);
            this.searchAction.id = guid.create();

            this.searchAction.publishSearch = false;
            this.searchAction.validate = false;
            this.searchAction.applyRecordLimits = false;
            if (this.searchAction.postAction) {
              this.searchAction.postAction = null;
            }

            actionHandler.initAction(this.searchAction, this.parent);
          }
        }
      }
    }

    return null;
  },

  getParent: function () {
    // use export model (generated or defined), then check for master grid,
    // finally use parent which should be a grid in this case
    return this.exportModel || this.parent.grid || this.parent;
  },

  processFeatures: function (data) {
    const supportedFeatures = ["ColumnVisible"];
    if (!this.exportModel) {
      return;
    }

    const self = this;
    this.exportModel.columns.forEach((column) => {
      if (column.features && column.features.length > 0) {
        column.features.forEach((config) => {
          // right now all we care about is the visible feature
          // which only needs to be run once and updates the
          // column automatically
          if (supportedFeatures.indexOf(config.name) > -1) {
            const feature = featureFactory.create(column, config, self);
            feature.execute(data[0], 0, data);
          }
        });
      }
    });
  }
});

ExportAction.configure = function (action) {
  if (action.parent && "searched" in action.parent) {
    if ("results" in action.parent || (action.parent.grid && "results" in action.parent.grid)) {
      // update disable to rely on whether the parent has results
      const grid = action.parent.grid || action.parent;

      // set initial state
      let isVisible = grid.config.visible();
      let hasData = grid.results().length > 0;
      const disableAction = function () {
        action.disabled(!hasData || !isVisible);
      };
      disableAction();

      grid.config.visible &&
        grid.config.visible.subscribe((value) => {
          isVisible = value;
          disableAction();
        });

      if (grid.master) {
        action.parent.searched.subscribe(() => {
          hasData = action.parent.grid.results().length > 0;
          disableAction();
        });
      } else {
        action.parent.results.subscribe((value) => {
          hasData = value.length > 0;
          disableAction();
        });
      }
    } else {
      // update disable to rely on whether the parent has been searched
      action.disabled(!action.parent.searched());
      action.parent.searched.subscribe((value) => {
        action.disabled(!value);
      });
    }
  }

  exporter.setGlossarizedMessages();
};

module.exports = ExportAction;
plexExport("actions.Export", ExportAction);
