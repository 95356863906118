ï»¿const $ = require("jquery");
const ko = require("knockout");
const pageState = require("../Core/plex-pagestate");
const pubsub = require("../Core/plex-pubsub");
const domUtils = require("../Utilities/plex-utils-dom");
const ready = require("./plex-handler-ready");
const plexExport = require("../../global-export");

// todo: the state stuff doesn't belong in here
let components = {};
let componentConfig = null;

const configDefaults = {
  setFocus: true
};

function restoreState(key) {
  const state = pageState.getCurrentState();
  return key ? state[key] : state;
}

function setState(key, data) {
  /// <summary>Adds the key/value to the current page's state.</summary>
  /// <param name="key">The state key.</param>
  /// <param name="data">The data to save.</param>

  pageState.addToCurrentState(key, data);
}

function removeState(key) {
  /// <summary>Removes the key from the page's current state.</summary>
  /// <param name="key">The key to remove.</param>

  pageState.removeFromCurrentState(key);
}

function setController(el, ctrl, config) {
  /// <summary>
  /// Assigns a controller to an element. (If the controller
  /// is already assigned to the element it will be replaced
  /// by the one provided. Configuration will be preserved
  /// unless a new instance is passed in.)
  /// </summary>
  /// <param name="el">The element.</param>
  /// <param name="ctrl">The controller reference.</param>
  /// <param name="config">The configuration.</param>

  if (typeof el === "string") {
    // this is safer than adding "#" and using jquery
    el = document.getElementById(el);
  }

  const $el = $(el);
  let id, setup;

  if ($el.length === 0) {
    id = (config && config.id) || el;
    throw Error(`Element ${id} not found.`);
  }

  id = $el[0].id;
  if (id in components) {
    setup = components[id];
  } else {
    components[id] = setup = { $element: $el };
  }

  setup.factory = ctrl;
  setup.config = config || setup.config;
}

const collectionTypes = ["elements", "sections", "widgets"];
const propertyTypes = ["actionBar", "chart"];

function createConfigMap(config, map) {
  map = map || Object.create(null);
  if (config.id) {
    map[config.id] = config;
  }

  collectionTypes.forEach((type) => {
    const current = config[type];
    if (Array.isArray(current) && current.length > 0) {
      current.forEach((c) => {
        createConfigMap(c, map);
      });
    }
  });

  propertyTypes.forEach((type) => {
    const current = config[type];
    if (current) {
      createConfigMap(current, map);
    }
  });

  return map;
}

function setConfig(config) {
  /// <summary>Sets the config for the page being loaded.</summary>
  /// <param name="config">The config object.</param>

  componentConfig = config;
}

function init(config) {
  /// <summary>
  /// Initializes all registered components. Invokes `plex.onReady`
  /// callbacks when completed.
  /// </summary>
  /// <returns type="Object">
  /// Returns an object with a `dispose` function which can be
  /// used to dispose all components.
  /// </returns>

  const renderCompletes = [];

  config = $.extend({}, configDefaults, config, componentConfig);

  let actionHandler;
  if (config && config.messageActions) {
    // todo: fix circular reference
    /* eslint global-require: "off" */
    actionHandler = require("./plex-handler-action");
    config.messageActions.forEach((action) => {
      actionHandler.initAction(action);
    });
  }

  const componentConfigMap = createConfigMap(config);
  let componentInstances = {};

  $.each(components, (id, setup) => {
    /* eslint new-cap: "off" */
    const controller = setup.factory.create ? setup.factory.create() : new setup.factory();
    componentInstances[id] = controller;

    // make sure these $element & config are always defined
    controller.$element = setup.$element;

    // todo: making a new copy to support legacy behavior, but this should be changed so that the config is shared
    // this will help with some of the issues we see with nested grids, etc.
    controller.config = $.extend({}, setup.config || componentConfigMap[id]);

    setup.$element.data("controller", controller);

    const e = { cancel: false, controller };
    pubsub.publish("preinit." + id, e);

    if (e.cancel !== true) {
      if (typeof controller.init === "function") {
        controller.init(controller.$element, controller.config);
      }

      pubsub.publish("init." + id, controller);

      if (typeof controller.renderComplete === "function") {
        renderCompletes.push(controller.renderComplete());
      }
    }
  });

  Object.keys(componentInstances)
    .filter((key) => typeof componentInstances[key].postInit === "function")
    .forEach((key) => componentInstances[key].postInit());

  if (config.setFocus) {
    domUtils.setInitialFocus(config.setFocus === true ? document : config.setFocus);
  }

  // clear out references
  components = {};
  componentConfig = null;

  ready.trigger();

  if (actionHandler && config && config.messageActions) {
    if (config.messageActions.length > 0) {
      actionHandler.executeAction(config.messageActions[0]);
    }
  }

  ko.computed({
    read: function () {
      const stillRendering = renderCompletes.filter((x) => x() === false);
      if (stillRendering.length === 0) {
        pubsub.publish("page rendered");
      }
    }
  });

  return {
    elements: componentInstances,
    dispose: function () {
      if (componentInstances) {
        Object.keys(componentInstances)
          .filter((key) => typeof componentInstances[key].dispose === "function")
          .forEach((key) => componentInstances[key].dispose());

        componentInstances = null;
      }
    }
  };
}

const api = {
  removeState,
  restoreState,
  setState,
  setConfig,
  setController,
  onReady: ready.onReady,
  init,
  reset: ready.reset
};

module.exports = api;
plexExport("PageHandler", api);
