ï»¿/* eslint-disable no-invalid-this */
const ControllerFactory = require("./plex-controller-factory");
const plexExport = require("../../global-export");

function RadioButtonController(el, data) {
  this.init(el, data);
}

RadioButtonController.prototype = {
  constructor: RadioButtonController,

  init: function (el, data) {
    const self = this;
    self.el = el;
    self.data = data;

    self.setGroupName(el, data);
  },

  setGroupName: function (el, data) {
    if (el.groupName && data) {
      el.groupName = data[el.groupName];
    } else {
      el.groupName = el.propertyName;
    }
  }
};

RadioButtonController.create = function (el, data) {
  return new RadioButtonController(el, data);
};

ControllerFactory.register("Elements/_RadioButton", RadioButtonController);

module.exports = RadioButtonController;
plexExport("RadioButtonController", RadioButtonController);
