ï»¿Array.prototype.remove =
  Array.prototype.remove ||
  function (valueOrPredicate) {
    const removedValues = [];
    const predicate =
      typeof valueOrPredicate === "function"
        ? valueOrPredicate
        : function (item) {
            return item === valueOrPredicate;
          };
    let value;

    for (let i = 0; i < this.length; i++) {
      value = this[i];
      if (predicate(value)) {
        removedValues.push(value);
        this.splice(i, 1);
        i--;
      }
    }

    return removedValues;
  };

Array.prototype.removeAll =
  Array.prototype.removeAll ||
  function (arrayOfValues) {
    // If you passed zero args, we remove everything
    if (arrayOfValues === undefined) {
      const allValues = this.slice(0);
      this.splice(0, this.length);
      return allValues;
    }

    // If you passed an arg, we interpret it as an array of entries to remove
    if (!arrayOfValues) {
      return [];
    }

    return this.remove((value) => {
      return arrayOfValues.indexOf(value) >= 0;
    });
  };
