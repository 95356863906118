ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const pubsub = require("../Core/plex-pubsub");
const jsUtils = require("../Utilities/plex-utils-js");
const dataUtils = require("../Utilities/plex-utils-data");
const dataSourceFactory = require("./plex-datasource-factory");
const DataResult = require("./plex-data-result");
const modelRepository = require("../Controls/plex-model-repository");
const dataTokens = require("../Utilities/plex-utils-datatoken");
const onReady = require("../Controls/plex-handler-page").onReady;
const plexExport = require("../../global-export");

require("../Mixins/plex-mixins-disposable"); // eslint-disable-line import/no-unassigned-import

const defaultOptions = {
  singleRecord: false,
  ignoreChanges: false,
  deferLoading: false
};

// note: use with care - some pathes are not yet implemented
const DataProvider = function (config, model, optionsOrSingleRecord) {
  // backwards compatibility for single record option
  let options = optionsOrSingleRecord;
  if (typeof options === "boolean") {
    options = { singleRecord: options };
  }

  this.options = $.extend({}, defaultOptions, options);
  this.config = config;
  this.model = model;
  this.init();
};

DataProvider.prototype = {
  constructor: DataProvider,

  init: function () {
    this.isLoading = ko.observable(false);
    this.isLoaded = ko.observable(false);

    this.data = this.options.singleRecord ? ko.observable() : ko.observableArray();
    this.emptyRecord = this.config.emptyRecord || {};

    if (this.config.propertyName) {
      // BoundElementDataSource
      // defer the execution so that the entire page has a chance to be processed before we bind the data
      onReady(loadDataFromModel, this);
    } else if (this.config.searchActionId) {
      // SearchDataProvider
      this.addDisposable(pubsub.subscribe("searched." + this.config.searchActionId, this.load, this));
    } else if (this.config.request) {
      // DataSourceElementDataProvider
      // not implemented because i can't test it from the sample app and am being ridden to get this out
      throw new Error("DataSourceElementDataProvider is not yet implemented.");
    } else if (this.config.route && !this.config.response) {
      // if config has tokens add them to route parameters
      this.config.route.parameters = this.config.route.parameters || [];
      if (this.config.tokens) {
        this.config.tokens.forEach((token) => {
          this.config.route.parameters.push(token);
        });
      }

      // RemoteElementDataProvider
      this.dataSource = dataSourceFactory.create(this.config.route);

      // delay execution so any dependencies have a chance to process
      onReady(() => this._trackChanges());
    } else {
      // IDataSourceBoundElementProvider or IStaticElementDataProvider
      this.load(this.config.response || this.config.data);
    }
  },

  initRecord: function (record, index) {
    if (!record.$$observableIndex) {
      record.$$observableIndex = ko.observable(index);
    }

    dataUtils.trackObject(record, this.config.computedProperties);
  },

  buildRequest: function (data) {
    let request = $.extend({}, this.config.request, data || this.model);
    if (this.config.tokens && this.config.tokens.length > 0) {
      request = dataUtils.cleanse(dataTokens.applyTokens(request, this.config.tokens));
      this.dataSource.request.request = $.extend(this.dataSource.request.request, request);
    }

    return request;
  },

  get: function (data) {
    if (!this.dataSource) {
      return $.when(new DataResult(this.data()));
    }

    this.isLoading(true);

    const request = this.buildRequest(data);
    const response = this.dataSource.get(request, { resolveRows: false }).then((res) => this.load(res));

    response.always(() => this.isLoading(false));
    return response;
  },

  load: function (results) {
    let dataResult = results;
    if (!(dataResult instanceof DataResult)) {
      dataResult = new DataResult(dataResult || []);
    }

    let outputParams = null;
    if (!$.isEmptyObject(dataResult.outputParameters)) {
      outputParams = dataResult.outputParameters;
    }

    if (this.options.singleRecord) {
      // todo: passing in the model here is probably going to cause problems - need to evaluate
      const data = dataResult.data[0] || new DataResult(this.model).data[0] || {};
      data.$$outputParameters = outputParams;

      this.initRecord(data);
      this.data(data);
    } else {
      dataResult.data.$$outputParameters = outputParams;
      this.data(dataResult.data);

      // setup initial records
      // todo - need to add hooks in case records are manually added
      this.data.peek().forEach(this.initRecord, this);
    }

    this.isLoaded(true);
    return dataResult;
  },

  _trackChanges: function () {
    if (this.options.deferLoading) {
      return;
    }

    if (!this.options.ignoreChanges) {
      // wrap request in computed - this will allow us to trigger a reload whenever a dependency changes
      const loader = ko.pureComputed(this.buildRequest, this).extend({ defer: true });

      // whenever a dependency changes, trigger a load
      loader.subscribe(this.get, this);
      this.addDisposable(loader);
    }

    // if it hasn't been loaded yet, go ahead and trigger
    if (!this.isLoaded() && !this.isLoading()) {
      this.get();
    }
  }
};

function loadDataFromModel() {
  const propertyName = this.config.propertyName;
  let model = this.model;

  if (this.config.sourceId) {
    model = modelRepository.get(this.config.sourceId) || model;
  }

  const source = dataUtils.getObservableOrValue(model, propertyName);
  if (ko.isObservble(source)) {
    this.data = source;
  } else {
    this.data(source);
  }

  this.isLoaded(true);
}

jsUtils.mixin(DataProvider, "disposable");

module.exports = DataProvider;
plexExport("data.DataProvider", DataProvider);
