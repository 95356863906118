const PluginBase = require("./plex-native-base");
const plexExport = require("../../global-export");

const LabelPrinting = PluginBase.extend({
  printLabel: function (labelCode, options) {
    /// <summary>Prints the specified label code by calling the websocket plugin.</summary>
    return this.call("printSimpleLabel", labelCode, options);
  }
});

module.exports = LabelPrinting;
plexExport("native.plugins.LabelPrinting", LabelPrinting);
