ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const DEFAULT_SELECTED_PROPERTY = "$$selected";

const states = {
  unchecked: 0,
  checked: 1,
  indeterminate: -1
};

function findPropertyNameFromColumn(column) {
  if (column.propertyName) {
    return column.propertyName;
  }

  // if this is an element column with only one element, we will try to get the property from the element
  if (column.elements && column.elements.length === 1 && column.elements[0].propertyName) {
    return column.elements[0].propertyName;
  }

  return DEFAULT_SELECTED_PROPERTY;
}

function recordIsSelectable(record, propertyName) {
  if (record.$$controller && propertyName in record.$$controller.elements) {
    const el = record.$$controller.elements[propertyName];
    return el.visible() && !el.disabled() && !el.readOnly();
  }

  return false;
}

const SelectionState = function (records, column, emitter) {
  const self = this;
  let initialSelections = [];
  const disposables = [];
  let propertyName = DEFAULT_SELECTED_PROPERTY;
  const evaluationTrigger = ko.observable();

  if (column) {
    propertyName = findPropertyNameFromColumn(column);
  }

  const selectablePropertyName = "$$selectable" + propertyName;

  this.isSelectable = function (record) {
    if (!(selectablePropertyName in record)) {
      const selectedObservable = dataUtils.getObservable(record, propertyName);
      if (selectedObservable) {
        // setup observable as deferred so UI updates are batched
        selectedObservable.extend({ deferred: true });
      }

      if (propertyName === DEFAULT_SELECTED_PROPERTY) {
        record[selectablePropertyName] = ko.pureComputed(() => {
          return record.$$selectable !== false && !("$$disabled" in record && record.$$disabled());
        });
      } else if ("$$controller" in record) {
        record[selectablePropertyName] = ko.pureComputed(() => {
          return recordIsSelectable(record, propertyName);
        });
      } else {
        record[selectablePropertyName] = ko.pureComputed(() => {
          if ("$$controller" in record) {
            // controller might not be present during initial evaluation
            // this gives a chance to let it go back through and get evaluated
            return recordIsSelectable(record, propertyName);
          }

          if ("elements" in column) {
            // if we get here the record might not yet be rendered
            // this trigger gives the state a means to trigger a
            // re-evaluation
            evaluationTrigger();
          }

          return column ? self.allowColumnSelection(column, record) : false;
        });
      }
    }

    return record[selectablePropertyName]();
  };

  this.allowColumnSelection = function (currentColumn, record) {
    if (currentColumn.master) {
      const columns = currentColumn.master.columns.filter((o) => {
        return o.column === currentColumn;
      });

      const firstColumn = columns ? columns[0] : null;
      if (!firstColumn || !firstColumn.evaluator(record)) {
        return false;
      }
    }

    if (currentColumn.type === "Boolean") {
      return currentColumn.enableToggleAll;
    }

    return false;
  };

  this.getRecordSelected = function (record) {
    return ko.unwrap(record[propertyName]);
  };

  this.setRecordSelected = function (record, value) {
    // todo: we might want to cache the result of this observable check - we should only need to check this once
    if (ko.isObservable(record[propertyName])) {
      record[propertyName](value);
    } else {
      record[propertyName] = value;
    }
  };

  function isSelected(record) {
    return self.isSelectable(record) && self.getRecordSelected(record);
  }

  let selectedRecords, selectableRecords;
  if (ko.isObservable(records) && "filter" in records) {
    // leverage knockout projection plugin if we can, for better performance
    selectedRecords = records.filter(isSelected);
    selectableRecords = records.filter(this.isSelectable);
  } else {
    selectedRecords = ko.pureComputed(() => {
      return ko.unwrap(records).filter(isSelected);
    });
    selectableRecords = ko.pureComputed(() => {
      return ko.unwrap(records).filter(self.isSelectable);
    });
  }

  // rate limit so that notifications do not happen until all records are toggled
  selectedRecords = selectedRecords.extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
  selectableRecords = selectableRecords.extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
  disposables.push(selectedRecords, selectableRecords);

  // compute the state from current selection
  const state = ko.pureComputed(() => {
    evaluationTrigger();

    const currentSelectedCount = selectedRecords().length;
    if (currentSelectedCount === 0) {
      return states.unchecked;
    }

    // if all records get updated outside of toggling all,
    // we need for the checkbox to reflect this
    const selectableCount = selectableRecords().length;
    if (selectableCount === currentSelectedCount && selectableCount > 0) {
      return states.checked;
    }

    return states.indeterminate;
  });

  disposables.push(state);
  const currentState = ko.observable(states.unchecked);

  // these properties expose the checked/indeterminate state for binding
  // these are deferred since they are updated together, to eliminate multiple
  // UI updates
  this.checked = ko.observable(false).extend({ deferred: true });
  this.indeterminate = ko.observable(false).extend({ deferred: true });

  this.reset = function () {
    currentState(state());

    // update initial selections with current selections
    initialSelections = ko.unwrap(records).filter((record) => {
      return self.getRecordSelected(record) && self.isSelectable(record) !== false;
    });
  };

  this.restore = function () {
    ko.unwrap(records).forEach((record) => {
      self.setRecordSelected(record, initialSelections.indexOf(record) >= 0);
    });
  };

  this.toggle = function () {
    let selectAll = true;
    switch (currentState()) {
      case states.indeterminate:
        currentState(states.checked);
        break;

      case states.checked:
        currentState(states.unchecked);
        selectAll = false;
        break;

      default:
        if (initialSelections.length > 0) {
          currentState(states.indeterminate);
          this.restore();
          return;
        }

        currentState(states.checked);
        break;
    }

    // defer the toggling of values so that actual toggling checkbox gets updated immediately
    $(document).block();
    jsUtils.defer(() => {
      // make sure late-bound observables are up to date
      evaluationTrigger.notifySubscribers();

      const unwrappedRecords = selectableRecords();
      for (let i = 0, ln = unwrappedRecords.length; i < ln; i++) {
        self.setRecordSelected(unwrappedRecords[i], selectAll);
      }

      $(document).unblock();
      if (emitter) {
        emitter.fire("toggleSelectAll", { propertyName, selected: selectAll });
      }
    });
  };

  this.dispose = function () {
    let sub;
    while ((sub = disposables.pop())) {
      sub.dispose();
    }
  };

  disposables.push(
    state.subscribe((value) => {
      currentState(value);
    })
  );

  disposables.push(
    currentState.subscribe((value) => {
      switch (value) {
        case states.checked:
          self.checked(true);
          self.indeterminate(false);
          break;

        case states.unchecked:
          self.checked(false);
          self.indeterminate(false);
          break;

        default:
          self.checked(false);
          self.indeterminate(true);
          break;
      }
    })
  );

  // set initial state by calling reset
  this.reset();
};

module.exports = SelectionState;
plexExport("SelectionState", SelectionState);
