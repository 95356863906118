ï»¿const $ = require("jquery");
const ko = require("knockout");
const DataSourceRequest = require("./plex-datasource-request");
const logger = require("../Core/plex-logger");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const plexExport = require("../../global-export");

const slice = Array.prototype.slice;
let eventId = 0;

// #region Helpers

function createValueFn(propertyName) {
  return function (record) {
    // we might be able to save some overhead here by caching to
    // see if this is an observable and returning a function based on that
    return ko.utils.peekObservable(record[propertyName]);
  };
}

function mergeFilters(/* args */) {
  const args = slice.call(arguments);
  // cache length
  const ln = args.length;

  return function (record) {
    let i = 0;
    let result = true;
    while (i < ln && (result = args[i](record))) {
      // do nothing - the while loop will short circuit
      i++;
    }

    return result;
  };
}

function createMultiColumnComparer(sortList) {
  // we can cache length
  const ln = sortList.length;

  // prepare sorters
  sortList.forEach((sort) => {
    if (typeof sort.propertyNameOrValueProvider === "string") {
      sort.sortPropertyName = sort.propertyNameOrValueProvider;
      sort.valueFn = createValueFn(sort.propertyNameOrValueProvider);
    } else if ("bucketIndex" in sort.propertyNameOrValueProvider.column) {
      // sort on pivot column

      const valueProvider = sort.propertyNameOrValueProvider;
      const bucketIndex = valueProvider.column.bucketIndex;
      const emptyRecord = valueProvider.parent.emptyRecord;

      if (valueProvider.column.expression) {
        // sort on expression column

        sort.sortPropertyName = valueProvider.column.expression.expressionText;
        sort.valueFn = function (record) {
          return valueProvider.getValue(record.$$buckets[bucketIndex].$$data || emptyRecord);
        };
      } else {
        const pivotPropertyName = valueProvider.column.propertyName;

        sort.sortPropertyName = pivotPropertyName;
        sort.valueFn = function (record) {
          return dataUtils.getValue(record.$$buckets[bucketIndex].$$data || emptyRecord, pivotPropertyName);
        };
      }
    } else {
      sort.sortPropertyName = sort.propertyNameOrValueProvider.column.propertyName;
      sort.valueFn = (sort.propertyNameOrValueProvider.getSortValue || sort.propertyNameOrValueProvider.getValue).bind(
        sort.propertyNameOrValueProvider
      );
    }
  });

  const comparer = function (left, right) {
    let i = 0;
    let a, b, compare;

    for (; i < ln; i++) {
      a = sortList[i].valueFn(sortList[i].ascending ? left : right);
      b = sortList[i].valueFn(sortList[i].ascending ? right : left);

      compare = dataUtils.compareValues(a, b);

      // only return if we're not equal, which would be a negative or positive number
      if (compare) {
        return compare;
      }
    }

    return 0;
  };

  // add this to the comparer function - the group extender needs to know if a group sort has occured
  // for rendering purposes - it's a little hacky but it's all i got right now
  // todo: the real solution here is to make the grid re-syncing more robust so that it can properly account for headers & footers
  comparer.isGroupSort = sortList.some((sort) => {
    return sort.isGroupSort;
  });
  return comparer;
}

function subscribeToChange(record, propertyName, callback, options, disposables, parentRecord) {
  let subscribeToArrayChanges;
  eventId++;

  // default is to execute async
  const async = options.async !== false;

  // note: this will return the current observable if already tracked or track if not
  const observable = dataUtils.trackProperty(record, propertyName);

  logger.debug("subscription for '" + propertyName + "' id:" + eventId);

  const deepSubscribe = options.deep && Array.isArray(observable());
  if (deepSubscribe) {
    subscribeToArrayChanges = function (records) {
      records
        .filter((row) => {
          return typeof row === "object";
        })
        .forEach((row) => {
          $.each(row, (property) => {
            if (!dataUtils.isProtectedProperty(property)) {
              subscribeToChange(row, property, callback, options, disposables, record);
            }
          });
        });
    };

    subscribeToArrayChanges(observable());
  }

  const subscription = observable.subscribe(
    function () {
      logger.debug("updated triggered from '" + propertyName + "' id: " + eventId);

      let args;
      if (deepSubscribe) {
        const changes = slice.call(arguments)[0];
        subscribeToArrayChanges(
          changes
            .filter((change) => {
              return change.status === "added";
            })
            .map((change) => {
              return observable()[change.index];
            })
        );
      }

      // if deep subscription, append the property name to the arguments
      if (options.deep) {
        args = slice.call(arguments).concat([propertyName, parentRecord]);
      }

      // we are defering the execution so that it occurs outside of knockout's notifications
      // otherwise changes can happen within the callback that can cause unpredictable behavior
      if (async) {
        jsUtils.defer(callback, record, args || arguments);
      } else {
        ko.ignoreDependencies(callback, record, args || arguments);
      }
    },
    null,
    deepSubscribe ? "arrayChange" : "change"
  );

  disposables.push(subscription);
  return subscription;
}

// #endregion

const DataSource = function () {
  // constructor
};

DataSource.prototype = {
  constructor: DataSource,

  init: function (options, data) {
    const self = this;

    this.options = options || {};
    this.request = new DataSourceRequest(this.options);
    this.maxRecords = this.options.maxRecords;
    this.raw = data || this.options.data || [];
    this.source = ko.observableArray();
    this.outputs = ko.observable();
    this.isLoading = ko.observable(false);
    this.initializer = this.options.initializer || $.noop;
    this.disposer = this.options.disposer || $.noop;
    this.currentColumnComparer = null;
    this.currentFilter = null;
    this._disposables = [];
    this._subscriptions = [];

    this.recordLimitExceeded = ko.computed(() => {
      return self.options.maxRecords && self.source().length > options.maxRecords;
    });

    this.recordCount = ko.observable(0);
    this.cachedRecordSet = ko.computed(() => {
      return self.options.maxRecords && self.recordCount() > self.options.maxRecords;
    });
    this.recordCountLimitExceeded = ko.computed(() => {
      return self.cachedRecordSet() && self.data().length >= self.options.maxRecords;
    });

    this.data = this.source;
    this.applyExtenders(this.options);

    this.load(this.raw, false, this.options.outputs);

    this.source.subscribe(
      (changes) => {
        if (self._subscriptions.length === 0 || self.isLoading() || self.source().length === 0) {
          return;
        }

        changes
          .filter((change) => {
            return change.status === "added";
          })
          .map((change) => {
            return self.source()[change.index];
          })
          .forEach((record) => {
            self._subscriptions.forEach((sub) => {
              subscribeToChange(record, sub.propertyName, sub.callback, sub.options, self._disposables);
            });
          });
      },
      null,
      "arrayChange"
    );
  },

  applyExtenders: function (options) {
    // note: these extenders can work together but it's important that
    // they are used in the order below

    if (options.dataProvider && options.dataProvider.computedProperties) {
      this.data = this.data.extend({ computedProperties: options.dataProvider.computedProperties });
    }

    if (options.columns && options.columns.some((column) => column.type === "Xml")) {
      this.data = this.data.extend({ xmlColumn: options });
    }

    if (options.pivotKeyPropertyName) {
      this.data = this.data.extend({ crosstab: options });
    }

    if (options.groups && options.groups.length > 0) {
      this.data = this.data.extend({ group: options });
    }

    if (options.maxRecords) {
      this.data = this.data.extend({ slice: { begin: 0, end: options.maxRecords } });
    }
  },

  reset: function () {
    /// <summary>Resets the datasource to it's initial state.</summary>

    this.raw = [];
    this.source([]);
    this.recordCount(0);
  },

  load: function (data, filtering, outputs) {
    /// <summary>
    /// Loads the provided data into the datasource. When data is loaded
    /// the initializer function will be run on the data. The sort will be
    /// maintained.
    /// </summary>
    /// <param name="data">An array of objects to load.</param>
    /// <param name="filtering">Keeps the raw data when filtering data internally. (For internal usage.)</param>
    /// <param name="outputs">An object containing outputs.</param>

    if (!Array.isArray(data)) {
      data = $.makeArray(data);
    }

    this.clearSubscriptions();
    this.initializer(data);

    if (this.currentColumnComparer && !this.options.pivotKeyPropertyName) {
      // apply the sort against the raw data to avoid multiple updates
      data = arrayUtils.stableSort(data, this.currentColumnComparer);
    }

    if (!filtering) {
      this.raw = data;
    }

    this.outputs(outputs);

    // clear out source so that updates are always triggered
    this.source([]);

    if (filtering || !this.currentFilter) {
      this.source(data);
    } else {
      this.applyFilter();
    }

    this.isLoading(false);
  },

  sort: function (currentSortList) {
    this.currentSortList = currentSortList;
    this.currentColumnComparer = createMultiColumnComparer(currentSortList);
    this.applySort();
  },

  applySort: function () {
    if (
      (this.options.groups && this.options.groups.length > 0) ||
      this.options.pivotKeyPropertyName ||
      ko.isWritableObservable(this.data) === false
    ) {
      // sort is overridden in appropriate extenders
      this.data.sort(this.currentColumnComparer);
    } else {
      this.data(arrayUtils.stableSort(this.data.peek(), this.currentColumnComparer));
    }
  },

  filter: function (filterFn, filterWithin) {
    /// <summary>Filters the datasource based on the provided predicate function.</summary>
    /// <param name="filterFn">The predicate function.</param>
    /// <param name="filterWithin">Indicates whether to filter using the current state or to reset and reapply the filter. (optional - default is false)</param>

    let source;
    if (filterWithin) {
      source = this.source();
      this.currentFilter = this.currentFilter ? mergeFilters(this.currentFilter, filterFn) : filterFn;
    } else {
      source = this.raw;
      this.currentFilter = filterFn;
    }

    const results = source.filter(filterFn);
    this.load(results, true, this.outputs());
  },

  applyFilter: function () {
    if (this.currentFilter) {
      this.source(this.raw.filter(this.currentFilter));
    }
  },

  clearFilter: function () {
    /// <summary>Undoes any filtering returning the array to it's prior state.</summary>

    this.currentFilter = null;
    this.load(this.raw, false, this.outputs());
  },

  onChange: function (propertyName, callback, options) {
    /// <summary>Register a callback to be executed when any record has a change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="options"> Options include
    ///   `async` - if set to false the callback will be excuted synchronously (optional - default to true)
    ///   'deep' - if set on array based properties, the event will be triggered when items are added, deleted or updated (optional - default to false)
    /// </param>

    const self = this;
    options = options || {};

    this.raw.forEach((record) => {
      self.onRecordChange(record, propertyName, callback, options);
    });

    this._subscriptions.push({ propertyName, callback, options });
  },

  onRecordChange: function (record, propertyName, callback, options) {
    /// <summary>Register a callback to be executed when a record has a change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="record">The record to subscribe to.</param>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="options"> Options include
    ///   `async` - if set to false the callback will be excuted synchronously (optional - default to true)
    ///   'deep' - if set on array based properties, the event will be triggered when items are added, deleted or updated (optional - default to false)
    /// </param>
    /// <returns type="Object">Returns the subscription object, which has a `dispose` function to cancel the subscription.</returns>

    return subscribeToChange(record, propertyName, callback, options || {}, this._disposables);
  },

  clearSubscriptions: function () {
    let sub;
    while ((sub = this._disposables.pop())) {
      sub.dispose();
    }
  },

  applySubscriptions: function () {
    const self = this;
    if (this._subscriptions.length === 0) {
      return;
    }

    this.raw.forEach((record) => {
      self._subscriptions.forEach((sub) => {
        subscribeToChange(record, sub.propertyName, sub.callback, sub.options, self._disposables);
      });
    });
  },

  dispose: function () {
    this.disposer(this.raw);
    this.clearSubscriptions();
  }
};

jsUtils.makeExtendable(DataSource);

module.exports = DataSource;
plexExport("DataSource", DataSource);
