ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const featureFactory = require("./plex-feature-factory");
const featureApplicator = require("./plex-feature-applicator");
const FeatureResult = require("./plex-feature-result");
const plexExport = require("../../global-export");

let guid = 0;
let cloneCache;

function keepElementCss(element, feature) {
  // prevents css that is assigned directly to the element from being removed
  if (element && element.css) {
    if (feature.removedCss && feature.removedCss.length > 0) {
      const index = feature.removedCss.indexOf(element.css);
      if (index >= 0) {
        feature.removedCss.splice(index, 1);
      }
    }
  }
}

const FeatureProcessor = function (configs, el, parent) {
  /// <summary>Processor for Features.</summary>
  /// <param name="configs">The feature configurations.</param>
  /// <param name="el">The element object.</param>
  /// <param name="parent">The element's parent.</param>

  this.configs = configs;
  this.element = el;
  this.parent = parent;
  this.features = [];
  this.init();
  this.elementId = "";
};

FeatureProcessor.prototype = {
  constructor: FeatureProcessor,

  init: function () {
    /// <summary>Initializes the FeatureProcessor.</summary>

    const self = this;
    if (this.configs) {
      this.configs.forEach((config) => {
        self.features.push(featureFactory.create(self.element, config, self.parent));
      });

      // Sort in ascending order of priorities
      self.features = self.features.sort((a, b) => {
        return a.priority - b.priority;
      });
    }
  },

  process: function (record, index, records, rowConfig, groupIndex, group, colIndex) {
    /// <summary>Processes all of the features against the provided arguments.</summary>
    /// <returns type="Object">The FeatureResult for the executed features.</returns>

    const ln = this.features.length;
    let i = 0;
    const results = new FeatureResult();
    let clone = null;
    let result;

    if (ln > 0) {
      if (record) {
        // note: the knockout-es5 plugin includes a WeakMap polyfill
        /* eslint-env es6 */
        cloneCache = cloneCache || new WeakMap();
        clone = cloneCache.get(record);

        if (!clone) {
          // create a flat/read-only copy of the record
          clone = dataUtils.flatten(record, { readOnly: true });

          // store the clone in a cache - this will prevent the record from being
          // cloned multiple times for each column, since cloning is a fairly
          // expensive operation
          cloneCache.set(record, clone);
        }
      }

      for (; i < ln; i++) {
        result = this.features[i].execute(
          clone,
          index,
          records,
          rowConfig || this.element,
          groupIndex,
          group,
          results,
          colIndex
        );
        if (result !== undefined && result !== results) {
          results.merge(result);
        }

        if (results.render === false) {
          break;
        }
      }
    }

    return results.resolve();
  },

  apply: function (el, featureArgs) {
    // wrap the processer call in an observable - this allows us to manually
    // apply the features to the element if value changes trigger updates
    const featureObservable = ko.computedPromise(() => this.process(...featureArgs));

    let current = featureObservable();
    if (current.render === false) {
      featureObservable.dispose();
      return false;
    }

    // need to apply id to an element so we can look it up as needed to apply
    // any features that end up changing due to values changing
    // note: elementId set on the processor will take precedence.
    // we are not using the id from the element because this is being
    // used for dynamic rendering (example grid columns) which do not have
    // unique ids assigned to them
    const id = (current.attr.id = this.elementId || current.attr.id || "featurable_el_" + String(++guid));

    featureObservable.subscribe((result) => {
      const $el = $(document.getElementById(id));
      if ($el.length > 0) {
        // get diff between last result and apply to the element
        const diff = current.diff(result);
        keepElementCss(el, diff);
        featureApplicator.update($el, diff);
        current = result;
      } else {
        // if the element no longer exists we can dispose the observable
        featureObservable.dispose();
      }
    });

    return featureObservable;
  },
  // Method to set elementId to be used to look up element for applying feature.
  // Prototype method will help consumer set id explicitly when required.
  setElementId: function (id) {
    this.elementId = id;
  }
};

module.exports = FeatureProcessor;
plexExport("features.FeatureProcessor", FeatureProcessor);
