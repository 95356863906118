ï»¿const nav = require("../../Core/plex-navigate");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const ImageValueProvider = defaultValueProvider.extend({
  getUrlComponents: function (record) {
    let path, url;
    let data = null;
    if (this.column.image) {
      path = this.column.image.imageUrl;
    } else {
      path = this.getValue(record);
    }

    if (!path) {
      return null;
    }

    url = this.column.rootUrl || "";
    if (url) {
      // todo: clean url - remove extra slashes, etc
      url += "/" + path;
    } else {
      url = path;
    }

    if (this.column.disableCaching) {
      data = { requestId: new Date().getTime() };
    }

    return {
      url,
      data
    };
  },

  getUrl: function () {
    const urlComponents = this.getUrlComponents.apply(this, arguments);
    return urlComponents ? nav.buildUrl(urlComponents.url, urlComponents.data) : "";
  },

  getHtml: function (record) {
    let alt;
    if (this.column.image) {
      alt = this.column.image.alternateText;
    } else {
      alt =
        (this.column.alternateTextPropertyName && record[this.column.alternateTextPropertyName]) ||
        this.column.alternateText;
    }

    return "<img src='" + this.getUrl.apply(this, arguments) + "' alt='" + (alt || "") + "' />";
  },

  getPrintValue: function (_record) {
    return new DocumentXml("plex-control-image").addAttribute("src", this.getUrl.apply(this, arguments)).serialize();
  },

  getTrackedHtml: function (record, _index, _colIndex, _features) {
    return this.renderTemplate("elements-formattedaddress", { data: record, options: this.column });
  }
});

module.exports = ImageValueProvider;
plexExport("grid.valueProviders.ImageValueProvider", ImageValueProvider);
