ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const GridController = require("../Controls/plex-controller-grid");
const selectionMode = require("../Grid/plex-grid-selectionmode");
const dataUtils = require("../Utilities/plex-utils-data");
const dataTokens = require("../Utilities/plex-utils-datatoken");
const plexExport = require("../../global-export");

const MassUpdateAction = Action.extend({
  onExecute: function (_data, rawData, _e) {
    const self = this;
    const grid = self.parent;
    let propertyName;

    if (!(grid instanceof GridController) || !grid.multiSelect) {
      throw new Error("Mass Update action is supported only on multiselect grids.");
    }

    const selectedRecords = selectionMode.isDirty(grid.config.selectionMode) ? grid.results() : grid.selected();
    const tokenizedData = dataTokens.applyTokens(rawData, self.tokens);

    selectedRecords.forEach((record) => {
      self.tokens.forEach((token) => {
        propertyName = token.alias || token.propertyName;
        dataUtils.setValue(record, propertyName, tokenizedData[propertyName]);
      });
    });

    return new $.Deferred().resolve(selectedRecords).promise();
  }
});

module.exports = MassUpdateAction;
plexExport("actions.MassUpdate", MassUpdateAction);
