ï»¿const valueProviderFactory = require("../../ValueProviders/plex-grid-value-providers-factory");
const PrintWriter = require("./plex-grid-print-writer-base");
const gridUtils = require("../../plex-grid-utils");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");

const GroupHeaderRowPrintWriter = PrintWriter.extend({
  init: function (grid, group, header) {
    this._base.apply(this, arguments);

    this.group = group;
    this.header = header;

    if (!this.header.valueProvider || typeof this.header.valueProvider.getPrintValue !== "function") {
      this.header.valueProvider = valueProviderFactory.create(this.header, grid.options);
    }

    if (this.header.features && this.header.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(this.header.features, this.header, this.parent);
    }
  },

  getNodeName: function () {
    return "grid-group-header-row";
  },

  print: function (data, config) {
    const record = gridUtils.getFirstRecord(data);

    if (this.header.printBreak) {
      this.node.addAttribute(this.header.printBreak.attribute, this.header.printBreak.condition);
    }

    this.writeFeatures(this.node, this.features);

    this.node
      .createNode("grid-table-cell", {
        colspan: config.colspan,
        "indentation-level": data.groupIndex * 10 + "px"
      })
      .appendXml(this.header.valueProvider.getPrintValue(record));

    return this.toPrintXml();
  }
});

module.exports = GroupHeaderRowPrintWriter;
plexExport("grid.writers.GroupHeaderRowPrintWriter", GroupHeaderRowPrintWriter);
