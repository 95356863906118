ï»¿/* eslint-disable no-invalid-this */
/* global plex */
// TODO: should use imports instead of plex global
const api = (module.exports = {});

const $ = require("jquery");
const ko = require("knockout");
const repository = require("../Controls/plex-model-repository");
const actionHandler = require("../Controls/plex-handler-action");
const nav = require("../Core/plex-navigate");
const pubsub = require("../Core/plex-pubsub");
const navigation = require("./plex-navigation");
const Searcher = require("./plex-unified-search");
const plexExport = require("../../global-export");
const plexImport = require("../../global-import");
const menuState = require("./Menu/plex-menu-state");
const SearchMethod = require("./plex-unified-search-method");
const Notify = require("../Dialogs/plex-dialogs-notify");
const Ask = require("../Dialogs/plex-dialogs-ask");
const Confirm = require("../Dialogs/plex-dialogs-confirm");
const banner = require("../Plugins/plex-banner");
const Popover = require("../Knockout/plex-popover");
const queryString = require("../Core/plex-query-string");
const urlBuilder = require("../Utilities/plex-utils-url-builder");

const SEARCH_ROUTE = "/Platform/Search";
const HOME_ROUTE = "/DefaultHome";

// Names to identify Vision Plex pages
const VP_SCREEN_INDEX_NAME = "INDEX";
const VP_CONTROLLER_NAME = "SCREEN";
const VP_AREA_NAME = "VISIONPLEX";

const environmentRgx = /^(\[[^\]]+\])/;
const selector = ".plex-navbar-container";
const spaOnUrlPrefix = "/-/";

const searchMethods = [
  new SearchMethod("menu"),
  new SearchMethod("report", { title: "Reports" }),
  new SearchMethod("data")
  // new SearchMethod("help")
];

function getFavorites(oCallback) {
  nav.post("/Platform/Navigation/Favorites", plexImport("currentApplication")).then(oCallback);
}

function getSettings(oCallback) {
  nav
    .post("/Platform/Navigation/Settings", plexImport("currentApplication"), {
      showOverlay: false
    })
    .then(oCallback);
}

function getHelp(oCallback) {
  nav.post("/Platform/Navigation/Help", plexImport("currentApplication")).then(oCallback);
}

function getUserAccount(oCallback) {
  nav.post("/Platform/Navigation/Account", plexImport("currentApplication")).then(oCallback);
}

function getMessages(oCallback) {
  nav.post("/Platform/Navigation/Messages", plexImport("currentApplication")).then(oCallback);
}

function CustomerMenu(options) {
  this.init(options);
}

function appendPrefixForRedirectUrls(items, prefixUrl) {
  if (Array.isArray(items) && items.length > 0) {
    items.forEach((item) => {
      if (Array.isArray(item) && item.length > 0) {
        appendPrefixForRedirectUrls(item, prefixUrl);
      } else if (Array.isArray(item?.links)) {
        appendPrefixForRedirectUrls(item.links, prefixUrl);
      } else if (typeof item === "object" && item?.action?.type === "Redirect") {
        const baseAddress = item.action.address;
        item.action.address = urlBuilder.prefixUrl(baseAddress, prefixUrl);
      }
    });
  }
}

CustomerMenu.prototype = {
  constructor: CustomerMenu,
  init: function (options) {
    this.current = options.customer;
    this.links = ko.observableArray();
    this.updateLandingCustomer = function (userCustomer) {
      Popover.closeAll();

      const doneCallback = function (_response) {
        Notify("Landing company updated. This change will be reflected upon your next login.", () => {
          navigation.refreshPage();
        });
      };

      const failCallback = function (_response) {
        banner
          .getPageBanner()
          .setMessage(
            "An error occurred updating your landing company. Please contact PLEX for help resolving.",
            false
          );
      };

      if (userCustomer.isLandingCompany) {
        Ask(
          "Remove your selected landing company? When logging into PLEX, you will be defaulted to your main company going forward.",
          ["Yes", "No"],
          (answer) => {
            if (answer && answer.answerText === "Yes") {
              nav
                .post("/Platform/Navigation/DeleteLandingUserCustomer")
                .done(doneCallback.bind())
                .fail(failCallback.bind());
            }
          }
        );
      } else {
        Ask(
          {
            text: "Make {1} your landing company? This will become the company you default to when logging into PLEX.",
            tokens: [userCustomer.text]
          },
          ["Yes", "No"],
          (answer) => {
            if (answer && answer.answerText === "Yes") {
              nav
                .post("/Platform/Navigation/UpdateLandingUserCustomer", {
                  UserCustomerKey: userCustomer.userCustomerKey
                })
                .done(doneCallback.bind())
                .fail(failCallback.bind());
            }
          }
        );
      }
    };

    this.load();
  },

  load: function () {
    const self = this;
    $.getJSON(nav.buildUrl("/Platform/Navigation/GetCustomerList")).then((response) => {
      pubsub.publish("navbar.customersLoaded", () => {
        return response;
      });

      const links = response.Customers.Submenu.map((link) => {
        return {
          url: link.Url,
          text: link.Text,
          isLandingCompany: link.LandingUserCustomer,
          userCustomerKey: link.UserCustomerKey
        };
      });

      self.links(links);
    });
  },

  customerToken: function () {
    if (this.links().length > 1) {
      // TODO: abbreviated name would be better, but this isn't passed
      // down to the client currently
      return "[" + plexImport("appState.customer.code") + "]";
    }

    return "";
  }
};

const NavBar = function (el, options) {
  this.$element = $(el);
  this.init(options);
};

// PLUGIN SETUP
NavBar.prototype = {
  init: function (options) {
    /// <summary>
    /// Initializes the navbar and its panels.
    /// </summary>
    const self = this;

    self.customerMenu = new CustomerMenu(options);
    self.favorites = ko.observableArray();
    self.settings = ko.observableArray();
    self.history = ko.observableArray();
    self.help = ko.observableArray();
    self.userAccount = ko.observableArray();
    self.messages = ko.observableArray();

    self.removeHighlightTimer = null;

    self.searchText = ko.observable("");
    self.searcher = new Searcher(searchMethods);
    searchMethods.forEach((m) => {
      m.closeSearchMenu.subscribe((closed) => {
        if (closed) {
          self.searchText("");
          self.searcher.clearResults();
        }
      });
    });

    self.searchText.subscribe(self.searcher.search, self.searcher);

    self.searchText.subscribe((query) => {
      self.searcher.search(query);
    }, self.searcher);

    self.title = ko.observable(options.title);
    self.dataSubTitle = ko.observable(options.dataSubTitle);

    self.noFavoriteForPage = ko.observable(true);

    self.formattedTitle = ko.pureComputed(() => {
      if (self.dataSubTitle()) {
        return self.dataSubTitle() + " (" + self.title() + ")";
      }

      return self.title();
    });

    self.windowTitle = ko.computed(() => {
      const segments = [];

      // preserve environment label
      const match = environmentRgx.exec(document.title);
      if (match) {
        segments.push(match[1]);
      }

      segments.push(self.customerMenu.customerToken());
      segments.push(self.formattedTitle());
      document.title = segments.join(" ");
      return document.title;
    });

    self.formattedTitle.subscribe(this.setWindowTitle, this);

    const spa = plexImport("spa");
    if (!spa?.isSpaRendering?.()) {
      this.checkIfActionIsFavorite();
      this.loadSettings();
    }

    if (this.$element.siblings(".plex-env-persistent-banner-container").length) {
      self.$element.fixedElement({ horizontal: true });
      document.body.style.paddingTop = 0;
    }

    repository.add(options.id, self);
    ko.applyBindings(self, self.$element[0]);
  },

  setWindowTitle: function (_title) {
    // can be overriden
  },

  loadFavorites: function (oCallback, forceRefetch) {
    const self = this;

    const favoriteGroupKey = self.selectedFavoriteGroupKey;
    self.selectedFavoriteGroupKey = undefined;
    if (self.removeHighlightTimer) {
      clearTimeout(self.removeHighlightTimer);
    }

    // refresh favorites when navigating out of favorite's group
    if (self.favorites().length && !forceRefetch) {
      self.openSelectedFavoriteGroup(self.favorites.peek(), favoriteGroupKey);
      oCallback();
    } else {
      getFavorites((data) => {
        const removeFavoriteActions =
          data.groups &&
          data.groups[0] &&
          data.groups[0].links &&
          data.groups[0].links.filter(
            (i) => i.action && i.action.additionalData && i.action.additionalData.isRemoveFavoriteAction
          );

        if (removeFavoriteActions && removeFavoriteActions[0]) {
          removeFavoriteActions[0].hidden = self.noFavoriteForPage;
        }

        self.openSelectedFavoriteGroup(data.groups, favoriteGroupKey);
        self.favorites(data.groups);
        oCallback();
      });
    }
  },

  openSelectedFavoriteGroup: function (favoriteGroups, selectedFavoriteGroupKey) {
    const self = this;
    let result = false;
    if (favoriteGroups && favoriteGroups.length) {
      favoriteGroups.forEach((favoriteGroup) => {
        if (favoriteGroup.links && favoriteGroup.links.length) {
          favoriteGroup.links.forEach((link) => {
            if (link.action && link.action.additionalData && link.action.additionalData.isFavoriteGroup === true) {
              let isSetOpen = false;
              if (link.additionalData && link.additionalData.groups) {
                isSetOpen = self.openSelectedFavoriteGroup(link.additionalData.groups, selectedFavoriteGroupKey);
              }

              if (
                selectedFavoriteGroupKey &&
                Number(link.action.additionalData.favoriteGroupKey) === Number(selectedFavoriteGroupKey)
              ) {
                setLinkProperties(link, true, true);
                result = true;
              } else {
                setLinkProperties(link, isSetOpen, false);
                result = result || isSetOpen;
              }
            }
          });

          // eslint-disable-next-line no-inner-declarations
          function setLinkProperties(link, isSetOpen, isHighlight) {
            if (ko.isObservable(link.isOpen)) {
              link.isOpen(isSetOpen);
            } else {
              link.isOpen = ko.observable(isSetOpen);
            }

            if (ko.isObservable(link.isHighlight)) {
              link.isHighlight(isHighlight);
            } else {
              link.isHighlight = ko.observable(isHighlight);
            }
          }
        }
      });
    }
    return result;
  },

  toggleFavoriteGroup: function (favoriteGroups, favoriteGroupKey) {
    const self = this;
    if (favoriteGroups && favoriteGroups.length > 0) {
      favoriteGroups.forEach((group) => {
        if (group.links && group.links.length > 0) {
          group.links.forEach((link) => {
            if (link.action && link.action.additionalData && link.action.additionalData.isFavoriteGroup === true) {
              if (favoriteGroupKey && link.action.additionalData.favoriteGroupKey === favoriteGroupKey) {
                link.isOpen(!link.isOpen.peek());
                return;
              }

              self.toggleFavoriteGroup(link.additionalData.groups, favoriteGroupKey);
            }
          });
        }
      });
    }
  },

  removeCurrentPageFavorites: function () {
    const self = this;
    const request = self._createFavoriteFindRequest();

    return nav.post(nav.buildUrl("/Platform/Favorites/FindFavoriteActions"), request).then((pageFavorites) => {
      if (!pageFavorites.length) {
        return Notify("No Favorites related to the page").then(() => new $.Deferred().reject());
      }

      let dialogResult;

      if (pageFavorites.length === 1) {
        dialogResult = Confirm("You are removing a saved Favorite. All the content will be removed");
      } else {
        const listOfFavorites = pageFavorites.map((i) => "<li>" + i.FavoriteActionName + "</li>").join("");
        dialogResult = Confirm({
          text: "You are removing several Favorites related to the current page. All the content will be removed {1}",
          tokens: [listOfFavorites]
        });
      }

      return dialogResult.then(() => {
        const favoiteKeysToRemove = pageFavorites.map((i) => i.FavoriteActionKey);
        return nav.post(nav.buildUrl("/Platform/Favorites/RemoveFavoriteActions"), favoiteKeysToRemove).then(() => {
          self.favorites.removeAll();
          self.checkIfActionIsFavorite();
        });
      });
    });
  },

  showFavoriteGroup: function (_oData, additionalData) {
    const self = this;

    self.toggleFavoriteGroup(self.favorites.peek(), additionalData.favoriteGroupKey);

    setTimeout(() => {
      $(".plex-favorites").removeClass("plex-popover-reloading");
    }, 500);
  },

  backToAllFavorites: function () {
    const self = this;

    self.loadFavorites(() => {}, true);
    setTimeout(() => {
      $(".plex-favorites").removeClass("plex-popover-reloading");
    }, 500);
  },

  loadHelp: function (oCallback) {
    const self = this;

    getHelp((data) => {
      self.help(data.groups);
      oCallback();
    });
  },

  loadHistory: function (callback) {
    callback = callback || $.noop;

    if (this.history().length === 0) {
      $.getJSON(nav.buildUrl("/Platform/Navigation/GetHistory/"))
        .then((result) => {
          const spa = plexImport("spa");
          if (spa?.isSpaRendering?.()) {
            appendPrefixForRedirectUrls(result, spaOnUrlPrefix);
          }
          this.history(result);
        })
        .then(callback);
    } else {
      callback();
    }
  },

  loadSettings: function (oCallback) {
    const self = this;
    oCallback = oCallback || $.noop;

    if (self.settings().length === 0 && !$.isEmptyObject(plexImport("currentApplication"))) {
      getSettings((data) => {
        const spa = plexImport("spa");
        if (spa?.isSpaRendering?.()) {
          appendPrefixForRedirectUrls(data.groups, spaOnUrlPrefix);
        }
        self.settings(data.groups);
        oCallback();
      });
    } else {
      oCallback();
    }
  },

  checkIfActionIsFavorite: function () {
    const self = this;

    const favorIcon = $(".plex-favorites-icon")[0];

    if (!favorIcon) {
      return new $.Deferred().reject();
    }

    const request = self._createFavoriteFindRequest();

    return nav
      .post(nav.buildUrl("/Platform/Favorites/CheckIfPageIsFavorite"), request, {
        showOverlay: false
      })
      .then((data) => {
        if (data && data.FavoriteExists) {
          self.noFavoriteForPage(false);
          favorIcon.classList.add("plex-favorites-active-icon");
        } else {
          self.noFavoriteForPage(true);
          favorIcon.classList.remove("plex-favorites-active-icon");
        }
      });
  },

  _createFavoriteFindRequest: function () {
    const currentApplication = plexImport("currentApplication");

    const request = {
      cloudApplicationActionKey: currentApplication.ActionKey,
      queryString: queryString.getDbFriendlyForm() || null,
      tryEmptyQueryString: false
    };

    const isVisionPlex =
      currentApplication.ActionName &&
      currentApplication.ControllerName &&
      currentApplication.AreaName &&
      currentApplication.ActionName.toUpperCase() === VP_SCREEN_INDEX_NAME &&
      currentApplication.ControllerName.toUpperCase() === VP_CONTROLLER_NAME &&
      currentApplication.AreaName.toUpperCase() === VP_AREA_NAME;

    if (isVisionPlex && request.queryString) {
      const queryWithActionKeyOnly = `{"__actionKey":"${currentApplication.ActionKey}"}`;

      // Vision Plex pages with only  __actionKey query parameter can
      // be stored in favorite list in 2 different manners: ActionKey + query string or just ActionKey
      // So we need to check both options for such pages
      if (queryWithActionKeyOnly === request.queryString) {
        request.tryEmptyQueryString = true;
      }
    }

    return request;
  },

  loadCompass: function (oCallback) {
    pubsub.publish("compass.load", oCallback);
  },

  loadUserAccount: function (oCallback) {
    const self = this;
    oCallback = oCallback || $.noop;

    if (self.userAccount().length === 0) {
      getUserAccount((data) => {
        self.userAccount(data.groups);
        oCallback();
      });
    } else {
      oCallback();
    }
  },

  loadMessages: function (oCallback) {
    const self = this;
    oCallback = oCallback || $.noop;

    if (self.messages().length === 0) {
      getMessages((data) => {
        self.messages(data.groups);
        oCallback();
      });
    } else {
      oCallback();
    }
  },

  search: function () {
    this.searcher.cancel();

    const query = this.searchText();
    if (!query) {
      return;
    }

    nav.navigate(SEARCH_ROUTE, { query });
  },

  navigate: function (data) {
    // deprecated
    if (data.script) {
      /* eslint no-new-func: "off" */
      new Function(data.script)();
      return;
    }

    // deprecated
    if (data.url) {
      nav.navigate(data.url);
      return;
    }

    if (!data.action) {
      return;
    }

    if (
      data.action.additionalData &&
      (data.action.additionalData.isFavoriteGroup === true || data.action.additionalData.isRemoveFavoriteAction)
    ) {
      data.parent = $(selector).data("navbar");
      actionHandler.initAction(data.action, data);
    } else {
      actionHandler.initAction(data.action, navigation);
    }

    actionHandler.executeAction(data.action, data.additionalData);
    if (data.additionalData && typeof data.additionalData === "object" && "groups" in data.additionalData) {
      // A popover automatically closes when you click on it.
      // We don't want this to happen when we have a favorite group,
      // so there's a class added and then removed after a period of time.
      $(".plex-favorites").addClass("plex-popover-reloading");
    }
    $(document).click();
  },

  navigateHome: function () {
    menuState.clearStates();
    nav.navigate(this._navHomeUrl);

    // prevent default navigation
    return false;
  },

  getFormattedHotkey: function (hotKey) {
    return hotKey ? "(" + hotKey + ")" : hotKey;
  },

  removeGroupHighlighting: function () {
    const self = this;

    self.removeHighlightTimer = setTimeout(() => {
      const $highlightGroup = self.getFavoriteHighlightedGroup();
      if ($highlightGroup.length > 0) {
        const data = ko.dataFor($highlightGroup[0]);
        if (data && data.isHighlight) {
          data.isHighlight(false);
        }
      }
    }, 5 * 1000);
  },

  scrollToHighlightedGroup: function () {
    const self = this;

    const $divFavorites = self.$element.find(".plex-favorites");
    const $highlightGroup = self.getFavoriteHighlightedGroup();
    if ($highlightGroup.length) {
      const elementPosition = $divFavorites.scrollTop() + $highlightGroup.position().top;
      const scrollHeight = $divFavorites[0].scrollHeight;
      const divHeight = $divFavorites.height();
      const elementHeight = $highlightGroup.height();

      if (elementPosition <= divHeight / 2 + elementHeight) {
        $divFavorites.animate({ scrollTop: 0 }, 200);
      } else if (elementPosition >= scrollHeight - divHeight / 2 - elementHeight) {
        $divFavorites.animate({ scrollTop: elementPosition }, 200);
      } else {
        $divFavorites.animate({ scrollTop: elementPosition - divHeight / 2 }, 200);
      }
    }
  },

  getFavoriteHighlightedGroup: function () {
    return this.$element.find(".plex-favorites .plex-favorites-group-highlight");
  },

  showHighlightedGroup: function () {
    this.scrollToHighlightedGroup();
    this.removeGroupHighlighting();
  },

  onOpenFavorites: function () {
    this.showHighlightedGroup();
  },

  get navHomeUrl() {
    return this._navHomeUrl || (this._navHomeUrl = nav.buildUrl(HOME_ROUTE));
  }
};

$.fn.navbar = function (options) {
  /// <summary>
  /// Attaches events & data gathering functions to the navbar.
  /// </summary>
  /// <param name="options"></param>
  /// <returns>The jQuery object that called navbar.</returns>

  const settings = $.extend(
    {
      // These are the defaults.
    },
    options
  );

  return this.each(function () {
    let currentNavBar = $(this).data("navbar");

    if (!currentNavBar) {
      currentNavBar = new NavBar(this, settings);
      $(this).data("navbar", currentNavBar);
    }

    return this;
  });
};

api.clearFavorites = function () {
  const navbar = $(selector).data("navbar");
  navbar.favorites.removeAll();
};

api.resetFavorites = function () {
  api.clearFavorites();
  $(selector).data("navbar").checkIfActionIsFavorite();
};

api.reset = function () {
  const navbar = $(selector).data("navbar");
  navbar.searchText("");
  navbar.settings.removeAll();
  navbar.history.removeAll();
  navbar.help.removeAll();
  this.resetFavorites();
};

api.closePanels = function () {
  // TODO: Make this better.
  $(selector).data("navbar").openedPanel("");
};

api.getCurrentNavbar = function () {
  return $(selector);
};

api.openSearch = function () {
  $(".plex-navbar-search-input").focus();
};

api.openMenu = (event, data) => {
  const $navbar = api.getCurrentNavbar();

  const $menuContainer = $navbar.find(".plex-compass-icon-container");
  const $menu = $menuContainer.find(".plex-menu-action.plex-menu-icon");
  if ($menuContainer.length > 0) {
    const containerClasses = $menuContainer.attr("class").split(/\s+/);

    // if main menu is opened, force reopen it
    if (
      containerClasses.filter((c) => {
        return c === "open";
      }).length > 0
    ) {
      $menu.trigger("click.popover");

      setTimeout(() => {
        open($menu, event, data.MenuNodeKey);
      }, 500);
    } else {
      open($menu, event, data.MenuNodeKey);
    }
  }

  function open($element, clickEvent, menuNodeKey) {
    plex.PageState.addToCurrentState("selectedNodeKey", menuNodeKey);
    plex.PageState.addToCurrentState("isSearchNode", true);

    clickEvent.stopPropagation();
    $element.trigger("click.popover");
  }
};

api.search = (query) => {
  const navbar = $(selector).data("navbar");
  navbar.searchText(query);
  navbar.search();
};

api.setTitle = (title, subtitle) => {
  const navbar = $(selector).data("navbar");
  navbar.title(title);
  navbar.dataSubTitle(subtitle);
};

api.openFavoritesGroupMenu = (additionalData) => {
  const $navbar = api.getCurrentNavbar();

  if ($navbar.length > 0) {
    const $favorites = $navbar.find(".plex-menu-action.plex-favorites-icon");
    const $favoritePopover = $favorites.closest("li");
    const data = $navbar.data("navbar");

    if ($favoritePopover.hasClass("open")) {
      if (data.removeHighlightTimer) {
        clearTimeout(data.removeHighlightTimer);
      }

      data.openSelectedFavoriteGroup(data.favorites.peek(), additionalData.favoriteGroupKey);
      data.showHighlightedGroup();
    } else {
      data.selectedFavoriteGroupKey = additionalData.favoriteGroupKey;
      $favorites.trigger("click.popover");
    }
  }
};

plexExport("navbar", api);
