ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const elementHandler = require("./plex-handler-element");
const DialogController = require("../Dialogs/plex-dialog-controller");
const plexExport = require("../../global-export");

const ACTION_CONTAINER_SUFFIX = "_Actions";

const ActionContainer = function (parent, actions) {
  this.parent = parent;
  this.actions = actions;
  this.init();
};

ActionContainer.prototype = {
  constructor: ActionContainer,

  init: function () {
    const parent = this.parent;
    const $buttons = $(document.getElementById(this.parent.config.id + ACTION_CONTAINER_SUFFIX));

    const dialog = DialogController.find($buttons);
    if (dialog) {
      this.setupDialog(dialog, $buttons);
    }

    if (this.actions && this.actions.length > 0) {
      if ($buttons.length === 0) {
        throw new Error("Unable to find the button section to bind the actions to.");
      }

      this.actions.forEach((button) => {
        button.parent = parent;
        elementHandler.initElement(button, parent.data, null, parent);
      });

      ko.applyBindings(this.parent, $buttons[0]);
    } else {
      $buttons.closest(".plex-form-footer").add($buttons).hide();
    }
  },

  setupDialog: function (dialog, $buttons) {
    const $dialogContent = dialog.dialogElement.find(".modal-content");
    const $gridButtons = dialog.dialogElement.find(".plex-grid-buttons");
    $dialogContent.toggleClass(
      "plex-dialog-has-buttons",
      (this.actions && this.actions.length > 0) || ($gridButtons && $gridButtons.length > 0)
    );

    $dialogContent.find(".modal-footer").append($buttons);
  }
};

module.exports = ActionContainer;
plexExport("ActionContainer", ActionContainer);
