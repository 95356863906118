const ko = require("knockout");
const $ = require("jquery");
const ResizeObserver = require("ResizeObserver");
const js = require("../Utilities/plex-utils-js");

const comparers = {
  "max-width": (h, w, value) => value >= w,
  "min-width": (h, w, value) => value <= w,
  "max-height": (h, w, value) => value >= h,
  "min-height": (h, w, value) => value <= h
};

function buildQuery(criteria) {
  const keys = Object.keys(criteria);
  return function (height, width) {
    return keys.every((key) => {
      if (!(key in comparers)) {
        throw Error("Unable to find a match for constraint: " + key);
      }

      const value = parseInt(criteria[key], 10);
      return comparers[key](height, width, value);
    });
  };
}

function parseQuery(query) {
  return Object.keys(ko.toJS(query)).reduce((acc, key) => {
    acc[key] = buildQuery(query[key]);
    return acc;
  }, {});
}

ko.bindingHandlers.mediaQuery = {
  init: function (element, valueAccessor) {
    let query = ko.pureComputed(() => parseQuery(valueAccessor()));
    const $element = $(element);
    const $parent = $element.scrollParent();

    const handleResize = () => {
      if (typeof query !== "function") {
        return;
      }

      const height = Math.ceil($parent.outerHeight(true));
      const width = Math.ceil($parent.outerWidth(true));
      const q = query();

      Object.keys(q).forEach((key) => {
        $element.toggleClass(key, q[key](height, width));
      });
    };

    query.subscribe(handleResize);

    let observer = new ResizeObserver(js.throttleRender(handleResize));
    observer.observe($parent[0].documentElement || $parent[0]);

    // set initial values
    handleResize();

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      observer.disconnect();
      observer = null;

      query.dispose();
      query = null;
    });
  }
};
