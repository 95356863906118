ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const elementHandler = require("../Controls/plex-handler-element");
const plexExport = require("../../global-export");

let uid = 0;

function findMatch(el, query) {
  if (query(el)) {
    return el;
  }

  if (el.elements && el.elements.length > 0) {
    let i = el.elements.length;
    let match;

    while (i--) {
      match = findMatch(el.elements[i], query);
      if (match) {
        return match;
      }
    }
  }

  return null;
}

const RecordElementController = function (record, index, column, parent) {
  this.record = record;
  this.index = index;
  this.column = column;
  this.parent = parent;
  this.init();
};

RecordElementController.prototype = {
  constructor: RecordElementController,

  init: function () {
    this.elements = {};
    this.validator = this.parent.controller.validator;
    this.controllerInitsPropertyValidation = true;
    this.isCrossTab = !!this.parent.pivotKeyPropertyName;
  },

  initElement: function (config, features, colIndex, index) {
    if (
      (typeof index === "number" && index !== this.index) ||
      (index != null && typeof index === "object" && index.id !== this.index.id)
    ) {
      // if the record or group changes position then we need to dispose
      // all existing elements so that they get re-created
      // with the appropriate IDs - otherwise there can be ID
      // collisions for records inserted between two existing
      // records.
      // todo: it would be preferrable to find another way
      // to uniquely identify the elements in a way that isn't
      // dependent on the record index.
      this.index = index;
      this.dispose();
    }

    if (config.id) {
      const elId = colIndex == null ? config.id : `${config.id}_${colIndex}`;
      if (elId in this.elements) {
        return this.elements[elId];
      }
    }

    // we need to make a deep clone of the element because the config will be reused.
    const clone = $.extend(true, {}, ko.toJS(config));
    this._prepareElement(clone, features, colIndex);
    elementHandler.initElement(clone, this.record, null, this, index || this.index);

    clone.colIndex = colIndex;
    return clone;
  },

  getElement: function (matchFn) {
    for (const currentId in this.elements) {
      if (Object.prototype.hasOwnProperty.call(this.elements, currentId)) {
        const el = findMatch(this.elements[currentId], matchFn);
        if (el) {
          return el;
        }
      }
    }

    return null;
  },

  afterRender: function (element, el) {
    if (el.events && el.events.length > 0) {
      const bindings = {};
      el.events.forEach((e) => {
        bindings[e.event.bindingName] = e.action.executeAction.bind(e.action);
      });

      ko.applyBindingsToNode(element, bindings, el);
    }
  },

  _prepareElement: function (el, features, colIndex) {
    const self = this;

    // Push features into the element
    if (features) {
      features.forEach((feature) => {
        el.features.push(feature);
      });
    }

    // save a reference with the original id to simplify looksup
    this.elements[el.id] = el;
    if (colIndex != null) {
      // store a column specific reference - this will be necessary for crosstabs
      this.elements[`${el.id}_${colIndex}`] = el;
    }

    // update with unique id
    const originalId = el.id;
    el.id = getUniqueElementId(el.id, this.index, this.isCrossTab ? colIndex : null);
    el.originalId = originalId;

    // recurse through any child elements
    if (el.elements) {
      arrayUtils.removeEmpty(el.elements);

      el.elements.forEach((child) => {
        self._prepareElement(child, el.features);
      });
    }

    this._prepareAction(el.action);
    this._setBindingTarget(el);
    this._setDataSourceTarget(el, originalId);
  },

  _prepareAction: function (action) {
    if (!action || !this.parent) {
      return;
    }

    // if the action is a child of the grid and uses the grid as it's source
    // this would cause it to use the selected item collection for it's data
    // instead what we want is to use the data from the current row, so clear
    // out the action in this case - this can happen if an action is reused
    // between an actionbar & a grid row
    if (action.sourceId && action.sourceId === this.parent.id) {
      action.sourceId = null;
    }

    this._prepareAction(action.postAction);
  },

  _setBindingTarget: function (el) {
    if (el.bindings && el.bindings.length > 0) {
      const self = this;
      const parentId = this.parent && this.parent.id;
      if (parentId) {
        el.bindings.forEach((binding) => {
          // if the binding is for the parent then the actual
          // target should be the current record
          if (binding.targetKey === parentId) {
            binding.target = self.record;
          }
        });
      }
    }
  },

  _setDataSourceTarget: function (el, originalId) {
    if (el.dataSource) {
      const dataSource = el.dataSource.route || el.dataSource;
      if (dataSource.parameters && dataSource.parameters.length > 0) {
        const self = this;
        const parentId = this.parent && this.parent.id;

        dataSource.parameters.forEach((param) => {
          if (param.binding) {
            if (parentId && param.binding.targetKey === parentId) {
              param.binding.target = self.record;
            }
            // pickers bind dataSource parameter for input text to itself
            else if (originalId && param.binding.targetKey === originalId) {
              param.binding.targetKey = el.id;
            }
          }
        });
      }
    }
  },

  dispose: function () {
    // todo: it might be worthwhile to look at
    // inheriting from controller-base, though i think this controller should
    // be very lightweight

    Object.keys(this.elements).forEach((id) => {
      this.elements[id].dispose?.();
      delete this.elements[id];
    });
  }
};

function getUniqueElementId(id, index, colIndex) {
  if (index == null && colIndex == null) {
    // this is *probably* occuring within a section (header|footer)
    return id + "_section_grid_control_" + String(uid++);
  }

  colIndex = colIndex == null ? "" : "_" + colIndex;
  if (index == null) {
    return id + colIndex;
  }

  if (typeof index === "number") {
    return id + "_grid_control_" + index + colIndex;
  }

  return id + "_" + (index.id || index) + colIndex;
}

module.exports = RecordElementController;
plexExport("grid.RecordElementController", RecordElementController);
