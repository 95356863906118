const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");
require("../Polyfills/string.padEnd"); // eslint-disable-line import/no-unassigned-import
require("../Polyfills/string.padStart"); // eslint-disable-line import/no-unassigned-import

const PadLeft = Token.extend({
  getValue: function (...args) {
    const value = String(this.getAttr("value", args));
    const length = parseInt(this.getAttr("length", args), 10);
    const ch = this.getAttr("char") || " ";

    if (shouldPad(length, value)) {
      return value.padStart(length, ch);
    }

    return value;
  }
});

const PadRight = Token.extend({
  getValue: function (...args) {
    const value = String(this.getAttr("value", args));
    const length = parseInt(this.getAttr("length", args), 10);
    const ch = this.getAttr("char") || " ";

    if (shouldPad(length, value)) {
      return value.padEnd(length, ch);
    }

    return value;
  }
});

function shouldPad(length, value) {
  if (isNaN(length) || !isFinite(length)) {
    return false;
  }

  return length > value.length;
}

module.exports = { PadLeftToken: PadLeft, PadRightToken: PadRight };
plexExport("tokens.padleft", PadLeft);
plexExport("tokens.padright", PadRight);
