ï»¿const ko = require("knockout");
const $ = require("jquery");
const stringUtils = require("../Utilities/plex-utils-strings");

// use this text binding if the value may or may not be encoded
ko.bindingHandlers.encodedText = {
  update: function (element, valueAccessor) {
    const text = stringUtils.unescapeHtml(ko.unwrap(valueAccessor()) || "");
    $(element).text(text);
  }
};
