ï»¿const ko = require("knockout");
const $ = require("jquery");
const domUtils = require("../Utilities/plex-utils-dom");

ko.bindingHandlers.resize = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const options = valueAccessor();
    const context = bindingContext.$data;
    const namespace = viewModel.config.id;
    const $target = domUtils.isInDialog(element) ? $(element).closest(".ui-resizable") : $(window);

    if (typeof options === "function") {
      $target.on("resizeCompleted." + namespace, $.proxy(options, context));
    } else {
      if (options.x) {
        $target.on("resizeCompleted-x." + namespace, $.proxy(options.x, context));
      }

      if (options.y) {
        $target.on("resizeCompleted-y." + namespace, $.proxy(options.y, context));
      }
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $target.off(
        "resizeCompleted" + namespace + " resizeCompleted-x." + namespace + " resizeCompleted-y." + namespace
      );
    });
  }
};
