import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { ReactFlowProvider } from "reactflow";
import { PlexFlow, FunctionSubscriberProvider } from "@plex/react-flows-designer";
import ControllerFactory from "../plex-controller-factory";
import { post, navigate } from "../../Core/plex-navigate";
import { ComponentLocale } from "../../React/Components/ComponentLocale";

const _FlowDesigner = (designerControllerParams) => {
  // Function that we need to expose to flow
  const datasourceCall = (searchName) => {
    const request = {
      Name: searchName
    };
    return post("/Platform/Flows/SearchDataSource", request);
  };

  const datasourceDetailCall = (id) => {
    const request = {
      Id: Number(id)
    };

    return post("/Platform/Flows/GetDataSourceSchema", request);
  };

  const showOverlay = (status) => {
    if (status) {
      $(document).block();
    } else {
      $(document).unblock();
    }
  };

  const adhocRunFlowCall = (document) => {
    return post("/Platform/Flows/AdhocRun", document);
  };

  const navigateToDataSourcePage = (data, openNewTab = false) => {
    let options = {};
    const dataSourceDetailUrl = "/Platform/CustomerDataSourceManager/Form";
    const dataSourceUrl = "/Platform/CustomerDataSourceManager";

    if (openNewTab) {
      options = { newWindow: true };
    }
    navigate(data === null ? dataSourceUrl : dataSourceDetailUrl, data, options);
  };

  const plexGetTraceEntriesCall = (executionId, offset, limit) => {
    const request = {
      executionId,
      offset,
      limit
    };

    const options = {
      showOverlay: false
    };

    return post("/Platform/Flows/GetTraceEntries", request, options);
  };

  const plexGetTraceSnapshotsCall = (executionId, entryIndexes) => {
    const request = {
      executionId,
      entryIndexes
    };

    const options = {
      showOverlay: false
    };

    return post("/Platform/Flows/GetSnapshots", request, options);
  };

  const plexSearchStandardObjectsCall = (friendlyName) => {
    const request = {
      FriendlyName: friendlyName
    };

    return post("/Platform/Flows/SearchStandardObject", request);
  };

  const plexGetCustomFieldsSchemaCall = (standardObjectId, standardObjectFriendlyName) => {
    const request = {
      StandardObjectId: standardObjectId,
      StandardObjectFriendlyName: standardObjectFriendlyName
    };

    return post("/Platform/Flows/GetCustomFieldsSchema", request);
  };

  return (
    <div>
      <ComponentLocale>
        <ReactFlowProvider>
          <FunctionSubscriberProvider
            plexFetchDsApi={datasourceCall}
            plexFetchDsDetailApi={datasourceDetailCall}
            plexShowOverlay={showOverlay}
            adhocRunFlow={adhocRunFlowCall}
            navigateToDataSourcePage={navigateToDataSourcePage}
            plexGetTraceEntries={plexGetTraceEntriesCall}
            plexGetTraceSnapshots={plexGetTraceSnapshotsCall}
            plexSearchStandardObjects={plexSearchStandardObjectsCall}
            plexGetCustomFieldsSchema={plexGetCustomFieldsSchemaCall}
          >
            <PlexFlow plexFlowDesignerController={designerControllerParams.plexFlowDesignerController} />
          </FunctionSubscriberProvider>
        </ReactFlowProvider>
      </ComponentLocale>
    </div>
  );
};

class FlowDesignerController {
  constructor(config, model) {
    this.config = config;
    this.model = model;
    this.init();
  }

  init() {
    const { id } = this.config;
    const container = document.getElementById(id + "_FlowDesignerContainer");
    if (container) {
      const root = ReactDOM.createRoot(container);
      root.render(<_FlowDesigner plexFlowDesignerController={this} />);
    }
  }
}

FlowDesignerController.create = (config, model) => new FlowDesignerController(config, model);
ControllerFactory.register("Flows/_FlowDesigner", FlowDesignerController);
