ï»¿/* eslint-disable no-invalid-this */
const plexExport = require("../../global-export");

const StopWatch = function (start) {
  this.elapsed = 0;

  if (start) {
    this.start();
  }
};

StopWatch.prototype = {
  constructor: StopWatch,

  start: function () {
    this.elapsed = 0;
    this._startTick = new Date().getTime();
  },

  stop: function () {
    this.elapsed = new Date().getTime() - this._startTick;
    this._startTick = null;
  }
};

StopWatch.startNew = function () {
  return new StopWatch(true);
};

module.exports = StopWatch;
plexExport("utils.StopWatch", StopWatch);
