ï»¿const Token = require("./plex-tokens-token-base");
const ConstantToken = require("./plex-tokens-constant");
const plexExport = require("../../global-export");

const CoalesceToken = Token.extend({
  getValue: function () {
    let tokens = this.attr.value;
    let value;

    if (!Array.isArray(tokens)) {
      tokens = [tokens];
    }

    const ln = tokens.length;
    let i = 0;

    while (i < ln && !(value = tokens[i].getValue.apply(tokens[i], arguments))) {
      i++;
    }

    return value || "";
  },

  parseAttributeValue: function (key, value) {
    if (key === "value") {
      const optionsSplitRgx = /(?:^|\s*,?\s*)(?:(\{\w+:?(?:\\.|[^}])*\})|([^,]+))/g;

      let match;
      const options = [];

      while ((match = optionsSplitRgx.exec(value))) {
        const tokenMatch = match[1];
        const constantMatch = match[2];

        if (tokenMatch) {
          options.push(Token.parse(tokenMatch));
        } else if (constantMatch) {
          options.push(new ConstantToken(constantMatch));
        }
      }

      return options;
    } else {
      return this.prototype.parseAttributeValue(key, value);
    }
  }
});

module.exports = CoalesceToken;
plexExport("tokens.coalesce", CoalesceToken);
