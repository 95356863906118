ï»¿const ko = require("knockout");

ko.extenders.and = function (target, observable) {
  const writer = ko.isWritableObservable(target) ? target : undefined;
  return ko.pureComputed({
    read: function () {
      return target() && observable();
    },
    write: writer
  });
};

ko.extenders.or = function (target, observable) {
  const writer = ko.isWritableObservable(target) ? target : undefined;
  return ko.pureComputed({
    read: function () {
      return target() || observable();
    },
    write: writer
  });
};

// shortcuts to the extenders
ko.subscribable.fn.orElse = function () {
  const observable = ko.isObservable(arguments[0]) ? arguments[0] : ko.pureComputed(...arguments);
  return this.extend({ or: observable });
};

ko.subscribable.fn.andAlso = function () {
  const observable = ko.isObservable(arguments[0]) ? arguments[0] : ko.pureComputed(...arguments);
  return this.extend({ and: observable });
};
