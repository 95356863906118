ï»¿const LabelPrintControllerFactory = require("./Controllers/plex-labelprinting-controller-factory");
const plexExport = require("../../../global-export");

const printLabel = function (dataModel, labelFormatConfiguration) {
  /// <summary>Prints a label for the given model and label configuration.</summary>
  /// <param name="dataModel">The source model which will be used when executing expressions for the label parameters.</param>
  /// <param name="labelFormatConfiguration">
  /// An object which defines some properties about the label format itself.
  /// This is expected to be a serialized instance of the ILabelFormatConfiguration interface.
  /// </param>

  const factory = new LabelPrintControllerFactory();
  const controller = factory.getLabelPrintController(labelFormatConfiguration);
  return controller.print(dataModel, labelFormatConfiguration);
};

module.exports = printLabel;
plexExport("barcoding.labelPrinting.printLabel", printLabel);
