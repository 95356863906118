ï»¿const Globalize = require("globalize");
const $ = require("jquery");
const logger = require("../Core/plex-logger");
const js = require("../Utilities/plex-utils-js");
const dateCulture = require("./plex-culture-datetime");
const numberCulture = require("./plex-culture-number");
const plexImport = require("../../global-import");

function trySetLocale(locales) {
  for (let i = 0, ln = locales.length; i < ln; i++) {
    const cultureCode = locales[i];
    try {
      // set globalize to our specific culture/locale
      const cldr = Globalize.locale(cultureCode);
      if (cldr.attributes.bundle) {
        return cultureCode;
      }
    } catch (err) {
      // ignore
    }
  }

  logger.warn(
    `Unable to retrieve locale information for any of the following locales: ${locales.join(
      ", "
    )}. Locale specific formatting will fail!`
  );
  return false;
}

// note: we need to wait for the page to be ready to grab the serialized app state
// todo: we could change the order that the localization scripts are loaded in order to improve this
$(() => {
  const appState = plexImport("appState");

  if (!appState || !appState.customer) {
    logger.warn('AppState is not available for globalization. Neutral English culture "en" will be used.');
    trySetLocale(["en"]);
    return;
  }

  // set culture code from customer to deal with formatting related items only.
  // language/translation related configuration are dealt with afterwards.
  const customerCulture = appState.customer.globalizationInfo.culture;
  let cultureCode = customerCulture.cultureCode || "en";
  const languageCulture = appState.language.cultureCode;
  const language = languageCulture.split("-")[0].toLowerCase();
  const locales = [languageCulture];

  if (languageCulture !== cultureCode) {
    // prefer language culture + customer locale, language culture, then customer culture
    // customer specific formatting characteristics will overwrite the current locale specifics
    const cultureLocale = cultureCode.split("-")[1];
    if (cultureLocale) {
      locales.unshift(`${language}-${cultureLocale}`);
    }

    // finally fallback to customer's culture
    locales.push(cultureCode);
  }

  cultureCode = trySetLocale(locales);
  if (!cultureCode) {
    return;
  }

  const locale = (cultureCode.split("-")[1] || cultureCode).toUpperCase();

  // add class for potential culture specific style targeting
  $("html").addClass(cultureCode.toLowerCase());

  const cldr = Globalize.locale();

  let weekDataOverrides = {};
  if (customerCulture.dateTime.firstDayOfWeek != null) {
    const firstDay = dateCulture.getDayKeyword(customerCulture.dateTime.firstDayOfWeek);
    weekDataOverrides = {
      firstDay: {
        [locale]: firstDay
      }
    };
  }

  const dateFormatOverrides = {};
  const timeFormatOverrides = {};
  const availableFormatsOverrides = {};

  if (customerCulture.dateTime.shortDateFormat) {
    availableFormatsOverrides.yMd = dateCulture.getCldrDateTimeFormat(customerCulture.dateTime.shortDateFormat);
  }

  ["short", "long"].forEach((kw) => {
    if (customerCulture.dateTime[kw + "DateFormat"]) {
      dateFormatOverrides[kw] = dateCulture.getCldrDateTimeFormat(customerCulture.dateTime[kw + "DateFormat"]);
    }

    if (customerCulture.dateTime[kw + "TimeFormat"]) {
      timeFormatOverrides[kw] = dateCulture.getCldrDateTimeFormat(customerCulture.dateTime[kw + "TimeFormat"]);
    }
  });

  const numberingSystem = cldr.main("numbers/defaultNumberingSystem");
  const overrides = {
    main: {
      [cldr.attributes.bundle]: {
        numbers: {
          // replace symbols
          ["symbols-numberSystem-" + numberingSystem]: {
            decimal: customerCulture.numeric.numberDecimalSeparator,
            group: customerCulture.numeric.numberGroupSeparator,
            percentSign: customerCulture.numeric.percentSymbol
          }
        },
        dates: {
          calendars: {
            gregorian: {
              // replace datetime formats
              dateFormats: dateFormatOverrides,
              timeFormats: timeFormatOverrides,
              dateTimeFormats: { availableFormats: availableFormatsOverrides }
            }
          }
        }
      }
    },
    supplemental: {
      weekData: weekDataOverrides
    }
  };

  Globalize.load(overrides);

  // hack: this will make Wijmo stop crying
  // fixes IP-5528
  Globalize.findClosestCulture = js.deprecate(
    $.noop,
    "This method is no longer a valid method. You are execting a version of Globalize prior to 1.0.0"
  );

  // backwards compatibility
  Globalize.format = js.deprecate((value, format) => {
    if (value && value instanceof Date) {
      return dateCulture.formatDate(value, format);
    }

    return numberCulture.formatNumber(value, format);
  }, "Globalize has been updated and the new API is different. However please use Plex specific format functions under `plex.culture.formatNumber` and `plex.culture.formatDate` instead.");
});
