ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const modelRepository = require("../Controls/plex-model-repository");
const valueProviderFactory = require("../Labels/plex-labels-value-providers-factory");
const plexExport = require("../../global-export");

function flattenValue(value) {
  if (value && Array.isArray(value)) {
    return value.join(",");
  }

  return value;
}

const DataSourceRequest = function (options) {
  this.request = options.request || {};
  this.params = options.parameters || [];
};

DataSourceRequest.prototype = {
  constructor: DataSourceRequest,

  build: function (data) {
    const results = $.extend({}, this.request);
    const promises = [];

    this.params.forEach((param) => {
      let value, model, provider;

      // todo: we could refactor this so that each path creates an evaluator function
      // and then just use that function in each case
      if (data && param.name in data) {
        value = ko.unwrap(data[param.name]);
        if (value !== undefined) {
          results[param.name] = flattenValue(value);
        }
      } else if (param.binding) {
        model = param.binding.target || modelRepository.get(param.binding.targetKey);
        if (model) {
          // detect dependencies for now, though we may end up wanting to make this conditional
          value = dataUtils.getValue(model, param.binding.targetPropertyName, true);
          if (value !== undefined) {
            const flatValue = flattenValue(value);
            results[param.name] = flatValue === "" || flatValue === null ? results[param.name] : flatValue;
          }
        }
      } else if (param.valueProvider) {
        if (!param.evaluator) {
          provider = valueProviderFactory.create(param);
          param.evaluator = provider.getValue.bind(provider);
        }

        if (param.sourceId && !param.source) {
          param.source = modelRepository.get(param.sourceId);
        }

        value = param.evaluator(param.source || data);
        if (value !== undefined) {
          const propertyName = param.name || param.alias || param.propertyName;
          if (jsUtils.isPromise(value)) {
            // update the request object when the promise is resolved
            promises.push(value.then((val) => (results[propertyName] = flattenValue(val))));
          } else {
            results[propertyName] = flattenValue(value);
          }
        }
      }

      if (param.value) {
        // copy assigned value forward
        results[param.name] = param.value;
      }
    });

    if (promises.length > 0) {
      // wait until all promises are resolved then return the results
      return $.when(...promises).then(() => results);
    }

    return results;
  },

  isValid: function (request) {
    const required = this.params.filter((p) => {
      return p.required;
    });
    let i = required.length;
    let param;

    while (i--) {
      param = required[i];
      if (request[param.name] == null || request[param.name] === "") {
        return false;
      }
    }

    return true;
  }
};

module.exports = DataSourceRequest;
plexExport("DataSourceRequest", DataSourceRequest);
