ï»¿const logger = require("../Core/plex-logger");
const TokenParser = require("../Tokens/plex-tokens-parser");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();
const INFINITY_DEFAULT_VALUE = "#infinity#";
const ERROR_DEFAULT_VALUE = "#error#";

function CalculationExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

CalculationExpression.prototype = {
  constructor: CalculationExpression,

  init: function (expr) {
    const compiledBody = tokenParser.compile(expr.body);
    this.evaluator = function () {
      const expression = compiledBody.apply(this, arguments);
      let value;

      try {
        /* eslint no-new-func: "off" */
        value = new Function("return " + expression + ";")();
        if (isNaN(value)) {
          // divide by zero doesn't cause an error in JS - instead the value is
          // infinity (unless 0/0 which is NaN)
          return expr.divideByZeroValue || ERROR_DEFAULT_VALUE;
        }

        if (!isFinite(value)) {
          return expr.infinityValue || INFINITY_DEFAULT_VALUE;
        }

        return value;
      } catch (ex) {
        logger.error("Error evaluating expression: " + expr.body);
        logger.error(ex);
        return ERROR_DEFAULT_VALUE;
      }
    };
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
};

module.exports = CalculationExpression;
plexExport("expressions.Calculate", CalculationExpression);
