ï»¿const $ = require("jquery");
const nav = require("../Core/plex-navigate");
const banner = require("./plex-banner");
const plexExport = require("../../global-export");

const downloadFile = function (downloadActionUri, options) {
  const defaultOptions = {
    httpMethod: "GET",
    fileDownloadCookieName: "fileDownload",
    fileDownloadErrorCookieName: "fileDownloadError",
    fileDownloadErrorElementId: "customError",
    defaultCookiePath: "/"
  };

  const defer = new $.Deferred();

  const downloadOptions = $.extend(defaultOptions, options);

  const actionUri = nav.buildUrl(
    downloadActionUri,
    downloadOptions.httpMethod.toUpperCase() === "GET" ? downloadOptions.data : null
  );

  if (downloadOptions.httpMethod.toUpperCase() === "GET") {
    downloadOptions.data = null;
  }

  $(document).block();
  $.fileDownload(actionUri, downloadOptions)
    .done(() => {
      $(document).unblock();
      defer.resolve();
    })
    .fail((responseHtml, url) => {
      $(document).unblock();

      const failureResponse = {
        message: "An error has occurred. Please try again.",
        responseHtml,
        unknownError: true,
        url
      };

      // a Plex defined error has occurred that we need to show the user.
      if ($.cookie(downloadOptions.fileDownloadErrorCookieName)) {
        const htmlFragment = $.parseHTML(responseHtml);
        const message = $(htmlFragment)
          .filter("#" + downloadOptions.fileDownloadErrorElementId)
          .html();

        failureResponse.message = message;
        failureResponse.unknownError = false;
        failureResponse.responseHtml = null;
      }

      let errorMessage = "";

      if (responseHtml) {
        errorMessage = $(responseHtml).find("#internalErrorMessage").text();
      }

      errorMessage = errorMessage || failureResponse.message;
      banner.getBanner().setMessage(errorMessage, { status: "error" });

      defer.reject(failureResponse);
    })
    .always(() => {
      // setting cookie expiration on the server will only delete
      // the cookie after the next request/response cycle. This will cause issues
      // if you have a successful download, but then the next link you click causes an error.
      // bottom line...we need to always remove the cookies immediately.
      $.removeCookie(downloadOptions.fileDownloadCookieName, { path: downloadOptions.defaultCookiePath });
      $.removeCookie(downloadOptions.fileDownloadErrorCookieName, { path: downloadOptions.defaultCookiePath });
    });

  return defer.promise();
};

module.exports = downloadFile;
plexExport("downloadFile", downloadFile);
