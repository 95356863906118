const arrayUtils = require("./plex-utils-arrays");
const plexExport = require("../../global-export");

function mergeCustomData(result) {
  if (result.Data && result.CustomDataResults) {
    result.CustomDataResults.forEach((customDataResult) => {
      const keyPropertyNames = customDataResult.KeyPropertyNames;
      const forPropertyName = customDataResult.ForPropertyName;

      if (keyPropertyNames && forPropertyName) {
        arrayUtils.leftJoin(result.Data[forPropertyName] || [], customDataResult.Rows || [], keyPropertyNames, {
          mutate: true,
          emptyRecord: customDataResult.EmptyRecord
        });

        customDataResult.EmptyRecord = {};
      }
    });
  }
}

function getData(result) {
  mergeCustomData(result);
  return result.Data;
}

const api = {
  mergeCustomData,
  getData
};

module.exports = api;
plexExport("utils.dataResult", api);
