ï»¿/* eslint-disable no-invalid-this */
/* eslint no-unused-vars: "off" */
const $ = require("jquery");
const ko = require("knockout");
const ControllerFactory = require("./plex-controller-factory");
const PickerController = require("./plex-controller-picker");
const plexExport = require("../../global-export");

const SUGGESTIONS_LIMIT = 5;

const AddressBookController = function (config, model) {
  this.picker = new PickerController(config, model);
  this.config = config;
  this.model = model;
  this.init();
};

AddressBookController.prototype = {
  constructor: AddressBookController,

  init: function () {
    const self = this;
    const $input = self.picker.getInput();
    $input.autocomplete({
      source: function (request, response) {
        self.picker.createFilteredRequest(request.term).done((results) => {
          const source = [];
          const limit = results.length < SUGGESTIONS_LIMIT ? results.length : SUGGESTIONS_LIMIT;
          for (let i = 0; i < limit; i++) {
            source.push(results[i][self.config.displayPropertyName]);
          }

          response(source);
        });
      },
      minLength: 3
    });

    $input.on("autocompleteselect", (event, ui) => {
      self.applySelectedContact(ui.item.label);
      event.preventDefault();
    });
  },

  showAllSelected: function () {
    this.picker.showAllSelected();
  },

  pick: function () {
    this.picker.pick();
  },

  applySelectedContact: function (contact) {
    const self = this;
    const emailTokens = contact.split(/[ ,;]+/);

    emailTokens.forEach((email) => {
      if (email) {
        const item = {};
        item[self.config.displayPropertyName] = email;
        self.picker.selected.push(item);
      }
    });

    self.picker.config.boundDisplayValue("");
    const $input = self.picker.getInput();
    $input.autocomplete("close");
  },

  onmouseup: function (_addressBook, e) {
    // if you click anywhere in the address book area, focus in the textbox
    const $input = this.picker.getInput();

    if (this.picker.selectedTextExist()) {
      return false;
    }

    // Keep focus from triggering any event binding multiple times
    if ($input.length && $input[0] !== e.target) {
      $input.focus();
    }

    return true;
  },

  onkeydown: function (_addressBook, e) {
    if (e.which === 13 && e.target.value) {
      this.applySelectedContact(e.target.value);
      return false;
    }
    // allow the default action
    return true;
  },

  onblur: function (addressBook, e) {
    if (e.target.value) {
      addressBook.pick();
    }
  }
};

// factory method
AddressBookController.create = function (config, model) {
  return new AddressBookController(config, model);
};

ControllerFactory.register("Elements/_AddressBook", AddressBookController);

module.exports = AddressBookController;
plexExport("AddressBookController", AddressBookController);
