/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const controllerFactory = require("./plex-controller-factory");
const repository = require("./plex-model-repository");
const elementHandler = require("./plex-handler-element");
const plexImport = require("../../global-import");

function ReadOnlyList(config, model) {
  this.config = config;
  this.model = model;
  this.init();
}

ReadOnlyList.prototype = {
  constructor: ReadOnlyList,

  init: function () {
    const self = this;
    this.$element = $(document.getElementById(this.config.id));
    this.elements = {};

    this.data = this.config.data || this.model;

    this.elementCollection = this.config.elements.map((el) => {
      elementHandler.initElement(el, self.data, null, self);
      return el;
    });

    if (this.$element.length > 0) {
      ko.applyBindings(this, this.$element[0]);
    }

    repository.add(this.config.id, this.data);
    plexImport("currentPage")[this.config.id] = this;
  },

  dispose: function () {
    repository.remove(this.config.id);
  }
};

ReadOnlyList.create = function (config, model) {
  return new ReadOnlyList(config, model);
};

controllerFactory.register("Elements/_ReadOnlyList", ReadOnlyList);
