ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const formatUtils = require("../../Globalization/plex-formatting");
const actionHandler = require("../../Controls/plex-handler-action");
const Feature = require("../../Features/plex-features");
const parsing = require("../../Core/plex-parsing-url");
const nav = require("../../Core/plex-navigate");
const plexExport = require("../../../global-export");

function normalize(prop) {
  return prop.toLowerCase().replace(/_/g, "");
}

function generateNewLink(url, rowCollection, rowNumber, formattedValue) {
  url = unescape(url);

  const sourceUrl = parsing.parseUrl(url);

  $.each(sourceUrl.query, function (key, value) {
    const self = this;

    // Parameter value is bound to XML column
    if (self.indexOf("<row>") >= 0) {
      const navigationColumn = self.substring(self.lastIndexOf("|") + 1);

      $.each(rowCollection[rowNumber].childNodes, function () {
        // Keeping the original, not sure the case it was build for
        if (this.nodeName === navigationColumn) {
          url = url.replace(value, this.textContent);
        }

        if (normalize(this.nodeName) === normalize(key)) {
          url = url.replace(value, this.textContent);
        }
      });
    }

    // Parameter value is bound to extractXml expression
    if (value.indexOf("<br/>") >= 0) {
      $.each(rowCollection[rowNumber].childNodes, function () {
        if (normalize(this.nodeName) === normalize(key)) {
          url = url.replace(value, this.textContent);
        }
      });
    }
  });

  return "<a href='" + url + "' class='plex-text-link'>" + formattedValue + "</a>";
}

const XmlSplit = Feature.extend({
  onExecute: function (record, _index, _records) {
    const value = this.element.boundValue();
    if (value) {
      return {
        content: this.splitXml(value, record),
        style: {
          "white-space": "nowrap"
        }
      };
    }

    return {};
  },

  formatValue: function (value, record) {
    const formatter = this.config.formatter || this.column.formatter;
    if (formatter) {
      if (formatter.address === "DateTimeFormatter") {
        value = new Date(value);
      }

      return formatUtils.formatValue(value, formatter, record, this.config.trackClientUpdates);
    }

    return value;
  },

  splitXml: function (value, record) {
    const self = this;
    const columnName = this.config.columnName.toUpperCase();
    value = "<rows>" + value + "</rows>";
    let intermediateUrl;
    const xmlDoc = $.parseXML(value);
    const $xml = $(xmlDoc);
    const $row = $xml.find("row");

    const formattedText = [];
    const action = this.element.action;
    let navigationSetup = false;

    if (action && action.address) {
      // navigation is set up
      navigationSetup = true;
      record = record && actionHandler.getTokenData(action, record);
      intermediateUrl = nav.buildUrl(action.address, record);
    }

    $.each($row, (i, oneRow) => {
      let formattedRow = "";

      $.each(oneRow.childNodes, function () {
        if (this.nodeName.toUpperCase() === columnName) {
          if (navigationSetup) {
            formattedRow += generateNewLink(intermediateUrl, $row, i, self.formatValue(this.textContent, record));
          } else {
            formattedRow += self.formatValue(this.textContent, record);
          }
        }
      });

      if (navigationSetup) {
        self.element.action.href = null;
      }

      formattedText.push(formattedRow);
    });

    return formattedText.join(this.config.delimiter || "<br/>") || "&nbsp;";
  }
});

module.exports = XmlSplit;
plexExport("grid.features.XmlSplit", XmlSplit);
