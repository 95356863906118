ï»¿const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");

const CountOperation = Operation.extend({
  getValue: function (records) {
    /// <summary>Gets the count for the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Number">The count.</returns>

    this.config.scale = 0;
    return records.length;
  }
});

module.exports = CountOperation;
plexExport("grid.aggregates.CountOperation", CountOperation);
