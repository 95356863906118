ï»¿const fileHandler = require("./plex-upload-file-handler");
const plexExport = require("../../global-export");

const dataHandler = fileHandler.extend({
  onDone: function (e, data) {
    const dialog = this.controller.$dialog;
    if (data.result && data.result.ValidationResult && data.result.ValidationResult.Success) {
      if (dialog) {
        dialog.close(data.result);
      }
    } else if (data.result && data.result.ValidationResult) {
      data.form.valid();
    } else if (dialog) {
      dialog.close(data.result);
    }
  }
});

module.exports = dataHandler;
plexExport("uploads.data", dataHandler);
