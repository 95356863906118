ï»¿const $ = require("jquery");
const onReady = require("../plex-handler-page").onReady;
const pubsub = require("../../Core/plex-pubsub");

const FILTER_THREE_COLUMN_CUTOFF = 1350 - $.getScrollBarSize().width;
// current value 800 has issue when window resizing
const FILTER_TWO_COLUMN_CUTOFF = 850 - $.getScrollBarSize().width;

onReady(() => {
  pubsub.subscribe("sideTabsMenuPageContainerContentWidthChanged", () => {
    const $pageContentContainer = $(".plex-sidetabs-menu-page-content-container");

    const $filter = $(".plex-filter-content");

    if ($filter.length) {
      if ($pageContentContainer.outerWidth() >= FILTER_THREE_COLUMN_CUTOFF) {
        $filter.addClass("plex-filter-three-column");
        $filter.removeClass("plex-filter-two-column");
        $filter.removeClass("plex-filter-one-column");
      } else if ($pageContentContainer.outerWidth() >= FILTER_TWO_COLUMN_CUTOFF) {
        $filter.addClass("plex-filter-two-column");
        $filter.removeClass("plex-filter-three-column");
        $filter.removeClass("plex-filter-one-column");
      } else {
        $filter.addClass("plex-filter-one-column");
        $filter.removeClass("plex-filter-two-column");
        $filter.removeClass("plex-filter-three-column");
      }
    }
  });
});
