ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const stringUtils = require("../Utilities/plex-utils-strings");
const plexExport = require("../../global-export");

// note: this is mostly based on google's search operators.
// see: https://support.google.com/websearch/answer/136861?hl=en

// todo: the wrap functionality should probably be moved elsewhere
const termsRgx = /(\s?-+|\s|^)(?:"((?:\\.|[^"])+?)"|(\S+))/g;

const negativeRgx = /^\s?-+$/;
const wildcardRgx = /\s[*%]\s/g;
const insideWildcardRgx = /(^|[^\s\\])[*%]+/g;
const repeatedWildcardsRgx = /([*%])\1/g;
const specialCharsOnly = /^[%*$^]$/;

// we don't want to escape every special character - some are allowed
const escapeRgx = /[[\]{}.\\?+()|]/g;
const orRgx = /^or$/i;
const andRgx = /^and$/i;

function createRegExp(term, htmlSpecific) {
  if (htmlSpecific) {
    return {
      rgx: new RegExp("(" + stringUtils.escapeHtml(term) + ")", "i"),
      wrapRgx: new RegExp("(" + stringUtils.escapeHtml(term) + ")", "gi")
    };
  } else {
    return {
      rgx: new RegExp("(" + term + ")", "i"),
      wrapRgx: new RegExp("(" + term + ")", "gi")
    };
  }
}

function createMatcher(term, expected) {
  term = cleanSearchTerm(term);

  return {
    regExp: createRegExp(term),
    htmlSpecificRegExp: createRegExp(term, true),
    terms: [term],
    expected
  };
}

function getRegExp(matcher, htmlSpecific) {
  if (htmlSpecific) {
    return matcher.htmlSpecificRegExp;
  } else {
    return matcher.regExp;
  }
}

function appendToMatcher(matcher, term) {
  matcher.terms.push(cleanSearchTerm(term));

  const rgxText = "(" + matcher.terms.join("|") + ")";
  matcher.regExp = createRegExp(rgxText);
}

function cleanSearchTerm(term) {
  const escapedTerm = term
    .replace(escapeRgx, "\\$&")
    .replace(specialCharsOnly, "\\$&")
    .replace(repeatedWildcardsRgx, "\\$&")
    .replace(wildcardRgx, " .* ")
    .replace(insideWildcardRgx, "$1\\S*");

  return escapedTerm;
}

function createMatchers(query, isAdvanceMode) {
  const matchers = [];
  let term, current, expected;

  // reset regex
  termsRgx.lastIndex = 0;

  if (isAdvanceMode) {
    while ((term = termsRgx.exec(query))) {
      current = term[2] || term[3];
      expected = !negativeRgx.test(term[1]);

      // todo: maybe allow the last and/or and use them as a search term?
      if (andRgx.test(current)) {
        // ignore `and`s - `and` is the default behavior
        continue;
      } else if (orRgx.test(current) && matchers.length > 0 && (term = termsRgx.exec(query))) {
        appendToMatcher(matchers[matchers.length - 1], term[2] || term[3]);
      } else {
        matchers.push(createMatcher(current, expected));
      }
    }
  } else if (query) {
    matchers.push(createMatcher(query, true));
  }

  return matchers;
}

function getMatches(matchers, text, htmlSpecific) {
  const matches = [];
  matchers.forEach((matcher) => {
    let match, current;

    if (matcher.expected) {
      const regExp = getRegExp(matcher, htmlSpecific);

      while ((match = regExp.wrapRgx.exec(text))) {
        // Prevent browsers from getting stuck in an infinite loop
        if (match.index === regExp.wrapRgx.lastIndex) {
          regExp.wrapRgx.lastIndex++;
        }

        current = {
          start: match.index,
          end: match.index + match[0].length
        };

        if (!merge(matches, current)) {
          matches.push(current);
        }
      }
    }
  });

  return matches;
}

function merge(arr, pos) {
  let i = arr.length;
  let current;

  while (i--) {
    current = arr[i];
    if (current === pos) {
      continue;
    }

    // end is within
    if (pos.end <= current.end && pos.end > current.start) {
      current.start = Math.min(pos.start, current.start);
      return true;
    }

    // start is within
    if (pos.start >= current.start && pos.start < current.end) {
      current.end = Math.max(pos.end, current.end);
      return true;
    }

    if (pos.start < current.start && pos.end > current.end) {
      current.start = Math.min(pos.start, current.start);
      current.end = Math.max(pos.end, current.end);
      return true;
    }
  }

  // if we get here there are no overlaps
  return false;
}

function removeOverlaps(arr) {
  let overlapFound = false;
  let i = arr.length;

  while (i--) {
    if (merge(arr, arr[i])) {
      overlapFound = true;
      arr.splice(i, 1);
    }
  }

  // keep removing until none are found
  if (overlapFound) {
    removeOverlaps(arr);
  }
}

function wrapAll(matches, text, prefix, suffix) {
  let i, current;

  // there is a chance for overlaps so go back through
  removeOverlaps(matches);

  // go in reverse order so we don't have to worry about
  // offseting for the lengths of the wrapped text
  matches.sort((a, b) => {
    return a.end - b.end;
  });

  // apply wraps
  i = matches.length;

  while (i--) {
    current = matches[i];
    text =
      text.substring(0, current.start) +
      prefix +
      text.substring(current.start, current.end) +
      suffix +
      text.substring(current.end);
  }

  return text;
}

function TextQuery(query, isAdvanceMode) {
  this.cache = {};
  this.matchers = createMatchers(query, isAdvanceMode);
}

TextQuery.prototype = {
  constructor: TextQuery,

  merge: function (query) {
    this.cache = {};
    this.matchers = this.matchers.concat(query.matchers);
  },

  isMatch: function (strings, isHtml) {
    if (!Array.isArray(strings)) {
      strings = $.makeArray(strings);
    }

    const joinedString = strings.join(" ");

    if (joinedString in this.cache) {
      return this.cache[joinedString];
    }

    const notExpectedMatchers = this.matchers.filter((m) => {
      return !m.expected;
    });

    let i, j, matcher, string;
    let result = false;

    for (i = 0; i < notExpectedMatchers.length; i++) {
      matcher = notExpectedMatchers[i];

      if (getRegExp(matcher, isHtml).rgx.test(joinedString)) {
        return (this.cache[joinedString] = false);
      }
    }

    const expectedMatchers = this.matchers.filter((m) => {
      return m.expected;
    });

    for (i = 0; i < strings.length; i++) {
      result = true;
      string = strings[i];

      for (j = 0; j < expectedMatchers.length; j++) {
        matcher = expectedMatchers[j];

        if (!getRegExp(matcher, isHtml).rgx.test(string)) {
          result = false;

          break;
        }
      }

      if (result) {
        break;
      }
    }

    if (!result) {
      result = true;

      for (j = 0; j < expectedMatchers.length; j++) {
        matcher = expectedMatchers[j];

        if (!getRegExp(matcher, isHtml).rgx.test(joinedString)) {
          result = false;

          break;
        }
      }
    }

    return (this.cache[joinedString] = result);
  },

  matches: function (text, isHtml) {
    return getMatches(this.matchers, text, isHtml);
  },

  wrap: function (text, prefix, suffix, isHtml) {
    const matches = getMatches(this.matchers, text, isHtml);
    return wrapAll(matches, text, prefix, suffix);
  }
};

// utility method
TextQuery.wrap = wrapAll;

module.exports = TextQuery;
plexExport("TextQuery", TextQuery);
