ï»¿const regexUtils = require("../../Utilities/plex-utils-regex");
const stringUtils = require("../../Utilities/plex-utils-strings");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const ReplaceFeature = Feature.extend({
  onInit: function () {
    const self = this;
    const flags = this.config.caseSensitive ? "g" : "gi";
    const removeRgx = new RegExp(regexUtils.escapeText(this.config.oldValue), this.config.caseSensitive ? "" : "i");
    const replaceRgx = new RegExp(regexUtils.escapeText(this.config.oldValue), flags);

    this.replacer = function (value) {
      let orgValue = value;
      if (self.config.oldValue) {
        if (self.config.removeFirstValue) {
          orgValue = String(value).replace(removeRgx, "");
        }
        return String(orgValue).replace(replaceRgx, self.config.newValue);
      }
      return value;
    };
  },

  onExecute: function (record, index, records) {
    let value = null;

    if (this.column.valueProvider && typeof this.column.valueProvider.getValue === "function") {
      value = this.column.valueProvider.getValue(record, index, records);
    } else if (this.element) {
      value = this.element.initialDisplayValue;

      // most likely initial display value isn't available when the feature result is evaluated, get the bound value instead
      if (!value && this.element.boundValue) {
        value = this.element.boundValue();
      }
    }

    if (value) {
      const replacedValue = this.replacer(value);

      if (
        (this.column.valueProvider &&
          this.column.valueProvider.config &&
          this.column.valueProvider.config.address === "HtmlColumnValueProvider") ||
        this.column.type !== "Text"
      ) {
        return {
          content: replacedValue
        };
      } else {
        return {
          content: stringUtils.escapeHtml(replacedValue, { ignoreLineBreaks: true })
        };
      }
    }

    return {};
  }
});

module.exports = ReplaceFeature;
plexExport("grid.features.Replace", ReplaceFeature);
