ï»¿const jsUtils = require("../../Utilities/plex-utils-js");
const expressions = require("../../Expressions/plex-expressions-compiler");
const formatUtils = require("../../Globalization/plex-formatting");
const plexExport = require("../../../global-export");

const Operation = function () {
  // constructor
};

Operation.prototype = {
  constructor: Operation,

  init: function (column, config) {
    /// <summary>Initializes the aggregate operation.</summary>
    /// <param name="column">The grid column instance.</param>
    /// <param name="config">The operation configuration.</param>

    let evaluator;

    this.config = config;
    this.column = column;

    // add for numeric alignment
    this.css = ["plex-numeric-text"];

    if (config.expression) {
      evaluator = expressions.compile(config.expression);
    } else {
      evaluator = column.valueProvider.getValue.bind(column.valueProvider);
    }

    this.getRecordValue = function (record) {
      record = column.valueProvider.getRecord(record);
      if (record) {
        return evaluator(record);
      }

      return null;
    };
  },

  getRecordValues: function (records) {
    /// <summary>Gets an array of values from the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Array">An array of record values.</returns>

    return records.map(this.getRecordValue, this);
  },

  getFormattedValue: function (data, _section, _group) {
    const value = this.getValue.apply(this, arguments);
    return this.formatValue(value, data);
  },

  formatValue: function (value, data) {
    if (value === 0 && this.config.zeroText) {
      return this.config.zeroText;
    }

    const formatter = this.config.formatter || this.column.formatter;
    return formatUtils.formatValue(value, formatter, data, this.config.trackClientUpdates);
  },

  getEmptyValue: function () {
    // can be overriden
  },

  getPrintValue: function () {
    return this.getValue.apply(this, arguments);
  }
};

jsUtils.makeExtendable(Operation);

module.exports = Operation;
plexExport("grid.aggregates.Operation", Operation);
