ï»¿const $ = require("jquery");
const notify = require("../Core/plex-notify");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const nav = require("../Core/plex-navigate");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const bytesInMegabyte = 1048576;

const FileUploadHandler = function () {
  // constructor
};

FileUploadHandler.prototype = {
  constructor: FileUploadHandler,

  init: function (config, controller) {
    const self = this;
    self.config = config;
    self.controller = controller;
    self._setOptions();
    self.onInit();
  },

  onInit: function () {
    // can be overriden
  },

  onStart: function () {
    this.processedCount = 0;
    this.results = [];
    this.failed = false;
  },

  onAdd: function (_e, data) {
    const self = this;
    let error = "";
    if (data.form && data.form.length > 0 && self.config.fileParametersSourceId) {
      if (data.form.valid() === false) {
        return;
      }

      const currentPage = plexImport("currentPage");
      data.formData = dataUtils.cleanse(currentPage[self.config.fileParametersSourceId].data);
    }

    if (data.originalFiles && data.originalFiles.length > 0) {
      const regex = new RegExp(self.config.allowedFileTypesRegex, "i");

      // eslint-disable-next-line consistent-return
      $.each(data.originalFiles, (_idx, file) => {
        if (file.name.length && regex.test(file.name) === false) {
          error = "Invalid file type selected.";
          return false;
        } else if (file.size > self.config.maxFileSize) {
          error = {
            text: "File size cannot exceed {1} mb.",
            tokens: [Math.floor(self.config.maxFileSize / bytesInMegabyte)],
            autoGlossarize: true
          };
          return false;
        }
      });
    }

    if (error !== "") {
      notify.error(error);
      return;
    }

    if (this.isPreviewMode()) {
      this.createPreviews(data);
    } else {
      $(document).block();
      data.submit();
    }
  },

  // done callback is fired after each request is processed in current batch
  // there can be multiple concurrent requests issued for the set of files being uploaded in current batch
  // execute oncomplete only after all files in current batch have been processed
  onDone: function (_e, data) {
    const self = this;
    self.processedCount += data.files.length;
    self.results.push(data.result);
    self.failed |= !self.validateUploadResult(data);

    // even though we are requesting plugin to upload multiple files in single request
    // it can still perform multiple requests if the total files size exceeds configured size limit per request
    if (data.originalFiles.length === self.processedCount) {
      if (self.isPreviewMode()) {
        // in manual submit mode, there can be multiple batches if uploading of multiple files is allowed
        // let the controller fire next batch
        self.controller.submitNext(self.results);
      } else {
        self.failed ? self.onUploadFailed(self.results) : self.onUploadComplete(self.results);
      }
    }
  },

  onFail: function (_e, data) {
    // This is not being called in IE if an exception is thrown.  Is it because the response type is not "text/plain"?
    if (data.context) {
      data.context.text("Upload failed.");
    }

    if (!this.isPreviewMode()) {
      this.onUploadFailed([data.result]);
    }
  },

  onAlways: function (_e, _data) {
    $(document).unblock();
    // This is not being called in IE if an exception is thrown.  Is it because the response type is not "text/plain"?
    $(this).removeClass("fileupload-processing");
  },

  onProgressAll: function (_e, _data) {
    // can be overriden
  },

  isPreviewMode: function () {
    return this.config.autoSubmit === false;
  },

  createPreviews: function (data) {
    const self = this;
    data.previews = [];
    data.files.forEach((file) => {
      if (self.config.preview !== "none") {
        const preview = {
          name: file.name,
          file,
          kbSize: Math.round(file.size / 1024),
          remove: function () {
            data.files.splice(data.files.indexOf(file), 1);
            data.previews.splice(data.previews.indexOf(preview), 1);
            self.controller.previews.splice(self.controller.previews().indexOf(preview), 1);
          }
        };

        self.onCreatePreview(preview);

        if (self.config.multiple === true) {
          self.controller.previews.push(preview);
        } else {
          self.controller.previews([preview]);
        }

        data.previews.push(preview);
      }
    });

    if (self.config.multiple === true) {
      self.controller.uploadDataCollection.push(data);
    } else {
      self.controller.uploadDataCollection = [data];
    }
  },

  onCreatePreview: function (_preview) {
    // for the inheritors
  },

  getRouteUrl: function (_data) {
    return nav.buildUrl(this.config.uploadHandlerRoute);
  },

  onUploadComplete: function (results) {
    const dialog = this.controller.$dialog;
    if (dialog) {
      dialog.close(results);
    }
  },

  onUploadFailed: function (_results) {
    // one or more upload data submits failed
  },

  validateUploadResult: function (data) {
    const self = this;
    const result = data.result || {};
    if (result.ValidationResult ? !result.ValidationResult.Success : !result.Success) {
      notify.warning(result.ValidationResult ? result.ValidationResult.Message : result.Message || "Failed to upload.");
      return false;
    } else if (data.previews) {
      data.previews.forEach((preview) => {
        self.controller.previews.splice(self.controller.previews().indexOf(preview), 1);
      });
    }

    return true;
  },

  onDragEnter: function (_e) {
    this.$dropZone.addClass("hovered");
  },

  onDragLeave: function (_e) {
    this.$dropZone.removeClass("hovered");
  },

  onDrop: function (_e, _data) {
    this.$dropZone.removeClass("hovered");
  },

  _setOptions: function () {
    const self = this;
    self.$dropZone = $("#" + self.controller.$element[0].id + "_FileDrop").find("div");
    self.controller.$element.attr({ accept: self.config.allowedFileTypes.join(",") });
    self.controller.$element.fileupload({
      formData: Object.keys(self.config.additionalData ?? {}).map((name) => ({
        name,
        value: self.config.additionalData[name]
      })),
      url: self.getRouteUrl(),
      dropZone: self.$dropZone,
      dataType: "json",
      maxFileSize: self.config.maxFileSize,
      acceptFileTypes: new RegExp(self.config.allowedFileTypesRegex, "i"),
      singleFileUploads: false, // upload multiple files in single request
      limitMultiFileUploadSize: self.config.totalFilesSizePerRequest || 209715200 // limit total files size per request (default is 200MB)
    });

    $(document).on("drop.upload dragover.upload", (e) => {
      e.preventDefault();
    });
  }
};

jsUtils.makeExtendable(FileUploadHandler);

module.exports = FileUploadHandler;
plexExport("uploads.file", FileUploadHandler);
