ï»¿const $ = require("jquery");
const LabelPrintController = require("./plex-labelprinting-controller-base");
const plexExport = require("../../../../global-export");

const ContainerLabelPrintController = LabelPrintController.extend({
  controllerAction: "/Labels/Print/Container",
  getRequestData: function (data, options) {
    let requestData;

    if (typeof data === "object") {
      // _base is a reference to getRequestData on LabelPrintController
      requestData = this._base(data, options);
      requestData = $.extend(requestData, {
        SerialNo: this.parseAndExecuteExpression(
          options.serialNo,
          data,
          this.getDefaultValue(data, options.serialNoPropertyName)
        ),
        CustomerAddressNo: this.parseAndExecuteExpression(
          options.customerAddressNo,
          data,
          this.getDefaultValue(data, options.customerAddressNoPropertyName)
        ),
        ForceSuspectLabel: this.parseAndExecuteExpression(
          options.forceSuspectLabel,
          data,
          this.getDefaultValue(data, options.forceSuspectLabelPropertyName)
        ),
        ShipperKey: this.parseAndExecuteExpression(
          options.shipperKey,
          data,
          this.getDefaultValue(data, options.shipperKeyPropertyName)
        ),
        POLineKey: this.parseAndExecuteExpression(
          options.poLineKey,
          data,
          this.getDefaultValue(data, options.poLineKeyPropertyName)
        )
      });
    } else {
      requestData = this._base({}, options);
      requestData.SerialNo = data;
    }

    return requestData;
  }
});

module.exports = ContainerLabelPrintController;
plexExport("barcoding.labelPrinting.ContainerLabelPrintController", ContainerLabelPrintController);
