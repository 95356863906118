ï»¿const ko = require("knockout");
const koExt = require("./knockout-ext");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

const api = {};

api.addNonMutatingArrayMethods = koExt.addNonMutatingArrayMethods;
api.updateTwoWayBinding = function (bindings, bindingName, value) {
  const writer = bindings.get("_ko_property_writers");
  if (writer && typeof writer[bindingName] === "function") {
    writer[bindingName](value);
  } else {
    throw new Error(
      "Binding " +
        bindingName +
        " not found. Be sure to register the binding as a two way binding in `ko.expressionRewriting._twoWayBindings`"
    );
  }
};

api.hasMemoization = function (value) {
  const knockoutMemoizationPlaceholder = /<!--\[ko_memo/;

  return knockoutMemoizationPlaceholder.test(value);
};

api.copySubscriptions = function (source, target) {
  if (logger) {
    logger.warn("Subscriptions are being copied: the value should be replaced instead");
  }

  // hack: minified source does not expose this property
  // todo: KILL THIS METHOD
  let subscriptionsField = "_subscriptions";
  let callbackField = "callback";
  let isDisposedField = "isDisposed";
  let targetField = "target";

  if (!(subscriptionsField in source)) {
    switch (ko.version) {
      case "3.2.0":
        subscriptionsField = "M";
        callbackField = "wa";
        isDisposedField = "Cb";
        break;

      case "3.3.0":
        targetField = "da";
        subscriptionsField = "G";
        callbackField = "La";
        isDisposedField = "Gb";
        break;

      case "3.4.0":
        targetField = "ia";
        subscriptionsField = "K";
        callbackField = "gb";
        isDisposedField = "R";
        break;

      default:
        throw new Error(
          "Unsupported Knockout version. Cannot find subscriptions property. Current version is " + ko.version
        );
    }
  }

  const subscriptions = source[subscriptionsField];
  let event;

  for (event in subscriptions) {
    if (Object.prototype.hasOwnProperty.call(subscriptions, event) && subscriptions[event].length > 0) {
      let index = subscriptions[event].length;
      while (index--) {
        const sub = subscriptions[event][index];
        if (!sub[isDisposedField]) {
          target.subscribe(sub[callbackField], sub[targetField] || sub._target, event);
          sub.dispose();
        }
      }
    }
  }
};

module.exports = api;
plexExport("knockout", api);
