const $ = require("jquery");
const ko = require("knockout");

const getViewModel = (element) => ko.dataFor(element instanceof $ ? element[0] : element);

const configFactory = {};

configFactory.createSortableConfig = (controller) => {
  const SORT_TARGET_CLASS = "sort-target";
  const SORT_HELPER_CLASS = "sort-helper";
  const SORT_PLACEHOLDER_CLASS = "sort-placeholder";

  const SORT_ITEM_SELECTOR = ".favorite";
  const SORT_ITEM_HANDLE_SELECTOR = ">" + SORT_ITEM_SELECTOR;

  return {
    axis: "y",
    opacity: 0.8,
    scroll: true,
    distance: 4,
    refreshPositions: true,
    containment: "document",
    handle: SORT_ITEM_HANDLE_SELECTOR,
    cancel: ".form-control, .plex-select-wrapper",
    placeholder: SORT_TARGET_CLASS,
    helper(event, ui) {
      const margin = "margin";
      const $favorite = ui.children(SORT_ITEM_SELECTOR);

      return $favorite
        .clone()
        .addClass("selected")
        .addClass(SORT_HELPER_CLASS)
        .outerHeight($favorite.outerHeight())
        .outerWidth($favorite.outerWidth())
        .css(margin, ui.css(margin));
    },
    start(event, ui) {
      const node = getViewModel(ui.item);

      controller.isSorting = true;
      controller.selectNode(node);

      ui.item.show().addClass(SORT_PLACEHOLDER_CLASS);
      ui.placeholder.hide().fadeIn();

      // need to solve bug with helper position
      $(this).sortable("refreshPositions");
    },
    stop(event, ui) {
      controller.isSorting = false;

      ui.item.removeClass(SORT_PLACEHOLDER_CLASS);

      $(this).sortable("refreshPositions");
    },
    sort(event, ui) {
      const $target = ui.placeholder;

      if (controller.isSorting !== $target.is(":visible")) {
        $target.stop().hide();

        if (controller.isSorting) {
          $target.fadeIn();
        }
      }
    },
    change(event, ui) {
      if (controller.isSorting) {
        ui.placeholder.stop().hide().fadeIn(controller.config.animationSpeed);
      }
    },
    beforeMove(args) {
      args.cancelDrop = true;

      if (controller.isSorting) {
        const node = args.item;
        const targetNode = getViewModel(this);
        const targetIndex = args.targetIndex;

        ko.tasks.schedule(() => controller.moveNode(node, targetNode, targetIndex));
      }
    }
  };
};

configFactory.createDroppableConfig = (controller) => {
  const DROP_POSSIBLE_CLASS = "drop-possible";
  const DROP_IMPOSSIBLE_CLASS = "drop-impossible";

  // to solve bug with plex method wrapping for this binding
  const getUi = (args) => args[args.length - 1];
  const getEvent = (args) => args[args.length - 2];
  const getDroppable = (args) => $(getEvent(args).target);

  return {
    greedy: true,
    tolerance: "intersect",
    accept: ".sort-placeholder",
    over() {
      const ui = getUi(arguments);
      const droppable = getDroppable(arguments);

      const node = getViewModel(ui.draggable);
      const targetNode = getViewModel(droppable);

      controller.isSorting = false;

      if (controller.canMoveNode(node, targetNode)) {
        droppable.parent().addClass(DROP_POSSIBLE_CLASS);
      } else {
        droppable.parent().addClass(DROP_IMPOSSIBLE_CLASS);
      }
    },
    out() {
      const droppable = getDroppable(arguments);

      controller.isSorting = true;

      droppable.parent().removeClass(DROP_POSSIBLE_CLASS).removeClass(DROP_IMPOSSIBLE_CLASS);
    },
    drop() {
      const ui = getUi(arguments);
      const droppable = getDroppable(arguments);

      const node = getViewModel(ui.draggable);
      const targetNode = getViewModel(droppable);

      droppable.parent().removeClass(DROP_POSSIBLE_CLASS).removeClass(DROP_IMPOSSIBLE_CLASS);

      ko.tasks.schedule(() => controller.moveNode(node, targetNode));
    }
  };
};

configFactory.createAnimationConfig = (controller) => {
  return {
    show(target, callback) {
      const $target = $(target);

      if (target.nodeType !== 1) {
        $target.remove();
        return;
      }

      $target.hide().slideDown(controller.config.animationSpeed, () => {
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    hide(target, callback) {
      const $target = $(target);

      if (target.nodeType !== 1) {
        target.remove();
        return;
      }

      $target.slideUp(controller.config.animationSpeed, () => {
        $target.remove();
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  };
};

module.exports = configFactory;
