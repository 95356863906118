const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const root = plexImport("grid.aggregates");

const AggregateFactory = {
  create: function (column, config) {
    /// <summary>Creates the aggregate operation based on the provided configuration.</summary>
    /// <param name="column">The grid column instance.</param>
    /// <param name="config">The aggregate configuration.</param>
    /// <returns type="Object">The aggregate operation.</returns>

    if (!(config.name in root)) {
      throw new Error("Operation " + config.name + " not found.");
    }

    const provider = new root[config.name](column, config);
    provider.init(column, config);

    return provider;
  }
};

module.exports = AggregateFactory;
plexExport("grid.aggergates.AggregateFactory", AggregateFactory);
