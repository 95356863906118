ï»¿const ko = require("knockout");
const Ask = require("../Dialogs/plex-dialogs-ask");
const actionHandler = require("../Controls/plex-handler-action");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const AskAction = Action.extend({
  onExecute: function (data, sourceData, event) {
    const self = this;

    /* eslint new-cap: "off" */
    return Ask(this.message, this.choices, this.defaultChoice, {
      // glossarization will occur on the server
      autoGlossarize: false,
      autoGlossarizeOptions: false
    }).then((answer) => {
      // confirm dialog can return an object or answer value
      const selectedAnswer =
        typeof answer === "object" ? answer : ko.utils.arrayFirst(self.choices, (item) => item.answerValue === answer);

      if (selectedAnswer && selectedAnswer.action) {
        actionHandler.initAction(selectedAnswer.action, self.parent);
        actionHandler.executeAction(selectedAnswer.action, data, event, self);
      }
      return answer;
    });
  }
});

module.exports = AskAction;
plexExport("actions.Ask", AskAction);
