if ("Promise" in window) {
  const noop = () => {};
  const proto = window.Promise.prototype;

  proto.done = function (func) {
    console.warn("`done` is non-standard - use `then`, `catch`, or `finally`");
    this.then(func, noop);
    return this;
  };

  proto.fail = function (func) {
    console.warn("`fail` is non-standard - use `catch`");
    return this.catch(func);
  };

  proto.always = function (func) {
    console.warn("`always` is non-standard - use `finally`");
    return this.finally(func);
  };
}
