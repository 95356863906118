ï»¿const ContainerLabelPrintController = require("./plex-labelprinting-controller-container");
const MasterLabelPrintController = require("./plex-labelprinting-controller-masterunit");
const GenericLabelPrintController = require("./plex-labelprinting-controller-generic");
const plexExport = require("../../../../global-export");

const LabelPrintControllerFactory = function () {
  // holds LabelFormatType -> LabelPrinter constructors
  // eslint-disable-next-line no-invalid-this
  this.printers = {
    Container: ContainerLabelPrintController,
    MasterUnit: MasterLabelPrintController,
    Generic: GenericLabelPrintController
  };
};

LabelPrintControllerFactory.prototype = {
  getLabelPrintController: function (labelFormatConfiguration) {
    // Based on the label format type (container, master unit, etc.) branch. Need to call different logic to figure out which label will print.
    const PrinterConstructor = this.printers[labelFormatConfiguration.labelFormatType];
    if (typeof PrinterConstructor === "function") {
      return new PrinterConstructor();
    } else {
      throw new Error(
        "Could not find a printer for the requested labelFormatType: " + labelFormatConfiguration.labelFormatType
      );
    }
  }
};

module.exports = LabelPrintControllerFactory;
plexExport("barcoding.labelPrinting.LabelPrintControllerFactory", LabelPrintControllerFactory);
