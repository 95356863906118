ï»¿const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

function getAdress(record, propertyName, prefix, delimiter) {
  const address = [];
  if (propertyName in record) {
    record[propertyName].forEach((addressLine) => {
      address.push(prefix + addressLine.Text);
    });
  }

  return address.join(delimiter);
}

const FormattedAddressValueProvider = defaultValueProvider.extend({
  getHtml: function (record) {
    if (this.config.trackClientUpdates) {
      return this.getTrackedHtml.apply(this, arguments);
    }

    return getAdress(record, this.column.propertyName, "<div>", "</div>");
  },

  getFormattedValue: function (record) {
    // for export make it one line ',' separated
    const value = getAdress(record, this.column.propertyName, "", ", ");
    // remove \r\n
    return value.replace(/\r\n/g, " ").replace(/\n|\r/g, " ");
  },

  getPrintValue: function (record) {
    return getAdress(record, this.column.propertyName, "", "\n");
  },

  getTrackedHtml: function (record, _index, _colIndex, _features) {
    return this.renderTemplate("elements-formattedaddress", { data: record, options: this.column });
  }
});

module.exports = FormattedAddressValueProvider;
plexExport("grid.valueProviders.FormattedAddressValueProvider", FormattedAddressValueProvider);
