ï»¿const $ = require("jquery");
const ko = require("knockout");
const logger = require("../Core/plex-logger");
const jsUtils = require("../Utilities/plex-utils-js");
const compiler = require("../Expressions/plex-expressions-compiler");
const urlProvider = require("../Core/plex-url-provider");
const nav = require("../Core/plex-navigate");
const dialogController = require("../Dialogs/plex-dialog-controller");
const Cache = require("../Core/plex-cache");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const noop = function () {
  // noop
};
const cache = new Cache();
const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes
const unauthorizedUrl = {
  url: "",
  authorized: false
};

function navigate(result) {
  if (result && result.url?.authorized !== false) {
    if (result.options?.modal) {
      dialogController.create({ route: result.url });
    } else {
      nav.navigate(result.url, null, result.options);
    }
  }
}

function buildUrl(url, data, options) {
  return {
    url: !data && options?.externalLink ? url : decodeURI(nav.buildUrl(url, data, options)),
    authorized: true,
    options
  };
}

function resolveUrl(value) {
  if (!value) {
    return null;
  }

  if (jsUtils.isPromise(value)) {
    return value.then(resolveUrl);
  }

  const url = value.url;
  if (!url) {
    return null;
  }

  const qs = value.data;
  const options = value.options || {};
  if (options.externalLink) {
    return buildUrl(url, qs, options);
  }

  const cachedUrl = cache.get(url);
  if (cachedUrl) {
    if (cachedUrl.authorized) {
      return buildUrl(cachedUrl.url, qs, options);
    }

    return unauthorizedUrl;
  }

  return urlProvider.getUrl(url).then((response) => {
    cache.set(url, response, Cache.slidingExpiration(CACHE_EXPIRATION));

    if (response.authorized) {
      return buildUrl(response.url, qs, options);
    }

    return unauthorizedUrl;
  });
}

const ExpressionAction = Action.extend({
  onInit: function () {
    // make sure that the evaluator function has been created
    if (typeof this.config.evaluator !== "function") {
      ExpressionAction.configure(this.config);
    }
  },

  onExecute: function (data) {
    try {
      const result = this.config.evaluator(data, null, null, this);

      if (this.expressionType === "url") {
        return $.when(result).then(resolveUrl).then(navigate);
      }

      return result;
    } catch (ex) {
      logger.error(ex);
      return false;
    }
  }
});

ExpressionAction.configure = function (action) {
  action.evaluator = action.expression ? compiler.compile(action.expression) : noop;

  // need to do a deep comparison because we will get a different object from each evaluation
  // which leads to an infinite loop
  action.$$href = ko.observable().extend({ equality: "deep" });

  // this will allow the grid to determine the href at runtime
  // this may need to be adjusted to work with forms, etc
  action.href = function () {
    const evaluatedValue = action.evaluator.apply(null, arguments);
    const resolvedValue = resolveUrl(evaluatedValue);

    if (jsUtils.isPromise(resolvedValue)) {
      resolvedValue.then(action.$$href);
    } else {
      action.$$href(resolvedValue);
    }

    const current = action.$$href();
    return current && current.url;
  };

  action.authorized = ko.pureComputed(() => {
    const current = action.$$href();
    return current && current.authorized;
  });
};

module.exports = ExpressionAction;
plexExport("actions.Expression", ExpressionAction);
