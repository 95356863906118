import autobahn from "autobahn";

const AUTH_ERROR_URI = "wamp.error.authorization_failed";
const GENERIC_ERROR_URI = "com.plex.generic_error";

export const isAuthorizationError = (wampError) => {
  if (!(wampError instanceof autobahn.Error)) {
    return false;
  }

  if (wampError.error === AUTH_ERROR_URI) {
    return true;
  }

  if (wampError.error === GENERIC_ERROR_URI && wampError.args[0]?.indexOf(AUTH_ERROR_URI) !== -1) {
    return true;
  }

  return false;
};
