ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");

const templateCache = Object.create(null);

function createFragment(template) {
  const fragment = document.createDocumentFragment();
  $(fragment).append(template.html);

  // cache the fragment for reuse
  return (template.nodes = fragment);
}

const TemplateCacheSource = function (name) {
  this.data = {};
  this.source = templateCache[name].html;
  this.fragment = templateCache[name].nodes || createFragment(templateCache[name]);
};

TemplateCacheSource.prototype = {
  constructor: TemplateCacheSource,

  // eslint-disable-next-line consistent-return
  text: function (value) {
    if (arguments.length === 0) {
      return this.source;
    }

    this.source = value;
  },

  // eslint-disable-next-line consistent-return
  data: function (key, value) {
    if (arguments.length === 1) {
      return this.data[key];
    }

    this.data[key] = value;
  },

  nodes: function () {
    return this.fragment;
  }
};

// static method to preload templates
TemplateCacheSource.load = function (name, html) {
  templateCache[name] = {
    html
  };
};

TemplateCacheSource.unload = function (name) {
  delete templateCache[name];
};

const CachedTemplateEngine = function () {
  // constructor
};

/* eslint new-cap: "off" */
CachedTemplateEngine.prototype = new ko.nativeTemplateEngine();

// override the make template source
CachedTemplateEngine.prototype.makeTemplateSource = function (template) {
  if (typeof template === "string" && template in templateCache) {
    return new TemplateCacheSource(template);
  }

  // if it's not cached just use the native templating
  return ko.nativeTemplateEngine.prototype.makeTemplateSource.apply(this, arguments);
};

ko.templateSources.templateCache = TemplateCacheSource;
ko.setTemplateEngine(new CachedTemplateEngine());
