const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");

const DistinctCountOperation = Operation.extend({
  getValue: function (records) {
    const self = this;
    const hash = {};
    let count = 0;

    records.forEach((record, i) => {
      const value = String(self.getRecordValue(record, i));
      if (!(value in hash)) {
        count++;
        hash[value] = true;
      }
    });

    return count;
  }
});

module.exports = DistinctCountOperation;
plexExport("grid.aggregates.DistinctCountOperation", DistinctCountOperation);
