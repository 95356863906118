ï»¿// todo:
// - this is constructed differently than other controllers. need to standardize
// - too many dependencies - doesn't smell right

const $ = require("jquery");
const ko = require("knockout");
const pubsub = require("../Core/plex-pubsub");
const logger = require("../Core/plex-logger");
const jsUtils = require("../Utilities/plex-utils-js");
const knockoutUtils = require("../Knockout/plex-utils-knockout");
const pageHandler = require("./plex-handler-page");
const elementHandler = require("./plex-handler-element");
const actionHandler = require("./plex-handler-action");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const dataUtils = require("../Utilities/plex-utils-data");
const repository = require("./plex-model-repository");
const validation = require("../Core/plex-validation");
const banner = require("../Plugins/plex-banner");
const gridSelectionMode = require("../Grid/plex-grid-selectionmode");
const GridHeader = require("./Grid/plex-grid-header");
const RowLayout = require("./Grid/plex-grid-row-layout");
const DataSource = require("../Data/plex-datasource");
const DataResult = require("../Data/plex-data-result");
const SelectionState = require("./plex-selection-state");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const regexUtils = require("../Utilities/plex-utils-regex");
const serverBannerProvider = require("./plex-banner-server-info");
const remoteStateStorage = require("./plex-remote-state-storage");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const { getGroupSearchIterator } = require("../Grid/plex-grid-group-search");
const env = require("../Core/plex-env");

require("../Mixins/plex-mixins-events"); // eslint-disable-line import/no-unassigned-import

const selectionModes = gridSelectionMode.selectionModes;

const RECORD_LIMIT_EXCEEDED = "Record limit has been exceeded. Only the first {1} records are displayed.";
const NO_RECORDS_MODIFIED = "No records have been modified.";
const NO_RECORDS_SELECTED = "No records have been selected.";

const selectionTarget = {
  record: 0,
  group: 1
};

const createOrReturnObservable = (value, defaultValue) => {
  if (ko.isObservable(value)) {
    return value;
  }

  return ko.observable(defaultValue);
};

const GRID_GROUP_SEARCH_FEATURE_FLAG = "fix-tri-4238-grid-ctrlf-group-search";

function GridController() {
  // constructor
}

GridController.prototype = {
  constructor: GridController,

  events: [
    "loading",
    "loaded",
    "toggleSelectAll",
    "recordSelected",
    "recordDeselected",
    "recordRanked",
    {
      eventName: "beforeRecordRanked",
      defaultOptions: {
        async: false
      }
    }
  ],

  init: function (el, config, data) {
    const self = this;

    this.$element = $(el);
    this.config = config;
    this.master = config.master;
    this.config.controller = this;
    this.recordLimitExceeded = ko.observable(false);
    this.searched = ko.observable(false).extend({ notify: "always" });
    this.searchCriteria = null;
    this.isDisposed = ko.observable(false);
    this.progress = ko.observable();
    this.alwaysSearch = ko.observable(false);
    this.rendered = ko.observable(false);
    this.config.usesFixedActionbar = true;

    const $container = this.$element.closest(".plex-grid-content");
    if ($container.length > 0) {
      ko.cleanNode($container[0]);
    }

    const validationModel = self.config.validationModel || {};
    if (shouldSetupValidation(validationModel)) {
      this.setupValidation = true;
      this.$form = this.$element.closest("form");
      if (this.$form.length === 0) {
        this.$form = this.$element.wrap("<form></form>").closest("form");
      }
    }

    self.validator = validation.createValidator(this.$form, validationModel, self);

    this.selectable = gridSelectionMode.isSelectable(config.selectionMode);

    this.multiSelect = gridSelectionMode.isMultiSelect(config.selectionMode);

    // keep track of internal subscriptions - these will need to be
    // disposed if the grid is disposed to free up resources
    this._dataDisposables = [];

    // todo: this gives us a brute-force way to force the selected observables to reevaluate
    // after records have been initialized. It would be better if initialization always happened
    // before these observables. Something's changed but it is not immediately evident what
    // the source of the change is (and it looks like it should have always been an issue!)
    this._recordsUpdatedTrigger = ko.observable();

    if (!this.config.columns || this.config.columns.length === 0) {
      notify.error({ text: "Grid {1} has no columns configured.", tokens: [this.config.handle || this.config.id] });
      return;
    }

    // keep a copy of original column configuration
    this.config.originalColumns = this.config.columns.map((col) => {
      return dataUtils.clone(col, { exclude: ["parent"] });
    });

    if (this.config.initialSort && this.config.initialSort.length > 0) {
      this.initialSort = this.config.initialSort;
    }

    if (this.config.ranking) {
      this.initialSort = [this.config.ranking];

      // Disable sorting via column headers while ranking is in place.
      this.config.isSortable = false;
    }

    this.$element.on("columnResized", () => {
      this.config.isResized = true;
      this.saveState();
    });

    this.exportHeader = this.config.exportHeader !== false && this.config.headerVisible !== false;

    // filter disabled groups before intializing the datasource
    if (this.config.groups && this.config.groups.length > 0) {
      this.config.groups = this.config.groups.filter((group) => !group.disabled);
    }

    this.restoreState();
    this.saved = ko.observable(false).extend({ notify: "always" });
    this.addDisposable(this.saved.subscribe(this.onSave, this));

    data = data || this._getConfigData();
    if (data && data instanceof DataSource) {
      this.datasource = data;
      this._initData(data.raw);
    } else {
      config.disposer = this._disposeData.bind(this);
      data = data instanceof DataResult ? data.data : data;
      this.datasource = dataSourceFactory.create(config, data);
      if (data) {
        this.searched(true);
        this._initData(data);
      }
    }

    this.addDisposable(this.datasource);

    this.searchEnabled = ko.observable(false);

    // need to initialize any other data that is updated in the observable
    // since it will not be loaded through the `load` method
    // todo: we can probably be more efficient about this by
    // only listening to array changes and responding only to adds
    this.addDisposable(
      this.datasource.source.subscribe((values) => {
        self._initData(values);
      })
    );

    // this is just a pointer to the datasource's source property
    this.results = this.datasource.source;

    // set up bindings for parent container
    const gridDataContext = new GridDataContext(this.results, this);
    elementHandler.initElement(this.config, gridDataContext, null, this);
    repository.add(this.config.id + "_gridDataContext", gridDataContext);

    if (this.config.rowLayouts) {
      const primaryLayout = ko.utils.arrayFirst(this.config.rowLayouts, (layout) => {
        return layout.primary;
      });
      self.header = new GridHeader(primaryLayout, this.datasource, this);
      this.config.rows = this.config.rowLayouts.map((layout) => {
        if (layout.primary) {
          return self.header;
        }

        return new RowLayout(layout, self);
      });
    } else {
      // this code path supports backwards compatibilty and pickers
      // RowLayout could be added to pickers at some point to unify this code path
      this.header = new GridHeader(
        {
          columns: this.config.columns,
          writerProvider: this.config.rowWriterProvider,
          printWriterProvider: this.config.rowPrintWriterProvider,
          features: this.config.rowFeatures || [],
          primary: true
        },
        this.datasource,
        this
      );

      this.config.columns = this.header.columns;
      this.config.rows = [this.header];
    }

    this.columns = this.config.columns;
    ko.track(this.config, ["columns"]);
    ko.track(this, ["columns"]);

    this._initSelectedObservable();

    this.dirtyRecords = ko
      .pureComputed(() => {
        let records = [];

        if (gridSelectionMode.isDirty(self.config.selectionMode)) {
          // this will force a re-trigger when a record is added
          // todo: see if this is still needed
          self._recordsUpdatedTrigger();

          if (gridSelectionMode.isDirty(self.config.selectionMode)) {
            records = self.getDirtyRecords();
          }
        }

        return records;

        // defer observable so that mass updates don't trigger multiple evaluations
      })
      .extend({ deferred: true });

    this.captionText = ko.pureComputed(() => {
      // if the datasource is loading, we don't need to
      // show any captions in the background of the loading layer
      if (self.datasource.isLoading()) {
        return "";
      }

      const total = self.datasource.source().length;
      const searched = self.searched();

      if (!searched) {
        return self.config.startSearchText;
      }

      if (total === 0) {
        return self.config.emptyText;
      }

      return "";
    });

    pubsub.publish("preinit." + this.config.id, this.config);

    if (!this.isSortingFromRemoteStorage) {
      this._setInitialSort();
    }

    this._subscribeToSearch();
    if (this.config.defaultAction) {
      logger.warn("Double-click/default actions are no longer supported within the grid component.");
    }

    this.addDisposable(
      this.selected.subscribe((values) => {
        // only send an array when in multiselect mode
        const selected = self.multiSelect ? values : values[0];

        // let world know when we've changed our selected items
        // this is partically important for the actionbar
        pubsub.publish("selected.grid." + self.config.id, selected || null);
      })
    );

    this.addDisposable(
      this.dirtyRecords.subscribe((values) => {
        // let world know when the items are modified, important for actionbar when grid is in mixed-selection mode
        pubsub.publish("modified.grid." + self.config.id, values);
      })
    );

    if (this.selected().length > 0) {
      this.selected.notifySubscribers(this.selected());
    }

    if (this.config.groups && this.config.groups.length > 0) {
      this._initGroups();
    }

    if (this.multiSelect && this.selectable) {
      this.addDisposable((this.selectionState = new SelectionState(this.datasource.source, null, this)));
    }

    // note: adding this before caused some issues with the picker
    // need to verify that this does not cause issues now
    repository.add(this.config.id, this.selected);

    this.addDisposable(pubsub.subscribe("deleted." + this.config.id, this.onDelete, this));

    if ($container.length > 0) {
      // set visiblity on outer container
      ko.applyBindingsToNode($container[0], { visible: config.visible });
    }

    ko.renderTemplate("grid-template", this, {}, this.$element[0]);
    this.bindActions();

    plexImport("currentPage")[this.config.id] = this;

    this.$grid = this.$element.grid(this.config, this.datasource.data).data("grid");

    this.ownBanner = banner.findClosest(this.$element);
    this.banner = banner.getBanner();

    if (this.isSortingFromRemoteStorage) {
      this._restoreSortingFromRemoteStorage();
    }

    if (this.isColumnsWidthFromRemoteStorage) {
      this._restoreColumnsWidthFromRemoteStorage();
    }
  },

  getDirtyRecords: function () {
    const records = [];
    const self = this;

    self.datasource.source().forEach((record) => {
      if ("$$dirtyFlag" in record) {
        // treat each record in a pivoted dataset as a separate dirty record
        if (self.header.isCrosstab) {
          if ("$$buckets" in record) {
            record.$$buckets.forEach((child) => {
              if (child.$$data && "$$dirtyFlag" in child.$$data && child.$$data.$$dirtyFlag.isDirty()) {
                records.push(child.$$data);
              }
            });
          }
        } else if (record.$$dirtyFlag.isDirty()) {
          records.push(record);
        }
      }
    });

    return records;
  },

  getStateColumnId: function (column) {
    const unwrappedHeaderName = ko.unwrap(column.headerName);
    const headerName = unwrappedHeaderName == null ? "_" : unwrappedHeaderName;
    const columnId = headerName + column.id;
    return columnId;
  },

  _getConfigData: function () {
    const { data, customDataResults } = this.config;

    if (!data || !customDataResults) {
      return data;
    }

    let mergedData = data;
    customDataResults.forEach((result) => {
      if (result.keyPropertyNames) {
        mergedData = arrayUtils.leftJoin(mergedData, result.rows || [], result.keyPropertyNames, {
          emptyRecord: result.emptyRecord
        });
      }
    });

    return mergedData;
  },

  _initGroups: function () {
    const self = this;

    this.selectedGroup = ko.observable();

    this.config.groups.forEach((groupConfig) => {
      if (groupConfig.headers && groupConfig.headers.length > 0) {
        groupConfig.headers.forEach((header) => {
          setConfigCollapsibleFeature(header, self.config);
        });
      }
    });

    this.addDisposable(
      this.datasource.data.subscribe((values) => {
        if (self.config.selectionTarget === selectionTarget.group) {
          setSelectableRecords(values, self.config.groups);
        }

        setSelectableGroups(values, self.config.groups, self);
        setCollapsibleGroups(values, self);
      })
    );

    // manually trigger to set the selectables since we already have the data
    if (this.config.selectionTarget === selectionTarget.group) {
      setSelectableRecords(this.datasource.data(), this.config.groups);
    }

    setSelectableGroups(this.datasource.data(), this.config.groups, this);

    setCollapsibleGroups(this.datasource.data(), this);

    this.addDisposable(
      this.selectedGroup.subscribe((value) => {
        pubsub.publish("selectedGroup.grid." + self.config.id, value);
      })
    );
  },

  _initSelectedObservable: function () {
    const self = this;
    const selectedObservable = this.datasource.source.filter((record) => {
      self._recordsUpdatedTrigger();

      if (record.$$selected && record.$$selected()) {
        return true;
      }

      if (record.$$dirtyFlag && self.config.selectionMode === selectionModes.dirty) {
        return record.$$dirtyFlag.isDirty();
      }

      return false;
    });

    // If selected was already initilized, copy subscriptions
    if (this.selected) {
      knockoutUtils.copySubscriptions(this.selected, selectedObservable);
      repository.add(this.config.id, selectedObservable);
    }

    let lastSelected = selectedObservable.peek();
    this.addDisposable(
      selectedObservable.subscribe((selected) => {
        // if both arrays are empty we don't need to respond to the change
        // ideally the observable would not be triggering these kind of
        // notifications
        // note: this was causing issues because the save state call was
        // getting triggered very early before the grid was rendered
        // causing incorrect column widths to be stored
        if (lastSelected.length !== selected.length || selected.length !== 0) {
          this.saveState();
        }

        lastSelected = selected;
      })
    );

    // note: we are throttling this observable to more efficiently handle select all changes
    // todo: can this replace with a standard deferred extender?
    this.selected = selectedObservable.extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 10 } });
  },

  postInit: function () {
    let loading = false;

    // todo: this should probably be encapsulated somewhere - i believe similar logic is used to
    // bind data to dropdowns and is likely used in a few other areas.
    if (this.config.dataSource) {
      const self = this;
      let data, observableSource;
      if (this.config.dataSource.propertyName) {
        const source = repository.get(this.config.dataSource.sourceId);
        if (source) {
          observableSource = dataUtils.getObservable(source, this.config.dataSource.propertyName);
          if (observableSource) {
            data = observableSource();
            this.addDisposable(
              observableSource.subscribe((values) => {
                if (!loading) {
                  // set loading variable to prevent circular loop
                  loading = true;
                  self.load(values);
                  // We have to re-init the selected observable to
                  // remap ko projections filter
                  self._initSelectedObservable();
                  loading = false;
                }
              })
            );
          } else {
            data = dataUtils.getValue(source, this.config.dataSource.propertyName);
          }
        }
      } else {
        data = this.config.dataSource.data;
      }

      if (data) {
        this.originalData = ko.toJS(data);

        loading = true;
        this.load(data);
        loading = false;
      } else {
        this.originalData = [];
      }
    }
  },

  renderComplete: function (value) {
    if (value !== undefined) {
      this.rendered(value);
    }

    return ko.unwrap(this.config.visible) ? this.rendered : ko.observable(true);
  },

  getTitleColSpan: function () {
    let colspan = this.header.columns().filter((col) => {
      return col.visible();
    }).length;

    if (this.multiSelect && this.selectable) {
      colspan++;
    }

    if (this.config.ranking) {
      colspan++;
    }

    return colspan;
  },

  getPrintTitleColSpan: function () {
    return this.header.columns().filter((col) => {
      return col.printVisible();
    }).length;
  },

  getSelectedBySelectionMode: function () {
    return gridSelectionMode.isDirty(this.config.selectionMode) ? this.dirtyRecords() : this.selected();
  },

  bindActions: function () {
    const self = this;

    if (this.config.gridActions && this.config.gridActions.length > 0) {
      this.elements = this.elements || {};

      const gridContent = this.$element.closest(".plex-grid-content");
      const $buttons = gridContent.siblings(".plex-grid-buttons");
      if ($buttons.length === 0) {
        throw new Error("Unable to find the button section to bind the actions to.");
      }

      this.config.gridActions.forEach((el) => {
        el.parent = self;
        elementHandler.initElement(el, {}, null, self);
        el.executeAction = function () {
          const selected = self.getSelectedBySelectionMode();
          if (selected.length > 0 || el.action.type === "Back" || self.config.allowEmptySubmit) {
            // for now prevent any action from executing if no records are selected
            // in the future it may be necessary to reuse some of the enable/disable
            // logic from the actionbar to give more flexibility here
            actionHandler.executeAction(el.action, selected);
          } else if (gridSelectionMode.isDirty(self.config.selectionMode)) {
            self.banner.setMessage({ text: NO_RECORDS_MODIFIED, autoGlossarize: true });
          } else if (gridSelectionMode.isSelectable(self.config.selectionMode)) {
            self.banner.setMessage({ text: NO_RECORDS_SELECTED, autoGlossarize: true });
          }
        };
      });

      ko.renderTemplate("grid-actions", this.config, {}, $buttons[0]);

      const $modalContent = this.$element.closest(".modal-content");
      if ($modalContent.length > 0) {
        const $modalFooter = $modalContent.find(".modal-footer");

        $modalContent.addClass("plex-dialog-has-buttons");
        $modalFooter.append($buttons);
      } else {
        $("body").addClass("plex-grid-has-buttons");
      }
    }
  },

  _subscribeToSearch: function () {
    if (!this.config.searchActionId) {
      return;
    }

    const self = this;
    let currentSub;

    const shouldSearch = ko.computed(() => {
      return ko.unwrap(self.config.visible) || self.alwaysSearch();
    });

    function toggleSubscription(enable) {
      if (enable) {
        currentSub = currentSub || pubsub.subscribe("searched." + self.config.searchActionId, self.load, self);
      } else {
        self.ownBanner && self.ownBanner.reset();

        currentSub && currentSub.dispose();
        currentSub = null;
      }
    }

    this.addDisposable(shouldSearch.subscribe(toggleSubscription));
    this.addDisposable({
      dispose: function () {
        shouldSearch.dispose();
        currentSub && currentSub.dispose();
      }
    });

    toggleSubscription(shouldSearch());
  },

  dispose: function () {
    let sub;
    this.isDisposed(true);

    if (this.$grid) {
      this.$grid.dispose();
      this.$grid = null;
    }

    while ((sub = this._dataDisposables.pop())) {
      sub.dispose();
    }

    serverBannerProvider.removeBanner(this);
    this.banner && this.banner.cancel();
    this.ownBanner && this.ownBanner.cancel();

    repository.remove(this.config.id);
    pageHandler.removeState(this.config.id);
    delete plexImport("currentPage")[this.config.id];

    this._base.apply(this, arguments);
  },

  _setInitialSort: function () {
    const self = this;
    this.header.currentSort = [];

    if (this.initialSort && this.initialSort.length > 0) {
      this.initialSort.forEach((sortOrder) => {
        self.header._processSortOrderObject(sortOrder);
      });
    }

    self.header._setBaseSort();

    if (this.header.currentSort.length > 0) {
      this.saveState();

      if (this.hasGroups()) {
        this.datasource.sort(this.header.mergeGroups());
      } else {
        this.datasource.sort(this.header.currentSort);
      }
    }
  },

  _setLateInitialSort: function () {
    const $gridBackup = this.$grid;
    try {
      this.$grid = null;
      this._setInitialSort();
    } finally {
      this.$grid = $gridBackup;
    }
  },

  _validateRemoteState: function (remoteState, selectProperty) {
    const isValid =
      remoteState &&
      remoteState.gridState &&
      remoteState.gridState.gridId === this.config.id &&
      selectProperty(remoteState.gridState) &&
      selectProperty(remoteState.gridState).length > 0;

    return !!isValid;
  },

  _restoreSortingFromRemoteStorage: function () {
    return remoteStateStorage
      .getState()
      .then((remoteState) => {
        if (this._validateRemoteState(remoteState, (i) => i.sort)) {
          this.initialSort = remoteState.gridState.sort;
        }
        this._setLateInitialSort();
      })
      .catch(() => this._setLateInitialSort());
  },

  _restoreColumnsWidthFromRemoteStorage: function () {
    return remoteStateStorage.getState().then((remoteState) => {
      if (this._validateRemoteState(remoteState, (i) => i.columnsWidth) && this.$grid) {
        const state = pageHandler.restoreState(this.config.id) || {};
        state.columnsWidth = remoteState.gridState.columnsWidth;
        pageHandler.setState(this.config.id, state);
        this.$grid.resize();
      }
    });
  },

  _disposeData: function (data) {
    // todo: clean up record element controllers
    data.forEach((record) => {
      record.$$initialized = false;

      if ("$$controller" in record) {
        if ("dispose" in record.$$controller) {
          record.$$controller.dispose();
        }

        delete record.$$controller;
      }

      if ("$$sections" in record) {
        Object.keys(record.$$sections).forEach((id) => {
          record.$$sections[id].dispose?.();
        });

        delete record.$$sections;
      }
    });

    let sub;
    while ((sub = this._dataDisposables.pop())) {
      sub.dispose();
    }
  },

  onChange: function (propertyName, callback, options) {
    /// <summary>Register a callback to be executed when any record has a change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="options"> Options include
    ///   `async` - if set to false the callback will be excuted synchronously (optional - default to true)
    ///   'deep' - if set on array based properties, the event will be triggered when items are added, deleted or updated (optional - default to false)
    /// </param>

    this.datasource.onChange(propertyName, callback, options);
  },

  onRecordChange: function (record, propertyName, callback, options) {
    /// <summary>Register a callback to be executed when a record has a change for the property registered. (The context of the callback will be the changed record.)</summary>
    /// <param name="record">The record to subscribe to.</param>
    /// <param name="propertyName">The property name to subscribe to.</param>
    /// <param name="callback">The callback to execute.</param>
    /// <param name="options"> Options include
    ///   `async` - if set to false the callback will be excuted synchronously (optional - default to true)
    ///   'deep' - if set on array based properties, the event will be triggered when items are added, deleted or updated (optional - default to false)
    /// </param>
    /// <returns type="Object">Returns the subscription object, which has a `dispose` function to cancel the subscription.</returns>

    return this.datasource.onRecordChange(record, propertyName, callback, options);
  },

  onSave: function () {
    const selected = this.getSelectedBySelectionMode();
    // reset dirty flags
    selected.forEach((record) => {
      if ("$$dirtyFlag" in record) {
        record.$$dirtyFlag.reset();
      }
    });

    this.resetInitialValues();
  },

  resetInitialValues: function () {
    this.results().forEach((record) => {
      if ("$$controller" in record) {
        Object.keys(record.$$controller.elements).forEach((elementName) => {
          const el = record.$$controller.elements[elementName];
          if (el.propertyName && "displayValue" in el) {
            el.initialDisplayValue = el.displayValue();
          }
        });
      }
    });
  },

  restoreState: function (state) {
    state = state || pageHandler.restoreState(this.config.id);
    if (state) {
      if (state.sort) {
        this.initialSort = state.sort;
      }

      if (this.selectable && !this.config.ignoreSelectedState && state.selected && state.selected.length > 0) {
        this.restoredSelected = state.selected;
      }

      if (this.config.virtualizeRows !== false) {
        if (state.columnsWidth) {
          this.initialColumnsWidth = state.columnsWidth;
        }

        if (state.isResized) {
          this.config.isResized = state.isResized;
        }
      }
    }

    if (!this.config.belongsToPicker && remoteStateStorage.isAvailable()) {
      this.isSortingFromRemoteStorage = !(state && state.sort);
      this.isColumnsWidthFromRemoteStorage = !(state && state.columnsWidth) && this.config.virtualizeRows !== false;
    }
  },

  saveState: function () {
    pageHandler.setState(this.config.id, this.getState());
  },

  getState: function () {
    const self = this;
    const state = {};

    if (this.header.currentSort && this.header.currentSort.length > 0) {
      state.sort = this.header.currentSort.map((sortOrder) => {
        return {
          colIndex: sortOrder.colIndex,
          propertyName:
            typeof sortOrder.propertyNameOrValueProvider === "string"
              ? sortOrder.propertyNameOrValueProvider
              : sortOrder.propertyNameOrValueProvider.column.propertyName,
          ascending: sortOrder.ascending
        };
      });

      if (this.config.baseSort && this.config.baseSort.length > 0) {
        // remove base sort from state - this will be applied automatically
        state.sort.splice(-this.config.baseSort.length, this.config.baseSort.length);
      }
    }

    if (this.selectable && !this.config.ignoreSelectedState) {
      // if we have a key defined that's all we need to store
      // otherwise we need to store all values for comparison
      state.selected = this.selected().map((record) => {
        return self.config.keyPropertyName ? record[self.config.keyPropertyName] : dataUtils.cleanse(record);
      });
    }

    if (self.config.virtualizeRows !== false) {
      const $grid = self.$grid;
      const currentState = pageHandler.restoreState(self.config.id) || {};
      const currentWidths = currentState.columnsWidth || [];

      // if grid isn't ready retain state if it already exists
      state.columnsWidth = currentWidths;
      state.isResized = self.config.isResized || currentState.isResized;

      if ($grid && $grid.$table) {
        const $cells = $grid.getCells("sample");
        const visibleColumns = self.columns.filter((c) => c.visible());
        const columnOffset = self.$grid.getColumnOffset();

        if ($cells.length > 0) {
          state.columnsWidth = [];

          for (let i = 0; i < $cells.length; i++) {
            const columnWidth = $cells[i].getBoundingClientRect().width;
            let columnId = null;

            if (i >= columnOffset && i - columnOffset < visibleColumns.length) {
              // account for system columns (ex: checkbox, ranking)
              columnId = self.getStateColumnId(visibleColumns[i - columnOffset]);
            }

            const columnInfo = {
              colIndex: i,
              columnId,
              width: columnWidth
            };

            const currentColumnState = ko.utils.arrayFirst(
              currentWidths,
              (c) => c.columnId === columnId || (!c.columnId && c.colIndex === i)
            );
            if (currentColumnState) {
              columnInfo.minWidth = currentColumnState.minWidth;
            } else {
              columnInfo.minWidth = columnWidth;
            }

            state.columnsWidth.push(columnInfo);
          }
        }
      }
    }

    return state;
  },

  load: function (data) {
    this.fire("loading");
    this.renderComplete(false);

    if (!(data instanceof DataResult)) {
      data = new DataResult(data);
    }

    serverBannerProvider.removeBanner(this);
    if (data.serverInfo) {
      serverBannerProvider.setBanner(data.serverInfo, this);
    }

    // get raw array
    let results = ko.utils.peekObservable(data.data);

    this.ownBanner.reset();
    if (data.recordLimitExceeded) {
      this.ownBanner.setMessage({ text: RECORD_LIMIT_EXCEEDED, tokens: [results.length], autoGlossarize: true });
    }

    // remove prior subscriptions
    this.datasource.dispose();
    this.searched(true);

    // transform data if a mapper function is provided
    if (this.config.dataMapper) {
      results = results.map(this.config.dataMapper);
    }

    this.searchCriteria = data.criteria;
    this.recordLimitExceeded(data.recordLimitExceeded);
    this._initData(results);
    this.datasource.load(results, false, ko.utils.peekObservable(data.outputParameters));

    if (this.selectionState) {
      this.selectionState.reset();
    }

    // note: subscribe repeaters
    const rawLength = this.datasource.raw.length;

    for (let i = 0; i < rawLength; i++) {
      this._subscribeRepeaters(this.datasource.raw[i]);
    }

    this.renderComplete(true);
    this.fire("loaded");
  },

  _subscribeRepeaters: function (raw) {
    const self = this;
    const rawController = raw.$$controller;

    if (rawController && "elements" in rawController) {
      Object.keys(rawController.elements).forEach((prop) => {
        const controller = rawController.elements[prop].controller;

        if (controller && "resetValidationSetup" in controller && "$grid" in self) {
          const grid = self.$grid;
          if ("setupValidation" in grid && $.isFunction(self.$grid.setupValidation)) {
            self._dataDisposables.push(
              controller.resetValidationSetup.subscribe(() => {
                self.$grid.setupValidation();
              })
            );
          }
        }
      });
    }
  },

  _initData: function (data) {
    const uninitData = data.filter((record) => {
      return !record.$$initialized;
    });

    if (uninitData.length > 0) {
      uninitData.forEach(this._initRecord, this);
      this._recordsUpdatedTrigger.notifySubscribers();
    }
  },

  _initRecord: function (record) {
    if (record.$$initialized) {
      return;
    }

    const { selectionPropertyName, disableTrackClientProperties, dataProvider, ranking, allowWhiteSpace } = this.config;

    record.$$disabled = createOrReturnObservable(record.$$disabled, !!record.$$disabled);
    record.$$selected = createOrReturnObservable(record.$$selected, !!record.$$selected);
    record.$$rendered = createOrReturnObservable(record.$$rendered, false);

    const selectedPropertyExists = !!selectionPropertyName && selectionPropertyName in record;
    if (selectedPropertyExists && this.multiSelect) {
      record.$$selected(record[selectionPropertyName]);
    }

    this._dataDisposables.push(
      record.$$disabled.subscribe((disabled) => {
        this.toggleEnable(record, !disabled);
      })
    );

    this._restoreSelectedRecord(record);

    this._dataDisposables.push(
      record.$$selected.subscribe((selected) => {
        this.toggleSelection(record, selected);
        this.fire(selected ? "recordSelected" : "recordDeselected", record, selected);
      })
    );

    if (!disableTrackClientProperties) {
      record.$$dirtyFlag = record.$$dirtyFlag || ko.dirtyFlag(record, { allowWhiteSpace });
      dataUtils.trackObject(record, dataProvider?.computedProperties);

      if (selectedPropertyExists) {
        record.$$originalSelectionValue = record[selectionPropertyName];
      }

      if (ranking?.propertyName && ranking.propertyName in record) {
        record.$$originalRankingValue = record[ranking.propertyName];
      }
    } else if (dataProvider?.computedProperties) {
      dataProvider.computedProperties.forEach((prop) => {
        record[prop.name] = prop;
        dataUtils.trackProperty(record, prop.name);
      });
    }

    record.$$initialized = true;
  },

  _restoreSelectedRecord: function (record) {
    if (!Array.isArray(this.restoredSelected) || this.restoredSelected.length === 0) {
      return;
    }

    const { selectionPropertyName, keyPropertyName } = this.config;

    const restored = this.restoredSelected.find((a) => {
      return keyPropertyName ? a === record[keyPropertyName] : dataUtils.compareObjects(a, record);
    });

    if (restored) {
      record.$$selected(true);
      this.restoredSelected.splice(this.restoredSelected.indexOf(restored), 1);
      if (selectionPropertyName && selectionPropertyName in record) {
        record[selectionPropertyName] = true;
      }
    }
  },

  onDelete: function (data) {
    const self = this;
    const propertyName = this.config.keyPropertyName;
    let item, i;

    // convert to array so we are only dealing with one interface
    if (!Array.isArray(data)) {
      if (
        self.config.selectionTarget === selectionTarget.group &&
        self.config.groups &&
        self.config.groups.length > 0
      ) {
        data = data.$$allDataForGroup ? data.$$allDataForGroup : [];
      } else {
        data = [data];
      }
    }

    i = data.length;

    while (i--) {
      item = data[i];

      // delete by property name if available, otherwise look for the instance of the item in the source
      if (propertyName) {
        // eslint-disable-next-line no-loop-func
        self.datasource.source.remove((record) => {
          return record[propertyName] === item[propertyName];
        });
      } else {
        self.datasource.source.remove(item);
      }
    }
  },

  toggleSelections: function () {
    if (this.selectionState) {
      this.selectionState.toggle();
    }
  },

  toggleCollapsed: function (boundObject) {
    // data == group
    // options = groupt config
    boundObject.data.$$collapsed(!boundObject.data.$$collapsed());
  },

  toggleSelection: function (item, selected) {
    // if nothing is passed in simply toggle the current state
    if (selected === undefined) {
      // toggle and exit out
      // this will be triggered again via the subscription
      item.$$selected(!item.$$selected());
      return;
    }

    if (this.config.selectionPropertyName && this.config.selectionPropertyName in item) {
      item[this.config.selectionPropertyName] = selected;
    }

    if (!this.multiSelect && selected) {
      // deselect all currently selected items
      // (should only be one at most, but ya know...)
      this.datasource.raw.forEach((record) => {
        if (record !== item) {
          record.$$selected(false);
        }
      });
    }

    // todo: it might make more sense to move this logic into the grid
    const $grid =
      this.$grid || plexImport("currentPage")[this.config.id] ? plexImport("currentPage")[this.config.id].$grid : null;

    if ($grid) {
      $grid.toggleRowSelection(item.$$index, selected);
    }
  },

  toggleGroupSelection: function (group) {
    const selected = group.$$selected();
    if (selected) {
      // deselect any currently selected groups
      group.parent.groups.forEach((g) => {
        if (g !== group && g.$$selected()) {
          g.$$selected(false);
        }
      });
    }

    this.selectedGroup(selected ? group : null);

    if (this.$grid) {
      this.$grid.toggleHeaderSelection(group.groupIndex, group.index, selected);
    }
  },

  toggleCollapsible: function (group, collapse, parentGroup) {
    const self = this;
    const config = group.config;

    if (group.groups && group.groups.length > 0) {
      group.groups.forEach((subGroup) => {
        self.toggleCollapsible(subGroup, collapse, group);
      });
    } else {
      group.data.forEach((record) => {
        if (!parentGroup || (parentGroup && group.$$collapsed() === false) || !group.config.collapsible) {
          if (self.$grid) {
            self.$grid.toggleRowCollapsed(record.$$index, collapse);
          }
        }
      });
    }

    if (config && config.headers && config.headers.length > 0) {
      config.headers.forEach((_header) => {
        if (self.$grid) {
          self.$grid.toggleHeaderCollapsed(group.gIndex, collapse, !!parentGroup);
        }
      });
    }

    if (config && config.footers && config.footers.length > 0) {
      if (!parentGroup || (parentGroup && group.$$collapsed() === false) || !group.config.collapsible) {
        config.footers.forEach((_footer) => {
          if (self.$grid) {
            self.$grid.toggleFooterCollapsed(group.gIndex, collapse);
          }
        });
      }
    }
  },

  toggleEnable: function (record, enabled) {
    if (this.$grid) {
      this.$grid.toggleRowEnable(record.$$index, enabled);
    }
  },

  hasGroups: function () {
    return this.config.groups && this.config.groups.length > 0;
  },

  onSortClick: function (column, e) {
    if (e.target.nodeName.toLowerCase() === "input") {
      return true;
    }

    if (this.config.isSortable !== false && column.isSortable && this.results().length > 0) {
      this.sort(column, !column.sortedAsc());
    }
    return undefined;
  },

  sort: function (column, ascending) {
    // todo: wtf? this shouldn't be called with ascending as an object - need to find where this is happening and fix it
    if (typeof ascending === "object" && ascending.target && ascending.type === "click") {
      return this.onSortClick(column, ascending);
    }

    this.header.setSortedColumn(column, ascending);
    this.header.updateCurrentSort();

    this.saveState();

    let sortInfo;

    if (this.hasGroups()) {
      sortInfo = this.header.mergeGroups();
    } else {
      sortInfo = this.header.currentSort;
    }

    this.applySort(sortInfo);

    return undefined;
  },

  applySort: function (sortInfo) {
    this.datasource.sort(sortInfo);
    this.$grid.resize();
  },

  /**
   * Add a new empty record to the grid, merging in the properties if included.
   * @param {Object} [properties] The properties/values to include in the new record.
   * @returns {Object} Returns the new record.
   */
  addEmptyRecord: function (properties) {
    return this.insertEmptyRecord(this.datasource.source().length, properties);
  },

  /**
   * Add a new record to the grid. This method ignores arguments so it is
   * safe to call from chain an action. This is used in the "Add Record" button.
   * @returns {Object} Returns the new record.
   */
  addNewRecord: function () {
    return this.addEmptyRecord();
  },

  /**
   * Inserts a new record at the given index. If properties are included they
   * are merged into the new record.
   * @param {Number} index The index to insert the record at.
   * @param {Object} [properties] The properties/values to include in the new record.
   * @returns {Object} Returns the new record.
   */
  insertEmptyRecord: function (index, properties) {
    // go ahead and mark as searched
    this.searched(true);

    // need to make a deep clone of the object so that observable meta-data isn't copied forward
    // todo: why is there observable meta-data on the empty record? The empty record should not be unaltered
    let customDataEmptyRecord = {};
    if (this.config.customDataResults) {
      this.config.customDataResults.forEach((result) => {
        customDataEmptyRecord = { ...customDataEmptyRecord, ...result.emptyRecord };
      });
    }

    const newRecord = $.extend(true, {}, this.config.emptyRecord, properties, customDataEmptyRecord);
    this._initRecord(newRecord);

    if (index >= 0 && index < this.datasource.source().length) {
      this.datasource.source.splice(index, 0, newRecord);
    } else {
      this.datasource.source.push(newRecord);
    }

    this._subscribeRepeaters(newRecord);
    return newRecord;
  },

  toDocumentXml: function () {
    const self = this;
    if (!self.config.visible()) {
      return "";
    }

    const doc = new DocumentXml("plex-grid");

    if (self.captionText() && !self.isDisposed()) {
      const captionNode = doc.createNode("plex-grid-caption");

      if (self.config.title) {
        captionNode.addElement("plex-grid-title", ko.unwrap(self.config.title));
      }

      captionNode.addElement("plex-grid-message", ko.unwrap(self.captionText));

      return doc.serialize();
    }

    doc.appendXml(self.$grid.print());

    return doc.serialize();
  },

  validate: function () {
    const isValid = !this.setupValidation || this.validator.isValid();

    return isValid;
  },

  onValidation: function (_result) {
    if (this.$grid) {
      this.$grid.resize();
    }
  },

  rankRecord: function (oldIndex, newIndex) {
    const record = this.datasource.source()[oldIndex];

    // We can sort records only between old position and new position of the element.
    const recordsToSort = this.datasource.source.slice(Math.min(newIndex, oldIndex), Math.max(newIndex, oldIndex) + 1);
    let i;

    if (newIndex > oldIndex) {
      const lastRecordSortOrder = recordsToSort[recordsToSort.length - 1][this.config.ranking.propertyName];

      for (i = recordsToSort.length - 1; i > 0; i--) {
        recordsToSort[i][this.config.ranking.propertyName] = recordsToSort[i - 1][this.config.ranking.propertyName];
      }

      record[this.config.ranking.propertyName] = lastRecordSortOrder;
    } else {
      const firstRecordSortOrder = recordsToSort[0][this.config.ranking.propertyName];

      for (i = 0; i < recordsToSort.length - 1; i++) {
        recordsToSort[i][this.config.ranking.propertyName] = recordsToSort[i + 1][this.config.ranking.propertyName];
      }

      record[this.config.ranking.propertyName] = firstRecordSortOrder;
    }

    this.applySort(this.header.currentSort);

    // todo: should we just listen for array changes and fire a `moved` event?
    this.fire("recordRanked", { record, oldIndex, newIndex });
  },

  undoAll: function () {
    if (this.originalData) {
      this.load($.extend(true, [], this.originalData));
    }
  },

  getSearchIterator: function (searchText) {
    const searchMatcher = new RegExp(regexUtils.escapeText(searchText), "i");
    const columns = this.columns.filter((column) => column.isSearchable && column.visible());
    const records = this.datasource.data();
    const length = records.length;
    let index = 0;

    // handle groups
    if (env.features[GRID_GROUP_SEARCH_FEATURE_FLAG] && this.hasGroups()) {
      return getGroupSearchIterator(records, columns, searchMatcher, isSearchMatch, columnMatches);
    }

    // return an iterator
    return {
      next: function () {
        let record;

        while (index < length) {
          record = records[index];
          if (columns.some(columnMatches(searchMatcher, record, index))) {
            return {
              done: false,
              value: {
                index: index++,
                record
              }
            };
          }

          index++;
        }

        return {
          done: true
        };
      }
    };
  },

  resize: function () {
    this.$grid.resize();
  }
};

// factory method
GridController.create = function () {
  return new GridController();
};

function columnMatches(pattern, record, index) {
  return function (column, colIndex) {
    const value = column.valueProvider.getFormattedValue(record, index, colIndex);
    return isSearchMatch(pattern, value);
  };
}

function isSearchMatch(pattern, value) {
  if (Array.isArray(value)) {
    return value.some((item) => isSearchMatch(pattern, item));
  }

  if (value && typeof value === "object") {
    return Object.keys(value).some((key) => isSearchMatch(pattern, value[key]));
  }

  return pattern.test(value);
}

function shouldSetupValidation(validationModel) {
  if (validationModel.rules && validationModel.rules.length > 0) {
    return true;
  }

  const props = validationModel.validationCollectionProperties;
  if (props && props.length > 0) {
    return true;
  }

  return false;
}

function setSelectableRecords(records, configs) {
  records.groups.forEach((group) => {
    if (configs[group.groupIndex].selectable) {
      setSelectableGroupRecords(getAllDataForGroup(group));
    } else if (group.groups.length > 0) {
      group.groups.forEach((childGroup) => {
        if (childGroup.groups.length > 0) {
          setSelectableRecords(childGroup, configs);
        } else if (configs[childGroup.groupIndex].selectable && childGroup.data) {
          setSelectableGroupRecords(childGroup.data);
        }
      });
    }
  });
}

function setSelectableGroupRecords(data) {
  let i = data.length;
  while (i--) {
    data[i].$$selectable = i === 0;
    if (i === 0) {
      data[i].$$allDataForGroup = data;
    }
  }
}

function getAllDataForGroup(group) {
  if (group.groups.length > 0) {
    let all = [];
    group.groups.forEach((current) => {
      all = all.concat(getAllDataForGroup(current));
    });

    return all;
  }

  return group.data;
}

function setSelectableGroups(parent, configs, ctrl) {
  if (!parent.groups || parent.groups.length === 0) {
    return;
  }

  const config = configs[parent.groups[0].groupIndex];
  parent.groups.forEach((group) => {
    if (config.headers && config.headers.length > 0) {
      config.headers.forEach((header) => {
        if (header.selectable && !("$$selected" in group)) {
          group.$$selected = ko.observable(false);
          group.$$selected.subscribe(() => {
            ctrl.toggleGroupSelection(group);
          });
        }
      });
    }

    if (group.groups && group.groups.length > 0) {
      group.groups.forEach((child) => {
        setSelectableGroups(child, configs, ctrl);
      });
    }
  });
}

function hasCollapsibleFeature(features) {
  if (features && features.length > 0) {
    const collapsibleFeatures = features.filter((feature) => {
      return feature.name === "Collapsible";
    });

    return collapsibleFeatures.length > 0;
  }

  return false;
}

function getCollapsibleFeature(features) {
  if (features && features.length > 0) {
    const collapsibleFeatures = features.filter((feature) => {
      return feature.name === "Collapsible";
    });

    return collapsibleFeatures.length > 0 ? collapsibleFeatures[0] : null;
  }

  return null;
}

function setConfigCollapsibleFeature(featuresItem, config) {
  if (featuresItem.features && hasCollapsibleFeature(featuresItem.features)) {
    const feature = getCollapsibleFeature(featuresItem.features);
    featuresItem.collapsible = true;
    featuresItem.initialStateCollapsed = feature.initialStateCollapsed;
    if (config) {
      config.collapsible = true;
      if (!("initialStateCollapsed" in config)) {
        config.initialStateCollapsed = feature.initialStateCollapsed;
      }
    }
  }
}

function setCollapsibleGroups(parent, ctrl) {
  if (!parent.groups || parent.groups.length === 0) {
    return;
  }

  parent.groups.forEach((group) => {
    if (group.config.headers && group.config.headers.length > 0) {
      group.config.headers.forEach((header) => {
        if (header.collapsible) {
          group.config.collapsible = true;

          if (!("$$collapsed" in group)) {
            group.$$collapsed = ko.observable(header.initialStateCollapsed || false);
            group.toggleCollapsed = ctrl.toggleCollapsed;
            group.$$collapsed.subscribe(() => {
              ctrl.toggleCollapsible(group, group.$$collapsed());
            });
          }
        }
      });
    }

    if (ctrl.config.collapsible) {
      if (!group.config.collapsible) {
        group.$$collapsed = ko.observable(ctrl.config.initialStateCollapsed);
      }

      if (group.groups && group.groups.length > 0) {
        setCollapsibleGroups(group, ctrl);
      } else {
        group.data.forEach((record, _indexInGroup) => {
          record.$$collapsed = group.$$collapsed();
        });
      }
    }
  });
}

function GridDataContext(source, grid) {
  // note: the properties here need to be title-case to match C# conventions for expression evaluation
  // eslint-disable-next-line no-invalid-this
  Object.defineProperties(this, {
    HasRecords: {
      enumerable: true,
      get: function () {
        // if this is accessed then a search is required even when the grid isn't visible
        grid.alwaysSearch(true);
        return source().length > 0;
      }
    },

    Records: {
      enumerable: true,
      get: source
    }
  });
}

jsUtils.mixin(GridController, "events");

module.exports = GridController;
plexExport("GridController", GridController);
