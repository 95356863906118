ï»¿const ko = require("knockout");
const glossary = require("../Globalization/plex-glossary-handler");
const nav = require("../Core/plex-navigate");
const Ask = require("../Dialogs/plex-dialogs-ask");
const fileHandler = require("./plex-upload-file-handler");
const plexExport = require("../../global-export");

const imageHandler = fileHandler.extend({
  onCreatePreview: function (preview) {
    const self = this;

    preview.dataUrl = ko.observable();
    preview.width = ko.observable(100);
    preview.height = ko.observable(100);
    preview.resizeOptions = { percentResize: 100, percentIncrement: 5 };

    if (window.FileReader === undefined) {
      preview.width(1);
      preview.height(1);
    } else {
      const reader = new FileReader();
      reader.onload = function (loaded) {
        preview.dataUrl(loaded.target.result);
        if (self.config.preview === "fullsize" || self.config.preview === "resizable") {
          const image = new Image();
          image.src = loaded.target.result;
          image.onload = function () {
            preview.width(this.width);
            preview.height(this.height);
          };
        }
      };

      reader.readAsDataURL(preview.file);
    }
  },

  isPreviewMode: function () {
    return this.config.autoSubmit === false || this.config.preview !== "none";
  },

  getRouteUrl: function (data) {
    const routeData = {
      percentResize: data && data.previews.length > 0 ? data.previews[0].resizeOptions.percentResize : 100
    };

    if (this.overwrite === true) {
      routeData.Overwrite = true;
      this.overwrite = false;
    }

    return nav.buildUrl(this.config.uploadHandlerRoute, routeData);
  },

  onUploadFailed: function (results) {
    const self = this;
    if (results && results.length > 0 && !results[0].Data.Overwrite) {
      if (
        (results[0].Data.UploadFileResults || []).some((fileResult) => {
          return fileResult.AlreadyExists === true;
        })
      ) {
        glossary.getCustomerWordAsync("Do you wish to overwrite image?").done((glossarizedMessage) => {
          Ask(glossarizedMessage, ["Yes", "No"]).done((answer) => {
            if (answer.answerText === "Yes") {
              self.overwrite = true;
              self.controller.submitData();
            }
          });
        });
      }
    }
  }
});

module.exports = imageHandler;
plexExport("uploads.image", imageHandler);
