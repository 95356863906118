ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const raphael = require("raphael");
const FeatureProcessor = require("../Features/plex-feature-processor");
const FeatureResult = require("../Features/plex-feature-result");
const graphicFactory = require("./plex-graphics-factory");
const plexExport = require("../../global-export");

const alignments = {
  verticalLeft: 0,
  verticalCenter: 1,
  verticalRight: 2,
  horizontalTop: 3,
  horizontalMiddle: 4,
  horizontalBottom: 5
};

const Renderer = function (controller) {
  this.controller = controller;
  this.paper = raphael(
    $("#" + controller.config.id)[0],
    controller.config.width || "100%",
    controller.config.height || "100%"
  );
};

Renderer.prototype = {
  constructor: Renderer,

  renderNode: function (nodeConfig, data) {
    const self = this;

    const graphics = [];
    let container;
    let header = null;
    const config = $.extend(true, {}, nodeConfig.container);
    self._processFeatures(config, data);

    if (config.featuresResult.render === false) {
      return null;
    }

    graphics.push((container = self._renderContainer(config, data)));

    if (nodeConfig.hasHeader) {
      config.y = container.getBoundingBox().y - 7;
      graphics.push(
        (header = graphicFactory
          .create(config, self.controller, self.paper, data)
          .fill(nodeConfig.headerColor)
          .cursor("move")
          .toBack())
      );
    }

    const node = graphicFactory.create({ type: "set" }, self.controller, self.paper, data);
    graphics.forEach((graphic) => {
      node.addGraphic(graphic);
    });

    if (nodeConfig.draggableBody) {
      node.makeDraggable();
    } else if (header) {
      header.makeDraggable(node);
    }

    return node.moveTo(nodeConfig.x || 10, nodeConfig.y || 10);
  },

  renderConnection: function (connectionConfig, parentGraphic, childGraphic) {
    let connectionData = null;
    if (connectionConfig.colorCondition) {
      const connections = parentGraphic.$$connectionConfigs.filter((c) => {
        return c.childId === childGraphic.data[childGraphic.data.$$layout.identityPropertyName];
      });

      if (connections.length > 0) {
        connectionData = connections[0].data;
      }
    }

    if (connectionConfig.straightLine) {
      let colorData = connectionData;
      if (!colorData && connectionConfig.parentNode && connectionConfig.parentNode.colorCondition) {
        colorData = parentGraphic.data;
      } else if (!colorData && connectionConfig.childNode && connectionConfig.childNode.colorCondition) {
        colorData = childGraphic.data;
      }

      const connection = graphicFactory
        .create({ type: "line" }, this.controller, this.paper)
        .between(parentGraphic, childGraphic)
        .stroke(parentGraphic.evaluateColor(connectionConfig, colorData))
        .strokeWidth(connectionConfig.width)
        .toBack();

      parentGraphic.initConnectionAction(connectionConfig, childGraphic, connection, connectionData);
    } else {
      parentGraphic.connect(connectionConfig, childGraphic, connectionData);
    }
  },

  setPaperSize: function (viewBox) {
    this.paper.setSize(
      viewBox.width || this.controller.config.width || "100%",
      viewBox.height || this.controller.config.height || "100%"
    );
  },

  _processFeatures: function (config, data) {
    if (config.features && config.features.length > 0) {
      const featureProcessor = new FeatureProcessor(config.features);
      config.featuresResult = featureProcessor.process(data);
    } else {
      config.featuresResult = new FeatureResult();
    }
  },

  _renderContainer: function (config, data) {
    const self = this;
    const graphicObjects = [];

    config.graphics.forEach((graphicConfig) => {
      if (graphicConfig.action == null || graphicConfig.action.disabled !== true) {
        self._processFeatures(graphicConfig, data);
        if (graphicConfig.featuresResult.render) {
          if (graphicConfig.graphics) {
            graphicObjects.push(self._renderContainer(graphicConfig, data));
          } else {
            graphicObjects.push(graphicFactory.create(graphicConfig, self.controller, self.paper, data));
          }
        }
      }
    });

    let x = 0;
    let y = 0;
    const container = graphicFactory.create({ type: "set" }, self.controller, self.paper, data);

    const size = self._getExpectedContainerSize(graphicObjects);
    graphicObjects.forEach((graphic) => {
      const boundingBox = graphic.getBoundingBox();

      if (config.alignment <= alignments.verticalRight) {
        // vertical alignment
        if (config.alignment === alignments.verticalCenter) {
          x = (size.width - boundingBox.width) / 2;
        } else if (config.alignment === alignments.verticalRight) {
          x = size.width - boundingBox.width;
        }

        graphic.moveTo(x, y);
        y += boundingBox.height + config.spacing || 5;
      } else {
        // horizontal alignment
        if (config.alignment === alignments.horizontalMiddle) {
          y = (size.height - boundingBox.height) / 2;
        } else if (config.alignment === alignments.horizontalBottom) {
          y = size.height - boundingBox.height;
        }

        graphic.moveTo(x, y);
        x += boundingBox.width + config.spacing || 5;
      }

      container.addGraphic(graphic);
    });

    switch (config.type) {
      case "circle":
        self._updateConfigForCircleContainer(config, container.getBoundingBox());
        break;
      case "ellipse":
        self._updateConfigForEllipseContainer(config, container.getBoundingBox());
        break;
      default:
        self._updateConfigForRectContainer(config, container.getBoundingBox());
        break;
    }

    container.addGraphic(graphicFactory.create(config, self.controller, self.paper, data));
    return container.toBack();
  },

  _getExpectedContainerSize: function (items) {
    const size = { width: 0, height: 0 };
    items.forEach((graphic) => {
      const boundingBox = graphic.getBoundingBox();
      if (boundingBox.height > size.height) {
        size.height = boundingBox.height;
      }

      if (boundingBox.width > size.width) {
        size.width = boundingBox.width;
      }
    });

    return size;
  },

  _updateConfigForRectContainer: function (config, r) {
    if (config.width > r.width) {
      config.x = r.x - (config.width - r.width) / 2;
    } else {
      config.x = r.x - 5;
      config.width = r.width + 10;
    }

    if (config.height > r.height) {
      config.y = r.y - (config.height - r.height) / 2;
    } else {
      config.y = r.y - 5;
      config.height = r.height + 10;
    }
  },

  _updateConfigForCircleContainer: function (config, c) {
    c.radius = c.height > c.width ? c.height / 2 : c.width / 2;
    config.x = c.x + c.width / 2;
    config.y = c.y + c.height / 2;
    if (config.radius < c.radius) {
      config.radius = c.radius + 15;
    }
  },

  _updateConfigForEllipseContainer: function (config, e) {
    e.radius = e.width / 2;
    e.radiusY = e.height / 2;

    config.x = e.x + e.width / 2;
    config.y = e.y + e.height / 2;
    if (config.radius < e.radius) {
      config.radius = e.radius + 15;
    }

    if (config.radiusY < e.radius) {
      config.radius = e.radius + 15;
    }
  }
};

module.exports = Renderer;
plexExport("graphics.Renderer", Renderer);
