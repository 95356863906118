ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const Blocker = require("../Plugins/plex-blocker");
const plexExport = require("../../global-export");

const TemplateName = "elements-lightbox";

const LightBoxController = function (config) {
  this.config = config;
  this.init();
};

LightBoxController.prototype = {
  init: function () {
    const self = this;

    self.contentUrl = ko.observable(null);
    self.contentDescription = ko.observable(null);
    self.isImage = ko.observable(true);
  },

  showImage: function (imageUrl, description) {
    this._show(imageUrl, description, true);
  },

  showObject: function (url, description) {
    this._show(url, description, false);
  },

  _show: function (url, description, isImage) {
    const self = this;
    const blockerOptions = {};
    self.contentUrl(url);
    self.contentDescription(description);
    self.isImage(isImage);

    blockerOptions.messageType = Blocker.BlockerMessageType.KnockoutTemplate;
    blockerOptions.message = TemplateName;
    blockerOptions.messageModel = self;
    blockerOptions.messageClass = "plex-lightbox-container";
    blockerOptions.overlayCss = {
      cursor: "auto",
      opacity: 0.75,
      backgroundColor: "#000"
    };

    self.blocker = new Blocker(document.body, blockerOptions);

    self.blocker.show();

    // Add click event to track when they click out of lightbox.
    if (self.blocker.$message) {
      self.blocker.$message.css({
        transform: "translate(-50%, -50%)"
      });

      const $lightbox = self.blocker.$message;

      $(document).one("click.lightbox", (e) => {
        if ($lightbox[0] !== e.target && $lightbox.has($(e.target)).length === 0) {
          self.hide();
        }
      });
    }
  },

  hide: function () {
    const self = this;

    if (self.blocker) {
      self.blocker.hide();
    }
  }
};

module.exports = LightBoxController;
plexExport("images.LightBoxController", LightBoxController);
