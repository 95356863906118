ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const valueProviderFactory = require("../ValueProviders/plex-grid-value-providers-factory");
const aggregateFactory = require("../Aggregates/plex-grid-aggregate-factory");
const plexExport = require("../../../global-export");

const ElementRowCellProcessor = function (grid, parent) {
  this.grid = grid;
  this.parent = parent;
};

ElementRowCellProcessor.prototype = {
  constructor: ElementRowCellProcessor,
  process: function (cells) {
    let spacer = null;
    let index = 0;
    const ln = this.grid.columns.length;

    const processedCells = [];

    for (; index < ln; index++) {
      const column = this.grid.columns[index];
      const cell = $.extend(
        {},
        cells.filter((c) => {
          return c.anchorColumnId === column.id;
        })[0]
      );

      if (cell) {
        if (spacer) {
          // if spacer exists update where it should end and then clear out
          if (spacer.colIndex !== index - 1) {
            spacer.endColIndex = index - 1;
          }

          spacer = null;
        }

        let element;
        cell.colIndex = index;
        if (cell.aggregate) {
          cell.operator = aggregateFactory.create(column, cell.aggregate);
        } else if (cell.element || (cell.elements && cell.elements.length > 0)) {
          element = cell.element || cell.elements[0];
          cell.valueProvider = valueProviderFactory.create(cell, this.grid.options);
          if (element.features && element.features.length > 0) {
            cell.featureProcessor = new FeatureProcessor(element.features, element, this.parent);
          }
        }

        if (cell.endAnchorColumnId) {
          const endColIndex = findColumnIndexById(this.grid.columns, cell.endAnchorColumnId);
          if (endColIndex !== index && endColIndex > index) {
            cell.endColIndex = endColIndex;

            // skip on to next column
            index = endColIndex;
          }
        }

        processedCells.push(cell);
      } else if (!spacer) {
        // create spacer to sit between cells.
        spacer = { colIndex: index };
        processedCells.push(spacer);
      }
    }

    // update last spacer with the end column - we probably don't *need* to do this but might as well
    if (spacer && !spacer.endColIndex && spacer.colIndex !== ln - 1) {
      spacer.endColIndex = ln - 1;
    }

    return processedCells;
  }
};

function findColumnIndexById(columns, id) {
  let i = columns.length;
  while (i--) {
    if (columns[i].id === id) {
      return i;
    }
  }

  return -1;
}

module.exports = ElementRowCellProcessor;
plexExport("grid.writers.ElementRowCellProcessor", ElementRowCellProcessor);
