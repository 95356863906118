ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");
const plexExport = require("../../global-export");

function normalize(name) {
  return name.toLowerCase().replace(/_/g, "");
}

function resolveProperty(name, obj, map) {
  if (name in obj) {
    return name;
  }

  if (name in map) {
    return obj[map[name]];
  }

  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (normalize(prop) === normalize(name)) {
        // save in map to save on future lookups
        map[name] = prop;
        return map[name];
      }
    }
  }

  return null;
}

const ObjectWrapper = function (obj, options) {
  const self = this;
  const propertyMap = {};

  // allow to be called without `new`
  if (!(this instanceof ObjectWrapper)) {
    return new ObjectWrapper(obj, options);
  }

  options = options || {};

  function getter(name) {
    const propertyName = resolveProperty(name, obj, propertyMap);
    if (!propertyName && !options.autoAdd) {
      throw new Error("Unable to find property: " + name);
    }

    return ko.unwrap(obj[propertyName]);
  }

  function setter(name, value) {
    if (options.readOnly) {
      throw new Error("Invalid operation: This object is read only.");
    }

    let propertyName = resolveProperty(name, obj, propertyMap);
    if (!propertyName) {
      if (!options.autoAdd) {
        throw new Error("Unable to find property: " + name);
      }

      propertyName = name;
      defineProperty(name);
    }

    if (ko.isObservable(obj[propertyName])) {
      obj[propertyName](value);
    } else {
      obj[propertyName] = value;
    }
  }

  function defineProperty(key) {
    if (options.autoObserve && !dataUtils.getObservable(obj, key)) {
      obj[key] = ko.observable(obj[key]);
    }

    Object.defineProperty(self, key, {
      configurable: false,
      enumerable: true,
      get: function () {
        return getter(key);
      },
      set: options.readOnly
        ? undefined
        : function (value) {
            setter(key, value);
          }
    });
  }

  // setup getter/setters
  Object.keys(obj).forEach(defineProperty);

  // these should not be overwritable
  Object.defineProperty(this, "get", {
    configurable: false,
    enumerable: false,
    writable: false,
    value: getter
  });

  if (!options.readOnly) {
    Object.defineProperty(this, "set", {
      configurable: false,
      enumerable: false,
      writable: false,
      value: setter
    });
  }

  return self;
};

module.exports = ObjectWrapper;
plexExport("ObjectWrapper", ObjectWrapper);
