ï»¿const GridPrintWriter = require("./plex-grid-print-writer");
const gridUtils = require("../../plex-grid-utils");
const writerFactory = require("../plex-grid-writer-factory");
const plexExport = require("../../../../global-export");

const GroupPrintWriter = GridPrintWriter.extend({
  init: function (_grid) {
    const self = this;
    this._base.apply(this, arguments);

    this.groups = this.grid.groups;
    this.groups.forEach((group) => {
      if (group.headers && group.headers.length > 0) {
        group.headers.forEach((header) => {
          header.printWriter = writerFactory.create(header.printWriterProvider, self.grid, group, header);
        });
      }

      if (group.footers && group.footers.length > 0) {
        group.footers.forEach((footer, _index) => {
          footer.printWriter = writerFactory.create(footer.printWriterProvider, self.grid, group, footer);
        });
      }
    });
  },

  getNodeName: function () {
    return "plex-grid-table";
  },

  print: function (data) {
    const self = this;
    if (this.grid.getData().length === 0) {
      // note: for now if there is no data we aren't printing the groups
      return this._base.apply(this, arguments);
    }

    if (this.grid.controller.config.virtualizeRows !== false) {
      this.setTableColumnsWidth();
    }

    self.printHeader(data);

    const body = this.node.createNode("grid-table-body");

    self.writeFeatures(self.node, self.features);

    const config = {
      colspan: self.grid.controller.getPrintTitleColSpan()
    };

    self.rowIndex = 0;
    const groupCount = data.groups.length;
    data.groups.forEach((group, groupIndex) => {
      self.printGroup(body, group, groupIndex, groupCount, config, 0);
    });

    self.printFooter(data, config);
    return this.toPrintXml();
  },

  printGroup: function (node, group, groupIndex, groupCount, config, depth) {
    const self = this;
    const current = group.config;
    let currentRecords, firstRecord; // only load if needed

    if (current && current.headers && current.headers.length > 0) {
      currentRecords = gridUtils.aggregateData(group);
      firstRecord = currentRecords[0];

      current.headers.forEach((header) => {
        if (header.printWriter.prePrint(firstRecord, 0, currentRecords) !== false) {
          node.appendXml(header.printWriter.print(group, config));
        }
      });
    }

    if (group.groups.length > 0) {
      group.groups.forEach((subGroup, subGroupIndex) => {
        self.printGroup(node, subGroup, subGroupIndex, group.groups.length, config, depth + 1);
      });
    } else {
      group.data.forEach((record, indexInGroup) => {
        self.printRow(node, record, self.rowIndex++, group.data, indexInGroup, group);
      });
    }

    if (current && current.footers && current.footers.length > 0) {
      currentRecords = currentRecords || gridUtils.aggregateData(group);
      firstRecord = firstRecord || currentRecords[0];

      current.footers.forEach((footer) => {
        if (groupCount > 1 || !footer.suppressForSingleGroup) {
          if (footer.printWriter.prePrint(firstRecord, 0, currentRecords) !== false) {
            node.createNode("grid-group-footer").appendXml(footer.printWriter.print(group, config, groupIndex));
          }
        }
      });
    }
  }
});

module.exports = GroupPrintWriter;
plexExport("grid.writers.GroupPrintWriter", GroupPrintWriter);
