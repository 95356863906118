ï»¿const ko = require("knockout");
const $ = require("jquery");
const knockoutUtils = require("./plex-utils-knockout");

const registry = {};

// this binding is intended to be used within a grid where only one record is selectable
// when one item is selected, the boolean property on all other records will be updated
// to reflect that they are not selected
ko.bindingHandlers.radioButton = {
  // this needs to be bound after attr so that the `name` attribute is set already
  after: ["attr"],

  init: function (element, valueAccessor, allBindings) {
    // register all bindings by name, which should be unique
    const entries = (registry[element.name] = registry[element.name] || []);
    const entry = {
      el: element,
      accessor: valueAccessor,
      bindings: allBindings
    };

    entries.push(entry);

    // on click toggle all values
    const $el = $(element).on("click.plex.radioButton", () => {
      updateAll(entries, entry);
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      // remove current entry from registry
      const index = entries.indexOf(entry);
      if (index >= 0) {
        entries.splice(index, 1);
      }

      // remove registry if it is now empty
      if (entries.length === 0) {
        delete registry[element.name];
      }

      $el.off("click.plex.radioButton");
    });
  },

  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    const entries = registry[element.name];
    let entry;

    if (entries) {
      // look for entry with the matching element
      entry = ko.utils.arrayFirst(entries, (e) => {
        return e.el === element;
      });
      if (entry) {
        element.checked = value;
        if (value) {
          // we should only need to update all values if this one is selected
          updateAll(entries, entry);
        }
      }
    }
  }
};

ko.expressionRewriting._twoWayBindings.radioButton = true;

function updateAll(entries, currentEntry) {
  entries.forEach((entry) => {
    const value = entry === currentEntry;
    const target = entry.accessor();
    const currentValue = ko.utils.peekObservable(target);

    // do coercive check so we are not updating unnecessarily
    if (value !== currentValue) {
      entry.el.checked = value;
      if (ko.isObservable(target)) {
        target(value);
      } else {
        knockoutUtils.updateTwoWayBinding(entry.bindings, "radioButton", value);
      }
    }
  });
}
