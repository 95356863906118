ï»¿const $ = require("jquery");
const ULID = require("ulid");
const expressionCompiler = require("../../../Expressions/plex-expressions-compiler");
const LegacyPlugin = require("../../../Native/plex-native-label");
const plexJs = require("../../../Utilities/plex-utils-js");
const dataUtils = require("../../../Utilities/plex-utils-data");
const Notify = require("../../../Dialogs/plex-dialogs-notify");
const PrintManagerController = require("../../../Controls/plex-controller-printmanager");
const http = require("../../../Core/plex-navigate");
const labelComponent = require("../../../Native/plex-label-printer");
const errors = require("../../../Core/plex-handler-error");
const notify = require("../../../Core/plex-notify");
const URL = require("../../../Core/plex-parsing-url");
const logger = require("../../../Core/plex-logger");
const plexExport = require("../../../../global-export");
const plexImport = require("../../../../global-import");

function processHtmlPrintResult() {
  /// <summary>
  /// Processes an HTML print result by simply displaying a Notify dialog
  /// indicating that HTML labels are not supported.
  /// </summary>
  /// <returns type="Promise">A promise which will be resolved once the user clicks "OK" in the dialog.</returns>

  return Notify("HTML labels are not currently supported in Plex UX.");
}

function processPrintDocumentPrintResult(result) {
  /// <summary>
  /// Processes a Print Document (PDF) print result by rendering the appropriate
  /// PrintDocument represented by the result
  /// </summary>
  /// <param name="result">The PrintResult instance. Contains a reference to a PrintDocument.</param>
  /// <returns type="Promise">A promise which will be resolved once the rendered PDF dialog has been closed.</returns>

  const config = {
    printDocumentKey: result.PrintDocument.PrintDocumentKey,
    xmlAddress: result.PrintDocument.XmlAddress
  };

  const data = result.DataForPrintDocumentXmlAction;

  const spa = plexImport("spa");
  if (spa?.isSpaRendering?.()) {
    return spa.createPrintManager(config, data);
  }

  return PrintManagerController.create(config, data);
}

function processRawPrintResult(result) {
  /// <summary>
  /// Processes a raw print result by sending printer code (ZPL, IPL) directly to the printer via a websocket interface.
  /// </summary>
  /// <param name="result">The PrintResult instance. Contains the raw printer code to send to the printer (ZPL, IPL).</param>
  /// <returns type="Promise">A promise which will be resolved once the label has been printed.</returns>

  return printRawResult(result.PrinterCode, result.PrintBehavior, result.Meta);
}

/**
 * Sends the provided code to the a printer. This will use either Plex Component Host or
 * the Websocket plugin, depending on what is installed on the users computer.
 *
 * @param {String} code - the code to send to the printer
 * @param {Object} behavior - The options for printing
 * @param {Boolean} behavior.showPrintDialog - Prompts the user for a printer to use
 * @param {String} behavior.providePrinterName - Specify a printer to send job to
 * @param {String} behavior.labelNo - Will prompt the user and remember the selection base on identifier
 * @param {Object} meta - Data to send along with print job
 * @returns {PromiseLike} returns a deferred object which will resolve when completed
 */
function printRawResult(code, behavior, meta) {
  behavior = behavior || {};
  const options = {
    showPrintDialog: behavior.PromptUserForPrinterSelection || behavior.showPrintDialog || false,
    providePrinterName: behavior.PrinterName || behavior.providePrinterName || "",
    labelNo: behavior.UniqueIdentifier || behavior.labelNo || ""
  };

  return labelComponent
    .isEnabled()
    .then((enabled) => {
      if (enabled) {
        return labelComponent.handlePrintRequest(
          code,
          {
            PromptUserForPrinterSelection: options.showPrintDialog,
            PrinterName: options.providePrinterName,
            UniqueIdentifier: options.labelNo
          },
          meta
        );
      }

      const plugin = new LegacyPlugin();
      return plugin.printLabel(code, options);
    })
    .catch((err, msg) => {
      if (msg && typeof err === "number") {
        // this is the kind of error that will be returned from legacy plugin
        notify.error("Unable to connect to label component");
      } else if (err) {
        if (typeof err === "object" && !err.message) {
          // most likely a WAMP error
          // eslint-disable-next-line no-console
          logger.error(err);
          err.message = "An error occurred printing labels";
        }
        errors.raise(err, true);
      }
    });
}

const LabelPrintController = function () {
  // constructor
};

LabelPrintController.prototype = {
  processResult: function (result) {
    switch (result.ResultType) {
      case "Html":
        return processHtmlPrintResult(result);
      case "PrintDocument":
        return processPrintDocumentPrintResult(result);
      case "Raw":
      default:
        return processRawPrintResult(result);
    }
  },

  processResults: function (results) {
    if (Array.isArray(results)) {
      // get the promise for processing the first result, then process
      // subsequent results by chaining them to the initial promise.
      let promise = this.processResult(results[0]);
      for (let i = 1; i < results.length; i++) {
        promise = promise.then(this.processResult.bind(this, results[i]));
      }

      return promise;
    } else {
      return this.processResult(results);
    }
  },

  parseAndExecuteExpression: function (expression, parameter, defaultValue) {
    if (expression) {
      const compiledExpression = expressionCompiler.compile(expression);
      return compiledExpression(parameter);
    }

    if (typeof defaultValue === "undefined") {
      defaultValue = null;
    }

    return defaultValue;
  },

  getRequestModel: function (dataModel, labelConfigModel) {
    let requestModel;

    if (Array.isArray(dataModel)) {
      if (Array.isArray(labelConfigModel) && dataModel.length === labelConfigModel.length) {
        requestModel = dataModel.map((data, index) => this.getRequestData(data, labelConfigModel[index]));
      } else {
        requestModel = dataModel.map((data) => this.getRequestData(data, labelConfigModel));
      }
    } else {
      if (Array.isArray(labelConfigModel) && labelConfigModel.length === 1) {
        labelConfigModel = labelConfigModel[0];
      }

      requestModel = [this.getRequestData(dataModel, labelConfigModel)];
    }

    return requestModel;
  },

  getRequestData: function (dataModel, labelConfigModel) {
    const self = this;
    const parameters = {};

    if (labelConfigModel && labelConfigModel.parameters) {
      $.each(labelConfigModel.parameters, (propertyName, item) => {
        if (typeof item === "object") {
          parameters[propertyName] = self.parseAndExecuteExpression(item, dataModel);
        } else {
          parameters[propertyName] = item;
        }
      });
    }

    // Derive workcenter from querystring - but prefer passed in value, even if empty.
    if (!("Workcenter_Key" in parameters)) {
      const qs = URL.parseQueryString();
      // eslint-disable-next-line camelcase
      parameters.Workcenter_Key = qs.WorkcenterKey;
    }

    return {
      LabelParameters: parameters
    };
  },

  getDefaultValue: function (data, propertyName) {
    if (!propertyName) {
      return null;
    }

    return dataUtils.getValue(data, propertyName);
  },

  getPrintResults: function (requests, options) {
    const queryStringData = {
      correlationId: ULID.ulid(),
      xpcn: options && options.TargetPcn
    };
    const url = http.buildUrl(this.controllerAction, queryStringData);
    return http.post(url, requests, { standardArrays: true });
  },

  print: function (dataModel, labelConfigModel) {
    const requests = this.getRequestModel(dataModel, labelConfigModel);
    let options = labelConfigModel;
    if (Array.isArray(labelConfigModel)) {
      options = labelConfigModel[0];
    }

    return this.getPrintResults(requests, options).then(this.processResults.bind(this));
  }
};

plexJs.makeExtendable(LabelPrintController);

module.exports = LabelPrintController;
plexExport("barcoding.labelPrinting.LabelPrintController", LabelPrintController);
plexExport("barcoding.labelPrinting.sendToPrinter", printRawResult);
