ï»¿const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const ColumnVisibleFeature = Feature.extend({
  onInit: function () {
    // this is a bit of a different case because we want the visibility to be set before
    // the rendering occurs. so we listen to changes on the condition and set the visibility
    // based on this value.

    const self = this;

    // we want to delay evaluation until the grid is rendering
    if (this.parent && this.parent.isRendering) {
      this.parent.isRendering.subscribe((rendering) => {
        if (rendering) {
          // this feature should always be bound to a different view model so we do not need
          // to pass any record data to it.
          self.column.visible(self.conditionEvaluator());
        }
      });
    }

    // set initial value
    this.column.visible(this.conditionEvaluator());
  }
});

module.exports = ColumnVisibleFeature;
plexExport("grid.features.ColumnVisible", ColumnVisibleFeature);
