ï»¿/* eslint-disable no-invalid-this */
const jsUtils = require("../../Utilities/plex-utils-js");
const plexExport = require("../../../global-export");

const DataTransform = function (config) {
  this.config = config;
};

DataTransform.prototype = {
  constructor: DataTransform,

  init: function () {
    this.onInit();
  },

  onInit: function () {
    // can be overriden
  },

  transform: function (data) {
    data = this.onTransform(data);

    return data;
  },

  onTransform: function () {
    // can be overriden
  }
};

jsUtils.makeExtendable(DataTransform);

module.exports = DataTransform;
plexExport("data.transforms.DataTransform", DataTransform);
