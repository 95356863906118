ï»¿const $ = require("jquery");
const ko = require("knockout");
const DocumentGalleryItem = require("./plex-document-gallery-item-base");
const downloadFile = require("../../Plugins/plex-filedownload");
const banner = require("../../Plugins/plex-banner");
const plexExport = require("../../../global-export");

const DocumentGalleryItemImage = DocumentGalleryItem.extend({
  onInit: function () {
    this.displayExpand(true);
  },

  onDownload: function () {
    const self = this;
    let $deferred = new $.Deferred();

    if (self.config.nodeNo) {
      // Download from DCS
      const url = "/Platform/DocumentControlSystem/DownloadDocument";
      const options = { data: { nodeNo: self.config.nodeNo } };

      $deferred = downloadFile(url, options);

      $deferred.fail((result) => {
        banner.findClosest().setMessage(result.message, { autoGlossarize: false });
      });
    } else {
      // TODO: Implement non-DCS downloading.
    }

    return $deferred.promise();
  },

  getXml: function () {
    const imageNode = $("<plex-control-image />");
    if (this.config.nodeNo) {
      imageNode.attr("nodeno", this.config.nodeNo);
    } else {
      imageNode.attr("imagepath", ko.unwrap(this.imageUrl));
    }

    imageNode.attr({
      height: ko.unwrap(this.height()),
      width: ko.unwrap(this.width())
    });
    return imageNode;
  }
});

module.exports = DocumentGalleryItemImage;
plexExport("documentGallery.DocumentGalleryItemImage", DocumentGalleryItemImage);
