ï»¿const $ = require("jquery");
const valueProviderFactory = require("../Labels/plex-labels-value-providers-factory");
const dataUtils = require("./plex-utils-data");
const jsUtils = require("./plex-utils-js");
const plexExport = require("../../global-export");

const api = {};

api.applyTokens = function (data, tokens, outputParams) {
  if (Array.isArray(data)) {
    if (data.length > 0) {
      // todo: i can't decide whether this should return an array of promises
      // or a single promise that is resolved when all are resolved so for now
      // this is purposely not handled until a use case arises - right now it'll
      // just return an array of promises if th
      return data.map((record) => mapTokens(record, tokens, outputParams));
    }

    // resolve to a new object - there may be output parameters or other types
    // of tokens which can be resolved
    data = null;
  }

  return mapTokens(data, tokens, outputParams);
};

api.resolveToken = function (data, token, outputParams) {
  const source = token.directOutput ? outputParams : data;

  if (token.valueProvider && !token.evaluator) {
    token.evaluator = createTokenEvaluator(token);
  }

  if (typeof token.evaluator === "function") {
    return token.evaluator(data);
  }

  return dataUtils.getValue(source, token.propertyName, true);
};

function mapTokens(data, tokens, outputParams) {
  const obj = {};
  let deferred = [];

  tokens.forEach((token) => {
    const prop = token.alias || token.propertyName;
    const value = api.resolveToken(data, token, outputParams);

    if (jsUtils.isPromise(value)) {
      deferred.push(value.then((result) => (obj[prop] = result)));
    } else {
      obj[prop] = value;
    }
  });

  if (deferred.length > 0) {
    return $.when(...deferred).then(() => {
      deferred = null;
      return obj;
    });
  }

  return obj;
}

function createTokenEvaluator(token) {
  const provider = valueProviderFactory.create(token);
  return provider.getValue.bind(provider);
}

module.exports = api;
plexExport("utils.dataToken", api);
