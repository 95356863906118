ï»¿const $ = require("jquery");
const LabelPrintController = require("./plex-labelprinting-controller-base");
const plexExport = require("../../../../global-export");

const MasterLabelPrintController = LabelPrintController.extend({
  controllerAction: "/Labels/Print/MasterUnit",
  getRequestData: function (data, options) {
    let requestData;

    if (typeof data === "object") {
      // _base is a reference to getRequestData on LabelPrintController
      requestData = this._base(data, options);
      requestData = $.extend(requestData, {
        MasterUnitKey: this.parseAndExecuteExpression(
          options.masterUnitKey,
          data,
          this.getDefaultValue(data, options.masterUnitKeyPropertyName)
        ),
        CustomerAddressNo: this.parseAndExecuteExpression(
          options.customerAddressNo,
          data,
          this.getDefaultValue(data, options.customerAddressNoPropertyName)
        ),
        ShipperKey: this.parseAndExecuteExpression(
          options.shipperKey,
          data,
          this.getDefaultValue(data, options.shipperKeyPropertyName)
        ),
        POLineKey: this.parseAndExecuteExpression(
          options.poLineKey,
          data,
          this.getDefaultValue(data, options.poLineKeyPropertyName)
        )
      });
    } else {
      requestData = this._base({}, options);
      requestData.MasterUnitKey = data;
    }

    return requestData;
  }
});

module.exports = MasterLabelPrintController;
plexExport("barcoding.labelPrinting.MasterLabelPrintController", MasterLabelPrintController);
