ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const domUtils = require("../Utilities/plex-utils-dom");
const bindingHandler = require("./plex-handler-bindings");
const DialogController = require("../Dialogs/plex-dialog-controller");
const jsUtils = require("../Utilities/plex-utils-js");
const logger = require("../Core/plex-logger");
const nav = require("../Core/plex-navigate");
const notify = require("../Core/plex-notify");
const banner = require("../Plugins/plex-banner");
const EmailAction = require("../Actions/plex-actions-email");
const FaxAction = require("../Actions/plex-actions-fax");
const SaveToDCSAction = require("../Actions/plex-actions-save-to-dcs");
const dataUtils = require("../Utilities/plex-utils-data");
const jsonUtils = require("../Utilities/plex-utils-json");
const DocumentProviderFactory = require("../Printing/DocumentProviders/plex-printing-document-providers-factory");
const browser = require("../Core/plex-browser");
const plexExport = require("../../global-export");
const glossary = require("../Globalization/plex-glossary-handler");
const pubsub = require("../Core/plex-pubsub");
const environment = require("../Core/plex-env");

const faxDefaults = {};
const emailDefaults = {};
const FEATURE_FLAG = "feat-tri-3250-dynamic-print-sizing";
const DEFAULT_PAGESIZE = "Letter";
const DEFAULT_MARGIN = "Default";
const DEFAULT_ERROR_MESSAGE = "An error has occurred and the document cannot be displayed.";
const NOT_SUPPORTED_MESSAGE =
  "This document cannot be displayed in your current browser. You can view the document by downloading it from above.";
const ENGLISH_LANGUAGE_KEY = 1;
const ACTIONBAR_ACTION_DEFAULTS = {
  visible: true,
  disabled: false
};

const PrintManagerController = function (config, data) {
  this.config = config;
  this.data = data;

  this.init();
};

const configurePostAction = function (form, implicitlyClosing) {
  if (form.config.postAction) {
    form.config.postAction.additionalData = $.extend(form.config.postAction.additionalData, {
      implicitlyClosing
    });
  }
};

const getErrorMessage = function (ex) {
  const message = ex?.responseJSON?.ErrorObject?.PublicMessage || DEFAULT_ERROR_MESSAGE;
  return typeof ex === "string" ? ex : message;
};

const updateClientItem = function (clientItem) {
  if (clientItem.document || clientItem.propertyName) {
    clientItem.documentProvider = "RetainedDocumentProvider";
    clientItem.retainedDocumentPropertyName = clientItem.propertyName;
  } else {
    clientItem.documentProvider = "PrintDocumentProvider";
    clientItem.document = {
      printDocumentKey: clientItem.printDocumentKey || 1,
      xmlAddress: clientItem.xmlAddress
    };
  }
};

const actionbarSetup = function (form, changeSettingsDisplay) {
  form._addPrintAction("Print", form.print.bind(form), { disabled: !form.printingSupported });
  form._addPrintAction("Download", form.download.bind(form));
  form._addPrintAction("Settings", form._changeSettings.bind(form), !!changeSettingsDisplay && !form.document());
  form._addPrintAction("E-Mail", form.email.bind(form), form.config.emailDisplay);
  form._addPrintAction("Fax", form.fax.bind(form), form.config.faxDisplay);
  form._addPrintAction("Save to DCS", form.saveToDCS.bind(form));
};

const getDocumentUrl = function (form) {
  const doc = form.document();

  if (doc) {
    if (doc.WebFile) {
      const request = {
        "webFile.url": doc.WebFile.Url,
        "webFile.fileExtension": doc.WebFile.FileExtension,
        __dialog: "1"
      };

      return nav.buildUrl("/Platform/PrintManager/ViewDocument", request);
    }

    return nav.buildUrl("/Platform/PrintManager/ViewDocument", { ...doc, __dialog: "1" });
  }

  return "";
};

const getSettingsDisplay = function (form) {
  let changeSettingsDisplay = form.config.changeOrientationDisplay || form.config.languageDisplay;
  if (form.items.length > 1 || form.items[0].documentProvider !== "PrintDocumentProvider") {
    changeSettingsDisplay = false;
  }
  return changeSettingsDisplay;
};

PrintManagerController.prototype = {
  constructor: PrintManagerController,

  get printingSupported() {
    return browser.canPrint;
  },

  init: function () {
    const self = this;

    configurePostAction(this, false);
    this.env = environment;
    this.items = this.config.items || [];
    if (this.items.length === 0) {
      // this should be enabled after Cloud.Framework.Pages 2.X.X release
      // if (self.config.items)
      // {
      //  throw new Error("No items exist to be printed.");
      // }

      // Need the following to support Cloud.Framework.Pages.1.0.0
      const clientItem = $.extend({}, this.config);
      clientItem.parent = this;

      // remove tokens since the data would already be tokenized at this point
      clientItem.tokens = [];

      updateClientItem(clientItem);

      this.items.push(clientItem);
    }

    // disable change orientation when merging or displaying retained document
    const changeSettingsDisplay = getSettingsDisplay(this);

    this.$element = $("<div>").appendTo(document.body);

    this.documentProviders = this.items.map((item) => DocumentProviderFactory.create(item, this));

    this.afterAcceptPromises = [];
    this._disposables = [];
    this._disposables.push(...this.documentProviders);

    // property bindings
    this.emailProperties = $.extend({}, emailDefaults, this.config.emailProperties);
    this.faxProperties = $.extend({}, faxDefaults, this.config.faxProperties);
    this.applyPropertyBindings(this.emailProperties);
    this.applyPropertyBindings(this.faxProperties);

    // observables
    this.document = ko.observable();
    this.createdDocuments = ko.observableArray();
    this.documentUrl = ko.computed(() => {
      return getDocumentUrl(this);
    });

    this.isPreviewing = ko.pureComputed(() =>
      this.createdDocuments().some((doc) => doc.document && doc.document.Preview)
    );

    // work around for displaying content above print document dialog in IE.
    // Ideally I would like this observable to be computed based on changes to the dom.
    this.isBusy = ko.observable(false);
    this.returning = false;

    // actionbar setup
    actionbarSetup(this, changeSettingsDisplay);

    if (typeof this.config.applyCustomActions === "function") {
      this.config.applyCustomActions.apply(this);
    }

    this.progressTitle = ko.computed(() => {
      if (this.isBusy() !== true) {
        return "";
      }

      const documentCount = this.documentProviders.length;
      if (documentCount > 1) {
        const createdDocuments = this.createdDocuments().length;
        if (createdDocuments < documentCount) {
          return { text: "Creating Document {1} of {2}", tokens: [createdDocuments + 1, documentCount] };
        }

        return "Merging Documents";
      }

      return "Creating Document";
    });

    glossary.getCustomerWordAsync(NOT_SUPPORTED_MESSAGE).done((glossarizedMessage) => {
      self.config.notSupportedMessage = glossarizedMessage;
    });

    // using html binding instead of template because IE cannot process knockout
    // changing the value of object's data attribute
    this.viewerHtml = ko.computed(() => {
      const url = this.documentUrl();

      if (url) {
        const objectTag = document.createElement("object");
        objectTag.data = url;
        objectTag.type = "application/pdf";
        objectTag.innerText = this.config.notSupportedMessage;
        return objectTag.outerHTML;
      }

      return "";
    });

    const displayText = ko.observable();
    this.displayText = ko.computed({
      read: displayText,
      write: (value) => {
        displayText(value);
      }
    });

    ko.renderTemplate("dialog-printmanager", this, {}, this.$element[0]);

    // Banner for dialog
    this.$element.find(".plex-banner").banner();
    this.banner = banner.findClosest(this.$element);

    // defer for animation timing
    this._disposables.push(
      this.banner.message.subscribe(() => {
        jsUtils.defer(self.onHeightResize, self, null, 300);
      })
    );

    this.$context = $("body");
    if (this.config.parent) {
      const $parent = this.config.parent.$element;
      if (domUtils.isInDialog($parent)) {
        this.$context = $parent.closest(".plex-dialog");
      }
    }
  },

  email: function () {
    const doc = this.document();
    let data = {
      Documents: [doc]
    };

    const action = new EmailAction();

    if (!doc.WebFile || !doc.WebFile.Url) {
      data = Object.assign(data, {
        Filepath: doc.Filepath
      });

      action.tokens = [
        {
          propertyName: "Filepath"
        }
      ];
    }

    configurePostAction(this, true);

    // close the dialog first! Two-modals == no-bueno
    this.dialog.close();
    action.init(this.emailProperties);
    action.execute(data, data).then(() => pubsub.publish("printManager.email." + this.config.id, this.data));
  },

  fax: function () {
    const action = new FaxAction();
    const doc = this.document();
    let data = {
      Document: doc,
      FaxToBuildings: this.config.faxToBuildings || false
    };

    if (!doc.WebFile || !doc.WebFile.Url) {
      data = Object.assign(data, {
        Filepath: doc.Filepath
      });

      action.tokens = [
        {
          propertyName: "Filepath"
        }
      ];
    }

    $.extend(data, this.config.faxProperties);

    configurePostAction(this, true);

    this.dialog.close();
    action.init(this.config);
    action.execute(data, data).then(() => pubsub.publish("printManager.fax." + this.config.id, this.data));
  },

  saveToDCS: function () {
    const action = new SaveToDCSAction();
    const doc = this.document();
    let data = {
      DocStorage: "Plexus Server"
    };

    if (doc.WebFile && doc.WebFile.Url) {
      data = Object.assign(data, {
        WebFile: doc.WebFile,
        Filename: doc.Filename
      });
    } else {
      data = Object.assign(data, {
        Filename: doc.Filepath
      });
    }

    // workaround for overlapping IE pdf plugin - hide and show it when needed
    this.isBusy(true);

    action.cancelAction = () => {
      this.isBusy(false);
    };

    const dialog = this.dialog;
    action.init();
    action.execute(data, null, () => {
      dialog.close();
    });
  },

  print: function () {
    if (this.printingSupported !== true) {
      logger.error("Your browser does not support printing this document.");
      return;
    }

    const $object = this.$element.find("object");
    if (!$object.length) {
      logger.error("Document is not fully loaded for printing.");
      return;
    }

    try {
      $object[0].print();
    } catch (e) {
      const printWin = window.open(this.documentUrl());

      printWin.print();
    }
  },

  download: function () {
    const request = Object.assign({}, this.document());

    if (request.WebFile) {
      request["webFile.url"] = request.WebFile.Url;
      request["webFile.fileExtension"] = request.WebFile.FileExtension;

      request.WebFile = null;
    }

    nav.navigate("/Platform/PrintManager/DownloadDocument", request, { showOverlay: false });
  },

  load: function () {
    const self = this;
    const resolvers = [];

    this.isBusy(true);

    this.documentProviders.forEach((provider, index) => {
      const promise = provider.getDocument(this.data);
      resolvers.push(promise);

      promise.done((doc) => {
        if (doc) {
          self.createdDocuments.push({ document: doc, index });

          // if previewing - delay performing of documentCreated action, else - it should be already called by document provider
          if (doc.Preview === true) {
            self._delayCallingDocumentCreatedAction(provider, doc);
          }
        }
      });
    });

    $.when(...resolvers)
      .done(() => {
        if (self.createdDocuments().length === 0) {
          self._onError("There are no documents to be displayed.");
          return;
        }

        const onDocumentCreated = (doc) => {
          self.document(doc);
          self.isBusy(false);

          if (self.isPreviewing()) {
            self.banner.setMessage({
              text: "This document is being previewed. Please click OK to accept.",
              autoGlossarize: true
            });
          }
        };

        if (self.createdDocuments().length > 1) {
          self._mergeCreatedDocuments().done(onDocumentCreated).fail($.proxy(self._onError, self));
        } else {
          onDocumentCreated(self.createdDocuments()[0].document);
        }
      })
      .fail($.proxy(this._onError, this));
  },

  render: function () {
    const self = this;
    const $deferred = new $.Deferred();
    const $win = $(window);

    this.dialog = new DialogController({
      dialogElement: this.$element.find(".plex-dialog"),
      width: $win.width() * 0.8,
      height: $win.height() * 0.9,
      returnAction: () => {
        if (self.returning === true) {
          return;
        }

        self.returning = true;

        const promises = [];
        self.afterAcceptPromises.forEach((func) => {
          promises.push(func());
        });

        $.when(...promises).done(() => {
          $deferred.resolve(self.document());
          self.dispose();
        });
      },
      closeAction: () => {
        self.dispose();
      }
    });

    // setup the initial height once modal is shown.
    this.onHeightResize();

    // wait until dialog is being displayed before loading document
    this.dialog.dialogElement.on("shown.bs.modal", this.load.bind(this));

    this.dialog.render();

    return $deferred.promise();
  },

  reset: function () {
    this.document(null);
    this.createdDocuments([]);
  },

  cancel: function () {
    this.dialog.cancel();
  },

  onHeightResize: function () {
    // offset document viewer for any banner message
    const $content = $(".plex-printmanager-content");
    if ($content.length) {
      const $docViewer = $(".plex-printmanager-document-viewer");
      if ($docViewer.length) {
        $docViewer.toggleClass("content-display", $content.outerHeight() > 0);
        $docViewer.css("top", $content.outerHeight() + "px");
      }
    }
  },

  applyPropertyBindings: function (property) {
    if (property.bindings && property.bindings.length > 0) {
      property.bindings.forEach((binding) => {
        binding.target = property;
        bindingHandler.update(binding, this.data);
      });
    }
  },

  dispose: function () {
    let sub;
    while ((sub = this._disposables.pop())) {
      sub.dispose();
    }

    this.$element.remove();
  },

  _addPrintAction: function (text, action, visible, replace, index) {
    this.printActions = this.printActions || [];

    if (typeof visible !== "object") {
      visible = { visible };
    }

    visible = visible || {};
    visible = $.extend({}, ACTIONBAR_ACTION_DEFAULTS, visible);

    visible.text = text;
    visible.action = action;

    if (index) {
      // insert item into position
      this.printActions.splice(index, 0, visible);
    } else if (replace) {
      // replace existing item
      const existingElementIndex = this.printActions.map((e) => e.text).indexOf(text);
      if (existingElementIndex > 0) {
        this.printActions[existingElementIndex] = visible;
      }
    } else {
      // add new item
      this.printActions.push(visible);
    }
  },

  _mergeCreatedDocuments: function () {
    const $deferred = new $.Deferred();

    // ensure the documents are in the correct order
    const documents = this.createdDocuments()
      .sort((a, b) => a.index - b.index)
      .map((createdDocument) => createdDocument.document);

    nav
      .post(nav.buildUrl("/Platform/PrintManager/MergeDocuments"), documents, { showOverlay: false })
      .done((mergedDocument) => {
        $deferred.resolve(mergedDocument);
      })
      .fail($deferred.reject);

    return $deferred.promise();
  },

  _createPrintedRetentionDocument: function (document) {
    const $deferred = new $.Deferred();

    nav
      .post(nav.buildUrl("/Platform/PrintManager/CreatePrintedRetentionDocument"), document)
      .done((retainedData) => {
        if (retainedData.ValidationResult.Success) {
          $deferred.resolve(retainedData.Data);
        } else {
          notify.error(retainedData.ValidationResult.Message);
          $deferred.reject();
        }
      })
      .fail($deferred.reject);

    return $deferred.promise();
  },

  _delayCallingDocumentCreatedAction: function (provider, createdDocument) {
    const self = this;

    if (dataUtils.isEmpty(provider.config.createDocumentRequest) !== false) {
      logger.error(
        "Cannot get 'createDocumentRequest' from provider's config! Make sure that plex-printing-document-providers-print provider is used. 'CreatePrintedRetentionDocument' action wouldn't be called."
      );
      return;
    }

    this.afterAcceptPromises.push(() => {
      const docCreateRequest = provider.config.createDocumentRequest;
      const doc = jsonUtils.toCamelCaseKeys(createdDocument);

      return self._createPrintedRetentionDocument($.extend({}, doc, docCreateRequest)).then((retainedDocument) => {
        const documentCreatedData = dataUtils.cleanse($.extend({}, self.data, retainedDocument));

        provider.executeDocumentCreatedAction(documentCreatedData);
      });
    });
  },

  _onError: function (ex) {
    const message = getErrorMessage(ex);

    this.displayText(message);

    if (ex && ex.message) {
      throw new Error(ex);
    }
  },

  _changeSettings: function () {
    const self = this;
    const provider = this.documentProviders[0];
    if (!provider) {
      return;
    }

    const widthUnit = provider.documentProperty.pageWidth?.split(/[0-9]+/g).pop();
    const heightUnit = provider.documentProperty.pageHeight?.split(/[0-9]+/g).pop();

    DialogController.create({
      width: 600,
      route: nav.buildUrl(
        "/Platform/PrintManager/CustomizeDocumentForm",
        this._generateChangeSettingRequest(provider, widthUnit, heightUnit, this.env)
      ),
      routeData: nav.serialize(provider.createCustomizationRequest()),
      httpMethod: "POST",
      autoShow: true,
      returnAction: (response) => {
        this._setSettingResponseToProvider(response, provider, this.env);
        self.isBusy(true);
        self.reset();
        provider
          .getDocument(self.data)
          .done((newDoc) => {
            self.document(newDoc);
          })
          .always(() => {
            self.isBusy(false);
          });
      },
      closeAction: () => {
        self.isBusy(false);
      }
    });
  },

  _setSettingResponseToProvider(response, provider, env) {
    if (response?.Landscape?.length > 0) {
      provider.documentProperty.landscape = response.Landscape[0];
    }
    provider.language.languageKey = response?.LanguageKey?.[0] || ENGLISH_LANGUAGE_KEY;
    if (response?.PageWidth && response?.PageHeight) {
      provider.documentProperty.pageWidth = response.PageWidth + response.PageWidthUnit?.[0] || "";
      provider.documentProperty.pageHeight = response.PageHeight + response.PageHeightUnit?.[0] || "";
    }
    provider.documentProperty.saveAsDefault = response?.SaveAsDefault;
    provider.documentProperty.documentDimensionsDisplay = response?.PageSize?.[0] === DEFAULT_PAGESIZE;
    provider.documentProperty.pageSize = response?.PageSize?.[0];

    if (env.features[FEATURE_FLAG]) {
      this._updatePageMargins(provider, response);
      provider.documentProperty.grayscaleOnly = response.GrayscaleOnly;
    }
  },

  _updatePageMargins(provider, response) {
    provider.documentProperty.pageMargin = response?.PageMargin?.[0];
    if (response?.PageMargins.Margin) {
      provider.documentProperty.pageMargins.margin = response.PageMargins.Margin;
    }
    if (response?.PageMargins.Top) {
      provider.documentProperty.pageMargins.top = response.PageMargins.Top;
    }
    if (response?.PageMargins.Bottom) {
      provider.documentProperty.pageMargins.bottom = response.PageMargins.Bottom;
    }
    if (response?.PageMargins.Left) {
      provider.documentProperty.pageMargins.left = response.PageMargins.Left;
    }
    if (response?.PageMargins.Right) {
      provider.documentProperty.pageMargins.right = response.PageMargins.Right;
    }
  },

  _generateChangeSettingRequest(provider, widthUnit, heightUnit, env) {
    const settingsPhase1 = {
      printDocumentKey: provider.document.printDocumentKey,
      changeOrientationDisplay: this.config.changeOrientationDisplay,
      changeLanguageDisplay: this.config.languageDisplay,
      pageWidth: provider.documentProperty.pageWidth?.replace(widthUnit, ""),
      pageHeight: provider.documentProperty.pageHeight?.replace(heightUnit, ""),
      pageWidthUnit: widthUnit,
      pageHeightUnit: heightUnit,
      saveAsDefault: provider.documentProperty.saveAsDefault || false,
      pageSize: provider.documentProperty.pageSize || DEFAULT_PAGESIZE,
      documentDimensionsDisplay: (provider.documentProperty.pageSize || DEFAULT_PAGESIZE) === DEFAULT_PAGESIZE,
      languageKey: provider.language.languageKey,
      landscape: provider.documentProperty.landscape
    };

    const settingsPhase2 = env.features[FEATURE_FLAG] && {
      pageMargin: provider.documentProperty.pageMargin || DEFAULT_MARGIN,
      pageMargins: {
        margin: provider.documentProperty.pageMargins.margin,
        top: provider.documentProperty.pageMargins.top,
        bottom: provider.documentProperty.pageMargins.bottom,
        left: provider.documentProperty.pageMargins.left,
        right: provider.documentProperty.pageMargins.right
      },
      grayscaleOnly: provider.documentProperty.grayscaleOnly
    };

    return { ...settingsPhase1, ...settingsPhase2 };
  }
};

PrintManagerController.create = function (config, data) {
  const printManager = new PrintManagerController(config, data);
  return printManager.render();
};

module.exports = PrintManagerController;
plexExport("PrintManagerController", PrintManagerController);
