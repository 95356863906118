ï»¿const plexDates = require("../Core/plex-dates");
const CustomerDate = require("../Globalization/plex-customer-date");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const TimeHighlight = Feature.extend({
  onExecute: function (record) {
    const DAY_MS = 86400000;
    let width = 0;
    let left = 0;
    let startDateTime, toDateTime, emptyDate;

    let startDate = record[this.config.startDateTimePropertyExpression];
    let toDate = record[this.config.endDateTimePropertyExpression];
    const color = record[this.config.colorProperty] || this.config.color;
    const borderColor = record[this.config.borderColorProperty] || this.config.borderColor;
    const toolTipText = record[this.config.toolTipPropertyExpression];

    startDate = startDate ? new CustomerDate(startDate) : null;
    toDate = toDate ? new CustomerDate(toDate) : null;

    if (startDate && toDate) {
      // use only time part to avoid DST influence
      startDateTime = new Date(0, 0, 0, startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
      toDateTime = new Date(0, 0, 0, toDate.getHours(), toDate.getMinutes(), toDate.getSeconds());
      emptyDate = new Date(0, 0, 0);

      if (toDate >= plexDates.offsetEndDay(startDate)) {
        toDateTime = plexDates.addDays(toDateTime, 1);
      }

      width = ((toDateTime - startDateTime) / DAY_MS) * 100;
      left = ((startDateTime - emptyDate) / DAY_MS) * 100;
    }

    return {
      style: { left: left + "%", width: width + "%", backgroundColor: color, borderColor },
      content: toolTipText
    };
  }
});

module.exports = TimeHighlight;
plexExport("features.TimeHighlight", TimeHighlight);
