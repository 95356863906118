/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const Popover = require("../Knockout/plex-popover");
require("../Knockout/knockout-extender-and-or"); // eslint-disable-line import/no-unassigned-import

const MIN_REQUIRED_CHARS = 3;
const MAX_RESULT_COUNT = 5;

function Searcher(searchMethods) {
  this.searchMethods = searchMethods;
  this.searchResults = {};
  this.displayResults = {};
  this.searching = ko.observable(false);
  this.loading = ko.observable(false);

  this.searchMethods.forEach((m) => {
    this.searchResults[m.name] = ko.observableArray();
    this.displayResults[m.name] = this.searchResults[m.name].extend({ slice: { end: MAX_RESULT_COUNT } });
  });

  const searchKeys = Object.keys(this.searchResults);

  this.hasResults = ko.pureComputed(() => {
    return searchKeys.some((key) => this.searchResults[key]().length > 0);
  });

  this.hasMore = this.hasResults.andAlso(() => {
    return searchKeys.some((key) => this.searchResults[key]().length > MAX_RESULT_COUNT);
  });

  this.searching.subscribe((value) => {
    if (value) {
      // Since the search menu does not use the popover, it is necessary to force other
      // popovers to close when the search menu is opened
      // todo: Update the popover to allow it to be used by the search menu
      Popover.closeAll();
    }
  });
}

Searcher.prototype = {
  constructor: Searcher,

  activate: function () {
    if (this.hasResults()) {
      this.searching(true);
    }
  },

  search: function (query) {
    if (!query || query.length < MIN_REQUIRED_CHARS) {
      this.cancel();
      return;
    }

    this.searching(true);
    this.loading(true);

    const queries = this.searchMethods.map((m) => {
      return m.search(query).then(this.searchResults[m.name]);
    });

    $.when(...queries).then(() => this.loading(false));
  },

  cancel: function () {
    this.searching(false);
    this.loading(false);
  },

  clearResults: function () {
    this.searchMethods.forEach((m) => {
      this.searchResults[m.name].removeAll();
    });
  }
};

module.exports = Searcher;
