ï»¿const ko = require("knockout");
const Disposable = require("../../Mixins/plex-mixins-disposable");

const DIRTY_FLAG_PROPERTY = "$$dirtyFlag";

class Node extends Disposable {
  constructor(config = {}, record = {}) {
    super();

    this.parent = null;
    this.config = config;
    this.record = record;

    this._init();
    this._trackRecord();
  }

  get hasParent() {
    return this.parent instanceof Node;
  }

  remove() {
    if (this.hasParent) {
      this.parent.removeChild(this);
    }

    this.isRemoved(true);
  }

  traverseUp(action) {
    if (this.hasParent) {
      action(this.parent);
      this.parent.traverseUp(action);
    }
  }

  /* #region private  */
  _init() {
    this.key = this._getRecordObservable("Key");
    this.parentKey = this._getRecordObservable("ParentKey");
    this.sortOrder = this._getRecordObservable("SortOrder");

    this.name = this._getRecordObservable("Name");
    this.hotkey = this._getRecordObservable("HotKey");

    this.isRemoved = this._getRecordObservable("IsDeleted");

    this.isEditingName = ko.observable(false);
    this.isEditingHotkey = ko.observable(false);
    this.isEditing = this.isEditingName.orElse(this.isEditingHotkey);

    this.addDisposable(this.isEditing);
  }

  _getRecordObservable(property) {
    if (ko.es5.isTracked(this.record, property)) {
      return ko.getObservable(this.record, property);
    }

    const value = this.record[property];
    if (ko.isObservable(value)) {
      return value;
    } else {
      this.record[property] = Array.isArray(value)
        ? ko.observableArray(value)
        : ko.observable(value === undefined ? null : value);
      return this.record[property];
    }
  }

  _trackRecord() {
    ko.track(this.record);

    this.record[DIRTY_FLAG_PROPERTY] = ko.dirtyFlag(this.record);
    this.isDirty = this.record[DIRTY_FLAG_PROPERTY].isDirty;
  }
  /* #endregion */
}

module.exports = Node;
