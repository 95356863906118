ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.textareaResize = {
  after: ["resizable"],

  update: function (element, valueAccessor, allBindings) {
    const $closestResizable = $(element).closest(".ui-textarea-wrapper");
    if ($closestResizable.length === 0) {
      return;
    }

    const value = ko.unwrap(valueAccessor());
    let visible = true;
    if (allBindings().visible != null) {
      $closestResizable.toggle((visible = allBindings().visible()));
    }

    if (visible) {
      if (value.height) {
        $closestResizable.height(value.height);
      }

      if (value.width) {
        $closestResizable.width(value.width);
      }
    }
  }
};
