ï»¿const ko = require("knockout");
const repository = require("../Controls/plex-model-repository");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

const RecordCountToken = Token.extend({
  getValue: function (records, _i) {
    const sourceId = this.getAttr("sourceId", arguments);
    if (sourceId) {
      records = repository.get(sourceId);
    }

    records = ko.unwrap(records);
    if (!records) {
      return null;
    }

    return records.length;
  }
});

module.exports = RecordCountToken;
plexExport("tokens.recordcount", RecordCountToken);
