ï»¿const $ = require("jquery");
const ko = require("knockout");
const pubsub = require("../Core/plex-pubsub");
const configFactory = require("./FavoritesTree/plex-favorites-tree-config-factory");
const controllerFactory = require("./plex-controller-factory");

const Node = require("./FavoritesTree/plex-favorites-tree-node");
const GroupNode = require("./FavoritesTree/plex-favorites-tree-group-node");
const ActionNode = require("./FavoritesTree/plex-favorites-tree-action-node");
const Disposable = require("../Mixins/plex-mixins-disposable");

const showNotifyDialog = require("../Dialogs/plex-dialogs-notify");
const showConfirmDialog = require("../Dialogs/plex-dialogs-confirm");

const toGlossaryText = (message, tokens = []) => {
  return {
    text: message,
    tokens
  };
};

const findNode = (collection, predicate) => collection.filter((n) => !n.isRemoved() && predicate(n))[0];
const findOtherNode = (collection, node, predicate) => findNode(collection, (n) => n !== node && predicate(n));

const getRecords = (nodes) => nodes.map((node) => node.record);
const getDirtyRecords = (nodes) => getRecords(nodes.filter((node) => node.isDirty()));

const KEY_GENERATOR = {
  _counter: 0,
  next() {
    return --this._counter;
  }
};

const DEFAULT_CONFIG = {
  messages: {
    group: {
      remove: "You are removing a Group. All Favorites and Groups within that Group will be removed.",

      noName: "You need to give a name to your Group, before you can save it.",
      sameName:
        "There is another Group with the same name. If you save now, you will overwrite the previously saved Group. All Favorites and Groups within that Group will be removed.",
      sameHotkeyAtGroup:
        "This hotkey is already assigned. If you assign it to this Favorite Group, Group '{1}' will no longer have a hotkey assigned to it",
      sameHotkeyAtAction:
        "This hotkey is already assigned. If you assign it to this Favorite, Favorite '{1}' will no longer have a hotkey assigned to it.",

      moveError:
        "You cannot move a Group into that Group, as that Group is already at the limit of nesting (2nd level).",
      moveToRoot: "You are moving Group '{1}' outside of Group '{2}'.",
      moveToGroup: "You are nesting this Group into Group '{1}'. This is the limit of nesting (2nd level)."
    },
    action: {
      remove: "You are about to remove this Favorite.",

      noName: "You need to give a name to your Favorite, before you can save it.",
      sameName:
        "There is another Favorite with the same name. If you save now, you will overwrite the previously saved Favorite. The location, hotkey and settings of the previous Favorite will be removed.",
      sameHotkeyAtGroup:
        "This hotkey is already assigned. If you assign it to this Favorite, Group '{1}' will no longer have a hotkey assigned to it.",
      sameHotkeyAtAction:
        "This hotkey is already assigned. If you assign it to this Favorite, Favorite '{1}' will no longer have a hotkey assigned to it.",

      toggleHomePage: "You are saving this Favorite as your Homepage."
    }
  },
  nameMaxLength: 65,
  animationSpeed: 350
};

class FavoritesTreeController extends Disposable {
  static create(config, data) {
    return new FavoritesTreeController(config, data);
  }

  constructor(config, data = {}) {
    super();

    this.config = $.extend(config, DEFAULT_CONFIG, {
      sortable: configFactory.createSortableConfig(this),
      droppable: configFactory.createDroppableConfig(this),
      animations: configFactory.createAnimationConfig(this)
    });

    this.rootNode = this._createGroupNode();
    this.selectedNode = ko.observable(null);

    this.groupNodes = ko.observableArray([]).extend({ deferred: true });
    this.actionNodes = ko.observableArray([]).extend({ deferred: true });

    this.isLoading = ko.observable(false);
    this.isSorting = ko.observable(false);
    this.isDirty = ko.computed(() => this.nodes.some((node) => node.isDirty())).extend({ deferred: true });
    this.isEditing = ko
      .computed(() => this.nodes.some((node) => node.isEditing() && !node.isRemoved()))
      .extend({ deferred: true });
    this.isNotEditing = this.isEditing.extend({ negate: true });

    this.addDisposable(this.isDirty);
    this.addDisposable(this.isEditing);
    this.addDisposable(this.isEditing.subscribe(() => this.publishSelection()));
    this.addDisposable(this.selectedNode.subscribe(() => this.publishSelection()));
    this.addDisposable(this.isNotEditing);
    this.addDisposable(this.rootNode);

    this.load(data);
  }

  get nodes() {
    const groupNodes = this.groupNodes();
    const actionNodes = this.actionNodes();

    return groupNodes.concat(actionNodes);
  }

  dispose() {
    super.dispose();
    this.nodes.forEach((node) => node.dispose());
  }

  /* #region  is */
  isNode(node) {
    return node instanceof Node;
  }

  isGroupNode(node) {
    return node instanceof GroupNode;
  }

  isActionNode(node) {
    return node instanceof ActionNode;
  }

  isSelectedNode(node) {
    return this.selectedNode() === node;
  }
  /* #endregion */

  /* #region  data */
  get data() {
    return this._getData(getRecords);
  }

  get dirtyData() {
    return this._getData(getDirtyRecords);
  }

  _getData(selector) {
    const data = {};
    const groupRecords = selector(this.groupNodes());
    const actionRecords = selector(this.actionNodes());

    let dataProperty = this.config.groupsDataPropertyName;
    if (dataProperty) {
      data[dataProperty] = groupRecords;
    }

    dataProperty = this.config.actionsDataPropertyName;
    if (dataProperty) {
      data[dataProperty] = actionRecords;
    }

    return data;
  }
  /* #endregion */

  /* #region  load */
  load(data = {}) {
    this._beforeLoad();

    this.isLoading(true);

    // get records
    const groupRecords = data[this.config.groupsDataPropertyName] || [];
    const actionRecords = data[this.config.actionsDataPropertyName] || [];

    // create nodes
    const groupNodes = groupRecords.map((record) => this._createGroupNode(record));
    const actionNodes = actionRecords.map((record) => this._createActionNode(record));

    // build tree structure
    const map = {};
    groupNodes.forEach((node) => (map[node.key()] = node));
    groupNodes.forEach((node) => this.addNode(node, map[node.parentKey()]));
    actionNodes.forEach((node) => this.addNode(node, map[node.parentKey()]));

    this.rootNode.startOrderTracking();

    this.isLoading(false);

    this._afterLoad(data);
  }

  _beforeLoad() {
    this.selectedNode(null);

    this.rootNode.stopOrderTracking();
    this.rootNode.childNodes.removeAll();

    this.nodes.forEach((node) => node.dispose());
    this.groupNodes.removeAll();
    this.actionNodes.removeAll();
  }

  _afterLoad(data) {
    this._editNodesAfterLoad(data);
  }

  _createGroupNode(record) {
    const config = {
      messages: this.config.messages.group,
      nameMaxLength: this.config.nameMaxLength
    };
    return new GroupNode(config, record);
  }

  _createActionNode(record) {
    const config = {
      messages: this.config.messages.action,
      nameMaxLength: this.config.nameMaxLength
    };
    return new ActionNode(config, record);
  }

  _editNodesAfterLoad(data) {
    const groupKey = data[this.config.groupKeyToEditPropertyName];
    const group = findNode(this.groupNodes(), (node) => node.key() === groupKey);
    if (group) {
      this.selectNode(group);
      group.expand();
      group.traverseUp((parent) => parent.expand());
      setTimeout(() => group.isEditingName(true), this.config.animationSpeed);

      return;
    }

    const actionKey = data[this.config.actionKeyToEditPropertyName];
    const action = findNode(this.actionNodes(), (node) => node.key() === actionKey);
    if (action) {
      this.selectNode(action);
      action.traverseUp((parent) => parent.expand());
      setTimeout(() => action.isEditingName(true), this.config.animationSpeed);
    }
  }
  /* #endregion */

  /* #region  add */
  addNode(node, targetNode = this.rootNode) {
    if (!this.canAddNode()) {
      return;
    }

    if (this.isGroupNode(node)) {
      this.groupNodes.push(node);
    }
    if (this.isActionNode(node)) {
      this.actionNodes.push(node);
    }

    targetNode.addChild(node);
  }

  addNewGroupNode() {
    if (!this.canAddNode()) {
      return;
    }

    const node = this._createGroupNode();
    node.key(KEY_GENERATOR.next());
    node.startOrderTracking();

    this.addNode(node);
    setTimeout(() => node.isEditingName(true), this.config.animationSpeed);
  }

  canAddNode() {
    return this.isNotEditing();
  }
  /* #endregion */

  /* #region  move */
  moveNode(node, targetNode, targetIndex) {
    if (this.canMoveNode(node, targetNode)) {
      const message = this._getMoveMessage(node, targetNode);
      const moveAction = () => this._moveNode(node, targetNode, targetIndex);
      if (targetNode === node.parent || !message.text) {
        return $.when().then(moveAction);
      } else {
        return showConfirmDialog(message).then(moveAction);
      }
    } else {
      const message = this._getNoMoveMessage(node);
      return showNotifyDialog(message).then(() => new $.Deferred().reject());
    }
  }

  canMoveNode(node, targetNode) {
    if (this.isGroupNode(node) && targetNode !== this.rootNode) {
      if (node.childNodes.some((n) => this.isGroupNode(n)) || targetNode.parent !== this.rootNode) {
        return false;
      }
    }

    return true;
  }

  _moveNode(node, targetNode, targetIndex) {
    if (this.isGroupNode(node)) {
      node.collapse();
    }

    targetNode.addChild(node, targetIndex);

    if (!targetNode.isExpanded()) {
      this.selectNode(targetNode);
    }
  }

  _getMoveMessage(node, targetNode) {
    const messages = node.config.messages;
    return targetNode === this.rootNode
      ? toGlossaryText(messages.moveToRoot, [node.name, node.parent.name])
      : toGlossaryText(messages.moveToGroup, [targetNode.name]);
  }

  _getNoMoveMessage(node) {
    const messages = node.config.messages;
    return toGlossaryText(messages.moveError);
  }
  /* #endregion */

  /* #region  remove */
  removeNode(node) {
    const message = this._getRemoveMessage(node);
    return showConfirmDialog(message).then(() => {
      node.remove();

      if (this.isGroupNode(node) && node.key() < 0) {
        this.groupNodes.remove(node);
      }

      if (this.selectedNode() === node) {
        this.selectedNode(null);
      }
    });
  }

  _getRemoveMessage(node) {
    const messages = node.config.messages;
    return toGlossaryText(messages.remove);
  }
  /* #endregion */

  /* #region  select */
  selectNode(node = null) {
    if (this.isSelectedNode(node)) {
      return;
    }

    if (this.isEditing() && !node.isEditing()) {
      return;
    }

    this.selectedNode(node);
  }

  publishSelection() {
    pubsub.publish("selected.*." + this.config.id, this.selectedNode());
  }
  /* #endregion */

  /* #region  toggleHomePage */
  toggleHomePage(node) {
    if (!this.canToggleHomePage(node)) {
      return;
    }

    if (node.isHomePage()) {
      node.isHomePage(false);
    } else {
      const message = this._getToggleHomePageMessage(node);
      const currentHomePageNode = findOtherNode(this.actionNodes(), node, (n) => n.isHomePage());

      showConfirmDialog(message).then(() => {
        if (currentHomePageNode) {
          currentHomePageNode.isHomePage(false);
        }

        node.isHomePage(true);
      });
    }

    this.publishSelection();
  }

  canToggleHomePage(node) {
    return this.isNotEditing() && this.isActionNode(node);
  }

  _getToggleHomePageMessage(node) {
    const messages = node.config.messages;
    return toGlossaryText(messages.toggleHomePage);
  }
  /* #endregion */

  /* #region  toggleChildNodes */
  toggleChildNodes(node) {
    if (this.isEditing() && !this.isGroupNode(node)) {
      return;
    }

    if (node.isExpanded()) {
      node.collapse();
    } else {
      node.expand();
    }
  }
  /* #endregion */

  /* #region  edit/commit/validate */
  onEdit(component, _node) {
    if (this.validationPromise) {
      this.validationPromise.then(() => component.editing(true));
    } else {
      component.editing(true);
    }
  }

  onCommitName(component, node) {
    return this._onCommit(component, this.createNameValidator(node));
  }

  onCommitHotkey(component, node) {
    return this._onCommit(component, this.createHotkeyValidator(node));
  }

  _onCommit(component, validator) {
    component.editing(false);
    this.validationPromise = validator.validate(component.value());
    return this.validationPromise
      .catch(() => {
        setTimeout(() => component.editing(true), 100);
      })
      .always(() => {
        this.validationPromise = null;
      });
  }

  createNameValidator(node) {
    return {
      validate: (name) => {
        if (name && name.length) {
          const nodes = this.isGroupNode(node) ? this.groupNodes() : this.actionNodes();
          const nodeWithTheSameName = findOtherNode(
            nodes,
            node,
            (n) => n.name().toLowerCase() === node.name().toLowerCase()
          );
          if (nodeWithTheSameName) {
            const message = this._getSameNameMessage(node, nodeWithTheSameName);
            return showConfirmDialog(message).then(() => nodeWithTheSameName.remove());
          }

          return $.when();
        }

        const message = this._getNoNameMessage(node);
        return showNotifyDialog(message).then(() => new $.Deferred().reject());
      }
    };
  }

  createHotkeyValidator(node) {
    return {
      validate: (hotkey) => {
        if (hotkey) {
          const nodeWithSameHotkey = findOtherNode(this.nodes, node, (n) => n.hotkey() === node.hotkey());
          if (nodeWithSameHotkey) {
            const message = this._getSameHotkeyMessage(node, nodeWithSameHotkey);
            return showConfirmDialog(message).then(() => nodeWithSameHotkey.hotkey(null));
          }
        }

        return $.when();
      }
    };
  }

  _getNoNameMessage(node) {
    const messages = node.config.messages;
    return toGlossaryText(messages.noName);
  }

  _getSameNameMessage(node) {
    const messages = node.config.messages;
    return toGlossaryText(messages.sameName);
  }

  _getSameHotkeyMessage(node, nodeWithSameHotkey) {
    const messages = node.config.messages;
    return this.isGroupNode(nodeWithSameHotkey)
      ? toGlossaryText(messages.sameHotkeyAtGroup, [nodeWithSameHotkey.name])
      : toGlossaryText(messages.sameHotkeyAtAction, [nodeWithSameHotkey.name]);
  }
  /* #endregion */
}

controllerFactory.register("~/Views/FavoritesManager/_FavoritesTreeFormSection.cshtml", FavoritesTreeController);

module.exports = FavoritesTreeController;
