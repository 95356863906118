const $ = require("jquery");

const MainMenu = require("./plex-main-menu");
const stateKeys = require("./plex-menu-state").stateKeys;
const nav = require("../../Core/plex-navigate");

function getRoleBasedMenuNode(menuNodeKey, callback) {
  $.getJSON(nav.buildUrl("/Platform/Navigation/GetRoleBasedMenuNode", { roleKey: menuNodeKey }), callback);
}

class RoleBasedMenu extends MainMenu {
  constructor() {
    super();

    this.lastKnownValueKey = stateKeys.roleBasedMenu;
    this.currentNodeUrl = "/Platform/Navigation/GetCurrentRoleBasedMenuNode";
  }

  selectMenuNode(data) {
    const self = this;
    if (data.IsMenuLink) {
      self.loading(true);
      getRoleBasedMenuNode(data.MenuNodeKey, (model) => {
        self.setCurrentMenu(model);
        self.loading(false);
      });
    } else if (data.Url) {
      this._setMenuState(data.MenuNodeKey);
      nav.navigate(data.Url);
    }
  }
}

module.exports = RoleBasedMenu;
