ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const ReadOnlyFeature = Feature.extend({
  onExecute: function () {
    return {
      readOnly: true
    };
  }
});

module.exports = ReadOnlyFeature;
plexExport("features.ReadOnly", ReadOnlyFeature);
