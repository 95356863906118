ï»¿const plexExport = require("../../global-export");

function aggregateData(data, targetGroup) {
  /// <summary>Gets all data under the group.</summary>
  /// <param name="data">The data.</param>
  /// <param name="targetGroup">Indicates whether to target the group, which will cause only one record from each group to be provided. (default false)</param>
  /// <returns type="Array">The combined data.</returns>

  // grouped data will have a `data` property here,
  // non-grouped will just have an array
  if (data.groups && data.groups.length > 0) {
    let all = [];
    data.groups.forEach((group) => {
      all = all.concat(aggregateData(group, targetGroup));
    });

    return all;
  } else if (data.data) {
    // if targeting the group then only return the first record in the group
    return targetGroup ? [data.data[0]] : data.data;
  }

  return data;
}

function getFirstRecord(group) {
  /// <summary>Finds the first record within the provided data, including groups & subgroups.</summary>
  /// <param name="group">The group data.</param>
  /// <returns type="Object">The first record from the provided data.</returns>

  if (group.groups && group.groups.length > 0) {
    return getFirstRecord(group.groups[0]);
  }

  return (group.data || group)[0];
}

const api = {
  aggregateData,
  getFirstRecord
};

module.exports = api;
plexExport("grid.utils", api);
