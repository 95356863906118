ï»¿const plexExport = require("../../global-export");

// syncs up to Plex.Web.UI.Page.Grids.GridSelectionMode flags
const selectionModes = {
  none: 0,
  single: 1,
  multi: 2,
  dirty: 4,
  multiSelectModified: 6
};

function isSelectable(selectionMode) {
  return (selectionMode & selectionModes.single) > 0 || (selectionMode & selectionModes.multi) > 0;
}

function isCheckable(selectionMode) {
  return (selectionMode & selectionModes.multi) > 0;
}

function isMultiSelect(selectionMode) {
  return (selectionMode & selectionModes.multi) > 0 || selectionMode === selectionModes.dirty;
}

function isDirty(selectionMode) {
  return (selectionMode & selectionModes.dirty) > 0;
}

function isMulti(selectionMode) {
  return (selectionMode & selectionModes.multi) === selectionModes.multi;
}

function isSingle(selectionMode) {
  return (selectionMode & selectionModes.single) === selectionModes.single;
}

function isMultiSelectModified(selectionMode) {
  return (selectionMode & selectionModes.multiSelectModified) === selectionModes.multiSelectModified;
}

const api = {
  isSelectable,
  isCheckable,
  isMultiSelect,
  isDirty,
  selectionModes,
  isMulti,
  isSingle,
  isMultiSelectModified
};

module.exports = api;
plexExport("grid.selectionMode", api);
plexExport("grid.selectionModes", selectionModes);
