ï»¿/* eslint-disable no-invalid-this */
const dataTransformFactory = require("./plex-data-transform-factory");
const plexExport = require("../../../global-export");

const DataTransformProcessor = function (dataTransforms, parent) {
  this.config = { dataTransforms };
  this.parent = parent;
  this.init();
};

DataTransformProcessor.prototype = {
  constructor: DataTransformProcessor,

  init: function () {
    const self = this;

    self.dataTransforms = [];

    if (this.config.dataTransforms) {
      this.config.dataTransforms.forEach((config) => {
        self.dataTransforms.push(dataTransformFactory.create(config, self.parent));
      });
    }
  },

  process: function (data) {
    const self = this;

    const transformLength = self.dataTransforms.length;

    if (transformLength > 0) {
      for (let i = 0; i < transformLength; i++) {
        data = self.dataTransforms[i].transform(data);
      }
    }

    return data;
  }
};

module.exports = DataTransformProcessor;
plexExport("data.transforms.DataTransformProcessor", DataTransformProcessor);
