ï»¿/* eslint-disable no-invalid-this */
const plexExport = require("../../global-export");

// todo:
// - might want to make a polling function to periodically check the cache for expired items and evict them

const returnFalse = function () {
  return false;
};

function tryDispose(entry) {
  const obj = entry.value;
  if (obj && typeof obj === "object" && typeof obj.dispose === "function") {
    obj.dispose();
    return true;
  }

  return false;
}

function Cache() {
  this._cache = Object.create(null);
}

Cache.prototype = {
  constructor: Cache,

  set: function (key, value, expiration) {
    /// <summary>Adds an object to the cache under the provided key.</summary>
    /// <param name="key">The key to use to retrieve the object. Should be a string.</param>
    /// <param name="value">The object to store.</param>
    /// <param name="expiration">
    /// The expiration to use for the provided object. If nothing is provided the object will not expire.
    /// If a number is provided the item will expire when the provided number of milliseconds passes.
    /// Alternately you can pass in the results Cache.absoluteExpiration(ms) & Cache.slidingExpiration(ms)
    /// for more fine-grained control.
    /// </param>
    /// <returns type="Object">Returns the value to be cached.</returns>

    key = String(key);
    if (typeof expiration === "number") {
      expiration = Cache.absoluteExpiration(expiration);
    }

    if (this.has(key)) {
      // if the prior object is the same just return, otherwise dispose prior value
      if (this._cache[key].value === value) {
        return value;
      }

      tryDispose(this._cache[key]);
    }

    this._cache[key] = {
      value,
      expired: expiration || returnFalse
    };

    return value;
  },

  get: function (key) {
    /// <summary>Gets the object stored under the given key if found. (Null if not found)</summary>
    /// <param name="key">The key the object is stored under. Should be a string.</param>
    /// <returns type="Object">The cached value if found; null if not found or expired.</returns>

    if (this.has(key)) {
      return this._cache[String(key)].value;
    }

    return null;
  },

  has: function (key) {
    /// <summary>Determines if the cache contains an active object stored under the provided key.</summary>
    /// <param name="key">The key the object is stored under. Should be a string.</param>
    /// <returns type="Boolean">true if an active object exists; false otherwise.</returns>

    key = String(key);
    if (!(key in this._cache)) {
      return false;
    }

    const entry = this._cache[key];
    if (entry.expired()) {
      tryDispose(entry);
      delete this._cache[key];
      return false;
    }

    return true;
  },

  reset: function () {
    /// <summary>Clears all items from the cache.</summary>

    let key;
    /* eslint-disable guard-for-in */
    for (key in this._cache) {
      tryDispose(this._cache[key]);
      delete this._cache[key];
    }
  }
};

Cache.absoluteExpiration = function (timeOut) {
  /// <summary>
  /// Creates an expiration function based on the time out provided. The object will be
  /// considered expired after the time out provided has been exceeded.
  /// </summary>
  /// <param name="timeOut">Number of milliseconds before time out is exceeded.</param>
  /// <returns type="Function">The expiration function.</returns>

  if (!timeOut) {
    return returnFalse;
  }

  const then = Date.now();
  return function () {
    return Date.now() - then >= timeOut;
  };
};

Cache.slidingExpiration = function (timeOut) {
  /// <summary>
  /// Creates an expiration function based on the time out provided. The object will be
  /// considered expired when the time out since last access has been exceeded.
  /// </summary>
  /// <param name="timeOut">Number of milliseconds before time out is exceeded.</param>
  /// <returns type="Function">The expiration function.</returns>

  if (!timeOut) {
    return returnFalse;
  }

  let lastAccess = Date.now();
  return function () {
    const now = Date.now();
    if (now - lastAccess >= timeOut) {
      return true;
    }

    lastAccess = now;
    return false;
  };
};

module.exports = Cache;
plexExport("Cache", Cache);
