ï»¿const $ = require("jquery");
const PrintWriter = require("./plex-grid-print-writer-base");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const gridUtils = require("../../plex-grid-utils");
const printOperationTarget = require("../../plex-grid-recordtarget");
const plexExport = require("../../../../global-export");

const FooterRowPrintWriter = PrintWriter.extend({
  init: function (grid, group, footer) {
    this.group = group;
    this.footer = footer;
    this.hasGroup = this.group !== grid.options;

    this._base.apply(this, arguments);

    if (footer.features && footer.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(footer.features, footer, this.parent);
    }

    if (!(footer.elements && footer.elements.length > 0)) {
      this.footerTextEvaluator = footer.$$footerTextEvaluator;
    }
  },

  getNodeName: function () {
    return this.hasGroup ? "grid-group-footer-row" : "grid-table-row";
  },

  print: function (data) {
    const self = this;

    let i = 0;
    let colspan = 0;
    let isFirstCell = true;

    // no operations are visible so skip printing the footer
    const operations = this.footer.$$printOperations;
    if (!operations || operations.length === 0) {
      return "";
    }

    if (!this.footer.$$printTotals || !this.footer.$$printTotals[data.gIndex || 0]) {
      return self.toPrintXml();
    }

    const values = this.footer.$$printTotals[data.gIndex || 0];
    const allData = gridUtils.aggregateData(data, false);
    const groupData = gridUtils.aggregateData(data, true);

    if (this.hasGroup) {
      if (this.footer.printBreak) {
        this.node.addAttribute(this.footer.printBreak.attribute, this.footer.printBreak.condition);
      }
    }

    this.grid.columns.forEach((col, colIndex) => {
      let agg, features, context;

      if (col.printVisible()) {
        if (i < operations.length && colIndex === operations[i].colIndex) {
          agg = operations[i];

          if (colspan) {
            self._printEmpty(colspan, isFirstCell, allData[0]);
            colspan = 0;
            isFirstCell = false;
          }

          // use uppercase properties to comply with casing expected from C# expressions
          context = { Values: values };
          context.Value = values[colIndex];

          if (agg.featureProcessor) {
            /* eslint no-useless-call: "off" */
            features = agg.featureProcessor.apply(agg, [context])();
            features.merge({ css: agg.css });
          } else {
            features = { css: agg.css };
          }

          const cellNode = self.node.createNode("grid-table-cell");

          self.writeCss(cellNode, features.css);
          self.writeStyle(cellNode, features.style);
          if (colspan > 1) {
            self.writeAttributes(cellNode, $.extend(true, { colspan }, features.attr));
          } else {
            self.writeAttributes(cellNode, features.attr);
          }

          // If first cell is aggregate, add footertext to cell
          if (colspan === 0 && isFirstCell) {
            cellNode.appendXml(self.getFooterText(allData[0]));
            isFirstCell = false;
          }

          cellNode.appendXml(
            agg.formatValue(context.Value, agg.config.target === printOperationTarget.record ? allData : groupData)
          );

          i++;
        } else {
          colspan++;
        }
      }
    });

    if (colspan) {
      this._printEmpty(colspan);
    }

    return self.toPrintXml();
  },

  getFooterText: function (record) {
    let text;
    if (this.footerTextEvaluator) {
      text = this.footerTextEvaluator(record);
    } else {
      text = this.footer.valueProvider.getPrintValue(record);
    }

    return (text || "").replace(/(&nbsp;)/g, "");
  },

  _printEmpty: function (colspan, includeFooterText, record) {
    let text = "";
    if (includeFooterText) {
      text = this.getFooterText(record);
    }

    if (colspan > 1) {
      this.node.createNode("grid-table-cell", { colspan }).appendXml(text);
    } else {
      this.node.createNode("grid-table-cell").appendXml(text);
    }
  }
});

module.exports = FooterRowPrintWriter;
plexExport("grid.writers.FooterRowPrintWriter", FooterRowPrintWriter);
