ï»¿const ko = require("knockout");

// this extender simply wraps the rate limit function, adding a "flush" method to immediately notify subscribers of an
// updated value
const limit = ko.extenders.rateLimit;
ko.extenders.rateLimit = function (source) {
  const notify = source.notifySubscribers;
  const target = limit.apply(this, arguments) || source;

  target.flush = function () {
    notify.call(source, target.peek());
  };
  return target;
};
