ï»¿const excelUtils = require("./plex-exporter-utils-excel");
const Serializer = require("./plex-exporter-serializers");
const StringBuilder = require("../Utilities/plex-utils-stringbuilder");
const plexExport = require("../../global-export");
const arrayUtils = require("../Utilities/plex-utils-arrays");

let styles = [];
let visibleColumns = [];
const workBookAttributes = [
  { name: "xmlns", value: "urn:schemas-microsoft-com:office:spreadsheet" },
  { name: "xmlns:o", value: "urn:schemas-microsoft-com:office:office" },
  { name: "xmlns:x", value: "urn:schemas-microsoft-com:office:excel" },
  { name: "xmlns:ss", value: "urn:schemas-microsoft-com:office:spreadsheet" },
  { name: "xmlns:html", value: "http://www.w3.org/TR/REC-html40" }
];

const defaultHeaderStyle = {
  id: "DefaultHeader",
  elements: [{ name: "Font", attributes: [{ name: "Bold", value: "1" }] }]
};

const columnDataTypes = {
  Default: "String",
  Text: "String",
  DateTime: "Number",
  Number: "Number",
  Boolean: "Number"
};

const XmlSerializer = Serializer.extend({
  extension: ".xml",
  altExtension: ".xml",
  mimeType: "text/xml",

  serialize: function (data) {
    const headerWriter = new StringBuilder();
    const styleWriter = new StringBuilder();
    const writer = new StringBuilder();

    styles = [];
    styles.push(defaultHeaderStyle);
    visibleColumns = this.getColumns();

    headerWriter.append('<?xml version="1.0"?>');
    headerWriter.append('<?mso-application progid="Excel.Sheet"?>');
    startWorkbook(headerWriter);
    writeStyles(styleWriter);
    startWorksheet(writer, this);

    const chunker = arrayUtils.chunk(data).each((record, i) => writeRow(writer, record, i));

    return chunker.start().then(() => {
      endWorksheet(writer);
      endWorkbook(writer);
      return headerWriter.toString() + styleWriter.toString() + writer.toString();
    });
  }
});

function startWorkbook(writer) {
  writer.append("<Workbook");
  workBookAttributes.forEach((attr) => {
    writeAttribute(writer, attr, false);
  });

  writer.append(">");
}

function endWorkbook(writer) {
  writer.append("</Workbook>");
}

function writeStyles(writer) {
  writer.append("<Styles>");
  styles.forEach((style) => {
    writeStyle(writer, style);
  });

  writer.append("</Styles>");
}

function writeStyle(writer, style) {
  writer.append("<Style");
  writeAttribute(writer, { name: "ID", value: style.id });
  writer.append(">");

  style.elements.forEach((element) => {
    writer.append("<" + element.name);
    if (element.attributes) {
      element.attributes.forEach((attribute) => {
        writeAttribute(writer, attribute);
      });
    }

    writer.append("/>");
  });

  writer.append("</Style>");
}

function startWorksheet(writer, serializer) {
  writer.append("<Worksheet");
  writeAttribute(writer, { name: "Name", value: "Sheet1" });
  writer.append(">");
  writer.append("<Table>");
  writeHeaderRow(writer, serializer);
}

function endWorksheet(writer) {
  writer.append("</Table></Worksheet>");
}

function writeHeaderRow(writer, serializer) {
  if (serializer.parent.exportHeader === false) {
    return;
  }

  writer.append("<Row>");
  visibleColumns.forEach((column) => {
    const style = defaultHeaderStyle;
    writeCell(writer, { style, type: columnDataTypes.Text, value: serializer.getColumnHeaderName(column) });
  });

  writer.append("</Row>");
}

function writeRow(writer, record, i) {
  writer.append("<Row>");
  visibleColumns.forEach((column) => {
    let style = null;
    let type = getColumnType(column);
    const value = excelUtils.getCellValue(record, i, column);
    if (value.formatCode) {
      const columnStyle = styles.filter((s) => {
        return s.id === column.headerName();
      });

      if (columnStyle.length > 0) {
        style = columnStyle[0];
      } else {
        styles.push(
          (style = {
            id: column.headerName(),
            elements: [{ name: "NumberFormat", attributes: [{ name: "Format", value: value.formatCode }] }]
          })
        );
      }
    }

    if (value.value == null) {
      value.value = "";
      type = columnDataTypes.Default;
    }

    writeCell(writer, { style, type, value: value.value });
  });

  writer.append("</Row>");
}

function writeCell(writer, cell) {
  writer.append("<Cell");

  if (cell.style) {
    writeAttribute(writer, { name: "StyleID", value: cell.style.id });
  }

  writer.append(">");
  writer.append("<Data");
  writeAttribute(writer, { name: "Type", value: cell.type });
  writer.append(">");
  writer.append(cell.value + "</Data></Cell>");
}

function writeAttribute(writer, attr, isStyleAttr) {
  let prefix = "ss:";
  if (isStyleAttr === false) {
    prefix = "";
  }

  writer.append(" " + prefix + attr.name + '="' + attr.value + '"');
}

function getColumnType(column) {
  if (column.type in columnDataTypes) {
    return columnDataTypes[column.type];
  } else {
    return columnDataTypes.Default;
  }
}

module.exports = XmlSerializer;
plexExport("exporter.serializers.xml", XmlSerializer);
