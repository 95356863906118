const ko = require("knockout");
const EditableComponent = require("./plex-component-editable-base");

class EditableFieldComponent extends EditableComponent {
  constructor(params) {
    super(params);

    if (params.maxLength) {
      this.attributes.maxLength = params.maxLength;
    }
  }
}

ko.components.register("plex-component-editable-field", {
  template: "<!-- ko template: 'components-editable-field' --><!-- /ko -->",
  viewModel: EditableFieldComponent
});
