const ko = require("knockout");
const Disposable = require("../Mixins/plex-mixins-disposable");

const async = (func, delay = 150) => setTimeout(func, delay);

class EditableComponent extends Disposable {
  constructor(params) {
    super();

    this.value = params.value;
    this.editing = params.editing || ko.observable(false);
    this.attributes = params.attributes || {};

    this.onEdit = params.onEdit;
    this.onCommit = params.onCommit;

    this.events = {
      keyup: (_vm, event) => {
        if (event.keyCode === 13) {
          event.target.blur();
        }
      },
      focusout: (_vm, _event) => {
        this.commit();
      }
    };
  }

  edit() {
    async(() => this.onEdit && this.onEdit(this));
  }

  commit() {
    async(() => this.onCommit && this.onCommit(this));
  }
}

module.exports = EditableComponent;
