ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const controllerFactory = require("./plex-controller-factory");
const plexExport = require("../../global-export");
const banner = require("../Plugins/plex-banner");

const DEFAULT_BG_COLOR_CLASS = "plex-icon-bg-white";

function getClassByKey(arr, key) {
  const record = getCollectionItemByKey(arr, key);
  return record && record.className;
}

function getCollectionItemByKey(collection, key) {
  return ko.utils.arrayFirst(collection, (o) => {
    return o.key === key;
  });
}

const IconPickerController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

IconPickerController.prototype = {
  constructor: IconPickerController,

  init: function () {
    const self = this;
    this.collection = ko.observableArray(this.config.collection);
    this.colors = ko.observableArray(this.config.colors);

    this.resultMessageHtml = ko.computed(() => {
      if (self.collection().length === 0) {
        return "No records were found.";
      }

      return "";
    });

    this.config.boundValue = dataUtils.getObservable(this.model, this.config.propertyName);
    this.config.showSelected = true;

    const isIconPicker = typeof this.config.colorPropertyName !== "undefined";
    this.label = {
      title: isIconPicker ? "Icon Pick List" : "Image Pick List",
      preview: isIconPicker ? "Selected icon" : "Selected image",
      collectionList: isIconPicker ? "Select icon" : "Select image"
    };

    if (isIconPicker) {
      this.config.boundColorKey = dataUtils.getObservable(this.model, this.config.colorPropertyName);
    }

    // used in MenuDialog.js
    this.iconClassName = ko.pureComputed(() => {
      return getClassByKey(self.collection(), self.config.boundValue());
    });

    this.selected = ko.observable({});
    this.selectedColor = ko.observable({});

    this.selectedColorClassName = ko.computed(() => {
      return getClassByKey(self.colors(), self.selectedColor().key) || DEFAULT_BG_COLOR_CLASS;
    });

    // picker display models
    this.boundCollectionItem = ko.computed(() => {
      return getCollectionItemByKey(self.collection(), self.config.boundValue()) || {};
    });

    this.colorClassName = ko.computed(() => {
      if (!self.config.boundColorKey) {
        return DEFAULT_BG_COLOR_CLASS;
      }

      return getClassByKey(self.colors(), self.config.boundColorKey()) || DEFAULT_BG_COLOR_CLASS;
    });

    this.setInitialValues();
  },

  pick: function () {
    if (this.config.disabled()) {
      return;
    }

    this.setInitialValues();
    this.render();
  },

  render: function () {
    if (this.showing) {
      return;
    }

    const self = this;
    this.showing = true;
    this.$element = $("<div>").appendTo(document.body);
    ko.renderTemplate("picker-icon", this, {}, this.$element[0]);

    // Banner for icon-picker dialog
    self.$element.find(".plex-banner").banner();
    self.banner = banner.findClosest(self.$element);

    this.$picker = self.$element
      .find(".plex-picker-modal")
      .modal({ show: false, backdrop: "static" })
      .one("hide.bs.modal", () => {
        self.reset();
      })
      .one("hidden.bs.modal", function () {
        $(this).remove();
        self.$element.remove();
      });

    this.$content = this.$picker.find(".modal-content");
    this.$picker.modal("show");
  },

  cancel: function () {
    this.$picker.modal("hide");
  },

  select: function (icon) {
    if (icon === this.selected()) {
      this.selected({});
      return;
    }

    this.selected(icon);
  },

  selectColor: function (color) {
    if (color === this.selectedColor()) {
      this.selectedColor({});
      return;
    }

    this.selectedColor(color);
  },

  applySelected: function () {
    const item = this.selected();
    const color = this.selectedColor();

    this.config.boundValue(item.key);

    if (this.config.boundColorKey) {
      this.config.boundColorKey(color.key);
    }
  },

  reset: function () {
    this.showing = false;
    this.selected({});
    this.selectedColor({});
  },

  setInitialValues: function () {
    const initValue = this.config.boundValue();
    let collectionValue;

    if (initValue) {
      collectionValue =
        this.collection().filter((record) => {
          return record.key === initValue;
        })[0] || {};
      this.selected(collectionValue);
    }

    if (this.config.boundColorKey) {
      const colorKey = this.config.boundColorKey();
      let color;

      if (colorKey) {
        color =
          this.colors().filter((record) => {
            return record.key === colorKey;
          })[0] || {};
        this.selectedColor(color);
      }
    }
  }
};

IconPickerController.create = function (config, model) {
  return new IconPickerController(config, model);
};

controllerFactory.register("Elements/_IconPicker", IconPickerController);

module.exports = IconPickerController;
plexExport("IconPickerController", IconPickerController);
