ï»¿import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import plexExport from "../../../global-export";
import plexImport from "../../../global-import";
import pubsub from "../../Core/plex-pubsub";
import PageState from "../../Core/plex-pagestate";
import { onReady } from "../plex-handler-ready";
import {
  HelpSidePanel,
  helpSidePanelStateKeyState,
  helpSidePanelStateKeyLastKnownTopicPathname,
  helpSidePanelStateKeyLastKnownTopicSearchCriteria
} from "./HelpSidePanel";

const api = {};
let initialized = false;

const panelContainerClassName = "plex-side-panel-container";
const tabContainerClassName = "plex-help-side-tab";

const helpSidePanelStateEnum = {
  expanded: 1,
  collapsed: 2
};

let $helpPanel;
let $helpTab;

api.show = (_data) => {
  const $helpPanelWrapper = $("#helpPanel");

  if ($helpPanelWrapper.length) {
    const cloudApplicationKey = plexImport("currentApplication").ApplicationKey;

    const onClose = () => {
      ReactDOM.unmountComponentAtNode($helpPanelWrapper.get(0));

      PageState.clearStateByKey(helpSidePanelStateKeyState);
      PageState.clearStateByKey(helpSidePanelStateKeyLastKnownTopicPathname);
      PageState.clearStateByKey(helpSidePanelStateKeyLastKnownTopicSearchCriteria);

      pubsub.publish("helpPanelResized");
    };

    const onCollapse = () => {
      $helpPanel.hide();

      $helpTab.show();

      pubsub.publish("helpPanelResized");

      PageState.addToCurrentState(helpSidePanelStateKeyState, helpSidePanelStateEnum.collapsed);
    };

    const onExpand = () => {
      $helpPanel.show();

      $helpTab.hide();

      pubsub.publish("helpPanelResized");

      PageState.addToCurrentState(helpSidePanelStateKeyState, helpSidePanelStateEnum.expanded);
    };

    if (initialized) {
      const previousState = PageState.getLastKnownValue(helpSidePanelStateKeyState);

      if (previousState === helpSidePanelStateEnum.collapsed) {
        // User is (re)opening the side panel from the UX help menu while it is already opened in collapsed mode
        $helpPanel.show();
        $helpTab.hide();

        PageState.addToCurrentState(helpSidePanelStateKeyState, helpSidePanelStateEnum.expanded);
      } else {
        ReactDOM.render(
          <HelpSidePanel
            cloudApplicationKey={cloudApplicationKey}
            onClose={onClose}
            onCollapse={onCollapse}
            onExpand={onExpand}
            panelContainerClassName={panelContainerClassName}
            tabContainerClassName={tabContainerClassName}
          />,
          $helpPanelWrapper.get(0)
        );

        $helpPanel = $helpPanelWrapper.find("." + panelContainerClassName);
        $helpTab = $helpPanelWrapper.find("." + tabContainerClassName);

        $helpTab.hide();

        PageState.addToCurrentState(helpSidePanelStateKeyState, helpSidePanelStateEnum.expanded);
      }

      pubsub.publish("helpPanelResized");
    } else {
      $helpPanelWrapper.addClass("plex-side-panel");

      ReactDOM.render(
        <HelpSidePanel
          cloudApplicationKey={cloudApplicationKey}
          onClose={onClose}
          onCollapse={onCollapse}
          onExpand={onExpand}
          panelContainerClassName={panelContainerClassName}
          tabContainerClassName={tabContainerClassName}
        />,
        $helpPanelWrapper.get(0)
      );

      $helpPanel = $helpPanelWrapper.find("." + panelContainerClassName);
      $helpTab = $helpPanelWrapper.find("." + tabContainerClassName);

      const previousState = PageState.getLastKnownValue(helpSidePanelStateKeyState);

      if (previousState === helpSidePanelStateEnum.collapsed) {
        $helpPanel.hide();
      } else {
        $helpTab.hide();
      }

      initialized = true;

      PageState.addToCurrentState(helpSidePanelStateKeyState, helpSidePanelStateEnum.expanded);
    }
  }
};

onReady(() => {
  const previousState = PageState.getLastKnownValue(helpSidePanelStateKeyState);

  if (previousState === helpSidePanelStateEnum.expanded || previousState === helpSidePanelStateEnum.collapsed) {
    api.show({});
  }
});

module.exports = api;
plexExport("helpSidePanelController", api);
