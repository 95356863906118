ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const culture = require("../Globalization/plex-culture-datetime");
const CustomerDate = require("../Globalization/plex-customer-date");
const plexExport = require("../../global-export");

const PrimaryStates = {
  Month: "month",
  Day: "day",
  Time: "time"
};

function parseDateTokens(date, tokenString) {
  let dateText = tokenString.replace("{MONTH}", culture.formatDate(date, { raw: "MMMM" }));
  dateText = dateText.replace("{MM}", culture.formatDate(date, { raw: "MM" }));
  dateText = dateText.replace("{M}", culture.formatDate(date, { raw: "M" }));
  dateText = dateText.replace("{YYYY}", culture.formatDate(date, { raw: "yyyy" }));
  return dateText.replace("{YY}", culture.formatDate(date, { raw: "yy" }));
}

const DateTimeState = {
  Time: {
    previous: function (date) {
      return date;
    },
    next: function (date) {
      return date;
    },
    header: function (_date) {
      return "";
    },
    nextHeader: function (_date) {
      return "";
    },
    previousHeader: function (_date) {
      return "";
    }
  },
  Day: {
    previous: function (date) {
      const lastMonth = new CustomerDate(date.getTime());
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      return new CustomerDate(lastMonth);
    },
    next: function (date) {
      const nextMonth = new CustomerDate(date.getTime());
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return new CustomerDate(nextMonth);
    },
    header: function (date, dateTokenString) {
      return dateTokenString ? parseDateTokens(date, dateTokenString) : culture.formatDate(date, { raw: "yyyy MMMM" });
    },
    nextHeader: function (date) {
      return culture.formatDate(this.next(date), { raw: "MMMM" });
    },
    previousHeader: function (date) {
      return culture.formatDate(this.previous(date), { raw: "MMMM" });
    }
  },
  Month: {
    previous: function (date) {
      const lastYear = new CustomerDate(date.getTime());
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      return new CustomerDate(lastYear);
    },
    next: function (date) {
      const nextYear = new CustomerDate(date.getTime());
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      return new CustomerDate(nextYear);
    },
    header: function (date) {
      return date.getFullYear();
    },
    nextHeader: function (date) {
      return this.header(this.next(date));
    },
    previousHeader: function (date) {
      return this.header(this.previous(date));
    }
  },
  Year: {
    previous: function (date) {
      const lastDecade = new CustomerDate(date.getTime());
      lastDecade.setFullYear(lastDecade.getFullYear() - 10);
      return new CustomerDate(lastDecade);
    },
    next: function (date) {
      const nextDecade = new CustomerDate(date.getTime());
      nextDecade.setFullYear(nextDecade.getFullYear() + 10);
      return new CustomerDate(nextDecade);
    },
    header: function (date) {
      const currentYear = date.getFullYear();
      const decadeStart = currentYear - (currentYear % 10);
      return decadeStart + "-" + (decadeStart + 9);
    },
    nextHeader: function (date) {
      return this.header(this.next(date));
    },
    previousHeader: function (date) {
      return this.header(this.previous(date));
    }
  }
};

const defaultOptions = {
  primaryState: PrimaryStates.Day
};

function DateTimeStateMachine(options) {
  this.options = $.extend({}, defaultOptions, options);
  this.currentState = ko.observable(this.getPrimaryState());
}

DateTimeStateMachine.prototype.nextState = function () {
  const oldState = this.currentState();
  switch (oldState) {
    case DateTimeState.Month:
      if (this.options.primaryState === PrimaryStates.Day) {
        this.currentState(DateTimeState.Day);
      }
      break;
    case DateTimeState.Year:
      this.currentState(DateTimeState.Month);
      break;
    default:
      // ignore
      break;
  }
};

DateTimeStateMachine.prototype.previousState = function () {
  const oldState = this.currentState();
  switch (oldState) {
    case DateTimeState.Day:
      this.currentState(DateTimeState.Month);
      break;
    case DateTimeState.Month:
      this.currentState(DateTimeState.Year);
      break;
    default:
      // ignore
      break;
  }
};

DateTimeStateMachine.prototype.setState = function (state) {
  if (!state) {
    this.currentState(this.getPrimaryState());
    return;
  }

  this.currentState(state);
};

DateTimeStateMachine.prototype.getPrimaryState = function () {
  switch (this.options.primaryState) {
    case PrimaryStates.Month:
      return DateTimeState.Month;
    case PrimaryStates.Time:
      return DateTimeState.Time;
    default:
      // date
      return DateTimeState.Day;
  }
};

const api = {
  StateMachine: DateTimeStateMachine,
  DateTimeState,
  PrimaryStates
};

module.exports = api;
plexExport("dateTimeStateMachine", api);
