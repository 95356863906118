ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const DisableFeature = Feature.extend({
  onExecute: function () {
    return {
      disabled: true
    };
  }
});

module.exports = DisableFeature;
plexExport("features.Disable", DisableFeature);
