ï»¿const ko = require("knockout");
const repository = require("../Controls/plex-model-repository");
const logger = require("../Core/plex-logger");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

const SumToken = Token.extend({
  getValue: function (records, _i) {
    const prop = this.getAttr("propertyName", arguments);
    const sourceId = this.getAttr("sourceId", arguments);
    let value = 0;

    if (!prop) {
      return null;
    }

    if (sourceId) {
      records = repository.get(sourceId);
    }

    records = ko.unwrap(records);
    if (!records || records.length === 0) {
      return value;
    }

    if (!(prop in records[0])) {
      logger.error("Unable to find property: " + prop);
      return value;
    }

    records.forEach((rec) => {
      value += Number(rec[prop]);
    });

    return value;
  }
});

module.exports = SumToken;
plexExport("tokens.sum", SumToken);
