ï»¿const api = (module.exports = {});

const $ = require("jquery");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const parseURL = require("../Core/plex-parsing-url");
const nav = require("../Core/plex-navigate");

let favoriteActionKey, promise;

function isSpaRendering() {
  const spa = plexImport("spa");
  return spa?.isSpaRendering?.();
}

function isAvailable() {
  if (favoriteActionKey === undefined || isSpaRendering()) {
    favoriteActionKey = getFavoriteActionKey();
  }

  return !!favoriteActionKey;
}

function getFavoriteActionKey() {
  const qs = parseURL.parseQueryString(window.location.href);
  return qs[nav.FavoriteActionKeyTokenName] || null;
}

function getState() {
  if (!promise || (isSpaRendering() && promise.state() !== "pending")) {
    promise = isAvailable()
      ? $.getJSON(nav.buildUrl("/Platform/Favorites/GetState", { favoriteActionKey }))
      : new $.Deferred().reject();
  }

  return promise;
}

api.getState = getState;
api.isAvailable = isAvailable;
plexExport("remoteStateStorage", api);
