ï»¿const logger = require("../../Core/plex-logger");
const env = require("../../Core/plex-env");
const HtmlWriter = require("../../Utilities/plex-utils-html-writer");
const VirtualHtmlWriter = require("../../Utilities/plex-utils-v-html-writer");
const RowConfig = require("./plex-grid-row-config");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const writerFactory = require("./plex-grid-writer-factory");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const Writer = require("./plex-grid-writer-base");
const plexExport = require("../../../global-export");

const GridWriter = Writer.extend({
  init: function (grid) {
    const self = this;
    this._base.apply(this, arguments);

    this.options = grid.options;
    this.footers = this.options.footers;

    this.rows = this.options.rows;
    this.rows.forEach((layout) => {
      layout.writer = writerFactory.create(
        layout.config.rowWriterProvider || layout.config.writerProvider,
        grid,
        layout
      );

      if (layout.primary) {
        self.primaryLayout = layout;
      }
    });

    if (this.footers && this.footers.length > 0) {
      this.footers.forEach((footer, index) => {
        footer.index = index;
        footer.writer = writerFactory.create(footer.writerProvider, self.grid, self.options, footer);
      });
    }

    if (grid.options.gridFeatures && grid.options.gridFeatures.length > 0) {
      this.featureProcessor = new FeatureProcessor(grid.options.gridFeatures, grid.options, grid);
    }

    this.onReset();
  },

  render: function (data) {
    const self = this;
    const $tbody = this.grid.$tbody;
    const config = {
      selectable: this.grid.isSelectable(),
      checkable: this.grid.isCheckable(),
      collapsible: this.grid.isCollapsible()
    };
    const source = this.grid.getData();

    if (this.shouldVirtualize(source)) {
      const renderer = function (writer, index) {
        if (index < data.length) {
          return self.renderRow(writer, data[index], index, data);
        } else {
          return self.renderFooter(self.grid.$tfoot, source, config);
        }
      };

      this.renderVirtualHtml(renderer, data.length + (this.footers && this.footers.length > 0 ? 1 : 0));
    } else {
      this.renderBatch($tbody, data, source, config);
    }

    this.renderFeatures(this.grid.$table);
  },

  renderBatch: function ($tbody, data, source, config) {
    const self = this;
    if (!this.grid.onRenderStart()) {
      return;
    }

    let progressStep = 1;
    const length = data.length;

    if (length > 100) {
      progressStep = Math.floor(data.length / 100);
    }

    let cancelSub;
    let writer = new HtmlWriter($tbody);
    let chunker = arrayUtils
      .chunk(data, 300)
      .each((record, index) => {
        self.renderRow(writer, record, index, source);

        if (++index % progressStep === 0) {
          // todo: report progress
        }
      })
      .batch(writer.render.bind(writer));

    cancelSub = this.grid.cancelPending.subscribeOnce((cancel) => {
      if (cancel && chunker != null) {
        chunker.cancel();
        self.grid.onRenderCancel();
      }

      if (cancelSub) {
        cancelSub.dispose();
        cancelSub = null;
      }
    });

    chunker.start().done(() => {
      self.renderFooter(self.grid.$tfoot, source, config);
      self.grid.onRenderComplete();
      writer = chunker = null;

      if (cancelSub) {
        cancelSub.dispose();
        cancelSub = null;
      }
    });
  },

  renderVirtualHtml: function (renderer, length) {
    if (this.grid.onRenderStart()) {
      if (this.writer) {
        this.writer.dispose();
      }

      this.writer = new VirtualHtmlWriter(this.grid.$tbody, {
        renderer,
        count: length,
        batchCallback: this.grid.onRenderBatch.bind(this.grid)
      });
      this.writer.render();

      this.grid.lockWidths();
      this.grid.onRenderComplete();
    }
  },

  ensureRendered: function (dataIndex, callback) {
    if (this.writer && this.virtual) {
      this.writer.ensureRendered(dataIndex, callback);
    } else if (callback) {
      callback();
    }
  },

  shouldVirtualize: function (data) {
    if (data.length === 0) {
      return (this.virtual = false);
    }

    return (this.virtual = this.grid.options.virtualizeRows !== false && !env.features.novirtual);
  },

  renderRow: function (writer, record, index, data, indexInGroup, group) {
    const config = new RowConfig(record, index, data, indexInGroup, group, this.options, this.rowWriter);

    this.rows.forEach((layout, rowIndex) => {
      config.rowIndex = rowIndex;
      if (layout.writer.prerender(record, index, data, config, indexInGroup, group) !== false) {
        layout.writer.render(writer, record, index, data, config, indexInGroup, group);
      }
    });
  },

  renderFooter: function ($tfoot, data, config) {
    if (!this.footers || this.footers.length === 0) {
      return;
    }

    $tfoot.empty();
    let writer = new HtmlWriter($tfoot);
    this.footers.forEach((footer) => {
      let record = {};
      const records = data.source || data;
      if (records && records.length > 0) {
        record = records[0];
      }

      if (footer.writer.prerender(record, 0, records) !== false) {
        footer.writer.render(writer, data, config);
      }
    });

    writer.render();
    writer = null;
  },

  renderFeatures: function ($table) {
    if (this.features.css && this.features.css.length > 0) {
      $table.addClass(this.features.css.join(" "));
    }

    if (this.features.style) {
      $table.css(this.features.style);
    }
  },

  sync: function (data, changes) {
    // Can't sort dom when visible row and suppress row features are used
    if (this.grid.canSync === false || this.grid.data().canSync === false) {
      return false;
    }

    // make sure these are all moves and within our range of data
    const length = data.length;
    if (
      changes.some((change) => {
        return change.moved === undefined || change.index >= length;
      })
    ) {
      return false;
    }

    return this.onSync(data, changes);
  },

  onSync: function (data, _changes) {
    const rows = this.grid.$tbody[0].rows;

    // check to see if this is just a sort
    // if so we can save some time by just sorting the dom
    if (data.length === rows.length) {
      // we can get away with just sorting the dom
      logger.debug("resorting dom");

      this.grid.$tbody.append(
        data.map((record) => {
          return rows[record.$$index];
        })
      );

      // reindex the table
      this._reindex();
      return true;
    }

    // todo: we may be able to do further optimizations here
    // ie, only render the rows that change changed instead of re-rendering the whole grid
    return false;
  },

  _reindex: function () {
    // not using jquery here simply for optimal performance
    const tbody = this.grid.$tbody[0];
    let i = tbody.rows.length;

    while (i--) {
      tbody.rows[i].setAttribute("data-index", i);
    }
  },

  onReset: function () {
    const self = this;
    this.primaryLayout.reset();

    this.rows.forEach((layout) => {
      if (layout !== self.primaryLayout) {
        layout.reset();
      }
    });
  },

  dispose: function () {
    if (this.writer && typeof this.writer.dispose === "function") {
      this.writer.dispose();
    }
  }
});

module.exports = GridWriter;
plexExport("grid.writers.GridWriter", GridWriter);
