ï»¿const $ = require("jquery");
const documentProvider = require("./plex-printing-document-providers");
const dataUtils = require("../../Utilities/plex-utils-data");
const http = require("../../Core/plex-navigate");
const plexExport = require("../../../global-export");

const DynamicPrintDocumentProvider = documentProvider.extend({
  onInit: function () {
    this.requests = [];
  },

  onGetDocument: function (data, tokenizedData) {
    const $deferred = new $.Deferred(),
      self = this;

    const response = http.post(self.config.url, dataUtils.cleanse(tokenizedData || data), { showOverlay: false });
    this.requests.push(response);

    response
      .done((results) => {
        if (results) {
          $deferred.resolve(results.Result);
        }
      })
      .fail($deferred.reject);

    return $deferred.promise();
  },

  onDispose: function () {
    this.requests.forEach((request) => {
      if (request.state() === "pending") {
        request.abort();
      }
    });
  }
});

module.exports = DynamicPrintDocumentProvider;
plexExport("printing.documentProviders.DynamicPrintDocumentProvider", DynamicPrintDocumentProvider);
