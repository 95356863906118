const ko = require("knockout");
const logger = require("../Core/plex-logger");
const jsUtils = require("../Utilities/plex-utils-js");

const defaultFormat = "LastName, FirstName";

module.exports = {
  format: jsUtils.memoize((format, firstName, middleName, lastName) => {
    /// <summary>
    /// Generates a formatted person name string from the specified components.
    /// </summary>
    /// <param name="format">The person name format.</param>
    /// <param name="firstName">The person's first name.</param>
    /// <param name="middleName">The person's middle name.</param>
    /// <param name="lastName">The person's last name.</param>

    const firstNameClean = ko.unwrap(firstName) || "";
    const middleNameClean = ko.unwrap(middleName) || "";
    const lastNameClean = ko.unwrap(lastName) || "";

    if (firstNameClean === "" && lastNameClean === "") {
      return "";
    }

    const formatClean = format || "";
    let formattedName = formatClean;

    if (formattedName.trim() === "") {
      logger.warn("No person name format defined. Using default of '" + defaultFormat + "'");
      formattedName = defaultFormat;
    }

    formattedName = formattedName.replace("FirstName", firstNameClean);
    formattedName = formattedName.replace("MiddleName", middleNameClean);
    formattedName = formattedName.replace("LastName", lastNameClean);

    formattedName = formattedName.replace("FirstInitial", firstNameClean === "" ? "" : firstNameClean.substring(0, 1));
    formattedName = formattedName.replace(
      "MiddleInitial",
      middleNameClean === "" ? "" : middleNameClean.substring(0, 1)
    );
    formattedName = formattedName.replace("LastInitial", lastNameClean === "" ? "" : lastNameClean.substring(0, 1));

    const trimmedFullName = formattedName.trim();
    return trimmedFullName === "," ? "" : trimmedFullName;
  })
};
