ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const domUtils = require("../Utilities/plex-utils-dom");
const GridController = require("../Controls/plex-controller-grid");
const onReady = require("../Controls/plex-handler-page").onReady;
const HandlerFactory = require("./Handlers/plex-draggables-handler-factory");
const droppables = require("./plex-droppables");
const plexImport = require("../../global-import");

const api = plexImport("draggables");

const selectionModes = {
  defaultMode: 0,
  selected: 1,
  group: 2
};

const Draggable = function (config, controller, el) {
  this.config = config;
  this.controller = controller;
  this.$element = $(el);
  this.$draggables = null;
  this.$dragging = null;
  this.data = [];
};

Draggable.prototype = {
  constructor: Draggable,

  init: function () {
    const self = this;

    self._resolveHandlerType();
    self._initDraggingContainer();

    const handler = HandlerFactory.create(self.handlerType, self);

    handler.subscribeToDomChanges(() => {
      self.$draggables = handler.getDraggables();
      self.$draggables.draggable({
        helper: handler.getHelper(),
        cursor: self.config.operation,
        appendTo: self.$appendTo,
        revert: true,
        scroll: true,
        delay: 100,
        opacity: 0.8,
        zIndex: 1000,
        start: function (event, _ui) {
          self.$dragging = $(event.currentTarget);
          self.$dragging.draggable("option", "revert", true);
          droppables.dropSource = self;

          self.$element.trigger("dragStart.plex.drag-and-drop", self._getEventContext());
        },
        drag: function (_event, _ui) {
          // noop
        },
        stop: function () {
          self.$element.trigger("dragStop.plex.drag-and-drop", self._getEventContext());
        }
      });

      self.$draggables.addClass("plex-draggable-item");
    });
  },

  _getEventContext: function () {
    return {
      $dragging: this.config.draggableItem === "self" ? this.$element : this.$dragging,
      data: this.data
    };
  },

  _resolveHandlerType: function () {
    /// <summary>
    /// Resolve the handler type. We might consider moving this configuration to the server
    /// in future, but for now we are ok.
    /// </summary>
    const self = this;
    if (this.controller instanceof GridController) {
      self.handlerType = "grid";
      return;
    }

    self.handlerType = "default";
  },

  _initDraggingContainer: function () {
    /// <summary> Initializes the container that jquery ui is going to append the clone to.</summary>

    let $appendTo;
    const self = this;

    if (domUtils.isInForm(self.$element[0])) {
      $appendTo = self.$element.closest(".plex-form-content");
      if ($appendTo.css("overflow-y") === "hidden") {
        $appendTo = self.$element.closest(".plex-form-content-wrapper");
      }

      // if not in composite list
      if ($appendTo.closest(".plex-element-list").length === 0) {
        $appendTo.css("position", "absolute");
      }
    } else if (domUtils.isInDialog(self.$element[0])) {
      $appendTo = self.$element.closest(".modal-dialog");
    } else if (self.handlerType === "grid") {
      $appendTo = self.$element;
    } else {
      const absParents = self.$element.parents().filter(function () {
        return $(this).css("position") === "absolute";
      });

      $appendTo = absParents.length > 0 ? absParents[0] : $(document.body);
    }

    self.$appendTo = $appendTo;
  }
};

api.Draggable = Draggable;
api.selectionModes = selectionModes;

api.setupDraggable = function (config, controller, el) {
  const draggable = new Draggable(config, controller, el);
  onReady(() => {
    draggable.init();
  });
};

module.exports = api;
