ï»¿const ko = require("knockout");
const jsUtils = require("../Utilities/plex-utils-js");
const stringUtils = require("../Utilities/plex-utils-strings");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const plexExport = require("../../global-export");
const RecordElementController = require("../Grid/plex-grid-controller-element");

const Serializer = function () {
  // constructor
};

Serializer.prototype = {
  constructor: Serializer,

  serialize: function (data, progressCallback) {
    const self = this;
    const serialized = [];
    const delimiter = self.recordDelimiter || "";

    const chunker = arrayUtils.chunk(data).each((record, i) => {
      serialized.push(self.serializeRecord(self.transformRecord(record, i)));
      progressCallback(data, serialized);
    });

    return chunker.start().then(() => self.serializeBegin(data) + serialized.join(delimiter) + self.serializeEnd(data));
  },

  serializeBegin: function (_data) {
    // can be overriden
    return "";
  },

  serializeEnd: function (_data) {
    // can be overriden
    return "";
  },

  serializeRecord: function (_record) {
    // to be replaced by inheritors
  },

  transformRecord: function (record, index) {
    let headerName;
    const self = this;
    const transformed = [];
    const columns = this.getExportColumns(record, index);

    // 1. remove html from the formatted value that might be coming from content feature result (stringUtils.removeHtml)
    // 2. formatted value might be html encode so decode it here for exporting (stringUtils.unescapeHtml)
    // 3. processed composite element and getting formatted value

    columns.forEach((column, colIndex) => {
      headerName = self.getColumnHeaderName(column);
      transformed.push({
        header: stringUtils.unescapeHtml(stringUtils.replaceHtmlLineBreaks(headerName)),
        value: self.getColumnValue(column, record, index, colIndex),
        headerExists: transformed.filter((c) => {
          return c.header === headerName;
        }).length
      });
    });

    // Delete $$controller, so memory is released
    if ("$$controller" in record) {
      if ("dispose" in record.$$controller) {
        record.$$controller.dispose();
      }

      delete record.$$controller;
    }

    return transformed;
  },

  getColumnValue: function (column, record, index, colIndex) {
    if (typeof column.valueProvider.getExportValue === "function") {
      return column.valueProvider.getExportValue(column.valueProvider.getRecord(record), index);
    }

    let value;
    if (column.elements && column.elements.length && column.elements[0].elements) {
      const processedElements = this.processCompositeElement(
        column,
        column.valueProvider.getRecord(record),
        index,
        colIndex
      );

      processedElements.forEach((el) => {
        this.evaluateInitialDisplay(el);
      });

      value = this.getElementColumnValue(processedElements);
    } else {
      value = column.valueProvider.getFormattedValue(column.valueProvider.getRecord(record), index);
    }

    value = stringUtils.unescapeHtml(stringUtils.removeHtml(value, { replaceLineBreaks: true }));

    if (this.isHtmlColumn(column)) {
      value = stringUtils.removeHtml(value);
    }

    return value;
  },

  getColumns: function () {
    const parent = this.parent.$grid || this.parent;
    return parent.columns.filter((col) => {
      return col.exportVisible || (col.exportVisible !== false && col.visible());
    });
  },

  getExportColumns: function (record, index) {
    const self = this;
    const parent = this.parent.$grid || this.parent;
    const exportColumns = [];

    parent.columns.forEach((col) => {
      if (col.exportVisible || (col.exportVisible !== false && col.visible())) {
        if (col.type === "Master") {
          exportColumns.push(self.getCurrentColumnForMasterColumn(col, record, index));
        } else {
          exportColumns.push(col);
        }
      }
    });

    return exportColumns;
  },

  getCurrentColumnForMasterColumn: function (masterColumn, record, index) {
    let layout;

    if (masterColumn && record) {
      for (let i = 0; i < masterColumn.columns.length; i++) {
        layout = masterColumn.columns[i];
        if (layout.evaluator(record, index)) {
          layout.column.headerName(masterColumn.headerName());
          return layout.column;
        }
      }
    }

    return masterColumn.defaultColumn;
  },

  getElementColumnValue: function (elements) {
    const self = this;
    const value = [];

    elements.forEach((el) => {
      self.getCompositeElementValue(el, value);
    });

    return value.join(" ");
  },

  getCompositeElementValue: function (element, value) {
    const self = this;

    if (element.initialDisplayValue) {
      value.push(element.initialDisplayValue);
    } else if (element.text) {
      value.push(ko.unwrap(element.text));
    } else if (element.boundValue) {
      value.push(ko.unwrap(element.boundValue));
    } else if (element.elementRows) {
      const rowValues = [];
      element.elementRows.forEach((row) => {
        const elementValues = [];
        self.getCompositeElementValue(row.options, elementValues);
        rowValues.push(elementValues.join(" "));
      });

      value.push(rowValues.join(","));
    } else if (element.elements) {
      element.elements.forEach((el) => {
        self.getCompositeElementValue(el, value);
      });
    }
  },

  getColumnHeaderName: function (column) {
    let parentHeader = "";
    if (column.parent && column.parent.headerName) {
      parentHeader = column.parent.headerName() + " ";
    }

    if (column.headerName) {
      return parentHeader + column.headerName();
    } else if (column.headerElement && column.headerElement.formattedValue) {
      return parentHeader + column.headerElement.formattedValue();
    }

    return parentHeader;
  },

  isHtmlColumn: function (column) {
    return (
      column.valueProvider.config.address === "HtmlColumnValueProvider" ||
      (column.features || []).some((feature) => feature.name === "HtmlDisplay")
    );
  },

  processCompositeElement: function (column, record, index, colIndex) {
    const ctrl = new RecordElementController(record, index, column, column.valueProvider.parent);
    return column.elements.map((config) => {
      return ctrl.initElement(config, column.valueProvider.features, colIndex, index);
    });
  },

  evaluateInitialDisplay: function (el) {
    if (!el) {
      return;
    }

    if (el.displayValue) {
      el.displayValue();
    } else if (typeof el.initialDisplayValue === "undefined" && ko.isObservable(el.formattedValue)) {
      el.initialDisplayValue = el.formattedValue();
    }

    if (el.elements && el.elements.length > 0) {
      el.elements.forEach(this.evaluateInitialDisplay, this);
    }
  },

  serializeToBlob: function (data, progressCallback) {
    const self = this;
    const content = self.serialize(data, progressCallback);

    return content.then((result) => new Blob([result], { type: self.mimeType }));
  }
};

jsUtils.makeExtendable(Serializer);

module.exports = Serializer;
plexExport("exporter.serializers.Serializer", Serializer);
