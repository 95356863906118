ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");
const jsonUtils = require("../Utilities/plex-utils-json");
const ControllerFactory = require("./plex-controller-factory");
const onReady = require("./plex-handler-page").onReady;
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

function formatAddressLines(addressLines) {
  const lines = addressLines.map((line) => {
    return line.text;
  });

  return lines.join("\n");
}

function FormattedAddressController(config, model) {
  this.config = config;
  this.model = model;
  this.init();
}

FormattedAddressController.prototype = {
  constructor: FormattedAddressController,
  init: function () {
    const self = this;

    this.address = dataUtils.getObservable(this.model, this.config.propertyName);
    if (this.config.propertyName) {
      dataUtils.trackObject(this.model[this.config.propertyName]);
    }

    this.international = this.config.international || false;
    this.initialized = false;
    this._disposables = [];

    this.addressLines = ko.observableArray();
    this._disposables.push(
      this.addressLines.subscribe((value) => {
        if (!self.initialized) {
          self.config.initialDisplayValue = formatAddressLines(value);
          self.initialized = true;
        }
      })
    );

    // configure bound value
    this.config.boundValue = ko.computed(() => {
      return formatAddressLines(self.addressLines());
    });

    if (this.address) {
      // delay subscription of properties until they have been made observable
      onReady(() => {
        const addressTokens = ko
          .computed(() => {
            // this will create a dependency on each property
            return ko.toJS(self.address());
          })
          .extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 100 } });

        // track dependencies
        self._disposables.push(addressTokens.subscribe(self._load, self));

        self._load();
      });

      return;
    }

    this.addressLines(this.config.addressLines || this.config.lines);
  },

  dispose: function () {
    let subscription;
    while ((subscription = this._disposables.pop())) {
      subscription.dispose();
    }
  },

  _load: function () {
    const self = this;
    const url = nav.buildUrl("/Platform/FormattedAddress/Format", { International: this.international });
    const data = dataUtils.cleanse(this.address());

    nav.post(url, data, { showOverlay: false }).done((address) => {
      self.addressLines(jsonUtils.toCamelCaseKeys(address));
    });
  },

  getState: function () {
    const self = this;
    const state = {};
    if (self.address) {
      Object.keys(self.address()).forEach((key) => {
        state[key] = self.address()[key];
      });
    }

    return state;
  },

  restoreState: function (state) {
    ko.track(state);
    dataUtils.setValue(this.model, this.config.propertyName, state);
  }
};

FormattedAddressController.create = function (config, model) {
  return new FormattedAddressController(config, model);
};

ControllerFactory.register("Elements/_FormattedAddress", FormattedAddressController);

module.exports = FormattedAddressController;
plexExport("FormattedAddressController", FormattedAddressController);
