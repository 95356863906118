ï»¿const nav = require("../Core/plex-navigate");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ComingSoon = Action.extend({
  onExecute: function () {
    nav.featureComingSoon();
  }
});

module.exports = ComingSoon;
plexExport("actions.ComingSoon", ComingSoon);
