const plexExport = require("../../global-export");

const topics = {
  document: {
    name: "notifications",
    notifications: {
      created: "document-created"
    }
  }
};

module.exports = topics;
plexExport("clientNotifications.ClientNotificationsTopics", topics);
