ï»¿const CellPrintWriter = require("./plex-grid-print-writer-cell");
const expressions = require("../../../Expressions/plex-expressions-compiler");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");

const MasterCellPrintWriter = CellPrintWriter.extend({
  init: function (_parent, _options, _column) {
    const self = this;
    this._base.apply(this, arguments);

    this.columns = (this.column.columns || []).slice(0);
    if (this.column.defaultColumn) {
      this.columns.push({ column: this.column.defaultColumn });
    }

    // make sure to account for features that are applied to child columns
    this.columns.forEach((config) => {
      const child = config.column;
      if (child.features && child.features.length > 0) {
        child.featureProcessor = new FeatureProcessor(child.features, child, self.parent);
      }

      // the default column will not have an expression so just return true
      config.evaluator = config.expression
        ? expressions.compile(config.expression)
        : function () {
            return true;
          };
    });

    // preserve the reference to the master column
    // we will be swapping this out for each row
    this.masterColumn = this.column;
  },

  prePrint: function (record, index, _data, _colIndex, _groupIndex, _group) {
    this.column = this.getCurrentColumn(record, index);
    this.featureProcessor = this.column.featureProcessor;
    return this._base.apply(this, arguments);
  },

  getCurrentColumn: function (record, index) {
    if (record) {
      let i = 0;
      const ln = this.columns.length;
      let layout;

      for (; i < ln; i++) {
        layout = this.columns[i];
        if (layout.evaluator(record, index)) {
          return layout.column;
        }
      }
    }

    // should never get here
    return this.masterColumn.defaultColumn;
  }
});

module.exports = MasterCellPrintWriter;
plexExport("grid.writers.MasterCellPrintWriter", MasterCellPrintWriter);
