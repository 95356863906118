ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");

const defaultOptions = {
  enabled: true
};

function KeepFocus(el, options) {
  this.$element = $(el);
  options = $.extend({}, options, defaultOptions);

  this.toggle(options.enabled);
}

KeepFocus.prototype = {
  constructor: KeepFocus,

  toggle: function (enable) {
    this[enable ? "on" : "off"]();
  },

  on: function () {
    if (this.enabled) {
      return;
    }

    const $firstElement = this.$element.find(":focusable:first");
    const $lastElement = this.$element.find(":focusable:last");

    this.$element.on("keydown.plex.keepFocus", (e) => {
      if (e.which === 9) {
        if (e.target === $lastElement[0] && !e.shiftKey) {
          e.preventDefault();
          $firstElement.focus();
        } else if (e.target === $firstElement[0] && e.shiftKey) {
          e.preventDefault();
          $lastElement.focus();
        }
      }
    });

    this.enabled = true;
  },

  off: function () {
    if (!this.enabled) {
      return;
    }

    this.$element.off("keydown.plex.keepFocus");
    this.enabled = false;
  }
};

$.fn.keepFocus = function (options) {
  return this.each(function () {
    const $this = $(this);
    let data = $this.data("keepFocus");

    if (!data) {
      $this.data("keepFocus", (data = new KeepFocus(this, options)));
    }

    if (options === "string" && options in data) {
      data[options]();
    }
  });
};

$.fn.keepFocus.Constructor = KeepFocus;

$(document).on("keydown.plex.keepFocus", "[data-keep-focus]:not(.focus-blocking)", (e) => {
  if (e.which === 9) {
    const $el = $(e.currentTarget);
    const plugin = $el.data("keepInput");

    // go ahead and turn on
    if (!plugin) {
      $el.addClass("focus-blocking").keepFocus();
    }
  }
});
