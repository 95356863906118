ï»¿const xlsx = require("xlsx");
const JSZip = require("jszip");
const excelUtils = require("./plex-exporter-utils-excel");
const stringUtils = require("../Utilities/plex-utils-strings");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const Serializer = require("./plex-exporter-serializers");
const plexExport = require("../../global-export");

const ExcelSerializer = Serializer.extend({
  mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  extension: ".xlsx",
  altExtension: ".xls",

  writeOptions: {
    bookType: "xlsx",
    type: "binary"
  },

  addCustomFormat: function (workbook, formatCode) {
    for (let i = 0; i < 0x188; ++i) {
      if (workbook.SSF[i] === formatCode) {
        return;
      }
    }

    for (let i = 0xa4; i < 0x188; ++i) {
      if (!workbook.SSF[i]) {
        workbook.SSF[i] = formatCode;
        return;
      }
    }
  },

  serialize: function (data) {
    const self = this;

    const columns = self.getColumns();
    const sheetName = "Sheet1";

    self.workbook = {
      SheetNames: [],
      Sheets: {},
      SSF: xlsx.SSF.get_table()
    };

    self.worksheet = {};

    if (self.parent.exportHeader !== false) {
      columns.forEach((column, colIndex) => {
        const value = stringUtils.unescapeHtml(stringUtils.replaceHtmlLineBreaks(self.getColumnHeaderName(column)));
        if (value !== null) {
          const cell = {
            v: value,
            t: "s"
          };

          const cellRef = xlsx.utils.encode_cell({ c: colIndex, r: 0 });
          self.worksheet[cellRef] = cell;
        }
      });
    }

    // The worksheet range. Cells that are assigned outside of the range are not processed.
    // s - start, e - end, c - column, r - row
    const range = {
      s: {
        c: 0,
        r: 0
      },
      e: {
        c: columns.length,
        r: data.length + 1
      }
    };

    const chunker = arrayUtils.chunk(data).each((record, i) => self.transformRecord(record, i));

    return chunker.start().then(() => {
      self.worksheet["!ref"] = xlsx.utils.encode_range(range);

      self.workbook.SheetNames.push(sheetName);
      self.workbook.Sheets[sheetName] = self.worksheet;

      return xlsx.write(self.workbook, self.writeOptions);
    });
  },

  transformRecord: function (record, index) {
    const self = this;
    let cellValue;
    const exportColumns = self.getExportColumns(record, index);

    exportColumns.forEach((column, colIndex) => {
      if (column.elements && column.elements.length && column.elements[0].elements) {
        const processedElements = self.processCompositeElement(
          column,
          column.valueProvider.getRecord(record),
          index,
          colIndex
        );
        processedElements.forEach((el) => {
          self.evaluateInitialDisplay(el);
        });

        const elementValue = self.getElementColumnValue(processedElements);
        cellValue = {
          value: stringUtils.unescapeHtml(stringUtils.removeHtml(elementValue, { replaceLineBreaks: true })),
          type: "s"
        };
      } else {
        cellValue = excelUtils.getCellValue(record, index, column);
      }

      if (cellValue.value !== null) {
        if (self.isHtmlColumn(column)) {
          cellValue.value = stringUtils.removeHtml(cellValue.value);
        }

        if (cellValue.formatCode) {
          self.addCustomFormat(self.workbook, cellValue.formatCode);
        }

        const cell = {
          v: cellValue.value,
          t: cellValue.type,
          z: cellValue.formatCode
        };

        const cellRef = xlsx.utils.encode_cell({ c: colIndex, r: index + 1 });
        self.worksheet[cellRef] = cell;
      }
    });

    // Delete $$controller, so memory is released
    if ("$$controller" in record) {
      if ("dispose" in record.$$controller) {
        record.$$controller.dispose();
      }

      delete record.$$controller;
    }
  },

  serializeToBlob: function (data) {
    const self = this;
    const content = self.serialize(data);

    // the content is a base 64 string - we need to convert it to a blob
    return content.then((result) => {
      if (self.writeOptions.type === "base64") {
        return excelUtils.base64ToBlob(result, self.mimeType);
      } else if (self.writeOptions.type === "binary") {
        return JSZip.utils.string2Blob(result);
      }
      return result;
    });
  }
});

module.exports = ExcelSerializer;
plexExport("exporter.serializers.xlsx", ExcelSerializer);
