ï»¿const stringUtils = require("../Utilities/plex-utils-strings");
const Serializer = require("./plex-exporter-serializers");
const plexExport = require("../../global-export");

const HtmlSerializer = Serializer.extend({
  extension: ".html",
  mimeType: "text/html",

  serializeBegin: function (data) {
    const row = [];

    row.push('<!DOCTYPE html><html><head><meta charset="utf-8" /><title>');
    row.push(document.title);
    row.push("</title></head><body>");
    row.push(this.serializeHeader(data));

    return row.join("");
  },

  serializeHeader: function (data) {
    if (this.parent.exportHeader === false) {
      return "<table><tbody>";
    }

    const row = ["<table><thead><tr>"];
    const record = this.transformRecord(data[0]);

    record.forEach((col) => {
      row.push("<th>");
      row.push(escapeValue(col.header));
      row.push("</th>");
    });

    row.push("</tr></thead><tbody>");
    return row.join("");
  },

  serializeEnd: function (_data) {
    return "</tbody></table></body></html>";
  },

  serializeRecord: function (record) {
    const row = ["<tr>"];

    record.forEach((col) => {
      row.push("<td>");
      row.push(col.value);
      row.push("</td>");
    });

    row.push("</tr>");
    return row.join("");
  },

  getColumnValue: function (column, record, index, _colIndex) {
    if (this.isHtmlColumn(column)) {
      return stringUtils.unescapeHtml(
        column.valueProvider.getFormattedValue(column.valueProvider.getRecord(record), index)
      );
    }

    return escapeValue(this._base.apply(this, arguments));
  }
});

function escapeValue(value) {
  if (typeof value === "string") {
    return stringUtils.escapeHtml(value).replace(/\n/g, "<br />");
  }

  return value;
}

module.exports = HtmlSerializer;
plexExport("exporter.serializers.html", HtmlSerializer);
