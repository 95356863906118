ï»¿const $ = require("jquery");
const ko = require("knockout");
const actionHandler = require("../plex-handler-action");
const nav = require("../../Core/plex-navigate");
const plexExport = require("../../../global-export");
const pubsub = require("../../Core/plex-pubsub");
const SideMenuControllerBase = require("./plex-controller-side-menu-base");
const SidetabsMenuUrlTokenManager = require("./plex-sidetabs-menu-url-token-manager");

const SidetabsMenuController = SideMenuControllerBase.extend({
  onInit: function (config) {
    const self = this;

    this.tokenManager = new SidetabsMenuUrlTokenManager(config.currentTokens);
    this._loadSidetabsItems(config);

    this.addDisposable(
      pubsub.subscribe(
        "validationCompleted",
        (invalidElements) => {
          const item = self.sideMenuItems().filter((e) => e.active())[0];

          if (item) {
            if (Object.keys(invalidElements).length === 0) {
              item.hasError(false);
            } else {
              item.hasError(true);
            }
          }
        },
        this
      )
    );
  },

  initializeClickAction: function (item) {
    if (item.action) {
      const data = this.tokenManager.buildMenuData(item);
      const tokens = this.tokenManager.buildMenuTokens(item.action, data);

      item.action.tokens = [...tokens, ...item.action.tokens];
      item.action.data = data;

      actionHandler.initAction(item.action, this);
    }
  },

  onItemSelected: function (item) {
    pubsub.publish("sidetabsMenuSelectionChanged", {
      selectedItem: { iconName: item.iconClassName(), title: item.text() },
      submenuKey: this.tokenManager.urlData.submenuKey
    });
  },

  executeClickAction: function (item, e) {
    if (item.action && !item.active()) {
      actionHandler.executeAction(item.action, null, e);
    }
  },

  getSortOrderPath: function () {
    return "/Platform/SubmenuActions/UpdateSubmenuActionUserSortOrder";
  },

  getSortOrderData: function (arg) {
    return {
      SubmenuActionKey: arg.item.data.submenuActionKey,
      SubmenuCustomerActionKey: arg.item.data.submenuCustomerActionKey,
      SubmenuKey: arg.item.data.submenuKey,
      NewSortOrder: arg.targetIndex + 1
    };
  },

  isItemVisible: function (item) {
    if (item.action && !ko.unwrap(item.action.authorized)) {
      return false;
    }

    return ko.unwrap(item.visible) !== false;
  },

  getHref: function (item) {
    return ko.pureComputed(() => {
      let href;

      if (typeof item.action.href === "function") {
        href = item.action.href(item.action.data);
      } else {
        href = item.action.href;
      }

      return href;
    });
  },

  getCollapsedState: function (menuState) {
    return menuState.sidetabs;
  },

  createMenuState: function () {
    return { sidetabs: this.collapsed() };
  },

  updateMenuState: function (sideMenuState) {
    sideMenuState.sidetabs = this.collapsed();
  },

  findMenuItem: function (address) {
    const self = this;

    if (!address) {
      return null;
    }

    const urlData = SidetabsMenuUrlTokenManager.parseUrl(address);

    if (!urlData || urlData.submenuKey != null) {
      return null;
    }

    if (urlData.actionKey == null) {
      return self._findMenuItemByComparer(
        (itemUrlData) => itemUrlData.url != null && itemUrlData.url.toLowerCase() === urlData.url.toLowerCase()
      );
    } else {
      return self._findMenuItemByComparer(
        (itemUrlData) => itemUrlData.actionKey != null && itemUrlData.actionKey === urlData.actionKey
      );
    }
  },

  onDisplayed: function () {
    pubsub.publish("sidetabsMenuLoaded");
  },

  _findMenuItemByComparer: function (funcComparer) {
    const sideMenuItems = ko.unwrap(this.sideMenuItems);

    for (let i = 0; i < sideMenuItems.length; i++) {
      const menuItem = sideMenuItems[i];

      if (menuItem.config.urlData) {
        if (funcComparer(menuItem.config.urlData)) {
          return menuItem;
        }
      }
    }

    return null;
  },

  _loadSidetabsItems: function (config) {
    const self = this;
    let gettingItems = $.when([]);

    if (config.items && config.items.length) {
      gettingItems = $.when(config.items);
    } else if (config.filterCloudActionUrl) {
      const filterUrl = nav.buildUrl(config.filterCloudActionUrl.url);
      const postData = {
        submenuKey: self.tokenManager.urlData.submenuKey,
        data: self.tokenManager.buildMenuData(),
        filterActionKey: self.tokenManager.urlData.submenuLoadItemsActionKey
      };

      gettingItems = nav.post(filterUrl, postData);
    }

    gettingItems
      .done((result) => {
        self._enhanceItemsConfig(result);

        if (config.filterCloudActionUrl) {
          const activeItem = result.filter((e) => e.isActive)[0];

          if (activeItem && activeItem.action) {
            self.tokenManager.updateCurrentTokens(activeItem.action.tokens);
          }
        }

        self._addSidetabsItems(result, config);
      })
      .fail((error) => {
        $.when().reject(error);
      });
  },

  _addSidetabsItems: function (items, config) {
    this.addSideMenuItems(
      items.map((itemConfig) => {
        return {
          iconClassName: itemConfig.iconClassName,
          visible: itemConfig.visible,
          text: itemConfig.text,
          active: itemConfig.isActive,
          action: itemConfig.action,
          urlData: itemConfig.urlData,
          data: {
            submenuActionKey: itemConfig.submenuActionKey,
            submenuCustomerActionKey: itemConfig.submenuCustomerActionKey,
            submenuKey: config.submenuKey
          }
        };
      })
    );

    if (this.sideMenuItems().length) {
      this.visible(true);
    }
  },

  _enhanceItemsConfig: function (itemsConfig) {
    const self = this;
    let activeDetermined = false;

    for (let i = 0; i < itemsConfig.length; i++) {
      const itemConfig = itemsConfig[i];

      $.extend(itemConfig, { isActive: false, urlData: null });

      if (itemConfig.action && itemConfig.action.type === "Redirect" && itemConfig.action.address) {
        itemConfig.urlData = SidetabsMenuUrlTokenManager.parseUrl(itemConfig.action.address);
      }

      if (activeDetermined !== true) {
        if (self.tokenManager.isCurrentItem(itemConfig)) {
          itemConfig.isActive = true;

          activeDetermined = itemConfig.isActive;
        }
      }
    }
  }
});

SidetabsMenuController.create = function () {
  return new SidetabsMenuController();
};

SidetabsMenuController.find = function () {
  /// <summary>Finds the side tabs menu controller.</summary>
  /// <returns type="Object">The side tabs menu controller.</returns>

  const $menu = $(".plex-sidetabs-menu");

  if ($menu.length) {
    return ko.dataFor($menu[0]);
  }

  return null;
};

module.exports = SidetabsMenuController;
plexExport("SidetabsMenuController", SidetabsMenuController);
