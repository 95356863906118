ï»¿const stringUtils = require("../Utilities/plex-utils-strings");
const logger = require("../Core/plex-logger");
const repository = require("../Controls/plex-model-repository");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

const UniqueCountToken = Token.extend({
  getValue: function (records, _i) {
    const prop = this.getAttr("propertyName", arguments);
    const sourceId = this.getAttr("sourceId", arguments);
    const caseSensitive = this.getAttr("caseSensitive", arguments) === "true" || false;
    const values = [];

    if (!prop) {
      return null;
    }

    if (sourceId) {
      records = repository.get(sourceId);
    }

    if (!records || records().length <= 0) {
      return values.length;
    }

    if (!(prop in records()[0])) {
      logger.error("Unable to find property: " + prop);
      return values.length;
    }

    records.forEach((rec) => {
      let value = rec[prop];
      if (!caseSensitive && value && stringUtils.isString(value)) {
        value = value.toLowerCase();
      }

      if (values.indexOf(value) === -1) {
        values.push(value);
      }
    });

    return values.length;
  }
});

module.exports = UniqueCountToken;
plexExport("tokens.countunique", UniqueCountToken);
