/* eslint-disable no-invalid-this */
const plexExport = require("../../global-export");

function DateRangeFilter(startDateFilter, endDateFilter) {
  this.getStartDate = startDateFilter;
  this.getEndDate = endDateFilter;
}

module.exports = DateRangeFilter;
plexExport("dates.DateRangeFilter", DateRangeFilter);
