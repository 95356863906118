ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const http = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

const LoadHtmlAction = Action.extend({
  onExecute: function (data) {
    return http.loadHtml($("#" + this.targetId), this.address, dataUtils.cleanse(data), { method: this.httpMethod });
  }
});

module.exports = LoadHtmlAction;
plexExport("actions.LoadHtml", LoadHtmlAction);
