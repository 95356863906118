ï»¿const $ = require("jquery");
const culture = require("../Globalization/plex-culture-datetime");
const plexExport = require("../../global-export");

const api = {};

api.SECOND_MS = 1000;
api.MINUTE_MS = api.SECOND_MS * 60;
api.HOUR_MS = api.MINUTE_MS * 60;
api.DAY_MS = api.HOUR_MS * 24;
api.dateTimePrecisions = {
  minutes: "minutes",
  seconds: "seconds",
  milliseconds: "milliseconds"
};

api.compareMonths = function (firstDate, secondDate) {
  return firstDate.getMonth() - secondDate.getMonth() + 12 * (firstDate.getFullYear() - secondDate.getFullYear());
};

api.inRange = function (startDate, endDate, date) {
  if (startDate == null || endDate == null || date == null) {
    return false;
  }

  // allow time to be passed in
  const startEpoch = typeof startDate === "number" ? startDate : startDate.getTime();
  const endEpoch = typeof endDate === "number" ? endDate : endDate.getTime();
  const dateEpoch = typeof date === "number" ? date : date.getTime();

  return startEpoch <= dateEpoch && dateEpoch <= endEpoch;
};

api.compareDays = function (firstDate, secondDate) {
  const normalizedFirstDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const normalizedSecondDate = new Date(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());
  return (normalizedFirstDate.getTime() - normalizedSecondDate.getTime()) / api.DAY_MS;
};

api.offsetBeginDay = function (date) {
  // Note: set milliseconds to zero here or C# ADO code will round it to the next day.
  // This can have unintended consequences depending on what the sproc does with the value
  return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
};

api.offsetEndDay = function (date, options) {
  options = $.extend(
    { secondaryDateAdjust: true, enableTime: false, dateTimePrecision: api.dateTimePrecisions.minutes },
    options
  );

  const dateAdjust = options.secondaryDateAdjust;
  const enableTime = options.enableTime;
  const precision = options.dateTimePrecision;

  // Note:  When user selected 10/9/2018 as an end date.
  // - 10/9/2018 - if secondaryDateAdjust = false
  //     SPROC usually has date adjustment Invoice_Date < DATEADD(D, 1, @Invoice_Date_End)
  // - 10/10/2018 - if secondaryDateAdjust = true
  //     SPROC usually has Invoice_Date < @Invoice_Date_End

  if (dateAdjust) {
    return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0).getTime();
  }

  if (enableTime) {
    if (precision === api.dateTimePrecisions.seconds) {
      return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0).getTime();
    } else if (precision === api.dateTimePrecisions.milliseconds) {
      return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 997).getTime();
    }

    return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0, 0).getTime();
  }

  return new date.constructor(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
};

api.addDays = function (date, numDays) {
  const newDate = new date.constructor(date.getTime());
  return newDate.setDate(newDate.getDate() + numDays);
};

api.subtractDays = function (date, numDays) {
  return api.addDays(date, -1 * numDays);
};

api.subtractMinutes = function (date, numMinutes) {
  return api.addMinutes(date, -1 * numMinutes);
};

api.addHours = function (date, numHours) {
  const newDate = new date.constructor(date.getTime());
  return newDate.setHours(newDate.getHours() + numHours);
};

api.addMinutes = function (date, numMinutes) {
  const newDate = new date.constructor(date.getTime());
  return newDate.setMinutes(newDate.getMinutes() + numMinutes);
};

api.addSeconds = function (date, numSeconds) {
  const newDate = new date.constructor(date.getTime());
  return newDate.setSeconds(newDate.getSeconds() + numSeconds);
};

api.addMilliseconds = function (date, numMilliseconds) {
  const newDate = new date.constructor(date.getTime());
  return newDate.setMilliseconds(newDate.getMilliseconds() + numMilliseconds);
};

api.getWeekStart = function (options) {
  if (options && $.isNumeric(options.weekStart)) {
    return options.weekStart;
  }

  return culture.firstDay();
};

api.getNextSaturday = function (date) {
  const saturday = new date.constructor(api.offsetBeginDay(date));
  saturday.setDate(saturday.getDate() - ((saturday.getDay() + 1) % 7));
  return api.addDays(saturday, 7);
};

api.getNextSunday = function (date) {
  const sunday = new date.constructor(api.offsetBeginDay(date));
  sunday.setDate(sunday.getDate() - (sunday.getDay() % 7));
  return api.addDays(sunday, 7);
};

api.getFirstDayOfWeek = function (date, options) {
  const weekStart = api.getWeekStart(options);
  const daysFromWeekStart = (7 + date.getDay() - weekStart) % 7;
  return api.subtractDays(date, daysFromWeekStart);
};

api.getLastDayOfWeek = function (date, options) {
  const weekStart = api.getWeekStart(options);
  const daysFromWeekEnd = 6 - ((7 + date.getDay() - weekStart) % 7);
  return api.addDays(date, daysFromWeekEnd);
};

api.getLastDayOfMonth = function (date) {
  return new date.constructor(date.getFullYear(), date.getMonth() + 1, 0).getTime();
};

api.getLastDayOfYear = function (date) {
  return new date.constructor(date.getFullYear() + 1, 0, 0).getTime();
};

api.separateDates = function (dateString) {
  let primaryDateString, secondaryDateString;

  if (!dateString) {
    return [primaryDateString, secondaryDateString];
  }

  const hyphenMatches = dateString.match(/-/g);

  if (hyphenMatches && hyphenMatches.length > 0 && hyphenMatches.length % 2 === 1) {
    const mediumIndex = Math.floor(hyphenMatches.length / 2);

    let shift = 0;
    for (let i = 0; i <= mediumIndex; i++) {
      shift = dateString.indexOf("-", shift);
      shift++;
    }

    secondaryDateString = dateString.substring(shift);
    primaryDateString = dateString.substring(0, shift - 1);
  }

  return [primaryDateString, secondaryDateString];
};

module.exports = api;
Object.keys(api).forEach((key) => plexExport(`dates.${key}`, api[key]));
