const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const root = plexImport("draggables.handlers");

const HandlerFactory = {
  create: function (type, draggable) {
    if (!(type in root)) {
      throw new Error("Draggable handler not found: " + type);
    }

    const handler = new root[type]();
    handler.init(draggable);
    return handler;
  }
};

module.exports = HandlerFactory;
plexExport("draggables.handlers.HandlerFactory", HandlerFactory);
