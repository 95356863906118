ï»¿const ko = require("knockout");
const $ = require("jquery");

// http://jsfiddle.net/rniemeyer/BF5yW/
const DEFAULT_OPTIONS = {
  placement: "right",
  trigger: "hover"
};

ko.bindingHandlers.tooltip = {
  init: function (element, valueAccessor) {
    const local = ko.utils.unwrapObservable(valueAccessor());
    const options = {};

    ko.utils.extend(options, DEFAULT_OPTIONS);
    ko.utils.extend(options, local);

    $(element).tooltip(options);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(element).tooltip("destroy");
    });
  }
};
