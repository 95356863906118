ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const pubsub = require("../Core/plex-pubsub");
const pageHandler = require("../Controls/plex-handler-page");
const plexExport = require("../../global-export");
const glossary = require("../Globalization/plex-glossary-handler");

let GotoControllers = {};

function getStartRecord(pageSize, page) {
  return pageSize * (page - 1);
}

// disabled may not be an observable yet
function disableMenuItem(menuItem, disable) {
  if (ko.isObservable(menuItem.action.disabled)) {
    return menuItem.action.disabled(disable);
  }

  menuItem.action.disabled = disable;
  return menuItem.action.disabled;
}

function setMenuItemIndexes(menuItem, pageDefault) {
  menuItem.action.startRecordValue = ko.computed(function () {
    const currentPage = this.currentPage();
    const pages = this.pages();
    const indexOffset = 3;
    const indexSize = 5;
    let goToPage = 0;

    if (currentPage <= indexOffset) {
      goToPage = pageDefault;
    } else if (currentPage > indexOffset && currentPage <= pages - indexSize) {
      goToPage = currentPage - indexOffset + pageDefault;
    } else if (currentPage > pages - indexSize) {
      goToPage = pages - indexSize + pageDefault;
    }

    const disableCriteria = this.disabled() || pages < pageDefault || currentPage === goToPage;
    disableMenuItem(menuItem, disableCriteria);

    const pageText = "Page " + goToPage;

    // Text might not be an observable yet
    if (ko.isObservable(menuItem.text)) {
      menuItem.text(pageText);
    } else {
      menuItem.text = pageText;
    }

    const localPromise = glossary.getCustomerWordAsync({ text: "Page {1}", tokens: [goToPage] });
    if (localPromise) {
      localPromise.then((text) => {
        let glosPageText = text;
        if (currentPage === goToPage) {
          glosPageText = ">" + text;
        }
        if (ko.isObservable(menuItem.text)) {
          menuItem.text(glosPageText);
        } else {
          menuItem.text = glosPageText;
        }
      });
    }

    return getStartRecord(this.pageSize, goToPage);
  }, this);
}

// Initilize the Menu Items
const menuItemInit = {
  first: function (menuItem) {
    menuItem.action.startRecordValue = ko.computed(function () {
      const disableCriteria = this.disabled() || this.currentPage() === 1;

      disableMenuItem(menuItem, disableCriteria);

      return 0;
    }, this);
  },

  previous: function (menuItem) {
    menuItem.action.startRecordValue = ko.computed(function () {
      const currentPage = this.currentPage();
      const previousPage = currentPage - 1;
      const disableCriteria = this.disabled() || currentPage === 1;

      disableMenuItem(menuItem, disableCriteria);

      return getStartRecord(this.pageSize, previousPage);
    }, this);
  },

  index1: function (menuItem) {
    setMenuItemIndexes.call(this, menuItem, 1);
  },

  index2: function (menuItem) {
    setMenuItemIndexes.call(this, menuItem, 2);
  },

  index3: function (menuItem) {
    setMenuItemIndexes.call(this, menuItem, 3);
  },

  index4: function (menuItem) {
    setMenuItemIndexes.call(this, menuItem, 4);
  },

  index5: function (menuItem) {
    setMenuItemIndexes.call(this, menuItem, 5);
  },

  next: function (menuItem) {
    menuItem.action.startRecordValue = ko.computed(function () {
      const currentPage = this.currentPage();
      const nextPage = currentPage + 1;
      const pages = this.pages();
      const disableCriteria = this.disabled() || currentPage === pages;

      disableMenuItem(menuItem, disableCriteria);

      return getStartRecord(this.pageSize, nextPage);
    }, this);
  },

  last: function (menuItem) {
    menuItem.action.startRecordValue = ko.computed(function () {
      const pages = this.pages();
      const currentPage = this.currentPage();
      const disableCriteria = this.disabled() || currentPage === pages;

      disableMenuItem(menuItem, disableCriteria);

      return getStartRecord(this.pageSize, this.pages());
    }, this);
  }
};

const GotoController = function (config) {
  this.config = config;
  this.init();

  if (config.parent) {
    GotoControllers[config.parent.id] = this;
  }
};

GotoController.prototype = {
  constructor: GotoController,

  init: function () {
    const self = this;
    self.config.gotoController = this;

    self.pageSize = this.config.pageSize;
    self.recordTotalProperty = this.config.recordTotalProperty;

    self._subscriptions = [];
    self.recordTotal = 0;
    self.pages = ko.observable(0);
    self.currentStartRow = ko.observable(0);
    self.gotoExecuting = false;
    self.actionBar = self.config.actionBar;
    self.filter = self.config.parent;
    self.gotoMenu = self.actionBar.elements[self.config.parentMenuId];
    self.menuItems = {};

    self.saveState = function () {
      // update history with new state
      const state = {
        currentStartRow: self.filter.data[self.config.startRecordProperty]
      };

      pageHandler.setState(self.config.parentMenuId, state);
    };

    self.restoreState = (function () {
      const state = pageHandler.restoreState(self.config.parentMenuId);

      if (state) {
        self.currentStartRow(state.currentStartRow);
        self.filter.data[self.config.startRecordProperty] = state.currentStartRow;
      }
    })();

    // Disable Goto if 0 pages or set to disabled
    const disabled = ko.observable(true);
    self.disabled = ko.computed({
      read: function () {
        return !self.pages() || disabled();
      },
      write: disabled
    });

    self.currentPage = ko.pureComputed(() => {
      return Math.floor(self.currentStartRow() / self.pageSize) + 1;
    });

    // Changing Filter invalidates paging data, so we disable and reset start row
    const filterWatcher = self.filter.data.$$dirtyFlag.isDirty.subscribe((isDirty) => {
      // We want to ignore changes made by gotoAction
      if (self.gotoExecuting) {
        return;
      }

      self.disabled(isDirty);

      // Resetting the startRecordProperty latches the dirtyFlag to True if on any page other than 1
      if (isDirty) {
        self.filter.data[self.config.startRecordProperty] = 0;
      } else {
        self.filter.data[self.config.startRecordProperty] = self.currentStartRow();
      }
    });

    self._subscriptions.push(filterWatcher);
    // Init Menu Items
    const actions = self.gotoMenu.subActions[0].actions;
    const actionLength = actions.length;
    // first two are first/previous
    // then up to 5 gotopage actions firstPageIndex=1
    // then next and last/actions endpageIndex=actionLength-2
    const endPageIndex = actionLength - 2;
    if (actionLength < 5) {
      for (let itemIndex = 0; itemIndex <= actionLength - 1; itemIndex++) {
        let index = "index";
        index += itemIndex + 1;
        menuItemInit[index].call(self, actions[itemIndex]);
        if (actions[itemIndex].id) {
          self.menuItems[actions[itemIndex].id] = actions[itemIndex];
        } else {
          self.menuItems[itemIndex] = actions[itemIndex];
        }
      }
    } else {
      for (let itemIndex = 0; itemIndex < actionLength; itemIndex++) {
        let skipItem = false;
        switch (itemIndex) {
          case 0:
            menuItemInit.first.call(self, actions[itemIndex]);
            break;
          case 1:
            menuItemInit.previous.call(self, actions[itemIndex]);
            break;
          default:
            if (itemIndex < endPageIndex) {
              if (itemIndex < 7) {
                let index = "index";
                index += itemIndex - 1;
                menuItemInit[index].call(self, actions[itemIndex]);
              } else {
                skipItem = true;
              }
            } else if (itemIndex === endPageIndex) {
              menuItemInit.next.call(self, actions[itemIndex]);
            } else {
              menuItemInit.last.call(self, actions[itemIndex]);
            }
            break;
        }
        if (!skipItem) {
          if (actions[itemIndex].id) {
            self.menuItems[actions[itemIndex].id] = actions[itemIndex];
          } else {
            self.menuItems[itemIndex] = actions[itemIndex];
          }
        }
      }
    }
    // Subscribe to the search event of the filter
    if (this.config.searchAction.actions.length > 0) {
      this._subscriptions.push(
        pubsub.subscribe("searched." + this.config.searchAction.actions[0].id, this.setupPageInfo, this)
      );
    }
  },

  // Get page data from the search result of the filter
  setupPageInfo: function (dataResult) {
    const result = dataResult.outputParameters || dataResult;
    this.recordTotal = result[this.recordTotalProperty] || 0;

    this.currentStartRow(this.filter.data[this.config.startRecordProperty] || 0);

    if (this.recordTotal > 0) {
      this.pages(Math.ceil(this.recordTotal / this.pageSize));
    } else {
      this.pages(0);
    }

    // Reset dirty flag because filter has a new start row
    this.filter.data.$$dirtyFlag.reset();
    this.gotoExecuting = false;
    this.disabled(false);
  },

  dispose: function () {
    let sub;
    while ((sub = this._subscriptions.pop())) {
      sub.dispose();
    }
  }
};

GotoController.create = function (config) {
  // Actions with the same parent should have the same GotoController
  if (config.parent && GotoControllers[config.parent.id]) {
    return GotoControllers[config.parent.id];
  }

  return new GotoController(config);
};

GotoController.dispose = function () {
  if (!$.isEmptyObject(GotoControllers)) {
    GotoControllers = {};
  }
};

module.exports = GotoController;
plexExport("goto", GotoController);
