ï»¿const bindingHandler = require("../plex-grid-binding-handler");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const CollapsibleFeature = Feature.extend({
  onExecute: function (_record, _index, _records, _rowConfig, _indexInGroup, _group) {
    const results = { css: [], style: {}, attr: {}, prependContent: null };

    results.attr["data-grid-expander"] = "1";

    return results;
  },

  _renderExpander: function (record) {
    return "<span class='plex-grid-expander'>" + bindingHandler.addExpander(record, "$$collapsed") + "</span>";
  }
});

module.exports = CollapsibleFeature;
plexExport("grid.features.Collapsible", CollapsibleFeature);
