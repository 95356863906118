const DefaultValueProvider = require("./plex-grid-value-providers");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const valueProviders = plexImport("grid.valueProviders");

const ValueProviderFactory = {
  create: function (column, parent) {
    let provider, config;
    const address = column.valueProvider && column.valueProvider.address;

    if (!address || !(address in valueProviders)) {
      // this will happen when a dropdown is converted to a picker.
      provider = new DefaultValueProvider(column);
    } else {
      config = column.valueProvider;
      provider = new valueProviders[address](column);
    }

    provider.column = column;
    provider.config = config || {};
    provider.parent = parent;
    provider.address = address;

    provider.init();
    return provider;
  }
};

module.exports = ValueProviderFactory;
plexExport("grid.valueProviders.ValueProviderFactory", ValueProviderFactory);
