ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const stringUtils = require("../Utilities/plex-utils-strings");
const jsUtils = require("../Utilities/plex-utils-js");
const domUtils = require("../Utilities/plex-utils-dom");
const expressionUtils = require("../Expressions/plex-expressions-compiler");
const dataUtils = require("../Utilities/plex-utils-data");
const TextQuery = require("../Core/plex-text-query");
const valueProviderFactory = require("../Grid/ValueProviders/plex-grid-value-providers-factory");
const bindingHandler = require("./plex-handler-bindings");
const ControllerFactory = require("./plex-controller-factory");
const selectionModes = require("../Grid/plex-grid-selectionmode").selectionModes;
const DataSourceFactory = require("../Data/plex-datasource-factory");
const AdvancedSearch = require("./plex-advanced-search");
const modelRepository = require("./plex-model-repository");
const onReady = require("./plex-handler-page").onReady;
const banner = require("../Plugins/plex-banner");
const GridController = require("./plex-controller-grid");
const TokenParser = require("../Tokens/plex-tokens-parser");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");
const env = require("../Core/plex-env");

require("../Knockout/knockout-semaphore"); // eslint-disable-line import/no-unassigned-import

const FEATURE_FLAG = "feat-tri-3862-picker-performance-improvement";
const PICKER_EMPTY_ROW_COUNT = 8;
const RECORD_LIMIT_EXCEEDED = "Record limit has been exceeded. Only the first {1} records are displayed.";

const tokenParser = new TokenParser();

// #region helpers

// todo: this could be a useful utility function
function throttleRequests(funcs, limit) {
  const responses = [];
  let deferredCount = 0;
  const deferred = new $.Deferred();

  function next() {
    deferredCount--;
    if (funcs.length > 0) {
      iterate();
    }
  }

  function iterate() {
    while (deferredCount < limit && funcs.length > 0) {
      const response = funcs.shift()();
      responses.push(response);

      if (jsUtils.isPromise(response)) {
        response.then(next);
        deferredCount++;
      }
    }

    if (funcs.length === 0) {
      $.when(...responses).then((...args) => deferred.resolve(args));
    }
  }

  iterate();
  return deferred.promise();
}

function createFilter(picker) {
  const text = picker.newSearchText();
  const query = new TextQuery(text, picker.advancedMode());
  const columns = picker.columns.filter((c) => {
    return c.isSelected();
  });

  if (!picker.searchWithin()) {
    picker.searchQueries.length = 0;
  }

  picker.searchQueries.push({ query, columns });

  return function (record) {
    const values = [];
    let value;

    // todo: remove this - we shouldn't be creating a predicate at all if the search is empty
    if (!text) {
      return true;
    }

    for (let i = 0; i < columns.length; i++) {
      value = String(columns[i].valueProvider.getValue(record));

      values.push(value);
    }

    return query.isMatch(values);
  };
}

function setValueProvider(picker, column) {
  // this function wraps the value providers to enable the text highlighting when searching
  // todo: MasterColumns should work in theory but something is going on with them
  if (
    column.columns ||
    column.elements ||
    (column.valueProvider && typeof column.valueProvider.getValue === "function")
  ) {
    return;
  }

  column.valueProvider = valueProviderFactory.create(column, picker.config);

  // override these functions
  if (column.isSearchable) {
    jsUtils.addMethod(column.valueProvider, "getHtml", function () {
      const value = stringUtils.escapeHtml(this.getFormattedValue.apply(this, arguments), { ignoreLineBreaks: true });
      if (!value || picker.searchQueries.length === 0) {
        return this._base.apply(this, arguments);
      }

      let matches = [];
      picker.searchQueries.forEach((q) => {
        if (q.columns.indexOf(column) >= 0) {
          matches = matches.concat(q.query.matches(value, true));
        }
      });

      if (matches.length > 0) {
        return TextQuery.wrap(matches, value, "<mark class='picker-highlight'>", "</mark>");
      }

      return value;
    });
  }

  if (column.propertyName === picker.config.displayPropertyName) {
    jsUtils.addMethod(column.valueProvider, "getEmptyHtml", () => {
      return "<span class='plex-grid-deemphasized'>" + picker.config.blankText + "</span>";
    });
  }
}

// #endregion

const PickerController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

PickerController.prototype = {
  constructor: PickerController,

  init: function () {
    const self = this;

    // setup observables
    self.results = ko.observableArray();
    self.selected = self.config.selected;
    self.newSearchText = ko.observable();
    self.searchTerms = ko.observableArray();
    self.errorText = ko.observable("");
    self.orgPlaceholder = self.config.placeholder ? ko.unwrap(self.config.placeholder) : "";
    self.searchQueries = [];
    self.shouldShowAllSelected = ko.observable(false);
    self.showSelectedQty = self.config.showSelectedQty || 4;
    self.showSelectedQtyIndex = self.showSelectedQty - 1;
    self.currentTextLength = ko.observable(0);
    self.config.disablePickerIcon = ko.pureComputed(() => {
      return self.config.minimumTextLength > self.currentTextLength();
    }, self);

    if (self.config.autoPick || self.selected().length > 0) {
      self.config.boundDisplayValue = ko.observable();
    } else {
      const displayPropertyValue = dataUtils.getValue(this.model, self.config.displayPropertyName);
      self.config.boundDisplayValue = ko.observable(displayPropertyValue);
    }

    // todo: update the C# model to set this correctly
    self.config.selectionMode = self.config.multiSelect ? selectionModes.multi : selectionModes.single;

    if (!self.config.dataSource) {
      notify.error({
        text: "The data source has not been set on the {1} element.",
        tokens: [self.config.handle || self.config.displayPropertyName]
      });
      return;
    }

    self.config.ignoreSelectedState = true;

    self.config.dsGetOptions = { ignoreEmptyData: self.config.ignoreEmptyData };

    self.isBusy = ko.observable(false);
    self.isLoading = ko.semaphore(false);
    self.isEvaluating = ko.observable(false).extend({ or: self.isLoading });

    self.columns = self.config.columns.filter((column) => {
      return $.isEmptyObject(column) === false;
    });
    self.pendingSelections = ko.observableArray();
    self.config.dataSource.initializer = $.proxy(self._initData, self);
    self.config.dataSource.disposer = $.proxy(self._disposeData, self);
    self.config.dataSource.maxRecords = self.config.recordDisplayLimit;
    // dataProvider has tokens add them to parameters
    self.config.dataSource.parameters = self.config.dataSource.parameters || [];
    if (self.config.dataProvider && self.config.dataProvider.tokens) {
      self.config.dataProvider.tokens.forEach((token) => {
        if (token.propertyName && !token.valueProvider) {
          // tranform data tokens into data source parameter without value
          self.config.dataSource.parameters.push({
            name: token.alias || token.propertyName
          });
        } else {
          self.config.dataSource.parameters.push(token);
        }
      });
    }
    self.datasource = DataSourceFactory.create(self.config.dataSource);
    self.$input = $(document.getElementById(self.config.id));
    self.labelText =
      self.config.dialogTitle ||
      domUtils.getLabelText(self.$input) ||
      stringUtils.toTitleCase(self.config.displayPropertyName);
    self.searchWithin = ko.observable(false);
    self.advancedSearch = new AdvancedSearch();
    self.advancedMode = ko.observable(false);
    self.isValidRequest = ko.observable(true);
    self.closeOnSelection = false;
    self.config.blankText = (self.config.blankText || "BLANK").toUpperCase();
    self.onlyFilteredRequest = self.config.onlyFilteredRequest;
    self.pendingRequests = [];
    self.disposed = false;
    self.useCache = self.config.minimumTextLength && self.config.minimumTextLength > 0;
    self.enableAdvancedMode = self.enableWideOpenSearch =
      !self.config.minimumTextLength || self.config.minimumTextLength === 0;

    if (self.config.validationModel) {
      // default this to true for grid validation
      self.config.validationModel.selectedOnly = true;
      self.config.autoSelectSingleResult = false;
    }

    if (self.config.displayTextExpression) {
      self.displayTextEvaluator = expressionUtils.compile(self.config.displayTextExpression);
    } else {
      self.displayTextEvaluator = function (item) {
        return item[self.config.displayPropertyName];
      };
    }

    self.params = {};
    self.params[self.config.displayPropertyName] = "";

    self._disposables = [];
    self._resetables = [];
    self.config.headerVisible = self.columns.length > 0 || self.config.multiSelect;
    self.config.fixedHeader = self.config.headerVisible;
    self.searchableColumns = self.columns.filter((column) => {
      return column.isSearchable;
    });

    // create selected property for each header
    self.columns.forEach((column) => {
      column.isSelected = ko.observable(column.isSearchable);
      setValueProvider(self, column);
    });

    self.config.displayValue = ko.pureComputed(self.getDisplayValue, self);

    // this defines how many empty records are displayed when no records are found
    self.config.emptyRecordCount = PICKER_EMPTY_ROW_COUNT;

    self.resultMessageHtml = ko.pureComputed(() => {
      if (self.datasource.isLoading()) {
        return "";
      }

      const exceeded = self.datasource.recordCountLimitExceeded();
      const totalCount = self.datasource.data().length;
      const text = stringUtils.escapeHtml(self.searchTerms().join(" "));

      if (self.datasource.raw.length === 0 && self.datasource.recordCount() === 0) {
        // no data yet
        return "";
      }

      if (!exceeded && !text) {
        return "All items shown";
      }

      if (!exceeded && text) {
        if (totalCount === 1) {
          return {
            text: "1 item found for: {1}",
            tokens: ["<span class='plex-picker-query'>" + text + "</span>"]
          };
        }

        return {
          text: "{1} items found for: {2}",
          tokens: [totalCount, "<span class='plex-picker-query'>" + text + "</span>"]
        };
      }

      if (exceeded) {
        return { text: RECORD_LIMIT_EXCEEDED, tokens: [totalCount] };
      }

      return "";
    });

    self.hasError = ko.pureComputed(() => {
      // show as error if no columns are selected
      let i = self.columns.length;
      while (i--) {
        if (self.columns[i].isSelected()) {
          return false;
        }
      }

      return true;
    });

    if (self.config.bindings && self.config.bindings.length > 0) {
      self._disposables.push(self.selected.subscribe(self.applyBindings, self));
      if (!self.config.autoPick) {
        self._disposables.push(self.config.boundDisplayValue.subscribe(self.applyBindings, self));
      }
    }

    if (!self.config.autoPick) {
      self._disposables.push(
        self.config.boundDisplayValue.subscribe((value) => {
          dataUtils.setValue(self.model, self.config.displayPropertyName, value);
        })
      );
    }

    // track request updates to reset value
    if (
      self.config.dataSource.parameters &&
      self.config.dataSource.parameters.length > 0 &&
      self.config.trackRequestUpdates
    ) {
      self.config.features.push({ name: "PickerTrackRequestUpdates" });
      self._trackRequestUpdates();
    }

    if (self.orgPlaceholder !== "" && self.config.placeholder) {
      self._disposables.push(
        self.selected.subscribe(() => {
          self._setPlaceholder(self.selected());
        }, self)
      );

      if (self.selected().length > 0) {
        self._setPlaceholder(self.selected());
      }
    }
    if (!ko.isObservable(self.selected)) {
      throw new Error(
        "The Data Value property is not set on the " +
          (self.config.handle || self.config.displayPropertyName) +
          " element in the grid."
      );
    }
    // any time records are added to the selected array they need to be initialized
    // this needs to happen before rendering, which is why the `arrayChange` event is used

    self._disposables.push(
      self.selected.subscribe(
        () => {
          self._initData(self.selected());
        },
        null,
        "arrayChange"
      )
    );

    if (self.selected().length > 0) {
      self._initData(self.selected());
    }

    // store picker params in model repository (for datasource to retrieve values)
    modelRepository.add(self.config.id, self.params);
  },

  _setPlaceholder: function (selectedItems) {
    const placeholderValue = selectedItems.length > 0 ? "" : this.orgPlaceholder;
    if (this.config.placeholder) {
      if (ko.isObservable(this.config.placeholder)) {
        this.config.placeholder(placeholderValue);
      } else {
        this.config.placeholder = placeholderValue;
      }
    }
  },

  _initData: function (data) {
    const self = this;
    const selectedItems = this.pendingSelections();
    const propertyName = this.config.valuePropertyName || this.config.propertyName;

    data.forEach((item) => {
      if (!(propertyName in item)) {
        throw new Error('The property "' + propertyName + '" was not found in the picker resultset.');
      }

      // look to see if the object exists in the array,
      // confirming against the property name.
      // This should be a unique field.
      const selected = ko.utils.arrayFirst(selectedItems, (a) => a[propertyName] === item[propertyName]);
      if (selected) {
        // Restore properties that were updated by the user. ex. PartAttributePicker
        $.extend(item, dataUtils.cleanse(selected));
      }

      if (!ko.isObservable(item.$$selected)) {
        item.$$selected = ko.observable();
      }

      item.$$selected(!!selected);

      if (!ko.isObservable(item.$$displayText)) {
        if (!item.$$initialized) {
          dataUtils.trackObject(item);
        }

        item.$$displayText = ko.pureComputed(() => {
          const value = self.displayTextEvaluator(item);
          return value === 0 || value ? value : self.config.blankText;
        });
      }

      // cached item has expired and pendingSelections needs to be updated.
      if (item.$$selected() && item !== selected) {
        if ("$$restorePendingSelection" in selected) {
          item.$$restorePendingSelection = selected.$$restorePendingSelection;
        }

        self.pendingSelections.replace(selected, item);
      }
    });
  },

  _generateRequestData: function () {
    const self = this;
    const data = {};

    const tokens = this.config.dataProvider && this.config.dataProvider.tokens;
    if (tokens) {
      tokens.forEach((token) => {
        // map to the parameter name for data tokens
        if (self.model && token.propertyName in self.model) {
          data[token.alias || token.propertyName] = self.model[token.propertyName];
        }
      });
    }

    return data;
  },

  _disposeData: function (data) {
    if (this.grid) {
      this.grid._disposeData(data);
    }
  },

  _trackRequestUpdates: function () {
    const self = this;
    let init = true;

    onReady(() => {
      const request = ko
        .computed(() => {
          // do not allow the picker text field to trigger notifications for this observable
          self.config.boundDisplayValue.pause();
          self.isEvaluating(true);

          const tokenData = self._generateRequestData();
          let currentRequest;

          if (init) {
            init = false;
            currentRequest = self.currentRequest = self.datasource.request.build(tokenData);
          } else {
            // use isBusy check to determine if the change was actually caused by the picker itself
            // if so, return the cached copy but still build the request so all dependencies are captured
            const updatedRequest = self.datasource.request.build(tokenData);
            currentRequest = self.isBusy() ? self.currentRequest : updatedRequest;
          }

          self.isEvaluating(false);
          self.config.boundDisplayValue.resume();
          return currentRequest;
        })
        .extend({ deferred: true });

      request.subscribe((updatedRequest) => {
        const validRequest = self.datasource.request.isValid(updatedRequest);
        if (!validRequest || (self.currentRequest && !dataUtils.compareObjects(updatedRequest, self.currentRequest))) {
          self.selected.removeAll();
        }

        self.isValidRequest(validRequest);
        self.currentRequest = updatedRequest;
      });

      request.notifySubscribers(request());
    });
  },

  canPick: function () {
    return this.config.disabled() || this.config.readOnly() || this.config.disablePickerIcon();
  },

  pick: function () {
    if (this.isBusy() || this.canPick()) {
      return new $.Deferred().reject();
    }

    this.isBusy(true);

    const text = this.config.boundDisplayValue();
    this.newSearchText(text);
    return this.load(text);
  },

  load: function (text, key) {
    // note: the key property is likely no longer used - that was added for setValue, and that now makes its own requests
    // todo: obsolete the key argument
    const self = this;
    let picked = false;
    const propertyName = this.config.valuePropertyName || this.config.propertyName;
    const autoPick = this.config.autoSelectSingleResult || !!key;
    const onlyFilteredRequest = this.config.onlyFilteredRequest || !!key;
    let autoPickRequest, fullRequest;

    self.isLoading(true);
    self.cancelled = false;

    const useOpenRequest = env.features[FEATURE_FLAG] ? onlyFilteredRequest : text || onlyFilteredRequest;

    const pickFirst = (results) => {
      this.autoPick(results[0]);
      this.isLoading(false);
      picked = true;
    };

    const pickedResult = (results) => {
      if (results.length === 1 && autoPick) {
        pickFirst(results);
      } else {
        self.search();
        self.isLoading(false);
      }

      self.pendingRequests.push(autoPickRequest);
      self.params[self.config.displayPropertyName] = "";

      if (picked) {
        return autoPickRequest;
      }
      self.render();
      return undefined;
    };

    if (env.features["fix-tri-4806-picker-performance-bug-fix-v2"]) {
      if (useOpenRequest) {
        // partial search for performance
        autoPickRequest = self.createFilteredRequest(text).then((results) => {
          // try to search within datasource
          if (results.length > 1) {
            self.applyFilter();

            if (self.datasource.source().length === 1 && self.config.autoSelectSingleResult) {
              return pickedResult(self.datasource.source());
            }
          }

          // full search when result is not an exact match
          if (results.length === 1) {
            return pickedResult(results);
          } else {
            self.createFullRequest({ text, useCache: false }).then((resultset) => {
              return pickedResult(resultset);
            });
          }

          return undefined;
        });
      } else {
        fullRequest = self.createFullRequest({ text, useCache: false }).then((resultset) => {
          return pickedResult(resultset);
        });
      }

      self._selectedColumns();

      return fullRequest || autoPickRequest;
    } else {
      if (useOpenRequest) {
        autoPickRequest = this.createFilteredRequest(text).then((results) => {
          self.pendingRequests = self.pendingRequests.filter((request) => {
            return request.state() === "pending";
          });

          if (key) {
            // eslint-disable-next-line eqeqeq, no-param-reassign
            results = results.filter((record) => record[propertyName] == key);
          }

          if (results.length === 1 && autoPick) {
            pickFirst(results);
          } else {
            if (!key) {
              self.search();
            }

            self.isLoading(false);
          }

          // Rendering here would cause issues. On long requests, user could select a value from the autoPickRequest
          // and close picker. Then full request would complete and picker would re-render.
          return picked;
        });

        self.pendingRequests.push(autoPickRequest);

        // need to clear the params so that request doesn't contain the display value
        // when tracking changes to the request - see IP-4816
        this.params[this.config.displayPropertyName] = "";

        if (picked) {
          // this will all happen sequentially in the case of a dropdown
          // so we can exit out if the pick has happened
          return autoPickRequest;
        }
      } else if (!env.features[FEATURE_FLAG]) {
        // if we're doing a wide open search, go ahead and render
        this.render();
      }

      this._selectedColumns();

      if (!onlyFilteredRequest) {
        fullRequest = (autoPickRequest || $.when()).then((autoPicked) => {
          if (autoPicked === true) {
            // if an autopick occurred there is no need to make the full request
            return undefined;
          }

          return this.createFullRequest({ text, useCache: false }).then((result) => {
            let results = [];
            if (env.features[FEATURE_FLAG] && !dataUtils.isEmpty(text)) {
              results = result.filter(
                (record) => String(record[this.config.displayPropertyName]).toLowerCase() === String(text).toLowerCase()
              );
            }

            self.pendingRequests = self.pendingRequests.filter((request) => {
              return request.state() === "pending";
            });

            self.applyFilter();

            if (env.features[FEATURE_FLAG]) {
              if (results.length === 1 && autoPick) {
                pickFirst(results);
                return;
              }
            }

            // see if we can auto-select based on the current filtering
            if (self.datasource.source().length === 1 && self.config.autoSelectSingleResult) {
              self.autoPick(self.datasource.source()[0]);
              return;
            }

            if (!self.cancelled) {
              self.search();
              self.isLoading(false);
            }
          });
        });

        self.pendingRequests.push(fullRequest);
      }

      return fullRequest || autoPickRequest;
    }
  },

  getFilteredResults: function (results, text) {
    const self = this;
    let partialMatchResults = [];
    let exactMatchResults = [];

    // first check display property column for exact match
    if (!dataUtils.isEmpty(text)) {
      exactMatchResults = results.filter(
        (record) => String(record[self.config.displayPropertyName]).toLowerCase() === String(text).toLowerCase()
      );
    }

    // if single exact match result not found then check in all columns to get matches
    if (results.length > 1) {
      partialMatchResults = results.filter(createFilter(self));
    }

    return exactMatchResults.length === 1 ? exactMatchResults : partialMatchResults;
  },

  _selectedColumns() {
    if (env.features[FEATURE_FLAG]) {
      const hasDisplayColumn = this.searchableColumns.some((c) => c.propertyName === this.config.displayPropertyName);
      if (hasDisplayColumn) {
        this.setSelectedColumns(false);
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `[WARNING]: There is no column that matches the "displayPropertyName". All columns will be selected which will degrade performance. This should probably be changed!`
        );
        this.setSelectedColumns(true);
      }
    } else if (this.useCache) {
      this.setSelectedColumns(false);
    } else {
      this.setSelectedColumns(true);
    }
  },

  setSelectedColumns: function (all) {
    const self = this;
    this.searchableColumns.forEach((column) => {
      column.isSelected(all === true || column.propertyName === self.config.displayPropertyName);
    });
  },

  autoPick: function (record) {
    if (this.config.multiSelect && !this.checkSelectedDuplicate(record)) {
      this.selected.push(record);
    } else {
      // if not a multi-select remove any existing selection by replacing array
      this.selected([record]);
    }

    this.reset();
    this.closeAndAdvanceFocus();
  },

  pickFirst: function () {
    const self = this;
    self.isLoading(true);
    const tokenData = self._generateRequestData();
    const request = this.datasource
      .get(tokenData, this.config.dsGetOptions)
      .then((results) => {
        self.pendingRequests = self.pendingRequests.filter((req) => {
          return req.state() === "pending";
        });

        if (results.length > 0) {
          self.autoPick(results[0]);
        }
      })
      .always(() => {
        self.isLoading(false);
      });

    self.pendingRequests.push(request);
    return request;
  },

  checkSelectedDuplicate: function (record) {
    const self = this;
    let duplicate = false;
    const prop = self.config.valuePropertyName || self.config.propertyName;
    if (prop in record) {
      const propValue = record[prop];
      duplicate = this.selected.some((item) => {
        return prop in item && item[prop] === propValue;
      });
    }

    return duplicate;
  },

  setValue: function (selectedValues) {
    this.selected.removeAll();
    if (dataUtils.isEmpty(selectedValues)) {
      return $.when();
    }

    selectedValues = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    if (selectedValues.length > 1 && !this.config.multiSelect) {
      // we only want to set the last value if we can't multiselect
      selectedValues = [selectedValues[selectedValues.length - 1]];
    }

    const propertyName = this.config.valuePropertyName || this.config.propertyName;
    const values = selectedValues
      .map((item) => {
        // values may come in using boundValue/boundDisplayValue
        if (!(propertyName in item) && "boundValue" in item) {
          return {
            [propertyName]: item.boundValue,
            [this.config.displayPropertyName]: item.boundDisplayValue
          };
        }

        return item;
      })
      .filter((item) => item[propertyName] || item[this.config.displayPropertyName]);

    const requestFuncs = values.map((item) => () => {
      // need to set display value manually because VP wireups will be looking for it
      this.config.boundDisplayValue(item[this.config.displayPropertyName]);
      const request = this.createFilteredRequest(item[this.config.displayPropertyName]);
      this.config.boundDisplayValue("");
      return request;
    });

    this.isBusy(true);

    // only execute 5 requests at once
    return throttleRequests(requestFuncs, 5).then((responses) => {
      const pickedRows = responses
        .map((rows, index) => {
          const key = values[index][propertyName];

          // eslint-disable-next-line eqeqeq
          return ko.utils.arrayFirst(rows, (r) => r[propertyName] == key);
        })
        .filter(Boolean);

      if (pickedRows.length > 0) {
        this.selected.push(...pickedRows);
      }

      this.reset();
    });
  },

  search: function () {
    if (this.advancedMode()) {
      this.searchTerms.removeAll();
      this.newSearchText(this.advancedSearch.build());
    }

    const text = this.newSearchText();
    if (text && this.searchWithin() && this.searchTerms().indexOf(text) >= 0) {
      // we have already searched by this term
      return;
    }

    this.render();

    if (this.hasError()) {
      this.errorText("Select a field to search by");
      return;
    }

    this.errorText("");

    if (!this.searchWithin()) {
      // this will end up causing two updates which might end up being undesirable
      this.searchTerms.removeAll();
    }

    if (this.advancedMode()) {
      this.searchTerms.push(this.advancedSearch.build(true));
    } else {
      this.searchTerms.push(this.newSearchText());
    }

    this.applyFilter();
  },

  initSearch: function () {
    const self = this;

    if (self.config.disablePickerIcon()) {
      return;
    }

    if (this.config.multiSelect && this._isGridSelectionValid() === false) {
      return;
    }

    if (this.isLoading() === false) {
      if (
        (this.searchWithin() || (this.datasource.cachedRecordSet() === false && !this.onlyFilteredRequest)) &&
        this.enableWideOpenSearch
      ) {
        this.search();
        return;
      }
    } else {
      this.pendingRequests = self.pendingRequests.filter((request) => {
        return request.state() === "pending";
      });

      if (this.pendingRequests.length > 0) {
        const request = this.pendingRequests[0];
        if (request) {
          request.abort();
        }
      }

      this.isLoading(false);
    }

    if (this.onlyFilteredRequest) {
      const filteredRequest = this.createFilteredRequest(this.newSearchText()).done(() => {
        self.pendingRequests = self.pendingRequests.filter((request) => {
          return request.state() === "pending";
        });

        self.search.bind(self);
      });

      self.pendingRequests.push(filteredRequest);
      return;
    }

    const options = { useCache: self.useCache };

    if (this.advancedMode()) {
      options.searchMethods = [];

      this.advancedSearch.getFilteredMethods().forEach((method) => {
        options.searchMethods.push({ id: method.id, text: method.getText() });
      });
    } else {
      options.text = this.newSearchText();
    }

    const fullRequest = this.createFullRequest(options).done(() => {
      self.pendingRequests = self.pendingRequests.filter((request) => {
        return request.state() === "pending";
      });

      self.search();
    });

    self.pendingRequests.push(fullRequest);
  },

  createFullRequest: function (options) {
    const self = this;

    // additional args for controlling caching full requests
    let columns = [];
    this.columns
      .filter((column) => {
        return column.isSelected();
      })
      .forEach((column) => {
        if (column.bindableProperties?.length > 0) {
          columns = columns.concat(column.bindableProperties.map((c) => c.propertyName));
        } else {
          columns.push(column.propertyName);
        }
      });

    const dsArgs = {
      __filterColumns: columns,
      __recordLimit: this.config.recordDisplayLimit,
      __pickerRequest: true,
      __useCache: options.useCache
    };

    if (options.text) {
      dsArgs.__filterText = options.text;
    }

    if (options.searchMethods && options.searchMethods.length > 0) {
      options.searchMethods.forEach((method) => {
        dsArgs[method.id] = method.text;
      });
    }

    // for VP pickers, identify the param bound to the picker text. it should always be wired to element value type which uses TokenValueProvider
    this.config.dataSource.parameters
      .filter((param) => {
        if (
          param.valueProvider &&
          param.valueProvider.address === "TokenValueProvider" &&
          param.valueProvider.tokenText
        ) {
          const tokens = tokenParser.parse(param.valueProvider.tokenText);
          return self.config.id === tokens.getAt(0).getAttr("name");
        }

        return false;
      })
      .forEach((param) => {
        // send in empty param so that we get all data from the picker
        dsArgs[param.name || param.alias || param.propertyName] = self.enableWideOpenSearch ? "" : "%" + options.text;
      });

    const dsOptions = $.extend({}, this.config.dsGetOptions, {
      cacheResponse: this.config.cacheResponse,
      additionalParams: dsArgs
    });

    // for regular pickers, the param bound to the picker text always uses picker model repository
    if (this.enableWideOpenSearch) {
      // send in empty param so that we get all data from the picker
      this.params[this.config.displayPropertyName] = "";
    } else {
      this.params[this.config.displayPropertyName] = "%" + options.text;
    }

    return this.datasource.get(this._generateRequestData(), dsOptions);
  },

  createFilteredRequest: function (text) {
    this.params[this.config.displayPropertyName] = text || "";
    const dsOptions = $.extend({}, this.config.dsGetOptions, {
      cacheResponse: this.config.cacheResponse,
      additionalParams: { __recordLimit: this.config.recordDisplayLimit }
    });

    return this.datasource.get(this._generateRequestData(), dsOptions);
  },

  resetSearch: function () {
    this.newSearchText("");
    this.advancedMode(false);
    this._setSearchFocus();
  },

  setAdvancedMode: function () {
    this.advancedSearch.reset();
    this.advancedMode(true);
    this._setSearchFocus();
  },

  applyFilter: function () {
    this.datasource.filter(createFilter(this), this.searchWithin());
  },

  getDisplayValue: function () {
    const self = this;
    let displayValue = this.selected()
      .map(this.displayTextEvaluator.bind(this))
      .join(self.config.displayValueDelimiter || ", ");

    if (!displayValue && !this.config.autoPick && this.config.boundDisplayValue()) {
      displayValue = this.config.boundDisplayValue();
    }

    return displayValue;
  },

  getValue: function () {
    if (this.selected().length > 0) {
      return this.selected();
    }

    if (!this.config.autoPick && this.config.boundDisplayValue()) {
      return this.config.boundDisplayValue();
    }

    return null;
  },

  getState: function () {
    const self = this;
    const propertyName = this.config.valuePropertyName || this.config.propertyName;
    let selected;

    if (this.config.displayTextExpression) {
      // if using an expression we can't easily predict what properties are needed
      // so just return the whole object
      selected = dataUtils.cleanse(this.selected(), {
        flatten: false,
        ignoreEmpty: false
      });
    } else {
      selected = this.selected().map((item) => {
        const picked = {};

        // only keep the properties necessary to render picked items and maintain value
        picked[propertyName] = item[propertyName];
        picked[self.config.displayPropertyName] = item[self.config.displayPropertyName];

        // need to also save any properties that contain bindings
        if (self.config.bindings && self.config.bindings.length > 0) {
          self.config.bindings.forEach((binding) => {
            picked[binding.sourcePropertyName] = item[binding.sourcePropertyName];
            if (picked[binding.sourcePropertyName] == null && self.model[binding.targetPropertyName] != null) {
              picked[binding.sourcePropertyName] = self.model[binding.targetPropertyName];
            }
          });
        }

        return picked;
      });
    }

    return {
      selected,
      displayValue: this.config.boundDisplayValue(),
      request: this.currentRequest
    };
  },

  restoreState: function (state) {
    if (this.validateState(state)) {
      this.selected(state.selected || []);
      this.config.boundDisplayValue(state.displayValue);
      this.currentRequest = state.request;

      setTimeout(this.applyBindings.bind(this), 0);
    } else {
      this.selected.removeAll();
    }
  },

  validateState: function (state) {
    if (!state) {
      return false;
    }

    // Need to verify that selected records contain property key.
    // If the property does not exist, the request model may have
    // changed from a customer setting change.
    const propertyName = this.config.valuePropertyName || this.config.propertyName;
    const nullPropertyName = function (item) {
      return !(propertyName in item);
    };

    const selected = state.selected || [];
    return !selected.some(nullPropertyName);
  },

  applyBindings: function () {
    if (this.config.bindings && this.config.bindings.length > 0) {
      if (!this.config.autoPick && this.selected().length === 0) {
        const source = {};
        source[this.config.displayPropertyName] = this.config.boundDisplayValue();
        bindingHandler.update(this.config.bindings, source);
      } else {
        bindingHandler.update(this.config.bindings, this.selected());
      }
    }
  },

  getInput: function () {
    // Required for when picker is rendered by a Grid. Grid inits picker
    // before DOM element is created so $input may not point to picker.
    this.$input = $(document.getElementById(this.config.id));
    return this.$input;
  },

  render: function () {
    if (this.disposed || this.showing) {
      return;
    }

    const self = this;
    self.showing = true;

    // render template
    self.$element = $("<div>").appendTo(document.body);
    ko.renderTemplate("picker-modal", self, {}, self.$element[0]);

    // Banner for picker dialog
    self.$element.find(".plex-banner").banner();
    self.banner = banner.findClosest(self.$element);

    self.pendingSelections(self.config.selected().slice(0));

    self.$picker = self.$element
      .find(".plex-picker-modal")
      .modal({ show: false, backdrop: "static" })
      .one("shown.bs.modal", () => {
        const $el = self.$element.find(".plex-grid-container");
        self.grid = new GridController();

        // this is ugly - what to do? - the grid needs to have a different ID.
        // Both of these items use the repository for different purposes.
        const config = $.extend({}, self.config);
        config.id += "_grid";
        config.belongsToPicker = true;

        self.grid.init($el, config, self.datasource);
        self._resetables.push(self.grid.selected.subscribe(self._onGridSelected, self));

        // default this to true, picker will always search the grid
        self.grid.searched(true);

        // register single grid row selections
        if (!self.config.multiSelect) {
          self.grid.$grid.$table.on("click", "tbody td", (e) => {
            // let input do it's thing
            const tagName = e.target.tagName.toLowerCase();
            if (tagName === "select" || tagName === "input" || tagName === "textarea") {
              return;
            }

            self.closeOnSelection = true;
          });
        }

        self._setSearchFocus();
      })
      .one("hide.bs.modal", () => {
        // we want to reset while the picker is closing
        self.reset();
      })
      .one("hidden.bs.modal", function () {
        // we want to reset while the picker is closing
        self.reset();

        // remove element and cleaning up bindings
        ko.removeNode(this);
        self.$element.remove();
      });

    self.$picker.modal("show");
  },

  reset: function () {
    let sub;

    // clear bound value
    this.config.boundDisplayValue("");
    this.currentTextLength(0);
    this.searchTerms.removeAll();

    // clean up grid events
    if (this.grid) {
      this.grid.dispose();
      this.grid = null;
    }

    this.searchQueries = [];
    this.results.removeAll();
    this.newSearchText("");
    this.errorText("");
    this.datasource.reset();
    this.pendingSelections([]);
    this.searchWithin(false);
    this.advancedSearch.reset();
    this.advancedMode(false);
    this.isLoading(false);
    this.shouldShowAllSelected(false);
    this.closeOnSelection = false;

    // disposables
    while ((sub = this._resetables.pop())) {
      sub.dispose();
    }

    // reset state
    this.showing = false;
    this.isBusy(false);

    // return focus to the input field
    this.getInput().focus();
  },

  showAllSelected: function () {
    this.shouldShowAllSelected(true);
  },

  doubleClick: function (selected) {
    if (this._isGridSelectionValid()) {
      this.selected([selected]);

      this.closeAndAdvanceFocus();
    }
  },

  applySelected: function () {
    if (this._isGridSelectionValid() === false) {
      return;
    }

    const selected =
      this.pendingSelections().length > 0
        ? this.pendingSelections()
        : this.datasource.raw.filter((record) => {
            return record.$$selected();
          });

    this.selected(selected);

    this.closeAndAdvanceFocus();
  },

  closeAndAdvanceFocus: function () {
    const self = this;
    if (self.$picker) {
      self.$picker.modal("hide").one("hidden.bs.modal", () => {
        domUtils.advanceFocus(self.getInput());
      });
    } else {
      // the picker isn't open but still go ahead and advance focus
      domUtils.advanceFocus(self.getInput());
    }
  },

  cancel: function () {
    const self = this;
    self.currentTextLength(0);
    self.cancelled = true;
    self.$picker.modal("hide").one("hidden.bs.modal", () => {
      self.getInput().focus();
    });
  },

  removePendingSelected: function (item) {
    const self = this;
    const prop = self.config.valuePropertyName || self.config.propertyName;
    const results = $.grep(self.grid.results(), (a) => {
      return a[prop] === item[prop];
    });
    if (results.length > 0) {
      $.each(results, (i, a) => {
        a.$$selected(false);
      });
    } else {
      self.pendingSelections.remove(item);
      if (item.$$index) {
        self.grid.datasource.raw[item.$$index].$$selected(false);
      }
    }
  },

  removeSelected: function (item) {
    this.selected.remove(item);
    item.$$selected(false);

    this.getInput().focus();
    this.$input.trigger("text-change");
  },

  _setSearchFocus: function () {
    if (this.$picker) {
      // focus on search field
      this.$picker.find("input[type='text']:visible:first").focus();
    }
  },

  // it will be removed
  onchange: function (_picker, _e) {
    // noop
  },

  onclick: function (_picker, _e) {
    // noop
  },

  onmouseup: function (_picker, e) {
    // if you click anywhere in the picker area, focus in the textbox
    const $input = this.getInput();

    if (this.selectedTextExist()) {
      return false;
    }

    // Keep focus from triggering any event binding multiple times
    if ($input.length && $input[0] !== e.target) {
      $input.focus();
    }

    return true;
  },

  selectedTextExist: function () {
    let txt = "";

    if (window.getSelection) {
      txt = window.getSelection().toString();
    } else if (document.getSelection) {
      txt = document.getSelection().toString();
    } else if (document.selection) {
      txt = document.selection.createRange().text.toString();
    }

    if (txt) {
      return true;
    }

    return false;
  },

  onkeydown: function (picker, e) {
    // check for backspace or left arrow and remove picked item if detected
    if (
      (e.which === 8 || e.which === 37) &&
      !e.target.value &&
      !picker.config.readOnly() &&
      !picker.config.disabled()
    ) {
      if (picker.config.multiSelect) {
        picker.selected.pop();
      } else {
        const displayValue = picker.config.displayValue();
        picker.selected.pop();
        picker.config.boundDisplayValue(displayValue);
      }

      return false;
    }

    // on enter, use blur to invoke pick
    if (e.which === 13 && !picker.isBusy() && e.target.value) {
      e.target.blur();
      return false;
    }

    // allow the default action
    return true;
  },

  onkeyup: function (_picker, e) {
    if (e.target.value) {
      this.currentTextLength(e.target.value.length);
    } else {
      this.currentTextLength(0);
    }
  },

  onblur: function (picker, e) {
    // only pick on change if we have a value and autopick is enabled
    if (e.target.value && picker.config.autoPick) {
      picker.config.boundDisplayValue(e.target.value);
      picker.pick();
    }
  },

  _onGridSelected: function () {
    const self = this;
    const propertyName = self.config.valuePropertyName || self.config.propertyName;
    let exists, isSelected;

    self.grid.datasource.raw.forEach((item) => {
      if (item.$$selected()) {
        isSelected = true;
        exists = self.pendingSelections().filter((selection) => {
          if (selection[propertyName] === item[propertyName]) {
            // extend any missing properties to selection (could be bound to model).
            $.extend(true, selection, item);
            return true;
          }

          return false;
        });

        // add rows that are selected but do not already exist
        if (exists.length === 0) {
          item.$$restorePendingSelection = true;
          self.config.multiSelect ? self.pendingSelections.push(item) : self.pendingSelections([item]);
        }
      } else {
        // remove unselected rows
        self.pendingSelections.remove((pendingItem) => {
          return pendingItem[propertyName] === item[propertyName];
        });
      }
    });

    if (!self.config.multiSelect && self.closeOnSelection && (self.pendingSelections().length > 0 || isSelected)) {
      self.applySelected();
      return;
    }

    self.closeOnSelection = false;
  },

  _isGridSelectionValid: function () {
    if (!this.grid) {
      return true;
    }

    // reset grid validation
    const gridValidator = this.grid.validator;
    if (gridValidator && gridValidator.validator) {
      gridValidator.validator.resetForm();
      gridValidator.validator.$invalidElements = {};

      const $el = this.grid.$element;
      $el.find(".plex-error:not(label)").removeClass("plex-error");
      $el.find("label.plex-error").parent().remove();
    }

    return this.grid.validate();
  },

  dispose: function () {
    this.disposed = true;

    let request, sub;

    while ((request = this.pendingRequests.pop())) {
      if (request.state() === "pending") {
        request.abort();
      }
    }

    this.reset();

    while ((sub = this._disposables.pop())) {
      sub.dispose();
    }
  }
};

// factory method
PickerController.create = function (config, model) {
  return new PickerController(config, model);
};

ControllerFactory.register("Elements/_Picker", PickerController);

module.exports = PickerController;
plexExport("PickerController", PickerController);
