/* eslint-disable no-invalid-this */
const plexExport = require("../../global-export");
const jsUtils = require("../Utilities/plex-utils-js");
const logger = require("../Core/plex-logger");
const dataResultUtils = require("../Utilities/plex-utils-data-result");

const OBSOLETE_ACCESS_MESSAGE =
  "Do not access output parameters directly on the DataResult, please use the OutputParameters property of the DataResult instead. See PKG-954 for details.";

function mapOutputParameters(data) {
  const outputParameters = {};

  Object.getOwnPropertyNames(data).forEach((prop) => {
    if (prop !== "Rows") {
      outputParameters[prop] = data[prop];
    }
  });

  return outputParameters;
}

function copyOutputParameters(result) {
  Object.getOwnPropertyNames(result.OutputParameters)
    .filter((key) => !(key in result))
    .forEach((key) => {
      const message = `Property ${key} is obsolete. ${OBSOLETE_ACCESS_MESSAGE}`;
      const getter = () => result.OutputParameters[key];
      const setter = (value) => (result.OutputParameters[key] = value);

      Object.defineProperty(result, key, {
        enumerable: true,
        configurable: false,
        get: function () {
          logger.warn(message);
          return getter();
        },
        set: function (newValue) {
          setter(newValue);
          logger.warn(message);
        }
      });
    });
}

const DataResult = function (result, criteria) {
  if (jsUtils.isPrimitive(result)) {
    result = { Rows: [result] };
  }

  if (result.CustomDataResults) {
    dataResultUtils.mergeCustomData(result);
  }

  if ("Data" in result) {
    result = result.Data || [];
  }

  if (Array.isArray(result)) {
    result = { Rows: result, OutputParameters: result.$$outputParameters };
  }

  this.data = result.Rows || [result];
  this.recordLimitExceeded = !!result.RecordLimitExceeded;
  this.criteria = criteria;
  this.outputParameters = result.OutputParameters;
  this.recordCount = result.RecordCount || this.data.length;
  this.serverInfo = result.DbServerInfo;

  // Remove this block and mapOutputParameters function once DefaultResponseDto template with OutputParameters has run in production (11/3/2014)
  if (this.outputParameters) {
    copyOutputParameters(result);
  } else {
    this.outputParameters = result.Rows ? mapOutputParameters(result) : null;
  }

  return this;
};

module.exports = DataResult;
plexExport("DataResult", DataResult);
