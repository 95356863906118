ï»¿/* global plex */
// TODO: should use imports instead of plex global
const ko = require("knockout");
const validation = require("../Core/plex-validation");
const Controller = require("./plex-controller-base");
const CustomerDate = require("../Globalization/plex-customer-date");
const http = require("../Core/plex-navigate");
const notify = require("../Core/plex-notify");
const plexExport = require("../../global-export");

function handleResponse(response) {
  if (response && response.ValidationResult.Success === false) {
    notify.error(response.ValidationResult.Message);
  }
}

const MessageController = Controller.extend({
  onInit: function () {
    const self = this;
    self.$form = self.$element.parents(".plex-form");
    self.$dialogBody = self.$element.closest(".plex-dialog-body");
    self.$dialogModalContent = self.$element.closest(".modal-content");

    self.width = self.config.width || 600;
    self.height = self.config.height || 600;

    self.addDate = new CustomerDate(self.config.addDate);

    self.subtitle = ko.pureComputed(() => {
      if (self.config.authorName && self.addDate) {
        return `${self.config.authorName} (${self.addDate.toLocaleDateString()})`;
      }

      return "";
    });

    self.dialogController = self.$element.closest(":data(plex.dialog)").data("plex.dialog");

    if (self.dialogController) {
      self.dialogController.dialogElement.off("keyup.dismiss.bs.modal");
      self.dialogController.cancel = self.close.bind(self);
    }

    const headerHeight = self.$dialogModalContent.find(".modal-header").height();
    const footerHeight = self.$dialogModalContent.find(".modal-footer").height();

    self.$dialogModalContent
      .width(self.width)
      .height(self.height + headerHeight + footerHeight)
      .addClass("resized"); // add resized class since we are giving dialog fixed dimensions

    if (self.config.backgroundImage) {
      self.$dialogModalContent.css({
        background: "url(" + self.config.backgroundImage.imageUrl + ") no-repeat",
        "background-size": "cover"
      });
    } else if (self.config.backgroundColor) {
      self.$dialogBody.css({
        "background-color": self.config.backgroundColor
      });
    }

    // backwards compatible and only sets icon when attachment exists
    if (self.config.attachment && self.config.attachment.nodeNo) {
      self.attachment = {
        iconClass: setAttachmentIconClass(self.config.attachment.extension),
        name: self.config.attachment.name,
        nodeNo: self.config.attachment.nodeNo
      };
    }

    self.errorText = ko.observable("");

    self.showAcknowledgement = ko.observable(
      self.config.acknowledgementRequired && !self.config.acknowledgeDate && !self.config.previewMode
    );
    self.showDoNotShow = ko.observable(
      !self.config.acknowledgementRequired && !self.config.acknowledgeDate && !self.config.previewMode
    );
    self.showSendEmail = ko.observable(self.config.showSendEmail);

    self.acknowledged = ko.observable(false);
    self.doNotShow = ko.observable(true);
    self.sendEmail = ko.observable(false);

    // Validation Elements
    self.$elements = {};

    const validationModel = self.config.validationModel || {};
    self.validator = validation.createValidator(self.$form, validationModel, self);

    ko.renderTemplate("dialog-message", self, {}, self.$element[0]);

    self.$dialogBody.find(".plex-banner").banner();
    self.setupFooter();

    if (self.$form[0]) {
      self.$form[0].onsubmit = () => false;
    }
  },

  setupFooter: function () {
    this.$dialogModalContent.addClass("plex-dialog-has-buttons");
    this.$dialogModalContent.find(".modal-footer").append(this.$element.find(".plex-message-footer"));
  },

  viewDoc: function () {
    // link to view document
  },

  close: function () {
    const self = this;

    if (!self.config.previewMode) {
      if (self.showAcknowledgement() && !self.acknowledged()) {
        this.acknowledgeError = notify.error({
          text: "You must acknowledge this message before closing it.",
          autoGlossarize: true
        });
        return;
      }

      if ((self.showAcknowledgement() && self.acknowledged()) || (self.showDoNotShow() && self.doNotShow())) {
        self.acknowledgeMessage();
      }

      if (self.showSendEmail() && self.sendEmail()) {
        self.emailMessage();
      }
    }

    if (self.dialogController) {
      self.dialogController.cancelled = true;
      self.dialogController.close();
    }
  },

  cancel: function () {
    this.close();
  },

  acknowledgeMessage: function () {
    if (this.acknowledgeError) {
      this.acknowledgeError.clear();
    }

    return http
      .post("/Communication/Message/AcknowledgeMessage", { messageKey: this.config.messageKey })
      .then(handleResponse);
  },

  emailMessage: function () {
    return http
      .post("/Communication/Message/EmailMessage", { messageKey: this.config.messageKey })
      .then(handleResponse);
  },

  openAttachment: function () {
    const url = plex.buildUrl("/Platform/DocumentControlSystem/ViewDocumentDialog", {
      NodeNo: this.config.attachment.nodeNo
    });
    plex.DialogController.create({
      route: url,
      autoShow: true
    });
  }
});

MessageController.create = function (config, model) {
  return new MessageController(config, model);
};

module.exports = MessageController;
plexExport("MessageController", MessageController);

function setAttachmentIconClass(extension) {
  const ext = extension && extension.length ? extension.toLowerCase() : "";

  if ([".ppt", ".pptx"].indexOf(ext) > -1) {
    return "plex-powerpoint-icon";
  }

  if ([".doc", ".docx", ".txt"].indexOf(ext) > -1) {
    return "plex-word-icon";
  }

  if ([".xls", ".xlsx", ".csv"].indexOf(ext) > -1) {
    return "plex-excel-icon";
  }

  if ([".zip", ".rar"].indexOf(ext) > -1) {
    return "plex-zip-icon";
  }

  if ([".png", ".gif", ".jpg", ".tiff", ".tif"].indexOf(ext) > -1) {
    return "plex-image-icon";
  }

  if (ext === ".pdf") {
    return "plex-pdf-icon";
  }

  if (ext === ".xml") {
    return "plex-xml-icon";
  }

  return "plex-file-icon";
}
