ï»¿const defaultValueProvider = require("./plex-grid-value-providers");
const CustomerDate = require("../../Globalization/plex-customer-date");
const dataUtils = require("../../Utilities/plex-utils-data");
const plexExport = require("../../../global-export");

const DateTimeValueProvider = defaultValueProvider.extend({
  getValue: function (record) {
    const dateTime = dataUtils.getValue(record, this.column.propertyName, this.config.trackClientUpdates);
    return dateTime ? new CustomerDate(dateTime) : null;
  }
});

module.exports = DateTimeValueProvider;
plexExport("grid.valueProviders.DateTimeValueProvider", DateTimeValueProvider);
