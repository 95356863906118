ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const pubsub = require("../Core/plex-pubsub");
const FilterController = require("../Controls/plex-controller-filter");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");

const ExecuteFilterSearch = Action.extend({
  onExecute: function () {
    const deferred = new $.Deferred();
    const self = this;

    if (!(this.parent instanceof FilterController)) {
      notify.error("ExecuteFilterSearchAction Source must be configured with a Filter.");
      return deferred.reject();
    }

    if (!this.config.searchActionId) {
      notify.error("ExecuteFilterSearchAction searchActionID must be configured.");
      return deferred.reject();
    }

    this.searchSubscription = pubsub.subscribe(
      "searched." + self.config.searchActionId,
      (results) => {
        pubsub.unsubscribe(self.searchSubscription);
        deferred.resolve(results);
      },
      self
    );

    // Call Search on Filter
    this.parent.search();

    return deferred.promise();
  }
});

module.exports = ExecuteFilterSearch;
plexExport("actions.ExecuteFilterSearch", ExecuteFilterSearch);
