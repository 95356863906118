ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const CssFeature = Feature.extend({
  onExecute: function () {
    /// <summary>Executes the CSS class feature on the given record.</summary>
    /// <returns type="PlainObject">The attributes to apply.</returns>

    return {
      css: this.config.cssClasses
    };
  }
});

module.exports = CssFeature;
plexExport("features.Css", CssFeature);
