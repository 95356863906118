ï»¿const ko = require("knockout");
const pageHandler = require("../Controls/plex-handler-page");

ko.bindingHandlers.ready = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    const bindings = allBindingsAccessor();
    viewModel = bindingContext.$data;

    pageHandler.onReady(() => {
      bindings.ready.call(viewModel, viewModel, element);
    });
  }
};
