/* eslint-disable no-console */
const root = require("./global-root");

function log(message) {
  if ("console" in window) {
    if (console.warn) {
      console.warn(message);
    } else {
      console.log(message);
    }
  }
}

const TESTING = /jsdom/.test(navigator.userAgent || "");

module.exports = function GlobalExport(ns, obj, replace) {
  const segments = ns.split(".");
  let current = root;
  let segment;

  while (segments.length > 1) {
    segment = segments.shift();
    if (!(segment in current)) {
      current[segment] = {};
    }

    current = current[segment];
  }

  if (!replace && segments[0] in current && !TESTING) {
    log("Attempt to replace: " + ns);
  }

  current[segments[0]] = obj;
  return obj;
};
