ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");

// todo:
// - respond to resize events
// - allow other arrows besides top
// - respond to 'collision's
// - allow options to be updated individually (and reposition appropriately)

const defaultOptions = {
  anchor: null,
  yOffset: 0,
  xOffset: 0,
  topCss: null,
  closeFn: function () {
    ko.removeNode(this.$element[0]);
  }
};

const Modeless = function (el, options) {
  this.$element = $(el);
  this.options = $.extend({}, defaultOptions, options);
  this.init();
};

Modeless.prototype = {
  constructor: Modeless,

  init: function () {
    if (this.options.anchor) {
      if (typeof this.options.anchor === "string") {
        this.$anchor = $(document.getElementById(this.options.anchor));
      } else {
        this.$anchor = $(this.options.anchor);
      }
    }

    $(document).on("click.plex.modeless." + this.$element[0].id, this._handleDocumentClick.bind(this));
    this.setPosition();
  },

  setPosition: function () {
    if (!this.$anchor) {
      return;
    }

    let vPosition = "center";
    let hPosition = "top";

    if (this.options.yOffset) {
      hPosition += "+" + this.options.yOffset;
    }

    if (this.options.xOffset) {
      vPosition += "+" + this.options.xOffset;
    }

    this.$element.position({
      at: "center bottom",
      my: vPosition + " " + hPosition,
      of: this.$anchor,
      collision: "flip"
    });

    if (this.options.topCss) {
      this.$element.addClass(this.options.topCss);
    }
  },

  destroy: function () {
    $(document).off("click.plex.modeless." + this.$element[0].id);
    this.options.closeFn();
    this.$element.removeData("modeless");
    this.$element = null;
    this.$anchor = null;
  },

  _handleDocumentClick: function (e) {
    // if the click was already handled - ignore
    if (e.isDefaultPrevented()) {
      return;
    }

    // if the click was from within the element - ignore
    if (this.$element[0] === e.target || this.$element[0].contains(e.target)) {
      return;
    }

    // if there is an anchor and the click was from within it - ignore
    if (this.$anchor && (this.$anchor[0] === e.target || this.$anchor[0].contains(e.target))) {
      return;
    }

    // check if target is above the current panel - this would occur if the panel invokes child modeless panels
    // note: not sure if this will catch all cases - it would probably be better if we had a central mechanism to
    // handle modal/modeless stacks
    const zIndex = this.$element.zIndex();
    if (zIndex > 0 && $(e.target).zIndex() > zIndex) {
      return;
    }

    // otherwise remove modeless
    this.destroy();
    e.preventDefault();
  }
};

$.fn.modeless = function (options) {
  return this.each(function () {
    const $this = $(this);
    const data = $this.data("modeless");

    if (data) {
      // todo: reset options
    } else {
      $this.data("modeless", new Modeless(this, options));
    }
  });
};

$.fn.modeless.constructor = Modeless;
