ï»¿const ko = require("knockout");
const $ = require("jquery");
const js = require("../Utilities/plex-utils-js");

ko.bindingHandlers.normalizeHeight = {
  update: function (element, valueAccessor) {
    const options = ko.unwrap(valueAccessor());
    js.defer(() => processElement(element, options));
  }
};

function processElement(element, options) {
  const value = $(element).attr("data-normalizeHeight");
  const padding = ko.unwrap(options.padding) || 0;

  const allParents = $('*[data-normalizeHeight="' + value + '"]');
  const allChildren = [];
  let maxHeight = 0;

  for (let j = 0; j < allParents.length; j++) {
    const children = $(allParents[j]).children();
    for (let h = 0; h < children.length; h++) {
      allChildren.push($(children[h]));
    }
  }

  for (let i = 0; i < allChildren.length; i++) {
    if (allChildren[i].outerHeight() > maxHeight) {
      maxHeight = allChildren[i].outerHeight(true);
    }
  }

  for (let i = 0; i < allParents.length; i++) {
    $(allParents[i]).css("height", maxHeight + padding + "px");
  }
}
