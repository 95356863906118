import "@plex/culture-timezone"; // eslint-disable-line import/no-unassigned-import
import React from "react";
import { LocalizationProvider } from "@plex/culture-react";
import { loadFrameworkTerms } from "../../Globalization/paris-adapter";
import plexImport from "../../../global-import";

let frameworkMessages = [];

export const ComponentLocale = ({ children }) => {
  const [messages, setMessages] = React.useState(frameworkMessages);

  React.useEffect(() => {
    if (messages.length === 0) {
      loadFrameworkTerms().then((terms) => {
        frameworkMessages = terms;
        setMessages(terms);
      });
    }
  }, [messages]);

  if (messages.length === 0) {
    return null;
  }

  let timezone = "America/New_York";
  let locale = "en-US";

  const appState = plexImport("appState");
  if (appState?.customer) {
    timezone = appState.customer.globalizationInfo.timeZone.description.split(" ")[0];
    locale = appState.customer.globalizationInfo.culture.cultureCode;
  }

  return (
    <LocalizationProvider locale={locale} timezone={timezone} messages={messages}>
      {children}
    </LocalizationProvider>
  );
};
