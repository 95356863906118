ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const styleUtils = require("../Utilities/plex-utils-style");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const protectedAttributes = ["id"];

const FeatureResult = function () {
  /// <summary>A result object for a Feature execution.</summary>

  this.attr = {};
  this.style = {};
  this.css = [];
  this.content = null;
  this.printContent = [];
  this.render = true;
  this.disabled = false;
  this.readOnly = false;

  this.promises = [];
};

FeatureResult.prototype = {
  constructor: FeatureResult,

  merge: function (result) {
    /// <summary>Merges in the values from another FeatureResult instance.</summary>
    /// <param name="Object">The FeatureResult instance.</param>

    if (jsUtils.isPromise(result)) {
      this.promises.push(result.then((r) => this.merge(r)));
      return;
    }

    if (result === false) {
      this.render = false;
      return;
    }

    if (!result || $.isEmptyObject(result)) {
      return;
    }

    $.extend(this.attr, result.attr);
    $.extend(this.style, result.style);

    if (result.css?.length > 0) {
      this.css = this.css.concat(result.css);
    }

    this.content = result.content || this.content;
    this.printContent = result.printContent || this.printContent;
    this.render = this.render && result.render !== false;
    this.disabled = this.disabled || result.disabled === true;
    this.readOnly = this.readOnly || result.readOnly === true;
  },

  diff: function (feature) {
    const diff = new FeatureResult();
    // eslint-disable-next-line eqeqeq
    if (feature.content == this.content) {
      delete diff.content;
    }

    // this will remove the property entirely if it doesn't change
    // since there will be no need to apply it
    diffBool(this, feature, diff, "disabled");
    diffBool(this, feature, diff, "readOnly");

    // this will blank out prior values that are missing in the new result
    // and keep values that have changed or are new
    // blanking them out should be enough to wipe out the values from the element
    diff.attr = diffObject(this.attr, feature.attr);
    diff.style = diffObject(this.style, feature.style);

    // simply clone the array and then get those that were removed
    diff.css = feature.css.slice(0);
    diff.removedCss = removedItems(this.css, feature.css);

    // remove protected attributes - these should not be updated
    protectedAttributes.forEach((name) => {
      delete diff.attr[name];
    });

    return diff;
  },

  getStyle: function () {
    return styleUtils.toJavaScriptStyle(this.style);
  },

  getCss: function () {
    return this.css.join(" ");
  },

  getAttr: function () {
    return this.attr;
  },

  getContent: function () {
    return this.content;
  },

  resolve: function () {
    if (this.promises.length > 0) {
      return $.when(...this.promises).then(() => {
        // remove all promises since they've all been resolved
        this.promises.length = 0;
        return this;
      });
    }

    return this;
  },

  isResolved: function () {
    return this.promises.length === 0;
  }
};

function diffObject(prior, current) {
  const obj = {};
  $.each(prior, (name) => {
    // if it's not in the new object clear it out
    // for our purposes this will be enough to know to remove it
    if (!(name in current)) {
      obj[name] = "";
    }
  });

  $.each(current, (name) => {
    // only copy attributes that are new or have changed
    // eslint-disable-next-line eqeqeq
    if (!(name in prior) || prior[name] != current[name]) {
      obj[name] = current[name];
    }
  });

  return obj;
}

function removedItems(prior, current) {
  const arr = [];
  prior.forEach((item) => {
    if (current.indexOf(item) === -1) {
      arr.push(item);
    }
  });

  return arr;
}

function diffBool(prior, current, diff, name) {
  if (prior[name] || current[name]) {
    diff[name] = current[name];
  } else {
    delete diff[name];
  }
}

module.exports = FeatureResult;
plexExport("features.FeatureResult", FeatureResult);
