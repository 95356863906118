const api = (module.exports = {});

const $ = require("jquery");
const plexExport = require("../../global-export");
const navigate = require("./plex-navigate");
const urlParsing = require("./plex-parsing-url");
const data = require("../Utilities/plex-utils-data");

const sourceActionKey = "sourceActionKey";

api.getDbFriendlyForm = function () {
  const qsExclusionKeys = [navigate.AppStateTokenName, navigate.FavoriteActionKeyTokenName, sourceActionKey];
  const qs = data.cleanse(urlParsing.parseQueryString());

  qsExclusionKeys.forEach((key) => {
    if (qs[key]) {
      delete qs[key];
    }
  });

  if (!$.isEmptyObject(qs)) {
    $.each(qs, (key, value) => {
      const stateHashIndex = value.indexOf("#__psid");
      if (stateHashIndex > -1) {
        qs[key] = value.substring(0, stateHashIndex);
      }
    });

    const result = JSON.stringify(qs);
    return result;
  }

  return undefined;
};

plexExport("queryString", api);
