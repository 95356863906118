ï»¿const ko = require("knockout");
const expressions = require("../../Expressions/plex-expressions-compiler");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

// todo: this is probably obsolete now that the logic to swap out column layout has moved to the MasterCellWriter.
// todo: It is used to define master.executeAction. Need to refactor to combine with MasterCellWriter.
const methodsToWrap = ["getValue", "getFormattedValue", "getHtml", "getTrackedHtml", "getEmptyHtml"];

const MasterColumnValueProvider = defaultValueProvider.extend({
  onInit: function () {
    const self = this;

    // setup evaluators
    this.column.columns.forEach((config) => {
      config.evaluator = expressions.compile(config.expression);
    });

    this.currentRecord = ko.observable();
    this.currentColumn = ko.computed(() => {
      const record = self.currentRecord();
      let column;

      if (record) {
        self.column.columns.forEach((config) => {
          if (config.evaluator(record)) {
            column = column || config.column;
          }
        });
      }

      return column || self.column.defaultColumn;
    });

    // intercept each of these methods and route to current columns value provider
    methodsToWrap.forEach((methodName) => {
      self[methodName] = function (record) {
        const valueProvider = self.getCurrentValueProvider(record);
        return valueProvider[methodName].apply(valueProvider, arguments);
      };
    });
  },

  getCurrentValueProvider: function (record) {
    // i think we are probably safe to update this record every time
    // i think knockout is smart enough to know not to trigger updates
    // with the same reference, but to be safe i've added this check
    if (this.currentRecord() !== record) {
      this.currentRecord(record);
    }

    return this.currentColumn().valueProvider;
  },

  getFormatter: function () {
    return this.currentColumn().valueProvider.getFormatter();
  }
});

module.exports = MasterColumnValueProvider;
plexExport("grid.valueProviders.MasterColumnValueProvider", MasterColumnValueProvider);
