ï»¿/* eslint-disable no-invalid-this */
const ResizeObserver = require("ResizeObserver");
const $ = require("jquery");
const js = require("../Utilities/plex-utils-js");
const domUtils = require("../Utilities/plex-utils-dom");
require("./plex-jquery"); // eslint-disable-line import/no-unassigned-import
require("./plex-fixedelement"); // eslint-disable-line import/no-unassigned-import

function getWindowBody(element) {
  if (element === document) {
    return document.body;
  }

  return element;
}

function getParentContainerWidth(actionBar) {
  const windowElement = getWindowBody(actionBar.$scrollParent[0]);
  let windowWidth = windowElement.offsetWidth;

  // only offset for scrollbar if the window has horizontal scrollbar
  if (windowWidth < windowElement.scrollWidth) {
    windowWidth -= $.getScrollBarSize().width;
  }

  return windowWidth;
}

function getContentWidth($el) {
  const contentWidth = $el
    .children(":visible")
    .get()
    .reduce((total, el) => total + $(el).outerWidth(), 0);
  return Math.ceil(Math.max($el.outerWidth(), contentWidth));
}

const ActionBar = function (el, options) {
  this.$element = $(el);
  this.options = options;
  this.init();
};

ActionBar.prototype = {
  constructor: ActionBar,

  init: function () {
    const boundController = this.options.controller.boundController;

    this.$scrollParent = this.$element.scrollParent();

    // setup window resize listener
    this.resizeObserver = new ResizeObserver(() => this.resize());
    this.resizeObserver.observe(this.$element[0]);
    this.resizeObserver.observe(getWindowBody(this.$scrollParent[0]));

    this.resize();

    if (!boundController || (boundController.config && boundController.config.usesFixedActionbar)) {
      const parent = this.$element.parent();

      parent
        .addClass("plex-grid-actions")
        .fixedElement({ fixedClass: "plex-actions-fixed", horizontal: true, vertical: true });

      // Dialog's min-width depends on actionBar's width if the
      // actionBar is wider than any other dialog's content.
      // When dialog is scrolled the actionBar is fixed, obtained
      // 'absolute' position and can't longer keep dialog's min-width.
      // Setting min-width here to retain dialog's initial width.
      // https://jira-plex.atlassian.net/browse/IP-5362
      if (domUtils.isInDialog(this.$element)) {
        parent.parent().css("min-width", parent.parent().width());
      }
    }
  },

  resize: js.throttleRender(function (forceResize) {
    const $list = this.$element;
    const currentParentWidth = getParentContainerWidth(this);
    const { marginLeft, marginRight } = $list.css(["marginLeft", "marginRight"]);
    const menuOffset = parseInt(marginLeft, 10) + parseInt(marginRight, 10);
    const listWidth = getContentWidth($list);

    // if the list has outgrown the window, force a resize - this can happen
    // with dynamic changes made to the actionbar
    forceResize = forceResize || listWidth + menuOffset > currentParentWidth;

    const $more = $list.find(".plex-actions-more");
    const $moreMenu = $more.find("> ul");
    let moreVisible = $moreMenu.children().length > 0 || $more.is(":visible");

    if (
      !forceResize &&
      !moreVisible &&
      this._priorParentWidth &&
      Math.abs(this._priorParentWidth - currentParentWidth) <= $.getScrollBarSize().width
    ) {
      return;
    }

    let $items, i, ln;

    if (!this._priorParentWidth || this._priorParentWidth > currentParentWidth || forceResize) {
      // screen is shrinking - move items until it fits
      $items = $list.children(":not(.plex-actions-more):visible");
      i = $items.length;

      while (i-- && getContentWidth($list) + menuOffset > currentParentWidth) {
        $moreMenu.prepend($items[i]);

        if (!moreVisible) {
          // go ahead and expose more menu now since it will impact the width of the list
          moreVisible = true;
          $more.show();
        }
      }
    } else if (moreVisible) {
      // screen is growing - add items back to bar as long as it fits (we only need to check if the more menu is visible)
      $items = $moreMenu.children();
      i = 0;
      ln = $items.length;

      while (getContentWidth($list) + menuOffset < currentParentWidth && i < ln) {
        $more.before($items[i]);

        if (i === ln - 1) {
          // we're on the last item - let's hide the more menu so it does not factor in.
          // it will be redisplayed if necessary afterwards.
          $more.hide();
        }

        // see if list is still below screen size
        // (the item width will be a different width now so we can't compare it before adding)
        if (getContentWidth($list) + menuOffset >= currentParentWidth) {
          // add back to more menu and exit loop
          $moreMenu.prepend($items[i]);
          break;
        }

        i++;
      }
    }

    // save the width for comparison next time around
    // recalc in case actionbar resized window (can happen with dialogs)
    this._priorParentWidth = getParentContainerWidth(this);

    // show more menu if items exist
    $more.toggle($moreMenu.children().length > 0);
  }),

  clearMoreMenu: function () {
    const $list = this.$element;
    const $more = $list.find(".plex-actions-more");
    const $morePrev = $more.prev();
    const $moreMenu = $more.find("> ul");
    let moreVisible = $moreMenu.children().length > 0 || $more.is(":visible");
    let $items, i, ln;

    if (moreVisible) {
      // screen is growing - add items back to bar as long as it fits (we only need to check if the more menu is visible)
      $items = $moreMenu.children();
      i = 0;
      ln = $items.length;
      moreVisible = false;

      while (i < ln) {
        $morePrev.after($items[i]);

        i++;
      }
    }
  },

  dispose: function () {
    const $fixedElement = this.$element.parent().data("fixedElement");
    if ($fixedElement) {
      $fixedElement.dispose();
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }
};

$.fn.actionbar = function (options) {
  return this.each(function () {
    const $this = $(this);
    let data = $this.data("actionbar");

    if (!data) {
      $this.data("actionbar", (data = new ActionBar(this, options)));
    }
  });
};

$.fn.actionbar.Constructor = ActionBar;
