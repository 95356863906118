ï»¿const regexUtils = require("../Utilities/plex-utils-regex");
const comparer = require("../Utilities/plex-utils-comparer");
const ConstantToken = require("../Tokens/plex-tokens-constant");
const TokenParser = require("../Tokens/plex-tokens-parser");
const compiler = require("./plex-expressions-compiler");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();
const noop = function () {
  // noop
};

// get an array of all operator symbols to create a regex out of
const operators = Object.keys(comparer.operators).map((op) => {
  return regexUtils.escapeText(op);
});

const operatorsRgx = new RegExp("\\s(" + operators.join("|") + ")\\s", "gi");

function createEvaluator(node) {
  if (node.begin || node.end) {
    return createRangeEvaluator(node);
  }

  let evalRequired = false;
  const tokens = tokenParser.parse(node.switchValue || node.testValue);
  const output = node.body ? compiler.compile(node.body) : noop;

  tokens.forEach((token) => {
    if (token instanceof ConstantToken) {
      const value = token.getValue();

      // two birds - one stone. replace any operators with the JS version
      // and determine whether we will need to evaluate the text (based on the presence of operators)
      token.setValue(
        value.replace(operatorsRgx, (_$0, $1) => {
          evalRequired = true;

          return " " + comparer.operators[$1].symbol + " ";
        })
      );
    }
  });

  const evaluator = tokenParser.compile(tokens);
  if (evalRequired) {
    return {
      output,
      getValue: function () {
        const evalText = evaluator.apply(null, arguments);
        /* eslint no-new-func: "off" */
        return new Function("return " + evalText + ";")();
      },
      test: function (_value, args) {
        return this.getValue.apply(null, args);
      }
    };
  }

  return {
    output,
    getValue: evaluator,
    test: function (value, args) {
      const testValue = this.getValue.apply(null, args);
      return comparer.equals(value, testValue);
    }
  };
}

function createRangeEvaluator(node) {
  const output = node.body ? compiler.compile(node.body) : noop;
  let beginFn, endFn;
  if (node.begin) {
    beginFn = tokenParser.compile(node.begin);
  }

  if (node.end) {
    endFn = tokenParser.compile(node.end);
  }

  return {
    output,
    test: function (value, args) {
      const begin = beginFn && beginFn.apply(null, args);
      const end = endFn && endFn.apply(null, args);

      if (begin == null && end == null) {
        return false;
      } else {
        return comparer.between(value, begin, end);
      }
    }
  };
}

function SwitchExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

SwitchExpression.prototype = {
  constructor: SwitchExpression,

  init: function (config) {
    // it looks that the value can be omitted when the cases not comparisons but evaluations
    const valueEvaluator = config.switchValue ? createEvaluator(config) : null;
    const defaultCase = config.defaultCaseBody ? compiler.compile(config.defaultCaseBody) : noop;
    const cases = [];
    const caseLn = config.cases.length; // we can cache length

    config.cases.forEach((c) => {
      cases.push(createEvaluator(c));
    });

    this.evaluator = function () {
      const value = valueEvaluator && valueEvaluator.getValue.apply(null, arguments);
      let i = -1;

      while (++i < caseLn) {
        if (cases[i].test(value, arguments)) {
          return cases[i].output.apply(null, arguments);
        }
      }

      return defaultCase.apply(null, arguments);
    };
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
};

module.exports = SwitchExpression;
plexExport("expressions.Switch", SwitchExpression);
