ï»¿const Action = require("./plex-actions");
const downloadFile = require("../Plugins/plex-filedownload");
const plexExport = require("../../global-export");

const DownloadAction = Action.extend({
  onExecute: function (data) {
    // only pass data when it has been tokenized
    data = this.tokens && this.tokens.length > 0 ? data : {};
    return downloadFile(this.address, { data });
  }
});

module.exports = DownloadAction;
plexExport("actions.Download", DownloadAction);
