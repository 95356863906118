ï»¿const ko = require("knockout");
const $ = require("jquery");
const gridUtils = require("../plex-grid-utils");
const operationTarget = require("../plex-grid-recordtarget");
const factory = require("../Aggregates/plex-grid-aggregate-factory");
const Writer = require("./plex-grid-writer-base");
const dataUtils = require("../../Utilities/plex-utils-data");
const repository = require("../../Controls/plex-model-repository");
const expressions = require("../../Expressions/plex-expressions-compiler");
const stringUtils = require("../../Utilities/plex-utils-strings");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const valueProviderFactory = require("../ValueProviders/plex-grid-value-providers-factory");
const plexExport = require("../../../global-export");

const FooterRowWriter = Writer.extend({
  init: function (grid, group, footer) {
    this._base.apply(this, arguments);

    this.group = group;
    this.footer = footer;
    this.hasGroup = "groupIndex" in this.group;
    this.features = {};

    if (footer.features && footer.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(footer.features, footer, this.parent);
    }

    if (footer.elements && footer.elements.length > 0) {
      if (!this.footer.valueProvider || typeof this.footer.valueProvider.getHtml !== "function") {
        this.footer.valueProvider = valueProviderFactory.create(this.footer, grid);
      }
    } else {
      this.footerTextEvaluator = footer.$$footerTextEvaluator = footer.expression
        ? expressions.compile(footer.expression)
        : createFooterTextEvaluator(footer);
    }

    this.onReset();
  },

  onReset: function () {
    const self = this;
    const ln = self.grid.columns.length;
    this.aggregates = [];

    this.footer.aggregates.forEach((config) => {
      let i = 0;
      let column, op;

      if (!config.columnId) {
        throw new Error("The column id was empty or did not exist");
      }

      for (; i < ln; i++) {
        if (self.grid.columns[i].id === config.columnId) {
          column = self.grid.columns[i];
          op = factory.create(column, config);
          op.colIndex = i;

          if (config.features && config.features.length > 0) {
            op.featureProcessor = new FeatureProcessor(config.features, op, self.parent);
          }

          self.aggregates.push(op);
        }
      }
    });

    // sort by column index
    this.aggregates.sort((a, b) => {
      return a.colIndex - b.colIndex;
    });
  },

  prerender: function (_record, _index, data, gIndex) {
    const sourceIsController = function (feature) {
      const model = repository.get(feature.config.sourceId);
      return model && model.$$controller;
    };

    const groupIndex = gIndex || 0;

    if (!this.featureProcessor || (data.length === 0 && !this.featureProcessor.features.every(sourceIsController))) {
      this.features[groupIndex] = { attr: {}, style: {}, css: [] };
      return true;
    }

    const featureObservable = this.featureProcessor.apply(this.column || this.grid, arguments);
    if (!featureObservable) {
      return false;
    }

    this.features[groupIndex] = featureObservable();
    if (this.features[groupIndex].render === false) {
      return false;
    }

    return featureObservable;
  },

  render: function (writer, data, config) {
    const self = this;
    this.config = config;

    // todo: we will probably rarely need both of these sets of data so it'd be nice to lazy load these
    const allData = gridUtils.aggregateData(data, false);
    const groupData = gridUtils.aggregateData(data, true);
    let isFirstCell = true;
    let i = 0;
    const operations = this.aggregates.filter((o) => {
      return o.column.visible();
    });

    // store print aggregate information in the footer for later use during printing
    this.footer.$$printOperations = this.aggregates.filter((o) => {
      return o.column.printVisible();
    });
    this.footer.$$printTotals = this.footer.$$printTotals || {};
    if (this.footer.$$printOperations.length > 0) {
      this.footer.$$printTotals[data.gIndex || 0] = this.createAggregateContext(
        this.footer.$$printOperations,
        allData,
        groupData,
        true
      );
    }

    // no operations are visible so skip rendering the footer
    if (operations.length === 0) {
      return;
    }

    const values = this.createAggregateContext(operations, allData, groupData);
    let colspan = config.checkable ? 1 : 0;
    if (self.grid.options && self.grid.options.ranking) {
      colspan++;
    }

    const tr = writer.appendChild("tr").addClass("plex-grid-footer");

    if (this.hasGroup) {
      if ("$$collapsed" in data && data.$$collapsed()) {
        tr.addClass("plex-grid-row-collapsed");
      }

      tr.addClass("plex-grid-footer-level-" + this.group.groupIndex);
      tr.addAttr("data-group-footer-index", data.gIndex);

      if (this.footer.printBreak) {
        tr.addAttr("data-print-break", JSON.stringify(this.footer.printBreak));
      }
    } else {
      tr.addClass("plex-grid-footer-total");
    }

    if (this.hasGroup && config.collapsible && config.checkable) {
      this._renderEmpty(tr, 1);
      colspan--;
    }

    const groupIndex = data.gIndex || 0;

    if (self.features[groupIndex]) {
      self._applyFeatures(tr, self.features[groupIndex]);
    }

    this.grid.columns.forEach((col, colIndex) => {
      let agg, features, context;

      if (col.visible()) {
        if (i < operations.length && colIndex === operations[i].colIndex) {
          agg = operations[i];

          if (colspan) {
            self._renderEmpty(tr, colspan, isFirstCell, allData[0]);
            colspan = 0;
            isFirstCell = false;
          }

          // use uppercase properties to comply with casing expected from C# expressions
          context = { Values: values };
          if (agg.config.trackClientUpdates) {
            context.Value = ko.computed(() => {
              return values[colIndex];
            });
            ko.track(context, ["Value"]);
          } else {
            context.Value = values[colIndex];
          }

          if (agg.featureProcessor) {
            /* eslint no-useless-call: "off" */
            features = agg.featureProcessor.apply(agg, [context])();
            features.merge({ css: agg.css });
          } else {
            features = { css: agg.css };
          }

          const td = tr.appendChild("td");
          self._applyFeatures(td, features);

          // If first cell is aggregate, add footertext to cell
          if (colspan === 0 && isFirstCell) {
            self.writeFooterText(allData[0], td);
            isFirstCell = false;
          }

          td.appendHtml(
            self.getCellHtml(agg, context, agg.config.target === operationTarget.record ? allData : groupData)
          );
          td.close();

          i++;
        } else {
          colspan++;
        }
      }
    });

    if (colspan) {
      this._renderEmpty(tr, colspan);
    }

    // add totals to data
    data.$$totals = data.$$totals || [];
    data.$$totals.push(values);

    tr.close();
  },

  getAggregateValue: function (agg, data, forPrint) {
    const self = this;
    if (agg.config.trackClientUpdates) {
      // wrap in computed so value will be reevaluated if data changes
      const observable = ko.observable();
      return ko.computed(() => {
        let value;
        if (forPrint === true) {
          value = agg.getPrintValue(data, self.footer, self.group);
        } else {
          value = agg.getValue(data, self.footer, self.group);
        }

        $.when(value).then((resolvedValue) => {
          observable(resolvedValue);
        });

        return observable();
      });
    }

    return forPrint === true
      ? agg.getPrintValue(data, self.footer, self.group)
      : agg.getValue(data, self.footer, self.group);
  },

  createAggregateContext: function (operations, allData, groupData, forPrint) {
    const context = {};
    let i = operations.length;
    const toTrack = [];
    let current, data;

    while (i--) {
      current = operations[i];
      data = current.config.target === operationTarget.record ? allData : groupData;

      context[current.column.id] = context[current.colIndex] = this.getAggregateValue(current, data, forPrint);
      if (current.config.trackClientUpdates) {
        toTrack.push(current.column.id);
        toTrack.push(current.colIndex);
      }
    }

    if (toTrack.length > 0) {
      // wrap in property getter setter so expression doesn't have to unwrap the observable
      ko.track(context, toTrack);
    }

    return context;
  },

  getCellHtml: function (agg, context, data) {
    if (agg.config.trackClientUpdates) {
      const formattedValue = ko.computed(() => {
        return agg.formatValue(context.Value, data);
      });

      return ko.renderTemplate("grid-text", { value: formattedValue });
    }

    return agg.formatValue(context.Value, data);
  },

  writeFooterText: function (record, writer) {
    let text;
    if (this.footerTextEvaluator) {
      text = this.footerTextEvaluator(record);
    } else {
      text = this.footer.valueProvider.getHtml(record) || this.footer.valueProvider.getEmptyHtml();
    }

    const span = writer.appendChild("span").addClass("plex-grid-footer-text");

    const collapsibleStyle = this._getCollapsibleIndentation();

    this.writeAttributes(span, collapsibleStyle.attr);
    this.writeStyle(span, collapsibleStyle.style);

    span.appendHtml(text);
    span.close();
  },

  _renderEmpty: function (writer, colspan, includeFooterText, record) {
    const td = writer.appendChild("td").addAttr("colspan", colspan);

    if (includeFooterText) {
      this.writeFooterText(record, td);
    } else {
      td.appendHtml("&nbsp;");
    }

    td.close();
  },

  _getCollapsibleIndentation: function () {
    const collapsibleStyle = { attr: {}, style: {} };

    if (this.group && "groupIndex" in this.group && this.config.collapsible) {
      collapsibleStyle.attr = {
        // note: this attribute provides a way for printing to determine the indentation
        // without resorting to supporting inline style
        "data-indentation-level": this.group.groupIndex
      };
      collapsibleStyle.style = {
        "padding-left": this.group.groupIndex * 15 + "px"
      };
    }

    return collapsibleStyle;
  },

  _applyFeatures: function (writer, features) {
    this.writeCss(writer, features.css);
    this.writeAttributes(writer, features.attr);
    this.writeStyle(writer, features.style);
  }
});

function createFooterTextEvaluator(footer) {
  return function (record) {
    if (!footer.footerText) {
      return "&nbsp;";
    }

    const tokens = [];
    if (footer.tokens.length > 0) {
      footer.tokens.forEach((token) => {
        tokens.push(dataUtils.getValue(record, token.propertyName));
      });

      return stringUtils.format(footer.footerText, tokens);
    }

    return footer.footerText;
  };
}

module.exports = FooterRowWriter;
plexExport("grid.writers.FooterRowWriter", FooterRowWriter);
