ï»¿const MasterLabelPrintController = require("./Controllers/plex-labelprinting-controller-masterunit");
const plexExport = require("../../../global-export");

const printMasterUnitLabel = function (masterUnitKeys, options) {
  const controller = new MasterLabelPrintController();
  const promise = controller.print(masterUnitKeys, options);
  return promise;
};

module.exports = printMasterUnitLabel;
plexExport("barcoding.labelPrinting.masterUnitLabels.printMasterUnitLabel", printMasterUnitLabel);
