ï»¿const ko = require("knockout");
const $ = require("jquery");

// this binding is intended to be used with checkboxes where the state of the checkbox
// is controlled by the bindings - the actual normal click event is only intended to trigger
// the toggling of the bound observables
ko.bindingHandlers.checkedOneWay = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const options = ko.unwrap(valueAccessor());
    let fn, cancelBubble, $el;

    if (options && typeof options === "object" && options.click) {
      fn = options.click;
      cancelBubble = options.cancelBubble;
    }

    if (fn) {
      $el = $(element).on("click.plex.checkedOneWay", function (event) {
        const vm = bindingContext.$data;

        // pass vm as first argument to be consistent with
        // knockout event handling
        const args = $.makeArray(arguments);
        args.unshift(vm);

        fn.apply(vm, args);

        if (cancelBubble && event.stopPropagation) {
          event.stopPropagation();
        }
      });

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        $el.off("click.plex.checkedOneWay");
      });
    }
  },

  update: function (element, valueAccessor) {
    let checked = ko.unwrap(valueAccessor());
    let indeterminate = false;

    if (checked && typeof checked === "object") {
      indeterminate = !!ko.unwrap(checked.indeterminate);
      checked = !!ko.unwrap(checked.checked);
    }

    $(element).prop("checked", checked).prop("indeterminate", indeterminate);
  }
};
