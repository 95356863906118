ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.highlightedRow = {
  init: function (element, valueAccessor) {
    const $elementContainer = $(element);
    const options = valueAccessor();
    const controlGroupClass = options.controlGroupClass;
    const hoverEvents = options.hoverEvents !== false; // default true
    const css = options.css || "plex-control-focus";

    $elementContainer
      .on("focus.plex.highlightRow", ":input", () => {
        $elementContainer.addClass(css);
      })
      .on("blur.plex.highlightRow", ":input", () => {
        $elementContainer.removeClass(css);
      });

    if (hoverEvents) {
      $elementContainer
        .on("mouseover.plex.highlightRow", () => {
          $elementContainer.addClass(css);
        })
        .on("mouseout.plex.highlightRow", () => {
          // only remove class if element
          const $el = $(document.activeElement);

          // remove the focus class unless the currently focussed element is within this container
          if (!$el.is(":input") || !$el.closest(controlGroupClass).is($elementContainer)) {
            $elementContainer.removeClass(css);
          }
        });
    }

    // cleanup
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $elementContainer.off(
        "focus.plex.highlightRow blur.plex.highlightRow mouseover.plex.highlightRow mouseout.plex.highlightRow"
      );
    });
  }
};
