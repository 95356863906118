ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const plexExport = require("../../global-export");

function AdvancedSearch() {
  this.init();
}

AdvancedSearch.prototype = {
  constructor: AdvancedSearch,

  init: function () {
    this.methods = [];
    this.methods.push(
      new SearchMethod(
        "__allWords",
        "All these words",
        function () {
          return {
            searchText: this.getText().split(/\s+/g).join(" "),
            searchTerm: "all of (" + this.getText().split(/\s+/g).join(" ") + ")"
          };
        },
        true
      )
    );

    this.methods.push(
      new SearchMethod(
        "__exactPhrase",
        "Exact word or phrase",
        function () {
          return {
            searchText: '"' + this.getText().replace(/"/g, '\\"') + '"',
            searchTerm: "exactly (" + this.getText().replace(/"/g, '\\"') + ")"
          };
        },
        false
      )
    );

    this.methods.push(
      new SearchMethod(
        "__anyWords",
        "Any of these words",
        function () {
          return {
            searchText: this.getText().split(/\s+/g).join(" OR "),
            searchTerm: "one of (" + this.getText().split(/\s+/g).join(" ") + ")"
          };
        },
        true
      )
    );

    this.methods.push(
      new SearchMethod(
        "__noneWords",
        "None of these words",
        function () {
          return {
            searchText: "-" + this.getText().split(/\s+/g).join(" -"),
            searchTerm: "none of (" + this.getText().split(/\s+/g).join(" ") + ")"
          };
        },
        true
      )
    );

    this.methods.push(
      new SearchMethod(
        "__startsWith",
        "Starts with",
        function () {
          return {
            searchText: "^" + this.getText(),
            searchTerm: "starts with (" + this.getText() + ")"
          };
        },
        true,
        -1
      )
    );

    this.methods.push(
      new SearchMethod(
        "__endsWith",
        "Ends with",
        function () {
          return {
            searchText: this.getText() + "$",
            searchTerm: "ends with (" + this.getText() + ")"
          };
        },
        true,
        99
      )
    );
  },

  reset: function () {
    this.methods.forEach((m) => {
      m.reset();
    });
  },

  build: function (useSearchTerm) {
    const terms = [];
    const methods = this.getFilteredMethods();

    methods.forEach((m) => {
      terms.push(useSearchTerm ? m.build().searchTerm : m.build().searchText);
    });

    return terms.join(" ");
  },

  getFilteredMethods: function () {
    // only get search methods that have a value set
    const methods = this.methods.filter((m) => {
      return !!m.getText();
    });

    // sort in build order - some are positional
    methods.sort((a, b) => {
      return a.buildOrder - b.buildOrder;
    });

    return methods;
  }
};

function SearchMethod(id, label, buildFn, trim, buildOrder) {
  this.id = id;
  this.label = label;
  this.build = buildFn;
  this.text = ko.observable();
  this.trim = trim;
  this.buildOrder = buildOrder || 0;

  this.reset = function () {
    this.text("");
  };

  this.getText = function () {
    let txt = this.text();

    if (txt && this.trim) {
      txt = txt.trim();
    }

    return txt;
  };
}

module.exports = AdvancedSearch;
plexExport("AdvancedSearch", AdvancedSearch);
