ï»¿const $ = require("jquery");
const plexExport = require("../../global-export");

const MAX_PROCESS_TIME = 50;
const UI_PAUSE_TIME = 25;
const timer = window.performance && window.performance.now ? window.performance.now.bind(window.performance) : Date.now;

function arrayChunker(arr, processTimeout, pauseTimeout) {
  /// <summary>Run a process against an array in chunks to prevent long running processes.</summary>
  /// <param name="arr">The array to process.</param>
  /// <param name="processTimeout">The milliseconds a process can last before pausing for UI updates. (Default 50)</param>
  /// <param name="pauseTimeout">The milliseconds to pause and let UI respond. (Default 25)</param>
  /// <returns type="Object">An object with callback registries for `each`, `chunk`, and `done`. Call `start` to initiate the processing and `cancel` to stop.</returns>

  let cancelPending = false;
  const deferred = new $.Deferred();

  // store callbacks
  let eachCallback = [];
  let batchCallback = [];

  // set defaults
  processTimeout = processTimeout || MAX_PROCESS_TIME;
  pauseTimeout = pauseTimeout || UI_PAUSE_TIME;

  function cleanup() {
    // clear all references
    arr = eachCallback = batchCallback = null;
  }

  function executeCallbacks(cbs, args) {
    let index = 0;
    const length = cbs.length;

    while (index < length) {
      cbs[index++].apply(arr, args);
      if (cancelPending) {
        return false;
      }
    }

    return !cancelPending;
  }

  function chunker(source, index) {
    const start = timer();
    let cancelled = false;
    let current = source.shift();

    do {
      if (!executeCallbacks(eachCallback, [current, index++, arr])) {
        cancelled = true;
        break;
      }

      // use do-while so we make sure at least one record is processed for each batch
    } while (timer() - start < processTimeout && (current = source.shift()));

    if (!cancelled && !executeCallbacks(batchCallback)) {
      cancelled = true;
    }

    if (!cancelled && source.length > 0) {
      setTimeout(chunker.bind(null, source, index), pauseTimeout);
    } else {
      deferred[cancelled ? "reject" : "resolve"]();
      cleanup();
    }
  }

  return {
    each: function (cb) {
      /// <summary>Add a callback to be executed against each row in the array.</summary>
      /// <param name="cb">The callback to execute.</param>

      eachCallback.push(cb);
      return this;
    },

    batch: function (cb) {
      /// <summary>Add a callback to be executed after a batch is processed.</summary>
      /// <param name="cb">The callback to execute.</param>

      batchCallback.push(cb);
      return this;
    },

    cancel: function () {
      /// <summary>Cancels any pending batches.</summary>

      cancelPending = true;
    },

    start: function () {
      /// <summary>Starts the processing.</summary>

      // create a clone of the array - we don't want intermittent changes to affect processing
      chunker(arr.slice(0), 0);
      return deferred.promise();
    }
  };
}

/**
 * Merge sort (http://en.wikipedia.org/wiki/Merge_sort)
 */
function mergeSort(arr, compareFn) {
  if (arr == null) {
    return [];
  }

  if (arr.length < 2) {
    return arr;
  }

  const mid = ~~(arr.length / 2);
  const left = mergeSort(arr.slice(0, mid), compareFn);
  const right = mergeSort(arr.slice(mid, arr.length), compareFn);

  return merge(left, right, compareFn);
}

function merge(left, right, compareFn) {
  const result = [];

  while (left.length && right.length) {
    if (compareFn(left[0], right[0]) <= 0) {
      // if 0 it should preserve same order (stable)
      result.push(left.shift());
    } else {
      result.push(right.shift());
    }
  }

  if (left.length) {
    result.push.apply(result, left);
  }

  if (right.length) {
    result.push.apply(result, right);
  }

  return result;
}

function removeEmpty(arr) {
  /// <summary>Removes null and empty objects from the provided array.</summary>

  let i = arr.length;
  while (i--) {
    const empty = !arr[i] || $.isEmptyObject(arr[i]);
    if (empty) {
      arr.splice(i, 1);
    }
  }
}

function removeDuplicates(duplicateArray) {
  /// <summary>Removes duplicate values from the provided array.</summary>

  const uniqueArray = duplicateArray
    .reverse()
    .filter((e, i, array) => {
      return array.indexOf(e, i + 1) === -1;
    })
    .reverse();

  return uniqueArray;
}

/**
 * Performs left join on arrays on provided properties.
 * @param {array} left - The left array which gets modified and returned as a join result with `mutate` option.
 * @param {array} right - The right array gets purged on matching records with `mutate` option.
 * @param {string|array} on - The properties to perform left join on.
 * @param {object} options - The `mutate` option modifies the provided arrays else a new array is returned and `emptyRecord` is used when there's no matching record in the right array.
 * @returns {array} join result.
 */
function leftJoin(left, right, on, options) {
  const opts = options || { mutate: false, emptyRecord: {} };
  const result = opts.mutate ? left : [];
  const emptyRecord = opts.emptyRecord || {};

  let joinOn = on;
  if (!Array.isArray(joinOn)) {
    joinOn = [joinOn];
  }

  left.forEach((l) => {
    const i = right.findIndex((ri) => joinOn.every((property) => l[property] === ri[property]));
    const r = i > -1 ? right.at(i) : {};

    if (opts.mutate) {
      Object.assign(l, emptyRecord, r);
      if (i > -1) {
        // for lookup performance, remove joined record from right array
        right.splice(i, 1);
      }
    } else {
      result.push($.extend({}, l, emptyRecord, r));
    }
  });

  return result;
}

const api = {
  // todo: obsolete `mergeSort`
  mergeSort,
  stableSort: mergeSort,
  removeEmpty,
  chunk: arrayChunker,
  removeDuplicates,
  leftJoin
};

module.exports = api;
plexExport("arrays", api);
