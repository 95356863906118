ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const dataSourceFactory = require("../Data/plex-datasource-factory");
const controllerFactory = require("./plex-controller-factory");
const ElementHandler = require("./plex-handler-element");
const bindingHandler = require("./plex-handler-bindings");
const onReady = require("./plex-handler-page").onReady;
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");

const SelectionListController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

function SelectionListItem(data, elements) {
  this.data = data;
  this.elements = elements;
}

SelectionListController.prototype = {
  constructor: SelectionListController,

  init: function () {
    const self = this;
    this.selected = this.config.selected;
    this.$element = $("#" + this.config.id);
    this.selectionListItems = ko.observableArray();
    this.data = ko.observableArray();
    this.remoteData = this.config.dataSource.route != null;
    this.subscriptions = [];

    if (!this.config.dataSource) {
      notify.error({
        text: "The data source has not been set on the {1} element.",
        tokens: [this.config.handle || this.config.displayPropertyName]
      });
      return;
    }

    if (this.config.dataSource) {
      if (this.config.dataSource.propertyName) {
        this.config.dataSource.data = dataUtils.getValue(this.model, this.config.dataSource.propertyName);
      }

      this.dataSource = dataSourceFactory.create(this.config.dataSource.route || this.config.dataSource);
      this.load();
    }

    if (this.config.bindings && this.config.bindings.length > 0) {
      this.subscriptions.push(this.config.boundValue.subscribe(this.applyBindings, this));
    }

    if (this.remoteData) {
      // delay execution so dependencies have a chance to process
      onReady(() => {
        // wrap execution in computed - this will allow us to trigger a reload whenever a dependency changes
        const loader = ko.computed(self.load, self);

        // whenever a dependency changes, trigger a load
        loader.subscribe(self.load, self);
        self.subscriptions.push(loader);
      });
    }

    this.config.displayValue = ko.computed(this.getDisplayValue, this);

    // Radio does not support array for checked binding,
    // only checkboxes support them
    if (this.config.multiSelect === false) {
      this.selectedOption = ko.computed({
        read: function () {
          return self.selected().length > 0 ? self.selected()[0] : "";
        },
        write: function (value) {
          self.selected([value]);
        }
      });
    }

    // Trees and Grids will render this through the template
    if (this.$element[0]) {
      ko.renderTemplate(
        "elements-selectionlistitem",
        { options: { controller: this } },
        {},
        this.$element.children("div").first(),
        "replaceNode"
      );
    }
  },

  // This is required for objects, since selected object wont equal datasource object
  mapSelected: function () {
    const self = this;
    const selected = [];

    if (!self.config.boundValue) {
      notify.error({
        text: "The Data Value property is not set on the {1} element.",
        tokens: [self.config.handle || self.config.displayPropertyName]
      });
      return;
    }
    if (self.config.boundValue().length === 0) {
      return;
    }

    self.config.boundValue().forEach((item) => {
      self.selectionListItems().forEach((selectionListItem) => {
        if (item === selectionListItem.data[self.config.valuePropertyName]) {
          selected.push(selectionListItem.data);
        }
      });
    });

    self.config.selected(selected);
  },

  setInitialSelection: function () {
    // only set an initial value for radio's without a value
    if (
      this.config.type !== "radio" ||
      this.config.boundValue().length > 0 ||
      (typeof this.config.setInitialSelection !== "undefined" && this.config.setInitialSelection === false)
    ) {
      return;
    }

    if (this.data().length > 0) {
      this.selected([this.selectionListItems()[0].data]);
    }
  },

  setValue: function (selectedValues) {
    this.selected.removeAll();
    if (dataUtils.isEmpty(selectedValues)) {
      return;
    }

    selectedValues = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    const propertyName = this.config.valuePropertyName || this.config.propertyName;
    const normalizedValues = selectedValues.map((item) => {
      if (typeof item !== "object") {
        // if a key is passed in use that to resolve value
        return { [propertyName]: item };
      }

      if (!(propertyName in item) && "boundValue" in item) {
        // values coming from initial value feature will be in boundValue/boundDisplayValue format
        return { [propertyName]: item.boundValue };
      }

      return item;
    });

    const selected = this.selectionListItems()
      .filter((item) => {
        return normalizedValues.some((value) => {
          // note: need to ignore type for use with static datasources
          // eslint-disable-next-line eqeqeq
          return item.data[propertyName] == value[propertyName];
        });
      })
      .map((item) => {
        return item.data;
      });

    this.selected(selected);

    this.applyBindings();
  },

  load: function () {
    if (this.dataSource.isLoading() && this.currentRequest) {
      this.currentRequest.abort();
    }

    this.reset();

    this.currentRequest = this.dataSource.get().done(this.initData.bind(this));
  },

  initData: function (results) {
    this.data(results);
    this.initSelectionListItems(results);
    this.mapSelected();
    this.setInitialSelection();
  },

  initSelectionListItems: function (results) {
    const self = this;

    results.forEach((result) => {
      const elements = self.initElements(result);
      self.selectionListItems.push(new SelectionListItem(result, elements));
    });
  },

  initElements: function (result) {
    const self = this;
    const elements = [];

    if (self.config.elements) {
      self.config.elements.forEach((el, index) => {
        const clone = $.extend(true, {}, ko.toJS(el));
        clone.id = el.id + "-" + index;
        ElementHandler.initElement(clone, result, null, self);
        elements.push(clone);
      });
    }

    return elements;
  },

  getDisplayValue: function () {
    const values = [];
    const prop = this.config.displayPropertyName;
    const selected = this.selected();

    if (selected) {
      // radios will be a single item
      if (Array.isArray(selected)) {
        selected.forEach((record) => {
          values.push(record[prop]);
        });
      } else {
        values.push(selected[prop]);
      }
    }

    return values.join(this.config.displayValueDelimiter || ", ");
  },

  getState: function () {
    return {
      selected: Array.isArray(this.selected())
        ? dataUtils.cleanse(this.selected(), { flatten: false })
        : dataUtils.cleanse([this.selected()], { flatten: false })
    };
  },

  reset: function () {
    this.dataSource.reset();
    this.selectionListItems.removeAll();
  },

  restoreState: function (state) {
    const self = this;
    const propertyName = self.config.valuePropertyName || self.config.propertyName;

    const filterSelected = function () {
      const matches = self.data().filter((item) => {
        return state.selected.some((stateItem) => {
          if (!stateItem) {
            return false;
          }
          // just do coersive check in case we lose type during serialization
          /* eslint eqeqeq: "off" */
          return stateItem[propertyName] == item[propertyName];
        });
      });

      return self.config.multiSelect ? matches : [matches[0]];
    };

    if (this.data().length > 0 && state && state.selected && state.selected.length > 0) {
      this.selected(filterSelected());
    } else if (this.config.multiSelect) {
      this.selected.removeAll();
    } else {
      this.selected([]);
    }
  },

  applyBindings: function () {
    if (this.config.bindings && this.config.bindings.length > 0) {
      bindingHandler.update(this.config.bindings, this.selected());
    }
  },

  dispose: function () {
    let sub;

    while ((sub = this.subscriptions.pop())) {
      sub.dispose();
    }
  }
};

SelectionListController.create = function (config, model) {
  return new SelectionListController(config, model);
};

controllerFactory.register("Elements/_SelectionList", SelectionListController);

module.exports = SelectionListController;
plexExport("SelectionListController", SelectionListController);
