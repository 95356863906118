ï»¿const ko = require("knockout");
const $ = require("jquery");
const dcsAttachments = require("../Utilities/plex-utils-dcs-attachments");
const guid = require("../Utilities/plex-utils-guid");
const notify = require("../Core/plex-notify");
const plexImport = require("../../global-import");

ko.bindingHandlers.dcsAttachmentDropbox = {
  init: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    const config = value.config;
    const record = value.data;

    // uploading not allowed
    if (ko.unwrap(config.readOnly)) {
      return;
    }

    const $element = $(element);
    const $input = $element.find("input");
    if ($input.length === 0) {
      return;
    }

    // get the drop zone
    let $dropZone = null;
    if (config.dropZoneId) {
      $dropZone = $(document.getElementById(config.dropZoneId));
    }

    if (!$dropZone || $dropZone.length === 0) {
      const $grid = $element.closest(".plex-grid-container");
      if ($grid.length === 0) {
        $dropZone = $element;
      } else {
        const grid = plexImport("currentPage")[$grid[0].id];
        if (grid) {
          $dropZone =
            grid.config.columns.filter((col) => col.attachmentGroupKey).length > 1
              ? $element.closest("td")
              : $element.closest("tr");
        }
      }
    }

    const attachmentContext = dcsAttachments.createAttachmentContext(record, config);

    // progress indicator
    let progressId = null;
    const progress = ko.observable();

    // fileupload plugin
    $input.fileupload({
      url: dcsAttachments.createAddAttachmentUrl(attachmentContext),
      dropZone: $dropZone,
      dataType: "json",
      maxFileSize: dcsAttachments.getUploadSizeLimit(),

      start: function () {
        if (!progressId) {
          progressId = guid.create();
          $(
            `<!-- ko stopBindings: true --><div id='${progressId}' style='margin-top: 10px'></div><!-- /ko -->`
          ).appendTo($element);
          ko.applyBindingsToNode(document.getElementById(progressId), {
            progressBar: {
              value: progress,
              options: { displayText: false, colorClass: "success" }
            }
          });
        }

        progress(0);
      },
      stop: function () {
        progress("hide");
      },
      dragover: function () {
        $dropZone.addClass("plex-drop-attachments");
      },
      drop: function () {
        $dropZone.removeClass("plex-drop-attachments");
      },
      dragleave: function () {
        $dropZone.removeClass("plex-drop-attachments");
      },
      add: function (e, data) {
        let valid = true;
        let errorMessage = "";
        let uploadSizeValidation;
        $.each(data.originalFiles, (_idx, file) => {
          uploadSizeValidation = dcsAttachments.validateUploadSize(file);
          valid = valid && uploadSizeValidation.valid;
          if (uploadSizeValidation.valid === false) {
            errorMessage = uploadSizeValidation.error;
          }
        });

        if (valid) {
          data.submit();
        } else {
          notify.error(errorMessage);
        }
      },
      done: function (_e, data) {
        // validate the result
        const result = data.result || {};
        if (result.ValidationResult ? !result.ValidationResult.Success : !result.Success) {
          notify.warning(
            result.ValidationResult ? result.ValidationResult.Message : result.Message || "Failed to upload."
          );
        }

        const attachmentCountToken = dcsAttachments.getAttachmentsCountTokenName(attachmentContext.attachmentGroupKey);
        const attachmentCountObservable = record[attachmentCountToken];

        if (ko.isObservable(attachmentCountObservable)) {
          dcsAttachments.getAttachmentsCount(attachmentContext).then(attachmentCountObservable);
        }
      },
      fail: function (_e, _data) {
        notify.warning({ text: "Failed to upload.", autoGlossarize: true });
      },
      progressall: function (_e, data) {
        progress(parseInt((data.loaded / data.total) * 100, 10));
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $input.fileupload("destroy");
    });
  }
};
