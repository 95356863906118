const $ = require("jquery");
const ask = require("./plex-dialogs-ask");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

function Confirm(question) {
  const deferred = new $.Deferred();
  ask(question, ["Yes", "No"], "Yes", (answer) => {
    if (answer && answer.answerValue === "Yes") {
      deferred.resolve();
    } else {
      deferred.reject(answer);
    }
  });

  return deferred.promise();
}

plexExport("Dialogs.Confirm", Confirm);

module.exports = (...args) => {
  // Using global so that local references will pick up replaced instance for SPA.
  return plexImport("Dialogs.Confirm")(...args);
};
