ï»¿const DefaultColumn = require("./plex-grid-column");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const columns = plexImport("grid.columns");

const ColumnBuilder = {
  build: function (column, parent) {
    const builder = column.type in columns ? new columns[column.type]() : new DefaultColumn();
    builder.parent = parent;
    builder.init(column);
  }
};

module.exports = ColumnBuilder;
plexExport("grid.ColumnBuilder", ColumnBuilder);
