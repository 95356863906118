const Globalize = require("globalize");
const $ = require("jquery");
const numberCulture = require("./plex-culture-number");
const jsUtils = require("../Utilities/plex-utils-js");
const stringUtils = require("../Utilities/plex-utils-strings");
const regexUtils = require("../Utilities/plex-utils-regex");
const plexExport = require("../../global-export");

const filter = Array.prototype.filter;

const MIN_DATE = new Date(Date.UTC(1753, 0, 2));
const MAX_DATE = new Date(Date.UTC(9999, 11, 30));

const nonAlphaNumericPattern = /[^a-zA-Z0-9]/;

// these are the keywords used throughout CLDR to represent the days of the week
const dayKeywords = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const dotNetToOptions = {
  d: { date: "short" },
  D: { date: "full" },
  f: { skeleton: "yyyyMMMMEEEEddjjmm" },
  F: { datetime: "long" },
  g: { datetime: "short" },
  G: { skeleton: "yMdhms" },
  S: { raw: "yyyy'-'MM'-'dd'T'HH':'mm':'ss" },
  t: { time: "short" },
  T: { time: "long" },
  u: { raw: "yyyy'-'MM'-'dd HH':'mm':'ssXX" }
};

const getFormatter = jsUtils.memoize((options) => {
  return Globalize.dateFormatter(options);
});

const getParser = jsUtils.memoize((options) => {
  return Globalize.dateParser(options);
});

function getDayPeriods(style = "wide") {
  const periods = Globalize.locale().main(["dates/calendars/gregorian/dayPeriods/format", style]);
  return ["am", "pm", "am-alt-variant", "pm-alt-variant"].map((kw) => periods[kw]).filter(Boolean);
}

let dayPeriodMatcher;
function hasDayPeriod(dateString) {
  if (dayPeriodMatcher === false) {
    return false;
  }

  if (!dayPeriodMatcher) {
    const periods = getDayPeriods("wide").concat(getDayPeriods("narrow"));
    if (periods.length === 0) {
      dayPeriodMatcher = false;
      return false;
    }

    dayPeriodMatcher = new RegExp(periods.map(regexUtils.escapeText).join("|"));
  }

  return dayPeriodMatcher.test(dateString);
}

function sanitizeDayPeriod(dateString) {
  // using `some` so we can break out early
  ["wide", "narrow"].some((style) => {
    const patterns = Globalize.locale().main(["dates/calendars/gregorian/dayPeriods/format", style]);

    return ["am", "pm"].some((period) => {
      const pattern = patterns[period + "-alt-variant"];
      if (pattern && dateString.indexOf(pattern) >= 0) {
        // Globalize only looks at the exact am/pm pattern when matching
        // so we need to replace it if the user uses the alt pattern
        // https://github.com/globalizejs/globalize/issues/740
        dateString = dateString.replace(pattern, patterns[period]);
        return true;
      }

      return false;
    });
  });

  return dateString;
}

function sanitizeDateTimeString(value, dateSeparator, timeSeparator) {
  let parts = [[], []];
  let index = 0;

  // we're simply leveraging the replace function to iterate over the matches
  // note that we're adding a space so the ending numeric segment will match
  (value.trim() + " ").replace(/(\d+)([^\d]+)/g, ($0, $1, $2) => {
    let separator = $2.trim();
    if (separator.length === 0) {
      // this would indicate 1 or more spaces - keep single space
      parts[index].push($1 + " ");
      return;
    }

    if (separator.length > 1) {
      // we don't know what this is (likely am/pm indicator, but could be invalid)
      parts[index].push($0);
      return;
    }

    if (separator === timeSeparator) {
      index = 1;
    }

    if (index === 0) {
      if (parts[0].length >= 2) {
        separator = "";
      } else {
        separator = dateSeparator;
      }
    }

    parts[index].push($1 + separator);
  });

  parts = parts.map((p) => p.join("").trim());
  if (parts[0] && parts[1]) {
    return combineDateTimeString(parts[0], parts[1], "short");
  }

  return parts[0] || parts[1];
}

function parseUnsegmentedDateString(dateString, pattern, dateSeparator) {
  const schemas = [];

  // map to viable segment lengths - we'll let the date parser do the actual validation
  switch (dateString.length) {
    case 8:
      schemas.push({ y: 4, M: 2, d: 2 });
      break;

    case 7:
      schemas.push({ y: 4, M: 1, d: 2 });
      schemas.push({ y: 4, M: 2, d: 1 });
      break;

    case 6:
      schemas.push({ y: 4, M: 1, d: 1 });
      schemas.push({ y: 2, M: 2, d: 2 });
      break;

    case 5:
      schemas.push({ y: 2, M: 1, d: 2 });
      schemas.push({ y: 2, M: 2, d: 1 });
      break;

    case 4:
      schemas.push({ y: 2, M: 1, d: 1 });
      break;

    default:
      // invalid length - only dates are parsed at this time
      return null;
  }

  const segments = pattern.split(nonAlphaNumericPattern);

  const candidates = schemas.map((schema) => {
    const candidate = { dt: [], pt: [] };
    let position = 0;

    for (let i = 0, ln = segments.length; i < ln; i++) {
      const segment = segments[i][0];
      if (!(segment in schema)) {
        continue;
      }

      const length = schema[segment];
      candidate.dt.push(dateString.substr(position, length));
      candidate.pt.push(stringUtils.repeat(length, segment));
      position += length;
    }

    return candidate;
  });

  const resolvedDates = candidates
    .map((c) => {
      try {
        return getParser({ raw: c.pt.join(dateSeparator) })(c.dt.join(dateSeparator));
      } catch (err) {
        return false;
      }
    })
    .filter((dt) => dt && dt >= MIN_DATE && dt <= MAX_DATE);

  if (resolvedDates.length === 1) {
    // only return a value if the date is unambiguous
    return resolvedDates[0];
  }

  return null;
}

function combineDateTimeString(datePart, timePart, format = "short") {
  const cldr = Globalize.locale();
  const dateTimeFormat = cldr.main(["dates/calendars/gregorian/dateTimeFormats", format]);
  return stringUtils.format(dateTimeFormat, timePart, datePart);
}

function getDotNetMapping(dotNetOptions) {
  if (!dotNetOptions) {
    return undefined;
  }

  const format = typeof dotNetOptions === "object" ? dotNetOptions.format : dotNetOptions;
  if (typeof format !== "string") {
    return undefined;
  }

  return dotNetToOptions[format];
}

const api = {
  /**
   * Gets the customer's week start day index, 0-6 (Sunday-Saturday).
   *
   * @returns {Number} The customer day index.
   */
  firstDay: function () {
    return dayKeywords.indexOf(Globalize.locale().supplemental.weekData.firstDay());
  },

  /**
   * Converts a .Net based format string into the CLDR equivalent.
   *
   * @param {Object|String} configOrFormat - the .Net format string or UX Format object
   * @returns {String} The equivalent CLDR format string
   * @see {@link http://www.unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns}
   * @see {@link https://msdn.microsoft.com/en-us/library/az4se3k1(v=vs.110).aspx}
   */
  getCldrDateTimeFormat: function (configOrFormat) {
    let formatString;
    if (configOrFormat && typeof configOrFormat === "object") {
      formatString = configOrFormat.format;
    } else if (typeof configOrFormat === "string") {
      formatString = configOrFormat;
    }

    const options = dotNetToOptions[formatString];
    if (options) {
      if (options.raw) {
        return options.raw;
      }

      const cldr = Globalize.locale();
      if (options.skeleton) {
        let skeletonFormat = cldr.main([
          "dates/calendars/gregorian/dateTimeFormats/availableFormats",
          options.skeleton
        ]);
        if (skeletonFormat == null) {
          // the available format may be listed separately as date/time - check to see if we can find the individual parts
          // note that this is very hacky and should be improved (either here or Globalize should expose an option to convert
          // a skeleton into a CLDR format string)
          // todo: handle cases where the skeleton does not have a predefined format, approximating the format
          // all date segments, scoped from least specific to most.
          const datePatternMatcher = /G{1,5}|y+|Y|u+|U{1,5}|r+|Q{1,5}|q{1,5}|M{1,5}|L{1,5}|l|w{1,2}|W|d{1,2}|D{1,3}|F|g+|E{1,6}|e{1,6}|c{1,6}/g;
          let index = 0;
          let m;

          while ((m = datePatternMatcher.exec(options.skeleton))) {
            index = m.index + m[0].length;
          }

          let datePart = options.skeleton.substr(0, index);
          let timePart = options.skeleton.substr(index);

          datePart = datePart && cldr.main(["dates/calendars/gregorian/dateTimeFormats/availableFormats", datePart]);
          timePart = timePart && cldr.main(["dates/calendars/gregorian/dateTimeFormats/availableFormats", timePart]);
          skeletonFormat = combineDateTimeString(datePart, timePart);
        }

        return skeletonFormat;
      }

      if (options.date) {
        return cldr.main(["dates/calendars/gregorian/dateFormats", options.date]);
      }

      if (options.time) {
        return cldr.main(["dates/calendars/gregorian/timeFormats", options.time]);
      }

      if (options.datetime) {
        const datePart = cldr.main(["dates/calendars/gregorian/dateFormats", options.datetime]);
        const timePart = cldr.main(["dates/calendars/gregorian/timeFormats", options.datetime]);
        return combineDateTimeString(datePart, timePart, options.datetime);
      }

      throw Error("Unable to convert to CLDR format.");
    }

    // return standalone keyword if the format only contains a single specifier
    if (/^m+$/.test(formatString)) {
      return "L".repeat(formatString.length);
    }

    if (/^d+$/.test(formatString)) {
      return "c".repeat(formatString.length);
    }

    return (
      formatString
        // replace full day names
        .replace(/dddd/g, "EEEE")
        .replace(/ddd/g, "EEE")
        // replace fractional seconds
        .replace(/f/g, "S")
        // replace era
        .replace(/g/g, "G")
        // timezone, with locale indicator
        .replace(/K/g, "XXXXX")
        // am-pm indicator
        .replace(/tt/g, "a")
        .replace(/t/g, "aaaaa")
        // replace offset
        .replace(/zzz/g, "XXXX")
        .replace(/zz/g, "X")
        .replace(/z/g, "X")
    );
  },

  /**
   * Gets the CLDR day keyword for the given day index.
   * @returns {String} CLDR day keyword.
   */
  getDayKeyword: function (index) {
    return dayKeywords[index];
  },

  /**
   * Gets the culture specific weekday names.
   * @param {String} [style=wide] - The style to return. Available styles are "abbreviated", "narrow", "short", and "wide".
   * @returns {String[]} An array of day names in the specified format.
   */
  getDayNames: function (style = "wide") {
    const days = Globalize.locale().main(["dates/calendars/gregorian/days/stand-alone", style]);
    return dayKeywords.map((kw) => days[kw]);
  },

  /**
   * Gets the culture specific day periods (ie AM/PM). This will return an empty array if the culture does not support AM/PM time designations.
   * @param {String} [style=wide] - The style to return. Available styles are "abbreviated", "narrow", "short", and "wide".
   * @returns {String[]} An array of day names in the specified format.
   */
  getDayPeriods,

  /**
   * Determines if the current culture uses the 24 hour cycle. Assumes that the "short" display format 24 hour specification
   *  matches the other display formats (i.e. using "h" or "H" for 12 or 24 hour display respectively).
   * @return  {bool} true if the current culture uses the 24 hour cycle, else returns false.
   */
  uses24HourCycle: function () {
    const shortFormat = Globalize.locale().main(["dates/calendars/gregorian/timeFormats/short"]);
    // Ignore all literals in the format while searching for the 24 hour cycle symbol.
    const regexExpression = /.*H(?=([^']*'[^']*')*[^']*$)/;
    return regexExpression.test(shortFormat);
  },

  /**
   * Gets the culture specific month names. The return values will be in an array with 0=January, etc.
   * @param {String} [style=wide] - The style to return. Available styles are "abbreviated", "narrow", "short", and "wide".
   * @returns {String[]} An array of month names in the specified format.
   */
  getMonthNames: function (style = "wide") {
    const monthNames = Globalize.locale().main(["dates/calendars/gregorian/months/stand-alone", style]);

    // this is a 1-based arraylike object, so convert to a 0-based array
    const arr = [];
    for (let i = 1; i < 13; i++) {
      arr.push(monthNames[i]);
    }

    return arr;
  },

  /**
   * Format the given date value into a culture specific string. These can use .Net format patterns, CLDR format patterns, or Globalize options.
   * Note that the preferred format is to use an options argument with a "skeleton" argument.
   * @param {Date} value - The date value to be formatted.
   * @param {String|Object} patternOrOptions - The options to be used for formatting.
   * @param {string} patternOrOptions.skeleton - The token format from highest to lowest precision. These will be returned in an order specified by the current culture.
   * @param {string} patternOrOptions.raw - The raw CLDR format to be used
   * @param {string} patternOrOptions.date - Keywords "full", "long", "medium", "short"
   * @param {string} patternOrOptions.time - Keywords "full", "long", "medium", "short"
   * @param {string} patternOrOptions.datetime - Keywords "full", "long", "medium", "short"
   *
   * @see {@link http://www.unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns}
   * @see {@link https://msdn.microsoft.com/en-us/library/az4se3k1(v=vs.110).aspx}
   * @see {@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   */
  formatDate: function (value, patternOrOptions) {
    if (!(value instanceof Date)) {
      return value == null ? "" : String(value);
    }

    let options;
    if (patternOrOptions && typeof patternOrOptions === "object" && !patternOrOptions.format) {
      options = patternOrOptions;
    } else {
      options = getDotNetMapping(patternOrOptions);
      if (!options) {
        const pattern = api.getCldrDateTimeFormat(patternOrOptions);
        if (pattern) {
          options = { raw: pattern };
        }
      }
    }

    // hack to account for https://github.com/globalizejs/globalize/issues/753
    if (options && options.skeleton && /sS/.test(options.skeleton)) {
      const millisecondsLength = /(S+)/g.exec(options.skeleton)[1].length;
      const secondsLength = /(s+)/g.exec(options.skeleton)[1].length;

      // remove millisecond portion, we have to add it back manually
      // (create copy so as to not overwrite the original options)
      options = $.extend({}, options, { skeleton: options.skeleton.replace(/S/g, "") });
      const parts = Globalize.dateToPartsFormatter(options)(value);

      return parts
        .map((p) => {
          if (p.type === "second") {
            const numberFormat = "0".repeat(secondsLength) + "." + "0".repeat(millisecondsLength);
            const numberValue = Number(
              `${value.getSeconds()}.${String(value.getMilliseconds()).padStart(millisecondsLength, "0")}`
            );
            return numberCulture.formatNumber(numberValue, numberFormat);
          }

          return p.value;
        })
        .join("");
    }

    return getFormatter(options)(value);
  },

  /**
   * @see api.formatDate
   */
  format: function (value, config) {
    return this.formatDate(value, config);
  },

  /**
   * Parses the given string into a Date object. If the string cannot be parsed null is returned.
   *
   * @param {String} dateString - The string to parse.
   * @param {Object} [formatOptions] - The options used to format the date string (optional).
   * @returns {Date|null} The parsed date or null.
   */
  parseDate: function (dateString, formatOptions) {
    if (!dateString) {
      return null;
    }

    const locale = Globalize.locale();

    if (formatOptions) {
      // first let's try to parse it with the provided options
      try {
        const dateValue = getParser(formatOptions)(dateString);
        if (dateValue) {
          return dateValue;
        }
      } catch (err) {
        // ignore
      }
    }

    // time separator is standardized in CLDR, however date separator is not
    // so we deduce it by examining the short date pattern - Globalize has
    // become very strict about parsing dates and will fail if the date separator
    // is different than what is in the pattern
    const shortPattern = locale.main("dates/calendars/gregorian/dateFormats/short");
    const [dateSeparator] = nonAlphaNumericPattern.exec(shortPattern);

    const numberingSystem = locale.main("numbers/defaultNumberingSystem");
    const timeSeparator = locale.main(`numbers/symbols-numberSystem-${numberingSystem}/timeSeparator`);

    if (/^\d+$/.test(dateString)) {
      return parseUnsegmentedDateString(dateString, shortPattern, dateSeparator);
    } else {
      dateString = sanitizeDateTimeString(dateString, dateSeparator, timeSeparator);

      let skeleton = "";
      if (dateString.indexOf(dateSeparator) > 0) {
        skeleton += "yMd";
      }

      if (dateString.indexOf(timeSeparator) > 0) {
        if (hasDayPeriod(dateString)) {
          skeleton += "jm";
          dateString = sanitizeDayPeriod(dateString);
        } else {
          skeleton += "Hm";
        }

        if (filter.call(dateString, (c) => c === timeSeparator).length > 1) {
          skeleton += "s";
        }
      }

      return skeleton ? getParser({ skeleton })(dateString) : null;
    }
  },

  /**
   * The minimum date able to be persisted.
   * @member {Date}
   */
  MIN_DATE,

  /**
   * The maxiumu date able to be persisted.
   * @member {Date}
   */
  MAX_DATE
};

module.exports = api;

plexExport("culture.formatDate", api.formatDate);
plexExport("culture.parseDate", api.parseDate);

// for backwards compatibility
plexExport("dates.parseDate", jsUtils.deprecate(api.parseDate, "Use plex.culture.parseDate instead."));
