ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const dialogController = require("./plex-dialog-controller");
const http = require("../Core/plex-navigate");
const notify = require("../Core/plex-notify");
const plexImport = require("../../global-import");

const defaultOptions = {
  autoGlossarize: true,
  rejectOnEmpty: true
};

function PINValidate(options, callback) {
  if (!(this instanceof PINValidate)) {
    return new PINValidate(options, callback);
  }

  const $deferred = new $.Deferred();

  if (typeof options === "function") {
    callback = options;
    options = null;
  }

  options = $.extend({}, defaultOptions, options);

  this.$element = $("<div class='dialog-wrapper'>").appendTo(document.body);

  this.question = options.question
    ? { text: options.question, autoGlossarize: options.autoGlossarize }
    : "Please enter your credentials:";
  this.badgeNo = ko.observable("");
  this.userId = ko.observable("");
  this.pin = ko.observable("");
  this.config = options;
  this.title = options.title ? { text: options.title, autoGlossarize: options.autoGlossarize } : "Verify PIN";
  this.okButtonText = options.okButtonText
    ? { text: options.okButtonText, autoGlossarize: options.autoGlossarize }
    : "Ok";
  this.badgeNoFocus = ko.observable(false);
  this.userIdFocus = ko.observable(false);
  this.pinFocus = ko.observable(false);

  this.sendAnswer = () => {
    if (options.mustValidate) {
      let promise = new $.Deferred();

      if (
        (options.badgeNoVisibility || options.userIdVisibility) &&
        (options.displayFieldsSequentially || options.displayPasswordKeyboard)
      ) {
        if (options.badgeNoVisibility && options.displayType === "textbox") {
          promise = http.post("/Security/UserVerification/ValidateBadgeNo", { badgeNo: ko.unwrap(this.badgeNo) });
          this.badgeNoFocus(true);
        } else if (options.userIdVisibility && options.displayType === "textbox") {
          promise = http.post("/Security/UserVerification/ValidateUserId", { userId: ko.unwrap(this.userId) });
          this.userIdFocus(true);
        } else if (options.displayType === "password") {
          promise = http.post("/Security/UserVerification/ValidatePIN", { pin: ko.unwrap(this.pin), pun: options.pun });
          this.pinFocus(true);
        }
      } else if (options.badgeNoVisibility === false && options.userIdVisibility === false) {
        promise = http.post("/Security/UserVerification/ValidatePIN", { pin: ko.unwrap(this.pin), pun: options.pun });
        this.pinFocus(true);
      } else if (options.badgeNoVisibility) {
        promise = http.post("/Security/UserVerification/ValidatePINAndBadgeNo", {
          pin: ko.unwrap(this.pin),
          badgeNo: ko.unwrap(this.badgeNo)
        });
        this.badgeNoFocus(true);
      } else {
        promise = http.post("/Security/UserVerification/ValidatePINAndUserId", {
          pin: ko.unwrap(this.pin),
          userId: ko.unwrap(this.userId)
        });
        this.userIdFocus(true);
      }

      promise.then((res) => {
        if (res.ValidationResult && res.ValidationResult.Success === false) {
          notify.warning(res.ValidationResult.Message);
        } else {
          this.dialog.close({ pun: res.Data.PUN, isVerified: true });
        }
      });
    } else {
      this.dialog.close({
        pin: ko.unwrap(this.pin),
        pun: options.pun,
        userId: ko.unwrap(this.userId),
        badgeNo: ko.unwrap(this.badgeNo)
      });
    }
  };

  ko.renderTemplate("dialog-validate", this, {}, this.$element[0]);

  this.dialog = dialogController.create({
    dialogElement: this.$element.find(".plex-dialog"),
    autoShow: true,
    returnAction: function (data) {
      if (callback) {
        callback(data);
      }

      if (data || !options.rejectOnEmpty) {
        $deferred.resolve(data);
      } else {
        $deferred.reject();
      }
    },
    closeAction: $deferred.reject
  });

  if (options.displayPasswordKeyboard) {
    const visualKeyboard = plexImport("visualKeyboard", true);
    if (visualKeyboard) {
      const isPassword = options.displayType === "password";
      const id = isPassword ? "ValidateDialog_Password_Keyboard" : "ValidateDialog_Keyboard";
      let boundId;

      if (isPassword) {
        boundId = "ValidateDialog_PIN";
      } else if (options.badgeNoVisibility) {
        boundId = "ValidateDialog_BadgeNo";
      } else {
        boundId = "ValidateDialog_UserId";
      }

      setTimeout(() => {
        visualKeyboard.setupKeyboardElement({
          id,
          boundInputIds: [boundId],
          onAccept: this.sendAnswer,
          model: {
            layout: { address: boundId === "ValidateDialog_PIN" ? "PINPad" : "ElementKeyboardLayout" },
            scale: options.keyboardScale || 1
          }
        });
      }, 300);
    }
  }

  return $deferred.promise();
}

module.exports = PINValidate;
