/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const DefaultXmlProvider = require("./plex-printing-xml-provider");
const plexExport = require("../../../global-export");

const DocumentGalleryXmlProvider = DefaultXmlProvider.extend({
  onGetXml: function () {
    const printingLimit = 10;
    let counter = 0;
    const collection = ko.unwrap(this.controller.gallery);

    const self = this;

    // eslint-disable-next-line consistent-return
    $.each(collection, function () {
      if (counter >= printingLimit) {
        return false;
      }

      const galleryItemXml = this.getXml();
      if (galleryItemXml) {
        self.node.appendXml(galleryItemXml);
        counter++;
      }
    });

    return this.node;
  }
});

module.exports = DocumentGalleryXmlProvider;

plexExport("printing.xmlProviders.documentgallery", DocumentGalleryXmlProvider);
