ï»¿const $ = require("jquery");
const ko = require("knockout");
const validation = require("../Core/plex-validation");
const printing = require("../Core/plex-printing");
const nav = require("../Core/plex-navigate");
const banner = require("../Plugins/plex-banner");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const Controller = require("./plex-controller-base");
const ActionsController = require("./plex-controller-action-container");
const dom = require("../Utilities/plex-utils-dom");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const pubsub = require("../Core/plex-pubsub");

// handle toggling of collapsible form sections
$(document).on("click keydown", ".plex-form-legend-container", (e) => {
  const $fieldset = $(e.target).closest("fieldset.plex-fieldset-collapsible");
  if (e.type === "click" || e.which === 13) {
    // toggle collapsed so all visible inputs become hidden
    $fieldset.toggleClass("collapsed");
  }
});

function isInFormContainer($el) {
  return $el.parents(".plex-form-row-container").length > 0;
}

const FormController = Controller.extend({
  onInit: function () {
    const self = this;

    this.$form = self.$element.parents(".plex-form");
    this.sections = this.config.sections;
    this.config.usesFixedActionbar = dom.isInDialog(this.$element);

    // Validation Elements
    this.$elements = {};

    const validationModel = self.config.validationModel || {};
    self.validator = validation.createValidator(this.$form, validationModel, self);

    // initialize form itself so that it can respond to basic bindings
    this.initElement(this.config);

    this.sections.forEach((section) => {
      section.parentElement = self.config;
      self.initElement(section);
    });

    if (self.config.subtitleSection) {
      self.initElement(self.config.subtitleSection);
    }

    if (this.config.enableSectionsMenu) {
      pubsub.publish("formSectionsLoaded", { sections: this.sections });
    }

    if (self.config.loadDefaultFilters) {
      this.loadElementDefaults();
    }

    this.saveOriginalData();
    this.prepareData();

    this.bindActions();

    ko.applyBindings(self, self.$element[0]);

    if (!isInFormContainer(this.$element)) {
      this._handlePersistentBanner();
    }

    // NOTE: Since we normally don't use the default submit behavior of forms, we can do this. Calling submit on the form
    // does not fire the onsubmit function.
    if (this.$form[0]) {
      this.$form[0].onsubmit = () => false;
    }
  },

  saveDefaults: function () {
    const self = this;

    let actionKey = self.config.cloudApplicationActionKey;
    if (actionKey == null) {
      actionKey = plexImport("currentApplication").ActionKey;
    }

    const formDefaults = {
      CloudApplicationActionKey: actionKey,
      DefaultJSON: self.getDbFriendlyFormState()
    };

    const promise = nav.post(nav.buildUrl("/Platform/FilterDefault/Update"), formDefaults, {
      ajax: this.ajax
    });

    promise.done(() => {
      banner.getBanner().setMessage("Defaults have been saved.", { status: banner.states.success });
    });
  },

  getDbFriendlyFormState: function () {
    return JSON.stringify(this.getState(false));
  },

  onPostInit: function () {
    const currentPage = plexImport("currentPage");
    Object.keys(currentPage).forEach((id) => {
      // bug fix: https://jira.epam.com/jira/browse/PLEXUXC-87301
      // after assignment
      //    this.elements[id] = plex.currentPage[id]
      // this.elements[id] is loosing observable properties (visible, css, style,...)
      // the loop below copies observable properties from the this.elements[id] to the plex.currentPage[id]
      // property copied only if the target object does not contain it
      // $.extend() considered as not safe opeartion as we can break original modifications
      for (const prop in this.elements[id]) {
        if (
          Object.prototype.hasOwnProperty.call(this.elements[id], prop) &&
          ko.isObservable(this.elements[id][prop]) &&
          !Object.prototype.hasOwnProperty.call(currentPage[id], prop)
        ) {
          currentPage[id][prop] = this.elements[id][prop];
        }
      }

      // replace child elements with full implementations (for grids)

      this.elements[id] = currentPage[id];
    });
  },

  bindActions: function () {
    this.actionsController = new ActionsController(this, this.config.formActions);
  },

  validate: function () {
    return this.validator.validateAll();
  },

  undoAll: function () {
    this.restoreState(this.originalState);
  },

  reset: function () {
    const self = this;

    $.each(self.elements, (elem) => {
      const element = self.elements[elem];
      if (element && "undoAll" in element) {
        element.undoAll();
      }
    });
  },

  toDocumentXml: function () {
    const self = this;
    const doc = new DocumentXml("plex-form");

    // Form Header
    if (self.config.subtitleSection) {
      const subtitleElements = printing.filterElements(self.config.subtitleSection.elements);
      if (subtitleElements.length > 0) {
        const header = doc.createNode("plex-form-header");
        subtitleElements.forEach((element) => {
          header.addControlElement(element);
        });
      }
    }

    // Form Body
    if (self.config.sections) {
      const columns = doc.createNode("plex-form-columns");
      printing.filterElements(self.config.sections).forEach((section) => {
        // only include sections which have printable elements
        const elements = printing.filterElements(section.elements);
        if (elements && elements.length > 0) {
          let title = "";
          if (section.title) {
            title = section.title.peek() || "";
          }

          const controlGroups = columns
            .createNode("plex-form-section")
            .addAttribute(columns._getElementAttributes(section))
            .addElement("section-title", title.toUpperCase())
            .createNode("section-elements");

          printing.filterElements(section.elements).forEach((element) => {
            let plexControls;
            switch (element.viewName) {
              case "Elements/_FormRow":
                plexControls = controlGroups
                  .createNode("plex-formrow")
                  .addControlElement(element.label)
                  .createNode("plex-controls");
                break;

              case "Elements/_FormElementRow":
                plexControls = controlGroups.createNode("plex-formelementrow").createNode("plex-controls");
                break;

              default:
                // do nothing
                break;
            }

            if (plexControls) {
              printing.filterElements(element.elements).forEach((controlElement) => {
                plexControls.addControlElement(controlElement);
              });
            }
          });
        }
      });
    }

    return doc.serialize();
  },

  _handlePersistentBanner: function () {
    const bannerEnvElement = this.$form.prevAll(".plex-env-persistent-banner-container:first");
    const bannerElement = this.$form.prevAll(".plex-persistent-banner-container:first");
    const bannerControl = bannerElement.data("banner");
    const $form = this.$form;

    if (!bannerControl) {
      return;
    }

    const originalTop = $form.position().top;
    const bannerEnvElementHeight = typeof bannerEnvElement.height() === "undefined" ? 0 : bannerEnvElement.height();
    bannerControl.messages.subscribeAndCall(() => {
      $form.css("top", originalTop + bannerEnvElementHeight + bannerElement.height() + "px");
    });
  }
});

FormController.create = function () {
  return new FormController();
};

module.exports = FormController;
plexExport("FormController", FormController);
