ï»¿const ko = require("knockout");
const $ = require("jquery");
const Controller = require("./plex-controller-base");
const GridController = require("./plex-controller-grid");
const pubsub = require("../Core/plex-pubsub");
const elementHandler = require("./plex-handler-element");
const repository = require("./plex-model-repository");
const expressions = require("../Expressions/plex-expressions-compiler");
const dataUtils = require("../Utilities/plex-utils-data");
const plexExport = require("../../global-export");

const returnTrue = function () {
  return true;
};

const MasterGridController = Controller.extend({
  onInit: function () {
    const self = this;

    this._disposables = [];
    this._gridDisposables = [];

    this.grids = this.config.grids;
    this.grids.forEach((config) => {
      config.evaluator = config.expression ? expressions.compile(config.expression) : returnTrue;
      config.grid.master = self;
    });

    self.config.usesFixedActionbar = true;

    // If user did not use extension method to set default grid
    if (this.config.defaultGrid) {
      this.config.defaultGrid.master = self;
    }

    this.source = repository.get(this.config.sourceId);
    this.searched = ko.observable(false).extend({ notify: "always" });

    this.currentLayout = ko.pureComputed(() => {
      const data = dataUtils.cleanse(self.source, { ignoreEmpty: false, detectDependencies: true });
      const layout = ko.utils.arrayFirst(self.grids, (config) => {
        return config.evaluator(data);
      });
      return layout ? layout.grid : self.config.defaultGrid;
    });

    if (this.config.autoUpdate) {
      this.currentLayout.subscribe(this.reset, this);
    } else {
      this.subscribeToSearch();
    }

    this._disposables.push(pubsub.subscribe("deleted." + this.config.id, this.onDelete, this));
    elementHandler.initElement(this.config, null, null, this);
    this.reset();
  },

  renderComplete: function (value) {
    if (this.grid && typeof this.grid.renderComplete === "function") {
      return this.grid.renderComplete(value);
    }

    return ko.observable(false);
  },

  subscribeToSearch: function () {
    const self = this;
    let searchId = this.grids.length > 0 && this.grids[0].grid.searchActionId;

    // check for mismatched search actions - these will be handled differently
    if (
      searchId &&
      this.grids.some((layout) => {
        return layout.grid.searchActionId !== searchId;
      })
    ) {
      this.setupGridSearches();
      return;
    }

    this.grids.forEach((layout) => {
      layout.grid.searchActionId = "";
    });

    if (this.config.defaultGrid) {
      searchId = searchId || this.config.defaultGrid.searchActionId;
      this.config.defaultGrid.searchActionId = "";
    }

    if (searchId) {
      this._disposables.push(
        pubsub.subscribe("searched." + searchId, (data) => {
          self.setLayout(data);
          self.grid.searched(true);
        })
      );

      this.config.searchActionId = searchId;
    }
  },

  setupGridSearches: function () {
    const self = this;
    let priorGrid = this.currentLayout();
    let searchSub;

    this._disposables.push({
      dispose: function () {
        searchSub && searchSub.dispose();
      }
    });

    function subscribe(searchId) {
      searchSub && searchSub.dispose();
      searchSub = pubsub.subscribe("searched." + searchId, (data) => {
        self.setLayout(data);
        self.grid.searched(true);
      });
    }

    this.grids.forEach((layout) => {
      const thisGrid = layout.grid;
      const searchId = thisGrid.searchActionId;

      // clear search ID - we will be managing the searches
      thisGrid.searchActionId = "";
      if (searchId) {
        self._disposables.push(
          pubsub.subscribe("searching." + searchId, () => {
            const currentGrid = self.currentLayout();
            if (thisGrid === priorGrid && thisGrid !== currentGrid) {
              // this grid was the prior grid - we need to unsubscribe now so the search does not fire
              searchSub && searchSub.dispose();
              return;
            }

            if (currentGrid !== thisGrid || currentGrid === priorGrid) {
              return;
            }

            subscribe(searchId);
            priorGrid = thisGrid;
          })
        );

        // setup initial subscription
        if (priorGrid === thisGrid) {
          subscribe(searchId);
        }
      }
    });
  },

  reset: function () {
    let data = null;
    let searched;

    if (this.grid) {
      searched = this.grid.searched();
      data = this.grid.datasource.source();
      this.grid.datasource.dispose();
    }

    this.setLayout(data);

    // copy search status forward
    if (searched !== undefined) {
      this.grid.searched(searched);
    }
  },

  setLayout: function (data) {
    const layout = this.currentLayout();
    if (!layout) {
      return;
    }

    const gridCss = "plex-grid-container";

    if (layout === this.priorLayout && this.grid) {
      // if the grid is already displaying just update the data
      this.grid.load(data || []);
    } else {
      // remove grid which was rendered on the server
      ko.cleanNode(this.$element[0]);
      while (this.$element[0].firstChild) {
        ko.removeNode(this.$element[0].firstChild);
      }

      if (this.grid) {
        this.grid.dispose();
        ko.removeNode(this.$grid[0]);
      }

      if (this.$gridActions) {
        ko.removeNode(this.$gridActions[0]);
      }

      if (layout.gridActions.length > 0) {
        this.$gridActions = $("<div class='plex-grid-buttons'>").insertAfter(this.$element);
      }

      this.$grid = $(`<div class='${gridCss}' id='${layout.id}'>`).appendTo(this.$element);

      this.grid = new GridController();

      // Pass a deep clone of the layout to preserve columnGroups
      this.grid.init(this.$grid, $.extend(true, {}, layout), data);
      this.validator = this.grid.validator;
      this.listenForGridEvents();
      pubsub.publish("layoutChanged.mastergrid." + this.config.id);

      repository.remove(this.config.id);
    }

    this.priorLayout = layout;
  },

  load: function (data) {
    if (this.grid) {
      this.grid.load(data);
    }
  },

  listenForGridEvents: function () {
    const self = this;
    let sub;

    while ((sub = this._gridDisposables.pop())) {
      sub.dispose();
    }

    this._gridDisposables.push(
      this.grid.selected.subscribe((values) => {
        const selected = self.grid.multiSelect ? values : values[0];

        // Allow user to access selected data from Mastergrid ID
        repository.add(self.config.id, selected);

        pubsub.publish("selected.grid." + self.config.id, selected || null);
      })
    );

    this.searched(this.grid.searched());
    this._gridDisposables.push(
      this.grid.searched.subscribe((value) => {
        self.searched(value);
      })
    );

    pubsub.publish(
      "selected.grid." + self.config.id,
      self.grid.multiSelect ? self.grid.selected() : self.grid.selected()[0]
    );

    if (this.grid.config.groups && this.grid.config.groups.length > 0) {
      this._gridDisposables.push(
        this.grid.selectedGroup.subscribe((value) => {
          pubsub.publish("selectedGroup.grid." + self.config.id, value);
        })
      );
    }

    this._gridDisposables.push(
      this.grid.dirtyRecords.subscribe((values) => {
        pubsub.publish("modified.grid." + self.config.id, values);
      })
    );
  },

  onDelete: function (items) {
    if (!this.grid || !this.grid.datasource) {
      return;
    }

    if (!Array.isArray(items)) {
      items = [items];
    }

    let i = items.length;
    while (i--) {
      this.grid.datasource.source.remove(items[i]);
    }
  },

  dispose: function () {
    let sub;
    while ((sub = this._gridDisposables.pop() || this._disposables.pop())) {
      sub.dispose();
    }

    repository.remove(this.config.id);

    if (this.grid) {
      repository.remove(this.grid.config.id);
      this.grid.dispose();
    }
  }
});

MasterGridController.create = function () {
  return new MasterGridController();
};

module.exports = MasterGridController;
plexExport("MasterGridController", MasterGridController);
