ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const controllerFactory = require("./plex-controller-factory");
const elementHandler = require("./plex-handler-element");
const DialogController = require("../Dialogs/plex-dialog-controller");
const plexExport = require("../../global-export");

const TokenExpressionBuilderController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init(config, model);
};

function insertAtCaret(controller, textValue) {
  const textArea = $(".plex-token-expression-modal-field")[0];

  if (document.selection) {
    textArea.focus();
    const textSelectionRange = document.selection.createRange();
    textSelectionRange.text = textValue;
    textArea.focus();
  } else if (textArea.selectionStart || textArea.selectionStart === "0") {
    const startingCharacterPosition = textArea.selectionStart;
    const endingCharacterPosition = textArea.selectionEnd;
    const scrollTop = textArea.scrollTop;

    textArea.value =
      textArea.value.substring(0, startingCharacterPosition) +
      textValue +
      textArea.value.substring(endingCharacterPosition, textArea.value.length);
    textArea.focus();
    textArea.selectionStart = startingCharacterPosition + textValue.length;
    textArea.selectionEnd = startingCharacterPosition + textValue.length;
    textArea.scrollTop = scrollTop;
  } else {
    textArea.value += textValue;
    textArea.focus();
  }

  controller.model[controller.config.propertyName] = textArea.value;
}

function loadTokenPicker(controller) {
  elementHandler.initElement(controller.config, controller.model, null, controller);
}

TokenExpressionBuilderController.prototype = {
  init: function (config, model) {
    const self = this;

    if (config.tokenPicker) {
      this.tokenPickerController = { config: config.tokenPicker, model };

      loadTokenPicker(this.tokenPickerController);

      this.tokenPickerController.elements[this.tokenPickerController.config.id].selected.subscribe((token) => {
        if (token.length > 0) {
          let formattedToken = token[0][self.tokenPickerController.config.valuePropertyName];
          formattedToken = "[" + formattedToken + "]";

          insertAtCaret(self, formattedToken);

          token.splice(0, 1);
        }
      });
    }
  },

  openEditDialog: function () {
    const self = this;

    self.$element = $("<div class='dialog-wrapper'>").appendTo(document.body);

    ko.renderTemplate("token-expression-builder-modal", self, {}, self.$element[0]);

    DialogController.create({
      dialogElement: self.$element.find(".plex-dialog"),
      autoShow: true
    });
  },

  selectToken: function (token) {
    const self = this;

    insertAtCaret(self, token);
  }
};

// set min-width for plex-token-expression-modal-tool-container based on max-width of inner pickers
$(document).on("shown.bs.modal", () => {
  $(".plex-token-expression-modal-tool-container").each(function () {
    const pickerWidths = $(this)
      .find(".plex-picker-control")
      .map((index, pickerControl) => $(pickerControl).outerWidth(true));
    const maxPickerWidth = Math.max(...pickerWidths);
    $(this).css("min-width", maxPickerWidth + 10);
  });
});

// factory method
TokenExpressionBuilderController.create = function (config, model) {
  return new TokenExpressionBuilderController(config, model);
};

controllerFactory.register("Elements/_TokenExpressionBuilder", TokenExpressionBuilderController);

module.exports = TokenExpressionBuilderController;
plexExport("TokenExpressionBuilderController", TokenExpressionBuilderController);
