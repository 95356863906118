ï»¿const TokenParser = require("../Tokens/plex-tokens-parser");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();

const TokenValueProvider = DefaultDataLabelValueProvider.extend({
  init: function () {
    this.evaluator = tokenParser.compile(this.options.valueProvider.tokenText);
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
});

module.exports = TokenValueProvider;
plexExport("labels.valueProviders.TokenValueProvider", TokenValueProvider);
