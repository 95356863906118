/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const jsUtils = require("../../Utilities/plex-utils-js");
const dataUtils = require("../../Utilities/plex-utils-data");
const formatUtils = require("../../Globalization/plex-formatting");
const htmlUtils = require("../../Utilities/plex-utils-html");
const stringUtils = require("../../Utilities/plex-utils-strings");
const plexExport = require("../../../global-export");

function DefaultValueProvider(column) {
  this.column = column;
}

DefaultValueProvider.prototype = {
  init: function () {
    if (typeof this.onInit === "function") {
      this.onInit();
    }
  },

  getHtml: function (_record, _index, _colIndex, _features) {
    if (this.config.trackClientUpdates) {
      return this.getTrackedHtml.apply(this, arguments);
    }

    const formattedValue = this.getFormattedValue.apply(this, arguments);
    return this.column.type === "Text"
      ? stringUtils.escapeHtml(formattedValue, { ignoreLineBreaks: true })
      : formattedValue;
  },

  getTrackedHtml: function (record, _index, _colIndex, _features) {
    const self = this;
    const args = arguments;
    const value = ko.computed(() => {
      return self.column.type === "Text"
        ? stringUtils.escapeHtml(self.getValue.apply(self, args), { ignoreLineBreaks: true })
        : self.getValue.apply(self, args);
    });

    return self.renderTemplate(this.templateName, { value, formatter: this.column.formatter, data: record });
  },

  templateName: "grid-text",

  getValue: function (record, _index, _colIndex) {
    return dataUtils.getValue(record, this.column.propertyName, this.config.trackClientUpdates);
  },

  getFormattedValue: function (record, _index, _colIndex, _features) {
    const value = this.getValue.apply(this, arguments);
    return formatUtils.formatValue(value, this.getFormatter(), record);
  },

  getSortValue: function (_record, _index, _colIndex) {
    const value = this.getValue.apply(this, arguments);
    if (this.column.sortHtmlText && typeof value === "string") {
      return $("<div>" + value + "</div>").text();
    }

    return value;
  },

  getFormatter: function () {
    if (this.column) {
      return this.column.formatter;
    }

    if (this.options) {
      return this.options.formatter;
    }

    return null;
  },

  getPrintValue: function (_record, _index, _colIndex, _features) {
    let value = this.getFormattedValue.apply(this, arguments);
    if (value) {
      value = value.replace(/<br[^>]*>/gi, "\n");

      // strip out html
      value = $("<div/>").html(value).text();
    }

    return value;
  },

  getEmptyHtml: function () {
    return this.column.emptyText || "&nbsp;";
  },

  getRecord: function (record) {
    return record;
  },

  getRecords: function (records) {
    return records;
  },

  getToggleAllHtml: function (data) {
    return this.renderTemplate("grid-toggle-all-selection", data);
  },

  renderTemplate: function (templateName, data, options) {
    // this is a clunky way to get the grid element, but it the parent is the column
    // and the column's parent is the grid, which has a reference to the grid container element
    if (this.parentElement == null) {
      let parent = this.parent;
      while (parent && !this.parentElement) {
        this.parentElement = parent.$element && parent.$element[0];
        parent = parent.parent;
      }

      if (!this.parentElement) {
        throw Error("Unable to find parent element for grid row");
      }
    }

    return htmlUtils.getMemoizedTemplate(templateName, data, options, this.parentElement);
  },

  process: function () {
    // can be overriden
  }
};

jsUtils.makeExtendable(DefaultValueProvider);

module.exports = DefaultValueProvider;
plexExport("grid.valueProviders.DefaultValueProvider", DefaultValueProvider);
