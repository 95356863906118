ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const logger = require("../Core/plex-logger");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const slice = Array.prototype.slice;
const asyncQueue = [];

// eslint-disable-next-line quote-props
const promiseTypes = { object: true, function: true };
const mixins = plexImport("mixins");
const nativeSetTimeout = window.setTimeout;
const nextTick = window.setImmediate || nativeSetTimeout;

function makeExtendable(target) {
  /// <summary>Adds an "extend" function to the target which can be used to create an inherited version of the target.</summary>
  /// <param name="target">The object to make extendable.</param>

  target.extend = function (source) {
    return inheritObject(target, source);
  };
}

function inheritObject(Target, source) {
  /// <summary>Inherits the properties and methods from the source onto the target function.</summary>
  /// <param name="target">The target function to be inherited from.</param>
  /// <param name="source">The source functions and methods to add to the target.</param>
  /// <returns type="Function">The inherited function.</returns>

  // note: largely borrowed from http://ejohn.org/blog/simple-javascript-inheritance/

  // create empty constructor
  const o = function () {
    Target.apply(this, arguments);
  };

  // assign object to prototype
  o.prototype = new Target();

  const base = Target.prototype;

  // assign constructor to prototype
  o.prototype.constructor = o;

  if ("mixin" in source) {
    mixin(o, source.mixin);
  }

  // add each method to the new class
  for (const method in source) {
    if (Object.prototype.hasOwnProperty.call(source, method)) {
      if (method in base && typeof base[method] === "function") {
        // if the same function exists on the base object
        // we will wrap the new function and temporarily
        // bind the base function to make it callable
        // from the inheritor
        o.prototype[method] = (function (name, fn) {
          return function () {
            // keep a reference to the original _base
            const temp = this._base;

            // temporarily bind `_base` method
            this._base = base[name];

            const results = fn.apply(this, arguments);

            // restore original _base
            this._base = temp;

            return results;
          };
        })(method, source[method]);
      } else {
        o.prototype[method] = source[method];
      }
    }
  }

  makeExtendable(o);
  return o;
}

function addMethod(target, name, fn) {
  if (name in target && typeof target[name] === "function") {
    target[name] = wrapInheritedMethod(fn, target[name]);
  } else {
    target[name] = fn;
  }
}

function wrapInheritedMethod(fn, baseMethod) {
  return function () {
    const temp = this._base;
    this._base = baseMethod;
    const results = fn.apply(this, arguments);
    this._base = temp;
    return results;
  };
}

function debounce(fn, delay = 100) {
  /// <summary>
  /// Prevents the function from being called multiple times
  /// within the given delay. Useful for events that fire frequently
  /// but only need to be reacted to periodically.
  /// </summary>
  /// <param name="fn">The function to be executed.</param>
  /// <param name="delay">
  /// The amount of uninterrupted delay to use before the function
  /// is executed.
  /// </param>
  /// <returns type="Function">The debounced function.</returns>

  let timer = null;

  return function () {
    const self = this;
    const args = arguments;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(self, args);
    }, delay);
  };
}

function dequeueTasks() {
  const queue = asyncQueue.splice(0, asyncQueue.length);
  let task, caughtError;

  while ((task = queue.shift())) {
    try {
      task();
    } catch (err) {
      // ignore the error so that the queue continues to process but rethrow the first error
      // after all tasks have been executed
      caughtError = caughtError || err;
    }
  }

  if (caughtError) {
    throw caughtError;
  }
}

function defer(fn, context, args, delay) {
  /// <summary>Defers the execution of a function until the current calls are completed.</summary>
  /// <param name="fn">The function to defer.</param>
  /// <param name="context">The context (this) of the function. (optional)</param>
  /// <param name="args">The arguments to pass to the function. (optional)</param>
  /// <param name="delay">The delay in milliseconds. (optional)</param>

  if (!Array.isArray(args)) {
    args = args ? slice.call(args) : [];
  }

  const callback = fn.bind(context || null, ...args);

  if (delay > 0) {
    const id = nativeSetTimeout(callback, delay);
    return {
      cancel: function () {
        clearTimeout(id);
      }
    };
  } else {
    if (asyncQueue.length === 0) {
      // trigger the execution of tasks after the current sequence
      nextTick(dequeueTasks);
    }

    asyncQueue.push(callback);
    return {
      cancel: function () {
        const index = asyncQueue.indexOf(callback);
        if (index >= 0) {
          asyncQueue.splice(index, 1);
        }
      }
    };
  }
}

// this is intended for testing purposes - it can be manually invoked instead of waiting for timeouts
defer.emptyQueue = function () {
  do {
    dequeueTasks();
  } while (asyncQueue.length > 0);
};

function deferWithPromise(fn, context, args, delay) {
  /// <summary>Defers the execution of a function until the current calls are completed.</summary>
  /// <param name="fn">The function to defer.</param>
  /// <param name="context">The context (this) of the function. (optional)</param>
  /// <param name="args">The arguments to pass to the function. (optional)</param>
  /// <param name="delay">The delay in milliseconds. (optional)</param>

  const deferred = new $.Deferred();
  if (args && !Array.isArray(args)) {
    args = slice.call(args);
  }

  defer(fn, context, (args || []).concat([deferred.resolve, deferred.reject]), delay);
  return deferred.promise();
}

function throttleRender(callback) {
  // this insures that the throttled function will only run once per frame
  let triggered = false;
  return function () {
    if (!triggered) {
      triggered = true;
      window.requestAnimationFrame(() => {
        triggered = false;
        callback.apply(this, arguments);
      });
    }
  };
}

function mixin(target, source) {
  if (Array.isArray(source)) {
    source.forEach((dependency) => {
      mixin(target, dependency);
    });

    return;
  }

  if (typeof source === "string") {
    if (!(source in mixins)) {
      throw new Error("Unable to find mixin: " + source);
    }

    source = mixins[source];
  }

  if (!source) {
    throw new Error("No source provided to mixin.");
  }

  const sourceProto = source.prototype || source;
  const targetProto = target.prototype || target;

  for (const prop in sourceProto) {
    // ignore constructor
    if (!Object.prototype.hasOwnProperty.call(sourceProto, prop) || prop === "constructor") {
      continue;
    }

    merge(targetProto, sourceProto, prop);
  }

  if (typeof source === "function") {
    source.call(target.prototype);
  }
}

function merge(target, source, prop) {
  const targetValue = target[prop];
  const sourceValue = source[prop];

  if (sourceValue == null) {
    return;
  }

  if (targetValue == null) {
    target[prop] = source[prop];
    return;
  }

  if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
    target[prop] = targetValue.concat(sourceValue);
    return;
  }

  if (typeof targetValue === "function" && typeof sourceValue === "function") {
    target[prop] = wrapInheritedMethod(targetValue, sourceValue);
    return;
  }

  // are there other types of values that we care about? For now just overwrite
  target[prop] = sourceValue;
}

function isPromise(obj) {
  return obj && typeof obj in promiseTypes && typeof obj.then === "function";
}

function serializeKey(value) {
  const valueType = typeof value;
  if (valueType === "undefined") {
    return "";
  }

  if (valueType === "string") {
    return '"' + value + '"';
  }

  if (value && valueType === "object") {
    return JSON.stringify(value);
  }

  return String(value);
}

function memoize(fn) {
  /// <summary>Wraps the provided function and caches results against provided arguments</summary>
  /// <param name="fn">The function to memoize</param>
  /// <returns type="Function">The wrapped function.</returns>

  const cache = Object.create(null);

  return function () {
    const ln = arguments.length;

    const args = new Array(ln);
    for (let i = 0; i < ln; i++) {
      args[i] = serializeKey(arguments[i]);
    }

    const key = "[" + args.join(",") + "]";
    return key in cache ? cache[key] : (cache[key] = fn.apply(this, arguments));
  };
}

function isPrimitive(obj) {
  switch (typeof obj) {
    case "number":
    case "boolean":
    case "string":
    case "undefined":
    case "symbol":
      return true;

    default:
      // null evaluates to "object"
      return obj === null;
  }
}

function deprecate(func, message) {
  return function () {
    logger.warn(message);
    return func.apply(this, arguments);
  };
}

function isObjectLike(value) {
  return value != null && typeof value === "object";
}

function isThenable(obj) {
  return obj && (typeof obj === "function" || typeof obj === "object") && typeof obj.then === "function";
}

const api = {
  addMethod,
  debounce,
  defer,
  deferWithPromise,
  deprecate,
  inherit: inheritObject,
  isObjectLike,
  isPromise,
  isPrimitive,
  makeExtendable,
  memoize,
  mixin,
  throttleRender,
  isThenable
};

module.exports = api;
plexExport("js", api);
