ï»¿const ko = require("knockout");
const $ = require("jquery");

function autoSize($target, max) {
  $target.style.height = "auto";
  $target.style.height = $target.scrollHeight + "px";
  $target.style.overflowY = $target.scrollHeight > max ? "scroll" : "hidden";
}

ko.bindingHandlers.autoGrow = {
  after: ["value", "textInput"],

  init: function (element, valueAccessor, allBindings) {
    const $el = $(element);

    const autoGrow = ko.unwrap(valueAccessor());
    const maxHeight = allBindings.get("autoGrowMaxHeight");

    if (autoGrow) {
      if (allBindings.has("value")) {
        $el.on("change keyup keydown", () => {
          autoSize(element, maxHeight);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
          $el.off("change keyup keydown");
        });
      }

      $el.css("overflow-y", "hidden");
      $el.css("resize", "none");
      $el.css("max-height", maxHeight + "px");

      autoSize(element, maxHeight);
    }
  },

  update: function (element, valueAccessor, allBindings) {
    const autoGrow = ko.unwrap(valueAccessor());
    if (autoGrow && allBindings.has("textInput")) {
      // we are not using the value - just forcing a subscription
      ko.unwrap(allBindings.get("textInput"));

      const maxHeight = allBindings.get("autoGrowMaxHeight");
      autoSize(element, maxHeight);
    }
  }
};
