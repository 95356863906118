ï»¿const env = require("./plex-env");
const logger = require("./plex-logger");
const pubsub = require("./plex-pubsub");
const nav = require("./plex-navigate");
const Notify = require("../Dialogs/plex-dialogs-notify");
const banner = require("../Plugins/plex-banner");
const strings = require("../Utilities/plex-utils-strings");
const CrossTabMessenger = require("./plex-crosstab-messenger");
require("../Polyfills/window-requestAnimationFrame"); // eslint-disable-line import/no-unassigned-import

// this file contains actions that are taken when a page is loaded

// set logger to correct logger level
if (env.logLevel) {
  logger.setLevel(env.logLevel);
}

pubsub.subscribe("session.expired", () => {
  const returnUrl = window.location.pathname + window.location.search;

  // note: glossary will not work when the session is expired
  // todo: add this phrase to a global cache so that it can be pre-glossarized
  const options = {
    autoGlossarize: false
  };

  Notify("Your session has expired.", null, options).then(
    () => (window.location = nav.buildUrl("/login", { returnUrl }))
  );
});

if (env.pageMessage) {
  const [, status, message] = /^(?:\s*([^:]+)\s*:)?\s*(.*)/.exec(env.pageMessage);
  banner
    .getPageBanner()
    .setMessage(
      { text: strings.escapeHtml(message), autoGlossarize: true },
      { status: (status || "info").toLowerCase() }
    );
}

// any messages that targets this page, forward on to pubsub
const receiver = new CrossTabMessenger("pubsub." + env.pageStateId);
receiver.addListener(pubsub.publish);

if (env.parentWindowId) {
  // if this is a child page, pass on messages to the parent page
  const sender = new CrossTabMessenger("pubsub." + env.parentWindowId);
  pubsub.subscribe("*", function (...args) {
    // eslint-disable-next-line no-invalid-this
    sender.postMessage(this.topic, ...args);
  });

  if (env.parentActionId) {
    const closeEvent = "action.closing." + env.parentActionId;
    let closeInvoked = false;

    // in case the event is invoked by the back dialog, we want to prevent it from firing twice
    pubsub.subscribe(closeEvent, () => (closeInvoked = true));

    window.addEventListener("beforeunload", () => {
      if (!closeInvoked) {
        closeInvoked = true;
        pubsub.publish(closeEvent, { cancelling: true });
      }
    });
  }
}
