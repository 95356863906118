ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.subscribable.fn.subscribeOnce = function (callback, callbackTarget, event) {
  let oneTime = this.subscribe(
    function (value) {
      if (oneTime) {
        oneTime.dispose();
        oneTime = null;
      }

      // eslint-disable-next-line no-invalid-this
      callback.call(callbackTarget || this, value);
    },
    null,
    event
  );

  return oneTime;
};

ko.subscribable.fn.subscribeAndCall = function (callback, callbackTarget, _event) {
  const sub = this.subscribe.apply(this, arguments);
  callback.call(callbackTarget || this, this.peek());
  return sub;
};

/**
 * Treats an observable like a promise by registering a callback against the next change
 * for the subscribable. A promise is returned which will resolve to the result of the
 * registered callback. The callback will receive the changed value for the subscribable.
 *
 * @param {Function} callback - The callback function.
 * @returns {Promise}
 */
ko.subscribable.fn.then = function (callback) {
  const deferred = new $.Deferred();
  this.subscribeOnce(deferred.resolve);
  return deferred.then(callback);
};

ko.subscribable.fn.when = function (predicate) {
  const notifier = ko.observable();

  let sub = this.subscribe((value) => {
    if (predicate(value)) {
      notifier.notifySubscribers(value);
    }
  });

  notifier.dispose = () => {
    if (sub) {
      sub.dispose();
      sub = null;
    }
  };

  return notifier;
};

ko.subscribable.fn.on = function (event, callback, thisArg) {
  if (typeof event === "function") {
    thisArg = callback;
    callback = event;
    event = "change";
  }

  return this.subscribe(callback, thisArg, event);
};

ko.subscribable.fn.one = function (event, callback, thisArg) {
  if (typeof event === "function") {
    thisArg = callback;
    callback = event;
    event = "change";
  }

  return this.subscribeOnce(callback, thisArg, event);
};
