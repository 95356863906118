/* eslint-disable no-invalid-this */
const culture = require("./plex-culture-datetime");
const tz = require("./plex-culture-timezone");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const LOCAL_DATE_PATTERN = /^(\d{4})-([01]\d)-([0-3]\d)/;
const LOCAL_TIME_PATTERN = /([0-2]\d):([0-5]\d):([0-5]\d)(\.\d+)?$/;

// Refine arguments and make manual corrections if needed.
function fixDateArguments(dateArguments) {
  if (dateArguments && dateArguments.length >= 3) {
    // Refine year if it is two digit.
    // If year is within range of 50 years of range to the future match it to current,
    // otherwise to the previous century (because it is more reasonable to match
    // 99 to 1999 instead of 2099 in 2001).
    let year = dateArguments[0];
    if (year && typeof year === "number" && year < 100) {
      const currentYear = new Date().getFullYear();
      const lastTwoDigitsOfCurrentYear = currentYear % 100;
      const currentCentury = currentYear - lastTwoDigitsOfCurrentYear; // in XX00 format

      if (year < lastTwoDigitsOfCurrentYear + 50) {
        year = currentCentury + year;
      } else {
        year = currentCentury - 100 + year;
      }

      dateArguments[0] = year;
    }
  }

  return dateArguments;
}

function parseDateString(dateString) {
  // small optimization to avoid standard UTC strings, which will be the normal case
  if (dateString[dateString.length - 1] !== "Z") {
    let y, M, d, h, m, s, ms;
    let hasValues = false;

    const dateMatch = LOCAL_DATE_PATTERN.exec(dateString);
    if (dateMatch) {
      [, y, M, d] = dateMatch;
      y = parseInt(y, 10);
      M = parseInt(M, 10) - 1;
      d = parseInt(d, 10);
      hasValues = true;
    }

    const timeMatch = LOCAL_TIME_PATTERN.exec(dateString);
    if (timeMatch) {
      [, h, m, s, ms] = timeMatch;
      h = parseInt(h, 10);
      m = parseInt(m, 10);
      s = parseInt(s, 10);
      ms = ms && parseFloat(ms) * 1000;
      hasValues = true;
    }

    if (hasValues) {
      return tz.LogicalDate.fromDate(y || 1900, M || 0, d || 1, h || 0, m || 0, s || 0, ms || 0).toDate();
    }
  }

  return new Date(dateString);
}

// Javascript dates are not actual objects in the VM, because of this we have to assign the prototype to an
// actual date instance and override the date behavior inside of the CustomerDate __proto__. This prempts dot
// notation from hitting the meaningless date created below but still allows for instances of CustomerDate
// to be truthy in the case of (i instanceof Date).
CustomerDate.prototype = new Date();
CustomerDate.prototype.constructor = CustomerDate;
function CustomerDate() {
  switch (arguments.length) {
    case 0:
      this.__localDate__ = new Date();
      break;

    case 1:
      if (arguments[0] instanceof Date) {
        // Date object
        this.__localDate__ = new Date(arguments[0].getTime());
      } else if (typeof arguments[0] === "number") {
        this.__localDate__ = new Date(arguments[0]);
      } else if (typeof arguments[0] === "string") {
        this.__localDate__ = parseDateString(arguments[0]);
      } else {
        throw new Error("Invalid date constructor.");
      }

      break;

    default:
      this.__logicalDate__ = tz.LogicalDate.fromDate.apply(tz.LogicalDate, fixDateArguments(arguments));
      this.__localDate__ = this.__logicalDate__.toDate();
      break;
  }

  this.__logicalDate__ = this.__logicalDate__ || tz.LogicalDate.fromDate(this.__localDate__);
}

CustomerDate.prototype.setLogicalProperty = function (propertySetterDelegate) {
  propertySetterDelegate(this.__logicalDate__);

  // create a new instance to account for any overflows that may have occurred adjusting the logical date
  this.__logicalDate__ = tz.LogicalDate.fromDate(this.__logicalDate__);
  this.__localDate__ = this.__logicalDate__.toDate();
  return this.__localDate__.getTime();
};

CustomerDate.prototype.setLocalProperty = function (propertySetterDelegate) {
  propertySetterDelegate(this.__localDate__);
  this.__logicalDate__ = tz.LogicalDate.fromDate(this.__localDate__);
  return this.__localDate__.getTime();
};

// Normal date overrides for customer timezone stuff.
CustomerDate.prototype.getDate = function () {
  return this.__logicalDate__.date;
};

CustomerDate.prototype.getDay = function () {
  return this.__logicalDate__.day;
};

CustomerDate.prototype.getFullYear = function () {
  return this.__logicalDate__.year;
};

CustomerDate.prototype.getHours = function () {
  return this.__logicalDate__.hour;
};

CustomerDate.prototype.getMinutes = function () {
  return this.__logicalDate__.minute;
};

CustomerDate.prototype.getMonth = function () {
  return this.__logicalDate__.month;
};

CustomerDate.prototype.getTimezoneOffset = function () {
  return tz.getCustomerTimezoneOffset(
    this.__logicalDate__.year,
    this.__logicalDate__.month,
    this.__logicalDate__.date,
    this.__logicalDate__.hour,
    this.__logicalDate__.minute,
    this.__logicalDate__.second,
    this.__logicalDate__.millisecond
  );
};

CustomerDate.prototype.toLocaleDateString = function () {
  return culture.formatDate(this, { date: "short" });
};

CustomerDate.prototype.toLocaleTimeString = function () {
  return culture.formatDate(this, { time: "long" });
};

CustomerDate.prototype.toDateString = function () {
  return culture.formatDate(this, "ddd MMM dd yyyy");
};

CustomerDate.prototype.toTimeString = function () {
  const gmtOffset = -1 * (this.getTimezoneOffset() / 60) * 100;
  let offsetString = String(gmtOffset);
  if (gmtOffset > -1000 && gmtOffset < 1000) {
    offsetString = "0" + (gmtOffset < 0 ? -1 * gmtOffset : gmtOffset);
  }

  offsetString = gmtOffset < 0 ? "-" + offsetString : "+" + offsetString;
  return (
    culture.formatDate(this, "HH':'mm':'ss") +
    " GMT" +
    offsetString +
    " (" +
    plexImport("appState").customer.globalizationInfo.timeZone.windowsTimeZoneId +
    ")"
  );
};

CustomerDate.prototype.toString = function () {
  return this.toDateString() + " " + this.toTimeString();
};

CustomerDate.prototype.setDate = function (date) {
  return this.setLogicalProperty((d) => {
    d.date = date;
  });
};

CustomerDate.prototype.setFullYear = function (year) {
  return this.setLogicalProperty((d) => {
    d.year = year;
  });
};

CustomerDate.prototype.setHours = function (hour) {
  return this.setLogicalProperty((d) => {
    d.hour = hour;
  });
};

CustomerDate.prototype.setMilliseconds = function (millisecond) {
  return this.setLogicalProperty((d) => {
    d.millisecond = millisecond;
  });
};

CustomerDate.prototype.setMinutes = function (minute) {
  return this.setLogicalProperty((d) => {
    d.minute = minute;
  });
};

CustomerDate.prototype.setMonth = function (month) {
  return this.setLogicalProperty((d) => {
    d.month = month;
  });
};

CustomerDate.prototype.setSeconds = function (second) {
  return this.setLogicalProperty((d) => {
    d.second = second;
  });
};

CustomerDate.prototype.setTime = function (time) {
  return this.setLocalProperty((d) => {
    d.setTime(time);
  });
};

CustomerDate.prototype.setUTCDate = function (date) {
  return this.setLocalProperty((d) => {
    d.setUTCDate(date);
  });
};

CustomerDate.prototype.setUTCFullYear = function (year) {
  return this.setLocalProperty((d) => {
    d.setUTCFullYear(year);
  });
};

CustomerDate.prototype.setUTCHours = function (hours) {
  return this.setLocalProperty((d) => {
    d.setUTCHours(hours);
  });
};

CustomerDate.prototype.setUTCMilliseconds = function (milliseconds) {
  return this.setLocalProperty((d) => {
    d.setUTCMilliseconds(milliseconds);
  });
};

CustomerDate.prototype.setUTCMinutes = function (minutes) {
  return this.setLocalProperty((d) => {
    d.setUTCMinutes(minutes);
  });
};

CustomerDate.prototype.setUTCMonth = function (month) {
  return this.setLocalProperty((d) => {
    d.setUTCMonth(month);
  });
};

CustomerDate.prototype.setUTCSeconds = function (seconds) {
  return this.setLocalProperty((d) => {
    d.setUTCSeconds(seconds);
  });
};

// Proxy everything else through to the native local date object.
CustomerDate.prototype.getMilliseconds = function () {
  return this.__localDate__.getMilliseconds();
};
CustomerDate.prototype.getSeconds = function () {
  return this.__localDate__.getSeconds();
};

CustomerDate.prototype.getTime = function () {
  return this.__localDate__.getTime();
};
CustomerDate.prototype.getUTCDate = function () {
  return this.__localDate__.getUTCDate();
};
CustomerDate.prototype.getUTCDay = function () {
  return this.__localDate__.getUTCDay();
};
CustomerDate.prototype.getUTCFullYear = function () {
  return this.__localDate__.getUTCFullYear();
};
CustomerDate.prototype.getUTCHours = function () {
  return this.__localDate__.getUTCHours();
};
CustomerDate.prototype.getUTCMilliseconds = function () {
  return this.__localDate__.getUTCMilliseconds();
};
CustomerDate.prototype.getUTCMinutes = function () {
  return this.__localDate__.getUTCMinutes();
};
CustomerDate.prototype.getUTCMonth = function () {
  return this.__localDate__.getUTCMonth();
};
CustomerDate.prototype.getUTCSeconds = function () {
  return this.__localDate__.getUTCSeconds();
};

CustomerDate.prototype.getYear = function () {
  throw Error("Function is deprecated, use getFullYear() instead.");
};
CustomerDate.prototype.setYear = function () {
  throw Error("Function is deprecated, use setFullYear() instead.");
};

CustomerDate.prototype.toUTCString = function () {
  return this.__localDate__.toUTCString();
};
CustomerDate.prototype.toGMTString = function () {
  return this.__localDate__.toGMTString();
};
CustomerDate.prototype.toISOString = function () {
  return this.__localDate__.toISOString();
};
CustomerDate.prototype.toJSON = function () {
  return this.__localDate__.toJSON();
};
CustomerDate.prototype.valueOf = function () {
  return this.getTime();
};

if (Object.defineProperty) {
  for (const prop in CustomerDate.prototype) {
    if (Object.prototype.hasOwnProperty.call(CustomerDate.prototype, prop)) {
      Object.defineProperty(CustomerDate.prototype, prop, { enumerable: false });
    }
  }

  if (typeof Symbol === "function" && Symbol.toStringTag != null) {
    Object.defineProperty(CustomerDate.prototype, Symbol.toStringTag, {
      get: () => "[object Date]",
      enumerable: false
    });
  }
}

module.exports = CustomerDate;
plexExport("dates.CustomerDate", CustomerDate);
