ï»¿const $ = require("jquery");
const ko = require("knockout");
const env = require("../Core/plex-env");
const repository = require("./plex-model-repository");
const bindingHandler = require("./plex-handler-bindings");
const revisionTracking = require("../Core/plex-revisiontracking");
const jsUtils = require("../Utilities/plex-utils-js");
const dataTokens = require("../Utilities/plex-utils-datatoken");
const nav = require("../Core/plex-navigate");
const DataTransformProcessor = require("../Data/Transforms/plex-data-transforms-processor");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const actions = plexImport("actions");
const navigateActionMatcher = /^(?:Redirect|Dialog)$/;

function getUrl(action, params) {
  // if action bar action's selection context is set to one, we want to grab the first
  // record in array to prevent object from being serialized as list item for mvc
  let paramsValue = params;
  if (action.isSingleSelection) {
    paramsValue = Array.isArray(params) ? params[0] : params;
  }

  if (action.type === "Dialog") {
    if (!action.allowOpenInNewWindow) {
      return nav.buildUrl(window.location.href, {
        __message: "Warning: The selected action cannot be performed in a new window."
      });
    }

    // copy action state into querystring
    paramsValue.__pwid = env.pageStateId;
    paramsValue.__action = action.id;
  }

  return nav.buildUrl(action.address, paramsValue, {
    externalLink: action.externalLink,
    pageMode: action.mode,
    replaceHistory: action.replaceHistory
  });
}

function executeAction(config, data, e, parentAction) {
  function execute(tokenizedData) {
    const action = new actions[config.type]();
    action.init(config, parentAction);

    const promise = deferActionExecution(action, tokenizedData || data, data, e);
    if (promise) {
      return promise;
    }

    return action.execute(tokenizedData || data, data, e);
  }

  if (ko.unwrap(config.disabled)) {
    return null;
  }

  // make sure that a handler exists
  if (!(config.type in actions)) {
    throw new Error("No handler for the given action type: " + config.type);
  }

  const transformedData = getActionData(config, data);
  if (jsUtils.isPromise(transformedData)) {
    return transformedData.then(execute);
  }

  return execute(transformedData);
}

function initAction(action, ctrl, data, actionBar) {
  let parent = action.parent || ctrl;
  const currentPage = plexImport("currentPage");

  if (action.sourceId && action.sourceId in currentPage) {
    parent = currentPage[action.sourceId];
  }

  if (parent) {
    action.parent = parent;
    parent.actions = action.parent.actions || {};
    parent.actions[action.id] = action;

    if (parent.pendingSubscriptionEvents) {
      action.anyPendingSubscriptionEvents = ko.pureComputed(() => {
        return parent.pendingSubscriptionEvents().length > 0;
      });
    }
  }

  if (!ko.isObservable(action.disabled)) {
    action.disabled = ko.observable(!!action.disabled);
  }

  // look for static `configure` method on action
  if (action.type in actions && "configure" in actions[action.type]) {
    actions[action.type].configure(action, data, actionBar);
  }

  if (action.postAction) {
    initAction(action.postAction, action.parent);
  }

  if (action.returnAction) {
    initAction(action.returnAction, action.parent);
  }

  if (action.closeAction) {
    initAction(action.closeAction, action.parent);
  }

  if (!action.href) {
    if (actionPerformsNavigation(action)) {
      action.href = function (record) {
        if (action.address) {
          let params;
          if (action.tokens && action.tokens.length > 0) {
            params = record && getActionData(action, record);
          }

          return getUrl(action, params || {});
        }

        return "";
      };
    } else {
      action.href = "javascript:;";
    }
  }

  if (action.dataTransforms && action.dataTransforms.length > 0) {
    action.dataTransformProcessor = new DataTransformProcessor(action.dataTransforms, action);
  }

  if (!("authorized" in action)) {
    action.authorized = true;
  }

  return action;
}

function getActionData(action, dataParam) {
  function transform(obj) {
    if (action.dataTransformProcessor) {
      return action.dataTransformProcessor.process(obj);
    }

    return obj;
  }

  function bind(obj) {
    if (action.bindings && action.bindings.length > 0) {
      bindingHandler.updateTarget(action.bindings, obj);
    }

    return obj;
  }

  function track(obj) {
    if (obj && action.revisionTracking === true) {
      revisionTracking.addRevisionDataToPostData(obj);
    }

    return obj;
  }

  let data = action.data || dataParam;

  if (!action.data && action.sourceId) {
    data = repository.get(action.sourceId) || data;
  }

  if (action.tokens && action.tokens.length > 0) {
    data = dataTokens.applyTokens(data, action.tokens);
    if (jsUtils.isPromise(data)) {
      return data.then(transform).then(bind).then(track);
    }
  }

  data = transform(data);
  data = bind(data);
  data = track(data);
  return data;
}

function deferActionExecution(action, tokenizedData, data, e) {
  if (action.parent && action.anyPendingSubscriptionEvents && action.parent.pendingSubscriptionEvents().length > 0) {
    const deferred = new $.Deferred();
    const subscription = action.anyPendingSubscriptionEvents.subscribe((value) => {
      if (value) {
        return;
      }

      const promise = action.execute(tokenizedData, data, e);
      if (promise) {
        if (promise === true) {
          deferred.resolve();
          setTimeout(() => {
            subscription.dispose();
          }, 0);
        } else {
          promise
            .done(() => {
              deferred.resolve();
            })
            .always(() => {
              subscription.dispose();
            });
        }
      }
    });

    return deferred.promise();
  }

  return null;
}

function getAction(actionId) {
  let id, controller;
  const currentPage = plexImport("currentPage");

  for (id in currentPage) {
    if (Object.prototype.hasOwnProperty.call(currentPage, id)) {
      controller = currentPage[id];
      if (controller.actions && actionId in controller.actions) {
        return controller.actions[actionId];
      }
    }
  }

  return null;
}

function getTokenData(action, data) {
  if (action.tokens && action.tokens.length > 0) {
    return dataTokens.applyTokens(data, action.tokens);
  }

  return {};
}

function buildLinkUrl(action, record, index) {
  if (typeof action.href === "function") {
    return action.href(record, index);
  }

  // let redirect render out to a link so that right-click open in new tab/window will work
  if (action.type === "Redirect" && action.address) {
    return nav.buildUrl(
      action.address,
      getTokenData(action, record),
      action.externalLink ? { externalLink: true } : {}
    );
  }

  return "javascript:void(0);";
}

function actionPerformsNavigation(action) {
  return (
    navigateActionMatcher.test(action.type) ||
    (action.type === "SidetabsRedirect" && action.action != null && navigateActionMatcher.test(action.action.type))
  );
}

const api = {
  initAction,

  executeAction,

  getAction,

  getActionData,

  getTokenData,

  buildLinkUrl
};

module.exports = api;
plexExport("ActionHandler", api);
