ï»¿const expressions = require("../Expressions/plex-expressions-compiler");
const actionHandler = require("../Controls/plex-handler-action");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ControlFlowAction = Action.extend({
  onInit: function () {
    const self = this;
    if (self.defaultAction) {
      actionHandler.initAction(self.defaultAction, self.parent);
    }

    if (self.conditionActions) {
      self.conditionActions.forEach((conditionAction) => {
        actionHandler.initAction(conditionAction, self.parent);
      });
    }

    this.conditionCompiler = function (condition) {
      return condition
        ? expressions.compile(condition)
        : function () {
            return true;
          };
    };
  },

  onExecute: function (data, sourceData, event) {
    const self = this;
    const conditionActions = self.conditionActions || [];

    for (let i = 0; i < conditionActions.length; i++) {
      const action = conditionActions[i];

      if (self.conditionCompiler(action.condition)(data, null, null, self)) {
        return actionHandler.executeAction(action, data, event, self);
      }
    }

    if (self.defaultAction) {
      return actionHandler.executeAction(self.defaultAction, data, event, self);
    }

    return true;
  }
});

module.exports = ControlFlowAction;
plexExport("actions.ControlFlow", ControlFlowAction);
