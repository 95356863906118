const $ = require("jquery");
const documentProvider = require("./plex-printing-document-providers");
const dataUtils = require("../../Utilities/plex-utils-data");
const domUtils = require("../../Utilities/plex-utils-dom");
const expressions = require("../../Expressions/plex-expressions-compiler");
const formatUtils = require("../../Globalization/plex-formatting");
const Cache = require("../../Core/plex-cache");
const nav = require("../../Core/plex-navigate");
const environment = require("../../Core/plex-env");
const { generateXmlFromPage } = require("./plex-printing-controller-xml-generator");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");
const urlBuilder = require("../../Utilities/plex-utils-url-builder");

const DEFAULT_PAGE_SIZE = "Letter";
const DEFAULT_MARGIN = "Default";
const ENGLISH_LANGUAGE_KEY = 1;
const FEATURE_FLAG = "feat-tri-3250-dynamic-print-sizing";
function DOCUMENT_DEFAULTS() {
  return {
    saveAsDefault: false,
    pageWidth: "8.5in",
    pageHeight: "11in",
    pageMargins: setDefaultPageMargins(),
    grayscaleOnly: false,
    reportDateRange: []
  };
}

function setDefaultPageMargins() {
  return { margin: 7, top: 7, bottom: 4, left: 7, right: 7 };
}

// customizing page sizes and settings data to save in browser coookies
function checkCookie(name) {
  let result = document.cookie.match(new RegExp(name + "=([^;]+)"));
  result && (result = JSON.parse(result[1]));
  return result;
}

function storeSettingInLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getSettingFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

function deleteSettingFromLocalStorage(key) {
  localStorage.removeItem(key);
}

function fallbackCookieToLocalStorage(key, setting, cookieNameToDelete) {
  storeSettingInLocalStorage(key, setting);
  deleteCookie(cookieNameToDelete);
}

function bakeCookie(name, days, value) {
  const path = urlBuilder.getRelativePath(document.baseURI) || "/";
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const cookie = [
    name,
    "=",
    JSON.stringify(value),
    "; expires=" + date.toUTCString(),
    "; domain=.",
    window.location.host.toString(),
    "; path=" + path + ";"
  ].join("");
  document.cookie = cookie;
}

function deleteCookie(cookieName) {
  bakeCookie(cookieName, "", -1);
}

function getSettings(name, localStorageName) {
  const oldSettings = checkCookie(name);

  // Fallback code if cookie is present move to local storage
  if (oldSettings) {
    fallbackCookieToLocalStorage(localStorageName, oldSettings, name);
  }

  // To make sure setting is save from fallback operation
  return getSettingFromLocalStorage(localStorageName);
}

function setPageAttributes(name, localStorageName, env) {
  const settings = getSettings(name, localStorageName);

  if (settings && settings.saveAsDefault) {
    const settingsPhase1 = {
      languageKey: settings.languageKey || ENGLISH_LANGUAGE_KEY,
      pageHeight: settings.pageHeight + settings.pageHeightUnit || "",
      pageWidth: settings.pageWidth + settings.pageWidthUnit || "",
      saveAsDefault: settings.saveAsDefault,
      documentDimensionsDisplay: settings.pageSize === DEFAULT_PAGE_SIZE,
      landscape: settings.pageSize === "Custom" ? false : settings.landscape,
      pageSize: settings.pageSize
    };

    const settingsPhase2 = env.features[FEATURE_FLAG]
      ? {
          pageMargin: settings.pageMargin || DEFAULT_MARGIN,
          pageMargins: settings.pageMargin
            ? {
                margin: settings.pageMargins?.margin,
                top: settings.pageMargins?.top,
                bottom: settings.pageMargins?.bottom,
                left: settings.pageMargins?.left,
                right: settings.pageMargins?.right
              }
            : setDefaultPageMargins(),
          grayscaleOnly: settings.grayscaleOnly || false
        }
      : { pageMargins: setDefaultPageMargins(), grayscaleOnly: false }; // to be removed with featureflag

    return { ...settingsPhase1, ...settingsPhase2 };
  } else {
    return DOCUMENT_DEFAULTS();
  }
}

function getAppStateLanguage() {
  const appState = plexImport("appState");
  if (appState && appState.language && appState.language.languageKey) {
    return appState.language.languageKey;
  }

  return ENGLISH_LANGUAGE_KEY;
}

const PrintDocumentProviderObsolete = documentProvider.extend({
  onInit: function () {
    const self = this;
    self.env = environment;

    this.cache = new Cache();

    // defaults
    self.document = self.config.document || { printDocumentKey: 1 };
    const cookieName = "printSettings-" + self.document.printDocumentKey;
    const localStorageKeyWithActionKey = plexImport("currentApplication").ActionKey + "_" + cookieName;

    self.documentProperty = $.extend(
      {},
      self.config.documentProperty,
      setPageAttributes(cookieName, localStorageKeyWithActionKey, self.env)
    );

    self.language = self.config.language || {};
    self.language.languageKey = self.language.languageKey || getAppStateLanguage();
    self.cacheXml = self.config.cacheXml;
    if (self.cacheXml == null) {
      self.cacheXml = true;
    }

    if (typeof self.documentProperty.landscape === "undefined" && self.document.xmlAddress) {
      self.documentProperty.landscape = false;
    }

    if (self.config.startDateFormatter) {
      self.startDateFormatter = formatUtils.getFormatter(self.config.startDateFormatter);
    }

    if (self.config.endDateFormatter) {
      self.endDateFormatter = formatUtils.getFormatter(self.config.endDateFormatter);
    }
  },

  onGetDocument: function (data, tokenizedData) {
    // check cache
    let key = this._getCacheKey();
    const cached = this.cache.get(key);
    if (cached) {
      return $.when(cached);
    }

    return this.getXml(tokenizedData || data).then((xml) => {
      this.xml = xml;
      this.config.createDocumentRequest = this._createRequest(data);

      return nav
        .post(nav.buildUrl("/Platform/PrintManager/CreateDocument"), this.config.createDocumentRequest, {
          showOverlay: false
        })
        .then(
          (document) => {
            // cache document
            key = this._getCacheKey();
            if (this.cache.has(key) === false) {
              this.cache.set(key, document);
            }

            return document;
          },
          () => {
            // delete settings from local storage if given setting fails to print
            const cookieName = "_printSettings-" + this.document.printDocumentKey;
            const localStorageKeyWithActionKey = plexImport("currentApplication").ActionKey + cookieName;
            deleteSettingFromLocalStorage(localStorageKeyWithActionKey);
          }
        );
    });
  },

  getXml: function (data) {
    if ((!this.xml || !this.cacheXml) && this.document.xmlAddress) {
      return $.post(this.document.xmlAddress, nav.serialize(dataUtils.cleanse(data)), null, "xml").then((response) => {
        const xmls = new XMLSerializer();
        return xmls.serializeToString(response);
      });
    }

    if (this.xml) {
      return $.when(this.xml);
    }

    return this.createControllerXml();
  },

  createControllerXml: function () {
    return generateXmlFromPage(this);
  },

  createCustomizationRequest: function () {
    const request = {};
    $.extend(request, this.language);
    $.extend(request, this.documentProperty);

    return request;
  },

  _getCacheKey: function () {
    // need to cache based on language key and document properties.
    // customization request happens to contain all of these.
    const request = this.createCustomizationRequest();
    return JSON.stringify(request);
  },

  _createRequest: function (data) {
    // date range
    const startDate = dataUtils.getValue(data, this.config.startDatePropertyName) || this.documentProperty.startDate;
    if (startDate && this.startDateFormatter) {
      this.documentProperty.reportDateRange.push(
        this.startDateFormatter.format(startDate, this.config.startDateFormatter)
      );
    }

    const endDate = dataUtils.getValue(data, this.config.endDatePropertyName) || this.documentProperty.endDate;
    if (endDate && this.endDateFormatter) {
      this.documentProperty.reportDateRange.push(this.endDateFormatter.format(endDate, this.config.endDateFormatter));
    }

    // bound properties
    this._setBoundProperty(this.config.reportCustomerPropertyName, "reportCustomer", data);
    this._setBoundProperty(this.config.thirdPartyCustomerPropertyName, "thirdPartyCustomer", data);
    this._setBoundProperty(this.config.buildingCodePropertyName, "buildingCode", data);
    this._setBoundProperty(this.config.customerNamePropertyName, "customerName", data);
    this._setBoundProperty(this.config.customerLogoPropertyName, "customerLogo", data);

    const printActionConfig = this.config.parent.config;

    let recordKey = null;
    if (printActionConfig.attachmentGroupKey) {
      if (
        printActionConfig.recordKeyPropertyName &&
        Object.prototype.hasOwnProperty.call(data, printActionConfig.recordKeyPropertyName)
      ) {
        recordKey = data[printActionConfig.recordKeyPropertyName];
      } else if (printActionConfig.recordKeyExpression) {
        recordKey = expressions.compile(printActionConfig.recordKeyExpression)(data);
      }
    }

    const request = {
      printDocumentKey: this.document.printDocumentKey,
      documentProperty: this.documentProperty,
      xml: this.xml,
      documentTitle: this._getDocumentTitle(data),
      languageKey: this.language.languageKey,
      attachmentGroupKey: printActionConfig.attachmentGroupKey,
      recordKey
    };

    return dataUtils.cleanse(request, { flatten: false });
  },

  _getDocumentTitle: function (data) {
    let title;
    if (this.config.titleExpression) {
      const fn = expressions.compile(this.config.titleExpression);
      title = fn(data);
    }

    title = title || this.documentProperty.documentTitle;
    if (title) {
      return title;
    }

    // fallback to page title
    let $pageTitle = $(".plex-page-title");
    if (this.config.parent.config.parent && domUtils.isInDialog(this.config.parent.config.parent.$element)) {
      const $dialogTitle = this.config.parent.config.parent.$element.closest(".plex-dialog").find(".plex-dialog-title");
      if ($dialogTitle.length) {
        $pageTitle = $dialogTitle;
      }
    }

    return $pageTitle.length ? $pageTitle.text().trim() : "";
  },

  _setBoundProperty: function (srcPropertyName, destPropertyName, data) {
    const boundValue = dataUtils.getValue(data, srcPropertyName);
    if (boundValue) {
      this.documentProperty[destPropertyName] = boundValue;
    }
  }
});

module.exports = PrintDocumentProviderObsolete;
plexExport("printing.documentProviders.PrintDocumentProvider", PrintDocumentProviderObsolete);
