ï»¿const ko = require("knockout");
const PrintWriter = require("./plex-grid-print-writer-base");
const ElementHandler = require("../../../Controls/plex-handler-element");
const stringUtils = require("../../../Utilities/plex-utils-strings");
const plexExport = require("../../../../global-export");

function getColSpan(column) {
  // the 'columns' property of the Table column type
  // refers to the contents' table's columns
  if (!column.columns || column.type === "Table" || column.composite) {
    return 1;
  }

  let span = 0;
  column.columns
    .filter((col) => {
      return col.printVisible && col.printVisible();
    })
    .forEach((col) => {
      span += getColSpan(col);
    });

  return span;
}

const HeaderRowPrintWriter = PrintWriter.extend({
  init: function (grid) {
    this._base.apply(this, arguments);
    this.config = grid.options;
    this.header = this.grid.controller.header;
  },

  getNodeName: function () {
    return "grid-table-header";
  },

  print: function (_data) {
    const self = this;

    const title = ko.unwrap(self.header.title);
    if (title) {
      self.node.createNode("grid-table-row").addElement("grid-table-cell", title, {
        "text-align": "center",
        colspan: self.grid.controller.getPrintTitleColSpan()
      });
    }

    self.header.headerRows().forEach((headerRow) => {
      let headerRowNode;
      const printCols = headerRow()
        .columns()
        .filter((col) => {
          return col.printVisible() && (col.lastRow() === -1 || col.lastRow() === headerRow().level());
        });

      if (printCols.length > 0) {
        headerRowNode = self.node.createNode("grid-table-row");
      }

      printCols.forEach((col) => {
        const attr = {};

        const colspan = getColSpan(col);
        if (colspan > 1) {
          attr.colspan = colspan;
        }

        const rowspan = col.lastRow() === -1 ? 1 : self.header.headerRows().length - col.lastRow();
        if (rowspan > 1) {
          attr.rowspan = rowspan;
        }

        if (col.sorted()) {
          attr["sort-dir"] = col.sortedAsc() ? "asc" : "desc";

          if (col.sortedOrder && col.sortedOrder() > 0) {
            attr["sort-order"] = col.sortedOrder();
          }
        }

        const cellNode = headerRowNode.createNode("grid-table-cell", attr);
        if (col.headerElement) {
          if (col.shortHeaderName) {
            let headerName = col.shortHeaderName();
            if (headerName) {
              if (typeof headerName === "string") {
                headerName = stringUtils.unescapeHtml(headerName.replace(/<br[^>]*>/gi, "\n"));
              }

              cellNode.setValue(headerName);
            }
          } else {
            if (!col.visible()) {
              ElementHandler.initElement(col.headerElement, col.$$headerData || {});
            }

            cellNode.addControlElement(col.headerElement);
          }
        }

        // apply header css
        if (col.headerCss) {
          const headerCss = ko.unwrap(col.headerCss);
          self.writeCss(cellNode, headerCss.split(" "));
        }
      });
    });

    return this.toPrintXml();
  }
});

module.exports = HeaderRowPrintWriter;
plexExport("grid.writers.HeaderRowPrintWriter", HeaderRowPrintWriter);
