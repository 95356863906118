ï»¿const plexImport = require("../../global-import");
const globalExport = require("../../global-export");

const qsRgx = /[?&]([^=&]+)=?([^&]*)/g;
const urlRgx = /^([^#?]*)(\?[^#]*)?(#.*)?$/;
const segmentRgx = /^(?:(https?):\/\/|\/\/)?([^/:]*)(?::(\d+))?/;
const validUrlRgx = /(\b(https?):\/\/)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
const externalUrlRgx = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;
const relativePathRgx = /^([^?#]*)/;
const pathRgx = /^(?:https?:)?\/\/[^/]*([^?#]*)/;

function isRelative(url) {
  if (!url) {
    return false;
  }

  if (url[0] === "/" && url[1] !== "/") {
    return true;
  }

  return url[0] === "." && url[1] === "." && (url.length === 2 || url[2] === "/");
}

// todo: might want to implement caching or some global variable to retrieve current url
function parseQueryString(url) {
  const qs = {};

  // if no url is passed in default to current url
  const urlPart = (url ?? window.location.search).split("#")[0];

  // make sure prefixed with ? for our purposes
  const qsStart = urlPart.indexOf("?");
  let qsPart;
  if (qsStart === -1) {
    // need to test if the fragment is url or querystring
    if (!url || isRelative(url) || externalUrlRgx.test(url)) {
      return qs;
    }

    qsPart = "?" + urlPart;
  } else {
    qsPart = urlPart.substring(qsStart);
  }

  qsPart.replace(qsRgx, (_, $1, $2) => {
    qs[$1] = $2 ? decodeURIComponent($2.replace(/\+/g, " ")) : "";
  });

  return qs;
}

function getDomainRoot() {
  return window.location.protocol + "//" + window.location.host;
}

function getStaticDomainRoot() {
  const services = plexImport("services", true);
  if (services && services["cloud-cdn"]) {
    return services["cloud-cdn"];
  }

  // TODO: remove once all webapps updated to inject plex.services
  const host = window.location.protocol + "//" + window.location.host.replace("cloud.", "cloud-cdn.");
  return host;
}

function isValidUrl(url) {
  return !!url && (url === "/" || validUrlRgx.test(url));
}

function isValidExternalUrl(url) {
  return !!url && externalUrlRgx.test(url);
}

const api = {};
api.parseQueryString = parseQueryString;

api.parseUrl = (input) => {
  let url = input;
  if (url && !isValidUrl(url)) {
    throw new Error("The value provided is not a valid URL: " + url);
  }

  const relative = isRelative(url);
  url = url || window.location.href;

  const urlMatch = urlRgx.exec(url);
  const segmentMatch = segmentRgx.exec(relative ? window.location.href : url);
  const [, path] = (relative ? relativePathRgx : pathRgx).exec(url) || [];

  return {
    url: urlMatch[1],
    query: parseQueryString(urlMatch[2] ?? ""),
    hash: urlMatch[3] ? urlMatch[3].substring(1) : "",
    scheme: segmentMatch[1] || window.location.protocol, // NOTE: regex does not include the :, but location.protocol does. This bug is being kept as-is for compatibility reasons
    protocol: segmentMatch[1] ? segmentMatch[1] + ":" : window.location.protocol,
    host: segmentMatch[2],
    port: segmentMatch[3],
    path,
    toString() {
      return urlToString(this);
    },
    toRelativeString() {
      return urlToString(this, true);
    }
  };
};

function urlToString({ path, query, hash, scheme, host, port }, toRelative) {
  let value = "";
  if (!toRelative) {
    value = scheme || window.location.protocol;
    value += "://" + (host || api.parseUrl().host);

    if (port) {
      value += ":" + port;
    }
  }

  if (path[0] !== "/") {
    value += "/";
  }

  value += path;

  if (query) {
    let queryString = query;
    if (typeof query !== "string") {
      queryString = Object.keys(query)
        .map((key) => key + "=" + encodeURIComponent(query[key] || ""))
        .join("&");
    }

    value += "?" + queryString;
  }

  if (hash) {
    value += "#" + hash;
  }

  return value;
}

api.isValidUrl = isValidUrl;
api.isValidExternalUrl = isValidExternalUrl;
api.isAbsolutePath = RegExp.prototype.test.bind(/^\/(?!\/)/); // (string) => bool; checks if string begins with a '/' character and is not followed by another '/'
api.getDomainRoot = getDomainRoot;
api.getStaticDomainRoot = getStaticDomainRoot;
api.combineUrl = (...args) => {
  let url = "";
  const ln = args.length;
  let i = 0;

  for (; i < ln; i++) {
    if (args[i]) {
      if (i > 0 && url.charAt(url.length - 1) !== "/") {
        url += "/";
      }

      url += args[i];
    }
  }

  return url;
};

module.exports = api;
Object.keys(api).forEach((name) => {
  globalExport("parsing." + name, api[name]);
});
