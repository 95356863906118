ï»¿const $ = require("jquery");
const documentProvider = require("./plex-printing-document-providers");
const dataUtils = require("../../Utilities/plex-utils-data");
const nav = require("../../Core/plex-navigate");
const plexExport = require("../../../global-export");

const RetainedDocumentProvider = documentProvider.extend({
  onGetDocument: function (data, _tokenizedData) {
    const $deferred = new $.Deferred();

    if (this.config.document) {
      $deferred.resolve(this.config.document);
      return $deferred.promise();
    }

    const request = { retainedDocumentKey: dataUtils.getValue(data, this.config.propertyName) };
    nav
      .post(nav.buildUrl("/Platform/PrintManager/LoadRetainedDocument"), request, { showOverlay: false })
      .done((retainedDocument) => {
        $deferred.resolve(retainedDocument);
      })
      .fail($deferred.reject);

    return $deferred.promise();
  }
});

module.exports = RetainedDocumentProvider;
plexExport("printing.documentProviders.RetainedDocumentProvider", RetainedDocumentProvider);
