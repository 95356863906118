ï»¿const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const Wiki = Action.extend({
  onExecute: function () {
    // noop
  }
});

module.exports = Wiki;
plexExport("actions.Wiki", Wiki);
