ï»¿const ko = require("knockout");
const $ = require("jquery");
const domUtils = require("../Utilities/plex-utils-dom");

const clickableTagMatcher = /^(?:A|BUTTON)$/;

const popoverInstances = [];

function getWindow(element) {
  const $dialog = domUtils.getTopDialog();
  if ($dialog && $dialog.length > 0 && $.contains($dialog[0], element[0])) {
    return $dialog;
  }
  return $(window);
}

const usingSetPosition = function (pos, feedback) {
  const $anchor = $(feedback.target.element);
  const top = pos.top - ($anchor.outerHeight() - $anchor[0].offsetHeight);
  // eslint-disable-next-line no-invalid-this
  $(this).css({
    top,
    left: pos.left,
    "max-height": "calc(95vh - " + top + "px)"
  });
};

function getBottomPopoverPosition($anchor, $panel) {
  $panel.position({
    my: "center top",
    at: "center bottom",
    of: $anchor,
    collision: "fit",
    within: getWindow($anchor),
    using: usingSetPosition
  });
}

function getBottomLeftPopoverPosition($anchor, $panel) {
  $panel.position({
    my: "left-5% top",
    at: "left bottom",
    of: $anchor,
    collision: "fit",
    within: getWindow($anchor),
    using: usingSetPosition
  });
}

function getBottomLeftNavBarPopoverPosition($anchor, $panel) {
  $panel.position({
    my: "left-1 top",
    at: "left bottom",
    of: $anchor,
    collision: "fit",
    within: getWindow($anchor),
    using: usingSetPosition
  });
}

function getTopLeftHelpPopoverPosition($anchor, $panel) {
  $panel.position({
    my: "left+5 top",
    at: "right center",
    of: $anchor,
    collision: "fit",
    within: getWindow($anchor),
    using: usingSetPosition
  });
}

function getBottomWidgetPopoverPosition($anchor, $panel) {
  $panel.position({
    my: "center top",
    at: "center bottom",
    of: $anchor,
    collision: "fit",
    within: getWindow($anchor),
    using: usingSetPosition
  });
}

function closePopoverAndAnchor($popoverElement, $anchor, appendToBody, popover) {
  $popoverElement.removeClass("open").slideUp(200, () => {
    restoreZIndex($popoverElement);

    if ($anchor) {
      $anchor.removeClass("open");
    }

    if (appendToBody) {
      ko.cleanNode($popoverElement[0]);
      $popoverElement.parent().remove();
    }

    popover.isOpen = false;

    // since removing the popover alters the z-index, this can impact other
    // popovers if they are within the same parent (issue IP-4445)
    repositionAllPopovers();
  });
}

function restoreZIndex($panel) {
  $panel.parents().each((i, el) => {
    const $el = $(el);
    const inlineZIndex = $el.data("original-z-index");
    if (inlineZIndex != null) {
      el.style.zIndex = inlineZIndex;
      $el.removeData("original-z-index");
    }
  });
}

function closeAllPopovers() {
  // reverse so that the last menus added close first
  // this will make the animations a little more fluid
  popoverInstances
    .filter((p) => p.isOpen)
    .reverse()
    .forEach((p) => p.close());
}

function repositionAllPopovers() {
  popoverInstances.filter((p) => p.isOpen).forEach((p) => p.setPosition());
}

function addGlobalEvents() {
  // note that we could try to reposition during resize/drag but there would be potentially a lot
  // of overhead to keeping it positioned, it could be a choppy experience (especially in IE) and
  // it's not completely unexpected for it to close (since clicking on the body closes the popover anyway)
  $(window)
    .one("resize.popover drag.popover", closeAllPopovers)
    .on("fixed.plex.popover unfixed.plex.popover", repositionAllPopovers);

  $(document).one("scroll.popover", closeAllPopovers);

  // allow popovers to be closed with escape key
  $(document).on("keyup.popover", (e) => {
    const code = e.keyCode || e.which;
    if (code === 27 /* ESCAPE */) {
      closeAllPopovers();
    }
  });
}

function removeGlobalEvents() {
  $(window).off("resize.popover drag.popover fixed.plex.popover unfixed.plex.popover");
  $(document).off("keyup.popover scroll.popover");
}

$(document).on("click.popover", (e) => {
  // this global click event propogates click events to the appropriate popovers
  // it may be more performant to have individual events for the anchors, but the
  // overhead here should be small and having these in a single event solves some
  // timing issues
  const anchors = popoverInstances.filter((p) => p.isAnchor(e.target));
  anchors.forEach((p) => p.toggle());

  if (anchors.length > 0 && e.target.getAttribute("href") === "#") {
    // prevent an empty anchor from causing the window to jump
    e.preventDefault();
  }

  popoverInstances
    .filter((p) => p.isOpen && (anchors.length === 0 || !p.containsElement(e.target)))
    .forEach((p) => p.handleClick(e));
});

class Popover {
  constructor(element, config, bindingContext) {
    this.element = element;
    this.config = config;
    this.bindingContext = bindingContext;

    this.init(config);
  }

  init(config) {
    this.$anchor = $(this.element);
    this.$popoverElement = $(config.panel);
    this.$scrollWindow = getWindow(this.$anchor);
    this.beforeOpen = config.callback || ((cb) => cb());
    this.onOpen = config.onOpen || $.noop;
    this.isOpen = false;

    switch (config.dock) {
      case "BottomLeft":
        this.dockPositionFunction = getBottomLeftPopoverPosition;
        break;
      case "BottomNavBarLeft":
        this.dockPositionFunction = getBottomLeftNavBarPopoverPosition;
        break;
      case "BottomHelp":
        this.dockPositionFunction = getTopLeftHelpPopoverPosition;
        break;
      case "BottomWidget":
        this.dockPositionFunction = getBottomWidgetPopoverPosition;
        break;
      default:
        this.dockPositionFunction = getBottomPopoverPosition;
        break;
    }

    if (config.popoverCloseElement && config.popoverCloseElement !== document) {
      this.$popoverCloseElement = $(config.popoverCloseElement);
    }

    popoverInstances.push(this);
  }

  getPanel() {
    if (this.$popoverElement.length === 0) {
      this.$popoverElement = $(this.config.panel);
      this.$popoverElement.data("popover-instance", this);
    } else if (!this.$popoverElement.data("popover-instance")) {
      this.$popoverElement.data("popover-instance", this);
    }

    return this.$popoverElement;
  }

  isAnchor(element) {
    return this.$anchor[0] === element || this.$anchor[0].contains(element);
  }

  containsElement(element) {
    if (this.isAnchor(element)) {
      return true;
    }

    if (this.$popoverCloseElement) {
      if (this.$popoverCloseElement[0] === element || this.$popoverCloseElement[0].contains(element)) {
        return false;
      }
    }

    const $panel = this.getPanel();
    if ($panel.length === 0) {
      return false;
    }

    return $panel[0].contains(element);
  }

  handleClick(e) {
    if (!this.isOpen) {
      return;
    }

    if (this.$popoverCloseElement) {
      if (this.$popoverCloseElement[0] === e.target || this.$popoverCloseElement[0].contains(e.target)) {
        this.close();
        return;
      }
    }

    const $panel = this.getPanel();
    if ($panel.hasClass("plex-popover-reloading")) {
      this.open();
      return;
    }

    if ($panel.hasClass("plex-popover-busy")) {
      return;
    }

    const panelContainsTarget = $panel[0].contains(e.target);
    if (panelContainsTarget) {
      const isChildPopover = popoverInstances.some((p) => p !== this && p.isAnchor(e.target));

      if (isChildPopover) {
        // this is a child so ignore the click
        return;
      }

      // this is a little presumpuous but the idea is that any link or button is going to perform
      // some action and that should cause the popover to close. This falls apart if the popover
      // contains controls (think pickers) but i don't think that use case exists
      if (clickableTagMatcher.test(e.target.nodeName) || $(e.target).closest("a").length > 0) {
        closeAllPopovers();
        return;
      }
    }

    if (
      this.element !== e.target &&
      $panel[0] !== e.target &&
      !panelContainsTarget &&
      !this.$anchor[0].contains(e.target)
    ) {
      this.close();
    }
  }

  toggle() {
    // check for disabled since pointer-events: none does not appear to work reliably in IE
    if (this.isOpen || this.getPanel().hasClass("open") || this.$anchor.hasClass("disabled")) {
      this.close();
    } else {
      this.beforeOpen(() => this.open());
    }
  }

  setPosition() {
    if (!this.isOpen) {
      return;
    }

    const $panel = this.getPanel();

    // restore z-index in case this is being repositioned
    restoreZIndex($panel);

    // in order for panel z-index to be honored it must be reflected by parents
    // this is meant to overcome a fixed actionbar which may have a dropdown menu
    // that could end up below a fixed grid header without this fix, see IP-4445
    // todo: this is hacky - would like a better css driven resolution for this
    const panelZIndex = parseInt($panel.css("z-index"), 10) || 0;
    if (panelZIndex > 0) {
      $panel.parents().each((i, el) => {
        if (el === document.body) {
          return false;
        }

        const $el = $(el);
        const zIndex = parseInt($el.css("z-index"), 10);
        if (zIndex >= 0 && zIndex < panelZIndex) {
          let originalZIndex = $el.data("original-z-index");
          if (originalZIndex === undefined) {
            originalZIndex = el.style.zIndex;
          }

          // store any inline z-index so it can be restored when closing
          $el.data("original-z-index", originalZIndex).css("z-index", panelZIndex);
        }

        return true;
      });
    }

    this.dockPositionFunction(this.$anchor, $panel);
  }

  open() {
    if (!popoverInstances.some((p) => p.isOpen)) {
      addGlobalEvents();
    }

    const $panel = this.getPanel();

    if (this.isOpen) {
      return;
    }

    if (this.$scrollWindow[0] !== window) {
      // if the popover is in a dialog, just close it when the user scrolls
      // again, we could try to reposition it on a scroll but that would add overhead
      this.$scrollWindow.one("scroll.popover", this.close.bind(this));
    }

    if (this.config.appendToBody) {
      const newElement = $("<div tabindex='-1'>").appendTo(document.body);
      newElement.append($panel);
      ko.applyBindings(this.bindingContext, $panel[0]);
    }

    this.isOpen = true;

    this.$anchor.addClass("open");
    $panel.addClass("open").slideDown(200, this.onOpen);

    this.setPosition();
  }

  close() {
    const $panel = this.getPanel();
    if (!popoverInstances.some((p) => p.isOpen && p !== $panel)) {
      removeGlobalEvents();
    }

    if (this.$scrollWindow) {
      this.$scrollWindow.off("scroll.popover");
    }

    if ($panel.is(":visible")) {
      closePopoverAndAnchor($panel, this.$anchor, this.config.appendToBody, this);
    }
  }

  dispose() {
    this.close();
    this.$popoverElement.removeData("popover-instance");
    popoverInstances.remove(this);
  }

  static closeAll() {
    closeAllPopovers();
  }
}

module.exports = Popover;
