ï»¿const Operation = require("./plex-grid-operation");
const Compiler = require("../../Expressions/plex-expressions-compiler");
const plexExport = require("../../../global-export");

const ExpressionOperation = Operation.extend({
  init: function (column, config) {
    this.config = config;
    this.column = column;
    this.css = ["plex-numeric-text"];

    this.evaluator = Compiler.compile(config.expression);
  },

  getValue: function (records, context) {
    // expressions expect this signature
    return this.evaluator.call(null, records[0], context, records);
  }
});

module.exports = ExpressionOperation;
plexExport("grid.aggregates.ExpressionOperation", ExpressionOperation);
