ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const comparableUtils = require("../Utilities/plex-utils-comparable");

const nonMutatingArrayMethods = [
  "filter",
  "map",
  "concat",
  "every",
  "forEach",
  "indexOf",
  "join",
  "lastIndexOf",
  "reduce",
  "reduceRight",
  "some",
  "slice"
];

ko.extenders.group = function (target, options) {
  let isSorting = false;
  let isGroupSort = false;
  let currentComparer = null;
  const groups = options.groups;
  const newArray = ko.observable();
  const observable = ko.computed({ read: newArray });
  let prior = target.peek().slice(0);

  function generateGroups(source) {
    if (!isSorting) {
      // the data needs to be sorted to be grouped
      // if it is not already sorted, sort by the last group
      // (the other groups will be sorted from the sorter)
      currentComparer =
        currentComparer || comparableUtils.createGroupComparer(groups, groups[groups.length - 1].propertyName);
      source = arrayUtils.stableSort(source, currentComparer);
    }

    newArray(buildGroups(source, groups, isGroupSort));
    return source;
  }

  target.subscribe(
    (changes) => {
      const source = target.peek();
      const result = generateGroups(source);

      if (source !== result) {
        // rebuild changes from prior copy of array
        changes = ko.utils.compareArrays(prior, result, { sparse: true });
      }

      observable.notifySubscribers(changes, "arrayChange");
      prior = result.slice(0);
    },
    null,
    "arrayChange"
  );

  observable.sort = function (groupComparer) {
    currentComparer = groupComparer;
    isGroupSort = currentComparer.isGroupSort;

    isSorting = true;
    target.stableSort(currentComparer);
    isSorting = isGroupSort = false;
  };

  const disposeFn = observable.dispose;
  observable.dispose = function () {
    prior = null;
    disposeFn.call(observable);
  };

  generateGroups(target.peek());
  return observable;
};

function buildGroups(data, groups, isGroupSort) {
  const root = Object.create(Group.prototype);
  root.groups = [];
  root.source = data;
  // data must be rerendered if the group sort is changed
  // todo: it doesn't feel like this should be aware of rendering concerns - may
  // want to look at refactoring this out somehow
  root.canSync = !isGroupSort;

  addArrayMethods(root, data);

  for (let i = 0, ln = data.length; i < ln; i++) {
    let current = root;
    const record = data[i];

    groups.forEach((group, groupIndex) => {
      const key = String(record[group.propertyName]);
      let currentGroup = current.groups.length > 0 && current.groups[current.groups.length - 1];

      if (!currentGroup || currentGroup.key !== key) {
        currentGroup = new Group(key, group, groupIndex, current.groups.length, current);
        current.groups.push(currentGroup);
      }

      current = currentGroup;
    });

    // we are at deepest node - go ahead and add data
    current.data.push(record);
  }

  return root;
}

function addArrayMethods(obj, arr) {
  let i = (obj.length = arr.length);

  // add indexes so data can be accessed directly at the root
  while (i--) {
    obj[i] = arr[i];
  }

  nonMutatingArrayMethods.forEach((fnName) => {
    obj[fnName] = arr[fnName].bind(arr);
  });
}

function Group(key, config, groupIndex, index, parent) {
  this.key = key;
  this.config = config;
  this.groups = [];
  this.data = [];
  this.groupIndex = groupIndex;
  this.index = index;
  this.parent = parent;
  this.gIndex =
    "groupIndex" in parent
      ? parent.groupIndex + "." + parent.index + "." + groupIndex + "." + index
      : groupIndex + "." + index;
}

Group.prototype = {
  constructor: Group,

  next: function () {
    this.currentIndex = this.currentIndex || 0;
    if (this.currentIndex >= this.groups.length) {
      if (this.parent) {
        return this.parent.next();
      }

      // we're at the root and have gone through the entire structure
      return false;
    }

    return this.groups[this.currentIndex++];
  },

  reset: function () {
    this.currentIndex = 0;
    this.groups.forEach((group) => {
      group.reset();
    });
  },

  isFirst: function () {
    return this.index === 0;
  },

  isLast: function () {
    if (!this.parent) {
      return false;
    }

    return this.index === this.parent.groups.length - 1;
  }
};
