ï»¿const valueProviderFactory = require("../../Labels/plex-labels-value-providers-factory");
const aggregateFactory = require("../Aggregates/plex-grid-aggregate-factory");
const BindingValueProvider = require("./plex-grid-value-providers-binding");
const plexExport = require("../../../global-export");

const PivotAggregateValueProvider = BindingValueProvider.extend({
  init: function () {
    this._base.apply(this, arguments);

    this.valueProvider = valueProviderFactory.create({ valueProvider: this.column.elements[0].recordValueProvider });

    const op = aggregateFactory.create(this, this.column.elements[0].aggregate);
    this.operation = op;
  },

  getValue: function (record) {
    if (this.operation) {
      return this.operation.getValue(this.getRecords([record]));
    }

    return 0;
  },

  getHtml: function (record, index) {
    const aggregateContext = this._createAggregateContext(record, index);

    const args = Array.prototype.slice.call(arguments);
    args[1] = aggregateContext;

    return this._base.apply(this, args);
  },

  getPrintValue: function (record, index) {
    const aggregateContext = this._createAggregateContext(record, index);

    const args = Array.prototype.slice.call(arguments);
    args[1] = aggregateContext;

    return this._base.apply(this, args);
  },

  getFormattedValue: function (record, index) {
    const aggregateContext = this._createAggregateContext(record, index);

    const args = Array.prototype.slice.call(arguments);
    args[1] = aggregateContext;

    return this._base.apply(this, args);
  },

  getRecords: function (records) {
    const all = [];
    let record;

    for (let i = 0, ln = records.length; i < ln; i++) {
      record = records[i];
      for (let bucketIndex = 0, bucketLength = record.$$buckets.length; bucketIndex < bucketLength; bucketIndex++) {
        if (record.$$buckets[bucketIndex].$$data && record.$$buckets[bucketIndex].$$data.$$empty !== true) {
          all.push(record.$$buckets[bucketIndex].$$data);
        }
      }
    }

    return all;
  },

  getFormatter: function () {
    if (this.column.elements[0].formatter) {
      return this.column.elements[0].formatter;
    }

    return this._base.apply(this, arguments);
  },

  _createAggregateContext: function (record, index) {
    return {
      id: this.column.id + index,
      section: this.column,
      data: this.getRecords([record])
    };
  }
});

module.exports = PivotAggregateValueProvider;
plexExport("grid.valueProviders.PivotAggregateValueProvider", PivotAggregateValueProvider);
