ï»¿const $ = require("jquery");
const ko = require("knockout");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

function getPrintElementId() {
  return "temp-print-element";
}

function filterElements(arr) {
  if (arr && Array.isArray(arr)) {
    return arr.filter(shouldPrint);
  }

  return [];
}

function shouldPrint(el) {
  const currentPage = plexImport("currentPage");
  if (el.id in currentPage && currentPage[el.id].elements && el.id in currentPage[el.id].elements) {
    el = currentPage[el.id].elements[el.id];
  }

  return (
    (typeof el.visible === "undefined" || el.visible() || el.clientVisible === false) &&
    ko.unwrap(el.printVisible) !== false &&
    (typeof el.style === "undefined" || el.style().display !== "none")
  );
}

function svgToDataUri(el, options) {
  const $svgElement = $("svg", $(el));

  if (!$svgElement.length) {
    throw new Error("Unable to find svg for element data uri.");
  }

  // options param can be used to set type directly w/o object
  options = options || {};
  if (typeof options === "string") {
    options = { type: options };
  }

  // extend default options
  options = $.extend(
    {
      type: "image/png",
      images: true
    },
    options
  );

  const $svg = createSvgForPrinting($svgElement, options.useInlinedStyles);

  if (options.useInlinedStyles) {
    inlineElementStyles($svgElement[0], $svg[0]);
  }

  // remove images for now
  if (!options.images) {
    $svg.find("image").remove();
  }

  const ns = "http://www.w3.org/2000/svg";
  const svg = document.createElementNS(ns, "svg");

  // chrome expects this attribute to be set
  if (svg.webkitMatchesSelector) {
    svg.setAttribute("xmlns", ns);
  }

  // setting up some default namesapce declaration
  svg.setAttribute("version", "1.1");
  svg.setAttribute("baseProfile", "full");
  svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");

  // copy (select) attribtues
  ["style", "width", "height", "version"].forEach((attr) => {
    const attrValue = $svg[0].getAttribute(attr);
    if (attrValue) {
      svg.setAttributeNS(null, attr, attrValue);
    }
  });

  // copy all child nodes
  while ($svg[0].hasChildNodes()) {
    const child = $svg[0].childNodes[0];

    svg.appendChild(child);
  }

  const canvas = document.createElement("canvas");
  canvas.width = $svg.attr("width");
  canvas.height = $svg.attr("height");

  // using extension method from canvg to render svg directly to canvas
  const serializer = new XMLSerializer();
  const context = canvas.getContext("2d");

  context.drawSvg(serializer.serializeToString(svg, 0, 0));

  if (options.useInlinedStyles) {
    $("#" + getPrintElementId()).remove();
  }

  return canvas.toDataURL(options.type);
}

function resolveViewName(value) {
  return value.replace(/^(_|elements\/_|elements-)/i, "").toLowerCase();
}

function createSvgForPrinting(originSvgObject, useInlinedStyles) {
  const $svgForPrinting = originSvgObject.clone();

  if (useInlinedStyles) {
    $svgForPrinting.removeAttr("class");
    $svgForPrinting.find("*").removeAttr("class");
    $svgForPrinting.attr("id", getPrintElementId());
    $svgForPrinting.attr("display", "none");
    $svgForPrinting.appendTo("body");
  }

  return $svgForPrinting;
}

function inlineElementStyles(styledElement, blankElement) {
  if (styledElement && blankElement) {
    const stylesOfStyledElement = getComputedStyle(styledElement);
    const styleOfBlankElement = getComputedStyle(blankElement);

    if (stylesOfStyledElement && styleOfBlankElement) {
      for (let i = 0; i < stylesOfStyledElement.length; i++) {
        const property = stylesOfStyledElement[i];

        const styledElementPropertyValue = stylesOfStyledElement.getPropertyValue(property);
        const blankElementPropertyValue = styleOfBlankElement.getPropertyValue(property);

        if (
          styledElementPropertyValue &&
          blankElementPropertyValue &&
          styledElementPropertyValue !== "" &&
          styledElementPropertyValue !== blankElementPropertyValue
        ) {
          blankElement.style[property] = styledElementPropertyValue;
        }
      }
    }

    const styledElementChildren = styledElement.children ? styledElement.children : styledElement.childNodes;
    const blankElementChildren = blankElement.children ? blankElement.children : blankElement.childNodes;

    for (let j = 0; j < styledElementChildren.length; j++) {
      const styledElementChild = styledElementChildren[j];
      const blankElementChild = blankElementChildren[j];

      inlineElementStyles(styledElementChild, blankElementChild);
    }
  }
}

const api = {
  filterElements,
  shouldPrint,
  svgToDataUri,
  resolveViewName
};

module.exports = api;
plexExport("printing", api);
