ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

function getWritableKeys(obj) {
  if (typeof obj !== "object") {
    return [];
  }

  return Object.keys(obj).filter((key) => {
    if (dataUtils.isProtectedProperty(key)) {
      return false;
    }

    const descriptor = Object.getOwnPropertyDescriptor(obj, key);
    return descriptor.writable === true || descriptor.set != null;
  });
}

function serialize(obj, keys, detectDependencies, trimWhitespace) {
  return JSON.stringify(
    dataUtils.cleanse(obj, {
      ignoreEmpty: false,
      recursive: true,
      properties: keys,
      detectDependencies,
      trimWhitespace
    })
  );
}

/**
 * Tracks the provide object for properties for changes.
 *
 * @param {any} root - the object to check to track for changes
 * @param {Object} [options] - the dirty flag options
 * @param {boolean} options.allowWhiteSpace - when enabled will include trailing whitespace changes as dirty
 * @returns {Object} The dirty flag object.
 */
ko.dirtyFlag = function (root, options) {
  // Stringify the entire object on any change
  let keys = getWritableKeys(root);
  const trimWhitespace = !options?.allowWhiteSpace;
  const initialState = ko.observable(serialize(root, keys, false, trimWhitespace));
  let evaluated = false;

  return {
    hasEvaluated: function () {
      return evaluated;
    },

    isDirty: ko.pureComputed(() => {
      // Compare stringified results
      evaluated = true;
      return initialState() !== serialize(root, keys, true, trimWhitespace);
    }),

    /**
     * Resets the dirty flag, setting a new base point for the initial state to compare against.
     */
    reset: function () {
      keys = getWritableKeys(root);
      initialState(serialize(root, keys, false, trimWhitespace));
    }
  };
};

module.exports = ko.dirtyFlag;
