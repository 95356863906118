ï»¿const expressions = require("../../Expressions/plex-expressions-compiler");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const ColumnIndentedFeature = Feature.extend({
  onInit: function () {
    this.indentationEvaluator = expressions.compile(this.config.indentationExpression);
  },

  onExecute: function (record, index, _records) {
    const level = this.indentationEvaluator(record, index);

    // only apply indentation if level has value > 0
    if (level > 0) {
      return {
        attr: {
          // note: this attribute provides a way for printing to determine the indentation
          // without resorting to supporting inline style
          "data-indentation-level": level
        },
        style: {
          "padding-left": level * this.config.indentationWidth + "px"
        }
      };
    }

    return {};
  }
});

module.exports = ColumnIndentedFeature;
plexExport("grid.features.ColumnIndented", ColumnIndentedFeature);
