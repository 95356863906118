ï»¿const ko = require("knockout");
const $ = require("jquery");
const formatUtils = require("../Globalization/plex-formatting");

ko.bindingHandlers.formattedText = {
  update: function (element, valueAccessor) {
    const options = valueAccessor();
    const value = ko.unwrap(options.value);
    const record = ko.unwrap(options.record);
    // this is unlikely to be observable or have any observable properties
    // if this ends up being needed we should unwrap all the observables
    // before passing them on to the formatter
    const formatter = options.formatter;
    $(element).html(formatUtils.formatValue(value, formatter, record, true));
  }
};
