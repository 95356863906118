ï»¿const $ = require("jquery");
const validate = require("./plex-dialogs-pinvalidate");
const plexImport = require("../../global-import");

const defaultOptions = {
  displayBadgeNo: false,
  displayUserId: false,
  mustValidate: true
};

function PINVerify(callback, options = {}) {
  if (arguments.length === 1 && typeof callback !== "function") {
    // allow to be called without a callback
    options = callback;
    callback = null;
  }

  options = $.extend({}, defaultOptions, options);

  const appState = plexImport("appState");
  const pun = options.pun || appState.user.pun;

  const dialogOptions = {
    pun,
    displayFieldsSequentially: options.displayFieldsSequentially || false,
    displayPasswordKeyboard: options.displayPasswordKeyboard || false,
    keyboardScale: options.keyboardScale || 1,
    title: options.title || "VERIFY PIN",
    badgeNoVisibility: pun === undefined ? options.displayBadgeNo : false,
    userIdVisibility: pun === undefined ? !options.displayBadgeNo : false,
    okButtonText: options.okButtonText || "Ok",
    okButtonTextForPIN: options.okButtonTextForPIN || options.okButtonText,
    okButtonTextForBadgeNo: options.okButtonTextForBadgeNo || options.okButtonText,
    okButtonTextForUserId: options.okButtonTextForUserId || options.okButtonText,
    questionForPIN: options.questionForPIN || "Please enter your PIN:",
    questionForBadgeNo: options.questionForBadgeNo || "Please enter your Badge No:",
    questionForUserId: options.questionForUserId || "Please enter your User ID:",
    question: options.question || "Please enter your credentials:",
    mustValidate: options.mustValidate
  };

  // for automated testing
  if (options.displayUserId) {
    dialogOptions.userIdVisibility = !options.displayBadgeNo;
    dialogOptions.badgeNoVisibility = options.displayBadgeNo;
  }

  const deferred = new $.Deferred();

  if (
    (dialogOptions.badgeNoVisibility || dialogOptions.userIdVisibility) &&
    (dialogOptions.displayFieldsSequentially || dialogOptions.displayPasswordKeyboard)
  ) {
    dialogOptions.displayType = "textbox";

    if (dialogOptions.badgeNoVisibility) {
      dialogOptions.question = dialogOptions.questionForBadgeNo;
      dialogOptions.okButtonText = dialogOptions.okButtonTextForBadgeNo;
    }

    if (dialogOptions.userIdVisibility) {
      dialogOptions.question = dialogOptions.questionForUserId;
      dialogOptions.okButtonText = dialogOptions.okButtonTextForUserId;
    }

    validate(dialogOptions, () => {})
      .then((data) => {
        dialogOptions.displayType = "password";
        dialogOptions.pun = data.pun;
        dialogOptions.question = dialogOptions.questionForPIN;
        dialogOptions.okButtonText = dialogOptions.okButtonTextForPIN;

        validate(dialogOptions, () => {})
          .then((res) => deferred[res ? "resolve" : "reject"](res), deferred.reject)
          .fail((res) => deferred.reject(res));
      })
      .fail((res) => deferred.reject(res));
  } else {
    if (dialogOptions.badgeNoVisibility === false && dialogOptions.userIdVisibility === false) {
      dialogOptions.question = dialogOptions.questionForPIN;
      dialogOptions.okButtonText = dialogOptions.okButtonTextForPIN;
    }

    dialogOptions.displayType = "password";
    validate(dialogOptions, () => {})
      .then((res) => deferred[res ? "resolve" : "reject"](res), deferred.reject)
      .fail((res) => deferred.reject(res));
  }

  if (callback) {
    // callback should be called whether authentication passes or not
    deferred.then(callback, callback);
  }

  return deferred.promise();
}

module.exports = PINVerify;
