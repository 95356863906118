/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const culture = require("../Globalization/plex-culture-datetime");
const dataUtils = require("../Utilities/plex-utils-data");
const DateTimeModel = require("./plex-datetime-model");
const DateRangeFilter = require("../Core/plex-dates-range-filter");
const dateTimeStateMachine = require("./plex-datetime-statemachine");
const dateUtils = require("../Core/plex-dates");
const CustomerDate = require("../Globalization/plex-customer-date");
const stringUtils = require("../Utilities/plex-utils-strings");
const ControllerFactory = require("./plex-controller-factory");
const DateRanges = require("../Core/plex-dates-ranges");
const browser = require("../Core/plex-browser");
const plexImport = require("../../global-import");
const jsUtils = require("../Utilities/plex-utils-js");

const $win = $(window);

const dateTimePrecisions = {
  minutes: "minutes",
  seconds: "seconds",
  milliseconds: "milliseconds"
};

// keycode: number of days to add
const _incrementDateKeyMap = {
  [browser.keyCodes.arrowUp]: 1,
  [browser.keyCodes.arrowDown]: -1,
  [browser.keyCodes.plus]: 1,
  [browser.keyCodes.minus]: -1,
  [browser.keyCodes.numpad.plus]: 1,
  [browser.keyCodes.numpad.minus]: -1
};

const openDateFilters = {
  openStartDate: "plex.dates.DateRange.MinStartDate",
  openEndDate: "plex.dates.DateRange.MaxEndDate"
};

function getDayHeaders() {
  const firstDay = culture.firstDay();
  const names = culture.getDayNames("short");
  const headers = [];
  for (let i = 0; i < names.length; i++) {
    headers.push(names[(i + firstDay) % 7]);
  }

  return headers;
}

function getDisplayFormat(config) {
  let format = "";
  if (config.primaryState !== dateTimeStateMachine.PrimaryStates.Time) {
    format = "yMd";
  }

  if (config.primaryState === dateTimeStateMachine.PrimaryStates.Time || config.enableTime) {
    format += config.militaryTime || culture.uses24HourCycle() ? "HH" : "h";
    format += "mm";

    if (config.dateTimePrecision !== dateTimePrecisions.minutes) {
      format += "ss";
    }

    if (config.dateTimePrecision === dateTimePrecisions.milliseconds) {
      format += "SSS";
    }
  }

  return { skeleton: format };
}

function getFilterFromGlobal(str) {
  if (str) {
    const nameParts = str.split(".");
    let filter = window;
    nameParts.forEach((namePart) => {
      filter = filter[namePart];
    });

    if (filter instanceof DateRangeFilter) {
      return filter;
    }
  }
  return undefined;
}

function getFilterFromPlex(str) {
  const ns = str.replace(/^plex\./, "");
  return plexImport(ns, true);
}

function getFilter(str) {
  if (str) {
    // prefer imported ranges
    const matcher = /\.(\w+)$/;
    const match = matcher.exec(str);
    if (match && match[1] in DateRanges) {
      return DateRanges[match[1]];
    }

    return getFilterFromPlex(str) || getFilterFromGlobal(str);
  }

  return null;
}

function sanitizeText(text) {
  if (typeof text === "string") {
    return stringUtils.replaceHtmlLineBreaks(text.replace(/&nbsp;/g, ""), " ");
  }

  return text;
}

function DateTimePickerController(config, model) {
  this.config = config;
  this.model = model;
  this.init(config, model);
}

DateTimePickerController.prototype = {
  constructor: DateTimePickerController,

  init: function (config, model) {
    const self = this;
    self.$element = null;
    self.$input = $(document.getElementById(self.config.id));
    self.dateTimePrecisions = dateTimePrecisions;
    self.config.primaryPropertyName = self.config.primaryPropertyName || self.config.propertyName;
    self.secondaryDateAdjust = self.config.secondaryDateAdjust;
    self.orgPlaceholder = self.config.placeholder ? ko.unwrap(self.config.placeholder) : "";

    if (self.config.militaryTime) {
      self.config.enableTime = true;
    }

    self.xPosition = ko.observable("0px");
    self.yPosition = ko.observable("0px");
    self.arrowPosition = ko.observable({ right: true, top: true, center: false });

    self.positionCss = ko.pureComputed(() => {
      const css = [];

      const arrowPosition = self.arrowPosition();
      css.push(arrowPosition.top ? "plex-datetimepicker-top" : "plex-datetimepicker-bottom");

      if (arrowPosition.center) {
        css.push("plex-datetimepicker-center");
      } else {
        css.push(arrowPosition.right ? "plex-datetimepicker-right" : "plex-datetimepicker-left");
      }

      return css.join(" ");
    });

    // User Defined Format
    if (config.dateFormat) {
      // todo: depreciate and convert to .Net
      self.displayFormat = { raw: culture.getCldrDateTimeFormat(config.dateFormat) };
    } else {
      self.displayFormat = getDisplayFormat(self.config);
    }

    self.dayHeadersViewModel = getDayHeaders();

    // setup computed observable for Primary Date
    const initialPrimaryDate = dataUtils.getValue(model, config.primaryPropertyName);
    let primaryObservable = dataUtils.getObservable(model, config.primaryPropertyName);

    if (primaryObservable && ko.isWritableObservable(primaryObservable)) {
      self.primaryDate = self.config.boundValue = primaryObservable.extend({ customerDate: config });
    } else {
      self.primaryDate = self.config.boundValue = primaryObservable = ko.observable().extend({ customerDate: config });
      dataUtils.setValue(model, config.primaryPropertyName, primaryObservable);
    }

    // set computed observable to trigger Write method (in case there is an initial value).
    self.primaryDate(initialPrimaryDate);

    self.primaryDatePickerModel = new DateTimeModel(self.primaryDate(), self.config);
    self.pickers = [this.primaryDatePickerModel];

    // setup computed observable for Secondary Date
    self.secondaryDatePickerModel = null;
    if (config.secondaryPropertyName) {
      const initialSecondaryDate = dataUtils.getValue(model, config.secondaryPropertyName);
      let secondaryObservable = dataUtils.getObservable(model, config.secondaryPropertyName);

      if (secondaryObservable && ko.isWritableObservable(secondaryObservable)) {
        self.secondaryDate = secondaryObservable.extend({ customerDate: config });
      } else {
        // create new observable - old one will be disposed when replaced
        self.secondaryDate = secondaryObservable = ko.observable().extend({ customerDate: config });
        dataUtils.setValue(model, config.secondaryPropertyName, self.secondaryDate);
      }

      // set computed observable to trigger Write method (in case there is an initial value).
      self.config.secondaryDate = self.secondaryDate;
      self.secondaryDate(initialSecondaryDate);

      self.secondaryDatePickerModel = new DateTimeModel(self.secondaryDateToDisplay(self.secondaryDate), self.config);
      self.pickers.push(self.secondaryDatePickerModel);
    } else {
      self.excludeFromDefaults = true;
    }

    // Allows an invalid date to be displayed..Used
    // for validation
    self.displayInvalidDateText = ko.observable();

    self.inputText = ko.computed({
      read: self.displayInvalidDateText,
      write: self.onDisplayTextChange,
      owner: self
    });

    self.lastInputText = null;
    self.config.boundDisplayValue = self.inputText;

    self.displayText = self.config.displayValue = ko.computed(self.getDisplayText, self);
    self.isBusy = ko.observable(false);

    self.titleText = ko.pureComputed(() => {
      return sanitizeText(self.displayText() || "");
    }, self);

    // If there are dateFilters, these will be reset
    self.openStartDate = ko.observable(false);
    self.openEndDate = ko.observable(false);

    // The unobserved variables are used so computed is not
    // triggered in getDisplayText()
    self.openStartDateUnobserved = false;
    self.openEndDateUnobserved = false;
    self.openDateEnabled = false;

    self.displayText.subscribe(self._setPlaceholder, self);

    if (config.dateFilters) {
      self.rangeOptionsMap = {};
      for (let i = 0; i < config.dateFilters.length; i++) {
        for (let j = 0; j < config.dateFilters[i].filters.length; j++) {
          const option = config.dateFilters[i].filters[j];
          self.rangeOptionsMap[option.filter] = getFilter(option.filter);
          if (option.filter === openDateFilters.openStartDate || option.filter === openDateFilters.openEndDate) {
            self.openDateEnabled = true;
          }
        }
      }

      self.selectedRange = ko.observable();
      if (!self.primaryDate() && self.secondaryDate() && self.openDateEnabled) {
        self.openStartDateUnobserved = true;
        self.selectedRange(openDateFilters.openStartDate);
        self.displayText = self.config.displayValue = ko.computed(self.getDisplayText, self);
      }

      if (!self.secondaryDate() && self.primaryDate() && self.openDateEnabled) {
        self.openEndDateUnobserved = true;
        self.selectedRange(openDateFilters.openStartDate);
        self.displayText = self.config.displayValue = ko.computed(self.getDisplayText, self);
      }

      self.selectedRange.subscribeChanged(self.onSelectedRangeChange, self);

      self.openStartDate = ko.computed(() => {
        const selectedRange = ko.unwrap(self.selectedRange);
        if (selectedRange === openDateFilters.openStartDate) {
          return true;
        }

        return false;
      });

      self.openStartDate.subscribe((value) => {
        self.openStartDateUnobserved = value;
      });

      self.openEndDate = ko.computed(() => {
        const selectedRange = ko.unwrap(self.selectedRange);
        if (selectedRange === openDateFilters.openEndDate) {
          return true;
        }

        return false;
      });

      self.openEndDate.subscribe((value) => {
        self.openEndDateUnobserved = value;
      });

      self.canResetRange = ko.computed(() => {
        return !self.openStartDate() && !self.openEndDate();
      });

      if (config.enableFilterDropdown && config.initialRange && config.initialRange.filter) {
        const initialRange = config.initialRange;
        if (self.rangeOptionsMap[initialRange.filter]) {
          // standard range filter that is selectable
          self.selectedRange(initialRange.filter);
        } else {
          // custom range filter that is not selectable
          self.setCustomRange(config.initialRange);
        }

        self.ok();
      }
    }

    self._setPlaceholder(self.displayText());
  },

  onclick: function (_picker, _e) {
    // noop
  },

  onmouseup: function (_picker, e) {
    // if you click anywhere in the picker area, focus in the textbox
    const $input = this.getInput();

    if (this.selectedTextExist()) {
      return false;
    }

    // Keep focus from triggering any event binding multiple times
    if ($input.length && $input[0] !== e.target) {
      $input.focus();
    }

    return true;
  },

  selectedTextExist: function () {
    let text = "";

    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.getSelection) {
      text = document.getSelection().toString();
    } else if (document.selection) {
      text = document.selection.createRange().text.toString();
    }

    if (text) {
      return true;
    }

    return false;
  },

  onkeydown: function (picker, e) {
    // check for backspace or left arrow and remove picked item if detected
    if ((e.which === 8 || e.which === 37) && !e.target.value) {
      self.lastInputText = sanitizeText(picker.config.displayValue());
      this.clear();
      picker.displayInvalidDateText(self.lastInputText);

      return false;
    }

    if (e.which === 13 && !picker.isBusy() && e.target.value) {
      e.target.blur();
      return false;
    }

    // single date picker and not busy
    if (
      !picker.secondaryDate &&
      picker.isBusy() === false &&
      picker.primaryDate() != null &&
      !e.target.value &&
      _incrementDateKeyMap[e.which]
    ) {
      this.incrementDate(_incrementDateKeyMap[e.which]);
      return false;
    }

    // allow the default action
    return true;
  },

  incrementDate: function (dateShift) {
    const shift = dateShift || 1;
    const date = this.primaryDate();

    this.clear();

    date.setDate(date.getDate() + shift);
    this.primaryDate(date);
    this.primaryDatePickerModel.selectedDate(date);

    this.ok();
  },

  onblur: function (picker, e) {
    if (self.lastInputText === e.target.value) {
      picker.onDisplayTextChange(e.target.value);
    }
  },

  selectDay: function (pickerIndex, date) {
    if (pickerIndex === 0) {
      this.primaryDatePickerModel.selectDay(date);
      // Reset secondary date so it can't be prior to primary
      if (
        this.secondaryDatePickerModel &&
        this.dateAfterPrimaryDate(this.secondaryDatePickerModel.selectedDate()) === false
      ) {
        this.secondaryDatePickerModel.selectDay(date);
      }

      if (this.secondaryDatePickerModel == null && !this.config.enableTime) {
        this.ok();
      }
    }
    // Only set secondary date if equal to or greater than primary date
    else if (pickerIndex === 1 && this.dateAfterPrimaryDate(date) === true) {
      this.secondaryDatePickerModel.selectDay(date);
    }
  },

  parseDisplayText: function (dateString) {
    let dateStrings,
      primaryDateString,
      secondaryDateString,
      primaryDate,
      secondaryDate,
      primaryDateOpen,
      secondaryDateOpen;

    if (dateString) {
      dateStrings = dateString.split(" - ");
      primaryDateString = dateStrings[0];
      secondaryDateString = dateStrings[1];
      primaryDate = culture.parseDate(primaryDateString, this.displayFormat);
      secondaryDate = culture.parseDate(secondaryDateString, this.displayFormat);
    }

    primaryDateOpen = false;
    secondaryDateOpen = false;

    if (primaryDate == null && primaryDateString && primaryDateString.toLowerCase() === "open") {
      primaryDateOpen = true;
    }

    if (secondaryDate == null && secondaryDateString && secondaryDateString.toLowerCase() === "open") {
      secondaryDateOpen = true;
    }

    if (primaryDateOpen && secondaryDateOpen) {
      primaryDateOpen = false;
      secondaryDateOpen = false;
    }

    if (
      (primaryDate == null && !primaryDateOpen) ||
      (this.secondaryDate && secondaryDate == null && !secondaryDateOpen)
    ) {
      dateString = dateString.replace(/\s+/g, "");

      if (this.secondaryDate) {
        dateStrings = dateUtils.separateDates(dateString);
        primaryDateString = dateStrings[0];
        secondaryDateString = dateStrings[1];
      } else {
        primaryDateString = dateString;
      }

      primaryDate = culture.parseDate(primaryDateString, this.displayFormat);
      secondaryDate = culture.parseDate(secondaryDateString, this.displayFormat);
    }

    return {
      primaryDate,
      secondaryDate,
      primaryDateOpen,
      secondaryDateOpen
    };
  },

  _setPlaceholder: function (displayText) {
    const placeholderValue = displayText ? "" : this.orgPlaceholder;
    if (this.config.placeholder) {
      if (ko.isObservable(this.config.placeholder)) {
        this.config.placeholder(placeholderValue);
      } else {
        this.config.placeholder = placeholderValue;
      }
    }
  },

  onDisplayTextChange: function (value) {
    const self = this;

    const displayText = self.parseDisplayText(value);

    let primaryDate = displayText.primaryDate;
    let secondaryDate = displayText.secondaryDate;

    const primaryDateOpen = displayText.primaryDateOpen && self.openDateEnabled;
    const secondaryDateOpen = displayText.secondaryDateOpen && self.openDateEnabled;

    self.clearInvalidDateText();

    if (primaryDateOpen) {
      self.selectedRange(openDateFilters.openStartDate);
    }

    if (secondaryDateOpen) {
      self.selectedRange(openDateFilters.openEndDate);
    }

    if (primaryDate != null) {
      primaryDate = new CustomerDate(
        primaryDate.getFullYear(),
        primaryDate.getMonth(),
        primaryDate.getDate(),
        primaryDate.getHours(),
        primaryDate.getMinutes(),
        primaryDate.getSeconds(),
        primaryDate.getMilliseconds()
      );
      self.primaryDatePickerModel.selectedDate(primaryDate);
      self.primaryDatePickerModel.setTime(primaryDate);
    }

    // This displays an invalid date. Implemented so validation will fail if
    // user enters an invalid date and immediatley presses ok/apply.
    // Previously date was just cleared and null was passed.
    if (primaryDate == null && self.getDisplayText() == null) {
      self.displayInvalidDateText(value);
    }

    // Same for secondaryDate, if range
    if (self.secondaryDatePickerModel && secondaryDate == null && self.getDisplayText() == null) {
      self.displayInvalidDateText(value);
    }

    if (self.secondaryDate != null && secondaryDate != null && (primaryDate != null || primaryDateOpen)) {
      secondaryDate = new CustomerDate(
        secondaryDate.getFullYear(),
        secondaryDate.getMonth(),
        secondaryDate.getDate(),
        secondaryDate.getHours(),
        secondaryDate.getMinutes(),
        secondaryDate.getSeconds(),
        secondaryDate.getMilliseconds()
      );
      // Can't allow secondary date to be before primary date
      if (this.dateAfterPrimaryDate(secondaryDate)) {
        self.secondaryDatePickerModel.selectedDate(secondaryDate);
        self.secondaryDatePickerModel.setTime(secondaryDate);
      } else {
        self.secondaryDatePickerModel.selectedDate(primaryDate);
        self.secondaryDatePickerModel.setTime(primaryDate);
      }
    }

    self.inputText.notifySubscribers(self.inputText());
    if (
      (primaryDate == null && !primaryDateOpen) ||
      (secondaryDate == null && self.secondaryDate != null && !secondaryDateOpen)
    ) {
      self.clearInvalidDateText();
      self.render();
    } else {
      self.ok();
    }
  },

  onSelectedRangeChange: function (newRange, oldRange) {
    const self = this;
    let primaryDate, secondaryDate, today;
    const startDateOptions = { weekStart: self.config.weekStart };
    const endDateOptions = {
      weekStart: self.config.weekStart,
      secondaryDateAdjust: self.secondaryDateAdjust,
      enableTime: self.config.enableTime,
      dateTimePrecision: self.config.dateTimePrecision
    };

    if (self.primaryDatePickerModel && self.secondaryDatePickerModel) {
      const filter = self.rangeOptionsMap[newRange];
      if (filter) {
        primaryDate = new CustomerDate(filter.getStartDate(new CustomerDate(), startDateOptions));
        secondaryDate = new CustomerDate(filter.getEndDate(new CustomerDate(), endDateOptions));

        self.setRangeBindings(primaryDate, secondaryDate);
      } else if (newRange in self.rangeOptionsMap) {
        // switching to "(select custom range)" that presents in self.rangeOptionsMap

        if (oldRange === openDateFilters.openStartDate) {
          // swithing from open start date

          today = new CustomerDate(DateRanges.CurrentDay.getStartDate(new CustomerDate(), startDateOptions));
          secondaryDate = self.secondaryDatePickerModel.selectedDate();

          // set primaryDate to today or current secondaryDate if it's an earlier date
          if (dateUtils.compareDays(today, secondaryDate) <= 0) {
            primaryDate = today;
          } else {
            primaryDate = new CustomerDate(DateRanges.CurrentDay.getStartDate(secondaryDate, startDateOptions));
          }

          self.primaryDatePickerModel.reset(primaryDate);
        } else if (oldRange === openDateFilters.openEndDate) {
          // swithing from open end date

          today = new CustomerDate(DateRanges.CurrentDay.getEndDate(new CustomerDate(), endDateOptions));
          primaryDate = self.primaryDatePickerModel.selectedDate();

          // set secondaryDate to today or current primaryDate if it's a later date
          if (dateUtils.compareDays(today, primaryDate) >= 0) {
            secondaryDate = today;
          } else {
            secondaryDate = new CustomerDate(DateRanges.CurrentDay.getEndDate(primaryDate, endDateOptions));
          }

          self.secondaryDatePickerModel.reset(self.secondaryDateToDisplay(secondaryDate));
        }
      }
    }
  },

  setRangeBindings: function (primaryDate, secondaryDate) {
    const self = this;

    self.removeRangeBindings();
    self.primaryDatePickerModel.reset(primaryDate);
    self.secondaryDatePickerModel.reset(self.secondaryDateToDisplay(secondaryDate));
    self.applyRangeBindings();
  },

  setCustomRange: function (customRange) {
    const self = this;

    const filter = getFilter(customRange.filter);
    if (filter && self.primaryDatePickerModel && self.secondaryDatePickerModel) {
      const primaryDate = new CustomerDate(filter.getStartDate(new CustomerDate(), customRange));
      const secondaryDate = new CustomerDate(filter.getEndDate(new CustomerDate(), customRange));

      self.setRangeBindings(primaryDate, secondaryDate);
    }
  },

  getInput: function () {
    // Required for when date picker is rendered by a Grid. Grid inits picker
    // before DOM element is created so $input may not point to picker.
    if (this.$input.length === 0) {
      this.$input = $(document.getElementById(this.config.id));
    }

    return this.$input;
  },

  getDisplayText: function () {
    let displayText, primaryCustomerDate, secondaryCustomerDate;

    // Unwrap secondaryDate here to trigger computed updates
    const primaryDate = ko.unwrap(this.primaryDate);
    let secondaryDate = ko.unwrap(this.secondaryDate);

    this.clearInvalidDateText();

    if (primaryDate == null && !this.openStartDateUnobserved) {
      return null;
    }

    if (this.openStartDateUnobserved && this.openDateEnabled) {
      displayText = "Open";
    } else {
      primaryCustomerDate = new CustomerDate(primaryDate);
      displayText = culture.formatDate(primaryCustomerDate, this.displayFormat);
    }

    // Check for the observable
    if (this.secondaryDate) {
      secondaryDate = this.secondaryDateToDisplay(secondaryDate);
      if (secondaryDate == null && !this.openEndDateUnobserved) {
        return null;
      }

      if (this.openEndDateUnobserved && this.openDateEnabled) {
        displayText += " - Open";
      } else {
        secondaryCustomerDate = new CustomerDate(secondaryDate);

        if (this.config.enableTime) {
          displayText +=
            " -<br/>&nbsp;&nbsp;&nbsp;&nbsp;" + culture.formatDate(secondaryCustomerDate, this.displayFormat);
        } else {
          displayText += " - " + culture.formatDate(secondaryCustomerDate, this.displayFormat);
        }
      }
    }

    return displayText;
  },

  pick: function () {
    if (this.config.disabled() || this.config.readOnly()) {
      return;
    }

    this.reset();
    this.render();
  },

  resetRange: function () {
    if (this.selectedRange) {
      this.selectedRange(null);
    }
  },

  clear: function () {
    this.primaryDate(null);
    if (this.secondaryDate) {
      this.secondaryDate(null);
    }
    this.resetRange();
    this.reset();
    this.getInput().trigger("text-change");
  },

  clearInvalidDateText: function () {
    if (this.displayInvalidDateText) {
      this.displayInvalidDateText(null);
    }
  },

  applyRangeBindings: function () {
    const self = this;
    this.rangeSubscriptions = [];

    for (let i = 0; i < this.pickers.length; i++) {
      const picker = this.pickers[i];

      const resetRange = function () {
        if (self.canResetRange()) {
          self.resetRange();
        }
      };

      this.rangeSubscriptions.push(picker.selectedDate.subscribe(resetRange));
      if (this.config.enableTime) {
        this.rangeSubscriptions.push(picker.selectedHour.subscribe(resetRange));
        this.rangeSubscriptions.push(picker.selectedMinute.subscribe(resetRange));
        this.rangeSubscriptions.push(picker.selectedSecond.subscribe(resetRange));
        this.rangeSubscriptions.push(picker.selectedMillisecond.subscribe(resetRange));
        this.rangeSubscriptions.push(picker.selectedTimePeriod.subscribe(resetRange));
      }
    }
  },

  removeRangeBindings: function () {
    if (this.rangeSubscriptions) {
      for (let i = 0; i < this.rangeSubscriptions.length; i++) {
        this.rangeSubscriptions[i].dispose();
      }
    }
  },

  // Since there is no way to set seconds or milliseconds at the moment both with have defaults applied when OK is hit.
  ok: function () {
    const self = this;

    let primaryDate;
    if (self.config.primaryState === dateTimeStateMachine.PrimaryStates.Time) {
      primaryDate = self.primaryDatePickerModel.getTime(self.primaryDatePickerModel.getDateWithTime());
    } else if (self.config.enableTime) {
      primaryDate = self.primaryDatePickerModel.getDateWithTime();
    } else {
      primaryDate = self.primaryDatePickerModel.selectedDate();
    }

    // Clear if User choose an open start date
    if (self.openStartDate()) {
      self.primaryDate(null);
    } else {
      self.primaryDate(primaryDate);
    }

    if (self.secondaryDate) {
      let secondaryDate;
      if (self.config.enableTime) {
        secondaryDate = self.secondaryDateFromDisplay(self.secondaryDatePickerModel.getDateWithTime());
      } else {
        secondaryDate = self.secondaryDateFromDisplay(self.secondaryDatePickerModel.selectedDate);
      }

      if (self.openEndDate()) {
        self.secondaryDate(null);
      } else {
        self.secondaryDate(secondaryDate);
      }
    }

    self.dispose();
  },

  cancel: function () {
    this.dispose();

    this.reset();
  },

  reset: function () {
    const openStartDate = ko.unwrap(this.openStartDate);
    const openEndDate = ko.unwrap(this.openEndDate);

    if (!openStartDate && !openEndDate) {
      this.resetRange();
    }

    const currentDate = new CustomerDate();

    if (!openStartDate) {
      const primaryDate = this.primaryDate();
      this.primaryDatePickerModel.reset(primaryDate ? new CustomerDate(primaryDate) : currentDate);
    }

    if (this.secondaryDatePickerModel && !openEndDate) {
      const secondaryDate = this.secondaryDate();
      this.secondaryDatePickerModel.reset(
        secondaryDate ? new CustomerDate(this.secondaryDateToDisplay(secondaryDate)) : currentDate
      );
    }
  },

  dateInRange: function (date) {
    if (this.secondaryDatePickerModel == null) {
      return false;
    }

    return (
      dateUtils.compareDays(date, this.primaryDatePickerModel.selectedDate()) >= 0 &&
      dateUtils.compareDays(date, this.secondaryDatePickerModel.selectedDate()) <= 0
    );
  },

  // Checks if secondary date is before primary date (out of range)
  dateExceedsRange: function (pickerIndex, date) {
    if (this.secondaryDatePickerModel == null || pickerIndex === 0) {
      return false;
    }

    if (this.dateAfterPrimaryDate(date) === true) {
      return false;
    } else {
      return true;
    }
  },

  dateAfterPrimaryDate: function (date) {
    return dateUtils.compareDays(date, this.primaryDatePickerModel.selectedDate()) >= 0;
  },

  secondaryDateToDisplay: function (secondaryDate) {
    return this.dateAdjust(secondaryDate, -1);
  },

  secondaryDateFromDisplay: function (secondaryDate) {
    return this.dateAdjust(secondaryDate, 1);
  },

  dateAdjust: function (date, adjustment) {
    const secondaryDate = ko.unwrap(date);

    if (!this.secondaryDateAdjust || !secondaryDate) {
      return ko.unwrap(secondaryDate);
    }

    if (this.config.enableTime) {
      return this.timeAdjust(secondaryDate, adjustment);
    }

    return new CustomerDate(dateUtils.addDays(secondaryDate, adjustment));
  },

  timeAdjust: function (dateTime, adjustment) {
    if (this.config.dateTimePrecision === dateTimePrecisions.seconds) {
      return new CustomerDate(dateUtils.addSeconds(dateTime, adjustment));
    } else if (this.config.dateTimePrecision === dateTimePrecisions.milliseconds) {
      return new CustomerDate(dateUtils.addMilliseconds(dateTime, adjustment));
    }

    return new CustomerDate(dateUtils.addMinutes(dateTime, adjustment));
  },

  render: function () {
    this.isBusy(true);

    const self = this;
    if (self.$element) {
      return;
    }

    self.$element = $("<div tabindex='-1'>");
    self.$anchor = $("#" + self.config.id + "_Anchor");

    // we could reposition the element when scrolling but to keep it simple we'll just close for now
    // note that this is only needed when the scroll container is not the window
    self.$anchor.scrollParent().one("scroll.datetimepicker", () => this.dispose());

    // Attach to body so picker always appears on top
    $("body").append(self.$element);
    ko.renderTemplate("datetimepicker-popover", self, {}, self.$element[0]);
    self.positionElement();

    // Delay is needed to avoid issue, when fields are left empty
    // if user changes focus using mouse (IP-5580)
    jsUtils.defer(
      () => {
        $(document).on("click.plex.datetimepicker." + self.config.id, (event) => {
          if (self.$element) {
            if (
              !(
                self.$element.is(event.target) ||
                self.$element.find(event.target).length ||
                $(event.target).hasClass("plex-calendar-icon")
              ) ||
              ($(event.target).hasClass("plex-calendar-icon") && !self.$anchor.is(event.target))
            ) {
              self.dispose();
              event.preventDefault();
            }
          }
        });
      },
      null,
      null,
      300
    );
  },

  positionElement: function () {
    const self = this;

    if (self.$element) {
      const $popover = self.$element.children(".plex-datetimepicker");
      const pickerWidth = $popover.width();
      const pickerHeight = $popover.height() + 10;
      const anchorOffset = self.$anchor.offset();
      const windowHeight = $win.height();
      const arrowPosition = ko.utils.unwrapObservable(self.arrowPosition);
      const formFrameAndFooterHeight = 85;

      const renderPickerAboveIcon =
        anchorOffset.top - pageYOffset + pickerHeight > windowHeight - formFrameAndFooterHeight &&
        anchorOffset.top - pickerHeight > 0;

      if (renderPickerAboveIcon) {
        self.yPosition(anchorOffset.top - pickerHeight + "px");
        arrowPosition.top = false;
      } else {
        self.yPosition(anchorOffset.top + 25 + "px");
        arrowPosition.top = true;
      }

      // note that left positioning does not appear to be accounted for
      arrowPosition.right = true;
      arrowPosition.center = false;
      let x = anchorOffset.left - pickerWidth + 91;

      if (x < 0) {
        arrowPosition.center = true;
        x = anchorOffset.left + self.$anchor.width() / 2 - pickerWidth / 2;
      }

      self.xPosition(x + "px");
      self.arrowPosition(arrowPosition);
    }
  },

  dispose: function () {
    const self = this;
    if (self.$element) {
      $(document).off("click.plex.datetimepicker." + this.config.id);

      ko.removeNode(self.$element[0]);
      self.$element = null;
    }

    if (self.$anchor) {
      self.$anchor.scrollParent().off("scroll.datetimepicker");
    }

    // after closing return focus to the input field
    window.setTimeout(() => {
      self.getInput().focus();
    }, 0);

    this.isBusy(false);
  },

  getValue: function () {
    if (this.config.secondaryPropertyName) {
      if (this.primaryDate() || this.secondaryDate()) {
        return [this.primaryDate(), this.secondaryDate()];
      }

      return null;
    }

    return this.primaryDate();
  },

  getState: function () {
    const self = this;

    const primaryDate = self.primaryDate();
    const secondaryDate = self.secondaryDate ? self.secondaryDate() : null;
    let dateRange = self.selectedRange ? self.selectedRange() : null;

    if (dateRange) {
      let resetRange = false;
      const filter = self.rangeOptionsMap[dateRange] || getFilter(dateRange);
      if (filter) {
        const expectedPrimaryDate = new CustomerDate(
          filter.getStartDate(new CustomerDate(), { weekStart: self.config.weekStart })
        );
        const expecteSecondaryDate = new CustomerDate(
          filter.getEndDate(new CustomerDate(), {
            weekStart: self.config.weekStart,
            secondaryDateAdjust: self.secondaryDateAdjust,
            enableTime: self.config.enableTime,
            dateTimePrecision: self.config.dateTimePrecision
          })
        );
        if (dateRange === openDateFilters.openStartDate) {
          resetRange = primaryDate !== null;
        } else if (dateRange === openDateFilters.openEndDate) {
          resetRange = secondaryDate !== null;
        } else {
          resetRange =
            (primaryDate && primaryDate.getTime() !== expectedPrimaryDate.getTime()) ||
            (secondaryDate && secondaryDate.getTime() !== expecteSecondaryDate.getTime());
        }
      }

      if (resetRange) {
        self.resetRange();
        dateRange = null;
      }
    }

    return {
      primaryDate: primaryDate || null,
      secondaryDate: secondaryDate || null,
      format: self.displayFormat,
      range: dateRange
    };
  },

  restoreState: function (state) {
    if (!state) {
      return;
    }

    const self = this;

    const dateRange = state.range;

    if (dateRange) {
      const filter = self.rangeOptionsMap[dateRange] || getFilter(dateRange);
      self.selectedRange(dateRange);

      if (dateRange === openDateFilters.openStartDate || dateRange === openDateFilters.openEndDate) {
        self.primaryDate(state.primaryDate);
        self.secondaryDate(state.secondaryDate);
      } else {
        self.primaryDate(
          new CustomerDate(filter.getStartDate(new CustomerDate(), { weekStart: self.config.weekStart }))
        );
        self.secondaryDate(
          new CustomerDate(
            filter.getEndDate(new CustomerDate(), {
              weekStart: self.config.weekStart,
              secondaryDateAdjust: self.secondaryDateAdjust,
              enableTime: self.config.enableTime,
              dateTimePrecision: self.config.dateTimePrecision
            })
          )
        );
      }
    } else {
      self.primaryDate(state.primaryDate);
      if (self.secondaryDate) {
        self.secondaryDate(state.secondaryDate);
      }

      if (self.selectedRange) {
        self.selectedRange(null);
      }
    }

    self.displayFormat = state.format;
  },

  isFilterVisible: function () {
    const self = this;
    return (self.primaryDate && self.primaryDate()) || (self.secondaryDate && self.secondaryDate());
  }
};

// factory method
DateTimePickerController.create = function (config, model) {
  return new DateTimePickerController(config, model);
};

ControllerFactory.register("Elements/_DatePicker", DateTimePickerController);
module.exports = DateTimePickerController;
