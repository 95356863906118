ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ServerAction = Action.extend({
  onExecute: function (data) {
    const self = this;
    const $deferred = new $.Deferred();
    const postData = dataUtils.cleanse(data);

    $.ajax({
      url: this.address,
      type: this.httpMethod,
      data: this.httpMethod === "POST" ? JSON.stringify(postData) : postData
    })
      .fail($deferred.reject)
      .done((results) => {
        if (!self.validateResponse(results, self.parent)) {
          $deferred.reject();
          return;
        }

        $deferred.resolve(results.Data || results);
      });

    return $deferred.promise();
  }
});

module.export = ServerAction;
plexExport("actions.Server", ServerAction);
