ï»¿const ko = require("knockout");
const jsUtils = require("../../Utilities/plex-utils-js");
const dataUtils = require("../../Utilities/plex-utils-data");
const valueProviderFactory = require("../../Grid/ValueProviders/plex-grid-value-providers-factory");
const actionHandler = require("../plex-handler-action");
const elementHandler = require("../plex-handler-element");
const SelectionState = require("../plex-selection-state");
const cssMap = require("./plex-grid-css-map");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const plexExport = require("../../../global-export");

const isDynamicVisibleFeature = (feature) => {
  return feature.name === "Dynamic" && !!feature?.entries.some((e) => e.id === "clientVisible");
};

const isVisibleFeature = (feature) => {
  return feature.name === "ColumnVisible" || isDynamicVisibleFeature(feature);
};

const DefaultColumn = function () {
  // constructor
};

DefaultColumn.prototype = {
  constructor: DefaultColumn,

  init: function (column) {
    this.applyAttributes(column);
    this.applyCss(column);
    this.setupFeatures(column);

    if (!column.valueProvider || typeof column.valueProvider.getValue !== "function") {
      column.valueProvider = valueProviderFactory.create(column, this.parent.config);
    }

    if (column.action) {
      actionHandler.initAction(column.action, this.parent.grid);
      column.executeAction = function (data, e) {
        const self = this;

        const record = self.valueProvider.getRecord(data);
        const result = actionHandler.executeAction(self.action, record, e);
        return result;
      };
    }

    if (column.enableAction) {
      actionHandler.initAction(column.enableAction, this.parent.grid);
    }

    if (column.enableToggleAll && !column.master) {
      column.selectionState = new SelectionState(this.parent.datasource.source, column, this.parent.grid);
    }

    column.$$initialized = true;
  },

  applyCss: function (column) {
    const self = this;
    column.css = cssMap.toClassName(column) || column.css;
    column.headerCss = ko.pureComputed(() => {
      const css = ["plex-grid-header-cell"];

      if (column.isSortable && self.parent.datasource.data().length > 0) {
        css.push("sortable");
      }

      if (column.sorted()) {
        css.push("sorted");
      }

      if (column.css) {
        css.push(column.css);
      }

      if (column.parent && column.parent.firstChild) {
        css.push("plex-grid-column-group-child");

        // see if the column is within a column group and apply
        // borders depending on it's position
        if (column.parent.firstChild() === column) {
          css.push("plex-grid-column-group-border-left");
        }

        if (column.parent.lastChild() === column) {
          css.push("plex-grid-column-group-border-right");
        }
      }

      return css.join(" ");
    });
  },

  applyAttributes: function (column) {
    // note: preserve observables in case column is re-initialized
    column.isSortable = column.isSortable && this.parent.config.isSortable !== false;
    column.visible = ko.isObservable(column.visible) ? column.visible : ko.observable(column.clientVisible !== false);
    column.sorted = ko.isObservable(column.sorted) ? column.sorted : ko.observable(false);
    column.sortedAsc = ko.isObservable(column.sortedAsc) ? column.sortedAsc : ko.observable(false);
    column.sortedOrder = ko.isObservable(column.sortedOrder) ? column.sortedOrder : ko.observable(0);
    column.sortHtmlText =
      column.sortHtmlText === true ||
      (column.features &&
        Array.isArray(column.features) &&
        column.features.filter((feature) => {
          return feature.name === "HtmlDisplay";
        }).length > 0);
    column.printVisible = ko.isObservable(column.printVisible)
      ? column.printVisible
      : ko.observable(column.printVisible !== false);
    if (column.headerElement) {
      // keep a clone of the original around in case the column config needs to be cloned (ie crosstabs)
      column.headerElementSource = column.headerElementSource || dataUtils.clone(column.headerElement);
      elementHandler.initElement(column.headerElement);

      // expose these directly on the column so that they can be altered by devs.
      // depending on the type of header element, these might be undefined
      column.headerName = column.headerElement.title || column.headerElement.formattedValue;
      column.shortHeaderName = column.headerElement.text || column.headerName;
    }
  },

  setupFeatures: function (column) {
    // todo: this is hacky and should be re-thought - we may need to separate features that act on the column vs features
    // that act on the column *content*.
    const features = (column.features || []).filter(isVisibleFeature);
    const featureProcessor = new FeatureProcessor(features, column);
    const featureResult = ko.computedPromise(() => featureProcessor.process());

    const visible = column.visible;
    let priorValue = visible() && featureResult()?.render;
    const parent = this.parent.grid;

    // these are settable so wrap in writable computed
    column.visible = ko.pureComputed({
      read: function () {
        // always call these to ensure dependencies are registered
        let tempFeatureResult = featureResult();
        const columnVisible = visible();
        const data = ko.unwrap(parent.results);

        if (features.some(isDynamicVisibleFeature) && data.length > 0) {
          tempFeatureResult = featureProcessor.process(data[0]);
        }

        if (!ko.unwrap(parent && parent.$grid && parent.$grid.isRendering)) {
          return priorValue;
        }

        if (tempFeatureResult?.render === false) {
          return (priorValue = false);
        }

        return (priorValue = columnVisible);
      },
      write: visible
    });

    const printVisible = column.printVisible;
    column.printVisible = ko.pureComputed({
      read: function () {
        if (featureResult().render === false) {
          return false;
        }

        return printVisible();
      },
      write: printVisible
    });
  }
};

jsUtils.makeExtendable(DefaultColumn);

module.exports = DefaultColumn;
plexExport("grid.columns.Default", DefaultColumn);
