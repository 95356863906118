ï»¿const Controller = require("./plex-controller-base");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const plexExport = require("../../global-export");

const LegendController = Controller.extend({
  toDocumentXml: function () {
    const doc = new DocumentXml("plex-legend");
    let items, node;

    if (this.config.title) {
      doc.addElement("title", this.config.title);
    }

    if (this.config.items && this.config.items.length > 0) {
      items = doc.createNode("items");

      this.config.items.forEach((item) => {
        node = items.createNode("item");

        if (item.text) {
          node.addElement("text", item.text);
        }

        if (item.symbol) {
          node.addElement("symbol", item.symbol);
        }

        if (item.htmlColor) {
          node.addElement("htmlcolor", item.htmlColor);
        }
      });
    }

    return doc.serialize();
  }
});

LegendController.create = function (config, model) {
  return new LegendController(config, model);
};

module.exports = LegendController;
plexExport("LegendController", LegendController);
