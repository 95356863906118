ï»¿const ko = require("knockout");
const $ = require("jquery");
const compiler = require("../Expressions/plex-expressions-compiler");
const jsUtils = require("./plex-utils-js");
const knockoutUtils = require("../Knockout/plex-utils-knockout");
const { getProxiedObservable, isProxied } = require("./plex-utils-proxy");
const plexImport = require("../../global-import");

// since 'data' is a shared namespace with some of the items in the /Data/ folder, we need to make sure we're using the same namespace object
const api = plexImport("data");

const hasOwnProperty = Object.prototype.hasOwnProperty;
const systemParamPrefix = "$$";
const propertyRgx = /^([^[]+)(?:\[(\d+)\])?$/;
const recursiveTestRgx = /\.|\[/;
const propertyExclusions = {
  DirtyProperties: true
};

const defaultOptions = {
  flatten: true,
  ignoreEmpty: true,
  detectDependencies: false,
  trimWhitespace: true,
  toString: false,
  recursive: false,
  keepObservables: false
};

const defaultCloneOptions = {
  excludeSystemProperties: false
};

const types = [Date, RegExp, String, Number, Boolean];
const ERROR_DEFAULT_VALUE = "#ERROR#";

function has(obj, propertyName) {
  return hasOwnProperty.call(obj, propertyName);
}

function canClone(value) {
  if (value && typeof value === "object") {
    // ignore dom objects
    if (value.nodeType > 0) {
      return false;
    }

    return !types.some((type) => {
      return value instanceof type;
    });
  }

  return false;
}

function canFlatten(arr) {
  // only join simple types (ie keys))
  return arr.length > 0 && !canClone(arr[0]);
}

function isEmpty(value) {
  /// <summary>
  /// Determines whether the value provided is an actual value.
  /// Values with no value include null, undefined, empty string,
  /// or an empty array.
  /// </summary>
  /// <param name="value">The value to evaluate.</param>
  /// <returns type="Boolean">true if has a value; false otherwise.</returns>

  if (ko.isObservable(value)) {
    value = value.peek();
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  // coercive check for null/undefined
  return value == null;
}

function isSerializable(obj) {
  switch (typeof obj) {
    case "function":
      return false;

    case "object":
      return !obj || !(obj instanceof Element);

    default:
      return true;
  }
}

function isObject(obj) {
  return !!(obj && typeof obj === "object" && !(obj instanceof Date));
}

/**
 * Indicates whether the property name is system defined property.
 * @param {String} propertyName
 * @returns {Boolean}
 */
function isProtectedProperty(propertyName) {
  return propertyName in propertyExclusions || propertyName.indexOf(systemParamPrefix) === 0;
}

const excludeProtectedProperties = jsUtils.memoize((keys) => {
  let i = keys.length;
  while (i--) {
    if (isProtectedProperty(keys[i])) {
      keys.splice(i, 1);
    }
  }

  return keys;
});

function isNestedProperty(record, propertyName) {
  if (propertyName in record) {
    return false;
  }

  return recursiveTestRgx.test(propertyName);
}

function getTrackableProperties(item) {
  /// <summary>Gets all non-system properties for a given object.</summary>
  /// <param name="item">The object to evaluate.</param>
  /// <returns type="Array">The trackable properties for the object.</returns>

  if (item && typeof item === "object") {
    return excludeProtectedProperties(Object.keys(item));
  }

  return [];
}

function getUntrackedProperties(item, targetProperties) {
  const properties = targetProperties || getTrackableProperties(item);
  const untrackedProperties = [];
  let prop;

  for (let i = 0, ln = properties.length; i < ln; i++) {
    // do not include observables - we only care about observables that are already wrapped in getters/setters
    prop = properties[i];
    if (!ko.es5.isTracked(item, prop)) {
      untrackedProperties.push(prop);
    }
  }

  return untrackedProperties;
}

function isArrayLike(obj) {
  if (Array.isArray(obj)) {
    return true;
  }

  if (!obj || typeof obj !== "object") {
    return false;
  }

  const length = "length" in obj && obj.length;
  if (typeof length !== "number") {
    return false;
  }

  return length === 0 || (0 in obj && length - 1 in obj);
}

function cleanseArray(data, options, visited) {
  let i = 0;
  const ln = data.length;
  const results = [];

  while (i < ln) {
    // keep references if we are supporting circular references
    // otherwise every item gets it's own set of visited references
    results.push(cleanseData(data[i++], options, options.recursive ? visited : []));
  }

  return results;
}

function cleanseData(data, options) {
  /// <summary>
  /// This function takes an object or an array of objects
  /// removes any system properties and unwraps any knockout
  /// observables converting to plan objects & values.
  /// </summary>
  /// <param name="data">The object or array to cleanse.</param>
  /// <param name="options">
  /// An options object to apply against the provided object.
  /// - flatten: any arrays are converted to a comma delimited string. (default true)
  /// - ignoreEmpty: skips any properties that have null or undefined values. (default true)
  /// - keepEmpty: preserves empty values - by default empty values are replaced with null. (default false)
  /// - detectDependencies: when enabled, values will be read in a way that will cause
  ///   knockout dependencies to be registered when found. (default false)
  /// - trimWhitespace: trim whitespaces when value is string. (default true)
  /// - toString: converts all values to strings. (default false)
  /// - recursive: does a deep clone of the object. (default false)
  /// - keepObservables: this will copy existing observables to the new cloned object.
  ///   This should not be used with `detectDependencies`. (default false)
  /// - properties: specify the properties to include in the returned object.
  /// - serializableOnly: exclude items that are not serializable
  /// </param>
  /// <param name="visited">This property is internal only used to track visited objects to avoid circular references.</param>
  /// <returns type="PlainObject">The clean object.</returns>

  // todo: see about merging this method and the cloneObject method - there is a lot of overlap
  if (isPrimitive(data)) {
    return data;
  }

  if (!options) {
    return cleanseData(data, defaultOptions, []);
  }

  if (options.serializableOnly && !isSerializable(data)) {
    return undefined;
  }

  let visited = arguments[2];
  if (visited) {
    // the properties collection only applies to the top level object
    options.properties = null;
  } else {
    // since this can be called recursively, do all options evaluations once
    options = $.extend({}, defaultOptions, options);

    // if we are preserving the observables we should not need to detect them
    // todo: it would probably be good to obsolete `detectDependencies` in favor of preserving the observables
    options.detectDependencies = !options.keepObservables && options.detectDependencies;
    visited = [];
  }

  if (isArrayLike(data)) {
    // if an array is passed in cleanse each object within the array
    return cleanseArray(data, options, visited);
  }

  // see if this object has already been cloned within the current stack
  const ref = ko.utils.arrayFirst(visited, (r) => {
    return r.source === data;
  });
  if (ref) {
    // circular references cannot be serialized, so replace with undefined when using for serialization
    if (options.serializableOnly) {
      return undefined;
    }

    if (options.recursive) {
      return ref.target;
    }

    return null;
  }

  const output = {};
  const propertiesToTrack = [];
  let prop, value;

  visited.push({ source: data, target: output });
  const properties = options.properties || getTrackableProperties(data);

  for (let i = 0, ln = properties.length; i < ln; i++) {
    prop = properties[i];
    if (options.keepObservables && tryCopyObservable(output, data, prop, options)) {
      // see if previous property was a wrapped observable and maintain
      // todo: should we always wrap? might need some IA but it seems like we should
      if (ko.es5.isTracked(data, prop)) {
        propertiesToTrack.push(prop);
      }

      continue;
    }

    value = getValue(data, prop, options.detectDependencies);
    if (options.trimWhitespace && typeof value === "string") {
      value = value.trim();
    }

    if (options.serializableOnly && !isSerializable(value)) {
      continue;
    }

    if (!options.keepEmpty && isEmpty(value)) {
      if (options.ignoreEmpty) {
        continue;
      }

      value = null;
    }

    output[prop] = formatValue(value, options, visited);
  }

  if (propertiesToTrack.length > 0) {
    ko.track(output, { fields: propertiesToTrack });
  }

  return output;
}

function formatValue(value, options, visited) {
  if (value && Array.isArray(value)) {
    // only join simple types (ie keys)
    if (value.some((v) => v && typeof v === "object")) {
      return cleanseData(value, options, visited);
    }

    return options.flatten && value.$$flatten !== false ? value.join(",") : value;
  }

  if (value && value instanceof Date) {
    if (options.toString) {
      return value.toISOString();
    }

    return new Date(value.getTime());
  }

  if (value && typeof value === "object") {
    return cleanseData(value, options, visited);
  }

  if (options.toString) {
    return value == null ? "" : String(value);
  }

  return value;
}

function tryCopyObservable(clone, original, propertyName, options) {
  let value = getObservable(original, propertyName);
  if (!value) {
    return false;
  }

  const underlyingValue = value.peek();
  if (options.ignoreEmpty && isEmpty(underlyingValue)) {
    return false;
  }

  if (options.flatten && Array.isArray(underlyingValue)) {
    value = value.extend({ flatten: true });
  }

  clone[propertyName] = value;
  return true;
}

function untrackProperty(record, propertyName) {
  ko.untrack(record, [propertyName]);
  const value = getValue(record, propertyName, false);
  Object.defineProperty(record, propertyName, {
    enumerable: true,
    configurable: true,
    writable: true,
    value
  });
}

function trackProperty(record, propertyName) {
  /// <summary>Tracks the property of an object by wrapping it in a Knockout observable. The observable will be wrapped in getters/setters. (Note if the property is already being tracked it will leave it as is.)</summary>
  /// <param name="record">The object.</param>
  /// <param name="propertyName">The property name.</param>
  /// <returns type="Object">The underlying Knockout observable.</returns>

  let observable = getObservable(record, propertyName);
  if (!observable) {
    let wasDirty = true;
    let parent = record;
    const segments = propertyName.split(".");
    propertyName = segments.pop();

    if (segments.length > 0) {
      parent = getValue(record, segments.join("."));
    }

    if ("$$dirtyFlag" in record && record.$$dirtyFlag.hasEvaluated()) {
      wasDirty = record.$$dirtyFlag.isDirty.peek();
    }

    parent[propertyName] = observable = createObservable(parent[propertyName], parent);
    if (!isProtectedProperty(propertyName)) {
      // if the property isn't protected, go ahead and wrap it in getter/setters
      // this will make the property easier to deal with for devs
      ko.track(parent, { fields: [propertyName] });
    }

    if (!wasDirty) {
      record.$$dirtyFlag.reset();
    }
  } else if (ko.isObservable(record[propertyName]) && !isProtectedProperty(propertyName)) {
    // if the value isn't already wrapped in getters/setters but is observable go ahead and wrap it
    ko.track(record, { fields: [propertyName] });
  }

  return observable;
}

/**
 * Tracks all of the properties of an object with Knockout observables. The observables will be wrapped
 * in getter/setters. This will wrap any nested objects or array contents as well.
 *
 * @param {any} record - the object or array to track
 * @param {Array<ComputedProperty>} computedProperties - server defined computed properties
 * @param {Array<String>} targetProperties - a list of properties to track; if not defined all trackable properties will be tracked
 * @returns {any} Returns the tracked object back
 */
function trackObject(record, computedProperties, targetProperties) {
  if (!isObject(record)) {
    return record;
  }

  if (Array.isArray(record)) {
    record.forEach((row) => trackObject(row, computedProperties));
    return record;
  }

  const untrackedProperties = getUntrackedProperties(record, targetProperties);
  untrackedProperties.forEach((prop) => {
    const value = record[prop];
    if (ko.isObservable(value)) {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => trackObject(v));
    } else if (value && typeof value === "object") {
      trackObject(value);
    }
  });

  if (computedProperties && computedProperties.length > 0) {
    computedProperties.forEach((prop) => {
      if (!(prop.name in record)) {
        record[prop.name] = createObservable(prop, record);
        untrackedProperties.push(prop.name);
      }
    });
  }

  if (untrackedProperties.length > 0) {
    ko.track(record, { fields: untrackedProperties, lazy: true });
  }

  return record;
}

function createObservable(value, parent) {
  /// <summary>Creates an observable based on the value provided.</summary>
  /// <param name="value">The value to wrap into an observable.</param>
  /// <param name="parent">The object which contains the value.</param>
  /// <returns type="ko.observable">The observable object.</returns>

  if (ko.isObservable(value)) {
    return value;
  }

  if (Array.isArray(value)) {
    return ko.observableArray(value);
  }

  if (compiler.isComputed(value)) {
    let evaluator;
    const valueObservable = ko.observable();

    return ko.pureComputed(() => {
      // lazily compile evaluator
      evaluator = evaluator || compiler.compile(value);

      const computedValue = evaluator(parent);
      if (jsUtils.isThenable(computedValue)) {
        computedValue.then(valueObservable);
      } else if (ko.isObservable(computedValue)) {
        valueObservable(computedValue());
      } else {
        valueObservable(computedValue);
      }

      let underlyingValue = valueObservable();
      if (typeof underlyingValue === "number") {
        if (isNaN(underlyingValue) || !isFinite(underlyingValue)) {
          underlyingValue = value.expression.divideByZeroValue || ERROR_DEFAULT_VALUE;
        }
      }

      return underlyingValue;
    });
  }

  return ko.observable(value);
}

function getObservable(record, propertyName) {
  /// <summary>Gets the observable for a property. This includes observables that are wrapped in getter/setters.</summary>
  /// <param name="record">The object.</param>
  /// <param name="propertyName">The property.</param>
  /// <returns type="Object">The observable if found; null otherwise.</returns>

  let value, properties;
  if (record == null) {
    return null;
  }

  if (!(propertyName in record) && isNestedProperty(record, propertyName)) {
    value = getValue(record, propertyName);
    if (ko.isObservable(value)) {
      return value;
    }

    // need to get parent object and check for the observable
    properties = propertyName.split(".");
    propertyName = properties.pop();
    record = getValueDeep(record, properties);

    return getObservable(record, propertyName);
  }

  if (typeof record.getObservable === "function") {
    const observable = record.getObservable(propertyName);
    if (observable) {
      return observable;
    }
  }

  // check for wrapped observables first so we don't needlessly create dependency
  return (
    getProxiedObservable(record, propertyName) ||
    ko.getObservable(record, propertyName) ||
    (ko.isObservable(record[propertyName]) ? record[propertyName] : null)
  );
}

function peekValue(record, propertyName) {
  if (isProxied(record) && !recursiveTestRgx.test(propertyName)) {
    // optimize to avoid creating observable in proxy if we don't need to
    return record[propertyName];
  }

  const observable = getObservable(record, propertyName);
  return observable ? observable.peek() : record[propertyName];
}

function getValue(record, propertyName, detectDependencies) {
  /// <summary>Gets the value of a property from an object. Note that this can include deeply nested properties and indexes.</summary>
  /// <param name="record">The object.</param>
  /// <param name="propertyName">The property.</param>
  /// <param name="detectDependencies">Indicates whether Knockout dependencies should be registered. Default is false.</param>
  /// <returns type="Object">The value.</returns>

  if (!record) {
    return null;
  }

  if (propertyName in record) {
    if (detectDependencies) {
      const value = record[propertyName];

      // cheaper than an isObservable check - if the value is a function it might be observable so pass it to unwrap
      // otherwise
      if (typeof value === "function") {
        return ko.unwrap(value);
      }

      return value;
    }

    return peekValue(record, propertyName);
  }

  if (isNestedProperty(record, propertyName)) {
    return getValueDeep(record, propertyName.split("."), detectDependencies);
  }

  return undefined;
}

function getValueDeep(record, properties, detectDependencies) {
  let value = record;
  let propertyMatch, propertyName, index;

  while (value && properties.length > 0) {
    propertyMatch = propertyRgx.exec(properties.shift());
    propertyName = propertyMatch[1];
    index = propertyMatch[2];

    value = getValue(value, propertyName, detectDependencies);

    if (value && index) {
      value = value[index];
    }
  }

  return value;
}

const getObservableOrValue = function () {
  // eslint-disable-next-line no-invalid-this
  return getObservable.apply(this, arguments) || getValue.apply(this, arguments);
};

// Check for property with recursive support
function hasProperty(record, property) {
  if (property in record) {
    return true;
  }

  const properties = property.split(".");
  let value = record;

  while (properties.length > 0) {
    const currentProperty = properties.shift();
    if (!(currentProperty in value)) {
      return false;
    }

    value = ko.unwrap(value[currentProperty]);
  }

  return true;
}

function setValue(record, propertyName, value) {
  /// <summary>Sets the value of a property on an object. This property can include deeply nested properties. Note that if the property doesn't exist it will be created as the object is traversed.</summary>
  /// <param name="record">The object.</param>
  /// <param name="propertyName">The property to set.</param>
  /// <param name="value">The value to set.</param>

  if (!record || !propertyName) {
    throw new Error("Record and property name must be provided to set a value.");
  }

  const properties = propertyName.split(".");
  let currentNode = record;
  let currentProperty, propertyMatch, index, sourceObservable;

  // iterate up the object until we are at the top
  while (properties.length > 1) {
    propertyMatch = propertyRgx.exec(properties.shift());
    currentProperty = propertyMatch[1];
    index = propertyMatch[2];

    currentNode = currentNode[currentProperty] = currentNode[currentProperty] || {};

    if (index) {
      currentNode = currentNode[index] = currentNode[index] || {};
    }
  }

  propertyMatch = propertyRgx.exec(properties.pop());
  currentProperty = propertyMatch[1];
  index = propertyMatch[2];

  if (index) {
    // an index would never be observable, right?
    // todo: what about observable arrays?
    currentNode[currentProperty][index] = value;
  } else if (ko.isWriteableObservable(currentNode[currentProperty]) && !ko.isObservable(value)) {
    currentNode[currentProperty](value);
  } else {
    // if we are replacing one observable with another wa want to make sure
    // and copy all of the subscriptions forward
    if (ko.isObservable(value) && (sourceObservable = getObservable(currentNode, currentProperty))) {
      // todo: find cases where this is happening (date pickers for one) and replace extenders instead
      knockoutUtils.copySubscriptions(sourceObservable, value);

      // see if the property is already wrapped in getter/setter
      // need to replace reference in this case and re-wrap
      if (ko.es5.isTracked(currentNode, currentProperty)) {
        ko.untrack(currentNode, [currentProperty]);
        currentNode[currentProperty] = value;
        ko.track(currentNode, { fields: [currentProperty] });

        return;
      }
    }

    currentNode[currentProperty] = value;
  }
}

function isPrimitive(a) {
  return a == null || typeof a !== "object";
}

function compareObjects(a, b, ignoreMissing) {
  let i, key;

  // default to true
  ignoreMissing = ignoreMissing !== false;

  if (isPrimitive(a) || isPrimitive(b)) {
    // eslint-disable-next-line eqeqeq
    return a == b;
  }

  if (a === b) {
    return true;
  }

  if (isArrayLike(a) || isArrayLike(b)) {
    return compareArrays(a, b, ignoreMissing);
  }

  // note: we might want to ignore dates entirely
  if (a instanceof Date) {
    return a.getTime() === (b instanceof Date && b.getTime());
  }

  const keys = getTrackableProperties(a);
  i = keys.length;

  if (!ignoreMissing && getTrackableProperties(b).length !== i) {
    return false;
  }

  while (i--) {
    key = keys[i];

    if (!ignoreMissing && !(key in b)) {
      return false;
    }

    // note: we only compare about a's property
    // if it doesn't exist in b, that's fine
    // and the opposite is true as well
    // this should be enough to make a unique match
    if (key in b && !compareObjects(a[key], b[key], ignoreMissing)) {
      return false;
    }
  }

  return true;
}

function compareArrays(a, b, ignoreMissing) {
  if (!isArrayLike(a) || !isArrayLike(b)) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  let i = a.length;
  while (i--) {
    if (!compareObjects(a[i], b[i], ignoreMissing)) {
      return false;
    }
  }

  return true;
}

function compareValues(a, b) {
  /// <summary>Compares two primitive values return whether they are equal or greater/less than the other.</summary>
  /// <param name="a">The first value to compare.</param>
  /// <param name="b">The second value to compare.</param>
  /// <returns type="Number">0 if equal; greater than 0 if greater than; less than 0 if less than.</returns>

  if (a === b) {
    return 0;
  }

  if (a == null && b == null) {
    return 0;
  }

  if (a == null) {
    return -1;
  }

  if (b == null) {
    return 1;
  }

  if (typeof a === "string") {
    return a.localeCompare(b);
  }

  return a - b;
}

function flattenValue(value) {
  value = ko.unwrap(value);
  if (value) {
    if (Array.isArray(value)) {
      if (canFlatten(value)) {
        // It's likely better to return null for an empty array,
        // but that is a risky change - it's possible current user
        // code relies on this behavior. For multiple items,
        // even though it's inconsistent, it's unlikely that a user
        // is relying on flattened multiselect items so this
        // inconsistency *should* be acceptable.
        return value.length === 1 ? value[0] : value.join(",");
      }

      return value.map(flattenValue);
    }

    if (canClone(value)) {
      return flattenObject(value);
    }
  }

  return value;
}

function createFlattenedPropertyDescriptor(obj, propertyName) {
  // note: this is the safest way to create a clone without reading every value and forcing
  // a dependency when none is needed - this also prevents lazily created observables
  // from being created unnecessarily

  return {
    configurable: true,
    enumerable: true,
    get: function () {
      return flattenValue(obj[propertyName]);
    }
  };
}

function flattenObject(obj) {
  /// <summary>Creates a shallow flattened clone of the object.</summary>
  /// <param name="obj">The object to clone.</param>
  /// <param name="options">Available options: `readOnly` - will create a read only cloned object.</param>
  /// <returns type="Object">The cloned object.</returns>

  const trackableProperties = getTrackableProperties(obj);

  if (typeof Proxy === "function") {
    // using a Proxy when available - this ends up being *much* faster than cloning the object and achieves
    // the same objectives

    const boundGetObservable = getObservable.bind(null, obj);

    /* eslint-env es6 */
    return new Proxy(obj, {
      has: (target, key) => trackableProperties.indexOf(key) >= 0 || key === "getObservable",
      ownKeys: () => trackableProperties,
      get: (target, key) => {
        if (key === "getObservable") {
          return boundGetObservable;
        }

        return trackableProperties.indexOf(key) >= 0 ? flattenValue(target[key]) : target[key];
      }
    });
  }

  const properties = {};
  let propertyName;

  for (let i = 0, ln = trackableProperties.length; i < ln; i++) {
    propertyName = trackableProperties[i];
    properties[propertyName] = createFlattenedPropertyDescriptor(obj, propertyName);
  }

  return Object.create({}, properties);
}

/**
 * Updates an object with values from another object without disturbing subscriptions
 * @param {Object} target - the object to copy to
 * @param {Object} source - the object to copy from
 */
function updateObject(target, source) {
  // NOTE: there is a chance of a circular reference here. In fact this
  // function makes a lot of assumptions...

  for (const prop in target) {
    if (Object.prototype.hasOwnProperty.call(target, prop)) {
      const sourceValue = source[prop];

      // try to keep array and update objects within the array
      if (Array.isArray(sourceValue)) {
        const targetArray = getObservableOrValue(target, prop);
        if (Array.isArray(targetArray) || ko.isObservableArray(targetArray)) {
          const underlyingArray = ko.isObservable(targetArray) ? targetArray.peek() : targetArray;

          for (let i = 0; i < Math.min(underlyingArray.length, sourceValue.length); i++) {
            const left = underlyingArray[i];
            const right = sourceValue[i];

            if (isObject(left) && isObject(right)) {
              updateObject(left, right);
            } else {
              // hopefully one or the other isn't an object - i'm
              // not sure the best way to handle that scenario
              targetArray.splice(i, 1, right);
            }
          }

          if (sourceValue.length > underlyingArray.length) {
            // We are making assumptions here by tracking the object - should probably detect if
            // other objects are tracked. I expect that this will be the general use case though.
            targetArray.push(...sourceValue.slice(underlyingArray.length).map((v) => trackObject(v)));
          } else if (sourceValue.length < underlyingArray.length) {
            targetArray.splice(sourceValue.length, underlyingArray.length - sourceValue.length);
          }

          continue;
        }
      }

      if (
        sourceValue &&
        typeof sourceValue === "object" &&
        !(sourceValue instanceof Date) &&
        !Array.isArray(sourceValue)
      ) {
        // recursively update target properties
        updateObject(target[prop], sourceValue);
      } else {
        setValue(target, prop, sourceValue);
      }
    }
  }
}

function cloneObject(obj, options) {
  /// <summary>
  /// Creates a deep clone of the provided object. The cloning will preserve simple observables,
  /// creating new instances with the same values but computed observables and functions will not
  /// be carried forward. The cloning will preserve circular structures.
  /// </summary>
  /// <param name="obj">The object to clone.</param>
  /// <param name="options">
  /// Options object literal - available options include:
  /// - exclude: An array of property names to exclude.
  /// - excludeSystemProperties: boolean
  /// </param>
  /// <returns type="Object">The cloned object.</returns>

  options = $.extend({}, defaultCloneOptions, options);

  // if the object is null/undefined, not an object, or a dom element just return the existing value
  if (obj == null || typeof obj !== "object" || obj.nodeType > 0) {
    return obj;
  }

  if (arguments.length < 3) {
    // the third argument is for internal usage only
    return cloneObject(obj, options || {}, []);
  }

  const result = {};
  const stack = arguments[2];
  const excludedProperties = options.exclude || [];
  const excludeSysProp = options.excludeSystemProperties;
  const propertiesToTrack = [];
  let prop, value, ref;

  if (stack.length > 0) {
    // see if this object has already been cloned within the current stack
    ref = ko.utils.arrayFirst(stack, (r) => {
      return r.source === obj;
    });
    if (ref) {
      return ref.target;
    }
  }

  // to maintain circular structures keep a stack of all cloned objects
  // and return the existing clone when another reference is found
  stack.push({ source: obj, target: result });

  for (prop in obj) {
    if (
      has(obj, prop) &&
      (excludedProperties.length === 0 || excludedProperties.indexOf(prop) === -1) &&
      (!excludeSysProp || (excludeSysProp && !isProtectedProperty(prop)))
    ) {
      value = ko.getObservable(obj, prop) || obj[prop];
      if (value === undefined) {
        // completely ignore undefined
        continue;
      }

      if (value && ko.isObservable(value)) {
        if (!ko.isComputed(value)) {
          if (Array.isArray(value.peek())) {
            result[prop] = ko.observableArray(
              value.peek().map((o) => {
                return cloneObject(o, options, stack);
              })
            );
          } else {
            result[prop] = ko.observable(value.peek());
          }

          // check if wrapped observable - do not call isObservable because if it's wrapped that will
          // potentially generate a dependency
          if (ko.es5.isTracked(obj, prop)) {
            propertiesToTrack.push(prop);
          }
        }
      } else if (value && Array.isArray(value)) {
        result[prop] = value.map((o) => {
          return cloneObject(o, options, stack);
        });
      } else if (value !== null && typeof value === "object") {
        result[prop] = cloneObject(value, options, stack);
      } else if (typeof value !== "function") {
        result[prop] = value;
      }
    }
  }

  if (propertiesToTrack.length > 0) {
    ko.track(result, { fields: propertiesToTrack, lazy: true });
  }

  return result;
}

function wrapObservables(obj) {
  /// <summary>Wraps all of the exposed observables in the object in getter/setters, excluding those that are considered system properties.</summary>
  /// <param name="obj">The object to evaluate.</param>

  if (!obj || typeof obj !== "object") {
    return;
  }

  const propertiesToTrack = [];
  getTrackableProperties(obj).forEach((prop) => {
    // check whether it's wrapped first to avoid unnecessary dependency
    if (!ko.es5.isTracked(obj, prop) && ko.isObservable(obj[prop])) {
      propertiesToTrack.push(prop);
    }
  });

  if (propertiesToTrack.length > 0) {
    ko.track(obj, { fields: propertiesToTrack });
  }
}

// todo: there's probably a lot of room for consolidation here
// - some of these probably do not need to be exposed
// - some of these belong somewhere else (comparisons)
// - some of these can possibly be merged

api.updateObject = updateObject;
api.cleanse = cleanseData;
api.clone = cloneObject;
api.flatten = flattenObject;
api.compareObjects = compareObjects;
api.compareValues = compareValues;
api.isEmpty = isEmpty;
api.isProtectedProperty = isProtectedProperty;
api.getTrackableProperties = getTrackableProperties;
api.getObservable = getObservable;
api.getObservableOrValue = getObservableOrValue;
api.getValue = getValue;
api.setValue = setValue;
api.trackObject = trackObject;
api.trackProperty = trackProperty;
api.untrackProperty = untrackProperty;
api.hasProperty = hasProperty;
api.wrapObservables = wrapObservables;

module.exports = api;
