const ko = require("knockout");
const $ = require("jquery");
require("../Plugins/plex-fixedelement"); // eslint-disable-line import/no-unassigned-import

ko.bindingHandlers.fixed = {
  update: function (element, valueAccessor) {
    const enabled = ko.unwrap(valueAccessor());
    const $element = $(element);
    let plugin = $element.data("fixedElement");

    if (enabled && plugin) {
      // already setup
      return;
    }

    if (enabled) {
      $element.fixedElement({ horizontal: true });

      ko.utils.domNodeDisposal.addDisposeCallback($element.parent()[0], (el) => {
        plugin = $element.data("fixedElement");

        // because the fixed element wraps the target element in another div we have to manually remove the elements
        $(el).empty();

        if (plugin) {
          plugin.dispose();
        }
      });
    } else if (plugin) {
      plugin.dispose();
    }
  }
};
