ï»¿const compiler = require("./plex-expressions-compiler");
const plexExport = require("../../global-export");

function CompositeExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

CompositeExpression.prototype = {
  constructor: CompositeExpression,

  init: function (config) {
    const evaluators = [];
    config.nodes.forEach((node) => {
      evaluators.push(compiler.compile(node));
    });

    this.evaluator = function () {
      const values = [];
      const args = arguments;

      evaluators.forEach((evaluator) => {
        const value = evaluator.apply(null, args);
        values.push(value == null ? "" : value);
      });

      return values.join("");
    };
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
};

module.exports = CompositeExpression;
plexExport("expressions.Composite", CompositeExpression);
