ï»¿const pubsub = require("../Core/plex-pubsub");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const PublishAction = Action.extend({
  onExecute: function (data) {
    pubsub.publish(this.address, data, this.additionalData);

    // publish is fire-and-forget so simply proceed to next action
    return true;
  }
});

module.exports = PublishAction;
plexExport("actions.Publish", PublishAction);
