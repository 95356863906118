ï»¿const $ = require("jquery");
const logger = require("../Core/plex-logger");
const Action = require("./plex-actions");
const plexExport = require("../../global-export");

const ClientAction = Action.extend({
  findMethodAddress: function () {
    while (this.parent) {
      if (this.address in this.parent) {
        return true;
      } else if (this.parent.master && this.address in this.parent.master) {
        this.parent = this.parent.master;
        return true;
      }
      if (this.parent.parent) {
        this.parent = this.parent.parent;
      } else if (this.parent.config && this.parent.config.parent && this.parent !== this.parent.config.parent) {
        this.parent = this.parent.config.parent;
      } else {
        this.parent = null;
      }
    }
    return false;
  },

  onExecute: function (data, sourceData, event) {
    if (!this.parent || !(this.address in this.parent)) {
      if (this.parent && this.parent.header && this.address in this.parent.header) {
        logger.warn(
          "Method " +
            this.address +
            " is attached to the header. This is obsolete. Please attach the method to the grid."
        );

        // for now provide this as a fallback
        this.parent = this.parent.header;
      } else if (this.findMethodAddress() === false) {
        throw new Error("Method " + this.address + " not found.");
      }
    }

    // if there is a post action it is up to the dev to resolve it
    const $deferred = new $.Deferred();
    const result = this.parent[this.address](data, this.additionalData, $deferred.resolve, $deferred.reject, event);
    if (result !== undefined && $deferred.state() === "pending") {
      $deferred.resolve(result);
      return result;
    }

    return $deferred.promise();
  }
});

module.exports = ClientAction;
plexExport("actions.Controller", ClientAction);
