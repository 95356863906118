ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const Blocker = require("../Plugins/plex-blocker");
const plexExport = require("../../global-export");

const ProgressBarController = function (config) {
  this.config = config;
  this.init();
};

ProgressBarController.prototype = {
  init: function () {
    this.limit = ko.observable(null);
    this.currentProgressAmount = ko.observable(0);
    this.customProgressMessage = ko.observable(null);

    this.messageTokenList = ko.pureComputed(() => {
      const tokens = [];

      tokens.push(this.currentProgressAmount);
      tokens.push(this.limit);

      for (const customToken in this.messageTokens) {
        if (Object.prototype.hasOwnProperty.call(this.messageTokens, customToken)) {
          tokens.push(this.messageTokens[customToken]);
        }
      }

      return tokens;
    });

    this.messageTokens = {};

    this.isLimitSet = ko.pureComputed(() => this.limit() != null);

    this.isCustomProgressMessage = ko.pureComputed(() => this.customProgressMessage() != null);

    this.progressPercentage = ko.pureComputed(() => {
      const percentage = this.isLimitSet() ? (this.currentProgressAmount() / this.limit()) * 100 : 0;
      return Math.min(100, percentage);
    });

    this.computedProgressMessage = ko.pureComputed(() => {
      return this.customProgressMessage();
    });

    if (this.config.progressMessageTokenNames && this.config.progressMessageTokenNames.length > 0) {
      for (let i = 0; i < this.config.progressMessageTokenNames.length; i++) {
        this.messageTokens[this.config.progressMessageTokenNames[i]] = ko.observable(null);
      }
    }
  },

  setLimit: function (newLimit) {
    if (newLimit > 0) {
      this.limit(newLimit);
    } else {
      throw new Error("Limit being set is 0 or less.");
    }
  },

  clearLimit: function () {
    this.limit(null);
  },

  setCustomProgressMessage: function (newMessage) {
    this.customProgressMessage(newMessage);
  },

  show: function () {
    if (this.blocker) {
      return;
    }

    const blockerOptions = {};

    blockerOptions.messageType = Blocker.BlockerMessageType.KnockoutTemplate;
    blockerOptions.message = "progress-bar";
    blockerOptions.messageModel = this;
    blockerOptions.messageClass = "plex-progress-bar-container";

    this.blocker = new Blocker(document.body, blockerOptions);
    this.blocker.show();
  },

  hide: function () {
    if (!this.blocker) {
      return;
    }

    this.blocker.hide();
    this.blocker = null;
  },

  incrementProgress: function (incrementValue = 1, isIncremental = false) {
    if (isIncremental) {
      this.currentProgressAmount(incrementValue);
    } else {
      this.currentProgressAmount(this.currentProgressAmount() + incrementValue);
    }
  },

  resetProgress: function () {
    this.currentProgressAmount(0);
  }
};

ProgressBarController.create = function (config) {
  return new ProgressBarController(config);
};

module.exports = ProgressBarController;
plexExport("ProgressBarController", ProgressBarController);
