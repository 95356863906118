/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dialogController = require("../Dialogs/plex-dialog-controller");
const Ask = require("../Dialogs/plex-dialogs-ask");
const validation = require("../Core/plex-validation");
const plexExport = require("../../global-export");

function cleanEMailAddress(svAddress, svDelimiter) {
  if (svAddress.length > 0) {
    // replace semicolon separating addresses with comma
    // and then remove any space between new separator comma and address
    return svDelimiter + svAddress.replace(/;/g, ",").replace(/,\s/g, ",") + svDelimiter;
  }

  return "";
}

function getAddress(svFriendlyAddress) {
  const ivNameEndPos = svFriendlyAddress.lastIndexOf('"');
  let ivAddressBeginPos = svFriendlyAddress.indexOf("<");
  let ivAddressEndPos = svFriendlyAddress.lastIndexOf(">");

  if (ivAddressBeginPos === -1) {
    ivAddressBeginPos = ivNameEndPos + 1;
  }

  if (ivAddressEndPos === -1) {
    ivAddressEndPos = svFriendlyAddress.length;
  }

  let svAddress = svFriendlyAddress.substring(ivAddressBeginPos + 1, ivAddressEndPos);
  svAddress = svAddress.replace(/[ ]/g, ""); // just in case there is a space between friendly name and address

  return svAddress;
}

// so....custom validation rules??
// validation.createCustomRule
$.validator.addMethod("ValidAddressLineRule", (value, _element) => {
  const svAddressLine = cleanEMailAddress(value, "");

  let bvValid = true;

  // friendly name if enclosed in double quotes should not have comma to separate first and last name
  let bvInQuote = false;

  for (let i = 0; i < svAddressLine.length; i++) {
    if (svAddressLine.charCodeAt(i) === 34) {
      // double quote mark
      bvInQuote = !bvInQuote;
    }
    if (svAddressLine.charCodeAt(i) === 44) {
      // comma
      if (bvInQuote) {
        return false;
      }
    }
  }

  const avAddress = svAddressLine.split(",");

  for (let i = 0; i < avAddress.length; i++) {
    if (avAddress[i].length > 0) {
      const svAddress = getAddress(avAddress[i]);
      /* eslint no-useless-escape: "off" */
      bvValid = /^[\W]*([\w+\-\'.%&]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*[,;]{1}[\W]*)*([\w+\-\'.%&]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
        svAddress
      );

      if (!bvValid) {
        return false;
      }
    }
  }

  return bvValid;
});

// maybe make this a custom rule
$.validator.addMethod("RestrictedAddressRule", (value, element) => {
  const svAllAddresses = cleanEMailAddress(value, "");
  const svUnrestrictedDomains = ko.dataFor(element).parent.config.data.UnrestrictedDomains;
  let svRestrictedAddresses = "";

  const viewModel = ko.contextFor(element).$root;

  // Process any rule conditions
  if (viewModel.config.data.RestrictDomain === false) {
    return true;
  }

  if (svUnrestrictedDomains.length) {
    const avAllAddress = svAllAddresses.split(",");

    for (let ivIndex = 0; ivIndex < avAllAddress.length; ivIndex++) {
      const svAddress = getAddress(avAllAddress[ivIndex]);
      const svDomain = svAddress.substring(svAddress.indexOf("@") + 1).toLowerCase();

      if (svUnrestrictedDomains.indexOf("," + svDomain + ",") === -1) {
        svRestrictedAddresses += svAddress + ",";
      }
    }
  }

  if (svRestrictedAddresses.length > 0) {
    // remove last comma
    svRestrictedAddresses = svRestrictedAddresses.substring(0, svRestrictedAddresses.length - 1);
  }

  return svRestrictedAddresses.length === 0;
});

// $.validator.addMethod("CheckForAttachment",
// returns defered

const EmailController = function (config, _data) {
  this.config = config;

  this.init();

  // private members, methods here.
  let data;

  Object.defineProperty(this, "data", {
    get: function () {
      if (data !== null && data !== undefined) {
        data = ko.dataFor(document.getElementById("EmailComposeForm"));
      }
      return data;
    }
  });
};

EmailController.prototype = {
  init: function () {
    const self = this;

    // get the form and map some methods.

    // we need to do some client side validation
    validation.createCustomRule(
      "mustBeValidEmailList",
      "Must be a comma separated list of valid e-mail addresses",
      (value, _element, _rule) => {
        if (value === "") {
          return true;
        }
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // rather than write a large regex for a comma separated list of e-mails, let's try splitting
        const emails = value.split(",");

        for (let i = 0; i < emails.length; i++) {
          if (re.test(emails[i]) === false) {
            return false;
          }
        }

        return true;
      }
    );

    validation.createCustomRule(
      "SendWithEmptySubject",
      "Must be a comma separated list of valid e-mail addresses",
      (value, _element, _rule) => {
        if (value === "") {
          // empty
        }

        return true;
      }
    );

    validation.applyValidationRule("Subject", "");

    // spawn the dialog.
    // prototype: root["Dialog"],
    dialogController.create({
      route: "/Communication/Send",
      httpMethod: "POST",
      routData: self.data,
      returnAction: function (dialogData) {
        self.dialogData = dialogData;
      }
    });
  },
  ValidateAndSend: function () {
    this.checkForAttachment(this.data, document.getElementById("Subject"));
  },
  checkForAttachment: function (value, element) {
    // todo, lets worry about this later. Bring it up with Rob later in the week.
    const dataModel = ko.dataFor(element).parent.config.data;
    const $element = $(element);
    const rule = $element.data("rules").CheckForAttachment;

    if (dataModel.UseAttachmentSubjectWarning) {
      const sgAttach = rule.attach.toLowerCase();
      const sgAttached = rule.attached.toLowerCase();
      const sgAttaching = rule.attaching.toLowerCase();
      const sgAttachment = rule.attachment.toLowerCase();
      const sgAttachments = rule.attachments.toLowerCase();

      if (
        value.indexOf(sgAttach) > -1 ||
        value.indexOf(sgAttached) > -1 ||
        value.indexOf(sgAttaching) > -1 ||
        value.indexOf(sgAttachment) > -1 ||
        value.indexOf(sgAttachments) > -1
      ) {
        // todo, in the old implementaiton, we first made an ajax query to check if there were
        // in fact session attachments already on this session. Since we don't have session attachments (yet)
        // we'll just check the filename parameter.

        if (dataModel.Filename !== null && dataModel.Filename !== "") {
          // ivCount == 0)
          /* eslint new-cap: "off" */
          return Ask("Send this message without attachments?", ["Yes", "No"], "No", {
            // glossarization will occur on the server
            autoGlossarize: false,
            autoGlossarizeOptions: false
          }).then((answer) => {
            const retVal = answer.answerText === "Yes";
            return $.Deferred.Resolve(retVal);
          });
        }
      }
    }
    return undefined;
  },
  GetRestrictedAddresses: function () {
    let svAllAddresses = cleanEMailAddress(this.data.To, "");

    let svAddress = this.data.Cc;
    if (svAddress.length > 0) {
      svAllAddresses += "," + cleanEMailAddress(svAddress, "");
    }

    svAddress = this.data.Bcc;
    if (svAddress.length > 0) {
      svAllAddresses += "," + cleanEMailAddress(svAddress, "");
    }

    const svUnrestrictedDomains = "UnrestrictedDomains"; // todo replace with variable
    let svRestrictedAddresses = "";

    if (svUnrestrictedDomains.length) {
      const avAllAddress = svAllAddresses.split(",");

      for (let ivIndex = 0; ivIndex < avAllAddress.length; ivIndex++) {
        svAddress = getAddress(avAllAddress[ivIndex]);
        const svDomain = svAddress.substring(svAddress.indexOf("@") + 1).toLowerCase();

        if (svUnrestrictedDomains.indexOf("," + svDomain + ",") === -1) {
          svRestrictedAddresses += svAddress + ",";
        }
      }
    }

    if (svRestrictedAddresses.length > 0) {
      // remove last comma
      svRestrictedAddresses = svRestrictedAddresses.substring(0, svRestrictedAddresses.length - 1);
    }

    return svRestrictedAddresses;
  },

  // legacy wrapper for convienince
  Send: function () {
    // todo, get the form and call it's post event.
    // Plex.DoPostBack("Send");
  },

  // todo. once F5 Addressbook is implemented, add a reference here.
  AddressBook: function () {
    // noop
  },

  // possibly remove this function. F5 standards say to never ask a yes/no on navigation.
  CancelMsg: function () {
    // noop
  }
};

// static factory
EmailController.create = function (config, data) {
  const emailController = new EmailController(config, data);

  return emailController;
};

module.exports = EmailController;
plexExport("EmailController", EmailController);
