ï»¿const ko = require("knockout");
const $ = require("jquery");
const notifyDialog = require("../Dialogs/plex-dialogs-notify");

ko.bindingHandlers.imageResize = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const $image = $(element);
    const value = valueAccessor();
    let percentChange = null;
    value.percentResize = 100; // initialize to 100 percent always

    // create model for the resize toolbar
    const model = {
      currentPercentResize: 100,
      origWidth: null,
      origHeight: null,
      percentResize: ko.observable(100),
      width: ko.observable(50),
      height: ko.observable(50),
      onZoomIn: function () {
        const zoomIn = Number(model.percentResize()) + value.percentIncrement;
        resizeImage($image, notifyNegative(zoomIn), valueAccessor, bindingContext, "zoomIn");
      },
      onZoomOut: function () {
        const zoomOut = Number(model.percentResize()) - value.percentIncrement;
        resizeImage($image, notifyNegative(zoomOut), valueAccessor, bindingContext, "zoomOut");
      }
    };

    // render the resize bar and bind it to the toolbar model
    $image.data("resizeToolbarModel", model);
    const $toolbar = $("<div>").prependTo($image.parent());
    ko.renderTemplate("image-resize-toolbar", model, {}, $toolbar[0]);

    // subscribe to the percent input change on the toolbar
    model.percentResize.subscribe((percentResize) => {
      percentChange = percentResize;
      updatePercent($image, percentResize, valueAccessor, bindingContext, model);
    });

    // subscribe to the width input change on the toolbar
    model.width.subscribe((width) => {
      percentChange = width === $image.width() ? percentChange : null;
      updateWidth($image, percentChange, valueAccessor, bindingContext, model, width);
    });

    // subscribe to the height input change on the toolbar
    model.height.subscribe((height) => {
      percentChange = height === $image.height() ? percentChange : null;
      updateHeight($image, percentChange, valueAccessor, bindingContext, model, height);
    });

    // not a static resize toolbar
    if (value.staticResizeToolbar) {
      const image = new Image();
      image.src = $image.attr("src");
      image.onload = function () {
        model.width((model.origWidth = this.width));
        model.height((model.origHeight = this.height));
      };
    } else {
      const hoverFn = function () {
        if (!$toolbar.is(":visible")) {
          model.width($image.width());
          model.height($image.height());

          $toolbar.css({
            position: "absolute",
            left: $image.position().left,
            top: $image.position().top
          });

          $toolbar.show();
        }
      };

      $image.add($toolbar).hover(() => {
        hoverFn();
      });

      const escapeTab = function (key) {
        return key !== "Tab";
      };

      $("#percentResize").on("keyup", (e) => {
        escapeTab(e.key) && model.percentResize(e.target.value);
      });

      $("#widthResize").on("keyup", (e) => {
        escapeTab(e.key) && model.width(e.target.value);
      });

      $("#heightResize").on("keyup", (e) => {
        escapeTab(e.key) && model.height(e.target.value);
      });

      $toolbar.hide();
    }
  },

  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    const $image = $(element);
    const value = valueAccessor();
    if (value.percentResize) {
      resizeImage($image, value.percentResize, valueAccessor, bindingContext);
    }
  }
};

function notifyNegative(zoom) {
  if (zoom <= 0) {
    notifyDialog("Percent must be a positive value.");
    return 100;
  }
  return zoom;
}

function updatePercent($image, percentResize, valueAccessor, bindingContext, model) {
  if ($.isNumeric(percentResize) || percentResize === "") {
    resizeImage($image, percentResize, valueAccessor, bindingContext, "percent");
  } else {
    model.percentResize(model.currentPercentResize);
  }
}

function updateWidth($image, percentChange, valueAccessor, bindingContext, model, width) {
  if ($.isNumeric(width) || width === "") {
    const origWidth = model.origWidth || $image.width();
    resizeImage(
      $image,
      percentChange === "" ? "" : Math.round((width / origWidth) * 100),
      valueAccessor,
      bindingContext,
      "width"
    );
  } else {
    model.width($image.width());
  }
}

function updateHeight($image, percentChange, valueAccessor, bindingContext, model, height) {
  if ($.isNumeric(height) || height === "") {
    const origHeight = model.origHeight || $image.height();
    resizeImage(
      $image,
      percentChange === "" ? "" : Math.round((height / origHeight) * 100),
      valueAccessor,
      bindingContext,
      "height"
    );
  } else {
    model.height($image.height());
  }
}

function checkSizes($image, model) {
  if ($image.width() === 0 || $image.height() === 0) {
    return true;
  }
  return $image.width() === model.width() && $image.height() === model.height();
}

function resizeImage($image, percentResize, valueAccessor, bindingContext, resize) {
  /// <summary>Resizes the image by percent resize and updates the data model and the resize toolbar model accordingly.</summary>
  /// <param name="$image">The image to resize.</param>
  /// <param name="percentResize">The percent resize or zoom percent.</param>
  /// <param name="valueAccessor">The value accessor to access data model.</param>
  let ogDialogWidth = null;
  const value = valueAccessor();
  const model = $image.data("resizeToolbarModel");

  if (checkSizes($image, model)) {
    if (percentResize === value.percentResize || percentResize === model.currentPercentResize) {
      updateDataModel(percentResize, valueAccessor, bindingContext);
      return;
    }
  }

  // get the original size of the image and store it
  if (model.currentPercentResize === 100) {
    model.origWidth = $image.width();
    model.origHeight = $image.height();
    ogDialogWidth = $(".modal-dialog:first").width();
  }

  const updateToolbarModel = function (resizePercent, width, height) {
    model.percentResize(resizePercent);
    model.width(width);
    model.height(height);
    $(".modal-dialog:first").width(ogDialogWidth);
  };

  // do not allow zoom in of less than 5 percent
  if (percentResize < 5 && (resize === "zoomIn" || resize === "zoomOut")) {
    // update the data model and toolbar model
    model.currentPercentResize = percentResize;
    updateToolbarModel(model.currentPercentResize, $image.width(), $image.height());
    updateDataModel(model.currentPercentResize, valueAccessor, bindingContext);
  } else {
    // calculate the new image size
    let newWidth = Math.round((percentResize * model.origWidth) / 100);
    let newHeight = Math.round((percentResize * model.origHeight) / 100);
    let newPercentResize = percentResize;

    if (resize === "width") {
      newWidth = model.width();
      newPercentResize = percentResize;
    } else if (resize === "height") {
      newHeight = model.height();
      newPercentResize = percentResize;
    }

    // update the size
    $image.width(newWidth);
    $image.height(newHeight);

    // update the data model and toolbar model with new size and resize precent
    model.currentPercentResize = newPercentResize;
    updateToolbarModel(newPercentResize, newWidth, newHeight);
    updateDataModel(newPercentResize, valueAccessor, bindingContext);
  }
}

function updateDataModel(percentResize, valueAccessor, bindingContext) {
  const value = valueAccessor();
  value.percentResize = percentResize;

  if (value.propertyName && value.propertyName in bindingContext.$data.data) {
    bindingContext.$data.data[value.propertyName] = value.percentResize;
  }
}
