ï»¿const parsing = require("./plex-parsing-url");
const plexExport = require("../../global-export");

const crossPcnTokenName = "xPCN";
const crossPcnRgx = /xPCN/i;
let crossPcnEvaluated = false;
let currentCrossPcn = null;
let mainCrossPcn = null;

function getCrossPcn() {
  /// <summary>Gets the current cross pcn no.</summary>
  /// <returns type="String">The current cross pcn no.</returns>
  if (crossPcnEvaluated) {
    return currentCrossPcn;
  }

  const qs = parsing.parseQueryString();
  for (const key in qs) {
    if (!currentCrossPcn && Object.prototype.hasOwnProperty.call(qs, key) && crossPcnRgx.test(key)) {
      currentCrossPcn = qs[key];
    }
  }

  crossPcnEvaluated = true;
  return currentCrossPcn;
}

function setCrossPcn(crossPcn) {
  if (currentCrossPcn === crossPcn) {
    return false;
  }

  mainCrossPcn = currentCrossPcn;
  currentCrossPcn = crossPcn;
  crossPcnEvaluated = true;
  return true;
}

function restoreCrossPcn() {
  currentCrossPcn = mainCrossPcn;
  mainCrossPcn = null;
}

const api = {
  crossPcnTokenName,
  getCrossPcn,
  setCrossPcn,
  restoreCrossPcn
};

module.exports = api;
plexExport("enterpriseManager", api);
