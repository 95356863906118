const UrlParser = require("@plex/ux/Core/plex-parsing-url");
const plexExport = require("../../global-export");
const plexImport = require("../../global-import");
const urlQueryParmsConstants = require("../Constants/url-related-constants");

function prefixUrl(url, prefix) {
  if (!prefix || !UrlParser.isAbsolutePath(url)) {
    return url;
  }
  // replace trailing and leading slashes respectively
  const actualUrl = UrlParser.combineUrl(prefix.replace(/\/$/, ""), url.replace(/^\//, ""));
  return actualUrl.toString();
}

function getRelativePath(url) {
  const result = UrlParser.parseUrl(url);
  return result?.path;
}

// Being reserved parameter is not required in query params
function filterQueryParamStripNonRequiredParam(queryUrlParams) {
  if (typeof queryUrlParams === "object" && Object.keys(queryUrlParams).length > 0) {
    return (
      "?" +
      Object.keys(queryUrlParams)
        .filter((key) => !urlQueryParmsConstants.PERSISTEDQUERYPARAMS.includes(key))
        .map((key) => key + "=" + queryUrlParams[key])
        .toString()
    );
  }

  return undefined;
}

function getRelativePathWithQueryParams(url) {
  const spa = plexImport("spa");
  const result = UrlParser.parseUrl(url);
  let path = result?.path;

  if (spa?.isSpaRendering?.() && path) {
    // Considering URL has spa identifiers replace it
    path = path.replace(/\/[-_]{0,2}/, "");
  }

  const queryResult = filterQueryParamStripNonRequiredParam(result.query);
  if (queryResult) {
    return path + queryResult;
  }

  return path;
}

const api = {
  prefixUrl,
  getRelativePath,
  getRelativePathWithQueryParams
};

module.exports = api;
plexExport("utils.urlBuilder", api);
