ï»¿const ko = require("knockout");
const jsUtils = require("../Utilities/plex-utils-js");

ko.deferredComputed = function (evaluator, targetObject, options) {
  if (typeof evaluator === "function") {
    options = options || {};
    options.read = evaluator;
  } else {
    options = ko.utils.extend(evaluator, options);
  }

  if (targetObject) {
    options.owner = targetObject;
  }

  options.deferEvaluation = true;
  const reader = options.read;
  const writer = options.write;

  const initialValue = ko.observable();
  let localEvaluated = false;
  const evaluated = ko.observable(false);

  options.read = function () {
    const value = reader.apply(this, arguments);
    if (!localEvaluated) {
      localEvaluated = true;
      initialValue(value);

      // need to give knockout a chance to cache this value first
      // or else subscribers to evaluated will get the previously cached value
      jsUtils.defer(() => {
        evaluated(true);
      });
    }

    return value;
  };

  if (writer) {
    options.write = function (value) {
      writer.apply(this, arguments);

      if (!localEvaluated) {
        initialValue(value);
        evaluated((localEvaluated = true));
      }
    };
  }

  const computed = ko.computed(options);
  computed.evaluated = ko.computed({ read: evaluated });
  computed.initialValue = ko.computed({ read: initialValue });
  return computed;
};
