// base class for future

const ab = require("ab");
const native = require("./plex-native");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const Plugin = function () {
  // constructor
};

Plugin.prototype = {
  call: function () {
    const _arguments = arguments;
    // get the instance and once the plugin is loaded, call the method.
    return native.getInstance().then((pluginSession) => {
      return ab.Session.prototype.call.apply(pluginSession, _arguments);
    });
  }
};

jsUtils.makeExtendable(Plugin);

module.exports = Plugin;
plexExport("native.PluginBase", Plugin);
