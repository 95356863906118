ï»¿const stringUtils = require("../Utilities/plex-utils-strings");
const numberCulture = require("../Globalization/plex-culture-number");
const dateCulture = require("../Globalization/plex-culture-datetime");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

const excelUtils = {};

const standardNumberFormatsRgx = /^[cdnp]\d*$/;
// eslint-disable-next-line no-control-regex
const utfEscapeRgx = /[\u0000-\u0008\u000b-\u001f]/g;
const utfSurrogatePairRgx = /[\ud800-\udbff][\udc00-\udfff]/g;
const numberFormatCache = {};

function base64ToBlob(data, contentType) {
  // http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript

  const sliceSize = 1024;
  const byteCharacters = atob(data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }

    /* eslint no-undef: "off" */
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: contentType || "" });
}

function getColumnValue(column, record, i) {
  if (typeof column.valueProvider.getExportValue === "function") {
    return column.valueProvider.getExportValue(record, i);
  }

  return column.valueProvider.getValue(record, i);
}

function formatStringValue(formatter, value, record, i, column) {
  let returnValue = value;

  // Keep value if developer supplies getExportValue
  if (typeof column.valueProvider.getExportValue !== "function") {
    if (column.elements || typeof returnValue === "object") {
      returnValue = column.valueProvider.getFormattedValue(record, i);

      // 1. remove HTML that might be coming from content feature result and replace break tags with new line
      // 2. value might be html encoded so decode the value for exporting
      returnValue = stringUtils.unescapeHtml(stringUtils.removeHtml(returnValue, { replaceLineBreaks: true }));
    } else {
      if (formatter) {
        // this applies for our own custom formatting, ex: names
        returnValue = column.valueProvider.getFormattedValue(record, i);
      }

      returnValue = stringUtils.replaceHtmlLineBreaks(returnValue);
    }
  }

  if (returnValue && typeof returnValue === "string") {
    // escape unicode characters
    // https://github.com/SheetJS/js-xlsx/issues/72
    returnValue = returnValue
      .replace(utfEscapeRgx, (match) => escapeChar(match, 0))
      .replace(utfSurrogatePairRgx, (match) => escapeChar(match, 0) + escapeChar(match, 1));
  }

  return returnValue;
}

function getCellValue(cellRecord, i, column, culture) {
  if (culture) {
    logger.warn("Argument `culture` is deprecated and will be ignored.");
  }

  const record = column.valueProvider.getRecord(cellRecord);

  let value = getColumnValue(column, record, i);
  let formatCode = "";
  const type = typeof value;
  let cellType = "s";
  const formatter = column.valueProvider.getFormatter();

  if (formatter && value && value instanceof Date) {
    value = toExcelDate(value);
    formatCode = dateCulture.getCldrDateTimeFormat(formatter.format).replace(/tt|a+/gi, "AM/PM");
    cellType = "n";
  } else if (formatter && type === "number") {
    formatCode = toNumberFormat(formatter, value, record);
    cellType = "n";
  } else if (value && type === "boolean") {
    cellType = "b";
  } else {
    value = formatStringValue(formatter, value, record, i, column);
  }

  return {
    value,
    formatCode,
    type: cellType
  };
}

function escapeChar(text, index) {
  return "_x" + ("000" + text.charCodeAt(index).toString(16)).substr(-4) + "_";
}

// #region Dates
function toExcelDate(dateValue) {
  // http://daveaddey.com/?p=40
  return 25569.0 + (dateValue.getTime() - dateValue.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24);
}
// #endregion

// #region Numbers

function replaceCurrencySymbols(formatter, record, format) {
  // this seems questionable, but matches existing behavior
  const currencySymbolRegex = new RegExp(numberCulture.CURRENCY_SYMBOL_PLACEHOLDER, "g");
  let currencySymbol = "";
  if (formatter.currencySymbol) {
    currencySymbol = formatter.currencySymbol.symbolText
      ? formatter.currencySymbol.symbolText
      : numberCulture.getBoundCurrencySymbol(formatter.currencySymbol, record);
  }

  return format.replace(currencySymbolRegex, currencySymbol);
}

function toNumberFormat(formatter, value, record) {
  // if not a standard we are assuming (hoping) that
  // the dev is using a valid custom format
  if (!standardNumberFormatsRgx.test(formatter.format)) {
    return formatter.format;
  }

  const specifier = formatter.format.toLowerCase();
  let scale = getScale(specifier, formatter.scale);
  let displayTrailingZeroes = formatter.displayTrailingZeroes;

  if (value && formatter.displayTrailingZeroes === false && specifier !== "d" && scale > 0) {
    // why is this portion necessary? won't the # symbol in the decimal portion have the same effect?
    const stringifiedValue = value.toString();
    if (stringifiedValue.indexOf(".") >= 0) {
      // raw decimal values in javascript don't have trailing zeroes.
      // this should give us the actual number of decimal places we want.
      const naturalScale = stringifiedValue.split(".")[1].length;
      if (scale > naturalScale) {
        scale = naturalScale;
      }

      // this shouldn't be necessary, but is tested for
      displayTrailingZeroes = true;
    } else {
      scale = 0;
    }
  }

  const format = specifier + scale;
  if (format in numberFormatCache) {
    return numberFormatCache[format];
  }

  const formatConfig = { format: specifier, scale, displayTrailingZeroes };
  let numericFormat = numberCulture.getCldrNumericFormat(formatConfig);

  if (specifier === "c") {
    numericFormat = replaceCurrencySymbols(formatter, record, numericFormat);
  }

  // cache and return
  return (numberFormatCache[format] = numericFormat);
}

function getScale(format, scale) {
  if (scale >= 0) {
    return scale;
  }

  // get the default based on the type
  let pattern;
  switch (format) {
    case "d":
      return 0;

    case "c":
      pattern = numberCulture.getCldrNumericFormat("accounting");
      break;

    case "p":
      pattern = numberCulture.getCldrNumericFormat("percent");
      break;

    default:
      pattern = numberCulture.getCldrNumericFormat("decimal");
      break;
  }

  const decimalPart = pattern.split(";")[0].split(".")[1];
  return decimalPart ? decimalPart.length : 0;
}
// #endregion

excelUtils.base64ToBlob = base64ToBlob;
excelUtils.getCellValue = getCellValue;

module.exports = excelUtils;
plexExport("exporter.excel", excelUtils);
