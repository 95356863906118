ï»¿const CustomerDate = require("../Globalization/plex-customer-date");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");

const DateTimeDataLabelValueProvider = DefaultDataLabelValueProvider.extend({
  getValue: function (_data) {
    const dateTime = this._base.apply(this, arguments);
    return dateTime ? new CustomerDate(dateTime) : null;
  }
});

module.exports = DateTimeDataLabelValueProvider;
plexExport("labels.valueProviders.DateTimeDataLabelValueProvider", DateTimeDataLabelValueProvider);
