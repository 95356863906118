ï»¿const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const nav = require("../Core/plex-navigate");
const parsing = require("../Core/plex-parsing-url");

ko.bindingHandlers.routeBoundImage = {
  update: function (element, valueAccessor, allBindings, viewModel) {
    const config = ko.unwrap(valueAccessor());
    if (config) {
      const fileName = dataUtils.getValue(viewModel.data, config.fileNamePropertyName);

      if (fileName) {
        const parameters = {
          imagePath: parsing.combineUrl(config.filePath, fileName),
          requestId: config.disableCaching ? new Date().getTime() : undefined
        };

        const url = nav.buildUrl(config.rootUrl, parameters);
        $(element).prop("src", url).show();
      } else {
        $(element).hide();
      }
    } else {
      $(element).hide();
    }
  }
};
