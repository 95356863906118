const ko = require("knockout");
const ControllerFactory = require("./plex-controller-factory");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const WeighScaleReader = require("../Native/plex-weigh-scale-reader");

// eslint-disable-next-line import/no-unassigned-import
require("../Knockout/knockout-extender-and-or");

class WeighScaleController {
  constructor(config, model) {
    this.config = config;
    this.model = model;
    this.init();
  }

  init() {
    // assume that this won't be enabled - that will be the normal case
    this.enabled = ko.observable(false);
    this.liveMode = ko.observable(this.config.enableLiveMode).andAlso(this.enabled);
    this.config.visible = ko.observable(this.config.clientVisible).andAlso(this.enabled);

    this.addDisposable(this.liveMode.subscribe(() => this.toggleLiveMode()));

    this.scale = new WeighScaleReader(this.config.scaleIndex);
    this.scale.isEnabled().then(this.enabled);
  }

  toggleLiveMode() {
    if (this.liveMode()) {
      this._watcher = this.scale.watch(this.applyScaleResult.bind(this));
    } else if (this._watcher) {
      this._watcher.unwatch();
      this._watcher = null;
    }
  }

  read() {
    if (this.enabled()) {
      this.scale.read().then(this.applyScaleResult.bind(this));
    }
  }

  applyScaleResult(result) {
    this.config.bindings.forEach((b) => {
      if (result[b.sourcePropertyName] != null) {
        dataUtils.setValue(this.model, b.targetPropertyName, result[b.sourcePropertyName]);
      }
    });
  }

  dispose() {
    if (this._watcher) {
      this._watcher.unwatch();
    }

    this._base.apply(this, arguments);
  }
}

jsUtils.mixin(WeighScaleController, "disposable");

WeighScaleController.create = (config, model) => new WeighScaleController(config, model);
ControllerFactory.register("Elements/_WeighScaleButton", WeighScaleController);
