ï»¿const jsUtils = require("../Utilities/plex-utils-js");
const expressions = require("../Expressions/plex-expressions-compiler");
const plexExport = require("../../global-export");

const returnTrue = function () {
  return true;
};

const Feature = function () {
  // constructor
};

Feature.prototype = {
  constructor: Feature,

  init: function () {
    /// <summary>Initializes the feature.</summary>

    let originalEvaluator;
    if (this.config.condition) {
      this.conditionEvaluator = expressions.compile(this.config.condition, this.config.sourceId, this.config.source);
    } else {
      // if no condition is provided, consider it to be always enabled
      this.conditionEvaluator = returnTrue;
    }

    if (this.config.invertCondition) {
      originalEvaluator = this.conditionEvaluator;
      this.conditionEvaluator = function () {
        return !originalEvaluator.apply(this, arguments);
      };
    }

    // The priority in which the feature processor is executed. Lower ordered priorities are processed first.
    this.priority = 1;

    if (this.parent && this.parent.addDisposable) {
      this.parent.addDisposable(this);
    }

    this.onInit();
  },

  onInit: function () {
    // can be overriden
  },

  evaluate: function (_record, _index, _records, _rowConfig, _groupIndex, _group) {
    return this.conditionEvaluator.apply(this, arguments);
  },

  execute: function (_record, _index, _records, _rowConfig, _groupIndex, _group) {
    const passed = this.evaluate.apply(this, arguments);
    if (jsUtils.isPromise(passed)) {
      return passed.then((val) => this[val ? "onExecute" : "onConditionFailed"](...arguments));
    }

    return this[passed ? "onExecute" : "onConditionFailed"](...arguments);
  },

  onExecute: function () {
    // this should be overridden by implementers
    return {};
  },

  onConditionFailed: function () {
    // this can be overridden by implementers when you want to apply a
    // feature when the condition fails
    return {};
  },

  dispose: function () {
    // this should be overridden by implementers
    this.onDispose();
  },

  onDispose: function () {
    // this can be overridden by implementers when you want to apply a
    // feature when the condition fails
  }
};

jsUtils.makeExtendable(Feature);

module.exports = Feature;
plexExport("features.Feature", Feature);
