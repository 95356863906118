const $ = require("jquery");
const ask = require("./plex-dialogs-ask");
const http = require("../Core/plex-navigate");
const plexImport = require("../../global-import");

function Authenticate(callback, options = {}) {
  if (arguments.length === 1 && typeof callback !== "function") {
    // allow to be called without a callback
    options = callback;
    callback = null;
  }

  const appState = plexImport("appState");
  const loginAuthRequestModel = {
    UserId: options.userId || appState.user.userId,
    CompanyCode: appState.user.companyCode,
    Password: ""
  };

  const dialogOptions = {
    displayType: "password",
    displayPasswordKeyboard: options.displayPasswordKeyboard || false,
    keyboardScale: options.keyboardScale || 1
  };

  const deferred = new $.Deferred();

  ask("Please verify your password.", [], "", dialogOptions, (password) => {
    loginAuthRequestModel.Password = password;
    http
      .post("/login/validatepassword", loginAuthRequestModel)
      .then((res) => deferred[res ? "resolve" : "reject"](res), deferred.reject);
  }).fail((res) => deferred.reject(res));

  if (callback) {
    // callback should be called whether authentication passes or not
    deferred.then(callback, callback);
  }

  return deferred.promise();
}

module.exports = Authenticate;
