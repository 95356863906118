const plexExport = require("../../global-export");

let pageReady = false;
const readyFns = [];

/**
 * Invokes the callback function once all page components have been initialized.
 * If the page is already ready the callback will be invoked immediately.
 *
 * @param {Function} callback
 * @param {Object} context
 */
function onReady(callback, context) {
  if (pageReady) {
    callback.call(context);
  } else {
    readyFns.push({ callback, context });
  }
}

let hasNavStart = false;

function trigger() {
  if (window.performance && "measure" in window.performance) {
    if (hasNavStart || window.performance.getEntriesByName("nav-start").length > 0) {
      hasNavStart = true;
      window.performance.measure("page ready", "nav-start");
    } else {
      window.performance.measure("page ready");
    }
  }

  let fn;
  pageReady = true;

  while ((fn = readyFns.shift())) {
    fn.callback.call(fn.context);
  }
}

/**
 * Resets the page handler's ready state to allow re-processing of content.
 */
function reset() {
  pageReady = false;
}

module.exports = {
  reset,
  onReady,
  trigger
};

plexExport("onReady", onReady);
