const ko = require("knockout");
const $ = require("jquery");
const valueProviderFactory = require("../ValueProviders/plex-grid-value-providers-factory");
const gridUtils = require("../plex-grid-utils");
const operationTarget = require("../plex-grid-recordtarget");
const htmlUtils = require("../../Utilities/plex-utils-html");
const factory = require("../Aggregates/plex-grid-aggregate-factory");
const Writer = require("./plex-grid-writer-base");
const FeatureProcessor = require("../../Features/plex-feature-processor");
const plexExport = require("../../../global-export");

const GroupHeaderRowWriter = Writer.extend({
  init: function (grid, group, header) {
    this._base.apply(this, arguments);

    this.group = group;
    this.config = this.header = header;

    if (!this.header.valueProvider || typeof this.header.valueProvider.getHtml !== "function") {
      this.header.valueProvider = valueProviderFactory.create(this.header, grid.options);
    }

    if (this.header.features && this.header.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(this.header.features, this.header, this.parent);
    }

    this.onReset();
  },

  onReset: function () {
    const self = this;
    const ln = self.grid.columns.length;
    this.aggregates = [];

    if (ln > 0 && this.header.aggregates && this.header.aggregates.length > 0) {
      this.header.aggregates.forEach((config) => {
        let i = 0;
        let column, op;

        for (; i < ln; i++) {
          if (self.grid.columns[i].id === config.columnId) {
            column = self.grid.columns[i];
            op = factory.create(column, config);
            op.colIndex = i;

            if (config.features && config.features.length > 0) {
              op.featureProcessor = new FeatureProcessor(config.features, op, self.parent);
            }

            self.aggregates.push(op);
          }
        }

        if (op === undefined) {
          throw new Error("Grid column not found");
        }
      });

      // sort by column index
      this.aggregates.sort((a, b) => {
        return a.colIndex - b.colIndex;
      });
    }
  },

  render: function (writer, data, config) {
    const self = this;

    // todo: we will probably rarely need both of these sets of data so it'd be nice to lazy load these
    const allData = gridUtils.aggregateData(data, false);
    const groupData = gridUtils.aggregateData(data, true);
    let isFirstCell = true;
    let i = 0;
    const operations = this.aggregates.filter((o) => {
      return o.column.visible();
    });

    // store print aggregate information in the header for later use during printing
    this.header.$$printOperations = this.aggregates.filter((o) => {
      return o.column.printVisible();
    });
    this.header.$$printTotals = data.index ? this.header.$$printTotals || [] : [];
    if (this.header.$$printOperations.length > 0) {
      this.header.$$printTotals.push(this.createAggregateContext(this.header.$$printOperations, allData, groupData));
    }

    let values = [];
    if (operations.length !== 0) {
      values = this.createAggregateContext(operations, allData, groupData);
    }

    const record = gridUtils.getFirstRecord(data);
    const css = "plex-grid-group-header plex-grid-group-level-" + data.groupIndex;

    const tr = writer
      .appendChild("tr")
      .addAttr("data-group-header", data.groupIndex + "." + data.index)
      .addAttr("data-group-header-index", data.gIndex)
      .addClass(css);

    if (this.header.printBreak) {
      tr.addAttr("data-print-break", JSON.stringify(this.header.printBreak));
    }

    if (
      "$$collapsed" in data &&
      data.$$collapsed() &&
      ((this.header.collapsible && data.groupIndex > 0) || !this.header.collapsible)
    ) {
      this.features.css.push("plex-grid-row-collapsed ");
    }

    this.writeCss(tr, this.features.css);
    this.writeAttributes(tr, this.features.attr);
    this.writeStyle(tr, this.features.style);

    let colspan = config.colspan;

    if (values.length === 0) {
      if (this.header.selectable) {
        // This is making an assumption that the header selectability is being
        // applied to the group selection - this feature is not well defined
        // and rarely used, so if more complex scenarios arise it will require
        // more explicit configuration.
        if (data.selectionState && !data.renderedToggleAll) {
          this._renderSelectAllCell(tr, data, css);
          data.renderedToggleAll = true;
        } else {
          this._renderSelectionCell(tr, data, css);
        }

        colspan--;
      } else if (config.collapsible && config.checkable) {
        this._renderEmpty(tr, 1, false, null, data, css);
        colspan--;
      }

      const td = tr.appendChild("td").addAttr("colspan", colspan).addClass(css);

      if (data && "$$collapsed" in data) {
        this.writeAttributes(td, { "data-indentation-level": data.groupIndex });
        this.writeStyle(td, { "padding-left": data.groupIndex * 15 + "px" });
      }

      if (this.header.collapsible && "$$collapsed" in data) {
        this._renderExpander(td, data, css);
      }

      this.writeHeaderText(record, td);
      td.close();
    } else {
      // todo: is this a copy of the footer implementation? can't we merge?
      colspan = config.checkable ? 1 : 0;
      if (self.grid.options && self.grid.options.ranking) {
        colspan++;
      }

      if (this.header.selectable) {
        if (data.selectionState && !data.renderedToggleAll) {
          this._renderSelectAllCell(tr, data, css);
          data.renderedToggleAll = true;
        } else {
          this._renderSelectionCell(tr, data, css);
        }

        colspan--;
      } else if (config.collapsible && config.checkable) {
        this._renderEmpty(tr, 1, false, null, data, css);
        colspan--;
      }

      this.grid.columns.forEach((col, colIndex) => {
        let agg, features, context;

        if (col.visible()) {
          if (i < operations.length && colIndex === operations[i].colIndex) {
            agg = operations[i];

            if (colspan) {
              self._renderEmpty(tr, colspan, isFirstCell, allData[0], data, css);
              colspan = 0;
              isFirstCell = false;
            }

            // use uppercase properties to comply with casing expected from C# expressions
            context = { Values: values };
            if (agg.config.trackClientUpdates) {
              context.Value = ko.computed(() => {
                return values[colIndex];
              });
              ko.track(context, ["Value"]);
            } else {
              context.Value = values[colIndex];
            }

            if (agg.featureProcessor) {
              /* eslint no-useless-call: "off" */
              features = agg.featureProcessor.apply(agg, [context])();
              features.merge({ css: agg.css });
              features.merge({ css });
            } else {
              features = { css: [agg.css, css], attr: {}, style: {} };
            }

            if (colspan === 0 && isFirstCell) {
              if (data && "$$collapsed" in data) {
                $.extend(features.attr, {
                  "data-indentation-level": data.groupIndex
                });
                $.extend(features.style, {
                  "padding-left": data.groupIndex * 15 + "px"
                });
              }
            }

            const td = tr.appendChild("td");
            self.writeAttributes(td, features.attr);
            self.writeCss(td, features.css);
            self.writeStyle(td, features.style);

            // If first cell is aggregate, add header text to cell
            if (colspan === 0 && isFirstCell) {
              if (self.header.collapsible && "$$collapsed" in data) {
                self._renderExpander(td, data, css);
              }

              self.writeHeaderText(record, td);
              isFirstCell = false;
            }

            td.appendHtml(
              self.getCellHtml(agg, context, agg.config.target === operationTarget.record ? allData : groupData)
            );
            td.close();

            i++;
          } else {
            colspan++;
          }
        }
      });

      if (colspan) {
        this._renderEmpty(tr, colspan, false, null, data, css);
      }

      // add totals to data
      data.$$totals = data.$$totals || [];
      data.$$totals.push(values);
    }

    tr.close();
  },

  _renderSelectionCell: function (writer, group, css) {
    const args = {
      data: group,
      options: { propertyName: "$$selected", visible: true }
    };
    const html = htmlUtils.getMemoizedTemplate("elements-checkbox", args, null, this.grid.$element[0]);

    writer.appendChild("td").addClass("plex-grid-selection-cell").addClass(css).appendHtml(html).close();
  },

  _renderSelectAllCell: function (writer, group, css) {
    const html = htmlUtils.getMemoizedTemplate("grid-toggle-all-selection", group, null, this.grid.$element[0]);

    writer.appendChild("td").addClass("plex-grid-selection-cell").addClass(css).appendHtml(html).close();
  },

  _renderExpander: function (writer, group, css) {
    const args = {
      data: group,
      options: { propertyName: "$$collapsed", visible: true }
    };
    const html = htmlUtils.getMemoizedTemplate("elements-expander", args, null, this.grid.$element[0]);

    writer.appendChild("span").addClass("plex-grid-expander").addClass(css).appendHtml(html).close();
  },

  getAggregateValue: function (agg, data) {
    const self = this;
    if (agg.config.trackClientUpdates) {
      // wrap in computed so value will be reevaluated if data changes
      const observable = ko.observable();
      return ko.computed(() => {
        const value = agg.getValue(data, self.header, self.group);
        $.when(value).then((resolvedValue) => {
          observable(resolvedValue);
        });

        return observable();
      });
    }

    return agg.getValue(data, self.header, self.group);
  },

  createAggregateContext: function (operations, allData, groupData) {
    const context = {};
    let i = operations.length;
    const toTrack = [];
    let current, data;

    while (i--) {
      current = operations[i];
      data = current.config.target === operationTarget.record ? allData : groupData;

      context[current.column.id] = context[current.colIndex] = this.getAggregateValue(current, data);
      if (current.config.trackClientUpdates) {
        toTrack.push(current.column.id);
        toTrack.push(current.colIndex);
      }
    }

    if (toTrack.length > 0) {
      // wrap in property getter setter so expression doesn't have to unwrap the observable
      ko.track(context, toTrack);
    }

    return context;
  },

  getCellHtml: function (agg, context, data) {
    if (agg.config.trackClientUpdates) {
      const formattedValue = ko.computed(() => {
        return agg.formatValue(context.Value, data);
      });

      return ko.renderTemplate("grid-text", { value: formattedValue });
    }

    return agg.formatValue(context.Value, data);
  },

  getHeaderHtml: function (record, _group) {
    if (this.headerTextEvaluator) {
      return this.headerTextEvaluator(record);
    }

    return this.config.valueProvider.getHtml(record) || this.config.valueProvider.getEmptyHtml();
  },

  writeHeaderText: function (record, td, group) {
    const span = td.appendChild("span").addClass("plex-grid-header-text");

    if (this.features) {
      this.writeCss(span, this.features.css);
      this.writeAttributes(span, this.features.attr);
      this.writeStyle(span, this.features.style);
    }

    const text = this.getHeaderHtml(record, group);
    span.appendHtml(text).close();
  },

  _renderEmpty: function (tr, colspan, includeText, record, data, css) {
    const td = tr.appendChild("td").addAttr("colspan", colspan);
    if (data && "$$collapsed" in data) {
      td.addAttr("data-indentation-level", data.groupIndex);
      td.addStyle("padding-left", data.groupIndex * 15 + "px");
    }

    td.addClass(css);

    if (includeText) {
      if (this.header.collapsible && "$$collapsed" in data) {
        this._renderExpander(td, data, css);
      }

      this.writeHeaderText(record, td);
    } else {
      td.appendHtml("&nbsp;");
    }

    td.close();
  }
});

module.exports = GroupHeaderRowWriter;
plexExport("grid.writers.GroupHeaderRowWriter", GroupHeaderRowWriter);
