ï»¿const ko = require("knockout");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const booleanIcon = "<i class='plex-icon-checkmark3'></i>";
const printXml = "<checkmark />";

const BooleanValueProvider = defaultValueProvider.extend({
  getHtml: function () {
    if (this.config.trackClientUpdates) {
      return this.getTrackedHtml.apply(this, arguments);
    }

    return getDisplayValue(this.getValue.apply(this, arguments));
  },

  getTrackedHtml: function (_record, _index, _colIndex, _features) {
    const self = this;
    const args = arguments;
    const value = ko.pureComputed(() => {
      return getDisplayValue(self.getValue.apply(self, args));
    });

    return this.renderTemplate(this.templateName, { value });
  },

  getPrintValue: function (_record, _index, _colIndex, _features) {
    return getDisplayValue(this.getValue.apply(this, arguments), true);
  }
});

function getDisplayValue(value, print) {
  if (typeof value === "string") {
    return value;
  }

  if (value) {
    return print === true ? printXml : booleanIcon;
  } else {
    return "";
  }
}

module.exports = BooleanValueProvider;
plexExport("grid.valueProviders.BooleanValueProvider", BooleanValueProvider);
