ï»¿const ko = require("knockout");
const dataUtils = require("../Utilities/plex-utils-data");

[
  { name: "ifempty", expected: true },
  { name: "ifnotempty", expected: false }
].forEach((binding) => {
  ko.bindingHandlers[binding.name] = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
      const wrappedAccessor = function () {
        return dataUtils.isEmpty(ko.unwrap(valueAccessor())) === binding.expected;
      };

      return ko.bindingHandlers.if.init.call(this, element, wrappedAccessor, allBindings, viewModel, bindingContext);
    }
  };

  // same behavior as if binding
  ko.expressionRewriting.bindingRewriteValidators[binding.name] = false;
  ko.virtualElements.allowedBindings[binding.name] = true;
});
