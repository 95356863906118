ï»¿const ko = require("knockout");

// this binding just provides an opposite version for the `visible` binding
ko.bindingHandlers.hidden = {
  update: function (element, valueAccessor) {
    const invertedValueAccessor = function () {
      return !ko.unwrap(valueAccessor());
    };
    ko.bindingHandlers.visible.update(element, invertedValueAccessor);
  }
};
