ï»¿const $ = require("jquery");
const tokenUtils = require("../Utilities/plex-utils-datatoken");
const TokenParser = require("../Tokens/plex-tokens-parser");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

const tokenParser = new TokenParser();
const noop = function () {
  // noop
};

const PageMode = {
  Normal: 0,
  Add: 1,
  Update: 2
};

function NavigationExpression(config) {
  // eslint-disable-next-line no-invalid-this
  this.init(config);
}

NavigationExpression.prototype = {
  constructor: NavigationExpression,

  init: function (config) {
    if (config.authorized && config.url) {
      const params = [];
      const urlFn = tokenParser.compile(config.url);

      // manually add these to params to make things simpler
      if (config.autoSearch) {
        config.parameters.push({ name: "search", value: "1" });
      }

      if (config.screen) {
        config.parameters.push({ name: "__screen", value: config.screen });
      }

      if (config.pageMode === PageMode.Add) {
        config.parameters.push({ name: "PageMode", value: "Add" });
      }

      if (config.parameters.length > 0) {
        // parse all the params
        config.parameters.forEach((p) => {
          params.push({
            alias: p.name,
            evaluator: tokenParser.compile(p.value)
          });
        });
      }

      this.evaluator = function (data) {
        const rawUrl = urlFn.apply(null, arguments);
        const qs = tokenUtils.applyTokens(data, params);
        const url = nav.buildUrl(rawUrl, qs, config);
        return $.extend({}, config, { url });
      };
    } else {
      this.evaluator = noop;
    }
  },

  getValue: function () {
    return this.evaluator.apply(null, arguments);
  }
};

module.exports = NavigationExpression;
plexExport("expressions.Navigate", NavigationExpression);
