const ko = require("knockout");
const $ = require("jquery");
const glossary = require("../Globalization/plex-glossary-handler");

ko.bindingHandlers.toggleButton = {
  after: ["visible"],
  init: function (element, valueAccessor, allBindings) {
    const options = valueAccessor();
    const value = options.value;
    const $element = $(element);

    glossary.getCustomerWordBulkAsync([options.onText, options.offText]).then((words) => {
      $element.bootstrapToggle({
        on: words[options.onText],
        off: words[options.offText],
        height: options.height || 30,
        size: options.size || "small"
      });

      if (allBindings.has("visible")) {
        // the plugin wraps input and injects additional element, so we are making sure that
        // the visible binding hides the entire component by also hiding the parent
        // (There may be additional bindings that warrant copying to the parent in this fashion)
        ko.applyBindingsToNode($element.parent()[0], { visible: allBindings.get("visible") });
      }

      if (ko.isObservable(value)) {
        // two-way bindings are not supported for this binding
        $element.on("change", () => {
          value($element.prop("checked"));
        });
      }

      if (ko.utils.peekObservable(value)) {
        $element.bootstrapToggle("on");
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $element.off("change").bootstrapToggle("destroy");
    });
  },

  update: function (element, valueAccessor) {
    const $element = $(element);

    // execute this outside of if block so dependency is always added.
    const enabled = ko.unwrap(valueAccessor().value);

    // verify that the toggle plugin is applied - update can happen before the plugin
    // is ready because of the glossary lookup
    if ($element.data("bs.toggle")) {
      $element.bootstrapToggle(enabled ? "on" : "off");
    }
  }
};
