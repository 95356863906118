const ko = require("knockout");
const CustomerDate = require("../Globalization/plex-customer-date");

const dateTimePrecisions = {
  minutes: "minutes",
  seconds: "seconds",
  milliseconds: "milliseconds"
};

function setDate(value, config) {
  value = ko.unwrap(value);
  let newDate = null;

  if (value) {
    newDate = new CustomerDate(value);

    if (config.dateTimePrecision === dateTimePrecisions.seconds && config.enableTime) {
      newDate.setMilliseconds(0);
    }

    // This is the default precision
    if (config.dateTimePrecision === dateTimePrecisions.minutes && config.enableTime) {
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
    }

    if (!config.enableTime) {
      // if time is not configurable then enforce consistency by setting the time to 00:00:00.
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      newDate.setMinutes(0);
      newDate.setHours(0);
    }
  }

  return newDate;
}

ko.extenders.customerDate = function (target, config) {
  return ko.pureComputed({
    read: target,
    write: function (value) {
      target(setDate(value, config));
    }
  });
};
