ï»¿const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const DayHighlightFeature = Feature.extend({
  onExecute: function (record) {
    const color = record[this.config.colorProperty] || this.config.color;

    return {
      style: { width: "100%", height: "100%", backgroundColor: color, border: "none" }
    };
  }
});

module.exports = DayHighlightFeature;
plexExport("features.DayHighlight", DayHighlightFeature);
