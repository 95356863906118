const ko = require("knockout");
const $ = require("jquery");
const ResizeObserver = require("ResizeObserver");
const dom = require("../Utilities/plex-utils-dom");
const jsUtils = require("../Utilities/plex-utils-js");

const registeredHandlers = new WeakMap();

function registerHandler(targetElement, element, func) {
  let handler = registeredHandlers.get(targetElement);
  const entry = {
    func,
    el: element
  };

  if (!handler) {
    let lastWidth;
    const observer = new ResizeObserver(
      jsUtils.throttleRender((entries) => {
        const currentWidth = entries && entries[0].contentRect.width;
        if (currentWidth === lastWidth) {
          return;
        }

        // reset all widths so they don't impact the sizing of each other
        handler.entries.forEach((e) => {
          e.el.style.width = "auto";
          e.el.style.minWidth = e.el.style.maxWidth = "none";
        });

        handler.entries.forEach((e) => e.func());
        lastWidth = currentWidth;
      })
    );

    handler = {
      observer,
      entries: []
    };

    observer.observe(targetElement);
    registeredHandlers.set(targetElement, handler);
  }

  handler.entries.push(entry);

  return {
    dispose: function () {
      handler.entries.remove(entry);

      if (handler.entries.length === 0) {
        handler.observer.disconnect();
        registeredHandlers.delete(targetElement);
      }
    }
  };
}

ko.bindingHandlers.sizeTo = {
  init: function (element, valueAccessor) {
    let options = valueAccessor();
    const $element = $(element);
    const inDialog = dom.isInDialog($element);
    let sourceProperty = "clientWidth";

    if (typeof options === "string") {
      options = { target: options };
    }

    let $target;
    const widthProperty = options.widthProperty || "minWidth";

    switch (options.target) {
      case "window":
        $target = inDialog ? $element.closest(".modal-body") : $(document.body);
        break;

      case "document":
        $target = inDialog ? $element.closest(".modal-body") : $(document);
        sourceProperty = "scrollWidth";
        break;

      default:
        $target = $(options.target);
        break;
    }

    let offset = options.offset || 0;
    const targetElement = $target[0].documentElement || $target[0];

    // offset for margin/padding
    offset += $element.outerWidth(true) - $element.width();

    const handleResize = function () {
      const width = targetElement[sourceProperty] - offset;
      element.style[widthProperty] = width + "px";
    };

    let disposer = registerHandler(targetElement, element, handleResize);
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      disposer.dispose();
      disposer = null;
    });
  }
};
