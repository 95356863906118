const root = require("./global-root");

module.exports = function GlobalImport(ns, ignoreIfMissing) {
  const segments = ns.split(".");
  let current = root;
  let segment;

  while (segments.length > 0) {
    segment = segments.shift();
    if (!(segment in current)) {
      if (ignoreIfMissing) {
        return null;
      }

      current[segment] = {};
    }

    current = current[segment];
  }

  return current;
};
