ï»¿const ko = require("knockout");
const Comparer = require("../Utilities/plex-utils-comparer");

ko.extenders.toBool = function (target, boolValue) {
  target(Comparer.equals(boolValue, target.peek()));
  return ko.pureComputed({
    read: target,
    write: (value) => target(Comparer.equals(boolValue, value))
  });
};
