ï»¿const formatUtils = require("../Globalization/plex-formatting");
const dataTokens = require("../Utilities/plex-utils-datatoken");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");

const PersonNameDataLabelValueProvider = DefaultDataLabelValueProvider.extend({
  getValue: function () {
    return this.getFormattedValue.apply(this, arguments);
  },

  getFormattedValue: function (data) {
    const formatter = this.options.formatter;
    const bindableProperties = this.options.bindableProperties;
    const model = dataTokens.applyTokens(data, bindableProperties);

    return formatUtils.PersonNameFormatter.format(formatter.format, model.FirstName, model.MiddleName, model.LastName);
  }
});

module.exports = PersonNameDataLabelValueProvider;
plexExport("labels.valueProviders.PersonNameDataLabelValueProvider", PersonNameDataLabelValueProvider);
