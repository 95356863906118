ï»¿const ko = require("knockout");
const DefaultXmlProvider = require("./plex-printing-xml-provider");
const plexExport = require("../../../global-export");

const IconPickerXmlProvider = DefaultXmlProvider.extend({
  onPreInit: function () {
    this.isImagePicker = this.config.templateName === "collection-picker-image";
  },

  getNodeName: function () {
    return this.isImagePicker ? "plex-control-imagepicker" : this._base.apply(this, arguments);
  },

  onGetXml: function () {
    const self = this;
    if (this.isImagePicker) {
      if (this.collection && this.collection.length > 0) {
        const image = ko.utils.arrayFirst(this.collection, (o) => {
          return o.key === self.initialDisplayValue;
        });
        if (image) {
          this.node.addAttribute("src", image.imageUrl);
        }
      }

      return this.node;
    }

    // must be an icon picker and we currently cannot print these
    return "";
  }
});

module.exports = IconPickerXmlProvider;
plexExport("printing.xmlProviders.iconpicker", IconPickerXmlProvider);
