ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const HtmlWriter = require("../../Utilities/plex-utils-html-writer");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const gridUtils = require("../plex-grid-utils");
const writerFactory = require("./plex-grid-writer-factory");
const GridWriter = require("./plex-grid-writer");
const SelectionState = require("../../Controls/plex-selection-state");
const logger = require("../../Core/plex-logger");
const plexExport = require("../../../global-export");

const slice = Array.prototype.slice;

const GroupWriter = GridWriter.extend({
  init: function () {
    const self = this;
    this._base.apply(this, arguments);
    this.groups = this.grid.groups;
    this.groups.forEach((group, i) => {
      group.groupIndex = i;

      if (group.headers && group.headers.length > 0) {
        if (group.printBreak && group.printBreak.attribute === "break-before") {
          group.headers[0].printBreak = group.printBreak;
        }

        group.headers.forEach((header, index) => {
          header.index = index;
          header.writer = writerFactory.create(header.writerProvider, self.grid, group, header);
        });
      }

      if (group.footers && group.footers.length > 0) {
        if (group.printBreak && group.printBreak.attribute === "break-after") {
          group.footers[group.footers.length - 1].printBreak = group.printBreak;
        }

        group.footers.forEach((footer, index) => {
          footer.index = index;
          footer.writer = writerFactory.create(footer.writerProvider, self.grid, group, footer);
        });
      }
    });
  },

  render: function (data) {
    const self = this;
    if (this.grid.getData().length === 0) {
      // note: for now if there is no data we aren't rendering the groups
      this._base.apply(this, arguments);
      return;
    }

    if (!this.grid.onRenderStart()) {
      return;
    }

    const selectable = this.grid.isSelectable();
    const checkable = this.grid.isCheckable();
    const collapsible = this.grid.isCollapsible();
    const draggable = this.grid.options && this.grid.options.ranking;
    let columnCount = ko.unwrap(this.primaryLayout.columns).filter((c) => {
      return c.visible();
    }).length;
    // account for checkbox
    columnCount += checkable ? 1 : 0;
    // account for draggable column
    columnCount += draggable ? 1 : 0;

    const config = {
      colspan: columnCount,
      selectable,
      checkable,
      collapsible
    };

    this.rowIndex = 0;

    const queue = this.buildRenderQueue(data, config);
    if (this.shouldVirtualize(queue)) {
      const renderer = function (virtualWriter, index) {
        return queue[index] ? queue[index].render(virtualWriter) : "";
      };
      this.renderVirtualHtml(renderer, queue.length);
      this.renderQueue = queue;
    } else {
      let writer = new HtmlWriter(this.grid.$tbody);
      let cancelSub;

      let chunker = arrayUtils
        .chunk(queue, 300)
        .each((task) => {
          task.render(writer);
        })
        .batch(writer.render.bind(writer));

      chunker.start().done(() => {
        self.grid.onRenderComplete();
        writer = chunker = null;

        if (cancelSub) {
          cancelSub.dispose();
          cancelSub = null;
        }
      });

      cancelSub = this.grid.cancelPending.subscribeOnce((cancel) => {
        if (cancel && chunker != null) {
          chunker.cancel();
          self.grid.onRenderCancel();
        }

        cancelSub = null;
      });
    }

    this.renderFooter(this.grid.$tfoot, data, config);
    this.renderFeatures(this.grid.$table);
  },

  buildRenderQueue: function (data, config) {
    // reset iteration indexes
    data.reset();
    let current = data.next();

    const stack = [];
    const footers = [];
    let rowIndex = 0;

    while (current) {
      let records, firstRecord;
      while (footers.length > current.groupIndex) {
        const currentFooters = footers.pop();
        while (currentFooters?.length > 0) {
          stack.push(currentFooters.shift());
        }
      }

      if (current.config.headers?.length > 0) {
        const currentContext = current;
        current.config.headers.forEach(function (header, index) {
          records = records || gridUtils.aggregateData(currentContext);
          firstRecord = firstRecord || records[0];

          if (header.writer.prerender(firstRecord, 0, records) !== false) {
            stack.push({
              render: function (context, headerConfig, writer) {
                return header.writer.render(writer, context, headerConfig, index);
              }.bind(this, currentContext, config)
            });
          }
        });
      }

      if (current.config.footers?.length > 0) {
        footers[current.groupIndex] = [];

        const currentContext = current;
        current.config.footers.forEach(function (footer, index) {
          if (currentContext.groups.length > 1 || !footer.suppressForSingleGroup) {
            records = records || gridUtils.aggregateData(currentContext);
            firstRecord = firstRecord || records[0];

            if (footer.writer.prerender(firstRecord, 0, records, currentContext.gIndex) !== false) {
              footers[currentContext.groupIndex].push({
                render: function (context, footerConfig, writer) {
                  return footer.writer.render(writer, context, footerConfig, index);
                }.bind(this, currentContext, config)
              });
            }
          }
        });
      }

      if (config.checkable && current.config.enableToggleAll) {
        records = records || gridUtils.aggregateData(current);
        current.selectionState = new SelectionState(records);
        current.renderedToggleAll = false;
      }

      if (current.data.length > 0) {
        for (let i = 0, ln = current.data.length; i < ln; i++) {
          stack.push({
            dataIndex: rowIndex,
            render: function (record, row, all, index, context, writer) {
              return this.renderRow(writer, record, row, all, index, context);
            }.bind(this, current.data[i], rowIndex++, current.data, i, current)
          });
        }
      }

      current = current.next();
    }

    // note: this array could likely be sparse
    while (footers.length > 0) {
      const currentFooters = footers.pop();
      while (currentFooters?.length > 0) {
        stack.push(currentFooters.shift());
      }
    }

    return stack;
  },

  renderFeatures: function ($table) {
    if (this.features.css && this.features.css.length > 0) {
      $table.addClass(this.features.css.join(" "));
    }

    if (this.features.style) {
      $table.css(this.features.style);
    }
  },

  ensureRendered: function (dataIndex, callback) {
    if (this.writer && this.writer.virtual) {
      // todo: this can be more efficient by starting near where the data index is
      let renderIndex = this.renderQueue.length;
      while (renderIndex--) {
        if (this.renderQueue[renderIndex].dataIndex === dataIndex) {
          break;
        }
      }

      if (renderIndex >= 0) {
        this.writer.ensureRendered(renderIndex, callback);
      }
    } else {
      callback();
    }
  },

  onSync: function (data, _changes) {
    // we can get away with just sorting the dom
    logger.debug("resorting group dom");

    return this._reindex(data);
  },

  _reindex: function (data) {
    // this gives us a mapping of new index > prior index
    let currentIndex = 0;
    const mappedIndices = data.map((record) => {
      return record.$$index;
    });

    if (
      mappedIndices.some((index) => {
        return index == null;
      })
    ) {
      return false;
    }

    // MAND-3548 If grid virtualization means not all DOM nodes are present, and bypass and don't try to reindex them.
    if ($("tr.plex-virtual-placeholder", this.grid.$tbody[0]).length > 0) {
      return false;
    }

    const source = slice.call(this.grid.$tbody[0].rows);
    const rows = [];
    let row, priorIndex, i, ln;

    while (source.length > 0) {
      for (i = 0, ln = source.length; i < ln; i++) {
        row = source[i];
        priorIndex = row.getAttribute("data-index");

        if (priorIndex) {
          priorIndex = parseInt(priorIndex, 10);
          if (priorIndex === mappedIndices[currentIndex]) {
            row.setAttribute("data-index", currentIndex);

            // see if we need to re-stripe the row
            if (currentIndex % 2 !== priorIndex % 2) {
              $(row).toggleClass("plex-grid-row-even plex-grid-row-odd");
            }

            rows.push(row);
            currentIndex++;
            source.splice(i, 1);
            break;
          }
        } else {
          // only a footer or header won't have a data index attribute
          // so go ahead and keep their position
          rows.push(row);
          source.splice(i, 1);
          break;
        }
      }
    }

    this.grid.$tbody.append(rows);
    return true;
  }
});

module.exports = GroupWriter;
plexExport("grid.writers.GroupWriter", GroupWriter);
