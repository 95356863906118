ï»¿const $ = require("jquery");
const plexExport = require("../../global-export");

function roundPrecision(value, precision) {
  const exp = -1 * precision;
  return decimalAdjust("round", value, exp);
}

function decimalAdjust(type, value, exp) {
  if (typeof exp === "undefined" || Number(exp) === 0) {
    return Math[type](value);
  }

  value = Number(value);
  exp = Number(exp);

  if (isNaN(value) || exp % 1 !== 0) {
    return NaN;
  }

  value = value.toString().split("e");
  value = Math[type](Number(value[0] + "e" + (value[1] ? Number(value[1]) - exp : -exp)));
  value = value.toString().split("e");
  return Number(value[0] + "e" + (value[1] ? Number(value[1]) + exp : exp));
}

function truncateDecimal(decimal, scale) {
  /// <summary>
  /// Truncates the decimal portion of a number using the specified scale value.
  /// </summary>
  /// <param name="decimal">The decimal number to truncate.</param>
  /// <param name="scale">The max number of digits to keep after the right of the decimal point.</param>

  const defaultScale = 2;

  // do not process non numeric values
  if ($.isNumeric(decimal) === false) {
    return decimal;
  }

  const stringifiedDecimal = decimal.toString();
  const pointIndex = stringifiedDecimal.indexOf(".");

  // do not split numbers in exp format
  if (stringifiedDecimal.split("e").length > 1) {
    return decimal;
  }

  // no decimal point so no truncation
  if (pointIndex < 0) {
    return decimal;
  }

  const pointPrefix = stringifiedDecimal.substr(0, pointIndex);
  const pointSuffix = stringifiedDecimal.substr(pointIndex + 1);
  scale = $.isNumeric(scale) && scale >= 0 ? scale : defaultScale;

  // under the scale limit so nothing to truncate here.
  if (pointSuffix.length <= scale) {
    return decimal;
  }

  const truncatedDecimal = pointPrefix + "." + pointSuffix.substr(0, scale);

  return parseFloat(truncatedDecimal);
}

function floorOrCeilIntegerPart(decimal, precision, scale) {
  /// <summary>
  /// Floors or ceils the integer portion of a decimal number using the specified precision and scale value.
  /// </summary>
  /// <param name="decimal">The decimal number.</param>
  /// <param name="precision">The total number of digits to keep before and after the decimal point.</param>
  /// <param name="scale">The max number of digits to keep after the right of the decimal point.</param>

  let pointIndex, integerPart;

  // do not process non numeric values
  if ($.isNumeric(decimal) === false) {
    return decimal;
  }

  // check if scale is numeric, else default to 2
  scale = $.isNumeric(scale) ? scale : 2;

  // do not process if precision is undefined or less than equal to scale
  if (!precision || precision <= scale) {
    return decimal;
  }

  // calculate the boundary value that will be used in case the integer part is greater/less than max/min allowed
  let boundaryValue = "";
  let length = precision - scale;
  while (length > 0) {
    length--;
    boundaryValue += "9";
  }

  // get the integer part of the decimal
  const stringifiedDecimal = decimal.toString();

  // decimals arent stored past 17 places and at 21 the value is expressed in exponential format
  if (Math.abs(decimal) >= 1e21) {
    pointIndex = -1;
    integerPart = Math.floor(Math.abs(decimal));
    integerPart = decimal < 0 ? -1 * integerPart : integerPart;
  } else {
    pointIndex = stringifiedDecimal.indexOf(".");
    integerPart = parseInt(pointIndex < 0 ? decimal : stringifiedDecimal.substr(0, pointIndex), 10);
  }

  if (decimal < 0) {
    boundaryValue = "-" + boundaryValue;
    if (integerPart >= parseInt(boundaryValue, 10)) {
      // if the integer part is greater than equal to the negative boundary value, simply return the decimal value
      return decimal;
    }
  } else if (integerPart < parseInt(boundaryValue, 10)) {
    // if the integer part is less than the boundary value, simply return the decimal value
    return decimal;
  }

  if (pointIndex < 0) {
    // no decimal point so return the boundary value
    return parseFloat(boundaryValue);
  } else {
    // Prevent decimal rounding from exceeding precision
    if (roundPrecision(stringifiedDecimal.substr(pointIndex), scale) === 1) {
      return truncateDecimal(parseFloat(boundaryValue + stringifiedDecimal.substr(pointIndex)), scale);
    }

    // append the decimals to the boundary value
    return parseFloat(boundaryValue + stringifiedDecimal.substr(pointIndex));
  }
}

function add(leftValue, rightValue, roundingOptions) {
  /// <summary>
  /// Add two values and appropriately round the resulting sum.
  /// </summary>
  /// <param name="rightValue">The left number.</param>
  /// <param name="rightValue">The right number.</param>
  /// <param name="roundingOptions"> if no options are supplied, the result is rounded to the higher scale of the two numbers.
  ///   'precision' - if precision is supplied, the result is rounded to the precision significant digits.
  /// </param>

  leftValue = Number(leftValue);
  rightValue = Number(rightValue);

  if (isNaN(leftValue) || isNaN(rightValue)) {
    return NaN;
  }

  if (leftValue === 0) {
    return rightValue;
  }

  if (rightValue === 0) {
    return leftValue;
  }

  const options = roundingOptions || {};
  const result = leftValue + rightValue;

  if (options.precision) {
    return parseFloat(result.toPrecision(options.precision));
  }

  const scale = Math.max(getDecimalScale(leftValue), getDecimalScale(rightValue));
  if (scale < 1) {
    return result;
  }

  const factor = 10 ** scale;
  return Math.round(result * factor) / factor;
}

function getDecimalScale(decimal) {
  if (Math.floor(decimal) === decimal) {
    return 0;
  }

  const stringifiedDecimal = decimal.toString().split(".");
  return stringifiedDecimal[1] ? stringifiedDecimal[1].length : 0;
}

const api = {
  round10: function (value, exp) {
    return decimalAdjust("round", value, exp);
  },

  ceil10: function (value, exp) {
    return decimalAdjust("ceil", value, exp);
  },

  floor10: function (value, exp) {
    return decimalAdjust("floor", value, exp);
  },

  roundPrecision,
  truncateDecimal,
  floorOrCeilIntegerPart,
  add
};

module.exports = api;
plexExport("math", api);
