ï»¿const $ = require("jquery");
const onReady = require("../plex-handler-page").onReady;
const pubsub = require("../../Core/plex-pubsub");

const FORM_TWO_COLUMN_CUTOFF = 768 - $.getScrollBarSize().width;

onReady(() => {
  pubsub.subscribe("sideTabsMenuPageContainerContentWidthChanged", () => {
    const $form = $(".plex-form-content");
    const $pageContentContainer = $(".plex-sidetabs-menu-page-content-container");

    if ($form.length) {
      if ($pageContentContainer.outerWidth() >= FORM_TWO_COLUMN_CUTOFF) {
        $form.addClass("plex-form-content-two-columns");
      } else {
        $form.removeClass("plex-form-content-two-columns");
      }
    }
  });
});
