ï»¿const $ = require("jquery");
const ko = require("knockout");
const Feature = require("./plex-features");
const dataTokens = require("../Utilities/plex-utils-datatoken");
const dataUtils = require("../Utilities/plex-utils-data");
const jsUtils = require("../Utilities/plex-utils-js");
const DataProvider = require("../Data/plex-data-provider");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const pageHandler = require("../Controls/plex-handler-page");

require("../Knockout/knockout-semaphore"); // eslint-disable-line import/no-unassigned-import

function normalizeValue(value) {
  if (Array.isArray(value)) {
    return value.map(normalizeValue);
  }

  // the entire result might be contained within the boundValue
  if ("boundValue" in value && Object.keys(value).length === 1 && typeof value.boundValue === "object") {
    return value.boundValue;
  }

  return value;
}

const InitialValueFeature = Feature.extend({
  onInit: function () {
    // todo: i wonder if the init method itself should be tracking dependencies?
    ko.ignoreDependencies(() => this.setInitialValue());
  },

  setInitialValue: function () {
    const self = this;

    if (
      self.parent.savedState &&
      (Object.prototype.hasOwnProperty.call(self.parent.savedState, self.element.propertyName) ||
        Object.prototype.hasOwnProperty.call(self.parent.savedState, self.element.id))
    ) {
      return;
    }

    if (self.parent.hasSavedDefaults && !!self.parent.hasSavedDefaults()) {
      return;
    }

    let cleanupRequired = false;
    function cleanup() {
      if (cleanupRequired) {
        cleanupRequired = false;

        // this needs to happen after the initial value is set
        self.element.controller.isLoading(false);
      }
    }

    function resetInitialDisplayValue() {
      // only reset the initial display value when the page is in update mode
      const currentApplication = plexImport("currentApplication");
      if (currentApplication && currentApplication.Mode !== "update") {
        return;
      }

      // delay changing the initial display value to make sure that both
      // the displayValue and initialDisplayValue exist. Both properties are created
      // in onSetupRevisionTracking which occurs after onSetupFeatures
      jsUtils.defer(() => {
        if (self.element) {
          self.element.initialDisplayValue = self.element.displayValue();
        }
      });
    }

    function setPageState() {
      // we need to add initial values to state in VP screens
      if (self.element.controller && self.element.controller.excludeFromDefaults) {
        pageHandler.setState(self.parent.config.id, self.parent.getState());
      }
    }

    function setValue(initialValue) {
      let outputParams;
      if ("data" in initialValue && "outputParameters" in initialValue) {
        outputParams = initialValue.outputParameters;
        initialValue = initialValue.data;
      }

      if (dataUtils.isEmpty(initialValue) && $.isEmptyObject(outputParams)) {
        cleanup();
        return;
      }

      let resolvedValue = dataTokens.applyTokens(initialValue, self.config.tokens, outputParams);
      if (self.element.controller && "setValue" in self.element.controller) {
        resolvedValue = normalizeValue(resolvedValue);
        $.when(self.element.controller.setValue(resolvedValue))
          .then(resetInitialDisplayValue)
          .then(setPageState)
          // defer running cleanup until after value is processed
          .then(cleanup, cleanup);

        return;
      } else {
        if (!Array.isArray(resolvedValue)) {
          resolvedValue = [resolvedValue];
        }

        resolvedValue.forEach((value) => {
          for (const prop in value) {
            if (prop in self.element && Object.prototype.hasOwnProperty.call(value, prop) && value[prop] != null) {
              dataUtils.setValue(self.element, prop, value[prop]);
            }
          }
        });

        resetInitialDisplayValue();
      }

      setPageState();
      cleanup();
    }

    if (this.element.controller) {
      cleanupRequired = true;
      if (ko.isObservable(this.element.controller.isLoading)) {
        this.element.controller.isLoading(true);
      } else {
        this.element.controller.isLoading = ko.semaphore(true);
      }
    }

    // if the config has data, do not make a new request
    if (this.config.data) {
      setValue(this.config.data);
    } else if (this.config.dataProvider) {
      const dataProvider = new DataProvider(this.config.dataProvider, this.parent.data, { ignoreChanges: true });
      dataProvider.get().then(setValue).fail(cleanup); // cleanup whether successful or not
    } else if (this.config.tokens && this.config.tokens.length > 0) {
      // static values - will be resolved via tokens
      // need to defer in case element is dependent on another element
      // this will give it a chance to process
      jsUtils.defer(() => setValue({}));
    }
  }
});

module.exports = InitialValueFeature;
plexExport("features.InitialValue", InitialValueFeature);
