import Glossary from "./plex-glossary-handler";
import { format } from "../Utilities/plex-utils-strings";

// see https://plexdev.visualstudio.com/PlexSystems/_git/plex-react-components?path=%2Fsrc%2Fmessages.en-us.json&version=GBdevelop
const frameworkTerms = {
  "ui-common-fileUpload-fileSelect": "Choose File",
  "ui-common-fileUpload-noFileSelected": "No File Chosen",
  "ui-common-fileUpload-draggableArea": "Drag and drop file here",
  "ui-common-fileUpload-fileRemove": "Remove",
  "ui-common-fileUpload-fileUpload": "Upload",
  "ui-common-fileUpload-imageResizerWidgetSize": "Size",
  "ui-common-fileUpload-imageResizerWidgetZoomIn": "Zoom In",
  "ui-common-fileUpload-imageResizerWidgetZoomOut": "Zoom Out",
  "ui-common-filter-action-search": "Search",
  "ui-common-filter-action-saveDefaults": "Save As Default",
  "ui-common-filter-action-clearDefaults": "Clear Defaults",
  "ui-common-filter-action-showFilters": "Show all filters",
  "ui-common-filter-action-hideFilters": "Hide Filters",
  "ui-common-picker-info-more": ["{1} more", "{count, number}"],
  "ui-common-action-ok": "OK",
  "ui-common-action-apply": "Apply",
  "ui-common-action-cancel": "Cancel",
  "ui-common-action-previous": "Previous",
  "ui-common-action-next": "Next",
  "ui-common-action-finish": "Finished",
  "ui-common-datePicker-option-customRange": "(select custom range)",
  "ui-common-datePicker-heading-days": "Days",
  "ui-common-datePicker-heading-weeks": "Weeks",
  "ui-common-datePicker-heading-months": "Months",
  "ui-common-datePicker-heading-years": "Years",
  "ui-common-datePicker-heading-open": "Open",
  "ui-common-datePicker-option-today": "Today",
  "ui-common-datePicker-option-yesterday": "Yesterday",
  "ui-common-datePicker-option-currentWeek": "Current Week",
  "ui-common-datePicker-option-nextWeek": "Next Week",
  "ui-common-datePicker-option-previousWeek": "Previous Week",
  "ui-common-datePicker-option-previous2Weeks": "Previous 2 Weeks",
  "ui-common-datePicker-option-last7Days": "Last 7 Days",
  "ui-common-datePicker-option-monthToDate": "Month To Date",
  "ui-common-datePicker-option-currentMonth": "Current Month",
  "ui-common-datePicker-option-previousMonth": "Previous Month",
  "ui-common-datePicker-option-next30Days": "Next 30 Days",
  "ui-common-datePicker-option-last30Days": "Last 30 Days",
  "ui-common-datePicker-option-last60Days": "Last 60 Days",
  "ui-common-datePicker-option-last90Days": "Last 90 Days",
  "ui-common-datePicker-option-yearToDate": "Year To Date",
  "ui-common-datePicker-option-currentYear": "Current Year",
  "ui-common-datePicker-option-previousYear": "Previous Year",
  "ui-common-datePicker-option-last365Days": "Last 365 Days",
  "ui-common-datePicker-option-openStart": "Open Start Date",
  "ui-common-datePicker-option-openEnd": "Open End Date",
  "ui-common-iconPicker-heading-selectedIcon": "Selected Icon",
  "ui-common-iconPicker-heading-selectIcon": "Select Icon",
  "ui-common-iconPicker-heading-selectIconBackground": "Select Icon Background",
  "ui-common-iconPicker-title-dialog": "Icon Pick List",
  "ui-common-dataPicker-info-newSearch": "Type a new search",
  "ui-common-dataPicker-option-advancedSearch": "Advanced Search",
  "ui-common-dataPicker-option-searchAllWords": "All these words",
  "ui-common-dataPicker-option-searchExactWords": "Exact word or phrase",
  "ui-common-dataPicker-option-searchAnyWords": "Any of these words",
  "ui-common-dataPicker-option-searchNoneWords": "None of these words",
  "ui-common-dataPicker-option-searchStartsWith": "Starts with",
  "ui-common-dataPicker-option-searchEndsWith": "Ends with",
  "ui-common-dataPicker-option-searchWithin": "Search within Results",
  "ui-common-dataPicker-heading-searchIn": "Search In",
  "ui-common-dataPicker-info-refineSearch": "No records were found. Refine your search criteria and search again.",
  "ui-common-dataPicker-info-startSearch": "Type your search criteria above, then press Search to find matching items.",
  // this is a bit clunky, but should be OK for now.
  "ui-common-dataPicker-info-foundText": {
    keys: ["1 item found for: {1}", "{1} items found for: {2}"],
    format: (one, other) => `{count, plural, =1 {${format(one, "{input}")}} other {${format(other, "#", "{input}")}}}`
  },
  "ui-common-dataPicker-info-allItemsShown": "All items shown",
  "ui-common-dataPicker-info-selectSearchField": "Select a field to search by",
  "ui-common-dataPicker-heading-currentSelection": "Current selection",
  "ui-common-dataPicker-heading-selectFromItemsBelow": "Select from the items below",
  "ui-common-warn-recordLimitExceeded": [
    "Record limit has been exceeded. Only the first {1} records are displayed.",
    "{count, number}"
  ],
  "ui-common-actionBar-option-more": "More",
  "ui-common-dialog-title-question": "Question",
  "ui-common-dialog-title-notify": "Notification",
  "ui-common-imagePicker-heading-selectedImage": "Selected Image",
  "ui-common-imagePicker-heading-selectImage": "Select Image",
  "ui-common-imagePicker-title-dialog": "Image Pick List",
  "ui-common-mainMenu-title-rootGroupTitle": "Main Menu",
  "ui-common-textArea-showMore": "Show more",
  "ui-common-textArea-showLess": "Show less",
  "ui-common-wizard-title-page": "Page",
  "ui-common-wizard-title-step": "Step",
  "ui-common-filePicker-action-dropToUpload": "Drag and drop to add a new file.",
  "ui-common-filePicker-title-filesSelected": "Selected Files",
  "ui-common-filePicker-title-fileSelectionArea": "Select One or More Files",
  "ui-common-filePicker-title-fileSingleSelectionArea": "Select One",
  "ui-common-filePicker-title-dialog": "File Pick List",
  "ui-common-filePicker-title-uploadDialog": "Upload File",
  "ui-common-filePicker-info-foundText": {
    keys: ["1 item found for: {1}", "{1} items found for: {2}"],
    format: (one, other) => `{count, plural, =1 {${format(one, "{input}")}} other {${format(other, "#", "{input}")}}}`
  },
  "ui-common-filePicker-info-allItemsShown": "All items shown",
  "ui-common-progressBar-loading": "Loading...",
  "ui-common-progressBar-complete": "Complete!"
};

const expandPlexTerms = (parisMap, parisKey) => {
  const plexTerm = parisMap[parisKey];
  if (Array.isArray(plexTerm)) {
    return plexTerm[0];
  }

  if (typeof plexTerm === "object") {
    return plexTerm.keys;
  }

  return plexTerm;
};

export const loadTerms = (terms) => {
  // flatten plex terms
  const plexTerms = [].concat(...Object.keys(terms).map((key) => expandPlexTerms(terms, key)));

  return Glossary.getCustomerWordBulkAsync(plexTerms).then((map) => {
    const loaded = {};

    Object.keys(terms).forEach((parisKey) => {
      const plexTerm = terms[parisKey];
      if (Array.isArray(plexTerm)) {
        loaded[parisKey] = format(map[plexTerm[0]], plexTerm.slice(1));
      } else if (typeof plexTerm === "object") {
        loaded[parisKey] = plexTerm.format(...plexTerm.keys.map((key) => map[key]));
      } else {
        loaded[parisKey] = map[plexTerm];
      }
    });

    return loaded;
  });
};

export const loadFrameworkTerms = () => loadTerms(frameworkTerms);
