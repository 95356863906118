ï»¿const $ = require("jquery");
const parsing = require("./plex-parsing-url");
const pubsub = require("./plex-pubsub");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

let sessionTimer = null;
let keepAliveInterval = null;
let keepAliveTimer = null;
const defaultTimeout = 85 * 60 * 1000; // 85 minutes
const appStateTokenName = "__asid";
let currentAppStateID = null;
const sessionLatency = 60000; // 60 seconds
let sessionExpired = false;

let lastActivityTimestamp = Date.now();

function refreshLastActivityTime() {
  /// <summary>
  /// Records the current timestamp as the last known activity that should have extended the UX session lifetime.
  /// </summary>

  lastActivityTimestamp = Date.now();
}

function setSessionTimeout(expirationMilliseconds) {
  /// <summary>
  /// Sets the session timeout in milliseconds. When the timeout is nearing
  /// an ajax call will be triggered which will verify whether the session
  /// is expired and either trigger the expiration on the client or reset
  /// the expiration based on the results.
  /// </summary>
  /// <param name="expirationMilliseconds">The timespan to expiration in milliseconds.</param>

  const currentApplication = plexImport("currentApplication");
  if (currentApplication && currentApplication.KeepSessionAlive) {
    const timeLimitMilliseconds = currentApplication.SessionTimeLimitInMinutes * 60 * 1000;
    if (timeLimitMilliseconds === 0) {
      // refresh timeout every 85 minutes
      keepAliveInterval = setInterval(extendSession, defaultTimeout);
    } else if (timeLimitMilliseconds > defaultTimeout) {
      // refresh timeout every 85 minutes
      keepAliveInterval = setInterval(extendSession, defaultTimeout);
      // stop after time limit
      setTimeout(clearKeepAlive, getAdjustedTimeLimit(timeLimitMilliseconds) + 60 * 1000);
    } else if (timeLimitMilliseconds > 0 && timeLimitMilliseconds < defaultTimeout) {
      // refresh timeout before time limit
      keepAliveInterval = setInterval(extendSession, timeLimitMilliseconds);
      // stop after time limit
      setTimeout(clearKeepAlive, timeLimitMilliseconds + 60 * 1000);
    }

    if (expirationMilliseconds < defaultTimeout || expirationMilliseconds < timeLimitMilliseconds) {
      expirationMilliseconds = Math.max(sessionLatency, expirationMilliseconds - sessionLatency);
      keepAliveTimer = setTimeout(extendSession, expirationMilliseconds);
    }
  } else {
    clearTimeout(sessionTimer);
    if (expirationMilliseconds > 0) {
      expirationMilliseconds = Math.max(sessionLatency, expirationMilliseconds - sessionLatency);
      sessionTimer = setTimeout(checkSession, expirationMilliseconds);
    }
  }
}

function extendSession() {
  $.ajax("/Platform/Session/ExtendSession").done((result) => {
    // if we extended it once, clear
    clearTimeout(keepAliveTimer);

    refreshLastActivityTime();

    if (!result.validSession) {
      expireSession();
    }
  });
}

function checkSession() {
  $.ajax(`/Platform/Session/CheckSession?lastActivityTimestamp=${lastActivityTimestamp}`).done((result) => {
    if (!result.validSession) {
      expireSession();
      return;
    }

    if (result.expirationMilliseconds) {
      setSessionTimeout(result.expirationMilliseconds);
    }
  });
}

function expireSession() {
  /// <summary>Marks the session as expired and invokes the `session.expired` subscription.</summary>

  if (!sessionExpired) {
    sessionExpired = true;
    clearTimeout(sessionTimer);
    pubsub.publish("session.expired");
  }
}

function getAppStateID() {
  /// <summary>Gets the current app state ID.</summary>
  /// <returns type="String">The current app state ID.</returns>

  return currentAppStateID || (currentAppStateID = parsing.parseQueryString()[appStateTokenName]);
}

function getAdjustedTimeLimit(timeLimitMilliseconds) {
  // adjust so if 240 min it will be 255, or keepAliveInterval will be cleared before 240
  return defaultTimeout * Math.ceil(timeLimitMilliseconds / defaultTimeout);
}

function clearKeepAlive() {
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval);
    clearTimeout(keepAliveTimer);
    plexImport("currentApplication").KeepSessionAlive = false;
    // call checkSession to get new expirationMilliseconds
    checkSession();
  }
}

function hasSession() {
  const appState = plexImport("appState");
  return !!(appState && appState.id);
}

const api = {
  appStateTokenName,
  expireSession,
  getAppStateID,
  hasSession,
  refreshLastActivityTime,
  setSessionTimeout
};

module.exports = api;
plexExport("sessionManager", api);
