ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const dateTimeStateMachine = require("./plex-datetime-statemachine");
const dateUtils = require("../Core/plex-dates");
const CustomerDate = require("../Globalization/plex-customer-date");
const culture = require("../Globalization/plex-culture-datetime");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

const defaultOptions = {
  primaryState: dateTimeStateMachine.PrimaryStates.Day
};

function getDaysModel(date) {
  const firstDayOfWeek = dateUtils.getFirstDayOfWeek(new CustomerDate(date.getFullYear(), date.getMonth(), 0));
  let currentDate = new CustomerDate(firstDayOfWeek);

  const daysModel = [];
  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      week.push(currentDate);
      currentDate = new CustomerDate(dateUtils.addDays(currentDate, 1));
    }

    daysModel.push(week);
  }

  return daysModel;
}

function getDecadeViewModel(date) {
  const year = date.getFullYear();
  const decadeStart = year - (year % 10);
  return ko.utils.range(decadeStart - 1, decadeStart + 10);
}

function createRange(min, max, step) {
  step = step || 1;
  const result = [];

  for (let i = min; i <= max; i += step) {
    result.push(i);
  }

  return result;
}

function DateTimeModel(date, options) {
  if (arguments.length === 3) {
    logger.warn("Culture argument for DateTimeModel is obsolete.");
    return new DateTimeModel(date, arguments[2]);
  }

  this.options = $.extend({}, defaultOptions, options);

  if (date == null) {
    date = new CustomerDate();
  }

  if (this.options.primaryState === dateTimeStateMachine.PrimaryStates.Time) {
    date = this.getTime(date);
  }

  const self = this;
  self.displayDate = ko.observable(new CustomerDate(date.getFullYear(), date.getMonth(), 1));
  self.selectedDate = ko.observable(null);
  self.selectedDate.subscribe(onSelectedDateChange);
  self.monthsViewModel = culture.getMonthNames("abbreviated");
  self.states = dateTimeStateMachine.DateTimeState;
  self.primaryStates = dateTimeStateMachine.PrimaryStates;
  self.stateMachine = new dateTimeStateMachine.StateMachine(this.options);

  const periods = self.options.militaryTime || culture.uses24HourCycle() ? [] : culture.getDayPeriods();
  self.astronomicalTime = periods.length === 0;
  self.hoursViewModel = self.astronomicalTime ? createRange(0, 23) : createRange(1, 12);
  self.minutesViewModel = createRange(0, 59, self.options.minuteIncrement);
  self.secondsViewModel = createRange(0, 59);
  self.millisecondsViewModel = createRange(0, 999);
  self.timePeriodModel = self.astronomicalTime ? null : [periods[0], periods[1]];

  self.selectedTimePeriod = ko.observable(null);
  self.selectedHour = ko.observable(null);
  self.selectedMinute = ko.observable(null);
  self.selectedSecond = ko.observable(0);
  self.selectedMillisecond = ko.observable(0);

  self.daysViewModel = ko.pureComputed(() => {
    return getDaysModel(self.displayDate());
  });

  self.displayHeaderText = ko.pureComputed(() => {
    return self.stateMachine.currentState().header(self.displayDate(), self.options.dateTokenString);
  });

  self.displayNextHeaderText = ko.pureComputed(() => {
    return self.stateMachine.currentState().nextHeader(self.displayDate());
  });

  self.displayPreviousHeaderText = ko.pureComputed(() => {
    return self.stateMachine.currentState().previousHeader(self.displayDate());
  });

  self.decadeViewModel = ko.pureComputed(() => {
    return getDecadeViewModel(self.displayDate());
  });

  self.reset(date);

  function onSelectedDateChange(newDate) {
    self.displayDate(new CustomerDate(newDate.getFullYear(), newDate.getMonth(), 1));
  }
}

DateTimeModel.prototype.reset = function (date) {
  this.stateMachine.setState();
  this.selectedDate(date);
  this.setTime(date);
};

DateTimeModel.prototype.setTime = function (date) {
  if (!date) {
    return;
  }

  const selectedTimePeriod = date.getHours() < 12 ? 0 : 1;
  let selectedHour =
    this.astronomicalTime === false && selectedTimePeriod === 1 ? date.getHours() % 12 : date.getHours();
  if (!this.astronomicalTime && selectedHour === 0) {
    selectedHour = 12;
  }

  this.selectedTimePeriod(selectedTimePeriod);
  this.selectedHour(selectedHour);
  this.selectedMinute(date.getMinutes());
  this.selectedSecond(date.getSeconds());
  this.selectedMillisecond(date.getMilliseconds());
};

DateTimeModel.prototype.getTime = function (dateWithTime) {
  return new CustomerDate(
    1900,
    0,
    1,
    dateWithTime.getHours(),
    dateWithTime.getMinutes(),
    dateWithTime.getSeconds(),
    dateWithTime.getMilliseconds()
  );
};

DateTimeModel.prototype.getDateWithTime = function () {
  const selectedDate = this.selectedDate();
  const selectedHour = Number(this.selectedHour());
  let hour = selectedHour;
  const minute = Number(this.selectedMinute());
  const second = Number(this.selectedSecond());
  const millisecond = Number(this.selectedMillisecond());
  if (!this.astronomicalTime) {
    hour = selectedHour % 12;
    if (Number(this.selectedTimePeriod()) === 1) {
      hour += 12;
    }
  }

  return new CustomerDate(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    hour,
    minute,
    second,
    millisecond
  );
};

DateTimeModel.prototype.selectDay = function (date) {
  this.selectedDate(new CustomerDate(date.getFullYear(), date.getMonth(), date.getDate()));
};

DateTimeModel.prototype.selectMonth = function (month) {
  const oldDisplayDate = this.displayDate();

  if (this.options.primaryState === this.primaryStates.Month) {
    this.displayDate(new CustomerDate(oldDisplayDate.getFullYear(), month, 1));
    this.selectedDate(
      new CustomerDate(this.displayDate().getFullYear(), this.displayDate().getMonth(), this.displayDate().getDate())
    );
  } else {
    this.displayDate(new CustomerDate(oldDisplayDate.getFullYear(), month, oldDisplayDate.getDate()));
    this.stateMachine.nextState();
  }
};

DateTimeModel.prototype.selectYear = function (year) {
  const oldDisplayDate = this.displayDate();
  this.displayDate(new CustomerDate(year, oldDisplayDate.getMonth(), oldDisplayDate.getDate()));
  this.stateMachine.nextState();
};

DateTimeModel.prototype.previous = function () {
  this.displayDate(this.stateMachine.currentState().previous(this.displayDate()));
};

DateTimeModel.prototype.next = function () {
  this.displayDate(this.stateMachine.currentState().next(this.displayDate()));
};

DateTimeModel.prototype.yearInDecade = function (year) {
  const displayYear = this.displayDate().getFullYear();
  const decadeStart = displayYear - (displayYear % 10);
  return decadeStart <= year && year <= decadeStart + 9;
};

DateTimeModel.prototype.compareMonthIndex = function (month) {
  const displayYear = this.displayDate().getFullYear();
  const selectedYear = this.selectedDate().getFullYear();
  if (displayYear === selectedYear) {
    return month - this.selectedDate().getMonth();
  }

  return 1;
};

module.exports = DateTimeModel;
plexExport("dateTimeModel", DateTimeModel);
