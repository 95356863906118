ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const logger = require("../Core/plex-logger");
const printing = require("../Core/plex-printing");
const xmlProviderFactory = require("../Printing/XmlProviders/plex-printing-xml-provider-factory");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const DocumentXml = function (node) {
  this._$node = $("<" + node + " />");
};

DocumentXml.prototype = {
  constructor: DocumentXml,

  addElement: function (name, value, attr) {
    const element = new DocumentXml(name);
    element.setValue(value);
    element.addAttribute(attr);

    this._$node.append(element._$node);
    return this;
  },

  appendXml: function (xml) {
    this._$node.append(xml);
    return this;
  },

  createNode: function (name, attr) {
    const node = new DocumentXml(name);
    node.addAttribute(attr);

    this._$node.append(node._$node);
    return node;
  },

  setValue: function (value) {
    this._$node.text(typeof value === "undefined" || value === null ? "" : value);
    return this;
  },

  addControlElement: function (element) {
    const self = this;
    let checkbox, composite, repeater, $table, ctlr, $image, imageNode, node, xmlProvider;

    // Use == for visible to coerce 0 to false
    if (element === undefined || !printing.shouldPrint(element)) {
      return self;
    }

    const name = formatControlName(element.viewName);
    const attr = self._getElementAttributes(element);
    const currentPage = plexImport("currentPage");

    switch (printing.resolveViewName(element.viewName)) {
      case "endorseline":
        self.createNode(name);
        break;
      case "radiobutton":
        self
          .createNode(name)
          .addElement("checked", !!(element.initialDisplayValue && element.initialDisplayValue !== ""));
        break;
      case "tree":
        self.appendXml(element.controller.toXml());
        break;
      case "checkbox":
        checkbox = self
          .createNode(name)
          .addElement("checked", !!(element.initialDisplayValue && element.initialDisplayValue !== ""));

        if (element.suffixLabel && element.suffixLabel.text) {
          checkbox.addElement("suffix-label", element.suffixLabel.text.peek());
        }

        break;
      case "compositeelement":
        composite = self.createNode(name, attr);
        printing.filterElements(element.elements).forEach((compositeElement) => {
          composite.addControlElement(compositeElement);
        });
        break;
      case "compositeelementlist":
        composite = self.createNode(name, attr);

        printing.filterElements(element.elements).forEach((compositeElement) => {
          composite
            .createNode("element-list-item")
            .addAttribute("alignment", element.alignment)
            .addControlElement(compositeElement);
        });
        break;
      case "":
      case "rangeelement":
      case "aside":
        printing.filterElements(element.elements).forEach((compositeElement) => {
          self.addControlElement(compositeElement);
        });

        break;
      case "elementrepeater":
        repeater = self.createNode(name).addAttribute("alignment", element.alignment);

        printing.filterElements(element.elementRows()).forEach((row) => {
          node = repeater.createNode("element-row");
          printing.filterElements(row.options.elements).forEach((el) => {
            node.addControlElement(el);
          });
        });

        break;
      case "readonlygrid":
        $table = $("#" + element.id);
        if ($table.length) {
          self.createNode("plex-readonlygrid").addTableElement($table, null, currentPage[element.id]);
        }

        break;
      case "mastergrid":
        ctlr = currentPage[element.id];
        if (ctlr && ctlr.grid && "toDocumentXml" in ctlr.grid) {
          self.createNode(name).appendXml(ctlr.grid.toDocumentXml());
        }

        break;
      case "image":
        $image = $("#" + element.id);
        if ($image.length) {
          imageNode = self.createNode(name);
          imageNode.addAttribute({
            src: $image[0].src,
            contentheight: convertPixelUnit($image.height()) + "mm",
            contentwidth: convertPixelUnit($image.width()) + "mm"
          });

          if (element.filePath && element.initialDisplayValue) {
            imageNode.addAttribute("imagepath", element.filePath + element.initialDisplayValue);
          }
        }

        break;
      case "htmlliteral":
        if (element.html) {
          self.addElement(name, element.html, attr);
        }

        break;
      case "compositeformrow":
        node = self.createNode("plex-composite-form-row");
        if (element.elements && element.elements.length > 0) {
          element.elements.forEach($.proxy(node.addControlElement, node));
        }

        break;
      case "datetimeliteral":
        self.addElement(name, ko.unwrap(element.formattedValue));
        break;
      default:
        // todo: convert the above cases to xml providers
        xmlProvider = xmlProviderFactory.create(element);
        if (xmlProvider) {
          self.appendXml(xmlProvider.getXml());
          break;
        }

        logger.warn("Unable to serialize: " + element.viewName);
    }

    return self;
  },

  addTableElement: function ($table, columns, controller) {
    const self = this;
    let body, trnode, $tr, $td, header, footer, printBreak;

    columns = columns || [];

    const table = self.createNode("plex-grid-table");

    const $thead = $table.children("thead:visible").first();
    if ($thead.length) {
      header = table.createNode("grid-table-header");
      $thead.children("tr").each(function () {
        trnode = header.createNode("grid-table-row");
        $(this)
          .children("th")
          .each(function () {
            trnode.addCellElement($(this), null, controller);
          });
      });
    }

    $table.children("tbody").each(function () {
      body = table.createNode("grid-table-body");
      $(this)
        .children("tr")
        .each(function () {
          trnode = body.createNode("grid-table-row");
          $tr = $(this);

          printBreak = $tr.data("print-break");
          if (printBreak) {
            trnode.addAttribute(printBreak.attribute, printBreak.condition);
          }

          $tr.children("th,td").each(function () {
            $td = $(this);
            trnode.addCellElement($td, getCellElements(columns, $tr, $td), controller);
          });
        });
    });

    const $tfoot = $table.children("tfoot:visible").first();
    if ($tfoot.length) {
      footer = table.createNode("grid-table-footer");
      $tfoot.children("tr").each(function () {
        trnode = footer.createNode("grid-table-row");
        $tr = $(this);

        $tr.children("td").each(function () {
          $td = $(this);
          trnode.addCellElement($td, getCellElements(columns, $tr, $td), controller);
        });
      });
    }

    return self;
  },

  addCellElement: function ($element, cellElements, controller) {
    let sortOrder, level;

    const cell = this.createNode("grid-table-cell");

    if (cellElements && cellElements.length > 0) {
      cellElements.forEach((el) => {
        cell.addControlElement(el);
      });
    }
    // Check if cell has a checkmark
    else if ($(".plex-icon-checkmark3", $element).length) {
      cell.appendXml($("<checkmark />"));
    } else if ($("img", $element).length) {
      cell.appendXml(getElementText($element, controller));
    } else {
      cell._$node.text(getElementText($element, controller));
    }

    // set attributes
    if ($element.hasClass("plex-grid-cell-suppressed")) {
      cell.addAttribute("suppressed", true);
    }

    // sort indicators
    const $sortIndicator = $element.children(".plex-direction-indicator:visible:first");
    if ($sortIndicator.length) {
      cell.addAttribute("sort-dir", $sortIndicator.is(".asc") ? "asc" : "desc");

      // sort order
      sortOrder = $sortIndicator.next(".plex-sort-order:visible");
      if (sortOrder.length) {
        cell.addAttribute("sort-order", sortOrder.text());
      }
    }

    const align = $element.css("text-align");
    if (align !== "left") {
      cell.addAttribute("text-align", align);
    }

    const colspan = $element.attr("colspan");
    if (colspan && parseInt(colspan, 10) > 1) {
      cell.addAttribute("colspan", colspan);
    }

    const rowspan = $element.attr("rowspan");
    if (rowspan && parseInt(rowspan, 10) > 1) {
      cell.addAttribute("rowspan", rowspan);
    }

    const bgcolor = $element[0].style.backgroundColor;
    if (bgcolor) {
      cell.addAttribute("background-color", bgcolor);
    }

    if ($element.hasClass("plex-grid-row-odd")) {
      cell.addAttribute("row-style", "odd");
    } else if ($element.hasClass("plex-grid-row-even")) {
      cell.addAttribute("row-style", "even");
    }

    level = parseInt($element.data("indentation-level"), 10);
    if (level) {
      level *= 10;
      cell.addAttribute("indentation-level", level + "px");
    }

    if ($element.hasClass("plex-grid-column-group") || $element.hasClass("plex-grid-column-group-border-left")) {
      cell.addAttribute("border-left", "0.5pt solid #ddd");
    }

    if ($element.hasClass("plex-grid-column-group") || $element.hasClass("plex-grid-column-group-border-right")) {
      cell.addAttribute("border-right", "0.5pt solid #ddd");
    }

    const color = $element[0].style.color;
    if (color) {
      cell.addAttribute("color", color);
    }

    return self;
  },

  serialize: function () {
    return this._$node[0].outerHTML;
  },

  addAttribute: function (key, value) {
    let prop, name;
    if (typeof key === "object") {
      for (prop in key) {
        if (Object.prototype.hasOwnProperty.call(key, prop)) {
          this.addAttribute(prop, key[prop]);
        }
      }
    } else if (value !== undefined) {
      // escape any invalid attribute name characters
      name = key.match(/[a-zA-Z_:][-a-zA-Z0-9_:.]*/gi).join("");
      this._$node.attr(name, value);
    }

    return this;
  },

  hyphenateAttributeName: function (value) {
    return value.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  },

  _getElementAttributes: function (element) {
    const attr = {};
    const viewName = printing.resolveViewName(element.viewName);
    const self = this;

    if (
      ["richtexteditor", "htmlliteral"].indexOf(viewName) >= 0 ||
      (viewName === "datalabel" && element.printLabelAsHtml)
    ) {
      attr.richtext = true;
    }

    if (element.printBreak) {
      attr[element.printBreak.attribute] = element.printBreak.condition;
    }

    if (element.style && element.style()) {
      $.each(element.style(), (key, prop) => {
        const styleVal = self.hyphenateAttributeName(key) + ":" + prop + ";";
        attr.style = attr.style ? attr.style + styleVal : styleVal;
      });
    }

    return attr;
  }
};

function formatControlName(viewname) {
  return "plex-control-" + printing.resolveViewName(viewname).replace(" ", "");
}

function getElementText($el, controller) {
  let $node;
  return $el
    .contents()
    .toArray()
    .filter((node) => {
      if (
        controller &&
        controller.elements &&
        controller.elements[node.id] &&
        controller.elements[node.id].printVisible === false
      ) {
        return false;
      }

      // text node and has a value
      if (node.nodeType === 3 && node.nodeValue.trim().length > 0) {
        return true;
      }

      // visible elements
      if (node.nodeType === 1 && node.style.display !== "none") {
        $node = $(node);
        return $node.length && !($node.hasClass("plex-sort-order") || $node.hasClass("plex-direction-indicator"));
      }

      return false;
    })
    .map((node) => {
      return getNodeValue(node, controller);
    })
    .join(" ");
}

function getNodeValue(node, controller) {
  if (node.nodeType === 3) {
    return node.nodeValue.trim();
  }

  if (/^br$/i.test(node.nodeName)) {
    return "\n";
  }

  if (/IMG/.test(node.nodeName)) {
    return new DocumentXml("plex-control-image").addAttribute("src", node.src).serialize();
  }

  return getElementText($(node), controller);
}

function getCellElements(columns, $row, $cell) {
  let cellElements;
  const column = columns[$cell.data("col-index")];
  if (column && column.elementCollection) {
    cellElements = column.elementCollection[$row.data("index")];
  }

  return cellElements || undefined;
}

function convertPixelUnit(value) {
  return parseInt(value / 3.779527559, 10);
}

module.exports = DocumentXml;
plexExport("utils.DocumentXml", DocumentXml);
