const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const DataTransformFactory = {
  create: function (config, parent) {
    /// <summary>Creates the Data Transform based on the provided configuration.</summary>
    /// <param name="config">The Data Transform configuration.</param>
    /// <param name="parent">The parent.</param>
    /// <returns type="Object">The Data Transform instance.</returns>

    const root = plexImport("data.transforms");
    if (!(config.name in root)) {
      throw new Error("Data Transform not found: " + config.name);
    }

    const Ctor = root[config.name];
    const dataTransform = new Ctor(config);

    dataTransform.config = config;
    dataTransform.parent = parent;
    dataTransform.init();

    return dataTransform;
  }
};

module.exports = DataTransformFactory;
plexExport("data.transforms.DataTransformFactory", DataTransformFactory);
