ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");

// override enforeFocus prototype
// IE: Drop-downs closed immediately after show in a Bootstrap Modal
// http://dev.ckeditor.com/ticket/12525
if ($.fn.modal && $.fn.modal.Constructor) {
  $.fn.modal.Constructor.prototype.enforceFocus = function () {
    $(document)
      .off("focusin.bs.modal") // guard against infinite focus loop
      .on(
        "focusin.bs.modal",
        $.proxy(function (e) {
          if (
            this.$element[0] !== e.target &&
            !this.$element.has(e.target).length &&
            $(e.target).parentsUntil('*[role="dialog"]').length === 0
          ) {
            this.$element.focus();
          }
        }, this)
      );
  };
}
