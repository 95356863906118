ï»¿const ko = require("knockout");
const $ = require("jquery");

// todo: see if this can be merged with popover binding (which seems to be more targeted towards menus) but has similar characteristics
ko.bindingHandlers.modeless = {
  update: function (element, valueAccessor) {
    const options = ko.toJS(valueAccessor());
    if (options.trigger === false) {
      return;
    }

    // add slight delay to let content size before positioning
    setTimeout(() => {
      // use toJS so all options are unwrapped
      $(element).modeless(options);
    }, 4);
  }
};
