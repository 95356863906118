ï»¿const ko = require("knockout");
const $ = require("jquery");
const domUtils = require("../Utilities/plex-utils-dom");
const dialogController = require("../Dialogs/plex-dialog-controller");
const FileUploadHandlerFactory = require("../Uploads/plex-upload-file-handler-factory");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const FileUploadController = function () {
  // constructor
};

FileUploadController.prototype = {
  init: function (el, config) {
    const self = this;
    self.config = config;
    self.$element = $(el);
    if (domUtils.isInDialog(el)) {
      self.$dialog = dialogController.find(el);
    }

    // needed for manual submits and previews
    self.uploadData = null;
    self.results = [];
    self.uploadDataCollection = [];
    self.previews = ko.observableArray();
    self.progress = ko.observable();
    self.uploadHandler = FileUploadHandlerFactory.create(config, self);

    self.$element.fileupload({
      start: function () {
        self.progress(0);
        self.uploadHandler.onStart();
      },
      stop: function () {
        self.progress("hide");
      },
      add: function (e, data) {
        // form is not populated when performing drag/drop
        data.form = data.form || $(self.$element.prop("form"));
        self.uploadHandler.onAdd(e, data);
      },
      done: function (e, data) {
        self.uploadHandler.onDone(e, data);
      },
      fail: function (e, data) {
        self.uploadHandler.onFail(e, data);
        if (self.uploadData) {
          self.uploadDataCollection.unshift(self.uploadData);
          self.uploadData = null;
        }
      },
      always: function (e, data) {
        self.uploadHandler.onAlways(e, data);
      },
      progressall: function (e, data) {
        self.progress(parseInt((data.loaded / data.total) * 100, 10));
        self.uploadHandler.onProgressAll(e, data);
      },
      dragenter: function (e) {
        self.uploadHandler.onDragEnter(e);
      },
      dragleave: function (e) {
        self.uploadHandler.onDragLeave(e);
      },
      drop: function (e, data) {
        self.uploadHandler.onDrop(e, data);
      }
    });

    ko.applyBindings(self, $("#" + self.config.id + "_FileUploadContent")[0]);

    plexImport("currentPage")[self.config.id] = this;
  },

  updateFileName: function (element, filenameId) {
    if (element.length > 0) {
      if (element[0].files.length > 0) {
        $("#" + filenameId)[0].innerHTML = element[0].files.item(0).name;
      }
    }
  },

  // Manually submit files that have preview mode like in case of images
  submitData: function () {
    this.uploadData = null;
    this.results = [];
    this.submitNext();
  },

  submitNext: function (results) {
    const self = this;
    if (self.uploadData && results) {
      // all files in the batch are processed, add results to all results
      self.results = self.results.concat(results);
      if (self.uploadHandler.failed) {
        // stop uploading next batch of files if the upload failed on one or more files
        self.uploadDataCollection.unshift(self.uploadData);
        self.uploadHandler.onUploadFailed(self.results);
        return;
      }
    }

    if (self.uploadDataCollection.length > 0) {
      // upload next batch of files
      $(document).block();
      self.uploadData = self.uploadDataCollection.shift();
      self.uploadData.url = self.uploadHandler.getRouteUrl(self.uploadData);
      self.uploadData.submit();
    } else if (self.uploadData) {
      // last batch of files uploaded, complete the upload
      self.uploadData = null;
      self.uploadHandler.onUploadComplete(self.results);
    }
  }
};

FileUploadController.create = function () {
  return new FileUploadController();
};

module.exports = FileUploadController;
plexExport("FileUploadController", FileUploadController);
