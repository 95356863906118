ï»¿const ko = require("knockout");
const expressions = require("../../Expressions/plex-expressions-compiler");
const repository = require("../../Controls/plex-model-repository");
const dataUtils = require("../../Utilities/plex-utils-data");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

function updateProperty(obj, propertyName, value) {
  if (ko.isObservable(obj[propertyName])) {
    obj[propertyName](value);
  } else {
    obj[propertyName] = value;
  }
}

const ColumnHeaderFeature = Feature.extend({
  onInit: function () {
    this.headerTextEvaluator = expressions.compile(this.config.headerTextExpression);
  },

  onExecute: function (record) {
    const data = this.config.headerSourceId ? repository.get(this.config.headerSourceId) : record;
    const headerText = this.headerTextEvaluator(dataUtils.cleanse(data, { ignoreEmpty: false }));

    updateProperty(this.column, "shortHeaderName", headerText);
    updateProperty(this.column, "headerName", headerText);
  }
});

module.exports = ColumnHeaderFeature;
plexExport("grid.features.ColumnHeader", ColumnHeaderFeature);
