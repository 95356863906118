ï»¿const stringUtils = require("../../Utilities/plex-utils-strings");
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const GlossarizedText = defaultValueProvider.extend({
  getValue: function (record) {
    if (this.column.tokens && this.column.tokens.length > 0) {
      const tokens = this.column.tokens.map((token) => {
        return record[token.propertyName];
      });

      return stringUtils.format(this.column.text, tokens);
    }

    return this.column.text;
  }
});

module.exports = GlossarizedText;
plexExport("grid.valueProviders.GlossarizedText", GlossarizedText);
