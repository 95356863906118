ï»¿const ko = require("knockout");
const RowConfig = require("../plex-grid-row-config");
const PrintWriter = require("./plex-grid-print-writer-base");
const writerFactory = require("../plex-grid-writer-factory");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");

const GridPrintWriter = PrintWriter.extend({
  init: function (grid) {
    const self = this;
    this._base.apply(this, arguments);

    this.options = grid.options || {};

    this.rows = grid.options.rows;
    this.rows.forEach((layout) => {
      layout.printWriter = writerFactory.create(
        layout.config.rowPrintWriterProvider || layout.config.printWriterProvider,
        grid,
        layout
      );
      if (layout.primary) {
        self.primaryLayout = layout;
      }
    });

    this.headerRowWriter = writerFactory.create(this.options.headerRowPrintWriterProvider, this.grid, this.options);

    this.footers = this.options.footers;
    if (this.footers && this.footers.length > 0) {
      this.footers.forEach((footer) => {
        footer.printWriter = writerFactory.create(footer.printWriterProvider, self.grid, self.options, footer);
      });
    }

    if (grid.options.gridFeatures && grid.options.gridFeatures.length > 0) {
      this.featureProcessor = new FeatureProcessor(grid.options.gridFeatures, grid.options, grid);
    }

    this.primaryLayout.reset();
    this.rows.forEach((layout) => {
      if (layout !== self.primaryLayout) {
        layout.reset();
      }
    });
  },

  getNodeName: function () {
    return "plex-grid-table";
  },

  setTableColumnsWidth: function () {
    const gridState = this.grid.controller.getState();
    if (!gridState || !gridState.isResized) {
      return;
    }

    const gridColumns = ko.unwrap(this.grid.controller.columns);
    const printColumns = gridColumns.filter((col) => col.printVisible());

    const restoredColumns = gridState.columnsWidth.reduce((acc, item) => {
      acc[item.columnId] = item;
      return acc;
    }, {});

    let printOnlyColumnCount = 0;
    let totalWidth = printColumns.reduce((width, col) => {
      const columnId = this.grid.controller.getStateColumnId(col);
      const restoredColumn = restoredColumns[columnId];

      if (restoredColumn) {
        return width + restoredColumn.width;
      }

      printOnlyColumnCount++;
      return width;
    }, 0);

    if (totalWidth === 0) {
      // account for edge case where there are only print-only columns
      return;
    }

    let unassignedWidth = 0;
    if (printOnlyColumnCount > 0) {
      // this is very unscientific, but for now if there are print-only
      // columns make their width equal to the average width.
      // there may be a better way to handle this scenario...
      unassignedWidth = totalWidth / printColumns.length;
      totalWidth += unassignedWidth * printOnlyColumnCount;
    }

    printColumns.forEach((col) => {
      const columnId = this.grid.controller.getStateColumnId(col);
      const restoredColumn = restoredColumns[columnId];
      const columnWidth = restoredColumn ? restoredColumn.width : unassignedWidth;

      const widthNode = this.node.createNode("grid-table-column-width");
      widthNode.setValue((columnWidth * 100) / totalWidth + "%");
    });
  },

  print: function (data) {
    const source = this.grid.getData();

    if (this.grid.controller.config.virtualizeRows !== false) {
      this.setTableColumnsWidth();
    }

    this.printHeader(data, source);
    this.printBody(data, source);

    return this.toPrintXml();
  },

  printHeader: function (data) {
    if (this.headerRowWriter.prePrint() !== false) {
      this.node.appendXml(this.headerRowWriter.print(data));
    }
  },

  printBody: function (data, source) {
    const self = this;
    let i = 0;

    const body = this.node.createNode("grid-table-body");
    for (; i < data.length; i++) {
      self.printRow(body, data[i], i, source);
    }

    this.printFooter(source);
  },

  printRow: function (node, record, index, data, indexInGroup, group) {
    const config = new RowConfig(record, index, data, indexInGroup, group, this.options, this.rowWriter);
    this.rows.forEach((layout, rowIndex) => {
      config.rowIndex = rowIndex;
      if (layout.printWriter.prePrint(record, index, data, config, indexInGroup, group) !== false) {
        node.appendXml(layout.printWriter.print(record, index, data, config, indexInGroup, group));
      }
    });
  },

  printFooter: function (data, config) {
    if (!this.footers || this.footers.length === 0) {
      return;
    }

    const node = this.node.createNode("grid-table-footer");
    this.footers.forEach((footer) => {
      let firstRecord = {};
      const records = data.source || data;
      if (records && records.length > 0) {
        firstRecord = records[0];
      }

      if (footer.printWriter.prePrint(firstRecord, 0, records) !== false) {
        node.appendXml(footer.printWriter.print(data, config || {}, 0));
      }
    });
  }
});

module.exports = GridPrintWriter;
plexExport("grid.writers.GridPrintWriter", GridPrintWriter);
