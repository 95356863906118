ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ConstantToken = require("./plex-tokens-constant");
const Token = require("./plex-tokens-token-base");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const compileCache = {};
const returnNull = function () {
  return null;
};

function TokenParser() {
  // constructor
}

TokenParser.prototype = {
  constructor: TokenParser,

  compile: function (textOrTokens) {
    if (!textOrTokens) {
      return returnNull;
    }

    let text = textOrTokens;
    let tokens;

    // allow tokens to be passed in directly
    if (textOrTokens instanceof TokenCollection) {
      tokens = textOrTokens;
      text = tokens.text;
    }

    // note: the tokens should have no state so we should be able to safely cache the generated functions
    if (text in compileCache) {
      return compileCache[text];
    }

    tokens = tokens || this.parse(text);
    return (compileCache[text] = function () {
      // if there is just one token return the value directly
      if (tokens.count() === 1) {
        return tokens.getAt(0).getValue.apply(tokens.getAt(0), arguments);
      }

      let hasPromise = false;

      const values = tokens.map((token) => {
        const value = token.getValue(...arguments);
        hasPromise = hasPromise || jsUtils.isPromise(value);
        return value;
      });

      if (hasPromise) {
        return $.when(...values).then((...args) => args.join(""));
      }

      return values.join("");
    });
  },

  parse: function (text) {
    if (!text) {
      return [];
    }

    // note: this is a port of the C# class TokenParser - any functional changes should likely be kept in sync
    const tokens = new TokenCollection(text);
    let braceCount = 0;
    let start = 0;
    let end = 0;
    const ln = text.length;
    let i, c;

    for (i = 0; i < ln; i++) {
      c = text.charAt(i);
      if (c === "\\") {
        // next character is escaped - skip it
        i++;
        continue;
      }

      if (c === "{") {
        braceCount++;
        if (braceCount === 1) {
          if (end !== i) {
            tokens.add(new ConstantToken(text.substr(end, i - end)));
          }

          start = i + 1;
        }
      } else if (c === "}") {
        braceCount--;
        if (braceCount === 0) {
          const token = Token.parse(text.substr(start - 1, i - start + 2));
          if (token) {
            tokens.add(token);
            end = i + 1;
          }

          start = i + 1;
        }
      }
    }

    if (end !== ln) {
      // include trailing text
      tokens.add(new ConstantToken(text.substr(end, ln - end)));
    }

    return tokens;
  }
};

// todo: extract into a reusable collection object
function TokenCollection(text) {
  this.tokens = [];
  this.text = text;
}

TokenCollection.prototype = {
  constructor: TokenCollection,

  add: function (token) {
    this.tokens.push(token);
  },

  forEach: function () {
    this.tokens.forEach.apply(this.tokens, arguments);
  },

  map: function () {
    return this.tokens.map.apply(this.tokens, arguments);
  },

  count: function () {
    return this.tokens.length;
  },

  getAt: function (index) {
    return this.tokens[index];
  }
};

module.exports = TokenParser;
plexExport("tokens.TokenParser", TokenParser);
