ï»¿const $ = require("jquery");
const plexExport = require("../../global-export");
const plexImport = require("../../global-import");

const notify = {};

["error", "info", "success", "warning"].forEach((type) => {
  notify[type] = function (message, options) {
    if (arguments.length === 3) {
      // fallback for when title was part of the signature
      options = arguments[2];
    }

    options = $.extend({}, options);
    const banner = plexImport("banner");
    const pageBanner = banner.getBanner();

    if (pageBanner) {
      options.status = type;
      pageBanner.setMessage(message, options);
    }

    return {
      clear: function () {
        if (pageBanner) {
          pageBanner.cancel();
        }
      }
    };
  };
});

notify.setOptions = function (options) {
  const banner = plexImport("banner");
  banner.setOptions(options);
};

module.exports = notify;
plexExport("notify", notify);
