ï»¿const $ = require("jquery");
const jsUtils = require("../../Utilities/plex-utils-js");
const styleUtils = require("../../Utilities/plex-utils-style");
const plexExport = require("../../../global-export");

const Writer = function () {
  // constructor
};

Writer.prototype = {
  constructor: Writer,

  init: function (grid) {
    this.grid = grid;
    this.grid.$element.on("layoutChanged.plex.grid", $.proxy(this.reset, this));
  },

  reset: function () {
    if (typeof this.onReset === "function") {
      this.onReset();
    }
  },

  prerender: function (record, index, data) {
    // do not apply features if data is empty
    if (!this.featureProcessor || data.length === 0) {
      this.features = { attr: {}, style: {}, css: [] };
      return true;
    }

    // need to inline arguments so function can be optimized
    const args = new Array(arguments.length);
    for (let i = 0, ln = arguments.length; i < ln; i++) {
      args[i] = arguments[i];
    }

    const featureObservable = this.featureProcessor.apply(this.column || this.grid, args);
    if (!featureObservable) {
      return false;
    }

    this.features = featureObservable();
    if (this.features.render === false) {
      return false;
    }

    return featureObservable;
  },

  render: function () {
    // should be overwritten by inheritors
  },

  writeCss: function (writer, css) {
    if (css && css.length > 0) {
      css.forEach(writer.addClass, writer);
    }
  },

  writeAttributes: function (writer, attr) {
    if (!attr || $.isEmptyObject(attr)) {
      return;
    }

    $.each(attr, (name) => {
      writer.addAttr(name, attr[name]);
    });
  },

  writeStyle: function (writer, style) {
    if (!style || $.isEmptyObject(style)) {
      return;
    }

    $.each(style, (css) => {
      writer.addStyle(styleUtils.toDashStyle(css), style[css]);
    });
  }
};

jsUtils.makeExtendable(Writer);

module.exports = Writer;
plexExport("grid.writers.Writer", Writer);
