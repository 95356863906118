ï»¿const ko = require("knockout");
const $ = require("jquery");
const knockoutUtils = require("./plex-utils-knockout");

const EOL_PATTERN = /\r?\n/g;
const CRLF = "\r\n";

ko.bindingHandlers.textareaValue = {
  init: function (element, valueAccessor, allBindings) {
    const accessor = valueAccessor();
    const data = ko.unwrap(accessor);
    const $element = $(element);

    // set textarea initial value from binded model
    $element.val(data);

    $element.on("change.plex.textareaValue", (e) => {
      const value = replaceLineBreakSymbol(e.target.value);
      if (ko.isObservable(accessor)) {
        accessor(value);
      } else {
        knockoutUtils.updateTwoWayBinding(allBindings, "textareaValue", value);
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(element).off("change.plex.textareaValue");
    });
  },
  update: function (element, valueAccessor) {
    const value = ko.unwrap(valueAccessor());
    $(element).val(replaceLineBreakSymbol(value));
  }
};

// this function replaces textarea line break '\n' symbol with '\r\n'
function replaceLineBreakSymbol(inputText) {
  inputText = inputText && String(inputText);
  if (inputText && inputText.search(EOL_PATTERN) !== -1) {
    return inputText.replace(EOL_PATTERN, CRLF);
  }

  return inputText;
}

ko.expressionRewriting._twoWayBindings.textareaValue = true;
