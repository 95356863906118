ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const pubsub = require("../../Core/plex-pubsub");
const nav = require("../../Core/plex-navigate");
const plexExport = require("../../../global-export");
const glossary = require("../../Globalization/plex-glossary-handler");

function SidetabsMenuPageHeader(element, options) {
  this.init(element, options);
}

SidetabsMenuPageHeader.prototype = {
  constructor: SidetabsMenuPageHeader,

  init: function (el, config) {
    const self = this;

    this.$element = $(el);
    this.config = config || {};

    this.iconName = ko.observable("");
    this.title = ko.observable("");
    this.subTitle = ko.observable("");
    this.visible = ko.observable(true);

    this.backActionUrl = ko.observable("#");
    this.backActionTitle = ko.observable("");

    this.enableBackNavigation = ko.observable(config && config.enableBackNavigation);

    this.initialized = false;

    this.formattedTitle = ko.pureComputed(() => {
      if (self.subTitle()) {
        return self.title() + " - " + self.subTitle();
      }

      return self.title();
    });

    ko.applyBindings(this, this.$element[0]);

    this.onInit(config);
  },

  onInit: function (_config) {
    const self = this;

    pubsub.subscribe(
      "sidetabsMenuSelectionChanged",
      (data) => {
        if (data && data.selectedItem) {
          if (data.submenuKey && self.backActionTitle() === "") {
            const postData = {
              submenuKey: data.submenuKey
            };

            nav
              .post("/Platform/Submenus/GetSubmenu", postData)
              .done((result) => {
                const submenuData = result && result.Data;

                if (submenuData) {
                  self.backActionUrl(submenuData.url);
                  glossary
                    .getCustomerWordAsync({ text: "View {1}", tokens: [submenuData.title] })
                    .then((glossarizedText) => {
                      self.backActionTitle(`< ${glossarizedText}`);
                    });
                }
              })
              .fail((error) => {
                $.when().reject(error);
              });
          }

          if (!self.initialized || data.overrideExisting) {
            self.iconName(data.selectedItem.iconName);
            self.title(data.selectedItem.title);
            self.initialized = true;

            if (data.formSectionMenu) {
              self.subTitle(data.selectedItem.title);
            }
          }
        } else {
          self.visible(false);
        }
      },
      this
    );
  },

  dispose: function () {
    // can be overriden
  }
};

$.fn.sidetabsMenuPageHeader = function (options) {
  const settings = $.extend({}, options);

  return this.each(function () {
    const header = $(this).data("sidetabsMenuPageHeader");

    if (!header) {
      $(this).data("sidetabsMenuPageHeader", new SidetabsMenuPageHeader(this, settings));
    }

    return this;
  });
};

module.exports = SidetabsMenuPageHeader;
plexExport("sidetabsMenuPageHeader", SidetabsMenuPageHeader);
