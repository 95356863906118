ï»¿const logger = require("../Core/plex-logger");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");
const plexImport = require("../../global-import");

const root = plexImport("labels.valueProviders");

const api = {
  create: function (config) {
    let provider;
    const address = config.valueProvider.address;
    if (address && address in root) {
      provider = new root[address]();
    } else {
      logger.warn("Value provider not found for: " + address + ". Using default provider.");
      provider = new DefaultDataLabelValueProvider();
    }

    provider.options = config;
    if (provider.init) {
      provider.init();
    }

    return provider;
  }
};

module.exports = api;
plexExport("labels.valueProviderFactory", api);
