ï»¿const tokens = require("../../Utilities/plex-utils-datatoken");
const personNameFormatter = require("../../Globalization/plex-formatting").PersonNameFormatter;
const defaultValueProvider = require("./plex-grid-value-providers");
const plexExport = require("../../../global-export");

const PersonNameValueProvider = defaultValueProvider.extend({
  getHtml: function (record) {
    return this.getValue(record) || "";
  },
  getValue: function (record) {
    const model = tokens.applyTokens(record, this.column.bindableProperties);
    return personNameFormatter.format(this.column.format, model.FirstName, model.MiddleName, model.LastName);
  },
  getFormattedValue: function () {
    return this.getValue.apply(this, arguments);
  }
});

module.exports = PersonNameValueProvider;
plexExport("grid.valueProviders.PersonNameValueProvider", PersonNameValueProvider);
