ï»¿const ko = require("knockout");
const Node = require("./plex-favorites-tree-node");

const SORT_ORDER_STEP = 10;

const isValidIndex = (index, maxIndex) => index >= 0 && index < maxIndex;

const nodeComparer = (leftNode, rightNode) => {
  const left = leftNode.sortOrder();
  const right = rightNode.sortOrder();

  if (left === right) {
    return 0;
  } else if (left === null) {
    return 1;
  } else if (right === null) {
    return -1;
  } else {
    return left - right;
  }
};

const truncateDecimalPart = (number) => number | 0;

const getNextNodeSortOrder = (nodes, index) => {
  const node = nodes[index + 1];
  return node ? node.sortOrder() : 0;
};

const getPreviousNodeSortOrder = (nodes, index) => {
  const node = nodes[index - 1];
  return node ? node.sortOrder() : 0;
};

class GroupNode extends Node {
  get hasChildNodes() {
    return !!this.childNodes().length;
  }

  remove() {
    super.remove();

    const childNodes = this.childNodes();
    while (childNodes.length) {
      childNodes[0].remove();
    }
  }

  dispose() {
    super.dispose();
    this.childNodes.forEach((child) => child.dispose());
  }

  /* #region  add/remove child */
  addChild(node, index) {
    if (node.hasParent) {
      node.parent.removeChild(node);
    }

    node.parent = this;
    node.parentKey(this.key());

    if (isValidIndex(index, this.childNodes().length)) {
      this.childNodes.splice(index, 0, node);
    } else {
      this.childNodes.push(node);
    }
  }

  removeChild(node) {
    const index = this.childNodes.indexOf(node);
    if (isValidIndex(index, this.childNodes().length)) {
      node.parent = null;
      node.parentKey(null);

      this.childNodes.splice(index, 1);
    }
  }
  /* #endregion */

  /* #region  expand/collapse child nodes */
  expand() {
    if (this.hasChildNodes) {
      this.isExpanded(true);
    }
  }

  collapse() {
    this.isExpanded(false);
    this.childNodes.forEach((node) => {
      if (node instanceof GroupNode) {
        node.collapse();
      }
    });
  }
  /* #endregion */

  /* #region  start/stop order tracking */
  startOrderTracking() {
    this.childNodes.sort(nodeComparer);
    this.childNodes.forEach((node) => node.stopOrderTracking && node.startOrderTracking());

    if (!this._sortOrderSubscription || this._sortOrderSubscription.isDisposed) {
      this._sortOrderSubscription = this.childNodes.subscribe(this._onChildNodesChange, this, "arrayChange");
      this.addDisposable(this._sortOrderSubscription);
    }
  }

  stopOrderTracking() {
    if (this._sortOrderSubscription && !this._sortOrderSubscription.isDisposed) {
      this._sortOrderSubscription.dispose();
    }

    this.childNodes.forEach((node) => node.stopOrderTracking && node.stopOrderTracking());
  }
  /* #endregion */

  /* #region private  */
  _init() {
    super._init();

    this.isExpanded = ko.observable(false);
    this.childNodes = ko.observableArray();
  }

  _onChildNodesChange(changes) {
    if (this.hasChildNodes) {
      this._updateSortOrders(changes);
    } else {
      this.collapse();
    }
  }

  _updateSortOrders(changes) {
    const action = changes.filter((change) => change.status === "added")[0];
    if (!action) {
      return;
    }

    const addedNode = action.value;
    const childNodes = addedNode.parent.childNodes();
    const nextNodeSortOrder = getNextNodeSortOrder(childNodes, action.index);
    const previousNodeSortOrder = getPreviousNodeSortOrder(childNodes, action.index);

    // set sort order of added node
    if (action.index === childNodes.length - 1) {
      addedNode.sortOrder(previousNodeSortOrder + SORT_ORDER_STEP);
    } else if (action.index === 0) {
      addedNode.sortOrder(nextNodeSortOrder - SORT_ORDER_STEP);
    } else {
      let newSortOrder = truncateDecimalPart((previousNodeSortOrder + nextNodeSortOrder) / 2);
      if (newSortOrder <= previousNodeSortOrder) {
        newSortOrder = previousNodeSortOrder + SORT_ORDER_STEP / 2;
      }

      addedNode.sortOrder(newSortOrder);
    }

    // update all sort orders
    childNodes.forEach((node, index) => {
      if (index === 0) {
        return;
      }

      const previousSortOrder = getPreviousNodeSortOrder(childNodes, index);
      if (node.sortOrder() <= previousSortOrder) {
        node.sortOrder(previousSortOrder + SORT_ORDER_STEP / 2);
      }
    });
  }
  /* #endregion */
}

module.exports = GroupNode;
