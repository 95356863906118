ï»¿const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");
const mathUtils = require("../../Utilities/plex-utils-math");

const SumOperation = Operation.extend({
  getValue: function (records) {
    /// <summary>Gets the total for the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Number">The total.</returns>

    let total = 0;
    let value;

    for (let i = 0, ln = records.length; i < ln; i++) {
      value = this.getRecordValue(records[i], i);
      if (value != null) {
        if (typeof value !== "number") {
          if (isNaN(value)) {
            return NaN;
          }

          value = parseFloat(value);
        }
      }

      total = mathUtils.add(total, value || 0);
    }

    return total;
  }
});

module.exports = SumOperation;
plexExport("grid.aggregates.SumOperation", SumOperation);
