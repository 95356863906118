const RemoteDataSource = require("./plex-datasource-remote");
const LocalDataSource = require("./plex-datasource-local");
const plexExport = require("../../global-export");

const DataSourceFactory = {
  create: function (config) {
    let ds;
    if (config && config.url) {
      ds = new RemoteDataSource();
    } else {
      ds = new LocalDataSource();
    }

    ds.init.apply(ds, arguments);
    return ds;
  }
};

module.exports = DataSourceFactory;
plexExport("DataSourceFactory", DataSourceFactory);
