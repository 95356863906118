const $ = require("jquery");
const { ulid } = require("ulid");
const client = require("./plex-component-host-client");

let componentEnabled = null;
const PLUGIN_NS = "com.plex.files";

function startUserInteraction() {
  if (!client.pchVersionAtLeast("1.4") && !client.userToken) {
    client.startUserInteraction(ulid());
  }
}

function endUserInteraction() {
  client.endUserInteraction();
}

function routedRpc(procedure, args, kwargs) {
  return client.rpc(procedure, args, kwargs, client.routedWithUserTokenOpts);
}

function unwrapArgs({ args }) {
  return args[0];
}

function reject(err) {
  endUserInteraction();
  return new $.Deferred().reject(err);
}

function isObject(obj) {
  return !!obj && typeof obj === "object";
}

const GENERIC_ERROR = reject({
  text:
    "There was a problem using the local file system. You may find additional details in the Component Host web interface. Click on the Plex icon in the system tray to launch.",
  isGlossarized: false
});

function handleError() {
  endUserInteraction();
  return GENERIC_ERROR;
}

class FileComponent {
  isEnabled() {
    if (componentEnabled != null) {
      return $.when(componentEnabled);
    }

    return client
      .getSession()
      .then(() => {
        // version can only be properly queried after connection is established
        if (client.pchVersionAtLeast("1.4")) {
          // only tests if plugin is running; does not test if the notifier application is running.
          // Procedure Meta API is not supported for routed procedures.
          return client.rpc(PLUGIN_NS + ".is_enabled");
        }

        return client.rpc("wamp.registration.lookup", [`${PLUGIN_NS}.can_read_file`]);
      })
      .then(
        ({ args }) => (componentEnabled = !!args[0]),
        () => (componentEnabled = false)
      );
  }

  verifyReadAccess(filename) {
    startUserInteraction();

    return (
      routedRpc(`${PLUGIN_NS}.can_read_file`, [filename])
        .then(unwrapArgs, handleError)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (!res) {
            return reject({});
          }
        })
    );
  }

  verifyWriteAccess(filename) {
    startUserInteraction();

    return (
      routedRpc(`${PLUGIN_NS}.can_write_file`, [filename])
        .then(unwrapArgs, handleError)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (!res) {
            return reject({
              text:
                'The local document "{1}" could not be written.  Please verify that the file is not already opened elsewhere and the folder can be written to.',
              tokens: [filename],
              isGlossarized: false
            });
          }
        })
    );
  }

  openFile(filename) {
    startUserInteraction();

    return (
      routedRpc(`${PLUGIN_NS}.open_file`, [filename])
        .then(unwrapArgs, handleError)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          // null will be returned if the user is being prompted
          if (res === false) {
            return reject({
              text: 'The local document "{1}" does not exist or could not be read.',
              tokens: [filename],
              isGlossarized: false
            });
          }
        })
        .always(endUserInteraction)
    );
  }

  getFileSize(filename) {
    startUserInteraction();

    return routedRpc(`${PLUGIN_NS}.get_file_size`, [filename]).then(unwrapArgs, (_err) => {
      return reject({
        text: 'Error reading file size of "{1}"',
        tokens: [filename],
        isGlossarized: false
      });
    });
  }

  downloadFile(uri, authToken, filename) {
    startUserInteraction();

    const token = document.cookie + authToken;
    return (
      routedRpc(`${PLUGIN_NS}.download_file`, [{ uri, token, filename }])
        .then(unwrapArgs)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (res) {
            // A non-empty string indicates an error from the server
            return reject(res);
          }
        })
        .catch((err) => {
          if (typeof err === "string") {
            try {
              const results = JSON.parse(err);
              if (isObject(results)) {
                const externalError = results.ErrorMessage;
                if (externalError) {
                  // this result is coming from the download handler, but isn't being glossarized
                  return reject({ text: externalError, isGlossarized: false });
                }
              }
            } catch (e) {
              // ignore
            }
          }

          return reject({ text: "Error during download", isGlossarized: false });
        })
    );
  }

  uploadFile(uri, authToken, filename) {
    startUserInteraction();

    const token = document.cookie + authToken;
    return (
      routedRpc(`${PLUGIN_NS}.upload_file`, [{ uri, token, filename }])
        .then(unwrapArgs, handleError)
        // eslint-disable-next-line consistent-return
        .then((res) => {
          let success = false;
          if (typeof res === "string") {
            try {
              const results = JSON.parse(res);
              if (isObject(results)) {
                if (isObject(results.ValidationResult)) {
                  success = results.ValidationResult.Success;
                  if (!success) {
                    if (results.ValidationResult.Message) {
                      // this result is coming from the upload handler and should already be glossarized
                      return reject({
                        text: results.ValidationResult.Message,
                        isGlossarized: true
                      });
                    }

                    if (results.ErrorObject && results.ErrorObject.Message) {
                      // this result is coming from the upload handler, but the error might not be a customer facing error message
                      // just show the generic error message
                      return reject({ text: "Error during upload", isGlossarized: false });
                    }
                  }
                }
              }
            } catch (e) {
              // ignore
            }
          }

          if (!success) {
            return reject({
              text: 'The local document "{1}" does not exist or could not be read.',
              tokens: [filename],
              isGlossarized: false
            });
          }
        })
        .always(endUserInteraction)
    );
  }

  copyFile(source, dest, deleteSource) {
    startUserInteraction();

    const action = deleteSource ? "move_file" : "copy_file";
    return routedRpc(`${PLUGIN_NS}.${action}`, [{ source, dest }])
      .then(unwrapArgs, (_err) => {
        endUserInteraction();
        return reject({ text: "Error during copy", isGlossarized: false });
      })
      .always(() => {
        if (action === "move_file") {
          endUserInteraction();
        }
      });
  }

  openLocalFilePicker() {
    startUserInteraction();

    return routedRpc(`${PLUGIN_NS}.choose_file`)
      .then(unwrapArgs, (err) => {
        if (err === "Cancelled") {
          return reject({ text: "User canceled the file picker", hideError: true });
        }

        return GENERIC_ERROR;
      })
      .always(endUserInteraction);
  }
}

module.exports = new FileComponent();
