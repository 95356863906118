ï»¿const ko = require("knockout");
const $ = require("jquery");
const parseJSON = require("../../Core/plex-parsing-json");
const pubsub = require("../../Core/plex-pubsub");
const plexExport = require("../../../global-export");
const glossary = require("../../Globalization/plex-glossary-handler");
const SideMenuControllerBase = require("./plex-controller-side-menu-base");
const plexImport = require("../../../global-import");
const dom = require("../../Utilities/plex-utils-dom");

function changeSectionsVisibility(sections, show) {
  sections.forEach((section) => {
    section.css(null);
    section.printVisible(show);
  });
}

function changeActiveSection(previousActiveSection, nextActiveSection) {
  if (previousActiveSection) {
    previousActiveSection.css(null);
    previousActiveSection.printVisible(false);
  }

  nextActiveSection.css("plex-active-form-section");
  nextActiveSection.printVisible(true);
}

const SidetabsSectionsMenuController = SideMenuControllerBase.extend({
  onInit: function (config) {
    const self = this;
    this.formSections = [];
    this.actionbarActions = [];
    this.actionbarUIComponent = {};

    const allSectionsItem = this.addSideMenuItem({
      iconClassName: "plex-menu-icon-Square_Process",
      visible: true,
      text: "All Sections",
      active: true,
      name: null,
      draggable: false
    });

    glossary.getCustomerWordAsync({ text: allSectionsItem.text() }).then((result) => {
      allSectionsItem.text(result);
    });

    self._loadMenuItems(config);

    this.addDisposable(
      pubsub.subscribe(
        "formSectionsLoaded",
        (data) => {
          self._loadFormSections(data, config);
        },
        this
      )
    );

    this.addDisposable(
      pubsub.subscribe(
        "validationCompleted",
        (invalidElements) => {
          self._changeValidationState(invalidElements);
        },
        this
      )
    );

    this.addDisposable(
      pubsub.subscribe(
        "actionbarInit",
        (data) => {
          if (data) {
            const actionBarController = plexImport("currentPage")[data.actionbarId];
            if (dom.isInDialog(actionBarController.$actionbar.$element) === false) {
              self.actionbarActions = actionBarController.actionList
                .groups()
                .reduce((actions, actionsGroup) => actions.concat(actionsGroup.actions()), []);
              self.actionbarUIComponent = actionBarController.$actionbar;
            }
          }
        },
        this
      )
    );
  },

  postInit: function () {
    pubsub.publish("formSectionsMenuLoaded");
  },

  executeClickAction: function (item) {
    const selectionInfo = { previousSelectedSection: null, newSelectedSection: item.name };

    if (this.selectedItem) {
      this.selectedItem.active(false);

      selectionInfo.previousSelectedSection = this.selectedItem.name;
    }

    if (selectionInfo.newSelectedSection === null) {
      changeSectionsVisibility(this.formSections, true);

      $(".plex-form").removeClass("plex-single-form-section-selected");
    } else {
      const previousSelectedSection = this.formSections.filter(
        (e) => e.sectionIdentifier === selectionInfo.previousSelectedSection
      )[0];
      const selectedSection = this.formSections.filter(
        (e) => e.sectionIdentifier === selectionInfo.newSelectedSection
      )[0];

      if (selectedSection) {
        if (selectionInfo.previousSelectedSection == null) {
          changeSectionsVisibility(this.formSections, false);
        }

        changeActiveSection(previousSelectedSection, selectedSection);

        $(".plex-form").addClass("plex-single-form-section-selected");
      }
    }

    pubsub.publish("selectedSectionRendered");

    item.active(true);
  },

  onItemSelected: function (item) {
    pubsub.publish("sidetabsMenuSelectionChanged", {
      selectedItem: { iconName: item.iconClassName(), title: item.text() },
      overrideExisting: true
    });

    this._changeActionbarActionsVisibility(item);
  },

  getSortOrderPath: function () {
    return "/Platform/SubmenuActions/UpdateSubmenuSectionsUserSortOrder";
  },

  getSortOrderData: function (arg) {
    const data = ko
      .unwrap(arg.targetParent)
      .filter((item) => {
        return item.visible() && item.data != null && item.data.submenuActionKey;
      })
      .map((item) => {
        return item.name;
      });

    return {
      DefaultJson: JSON.stringify(data),
      SubmenuActionKey: arg.item.data.submenuActionKey
    };
  },

  getCollapsedState: function (menuState) {
    return menuState.sections;
  },

  createMenuState: function () {
    return { sections: this.collapsed() };
  },

  updateMenuState: function (sideMenuState) {
    sideMenuState.sections = this.collapsed();
  },

  _loadMenuItems: function (controllerConfig) {
    const self = this;
    const savedItems = controllerConfig.sectionsJson ? parseJSON(controllerConfig.sectionsJson) : null;

    if (savedItems) {
      savedItems.forEach((savedItemName) => {
        self._addSectionItem(savedItemName, controllerConfig);
      });
    }
  },

  _loadFormSections: function (data, controllerConfig) {
    const self = this;

    if (data && data.sections) {
      data.sections.forEach((formSection) => {
        self.formSections.push(formSection);
        const sectionIdentifier = formSection.sectionIdentifier;
        const loadedItem = self.sideMenuItems().filter((e) => e.name === sectionIdentifier)[0];

        if (loadedItem) {
          self._initializeItem(formSection, loadedItem);
        } else {
          self._addSectionItem(sectionIdentifier, controllerConfig, {
            text: formSection.title(),
            iconClassName: formSection.iconClassName,
            visible: formSection.elements && formSection.elements.length > 0
          });
        }
      });
    }
  },

  _addSectionItem: function (sectionName, controllerConfig, formSectionData) {
    const addingItem = {
      visible: false,
      name: sectionName,
      iconClassName: "",
      text: "",
      data: { submenuActionKey: controllerConfig.submenuActionKey }
    };

    if (formSectionData) {
      addingItem.visible = formSectionData.visible;
      addingItem.iconClassName = formSectionData.iconClassName;
      addingItem.text = formSectionData.text;
    }

    this.addSideMenuItem(addingItem);
  },

  _initializeItem: function (formSection, item) {
    item.text(formSection.title());
    item.iconClassName(formSection.iconClassName);
    item.visible(formSection.elements && formSection.elements.length > 0);
  },

  _getFormSectionByElementId: function (elementId) {
    if (this.formSections) {
      const $formSectionElement = $("#" + elementId).parents("fieldset");

      if ($formSectionElement.length > 0) {
        const formSection = this.formSections.filter((e) => e.id === $formSectionElement.attr("id"))[0];

        return formSection;
      }
    }

    return null;
  },

  _getItemByName: function (sectionName) {
    const item = this.sideMenuItems().filter((e) => e.name === sectionName)[0];

    return item;
  },

  _changeValidationState: function (invalidElements) {
    this.sideMenuItems().forEach((e) => {
      e.hasError(false);
    });

    if (invalidElements) {
      Object.keys(invalidElements).forEach((element) => {
        const formSection = this._getFormSectionByElementId(element);

        if (formSection) {
          const item = this._getItemByName(formSection.sectionIdentifier);

          if (item) {
            item.hasError(true);
          }
        }
      });
    }
  },

  _changeActionbarActionsVisibility: function (selectedItem) {
    if (this.actionbarActions.length) {
      const formSection = this.formSections.filter((e) => e.sectionIdentifier === selectedItem.name)[0];
      this.actionbarActions.forEach((action) => {
        const sourceId = action.linkedSourceId ? action.linkedSourceId : action.sourceId;
        let closestElement = [];
        let childElement = [];
        if (sourceId && formSection) {
          const formSectionElement = $("#" + formSection.id);
          closestElement = formSectionElement.closest("#" + sourceId);
          childElement = formSectionElement.find("#" + sourceId);
        }

        if (sourceId && formSection && !childElement.length && !closestElement.length) {
          changeActionVisibility(action, false);
        } else {
          changeActionVisibility(action, true);
        }
      });

      this.actionbarUIComponent.resize();
    }

    function changeActionVisibility(action, visibility) {
      action.visible(visibility);
      action.printVisible(visibility);
    }
  }
});

SidetabsSectionsMenuController.create = function () {
  return new SidetabsSectionsMenuController();
};

module.exports = SidetabsSectionsMenuController;
plexExport("SectionsMenuController", SidetabsSectionsMenuController);
