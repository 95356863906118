const $ = require("jquery");
const validation = require("../Core/plex-validation");
const FormController = require("./plex-controller-form");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const plexExport = require("../../global-export");

// the composite controller just manages the buttons
// todo: we might want to manage buttons in a separate controller
const FormContainerController = FormController.extend({
  onInit: function () {
    this.$form = this.$element.parents(".plex-form");
    // initialize form itself so that it can respond to basic bindings
    this.initElement(this.config);

    // if any elements have validator, create validator for container
    let prop, element;
    for (prop in this.elements) {
      if (Object.prototype.hasOwnProperty.call(this.elements, prop)) {
        element = this.elements[prop];
        if (element.validationModel && element.validationModel.rules) {
          if (!element.validator) {
            element.validator = validation.createValidator(this.$form, element.validationModel, element);
          }
          // This will be the last validationController
          this.validator = element.validator;
        }
      }
    }

    this._handlePersistentBanner();
    this.bindActions();
  },

  toDocumentXml: function () {
    const doc = new DocumentXml("plex-form-container");
    if (this.config.elements && this.config.elements.length > 0) {
      this.config.elements.forEach($.proxy(doc.addControlElement, doc));
    }

    return doc.serialize();
  }
});

FormContainerController.create = function () {
  return new FormContainerController();
};

module.exports = FormContainerController;
plexExport("FormContainerController", FormContainerController);
