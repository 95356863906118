ï»¿const ko = require("knockout");
const dataUtils = require("./plex-utils-data");
const plexExport = require("../../global-export");
const plexImport = require("../../global-import");

// prefer handle
const elementIdentifiers = ["handle", "originalId", "id", "propertyName"];
const hasOwnProperty = Object.prototype.hasOwnProperty;

function findObservable(model, propertyName) {
  const underlyingObservable = dataUtils.getObservableOrValue(model, propertyName);
  if (ko.isObservable(underlyingObservable) && "$$selected" in underlyingObservable) {
    return underlyingObservable;
  }

  return null;
}

function kindOfEquals(a, b) {
  if (!a || !b) {
    return false;
  }

  if (a === b) {
    return true;
  }

  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA === lowerB) {
    return true;
  }

  if (lowerA.replace(/_/g, "") === lowerB.replace(/_/g, "")) {
    return true;
  }

  return false;
}

const api = {
  isLooseMatch: function (el, id) {
    if (el.handle === id || el.id === id) {
      return el;
    }

    return elementIdentifiers.some((prop) => {
      return kindOfEquals(el[prop], id);
    });
  },

  isSelectable: function (model, propertyName) {
    return findObservable(model, propertyName) != null;
  },

  getElementById: function (id, parent) {
    parent = parent || plexImport("currentPage");
    parent = parent.elements || parent;

    if (id in parent) {
      return parent[id];
    }

    for (const elId in parent) {
      if (hasOwnProperty.call(parent, elId)) {
        const instance = parent[elId];
        let foundElement;

        if (instance.getElementById) {
          foundElement = instance.getElementById(id);
        } else if (instance.elements) {
          foundElement = instance.elements[id];
        }

        if (foundElement) {
          return foundElement.controller || foundElement;
        }
      }
    }

    return null;
  },

  getSelectedValues: function (model, propertyName) {
    /// <summary>
    /// Gets the underlying value from a selectable element.
    /// This includes pickers, dropdowns, and selection lists.
    /// </summary>
    /// <param name="model">The data model.</param>
    /// <param name="propertyName">The property name bound to the element.</param>
    /// <returns type="Array">
    /// An array of the selected items. (Even if the element does not support
    /// multi-select, an array will be returned.
    /// </returns>

    const underlyingObservable = findObservable(model, propertyName);
    if (!underlyingObservable) {
      throw new TypeError("This property does not include metadata for a selectable element.");
    }

    return underlyingObservable.$$selected();
  },

  setSelectedValues: function (model, propertyName, pickerRowsOrKeyValue, displayValue) {
    /// <summary>
    /// Sets the underlying selected value for the provided model property.
    /// </summary>
    /// <param name="model">The data model.</param>
    /// <param name="propertyName">The property name bound to the element.</param>
    /// <param name="pickerRowsOrKeyValue">The row object to be selected OR the key value for the selectable element.</param>
    /// <param name="displayValue">The display value for the picker. (This is only required if you are providing the key value instead of a row object.)</param>

    const underlyingObservable = findObservable(model, propertyName);
    if (!underlyingObservable) {
      throw new TypeError("This property does not include metadata for a selectable element.");
    }

    // if null, just clear the element and return
    if (pickerRowsOrKeyValue == null) {
      underlyingObservable.$$selected.removeAll();
      return;
    }

    if (typeof pickerRowsOrKeyValue !== "object") {
      // create picker row from values
      const row = {};
      row[underlyingObservable.$$keyPropertyName] = pickerRowsOrKeyValue;
      row[underlyingObservable.$$displayPropertyName] = displayValue;
      pickerRowsOrKeyValue = [row];
    }

    pickerRowsOrKeyValue = Array.isArray(pickerRowsOrKeyValue) ? pickerRowsOrKeyValue : [pickerRowsOrKeyValue];
    underlyingObservable.$$selected(pickerRowsOrKeyValue);
  },

  selectValueByKey: function (model, propertyName, keyValue) {
    const underlyingObservable = findObservable(model, propertyName);
    if (!underlyingObservable || typeof underlyingObservable.selectByKey !== "function") {
      throw new TypeError("This property does not include metadata for a selectable element.");
    }

    underlyingObservable.selectByKey(keyValue);
  }
};

module.exports = api;
plexExport("elements", api);
