const ko = require("knockout");

ko.bindingHandlers.events = {
  init: function (element, valueAccessor, _allBindings, _viewModel, bindingContext) {
    const events = valueAccessor();
    if (!Array.isArray(events) || events.length === 0) {
      return;
    }

    const bindings = events.reduce((values, entry) => {
      const { action, event } = entry;

      if (!action || !event) {
        // eslint-disable-next-line no-console
        console.log(`[WARNING]: Required values are missing for 'events' binding!`);
        return values;
      }

      if (typeof action.executeAction !== "function") {
        // eslint-disable-next-line no-console
        console.log(
          `[WARNING]: Unable to finding action handler '${action.id}' for ${event.type || event.bindingName}!`
        );

        return values;
      }

      if (event.bindingName === "event") {
        values.event = values.event || {};
        values.event[event.type] = action.executeAction;

        if (event.canBubble && event.cancelBubble) {
          values[`${event.type}Bubble`] = false;
        }
      } else {
        values[event.bindingName] = action.executeAction;
      }

      return values;
    }, {});

    if (Object.keys(bindings).length === 0) {
      return;
    }

    ko.applyBindingsToNode(element, bindings, bindingContext);
  }
};
