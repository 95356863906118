const $ = require("jquery");
const glossary = require("../Globalization/plex-glossary-handler");
const { format } = require("../Utilities/plex-utils-strings");
const plexExport = require("../../global-export");

class ClientError extends Error {
  constructor(messageOrOptions) {
    super(typeof messageOrOptions === "string" ? messageOrOptions : messageOrOptions.text);
    const options = typeof messageOrOptions === "string" ? { text: messageOrOptions } : messageOrOptions;

    this.text = options.text;
    this.tokens = options.tokens || [];
    this.autoGlossarize = options.autoGlossarize ?? true;
    this.name = "ClientError";
  }

  getGlossedMessage() {
    if (this.autoGlossarize) {
      return glossary.getCustomerWordAsync(this);
    }

    return $.when(format(this.text, this.tokens));
  }
}
module.exports = ClientError;
plexExport("ClientError", ClientError);
