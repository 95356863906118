ï»¿const plexExport = require("../../global-export");

const controllerRegistry = {};

const api = {
  register: function (view, controller) {
    /// <summary>Register a controller for the specified view.</summary>
    /// <param name="view">The view.</param>
    /// <param name="controller">The controller.</param>

    controllerRegistry[view.toLowerCase()] = controller;
  },
  get: function (view) {
    /// <summary>Retrieves the controller for the specified view.</summary>
    /// <param name="view">The view.</param>
    /// <returns type="controller">The controller.</returns>

    return controllerRegistry[view.toLowerCase()];
  }
};

module.exports = api;
plexExport("ControllerFactory", api);
