ï»¿const ko = require("knockout");
const $ = require("jquery");

/// <summary>Enhances knockout's text binding to broadcast an event when it is changed.</summary>
ko.bindingHandlers.textBroadcast = {
  init: function (element, valueAccessor, allBindingsAccessor, data, context) {
    ko.bindingHandlers.text.init(element, valueAccessor, allBindingsAccessor, data, context);
  },

  update: function (element, valueAccessor, allBindingsAccessor, data, context) {
    ko.bindingHandlers.text.update(element, valueAccessor, allBindingsAccessor, data, context);

    $(element).trigger("text-change");
  }
};
