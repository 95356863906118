ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.tristate = {
  init: function (element, valueAccessor, allBindingAccessor, viewModel) {
    if (!/checkbox/i.test(element.type)) {
      throw new TypeError("the tristate binding can only be used with a checkbox control");
    }

    const $el = $(element);
    let options = valueAccessor();

    if (typeof options === "boolean") {
      options = { tristate: options };
    }

    // default tristate to true
    $el.tristateCheckbox({ tristate: ko.unwrap(options.tristate) !== false });

    if (options.state !== undefined) {
      $el.tristateCheckbox("setState", ko.unwrap(options.state));
    }

    if (options.stateChanged) {
      $el.on("stateChanged.tristateCheckbox", (e, state) => {
        options.stateChanged.call(viewModel, state);
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $el.off("stateChanged.tristateCheckbox");
    });
  },

  update: function (element, valueAccessor) {
    const options = valueAccessor();
    if (options.tristate) {
      $(element).tristateCheckbox("setOption", ko.unwrap(options.tristate));
    }

    if (options.state) {
      $(element).tristateCheckbox("setState", ko.unwrap(options.state));
    }
  }
};
