ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const arrayUtils = require("../../Utilities/plex-utils-arrays");
const columnBuilder = require("./plex-grid-column-builder");
const plexExport = require("../../../global-export");

function GridHeader(config, datasource, grid) {
  this.config = grid.config;
  this.columns = config.columns;
  this.datasource = datasource;
  this.grid = grid;
  this.features = config.features;
  this.parent = grid.config;
  this.groups = grid.config.groups;
  this.primary = true;
  this.init();
}

GridHeader.prototype = {
  constructor: GridHeader,

  init: function () {
    const self = this;
    this.sourceColumns = this.config.columns;

    // Removing empty objects from the serialized model is very
    // important for "auto" hiding grid columns based on customer settings.
    arrayUtils.removeEmpty(this.sourceColumns);

    this.title = ko.isObservable(this.parent.title) ? this.parent.title : ko.observable(this.parent.title);
    this.resetTrigger = ko.observable();

    this.isMultiColumnSort = false;
    this.sortedColumns = [];
    this.currentSort = [];

    this.initColumns(this.sourceColumns.slice(0));

    // default to true - picker will hide it conditionally
    this.visible = this.parent.headerVisible !== false;
    this.templateName = "grid-header-template";

    this.datasource.data.subscribe(() => {
      self.resetTrigger.notifySubscribers();
    });
  },

  initColumns: function (columns) {
    const self = this;
    let i = columns.length;
    let observableColumns = ko.getObservable(this.config, "columns");

    while (i--) {
      columnBuilder.build(columns[i], this);
      if (columns[i].enableToggleAll) {
        this.subscribeToggleAllColumn(columns[i]);
      }
    }

    // If a grid is being re-initialized the columns might already be
    // observable. If so keep that reference.
    if (observableColumns) {
      this.columns = observableColumns;
    } else {
      observableColumns = this.columns = this.config.columns = ko.observableArray(columns);
      if (this.isCrosstab) {
        const pivotGenerator = function () {
          const cols = self.pivotColumn.generate();
          self.initHeaderRows(cols);

          observableColumns.removeAll();
          observableColumns.pushAll(self.headerRows()[self.headerRows().length - 1]().columns());
        };

        pivotGenerator();
        self.datasource.data.subscribe(pivotGenerator);
      } else {
        this.initHeaderRows(columns);
        this.columns(this.headerRows()[this.headerRows().length - 1]().columns());
      }
    }
  },

  setHeaderRow: function (columns, headerRow) {
    let moreRows = false;
    columns.forEach((column) => {
      if (column.columns && !column.composite) {
        column.columns.forEach((col) => {
          col.lastRow = ko.observable(col.columns && !col.composite ? -1 : headerRow.level());
          headerRow.columns.push(col);
          if (col.lastRow() === -1) {
            moreRows = true;
          }
        });
      } else {
        headerRow.columns.push(column);
      }
    });

    headerRow.hasCrosstabColumns = headerRow.columns.some((column) => {
      return column.type === "Pivot";
    });

    headerRow.hasGroupColumns = headerRow.columns.some((column) => {
      return column.parent && column.parent.type === "Group";
    });

    headerRow.hasVisibleCrosstabColumns = ko.pureComputed(() => {
      return headerRow.columns.some((column) => {
        return column.type === "Pivot" && column.headerElement && column.columns.some((col) => col.visible());
      });
    });

    this.headerRows.push(ko.observable(headerRow));

    if (moreRows) {
      const nextRow = {};
      nextRow.columns = ko.observableArray([]);
      nextRow.level = ko.observable(headerRow.level() + 1);
      this.setHeaderRow(headerRow.columns(), nextRow);
    }
  },

  initHeaderRows: function (columns) {
    if (this.headerRows) {
      this.headerRows([]);
    } else {
      this.headerRows = ko.observableArray([]);
    }

    let moreRows = false;

    const headerRow = {};
    headerRow.columns = ko.observableArray([]);
    headerRow.level = ko.observable(0);

    const nextRow = {};
    nextRow.columns = ko.observableArray([]);
    nextRow.level = ko.observable(headerRow.level() + 1);

    columns.forEach((column) => {
      column.lastRow = ko.observable(column.columns && !column.composite ? -1 : headerRow.level());
      headerRow.columns.push(column);
      if (column.lastRow() === -1) {
        moreRows = true;
      }
    });

    this.headerRows.push(ko.observable(headerRow));
    if (moreRows) {
      this.setHeaderRow(headerRow.columns(), nextRow);
    }
  },

  getColumnByProperty: function (propertyName) {
    const self = this;
    return ko.utils.arrayFirst(this.columns(), (column) => {
      return self.findPropertyNameFromColumn(column) === propertyName;
    });
  },

  hasGroups: function () {
    return this.groups && this.groups.length > 0;
  },

  findPropertyNameFromColumn: function (column) {
    if (column.propertyName) {
      return column.propertyName;
    }

    if (column.valueExpression) {
      return column.valueExpression.propertyName || column.propertyName;
    }

    // if this is an element column with only one element, we will try to get the property from the element
    if (column.elements && column.elements.length === 1 && column.elements[0].propertyName) {
      return column.elements[0].propertyName;
    }

    return column.propertyName;
  },

  findGroupIndex: function (propertyName) {
    if (!this.hasGroups() || !propertyName) {
      return -1;
    }

    const groups = this.groups;
    let i = groups.length;

    while (i--) {
      if (
        groups[i].sortPropertyName === propertyName ||
        groups[i].displayPropertyName === propertyName ||
        groups[i].propertyName === propertyName
      ) {
        return i;
      }
    }

    return -1;
  },

  getGroupPropertyName: function (groupIndex) {
    return this.groups[groupIndex].propertyName;
  },

  getGroupDisplayPropertyName: function (groupIndex) {
    return this.groups[groupIndex].displayPropertyName || this.groups[groupIndex].propertyName;
  },

  getGroupSortPropertyName: function (groupIndex) {
    return (
      this.groups[groupIndex].sortPropertyName ||
      this.groups[groupIndex].displayPropertyName ||
      this.groups[groupIndex].propertyName
    );
  },

  findSortColumnIndexByProperty: function (propertyName) {
    let index = -1;

    if (!this.sortedColumns || this.sortedColumns.length === 0) {
      return index;
    }

    this.sortedColumns.forEach((col, colIndex) => {
      if ((col.displayPropertyName || col.propertyName) === propertyName) {
        index = colIndex;
      }
    });

    return index;
  },

  findCurrentSortIndexByProperty: function (propertyName) {
    if (!this.sortedColumns || this.sortedColumns.length === 0) {
      return -1;
    }

    let i = this.currentSort.length;
    let currentPropertyName, current;

    while (i--) {
      current = this.currentSort[i];
      if (typeof current.propertyNameOrValueProvider === "string") {
        currentPropertyName = current.propertyNameOrValueProvider;
      } else {
        currentPropertyName = current.propertyNameOrValueProvider.column.valueExpression
          ? current.propertyNameOrValueProvider.column.valueExpression.propertyName ||
            current.propertyNameOrValueProvider.column.propertyName
          : current.propertyNameOrValueProvider.column.propertyName;
      }

      if (currentPropertyName === propertyName) {
        return i;
      }
    }

    return -1;
  },

  mergeGroups: function () {
    const sortList = this.currentSort;
    const ln = this.groups.length;
    let i = 0;
    let idx = 0;
    let sortIndex, propertyName, displayPropertyName, sortPropertyName, group;

    for (; i < ln; i++) {
      group = this.groups[i];
      propertyName = this.getGroupPropertyName(i);
      displayPropertyName = this.getGroupDisplayPropertyName(i);
      sortPropertyName = this.getGroupSortPropertyName(i);

      sortIndex = this.findCurrentSortIndexByProperty(displayPropertyName);

      // not found insert starting from 0
      if (sortIndex === -1) {
        // the grid code needs to redraw when a group sort will change the direction of the grouped data
        // this is passed on to the sorter via the isGroupSort property
        sortList.splice(idx, 0, {
          propertyNameOrValueProvider: sortPropertyName,
          ascending: group.ascending,
          isGroupSort: this.wasGroupSort
        });
        if (sortPropertyName !== propertyName) {
          idx++;
          sortList.splice(idx, 0, {
            propertyNameOrValueProvider: propertyName,
            ascending: group.ascending,
            isGroupSort: this.wasGroupSort
          });
        }
      } else {
        sortList[sortIndex].isGroupSort = true;

        // replace propertyName with sortPropertyName and preserve the sort
        if (displayPropertyName !== sortPropertyName) {
          sortList[sortIndex].propertyNameOrValueProvider = sortPropertyName;
        }

        if (sortPropertyName !== propertyName) {
          idx++;
          sortList.splice(idx, 0, {
            propertyNameOrValueProvider: propertyName,
            ascending: sortList[sortIndex].ascending,
            isGroupSort: true
          });
        }
      }

      idx++;
    }

    return sortList;
  },

  // group sort always come first and in the group order
  insertGroupSort: function (column, groupIndex) {
    let colIndex;
    while (groupIndex >= 0) {
      colIndex = this.findSortColumnIndexByProperty(this.getGroupDisplayPropertyName(groupIndex));
      if (colIndex !== -1) {
        this.sortedColumns.splice(colIndex, 0, column);
        return;
      }

      groupIndex--;
    }

    this.sortedColumns.unshift(column);
  },

  setSortedColumn: function (column, ascending) {
    const sortIndex = this.sortedColumns.indexOf(column);
    this.wasGroupSort = false;

    // new column clicked
    if (sortIndex === -1) {
      if (this.isMultiColumnSort) {
        const groupIndex = this.findGroupIndex(this.findPropertyNameFromColumn(column));

        // if multiple column state add or insert
        if (groupIndex === 0) {
          this.sortedColumns.unshift(column);
        } else if (groupIndex > 0) {
          this.insertGroupSort(column, groupIndex - 1);
        } else {
          this.sortedColumns.push(column);
        }
      } else {
        // or replace
        this.wasGroupSort =
          this.sortedColumns.length > 0 && this.findGroupIndex(this.sortedColumns[0].propertyName) >= 0;
        this.sortedColumns = [column];
      }
    } else if (column.sortedAsc() === false) {
      // existing sort column
      // if descending toggle multiple column state
      if (this.isMultiColumnSort) {
        // remove column
        this.wasGroupSort = this.findGroupIndex(this.findPropertyNameFromColumn(column)) >= 0;
        this.sortedColumns.splice(sortIndex, 1);
        if (this.sortedColumns.length === 0) {
          this.isMultiColumnSort = false;
        }
      } else {
        this.isMultiColumnSort = true;
      }
    }
    this.updateSortedColumns(column, ascending);
  },

  updateSortedColumns: function (column, ascending) {
    const self = this;

    this.columns().forEach((col) => {
      if (col.isSortable) {
        const idx = self.sortedColumns.indexOf(col);
        if (idx === -1) {
          col.sorted(false);
          col.sortedAsc(false);
          col.sortedOrder(0);
        } else {
          col.sorted(true);
          col.sortedAsc(col === column ? ascending : col.sortedAsc());
          col.sortedOrder(self.isMultiColumnSort ? idx + 1 : 0);
        }
      }
    });
  },

  updateCurrentSort: function () {
    const self = this;
    this.currentSort = [];

    this.sortedColumns.forEach((col) => {
      if (col.sortAlias && col.sortAlias.length > 0) {
        col.sortAlias.forEach((sort) => {
          self.currentSort.push({
            propertyNameOrValueProvider: sort.propertyName,
            colIndex: sort.propertyName ? null : self.columns().indexOf(col),
            ascending: col.sortedAsc()
          });
        });
      } else {
        self.currentSort.push({
          propertyNameOrValueProvider: col.valueProvider,
          colIndex: col.propertyName ? null : self.columns().indexOf(col),
          ascending: col.sortedAsc()
        });
      }
    });

    self._setBaseSort();
  },

  _processSortOrderObject: function (sortOrder) {
    const self = this;
    let column = sortOrder.column;

    if (!column) {
      if (sortOrder.colIndex == null) {
        column = self.getColumnByProperty(sortOrder.propertyName);
      } else {
        column = self.columns()[sortOrder.colIndex];
      }
    }

    if (column) {
      if (self.sortedColumns.length > 0) {
        self.isMultiColumnSort = true;
      }

      self.setSortedColumn(column, sortOrder.ascending);
      self.currentSort.push({
        propertyNameOrValueProvider: column.valueProvider,
        colIndex: column.propertyName ? null : self.columns.indexOf(column),
        ascending: sortOrder.ascending
      });
    } else if (sortOrder.propertyName) {
      self.currentSort.push({
        propertyNameOrValueProvider: sortOrder.propertyName,
        ascending: sortOrder.ascending
      });
    }
  },

  _setBaseSort: function () {
    const self = this;
    if (!self.parent.baseSort || self.parent.baseSort.length === 0) {
      return;
    }

    self.parent.baseSort.forEach((sortOrder) => {
      self._processSortOrderObject(sortOrder);
    });
  },

  subscribeToggleAllColumn: function (column) {
    this.resetTrigger.subscribe(() => {
      if (column.selectionState) {
        column.selectionState.reset();
      }
    });
  },

  setColumnValues: function (data, propertyName, state) {
    let el;
    if (data && data.length > 0) {
      data.forEach((record) => {
        if (
          "$$controller" in record &&
          Object.prototype.hasOwnProperty.call(record.$$controller.elements, propertyName)
        ) {
          el = record.$$controller.elements[propertyName];
          if (el.visible() && !el.disabled() && !el.readOnly()) {
            record[propertyName] = state;
          }
        } else {
          record[propertyName] = state;
        }
      });
    }
  },

  getColumnValues: function (data, propertyName) {
    let state = true;
    if (data && data.length > 0) {
      data.forEach((record) => {
        if (record[propertyName] === false) {
          state = false;
        }
      });

      return state;
    }

    return false;
  },

  reset: function () {
    // this will be called by grid rendering whenever a render is triggered
    this.columns.forEach((column) => {
      column.colspan = column.visible() ? 1 : 0;
    });
  }
};

module.exports = GridHeader;
plexExport("GridHeader", GridHeader);
