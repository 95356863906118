ï»¿const $ = require("jquery");
const jsUtils = require("../../Utilities/plex-utils-js");
const actionHandler = require("../../Controls/plex-handler-action");
const dataTokens = require("../../Utilities/plex-utils-datatoken");
const plexExport = require("../../../global-export");

const DocumentProvider = function () {
  // constructor
};

DocumentProvider.prototype = {
  constructor: DocumentProvider,

  init: function (item) {
    this.config = item;

    if (this.config.documentCreatedAction) {
      this.documentCreatedAction = this.config.documentCreatedAction;
      actionHandler.initAction(this.documentCreatedAction, this.config.parent.config.parent);
    }

    if (typeof this.onInit === "function") {
      this.onInit();
    }
  },

  getDocument: function (data) {
    const self = this;
    let tokenizedData = null;

    if (self.config.tokens && self.config.tokens.length > 0) {
      tokenizedData = dataTokens.applyTokens(data, self.config.tokens);
    }

    const promise = this.onGetDocument(data, tokenizedData);

    // documents in preview will have their actions deffered by parent
    if (promise && this.documentCreatedAction && (!this.config.document || this.config.document.preview !== true)) {
      if (promise === true) {
        self.executeDocumentCreatedAction(data);
      } else if (typeof promise.done === "function") {
        promise.done((result) => {
          self.executeDocumentCreatedAction($.extend({}, data, result));
        });
      }
    }

    return promise;
  },

  onGetDocument: function (_data, _tokenizedData) {
    // this should be overridden by inheritors
  },

  executeDocumentCreatedAction: function (data) {
    if (this.documentCreatedAction) {
      actionHandler.executeAction(this.documentCreatedAction, data || {});
    }
  },

  dispose: function () {
    this.onDispose();
  },

  onDispose: function () {
    // empty but may be overriden by inheritors for additional cleanup
  }
};

jsUtils.makeExtendable(DocumentProvider);

module.exports = DocumentProvider;
plexExport("printing.documentProviders.DocumentProvider", DocumentProvider);
