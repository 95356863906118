ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");

// all changes will be considered part of the same changeset as long as they occur within this timeout
const CHANGESET_TIMEOUT = 100;

const states = {
  idle: "idle",
  pending: "pending",
  busy: "busy"
};

ko.watch.defaultOptions.valueAccessor = function (value, key, obj) {
  // get wrapped observable if it exists
  return ko.getObservable(obj, key) || value;
};

ko.undoManager = function (obj) {
  const undoStack = ko.observableArray();
  const redoStack = ko.observableArray();
  const pendingChangeset = [];

  const state = ko.observable(states.idle);

  let stackTimer;
  function loadStack() {
    if (pendingChangeset.length > 0) {
      // clear redo stack since we have new changes
      redoStack.removeAll();
      undoStack.push(pendingChangeset.splice(0, pendingChangeset.length));
    }

    state(states.idle);
  }

  function updateStack() {
    state(states.pending);
    clearTimeout(stackTimer);
    stackTimer = setTimeout(loadStack, CHANGESET_TIMEOUT);
  }

  const watchSub = ko.watch(
    obj,
    (change) => {
      if (state() === states.busy) {
        // ignore changes that occur as the result of an undo/redo
        return;
      }

      if ("push" in change.target) {
        // observable array
        pendingChangeset.push(new ArrayChange(change));
      }
      // ignore equivalent changes
      else if (change.value !== change.priorValue) {
        pendingChangeset.push(new ValueChange(change));
      }

      updateStack();
    },
    { shouldWatch: ko.isWritableObservable }
  );

  return {
    canUndo: function () {
      return undoStack().length > 0;
    },

    canRedo: function () {
      return redoStack().length > 0;
    },

    reset: function () {
      undoStack.removeAll();
      redoStack.removeAll();
    },

    undo: function () {
      if (state() !== states.idle) {
        state.subscribeOnce(this.undo, this);
        return;
      }

      const changeset = undoStack.pop();
      if (changeset) {
        state(states.busy);

        // apply changes in reverse order
        let i = changeset.length;
        while (i--) {
          changeset[i].undo();
        }

        redoStack.push(changeset);

        // make sure all updates are applied
        ko.tasks.runEarly();

        state(states.idle);
      }
    },

    redo: function () {
      if (state() !== states.idle) {
        state.subscribeOnce(this.redo, this);
        return;
      }

      const changeset = redoStack.pop();
      if (changeset) {
        state(states.busy);

        changeset.forEach((change) => {
          change.redo();
        });

        undoStack.push(changeset);
        ko.tasks.runEarly();
        state(states.idle);
      }
    },

    viewStack: function () {
      return {
        undo: undoStack(),
        redo: redoStack()
      };
    },

    dispose: function () {
      watchSub.dispose();
    }
  };
};

function ValueChange(change) {
  this.change = change;
  this.target = change.target;
}

ValueChange.prototype = {
  constructor: ValueChange,

  applyChange: function (value) {
    this.target(value);

    if (typeof this.target.flush === "function") {
      this.target.flush();
    }
  },

  redo: function () {
    this.applyChange(this.change.value);
  },

  undo: function () {
    this.applyChange(this.change.priorValue);
  }
};

function ArrayChange(change) {
  ValueChange.call(this, change);
}

ArrayChange.prototype = Object.create(ValueChange.prototype);
ArrayChange.prototype.constructor = ArrayChange;

ArrayChange.prototype.applyChange = function (value) {
  if (value) {
    this.target.splice(this.change.key, 0, value);
  } else {
    this.target.splice(this.change.key, 1);
  }
};
