const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const controllerFactory = require("./plex-controller-factory");
const actionHandler = require("./plex-handler-action");
const elementRepository = require("./plex-element-repository");
const FeatureProcessor = require("../Features/plex-feature-processor");
const FeatureResult = require("../Features/plex-feature-result");
const logger = require("../Core/plex-logger");
const valueProviderFactory = require("../Labels/plex-labels-value-providers-factory");
const formatUtils = require("../Globalization/plex-formatting");
const styleUtils = require("../Utilities/plex-utils-style");
const validation = require("../Core/plex-validation");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

require("../Knockout/knockout-promise"); // eslint-disable-line import/no-unassigned-import

const observableAttributes = {
  clientVisible: "visible",
  text: "text",
  readOnly: "readOnly",
  disabled: "disabled",
  placeholder: "placeholder",
  title: "title",
  printVisible: "printVisible"
};

function initElement(el, data, state, controller, index) {
  // pass in the element using getElementById in case an ID is used that makes jQuery cry
  const $el = $(document.getElementById(el.id));

  // if the model has a handle property save it for use in validation.
  if (el.handle) {
    $el.data("handle", el.handle);
  }

  // todo: may want to always update the controller if it is passed in
  // note: even if the control has been initialized we want to allow the ability
  // for the controller to be set
  el.parent = el.parent || controller;

  // todo: this could lead to issues - may need to allow some data to be re-init
  if (ko.unwrap(el.initialized) || !triggerIniting($el, el)) {
    return;
  }

  // copy all properties/values to a data object and wrap in an observable
  // this will track all changes so object can be posted or subscribed to
  if (el.propertyName || (el.bindableProperties && el.bindableProperties.length > 0)) {
    setupValue(el, data, state);
    setupBindings(el, data);

    // add reference to parent by property name
    if (el.parent) {
      el.parent.elements = el.parent.elements || {};

      if (
        el.parent.elements[el.propertyName] &&
        el.parent.elements[el.propertyName].designerTemplateName !== el.designerTemplateName
      ) {
        logger.warn("Dublication of binding! Element is already exists! (Property name: " + el.propertyName + ")");
      }

      el.parent.elements[el.propertyName] = el;
    }

    setupValidation(el, controller, data);
  }

  if (el.parent) {
    el.parent.elements = el.parent.elements || {};
    el.parent.elements[el.id] = el;
  }

  setupElementController(el, data, state);

  el.featureResult = setupFeatures(el, data, controller);
  setupActionElement(el, data, controller, index);
  setupEvents(el, data, controller);
  setupVisualKeyboard(el);

  if (el.type === "password") {
    setupPasswordInput(el);
  }

  if (el.valueProvider) {
    setupValueProvider(el, data, index);
  }

  // make sure this comes after setupValueProvider
  setupRevisionTracking(el, data);

  // process label as a special case
  if (el.label && el.label.id) {
    initElement(el.label, data, state, el.parent);
  }

  initChildElements(el, data, state, el.parent);

  el.afterRender = function (element) {
    if (el.controller) {
      element.$$controller = el.controller;
      if (el.controller.afterRender) {
        el.controller.afterRender(element);
      }
    }

    if (el.parent && el.parent.afterRender) {
      el.parent.afterRender(element, el);
    }

    if ($el.length > 0 && el.id) {
      elementRepository.add(el.id, el);

      $el.on("remove", () => {
        elementRepository.remove(el.id);
      });
    }
  };

  if (ko.isObservable(el.initialized)) {
    el.initialized(true);
  } else {
    el.initialized = ko.observable(true);
  }

  triggerInit($el, el);

  // if the element exists, render has already occurred
  if ($el.length > 0) {
    el.afterRender($el[0]);
  }
}

function cloneElement(el, idSuffix) {
  // todo: this might be heavy handed - need to evaluate
  const clone = $.extend(true, {}, ko.toJS(el));
  updateClone(clone, idSuffix);
  return clone;
}

function updateClone(el, idSuffix) {
  el.id += idSuffix;
  el.initialized = ko.observable(false);

  if (el.elements && el.elements.length > 0) {
    el.elements.forEach((child) => {
      updateClone(child, idSuffix);
    });
  }
}

function getDisplayValueGetter(el, data) {
  // do not track passwords!
  if (el.type === "password" || (!el.boundValue && !el.formattedValue)) {
    return function () {
      return "";
    };
  }

  if (!el.formattedValue) {
    const evaluator = function () {
      const value = el.boundValue();
      return el.formatter ? formatUtils.formatValue(value, el.formatter, data) : value;
    };

    // todo: this is a bit clunky
    if (!ko.isComputed(el.boundValue) || ko.unwrap(el.boundValue.evaluated) !== false) {
      // if already evaluated go ahead and trigger
      return (el.formattedValue = ko.computed(evaluator));
    } else {
      const observable = ko.deferredComputed(evaluator);

      // need to cascade the evaluation
      // todo: this is a bit clunky
      el.boundValue.evaluated.subscribeOnce(() => {
        observable();
      });
      return (el.formattedValue = observable);
    }
  }

  return el.formattedValue;
}

function initChildElements(el, data, state) {
  if (el.controller) {
    // the element's controller is responsible for processing any child elements
    return;
  }

  if (el.elements && el.elements.length > 0) {
    let i = el.elements.length;
    let child;

    while (i--) {
      child = el.elements[i];
      if ($.isEmptyObject(child)) {
        // get rid of any empty entries
        el.elements.splice(i, 1);
      } else {
        child.parentElement = el;
        initElement(child, data, state, el.parent);
      }
    }
  }
}

function triggerIniting($el, config) {
  /* eslint new-cap: "off" */
  const e = $.Event("initing.plex", { config });
  $el.triggerHandler(e);
  return !e.isDefaultPrevented();
}

function triggerInit($el, config) {
  /* eslint new-cap: "off" */
  const e = $.Event("init.plex", { config });
  $el.triggerHandler(e);
}

function setupValue(el, data, state) {
  if (el.bindableProperties) {
    setupBindableProperties(el, data, state);
    return;
  }

  const keyPropertyName = el.valuePropertyName || el.propertyName;
  const value = getValue(el, el.propertyName, data, state);
  let observable = dataUtils.getObservable(data, el.propertyName);

  // dropdowns & pickers have a multiselect property
  // we are using this to distinguish them since they need
  // different handling for their value properties
  if (el.multiSelect === undefined) {
    if (observable && "$$selected" in observable) {
      // do not reuse observable
      dataUtils.untrackProperty(data, el.propertyName);
      observable = null;
    }

    el.boundValue = observable = observable || dataUtils.trackProperty(data, el.propertyName);
    observable.extend({ equality: "shallow" });

    if (ko.isWritableObservable(observable)) {
      observable(value);
    }
  } else if (observable && el.selectedPropertyName) {
    if (el.selectedPropertyName === el.propertyName) {
      el.boundValue = observable.map((item) => {
        return item[keyPropertyName];
      });
      el.selected = observable;
    } else {
      el.boundValue = observable;
      el.selected = dataUtils.getObservable(data, el.selectedPropertyName);
    }
  } else if (observable && "$$selected" in observable) {
    // restore values from the observable
    el.boundValue = observable;
    el.selected = observable.$$selected;
  } else {
    setupMultiValue(el, data, value, keyPropertyName);
  }

  if (el.parent && "commitTrigger" in el.parent) {
    el.committedValue = el.boundValue.extend({
      commitEdit: {
        commitTrigger: el.parent.commitTrigger,
        rollbackTrigger: el.parent.rollbackTrigger
      }
    });
  }
}

function setupMultiValue(el, data, value, propertyName) {
  if (el.selectedPropertyName) {
    value = dataUtils.getValue(data, el.selectedPropertyName);
  }

  // for pickers & dropdowns the selected items are held in their `selected` array
  // use an array for multi-selectable items, even if the particular instance is a single select
  // this will make the interface between them consistent.
  if (el.selected && el.selected.length > 0) {
    value = el.selected;
  }

  let displayableValueConfig = {
    sourcePropertyName: el.propertyName,
    targetPropertyName: el.valuePropertyName || el.propertyName,
    sourceDisplayPropertyName: el.initialDisplayPropertyName || el.displayPropertyName,
    targetDisplayPropertyName: el.displayPropertyName
  };

  if (value && Array.isArray(value) && value.length > 0 && !el.displayTextExpression) {
    value = value.map((item) => {
      return toDisplayableValue(item, el, displayableValueConfig, item[propertyName]);
    });
  } else if (
    (value || value === false || (value === 0 && el.allowZeroIdentityValue !== false)) &&
    !Array.isArray(value)
  ) {
    value = [toDisplayableValue(data, el, displayableValueConfig, value)];
  }

  el.selected = ko.observableArray(value || []);

  const valueMapper = el.selected.filter((item) => item !== undefined).map((item) => item[propertyName]);

  if (!el.selectedPropertyName || el.selectedPropertyName !== el.propertyName) {
    displayableValueConfig = {
      sourcePropertyName: el.valuePropertyName || el.propertyName,
      targetPropertyName: el.valuePropertyName || el.propertyName,
      sourceDisplayPropertyName: el.displayPropertyName,
      targetDisplayPropertyName: el.displayPropertyName
    };

    // the read method will only return keys
    // the write method will pass on the items to the `selected` collection
    el.boundValue = ko.pureComputed({
      read: valueMapper,
      write: function (values) {
        if (Array.isArray(el.selected())) {
          if (values) {
            values = Array.isArray(values) ? values : [values];
            el.selected(
              values.map((item) => {
                return toDisplayableValue(item, el, displayableValueConfig);
              })
            );
          } else {
            el.selected.removeAll();
          }
        } else {
          // todo: the checked binding for radios converts an array into a regular object
          // this seems like a bug within knockout. we should either create
          // a custom binding to use instead or submit this as a bug to knockout.
          el.selected(toDisplayableValue(values, el, displayableValueConfig));
        }
      }
    });

    el.boundValue.selectByKey = function (keyValue) {
      const rows = ko.unwrap(el.controller.data);
      if (rows && rows.length > 0) {
        const selectedRecord = ko.utils.arrayFirst(rows, (row) => {
          return row[displayableValueConfig.targetPropertyName] === keyValue;
        });
        if (selectedRecord) {
          el.boundValue(selectedRecord);
        }
      }
    };

    // pass on array changes to the computed observable
    valueMapper.subscribe(
      (changes) => {
        el.boundValue.notifySubscribers(changes, "arrayChange");
      },
      null,
      "arrayChange"
    );

    dataUtils.setValue(data, el.propertyName, el.boundValue);

    // save a reference to the selected items on the observable
    el.boundValue.$$selected = el.selected;
    el.boundValue.$$keyPropertyName = displayableValueConfig.targetPropertyName;
    el.boundValue.$$displayPropertyName = displayableValueConfig.targetDisplayPropertyName;
  } else {
    el.boundValue = valueMapper;
  }

  if (el.selectedPropertyName) {
    dataUtils.setValue(data, el.selectedPropertyName, el.selected);
  }
}

function getValue(el, propertyName, data, state) {
  let value;

  // prefer state, then data value, then element value
  if (state) {
    // if the element has a controller associated with it then don't
    // set the value because it's a complex object and the controller
    // will handle that through the override of "restoreState" later.
    const factory = el.viewName && controllerFactory.get(el.viewName);
    if (factory) {
      value = null;
    } else {
      value = dataUtils.getValue(state, propertyName);
    }
  }

  if (value == null || dataUtils.isEmpty(value)) {
    value = dataUtils.getValue(data, propertyName);
  }

  if (el.checkedValue == null && (/checkbox/i.test(el.viewName) || /radiobutton/i.test(el.viewName))) {
    // convert to boolean
    value = !!value;
  }

  return value == null ? el.defaultValue : value;
}

function setupBindableProperties(el, data, _state) {
  if (el.bindableProperties.length > 0) {
    // designate the first property as `propertyName`
    el.propertyName = el.propertyName || el.bindableProperties[0].propertyName;

    el.bindableProperties.forEach((mapping) => {
      const propertyName = mapping.propertyName;
      const bindingName = mapping.alias || propertyName;
      const observable = dataUtils.trackProperty(data, propertyName);

      el[bindingName] = observable;
    });
  }
}

function setupValueProvider(el, data, index) {
  const provider = valueProviderFactory.create(el);

  el.value = ko.computedPromise(() => {
    return provider.getValue(data, index);
  });

  const formattedObservable = ko.computedPromise(() => {
    return provider.getFormattedValue(data, index);
  });

  el.formattedValue = ko.pureComputed(() => {
    if (el.featureResult().content) {
      return el.featureResult().content;
    }

    const formattedValue = formattedObservable();
    if (el.emptyText && dataUtils.isEmpty(formattedValue)) {
      return el.emptyText;
    }

    return formattedValue;
  });
}

function setupActionElement(el, data, controller, index) {
  if (!el.action) {
    return;
  }

  setupAction(el.action, data, controller);

  // set action as disabled if the element OR the action is disabled
  el.action.disabled = el.action.disabled.extend({ or: el.disabled });

  el.executeAction = function () {
    if (ko.isObservable(controller.isBusy) && controller.isBusy()) {
      controller.isBusy.subscribeOnce(() => {
        return el.executeAction();
      });

      return null;
    }

    return el.action.executeAction.apply(null, arguments);
  };

  // this is strictly for the visible link - this allows right-click open behavior
  el.href = ko.pureComputed(() => {
    if (typeof el.action.href !== "function") {
      return el.action.href;
    }

    return el.action.href(data, index);
  });

  // this controls in the view whether the link is displayed
  el.authorized = el.action.authorized;
  el.supported = ko.pureComputed(() => {
    return !el.disabled() && el.href() && ko.unwrap(el.authorized);
  });
}

function setupAction(action, data, controller) {
  actionHandler.initAction(action, controller, data);
  action.executeAction = function () {
    const args = $.makeArray(arguments);
    return actionHandler.executeAction(action, data, args.length > 1 ? args[1] : null);
  };
}

function setupEvents(el, data, controller) {
  if (el.events && el.events.length > 0) {
    el.events.forEach((event) => {
      if (event.action) {
        setupAction(event.action, data, controller);
      }
    });
  }
}

function setupElementAttributes(el) {
  for (const attr in observableAttributes) {
    if (
      Object.prototype.hasOwnProperty.call(observableAttributes, attr) &&
      attr in el &&
      !ko.isObservable(el[observableAttributes[attr]])
    ) {
      el[observableAttributes[attr]] = ko.observable(el[attr]);
    }
  }

  // shouldValidate is used to determine if element should be validated. This was added after the need to decipher
  // between hidden elements from a parent's VisibleWhen condition being false (which means no validation) and
  // elements in a collapsed form section (which should be validated)
  el.shouldValidate = ko.pureComputed(() => {
    return ko.unwrap(el.visible) && (!el.parentElement || ko.unwrap(el.parentElement.shouldValidate));
  });
}

function setupFeatures(el, data, controller) {
  setupElementAttributes(el);

  if (!el.features || el.features.length === 0) {
    // we can short circuit and skip all processing
    el.css = ko.observable("");
    el.style = ko.observable({});
    el.attr = ko.observable({});

    return ko.observable(new FeatureResult());
  }

  const featureProcessor = new FeatureProcessor(el.features, el, controller);
  const featurePromise = ko.computedPromise(() => featureProcessor.process(data));

  // if the initial value is a promise then the value will end up undefined
  // fall back to an empty feature result until that promise resolves if so
  const featureResult = ko.pureComputed(() => featurePromise() || new FeatureResult());
  let priorStyle;

  el.style = ko
    .pureComputed(() => {
      const currentStyle = styleUtils.toJavaScriptStyle(featureResult().style);
      const priorKeys = priorStyle ? Object.keys(priorStyle) : [];

      if (priorKeys.length === 0) {
        return (priorStyle = currentStyle);
      }

      const changedStyle = {};
      Object.keys(currentStyle).forEach((key) => {
        changedStyle[key] = currentStyle[key];
      });

      priorKeys.forEach((key) => {
        // need to keep empty values so they are removed
        if (!(key in changedStyle)) {
          changedStyle[key] = "";
        }
      });

      return (priorStyle = changedStyle);
    })
    .extend({ equality: "deep" });

  // this might seem inefficient but knockout will cache the result so
  // the feature should not be re-processed with every evaluation
  el.css = ko.pureComputed(() => featureResult().css.join(" ")).extend({ equality: "deep" });
  el.attr = ko.pureComputed(() => featureResult().attr).extend({ equality: "deep" });

  // these are settable so wrap in writable computed
  const visible = el.visible;
  el.visible = ko.pureComputed({
    read: () => visible() && featureResult().render !== false,
    write: visible
  });

  const printVisible = el.printVisible;
  el.printVisible = ko.pureComputed({
    read: () => printVisible() && featureResult().render !== false,
    write: printVisible
  });

  const disabled = el.disabled;
  el.disabled = ko.pureComputed({
    read: () => ko.unwrap(disabled) || featureResult().disabled,
    write: disabled
  });

  const readOnly = el.readOnly;
  el.readOnly = ko.pureComputed({
    read: () => ko.unwrap(readOnly) || featureResult().readOnly,
    write: readOnly
  });

  return featureResult;
}

function setupElementController(el, data, state) {
  if (!el.viewName) {
    return;
  }

  let controller;
  el.clientViewName = el.clientViewName || resolveViewName(el.viewName);

  const factory = el.viewName && controllerFactory.get(el.viewName);
  if (factory) {
    controller = factory.create(el, data);

    // add reference of control controller to parent
    if (el.parent && el.parent.elements && el.propertyName in el.parent.elements) {
      el.parent.elements[el.propertyName].controller = controller;
    }

    el.controller = controller;

    // restore state
    if (state && el.propertyName in state) {
      // use controller if override method is defined
      if ("restoreState" in controller) {
        controller.restoreState(state[el.propertyName]);
      }
      // otherwise use fallback method of state restoration
      else {
        dataUtils.setValue(data, el.propertyName, state[el.propertyName]);
      }
    }
  }
}

function setupBindings(el, data) {
  if (el.bindings && el.bindings.length > 0) {
    el.bindings.forEach((binding) => {
      if (!dataUtils.hasProperty(data, binding.targetPropertyName)) {
        // just create the property - don't do anything with it
        dataUtils.setValue(data, binding.targetPropertyName, ko.observable());
      }
    });
  }
}

function setupValidation(el, controller, data) {
  if (controller && controller.validator && !controller.controllerInitsPropertyValidation) {
    const $elements = controller.$element.find($("[name='" + el.propertyName + "']"));
    if ($elements.length > 0) {
      controller.$elements = controller.$elements || {};
      controller.$elements[el.propertyName] = [$elements];
      controller.validator.initPropertyValidation(el.propertyName);
    }
  }

  el.required = ko.pureComputed(() => {
    if (controller && controller.validator) {
      return controller.validator.getElementRequiredState(el.propertyName, data, controller.validator);
    }

    return validation.requiredStates.notRequired;
  });
}

function setupRevisionTracking(el, data) {
  if (!el.propertyName) {
    return;
  }

  // Add a display value getter, and set the initial display value
  el.displayValue = el.displayValue || getDisplayValueGetter(el, data);

  if (ko.isObservable(el.displayValue.evaluated) && !el.displayValue.evaluated()) {
    // need to set this when the value is set, since it is deferred
    el.displayValue.evaluated.subscribeOnce(() => {
      el.initialDisplayValue = el.displayValue.initialValue();
    });
  } else {
    el.initialDisplayValue = el.displayValue();
  }

  if (el.parent && "commitTrigger" in el.parent) {
    el.committedDisplayValue = el.displayValue.extend({
      commitEdit: {
        commitTrigger: el.parent.commitTrigger,
        rollbackTrigger: el.parent.rollbackTrigger
      }
    });
  }
}

function setDisplayableValue(obj, item, config, displayableValueConfig, value) {
  const {
    sourceDisplayPropertyName,
    targetDisplayPropertyName,
    sourcePropertyName,
    targetPropertyName
  } = displayableValueConfig;

  // if no value is specified and both properties are in the item then it can be returned
  // this is probably only going to occur during a write
  if (value === undefined && sourcePropertyName in item && sourceDisplayPropertyName in item) {
    return item;
  }

  const hasDisplayProperty = "displayPropertyName" in config || "displayTextExpression" in config;
  if (!hasDisplayProperty && String(value) === String(item[targetPropertyName])) {
    // If the config has no display value and the identity matches, use the actual item. This only applies
    // to the FilePicker which supports multiple selections but does not have a display value.
    return item;
  }

  if (targetDisplayPropertyName) {
    obj[targetDisplayPropertyName] = dataUtils.getValue(item, sourceDisplayPropertyName);
  }

  obj[targetPropertyName] = value || dataUtils.getValue(item, sourcePropertyName);
  return obj;
}

function toDisplayableValue(item, config, displayableValueConfig, value) {
  // create a blank record so that property accessors such as the display
  // text expression in the controller initialization don't error out
  const obj = { ...config.emptyRecord };

  if (item && typeof item === "object") {
    return setDisplayableValue(obj, item, config, displayableValueConfig, value);
  }

  obj[displayableValueConfig.targetPropertyName] = value || item;
  return obj;
}

function setupVisualKeyboard(el) {
  // todo: create hooks into element initialization so this and other niche cases can be handled within own files
  const visualKeyboard = plexImport("visualKeyboard", true);
  if (visualKeyboard) {
    el.visualKeyboard = visualKeyboard.getKeyboard(el);
  }
}

function setupPasswordInput(el) {
  $(el).passwordInput();
}

function resolveViewName(name) {
  return name.replace(/\//g, "-").replace(/_/g, "").toLowerCase();
}

const api = {
  initElement,
  setupFeatures,
  setupEvents,
  cloneElement
};

module.exports = api;
plexExport("ElementHandler", api);
