const ko = require("knockout");
const arrayUtils = require("../Utilities/plex-utils-arrays");
require("./knockout-subscribable-ext"); // eslint-disable-line import/no-unassigned-import

const arrayMethods = ["concat", "every", "forEach", "indexOf", "join", "lastIndexOf", "reduce", "reduceRight", "some"];
function addArrayMethods(observable) {
  arrayMethods.forEach((methodName) => {
    observable[methodName] =
      observable[methodName] ||
      function () {
        const underlyingArray = this.peek() || [];
        if (!Array.isArray(underlyingArray)) {
          throw new TypeError();
        }

        return underlyingArray[methodName].apply(underlyingArray, arguments);
      };
  });
}

// add non-mutating methods to the observable array - this will make dealing
// with observables more intuitive as the api will match regular arrays
addArrayMethods(ko.observableArray.fn);

ko.observableArray.fn.pushAll =
  ko.observableArray.fn.pushAll ||
  function (valuesToPush) {
    /// <summary>
    /// Pushes all the provided items into the observable array, triggering
    /// notifications after all items have been added.
    /// </summary>
    /// <param name="valuesToPush">The array of values to push into the array.</param>

    const underlyingArray = this.peek();
    this.valueWillMutate();
    ko.utils.arrayPushAll(underlyingArray, valuesToPush);
    this.valueHasMutated();
  };

ko.observableArray.fn.mergeSort = ko.observableArray.fn.stableSort = function (comparer) {
  const underlyingArray = this.peek();
  let i = underlyingArray.length;
  const sortedArray = arrayUtils.stableSort(underlyingArray, comparer);

  this.valueWillMutate();

  // sync values - we want to keep the array reference
  // since we're already iterating through the array and comparing, we can make a diff
  // at the same time and save knockout some processing
  const changes = [];
  while (i--) {
    if (underlyingArray[i] !== sortedArray[i]) {
      changes.push({ index: i, moved: i, status: "added", value: sortedArray[i] });
      changes.push({ index: i, moved: i, status: "deleted", value: underlyingArray[i] });

      underlyingArray[i] = sortedArray[i];
    }
  }

  if (changes.length > 0) {
    this.notifySubscribers(changes.reverse(), "arrayChange");
    this.notifySubscribers(underlyingArray, "change");
  }

  return underlyingArray;
};

ko.subscribable.fn.pause = function () {
  // already paused
  if (this._paused) {
    return;
  }

  this._paused = {
    subscribe: this.subscribe,
    notifySubscribers: this.notifySubscribers
  };

  this.subscribe = function () {
    // just create a dummy subscribe that we will immediately dispose
    const sub = ko.subscribable.fn.subscribe.apply(ko.observable(), arguments);
    sub.dispose();
    return sub;
  };

  this.notifySubscribers = function () {
    // ignore - we could possibly add an option to capture the notifications and send them on resume if the need arises
  };
};

ko.subscribable.fn.resume = function () {
  if (!this._paused) {
    return;
  }

  this.subscribe = this._paused.subscribe;
  this.notifySubscribers = this._paused.notifySubscribers;
  delete this._paused;
};

// This extension allows to receive previous value of an observable as well as latest one.
// https://github.com/knockout/knockout/issues/914#issuecomment-139111515
ko.subscribable.fn.subscribeChanged = function (callback, context) {
  let savedValue = this.peek();
  return this.subscribe((newValue) => {
    const oldValue = savedValue;
    savedValue = newValue;
    callback.call(context, newValue, oldValue);
  });
};

/**
 * Determines if the object is a Knockout observable array.
 * @param {Object} instance
 * @returns {Boolean}
 */
ko.isObservableArray =
  ko.isObservableArray ||
  function (instance) {
    return ko.isObservable(instance) && typeof instance.push === "function";
  };

module.exports = {
  addNonMutatingArrayMethods: addArrayMethods
};
