ï»¿const valueProviderFactory = require("./plex-labels-value-providers-factory");
const aggregateFactory = require("../Grid/Aggregates/plex-grid-aggregate-factory");
const defaultValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");

const AggregateValueProvider = defaultValueProvider.extend({
  init: function () {
    this.valueProvider = valueProviderFactory.create({ valueProvider: this.options.recordValueProvider });
    const provider = aggregateFactory.create(this, this.options.aggregate);

    this.evaluator = function (firstRecord, groupContext) {
      return provider.getValue(groupContext?.data ?? firstRecord, groupContext);
    };
  },

  getValue: function () {
    return this.evaluator.apply(this, arguments);
  }
});

module.exports = AggregateValueProvider;
plexExport("labels.valueProviders.AggregateValueProvider", AggregateValueProvider);
