ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const elementHandler = require("./plex-handler-element");
const repository = require("./plex-model-repository");
const controllerFactory = require("./plex-controller-factory");
const plexImport = require("../../global-import");

const GRID_CELL_COUNT = 3;

const ReadOnlyGridController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

// note: this grid essentially has two modes:
// - one is a typical data binding with child elements, server side markup etc
// - the other will have an array of data and will be bound via properties and use a template
ReadOnlyGridController.prototype = {
  constructor: ReadOnlyGridController,

  init: function () {
    const self = this;
    this.$element = $(document.getElementById(this.config.id));
    this.elements = {};

    // the grid will have it's own set of data
    // we want to track changes so that it can be rebound
    this.data = this.config.data || this.model;
    dataUtils.trackObject(this.data);

    // This allows use to keep same refence to data
    // that elements are bound to
    const observable = ko.observable(this.data);
    Object.defineProperty(this, "data", {
      enumerable: true,
      get: observable,
      set: this.load
    });

    const $target = this.$element.find("tbody");
    if (this.config.valuePropertyName) {
      this.cellCount = GRID_CELL_COUNT;
      this.rowCount = ko.pureComputed(() => {
        return Math.ceil(self.data.length / self.cellCount);
      });

      if ($target.length > 0) {
        ko.renderTemplate("dynamic-readonly-grid", this, {}, $target[0]);
      } else {
        // todo: implement client side dynamic readonly grid
        throw new Error("Dynamic ReadOnlyGrid has not yet been implemented for use within a grid.");
      }
    } else {
      this.elementCollection = this.config.elements.map((el) => {
        elementHandler.initElement(el, self.data, null, self);
        return el;
      });

      const visibleElements = this.elementCollection.filter((el) => {
        return ko.unwrap(el.visible);
      });

      const gridLength = calculateGridLength(visibleElements, self.config.cellCount);

      const visibleCount = ko.pureComputed(() => {
        return visibleElements.length;
      });

      this.cellCount = ko.pureComputed(() => {
        return Math.min(visibleCount(), self.config.cellCount);
      });

      this.rowCount = ko.pureComputed(() => {
        return Math.ceil(gridLength / self.cellCount());
      });

      if ($target.length > 0) {
        ko.applyBindings(this, $target[0]);
      }
    }

    repository.add(this.config.id, this.data);
    plexImport("currentPage")[this.config.id] = this;
  },

  load: function (newData) {
    dataUtils.updateObject(this.data, newData);
  },

  dispose: function () {
    repository.remove(this.config.id);
  }
};

function calculateGridLength(elements, cellCount) {
  let gridLength = 0;
  let emptyCellCount = cellCount;
  let i = 0;

  while (i < elements.length) {
    if (elements[i].cellSpan <= emptyCellCount) {
      gridLength += elements[i].cellSpan;
      emptyCellCount -= elements[i].cellSpan;

      if (emptyCellCount === 0) {
        emptyCellCount = cellCount;
      }

      i++;
    } else {
      gridLength += emptyCellCount;
      emptyCellCount = cellCount;
    }
  }

  return gridLength;
}

ReadOnlyGridController.create = function (config, model) {
  return new ReadOnlyGridController(config, model);
};

controllerFactory.register("Elements/_ReadOnlyGrid", ReadOnlyGridController);
