ï»¿const $ = require("jquery");
const actionHandler = require("../Controls/plex-handler-action");
const Feature = require("./plex-features");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const RefreshFeature = Feature.extend({
  onInit: function () {
    if (!("load" in this.parent)) {
      throw new Error("The Refresh feature must be bound to a controller that has a `load` function.");
    }

    if (this.config.updateAction) {
      if (this.config.sourceId) {
        this.config.updateAction.sourceId = this.config.sourceId;
      }

      this.action = this.config.updateAction;
      actionHandler.initAction(this.action, this.parent);

      if (this.config.interval > 0) {
        this.deferObject = jsUtils.defer(this.executeOnInterval, this, arguments, this.config.interval * 1000);
      }
    } else if (this.parent.config.searchActionId) {
      // in VP updateAction is not set, so search action will be used
      const searchAction = actionHandler.getAction(this.parent.config.searchActionId);
      if (searchAction) {
        this.action = $.extend(true, {}, searchAction);
        actionHandler.initAction(this.action, this.parent);

        // do not publish search to prevent grid from loading multiple times.
        this.action.publishSearch = false;

        if (this.config.interval > 0) {
          this.deferObject = jsUtils.defer(this.executeOnInterval, this, arguments, this.config.interval * 1000);
        }
      }
    }
  },

  executeOnInterval: function () {
    if (this.parent && "isDisposed" in this.parent && this.parent.isDisposed()) {
      return;
    }

    // set ready variable so that the feature does not execute during
    // initialization and only executes during an interval
    this.ready = true;
    this.execute();
    this.ready = false;

    // setup next call
    this.deferObject = jsUtils.defer(this.executeOnInterval, this, arguments, this.config.interval * 1000);
  },

  onExecute: function (record) {
    const self = this;
    if (
      self.ready &&
      (!this.parent.config.searchActionId ||
        (this.parent.config.searchActionId && this.parent.searched && this.parent.searched()))
    ) {
      actionHandler.executeAction(this.action, record).then((results) => {
        // Note: executeAction invokes load action for grid
        if (typeof self.parent.load !== "function") {
          return;
        }

        self.parent.load(results);
      });
    }
  },

  onDispose: function () {
    if (this.deferObject) {
      this.deferObject.cancel();
    }
  }
});

module.exports = RefreshFeature;
plexExport("features.Refresh", RefreshFeature);
