ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");

// cache size once looked up
let scrollbarSize;

// http://forum.jquery.com/topic/jquery-dimensions-should-have-a-method-to-return-the-scrollbar-size
$.getScrollBarSize = function () {
  if (scrollbarSize) {
    return scrollbarSize;
  }

  const inner = $("<p></p>").css({
    width: "100%",
    height: "100%"
  });
  const outer = $("<div></div>")
    .css({
      position: "absolute",
      width: "100px",
      height: "100px",
      top: "0",
      left: "0",
      visibility: "hidden",
      overflow: "hidden"
    })
    .append(inner);

  $(document.body).append(outer);

  const w1 = inner.width();
  const h1 = inner.height();
  outer.css("overflow", "scroll");

  let w2 = inner.width();
  let h2 = inner.height();
  if (w1 === w2 && outer[0].clientWidth) {
    w2 = outer[0].clientWidth;
  }
  if (h1 === h2 && outer[0].clientHeight) {
    h2 = outer[0].clientHeight;
  }

  outer.detach();
  scrollbarSize = {
    width: w1 - w2,
    height: h1 - h2
  };

  return scrollbarSize;
};

// http://upshots.org/javascript/jquery-test-if-element-is-in-viewport-visible-on-screen
$.fn.isOnScreen = function () {
  const win = $(window);

  const viewport = {
    top: win.scrollTop(),
    left: win.scrollLeft()
  };

  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();

  const bounds = this.offset();
  bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();

  return !(
    viewport.right < bounds.left ||
    viewport.left > bounds.right ||
    viewport.bottom < bounds.top ||
    viewport.top > bounds.bottom
  );
};

// http://stackoverflow.com/questions/499126/jquery-set-cursor-position-in-text-area
$.fn.selectRange = function (start, end) {
  if (end === undefined) {
    end = start;
  }

  return this.each(function () {
    if (this.focus) {
      this.focus();
    }

    if ("selectionStart" in this) {
      this.selectionStart = start;
      this.selectionEnd = end;
    } else if (this.setSelectionRange) {
      this.setSelectionRange(start, end);
    } else if (this.createTextRange) {
      const range = this.createTextRange();
      range.collapse(true);
      range.moveEnd("character", end);
      range.moveStart("character", start);
      range.select();
    }
  });
};

$.fn.resizeParent = function () {
  const $resizable = this.closest(".ui-resizable");
  if ($resizable.length > 0) {
    return $resizable;
  }

  return $(window);
};
