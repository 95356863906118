ï»¿const repository = require("../Controls/plex-model-repository");
const CustomerDate = require("../Globalization/plex-customer-date");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

function findPropertyCaseInsensitive(prop, obj) {
  if (prop in obj) {
    return prop;
  }

  prop = prop.toLowerCase();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && key.toLowerCase() === prop) {
      return key;
    }
  }

  return null;
}

const ColumnToken = Token.extend({
  getValue: function (record, _i, _records) {
    const prop = this.getAttr("value", arguments);
    const sourceId = this.getAttr("sourceId", arguments);

    if (!prop || !record) {
      return null;
    }

    if (sourceId) {
      record = repository.get(sourceId);
    }

    const resolvedPropertyName = findPropertyCaseInsensitive(prop, record);
    if (!resolvedPropertyName) {
      return null;
    }

    const value = record[resolvedPropertyName];
    if (value && value instanceof Date) {
      return new CustomerDate(value);
    }

    return value;
  }
});

module.exports = ColumnToken;
plexExport("tokens.column", ColumnToken);
