ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const plexExport = require("../../global-export");

const BlockerMessageType = {
  Html: 0,
  KnockoutTemplate: 1
};

const defaults = {
  overlayCss: {
    opacity: 0.4,
    backgroundColor: "#000",
    cursor: "wait"
  },
  message: "<i class='plex-waiting-spinner'></i>",
  messageClass: "plex-waiting-message",
  messageType: BlockerMessageType.Html,
  zIndex: 0,
  delay: 750
};

const overlay = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const opacityTransitionCss = {
  "-webkit-transition": "opacity .4s ease-in-out",
  "-moz-transition": "opacity .4s ease-in-out",
  "-ms-transition": "opacity .4s ease-in-out",
  "-o-transition": "opacity .4s ease-in-out",
  transition: "opacity .4s ease-in-out"
};

const BLOCKER_CLASS = "plex-blocked";
const BLOCKER_INLINE_CLASS = "plex-blocked-inline";
const INLINE_MATCHER = /^(?:inline|table)/;

function getParent(el) {
  return el === document || el === window ? document.body : el;
}

const Blocker = function (el, options) {
  /// <summary>Constructor function which creates a ui blocker object.</summary>
  /// <param name="el" type="Element">The element to block</param>
  /// <param name="options" type="PlainObject">Options object: includes
  ///     overlayCss: css properties to assign to the overlay.
  ///     message: the message (with html) to use for the message. (default: 'Please Wait...')
  ///     messageClass: the css class to apply to the message. (default: 'plex-waiting-message')
  ///     zIndex: the z-index to apply to the overlay. (default: 9000)
  ///     delay: the time interval (msec) to not darken screen within it. (default: 750)
  /// </param>

  this.$element = $(el);
  this.options = $.extend({}, defaults, options);
  this.init();
};

Blocker.prototype = {
  constructor: Blocker,

  init: function () {
    this.fullPage = this.$element[0] === document.body;
    this.isInline = !this.fullPage && INLINE_MATCHER.test(this.$element.css("display"));
  },

  show: function (delay) {
    /// <summary>Displays the blocking overlay.</summary>

    const self = this;

    if (!delay && delay !== 0) {
      delay = self.fullPage ? self.options.delay : 0;
    }

    let zIndex = self.fullPage ? 9000 : self.options.zIndex;

    if (zIndex === 0) {
      if (self.$element.css("z-index") === "auto") {
        zIndex = 1;
      } else {
        zIndex = parseInt(self.$element.css("z-index"), 10) + 10;
      }
    }

    if (!self.fullPage && self.$element.css("position") === "static") {
      self._wasStatic = true;
      self.$element.css("position", "relative");
    }

    const position = self.fullPage ? "fixed" : "absolute";

    self.$overlay = $("<div>")
      .css($.extend({}, overlay, self.options.overlayCss, { opacity: 0 }))
      .css(opacityTransitionCss)
      .css({
        zIndex,
        position
      });

    self.$element.prepend(self.$overlay);

    if (self.options.message && !self.isInline) {
      self.$message = $("<div>")
        .css({
          zIndex: zIndex + 20,
          position
        })
        .addClass(self.options.messageClass)
        .appendTo(self.$element);

      const showBlockerMessage = function () {
        // this method is wrapped in setTimeout, overlay and message may sometimes be removed before it
        if (!self.$message) {
          return;
        }

        switch (self.options.messageType) {
          case BlockerMessageType.KnockoutTemplate:
            if (self.options.messageModel) {
              ko.renderTemplate(self.options.message, self.options.messageModel, {}, self.$message[0]);
            } else {
              throw new Error("messageModel not specified when using a Knockout sourced blocker.");
            }

            break;
          default:
            self.$message.html(self.options.message);
        }

        self.$message.position({
          my: "center",
          at: "center",
          of: self.fullPage ? window : self.$element
        });
      };

      // Timeout to make animated blocker's gif be shown (at least a bit longer) in IE on browser redirect (navigate action)
      // https://jira-plex.atlassian.net/browse/IP-4581
      // http://stackoverflow.com/questions/780560/animated-gif-in-ie-stopping
      setTimeout(showBlockerMessage, 0);
    }

    self.$element.addClass(self.isInline ? BLOCKER_INLINE_CLASS : BLOCKER_CLASS);

    self.delayId = setTimeout(() => {
      self.delayId = null;

      // set opacity that will trigger CSS transition
      self.$overlay.css({ opacity: self.options.overlayCss.opacity });
    }, delay);
  },

  hide: function (onOverlayRemoved) {
    /// <summary>Hides the overlay.</summary>

    const self = this;

    // store and replace instance in case another block is invoked while the prior block
    // is still being transitioned
    let $overlay = this.$overlay;
    this.$overlay = null;

    function removeMessage() {
      if (self.$message) {
        self.$message.remove();
        self.$message = null;
      }
    }

    function removeOverlay() {
      if ($overlay) {
        $overlay.remove();
        self.reset();
        $overlay = null;

        if (onOverlayRemoved) {
          onOverlayRemoved();
        }
      }
    }

    if (this.delayId) {
      // if the element has a timer associated opacity transition hasn't been started yet
      clearTimeout(this.delayId);

      self.$element.removeClass(BLOCKER_CLASS + " " + BLOCKER_INLINE_CLASS);

      removeMessage();
      removeOverlay();

      return;
    }

    self.$element.removeClass(BLOCKER_CLASS + " " + BLOCKER_INLINE_CLASS);

    removeMessage();

    if ($overlay) {
      if ($("html").hasClass("no-csstransitions") || $overlay.css("opacity") === "0") {
        // Modernizr adds the "no-csstransitions" class if CSS transitions are not supported in the current browser
        // if overlay's opacity is already 0, no transitionend event will trigger when we explicitly set it to 0
        // so, remove overlay immediately in these cases
        removeOverlay();
        return;
      }

      // set opacity that will trigger CSS transition
      $overlay.css({ opacity: 0 });

      // wait CSS transition to complete
      $overlay.one("transitionend webkitTransitionEnd", removeOverlay);

      // add timer in case transition events don't fire - transition should fire within .5s
      setTimeout(removeOverlay, 500);
    } else {
      self.reset();
    }
  },

  reset: function () {
    if (this._wasStatic) {
      this.$element.css("position", "static");
      this._wasStatic = false;
    }
  }
};

$.fn.block = function (options) {
  /// <summary>Blocks the selected elements by showing an overlay and a wait message (by default).</summary>
  /// <param name="options" type="PlainObject">Options:
  ///     overlayCss: css properties to assign to the overlay.
  ///     message: the message (with html) to use for the message. (default: 'Please Wait...')
  ///     messageClass: the css class to apply to the message. (default: 'plex-waiting-message')
  ///     zIndex: the z-index to apply to the overlay. (default: 9000)
  /// </param>

  return this.each(function () {
    const $this = $(getParent(this));
    let data = $this.data("blocker");

    if (!data) {
      $this.data("blocker", (data = new Blocker($this[0], options)));
      data.show(options && options.delay);
    } else if (typeof options === "string" && options in data) {
      data[options]();
    }
  });
};

// this is just a helper function to call 'hide' on the blocked item
$.fn.unblock = function () {
  /// <summary>Unblocks the selected element.</summary>

  return this.each(function () {
    let $this = $(getParent(this));
    if (!$this.data("blocker")) {
      $this = $this.find(":data('blocker')");
    }

    $this.each(function () {
      const $el = $(this);
      const data = $el.data("blocker");

      if (data) {
        data.hide(() => {
          $el.removeData("blocker");
        });
      }
    });
  });
};

$.fn.block.constructor = Blocker;

Blocker.BlockerMessageType = BlockerMessageType;
module.exports = Blocker;

plexExport("Blocker", Blocker);
plexExport("BlockerMessageType", BlockerMessageType);
