ï»¿const saveAs = require("saveas");
const htmlSerializer = require("./plex-exporter-serializers-html");
const plexExport = require("../../global-export");

// overwrite the xlsx serializer with this serializer
// this is using the html serializer with excel specific leading/closing syntax
const LegacyExcelSerializer = htmlSerializer.extend({
  extension: ".xls",
  altExtension: ".xls",
  mimeType: "application/msexcel",

  serializeBegin: function (data) {
    const header =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet1</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>';
    return header + this.serializeHeader(data);
  }
});

module.exports = LegacyExcelSerializer;

// this fallback should only occur if saveAs support is not detected (IE9)
if (!saveAs) {
  plexExport("exporter.serializers.xlsx", LegacyExcelSerializer);
}
