ï»¿const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");

const MinOperation = Operation.extend({
  getValue: function (records) {
    /// <summary>Gets the minimum value from the provided data.</summary>
    /// <param name="records">The data to evaluate.</param>
    /// <returns type="Number">The minimum value.</returns>

    // this is a neat little trick - since apply takes an array,
    // you can invoke the min with an array and get the lowest value
    const recordValues = this.getRecordValues(records);

    return recordValues.length ? Math.min.apply(Math, recordValues) : "";
  }
});

module.exports = MinOperation;
plexExport("grid.aggregates.MinOperation", MinOperation);
