ï»¿/* eslint-disable no-invalid-this */
const ko = require("knockout");
const ElementHandler = require("./plex-handler-element");
const ControllerFactory = require("./plex-controller-factory");

require("../Knockout/knockout-extender-tobool"); // eslint-disable-line import/no-unassigned-import

const CheckboxController = function (config, model) {
  this.config = config;
  this.model = model;
  this.init();
};

CheckboxController.prototype = {
  init: function () {
    if (this.config.suffixLabel) {
      ElementHandler.initElement(this.config.suffixLabel, this.config.data, null, this.config.parent);
    }

    // todo: we may want to lock the value if it becomes conditionally read-only
    if (ko.unwrap(this.config.readOnly) === false) {
      // default to true
      const checkedValue = this.config.checkedValue == null ? true : this.config.checkedValue;
      if (typeof checkedValue === "boolean") {
        // intercept value updates and convert to boolean
        this.config.boundValue = this.config.boundValue.extend({ toBool: checkedValue });
      }
    }
  }
};

CheckboxController.create = function (config, model) {
  return new CheckboxController(config, model);
};

ControllerFactory.register("Elements/_Checkbox", CheckboxController);
module.exports = CheckboxController;
