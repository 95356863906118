const url = require("./plex-parsing-url");
const pageState = require("./plex-pagestate");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const state = Object.create(null);
const qs = url.parseQueryString();

state.pageStateId = pageState.getCurrentState().id;
state.parentWindowId = qs.__pwid;
state.parentActionId = qs.__action;
state.logLevel = qs.__debug;
state.pageMessage = qs.__message;

const localFeatures = (qs.__css || "")
  .split(",")
  .concat((qs.__features || "").split(","))
  .filter(Boolean)
  .reduce((c, f) => {
    c[f] = true;
    return c;
  }, {});

let mergedFeatures;

// features injected into page are not ready yet, so we lazy load them in the getter
Object.defineProperty(state, "features", {
  get() {
    if (mergedFeatures) {
      return mergedFeatures;
    }

    const appState = plexImport("appState");
    const remoteFeatures = appState?.features;
    if (remoteFeatures) {
      return (mergedFeatures = Object.assign({}, localFeatures, remoteFeatures));
    }

    return localFeatures;
  },
  enumerable: true,
  configurable: true
});

plexExport("env", state);
module.exports = state;
