ï»¿const CustomerDate = require("../Globalization/plex-customer-date");
const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const plexExport = require("../../global-export");

const ConstantValueProvider = DefaultDataLabelValueProvider.extend({
  getValue: function () {
    const value = this.options.valueProvider.value;
    if (value instanceof Date) {
      return new CustomerDate(value);
    }

    return value;
  }
});

module.exports = ConstantValueProvider;
plexExport("labels.valueProviders.ConstantValueProvider", ConstantValueProvider);
