ï»¿const regexUtils = require("../Utilities/plex-utils-regex");
const jsUtils = require("../Utilities/plex-utils-js");
const plexExport = require("../../global-export");

const subscriptions = [];

function createMatcher(topic) {
  // create a regex to match topics
  return new RegExp("^" + regexUtils.escapeText(topic).replace("\\*", ".+") + "$", "i");
}

const pubsub = {
  publish: function (topic, ...args /* , args */) {
    /// <summary>Notifies subscribers on the topic executing their callbacks.</summary>
    /// <param name="topic" type="String">The topic to publish.</param>
    /// <param name="args" type="Object">Any additional arguments will be passed to the callback.</param>

    if (!topic) {
      return;
    }

    const context = {
      topic
    };

    // Looping backwards to support subscription removals
    let i = subscriptions.length;
    while (i--) {
      const sub = subscriptions[i];
      if (sub.matcher.test(topic)) {
        if (sub.async) {
          jsUtils.defer(sub.callback, sub.context || context, args);
        } else {
          sub.callback.apply(sub.context || context, args);
        }
      }
    }
  },

  subscribe: function (topic, callback, context, options) {
    /// <summary>
    /// Subscribes a callback function to be executed when the topic is published
    /// </summary>
    /// <param name="topic" type="String">The topic to be subscribed to. (Wildcards are accepted.)</param>
    /// <param name="callback" type="Function">The callback function</param>
    /// <param name="context" type="Object">The context to be used for `this` of the callback. (If unassigned `null` is used.)</param>
    /// <param name="options" type="Object">Additional options that can be set for the subscription. Includes `async` (boolean) - default false.</param>

    if (!topic) {
      throw new Error("Topic must be set when subscribing.");
    }

    const subscription = {
      topic,
      matcher: createMatcher(topic),
      callback,
      context: context || null,
      async: options && options.async,
      dispose: function () {
        pubsub.unsubscribe(this);
        this.disposed = true;
      }
    };

    subscriptions.push(subscription);
    return subscription;
  },

  unsubscribe: function (subscription) {
    const index = subscriptions.indexOf(subscription);
    if (index !== -1) {
      subscriptions.splice(index, 1);
    }
  },

  hasSubscriptions: function (topic) {
    return subscriptions.some((sub) => {
      return sub.matcher.test(topic);
    });
  },

  getSubscriptions: function (topic) {
    if (!topic) {
      return subscriptions;
    }

    return subscriptions.filter((sub) => {
      return sub.matcher.test(topic);
    });
  }
};

module.exports = pubsub;
plexExport("pubsub", pubsub);
