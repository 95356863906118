ï»¿const $ = require("jquery");
const logger = require("./plex-logger");
const sessionManager = require("./plex-session");
const errorHandler = require("./plex-handler-error");
const notify = require("./plex-notify");
const parseJSON = require("./plex-parsing-json");
const parsing = require("./plex-parsing-url");
const { buildUrl, SessionTokenName, AppStateTokenName } = require("./plex-navigate");
const plexImport = require("../../global-import");

const formRequestVerificationHeader = "Plex-Form-Verification-Token";
const cookieRequestVerificationHeader = "Plex-Cookie-Verification-Token";
const formRequestVerificationId = "__formRequestVerification";
const cookieRequestVerificationId = "__cookieRequestVerification";
const errorLoggingUrlRgx = /\/error\/logtracekit/i;

let unloading = false;

const statusCodes = {
  aborted: 0,
  forbidden: 403,
  sessionExpired: 419,
  publicException: 519
};

$.support.cors = true;

// set ajax defaults
$.ajaxSetup({
  converters: {
    "text json": parseJSON
  },
  cache: false,
  contentType: "application/json; charset=utf-8",
  dataType: "json",
  crossDomain: true,
  xhrFields: {
    withCredentials: true
  }
});

const hasSessionToken = (query) => {
  return !!query[AppStateTokenName] || !!query[SessionTokenName];
};

// this executes on every ajax request and ensures that every request gets an appstate token
$.ajaxPrefilter((options) => {
  const qs = parsing.parseQueryString(options.url);
  const data = {};

  if (options.headers?.["x-plex-spa"] !== "1") {
    const currentApplication = plexImport("currentApplication");
    if ((!("sourceActionKey" in qs) || !qs.sourceActionKey) && currentApplication) {
      data.sourceActionKey = currentApplication.ActionKey;
    }
  }

  if (!hasSessionToken(qs) || data.sourceActionKey) {
    options.url = buildUrl(options.url, data);
  }

  options.headers = options.headers || {};

  options.headers[formRequestVerificationHeader] =
    $("#" + formRequestVerificationId).val() || $(`[name="${formRequestVerificationId}"]`).val() || "";
  options.headers[cookieRequestVerificationHeader] = $("#" + cookieRequestVerificationId).val() || "";
  options.headers["X-Requested-With"] = "XMLHttpRequest";
});

// check if page is being reloaded
$(window).on("beforeunload", () => {
  unloading = true;
});

// ajax error handling
$(document).ajaxError((_e, xhr, options, rawErrMsg) => {
  if (errorLoggingUrlRgx.test(options.url)) {
    // ignore any errors that might come from logging a client error
    return;
  }

  if (unloading) {
    // firefox treats unfinished ajax requests as errors - do not display these errors in the UI
    logger.warn("error detected during unloading: " + xhr.status);
    return;
  }

  if (xhr.status === statusCodes.aborted) {
    logger.warn("ajax request has been aborted");
    return;
  }

  if (xhr.status === statusCodes.sessionExpired) {
    sessionManager.expireSession();
    return;
  }

  // our error responses have a custom structure so look for this first
  const response = xhr.responseJSON;
  if (response && response.Message) {
    errorHandler.showErrorResponse(response);
    return;
  }

  let errorMessage = rawErrMsg;
  let fullErrorStack = errorMessage;

  // HTTP status text only is terrible; make this better
  if (rawErrMsg === xhr.statusText) {
    fullErrorStack = "HTTP " + xhr.status + ": " + xhr.statusText;

    if (options.url) {
      fullErrorStack += "\r\n" + options.url;
    }
  }

  if (response && response.ExceptionMessage) {
    errorMessage = response.ExceptionMessage;
    fullErrorStack += [response.Message, response.ExceptionType].join("\r\n");
  }

  if (fullErrorStack) {
    const sourceUrl = parsing.parseUrl(options.url);
    const elmahUrl = `${sourceUrl.scheme}://${sourceUrl.host}${sourceUrl.port ? ":" + sourceUrl.port : ""}/elmah.axd`;

    fullErrorStack += "\r\n" + elmahUrl;
    logger.error(fullErrorStack);
  }

  notify.error({ text: "An error occurred contacting the remote server ({1})", tokens: [errorMessage] });
});
