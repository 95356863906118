ï»¿const $ = require("jquery");
const styleUtils = require("../Utilities/plex-utils-style");
const browser = require("../Core/plex-browser");

if (!("open" in document.createElement("details"))) {
  // since this is a potentially expensive selector only add it if we need to
  styleUtils.injectRule("details:not([open]) > *:not(summary) { display: none; }");

  $(document).on("click", "details", function (e) {
    const $element = $(e.target);
    if ($element.is(":input") || $element.is("option")) {
      return;
    }

    const $el = $(this);

    // toggle the open attr - css will take care of the rest
    const open = $el.attr("open");
    if (open) {
      $el.removeAttr("open");
    } else {
      $el.attr("open", "open");
    }
  });
} else if (!browser.canSelectDropdownOption) {
  $(document).on("click", "select", (e) => {
    const $dropdown = $(e.target);
    if ($dropdown && $dropdown.closest("details")[0] != null) {
      e.preventDefault();
    }
  });
}
