ï»¿const jsUtils = require("../Utilities/plex-utils-js");
const dataUtils = require("../Utilities/plex-utils-data");
const formatUtils = require("../Globalization/plex-formatting");
const stringUtils = require("../Utilities/plex-utils-strings");
const CustomerDate = require("../Globalization/plex-customer-date");
const plexExport = require("../../global-export");

function evaluatePossiblyDeferred(value, evalFn) {
  if (jsUtils.isPromise(value)) {
    return value.then(evalFn);
  }

  return evalFn(value);
}

function DefaultDataLabelValueProvider(dataLabel) {
  // eslint-disable-next-line no-invalid-this
  this.dataLabel = dataLabel;
}

DefaultDataLabelValueProvider.prototype = {
  init: function () {
    // can be overriden
  },

  getValue: function (data) {
    const value = dataUtils.getValue(data, this.options.propertyName, true);
    return evaluatePossiblyDeferred(value, (v) => {
      // note: VisionPlex bridge uses data labels for all data types, as data types
      // cannot be predetermined. We need to guarantee dates are in customer (PCN) time.
      if (v instanceof Date) {
        return new CustomerDate(v);
      }

      return v;
    });
  },

  getFormattedValue: function (data) {
    const value = this.getValue.apply(this, arguments);
    const formatter = this.options.formatter;

    return evaluatePossiblyDeferred(value, (resolvedValue) => {
      return stringUtils.escapeHtml(formatUtils.formatValue(resolvedValue, formatter, data), {
        ignoreLineBreaks: true
      });
    });
  },

  getRecord: function (record) {
    // todo: this may not be necessary
    return record;
  }
};

jsUtils.makeExtendable(DefaultDataLabelValueProvider);

module.exports = DefaultDataLabelValueProvider;
plexExport("labels.valueProviders.DefaultDataLabelValueProvider", DefaultDataLabelValueProvider);
