ï»¿const $ = require("jquery");
const ko = require("knockout");
const columnBuilder = require("./plex-grid-column-builder");
const DefaultColumn = require("./plex-grid-column");
const plexExport = require("../../../global-export");

const GroupColumn = DefaultColumn.extend({
  init: function (column) {
    const self = this;
    let i = column.columns.length;
    let child;

    while (i--) {
      child = column.columns[i];
      if ($.isEmptyObject(child)) {
        column.columns.splice(i, 1);
      } else {
        child.childIndex = i;
        child.parent = column;

        columnBuilder.build(child, this.parent);
      }
    }

    column.headerCss = "plex-grid-column-group";

    this.applyAttributes(column);

    column.colSpan = ko.deferredComputed(() => {
      return self.getColSpan(column.columns);
    });

    const visibleColumns = ko.deferredComputed(() => {
      return column.columns.filter((c) => {
        return c.visible();
      });
    });

    const printVisibleColumns = ko.deferredComputed(() => {
      return column.columns.filter((c) => {
        return c.printVisible();
      });
    });

    // this will be used to determine the colspan
    column.visibleChildCount = ko.deferredComputed(() => {
      return visibleColumns().length;
    });

    column.printVisibleChildCount = ko.deferredComputed(() => {
      return printVisibleColumns().length;
    });

    column.firstChild = ko.deferredComputed(() => {
      return visibleColumns()[0];
    });

    column.lastChild = ko.deferredComputed(() => {
      const all = visibleColumns();
      return all.length > 0 && all[all.length - 1];
    });

    // replace with computed so we can also check that all children are visible
    const visibleObservable = column.visible;
    column.visible = ko.deferredComputed({
      read: function () {
        if (!visibleObservable()) {
          return false;
        }

        return column.visibleChildCount() > 0;
      },
      write: visibleObservable
    });

    const printVisibleObservable = column.printVisible;
    column.printVisible = ko.deferredComputed({
      read: function () {
        if (!printVisibleObservable()) {
          return false;
        }

        return column.printVisibleChildCount() > 0;
      },
      write: printVisibleObservable
    });

    column.$$initialized = true;
  },

  getColSpan: function (columns) {
    const self = this;
    let cspan = 0;

    const visColumns = columns.filter((c) => {
      return c.visible();
    });

    visColumns.forEach((col) => {
      if (col.columns && !col.composite) {
        cspan += self.getColSpan(col.columns);
      } else {
        cspan += 1;
      }
    });

    return cspan;
  }
});

module.exports = GroupColumn;
plexExport("grid.columns.Group", GroupColumn);
