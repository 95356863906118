ï»¿const ko = require("knockout");
const $ = require("jquery");

ko.bindingHandlers.clickToggle = {
  init: function (element, valueAccessor) {
    const observable = valueAccessor();
    if (!ko.isObservable(observable)) {
      throw new Error("The `clickToggle` binding only works with observable values");
    }

    $(element).on("click.plex.clickToggle", () => {
      observable(!observable());
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(element).off("click.plex.clickToggle");
    });
  }
};
