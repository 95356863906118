ï»¿const $ = require("jquery");
const dataUtils = require("./plex-utils-data");
const nav = require("../Core/plex-navigate");
const plexExport = require("../../global-export");
const enterpriseManager = require("../Core/plex-enterprise");
const compareUtils = require("../Utilities/plex-utils-comparer");

const BYTES_IN_MEGABYTE = 1048576;
const UPLOAD_SIZE_LIMIT = 1073741824;
const GET_ATTACHMENTS_COUNT_PATH = "/Platform/AttachmentSystem/GetAttachmentsCount";
const GET_ATTACHMENTS_COUNT_PRE_RECORD_PATH = "/Platform/AttachmentSystem/GetAttachmentsCountPreRecord";
const ADD_ATTACHMENTS_PATH = "/Platform/AttachmentSystem/AddAttachments";
const UPLOAD_ATTACHMENTS_PATH = "/Platform/DocumentControlSystem/UploadFile";

const ATTACHMENTS_COUNT_TOKEN_KEYWORD = "$$attachmentCount";
const CURRENT_PCN_TOKEN_KEYWORD = "$$currentPCN";

// Return a promise that resolves to an attachment count.
function getAttachmentsCount(attachmentContext) {
  return getAttachmentsCounts([attachmentContext]).then((r) => {
    return r.length > 0 ? r[0].attachmentCount : 0;
  });
}

// Return a promise that resolves to an array of attachment counts.
// Note: If the count for a particular record in the input is zero, it
// is omitted. The values in the resulting array have no defined sort order.
function getAttachmentsCounts(attachmentContexts) {
  const pendingQueries = [];
  const results = [];

  const attachmentContextsGroupedByPcn = attachmentContexts.reduce((acc, attachmentContext) => {
    const key = attachmentContext.enterprisePcn || CURRENT_PCN_TOKEN_KEYWORD;
    acc[key] = acc[key] || [];
    acc[key].push(attachmentContext);
    return acc;
  }, {});

  Object.keys(attachmentContextsGroupedByPcn).forEach((pcn) => {
    // Look up attachment counts for all attachments in a given PCN.
    const filteredAttachmentContexts = attachmentContextsGroupedByPcn[pcn].filter((acg) => acg.recordKeyValue !== null);

    if (filteredAttachmentContexts.length > 0) {
      const attachmentsCountQuery = queryAttachmentsCounts(pcn, filteredAttachmentContexts).then(
        (attachmentCountContexts) => {
          results.push(...attachmentCountContexts);
        }
      );

      pendingQueries.push(attachmentsCountQuery);
    }

    // Look up each pre-attachment count individually.
    // Unlike the regular attachment count action, the pre-record count action
    // only supports querying one recordKeyValue at a time.
    attachmentContextsGroupedByPcn[pcn]
      .filter((acg) => acg.preRecordIdentity !== null)
      .forEach((attachmentContext) => {
        const preAttachmentsCountQuery = queryPreAttachmentsCount(pcn, attachmentContext).then(
          (attachmentCountContext) => {
            if (preAttachmentsCountQuery.attachmentCount !== 0) {
              results.push(attachmentCountContext);
            }
          }
        );

        pendingQueries.push(preAttachmentsCountQuery);
      });
  });

  return $.when(...pendingQueries).then(() => results);
}

function queryAttachmentsCounts(pcn, attachmentContexts) {
  const urlParameters = {};
  if (pcn !== CURRENT_PCN_TOKEN_KEYWORD) {
    urlParameters[enterpriseManager.crossPcnTokenName] = pcn;
  }

  const attachmentParameters = attachmentContexts.map((attachmentContext) => {
    return {
      AttachmentGroupKey: attachmentContext.attachmentGroupKey,
      RecordKeyValue: attachmentContext.recordKeyValue
    };
  });

  return nav
    .post(nav.buildUrl(GET_ATTACHMENTS_COUNT_PATH, urlParameters), attachmentParameters, { showOverlay: false })
    .then((response) => {
      return response.Data.map((responseRow) => {
        return {
          attachmentGroupKey: responseRow.AttachmentGroupKey,
          recordKeyValue: parseInt(responseRow.RecordKeyValue, 10),
          preRecordIdentity: null,
          enterprisePcn: pcn === CURRENT_PCN_TOKEN_KEYWORD ? null : parseInt(pcn, 10),
          attachmentCount: responseRow.AttachmentCount
        };
      });
    });
}

function queryPreAttachmentsCount(pcn, attachmentContext) {
  const urlParameters = {};
  if (pcn !== CURRENT_PCN_TOKEN_KEYWORD) {
    urlParameters[enterpriseManager.crossPcnTokenName] = pcn;
  }

  const preAttachmentParameters = {
    AttachmentGroupKey: attachmentContext.attachmentGroupKey,
    PreRecordIdentity: attachmentContext.preRecordIdentity
  };

  return nav
    .post(nav.buildUrl(GET_ATTACHMENTS_COUNT_PRE_RECORD_PATH, urlParameters), preAttachmentParameters, {
      showOverlay: false
    })
    .then((response) => {
      return {
        attachmentGroupKey: attachmentContext.attachmentGroupKey,
        recordKeyValue: null,
        preRecordIdentity: attachmentContext.preRecordIdentity,
        enterprisePcn: pcn === CURRENT_PCN_TOKEN_KEYWORD ? null : parseInt(pcn, 10),
        attachmentCount: response.Data.AttachmentCount
      };
    });
}

function getAttachmentsCountTokenName(attachmentGroupKey) {
  return ATTACHMENTS_COUNT_TOKEN_KEYWORD + attachmentGroupKey;
}

function createAttachmentContext(record, config) {
  return {
    attachmentGroupKey: config.attachmentGroupKey,
    recordKeyValue:
      config.recordKeyValuePropertyName && config.recordKeyValuePropertyName in record
        ? dataUtils.getValue(record, config.recordKeyValuePropertyName)
        : null,
    preRecordIdentity:
      config.preRecordIdentityPropertyName && config.preRecordIdentityPropertyName in record
        ? dataUtils.getValue(record, config.preRecordIdentityPropertyName)
        : null,
    enterprisePcn:
      config.enterprisePcnPropertyName && config.enterprisePcnPropertyName in record
        ? dataUtils.getValue(record, config.enterprisePcnPropertyName)
        : null
  };
}

function createAttachmentContextFromAttachmentDialogResults(attachmentGroupKey, dialogResults) {
  return {
    attachmentGroupKey,
    recordKeyValue: dialogResults.RecordKeyValue || null,
    preRecordIdentity: dialogResults.PreRecordIdentity || null,
    enterprisePcn: dialogResults[enterpriseManager.crossPcnTokenName] || null
  };
}

function compareAttachmentContext(context1, context2) {
  return (
    context1.attachmentGroupKey === context2.attachmentGroupKey &&
    compareUtils.compare(context1.recordKeyValue, context2.recordKeyValue) &&
    context1.preRecordIdentity === context2.preRecordIdentity &&
    context1.enterprisePcn === context2.enterprisePcn
  );
}

function createUploadAttachmentUrl(attachmentContext) {
  const parameters = {
    sourceActionKey: attachmentContext.sourceActionKey
  };

  return nav.buildUrl(UPLOAD_ATTACHMENTS_PATH, parameters);
}

function createAddAttachmentUrl(attachmentContext) {
  const parameters = {
    AttachmentGroupKey: attachmentContext.attachmentGroupKey,
    RecordKeyValue: attachmentContext.recordKeyValue,
    PreRecordIdentity: attachmentContext.preRecordIdentity
  };

  if (attachmentContext.enterprisePcn) {
    parameters[enterpriseManager.crossPcnTokenName] = attachmentContext.enterprisePcn;
  }

  return nav.buildUrl(ADD_ATTACHMENTS_PATH, parameters);
}

function getUploadSizeLimit() {
  return UPLOAD_SIZE_LIMIT;
}

function validateUploadSize(file) {
  if (file.size > UPLOAD_SIZE_LIMIT) {
    return {
      valid: false,
      error: {
        text: "File size cannot exceed {1} mb.",
        tokens: [Math.floor(UPLOAD_SIZE_LIMIT / BYTES_IN_MEGABYTE)],
        autoGlossarize: true
      }
    };
  }

  return { valid: true, error: "" };
}

const api = {
  createAttachmentContext,
  createAttachmentContextFromAttachmentDialogResults,
  compareAttachmentContext,
  getAttachmentsCounts,
  getAttachmentsCount,
  createAddAttachmentUrl,
  getAttachmentsCountTokenName,
  getUploadSizeLimit,
  validateUploadSize,
  createUploadAttachmentUrl
};

module.exports = api;
plexExport("utils.dcsAttachments", api);
