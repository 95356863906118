/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const DialogController = require("./plex-dialog-controller");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const notifyQueue = [];
const defaultNotifyOptions = {
  autoGlossarize: true,
  title: "Notification"
};

const notifyDequeue = function () {
  if (notifyQueue.length === 0) {
    return;
  }

  let notifyObj = notifyQueue.splice(0, 1)[0];

  // Note: remove first element, else it be shown two times
  if (notifyObj.firstQueueElement) {
    if (notifyQueue.length > 0) {
      notifyObj = notifyQueue.splice(0, 1)[0];
    } else {
      return;
    }
  }

  const options = notifyObj.options || {};
  options.fromQueue = true;

  /* eslint new-cap: "off" */
  Notify(notifyObj.message, notifyObj.callback, options);
};

const notifyEnqueue = function (messages, callback, options) {
  for (let i = 0; i < messages.length; i++) {
    const notifyObj = {
      message: messages[i],
      callback: null,
      options
    };

    // Note: callback should be invoke after last notify in array
    if (i === messages.length - 1) {
      notifyObj.callback = callback;
    }

    if (notifyQueue.length === 0) {
      notifyObj.firstQueueElement = true;
    }

    notifyQueue.push(notifyObj);
  }
};

const processNotifyMessage = function (message, callback, options) {
  let firstQueueMessageIsArray = false;
  let messages = [];
  let messageText = message;

  if (Array.isArray(message)) {
    messages = message;
    messageText = messages[0];

    if (notifyQueue.length === 0) {
      firstQueueMessageIsArray = true;
    }
  } else {
    messages = [messageText];
  }

  notifyEnqueue(messages, callback, options);

  // Note: callback should be null else it be invoke after first notify in messages array
  let nextCallback = callback;
  if (messages.length > 1) {
    nextCallback = null;
  }

  return {
    message: messageText,
    callback: nextCallback,
    options,
    firstQueueMessageIsArray
  };
};

function Notify(messageOrArray, callbackOrOptions, optionsOrEmpty) {
  if (!(this instanceof Notify)) {
    return new Notify(messageOrArray, callbackOrOptions, optionsOrEmpty);
  }

  const $deferred = new $.Deferred();

  let message = messageOrArray;
  let callback = callbackOrOptions;
  let options = optionsOrEmpty;

  if (!message && typeof callback === "function") {
    callback();

    $deferred.resolve();
    notifyDequeue();

    return $deferred.promise();
  }

  const fromQueue = options?.fromQueue;
  if (!fromQueue) {
    const processedMessage = processNotifyMessage(message, callback, options);
    ({ message, callback, options } = processedMessage);

    // Note: if message is array (need for first invoke)
    const firstQueueMessageIsArray = processedMessage.firstQueueMessageIsArray;

    // Note: return if length > 1 because first item in array is displayed not from queue
    if (notifyQueue.length > 1 && !firstQueueMessageIsArray) {
      return $deferred.promise();
    }
  }

  if (!options && callback && typeof callback !== "function") {
    options = callback;
    callback = null;
  }

  if (typeof message === "string") {
    message = { text: message };
  }

  options = $.extend({}, defaultNotifyOptions, options);
  message.autoGlossarize = options.autoGlossarize;

  this.options = options;
  this.$element = $("<div class='dialog-wrapper'>").appendTo(document.body);
  this.message = message;

  this.closeNotify = function () {
    this.dialog.close();
  };

  ko.renderTemplate("dialog-notification", this, {}, this.$element[0]);

  this.dialog = DialogController.create({
    dialogElement: this.$element.find(".plex-dialog"),
    autoShow: true,
    returnAction: function () {
      if (callback) {
        callback();
      }

      $deferred.resolve();
      notifyDequeue();
    },
    closeAction: function () {
      $deferred.resolve();
      notifyDequeue();
    }
  });

  return $deferred.promise();
}

plexExport("Dialogs.Notify", Notify);

module.exports = function (...args) {
  // Using global so that local references will pick up replaced instance for SPA.
  return plexImport("Dialogs.Notify")(...args);
};
