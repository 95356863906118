const $ = require("jquery");
const ko = require("knockout");
const htmlUtils = require("../Utilities/plex-utils-html");
const plexExport = require("../../global-export");

const protectedAttributes = ["id"];
const elementProperties = ["readOnly", "disabled"];
const knockoutPlaceholder = /<!--\[(?:ko|pl)_memo/;

const FeatureApplicator = {
  apply: function () {
    // todo: obsolete apply - it's a horrible name for a function
    return this.update(...arguments);
  },

  update: function (element, features) {
    const $element = $(element);
    if ($element.length === 0) {
      return;
    }

    $element.attr(features.attr || {}).css(features.style || {});

    elementProperties.forEach((propertyName) => {
      if (propertyName in features) {
        $element.prop(propertyName, features[propertyName]);
      }
    });

    if (features.css && features.css.length > 0) {
      $element.addClass(features.css.join(" "));
    }

    if (features.removedCss && features.removedCss.length > 0) {
      $element.removeClass(features.removedCss.join(" "));
    }

    if (features.content) {
      ko.cleanNode($element[0]);
      $element.html(features.content);

      // apply late bound bindings if exists
      if (knockoutPlaceholder.test(features.content)) {
        htmlUtils.unmemoize($element[0]);
      }
    }
  },

  remove: function (element, features) {
    const $element = $(element);
    if ($element.length === 0) {
      return;
    }

    if (features.attr && !$.isEmptyObject(features.attr)) {
      $.each(features.attr, (key) => {
        if (protectedAttributes.indexOf(key) === -1) {
          $element.removeAttr(key);
        }
      });
    }

    if (features.style && !$.isEmptyObject(features.style)) {
      $.each(features.style, (key) => {
        $element.css(key, "");
      });
    }

    elementProperties.forEach((key) => {
      if (features[key]) {
        // only remove if property is set to true
        $element.removeProp(key);
      }
    });

    if (features.css && features.css.length > 0) {
      $element.removeClass(features.css.join(" "));
    }

    if (features.content) {
      ko.cleanNode($element[0]);
      $element.empty();
    }
  }
};

module.exports = FeatureApplicator;
plexExport("features.FeatureApplicator", FeatureApplicator);
