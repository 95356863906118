/* eslint-disable no-invalid-this */
const $ = require("jquery");
const stringUtils = require("./plex-utils-strings");
const styleUtils = require("./plex-utils-style");
const htmlUtils = require("./plex-utils-html");
const logger = require("../Core/plex-logger");
const plexExport = require("../../global-export");

function getAttrHtml(tag) {
  let html = "";
  if (tag.css.length > 0) {
    tag.attrs.push({ key: "class", value: tag.css.join(" ") });
  }

  if (tag.style.length > 0) {
    tag.attrs.push({ key: "style", value: tag.style.join(";") });
  }

  if (tag.attrs.length > 0) {
    tag.attrs.forEach((attr) => {
      html += " " + attr.key + "='" + attr.value + "'";
    });
  }

  return html;
}

function HtmlTag(parent, tag) {
  this.parent = parent;
  this.tagName = tag;
  this.attrs = [];
  this.innerHtml = [];
  this.childNodes = [];
  this.css = [];
  this.style = [];
  this.features = [];
}

HtmlTag.prototype = {
  constructor: HtmlTag,

  addAttr: function (key, value) {
    if (key) {
      if (!value && typeof key === "object") {
        Object.keys(key).forEach(function (k) {
          this.addAttr(k, key[k]);
        }, this);
        return this;
      }

      if (key === "style") {
        return this.addStyle(value);
      }

      if (key === "class") {
        return this.addClass(value);
      }

      this.attrs.push({ key, value });
    }

    return this;
  },

  addClass: function (className) {
    if (className) {
      if (Array.isArray(className)) {
        className.forEach(this.addClass, this);
      } else {
        this.css.push(className);
      }
    }

    return this;
  },

  addStyle: function (key, value) {
    if (key) {
      if (typeof key === "object" && !value) {
        Object.keys(key).forEach(function (k) {
          return this.addStyle(k, key[k]);
        }, this);
      } else if (value == null) {
        // this should be a fully formed
        this.style.push(key);
      } else {
        key = styleUtils.toDashStyle(key);
        this.style.push(key + ":" + value);
      }
    }

    return this;
  },

  addFeature: function (feature) {
    this.features.push(feature);
    return this;
  },

  appendChild: function (tagName) {
    const child = new HtmlTag(this, tagName);
    this.childNodes.push(child);
    return child;
  },

  appendHtml: function (_html) {
    // allow multiple arguments to be passed in
    this.innerHtml.push.apply(this.innerHtml, arguments);
    return this;
  },

  appendHtmlFormat: function (_html, _tokens) {
    return this.appendHtml(stringUtils.format.apply(null, arguments));
  },

  close: function () {
    return this.parent;
  },

  toString: function () {
    // we are intentionally not applying features until render so the object values can be manipulated up until render
    let feature;
    while ((feature = this.features.shift())) {
      this.addClass(feature.css);
      this.addAttr(feature.attr);
      this.addStyle(feature.style);
    }

    // todo: we could add validation that the tag has been closed, but hopefully we don't need that
    let html = "<" + this.tagName + getAttrHtml(this) + ">";

    // append children
    html += this.childNodes.join("");

    // add inner html directly (todo: throw error if both children and innerhtml?)
    html += this.innerHtml.join("");

    return html + "</" + this.tagName + ">";
  }
};

function UnboundHtml(parent) {
  HtmlTag.call(this, parent);
}

UnboundHtml.prototype = Object.create(HtmlTag.prototype);
UnboundHtml.prototype.constructor = UnboundHtml;

UnboundHtml.prototype.normalize = function () {
  if (this.css.length > 0 || this.style.length > 0 || this.attrs.length > 0) {
    HtmlTag.prototype.appendHtml.call(this, getAttrHtml(this));

    this.css = [];
    this.style = [];
    this.attrs = [];
  }
};

UnboundHtml.prototype.appendHtml = function () {
  this.normalize();
  HtmlTag.prototype.appendHtml.apply(this, arguments);
  return this;
};

UnboundHtml.prototype.toString = function () {
  return this.innerHtml.join("");
};

function HtmlWriter(target) {
  this.$target = $(target);
  this.nodes = [];
}

HtmlWriter.prototype = {
  constructor: HtmlWriter,

  append: function (_html) {
    // legacy support
    if (!this.unboundHtml) {
      logger.warn("`append` is deprecated. Use `appendChild` instead.");
      this.unboundHtml = new UnboundHtml(this);
    }

    this.unboundHtml.appendHtml.apply(this.unboundHtml, arguments);
    return this.unboundHtml;
  },

  appendFormat: function () {
    return this.append(stringUtils.format.apply(null, arguments));
  },

  addAttr: function () {
    return this.unboundHtml.addAttr.apply(this.unboundHtml, arguments);
  },

  addClass: function () {
    return this.unboundHtml.addClass.apply(this.unboundHtml, arguments);
  },

  addStyle: function () {
    return this.unboundHtml.addStyle.apply(this.unboundHtml, arguments);
  },

  appendChild: function (tagName) {
    if (this.unboundHtml) {
      this.nodes.push(this.unboundHtml);
      this.unboundHtml = null;
    }

    const node = new HtmlTag(this, tagName);
    this.nodes.push(node);
    return node;
  },

  close: function () {
    // todo: for now do nothing so as not to break current usages
    return this;
  },

  render: function () {
    if (this.unboundHtml) {
      this.nodes.push(this.unboundHtml);
      this.unboundHtml = null;
    }

    if (this.nodes.length === 0) {
      return;
    }

    let html = "";
    let current;
    while ((current = this.nodes.shift())) {
      html += current.toString();
    }

    if (html) {
      const targetNode = this.$target[0];
      let index = targetNode.childNodes.length;
      this.$target.append(html);

      // only process the newly added children
      const length = targetNode.childNodes.length;
      while (index < length) {
        htmlUtils.unmemoize(targetNode.childNodes[index++]);
      }
    }
  },

  toString: function () {
    return this.nodes.join("");
  }
};

module.exports = HtmlWriter;
plexExport("HtmlWriter", HtmlWriter);
