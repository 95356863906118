ï»¿const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

const root = plexImport("grid.writers");

const WriterFactory = {
  create: function (provider, grid, options, target) {
    if (!provider || !(provider.address in root)) {
      throw new Error("Unable to find grid writer for :" + (provider && provider.address));
    }

    const writer = new root[provider.address]();
    writer.init(grid, options, target);
    return writer;
  }
};

module.exports = WriterFactory;
plexExport("grid.writers.WriterFactory", WriterFactory);
