ï»¿const TokenParser = require("../../Tokens/plex-tokens-parser");
const logger = require("../../Core/plex-logger");
const Operation = require("./plex-grid-operation");
const plexExport = require("../../../global-export");

const parser = new TokenParser();
const INFINITY_DEFAULT_VALUE = "#infinity#";
const ERROR_DEFAULT_VALUE = "#error#";

const TokenOperation = Operation.extend({
  init: function () {
    this._base.apply(this, arguments);
    this.tokenEvaluator = parser.compile(this.config.tokenText);
  },

  getValue: function (records) {
    const expression = this.tokenEvaluator(records);
    if (expression && typeof expression === "string" && this.config.evaluate) {
      try {
        /* eslint no-new-func: "off" */
        const value = new Function("return " + expression + ";")();
        if (isNaN(value)) {
          // divide by zero doesn't cause an error in JS - instead the value is
          // infinity (unless 0/0 which is NaN)
          return this.config.divideByZeroValue || ERROR_DEFAULT_VALUE;
        }

        if (!isFinite(value)) {
          return this.config.infinityValue || INFINITY_DEFAULT_VALUE;
        }

        return value;
      } catch (err) {
        logger.error("Error evaluating expression: " + expression);
        logger.error(err);
        return ERROR_DEFAULT_VALUE;
      }
    }

    return expression;
  }
});

module.exports = TokenOperation;
plexExport("grid.aggregates.TokenOperation", TokenOperation);
