ï»¿const ko = require("knockout");
const plexExport = require("../../../global-export");

const defaultCss = {
  Number: (col) => {
    if (ko.unwrap(col.scale) === 0) {
      return "plex-int-text";
    }

    return "plex-numeric-text";
  },
  DateTime: () => "plex-date-text",
  Boolean: () => "plex-boolean",
  RadioButton: () => "plex-radio",
  Image: () => "plex-image",
  PivotAggregate: () => "plex-numeric-text",
  Table: () => "plex-table-column",
  Xml: () => "plex-table-column"
};

const api = {
  toClassName: function (column) {
    let type;

    const expression = ko.unwrap(column.expression);
    if (expression) {
      type = ko.unwrap(expression.type);
    } else if (ko.unwrap(column.enableToggleAll)) {
      type = "Boolean";
    }

    type = type || ko.unwrap(column.type);
    if (type in defaultCss) {
      return defaultCss[type](column);
    }

    return "";
  }
};

module.exports = api;
plexExport("grid.columns.CssMap", api);
