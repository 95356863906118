ï»¿const dataUtils = require("../../Utilities/plex-utils-data");
const Feature = require("../../Features/plex-features");
const plexExport = require("../../../global-export");

const EVEN_ROW_CSS = "plex-grid-row-even";
const ODD_ROW_CSS = "plex-grid-row-odd";

const SuppressRepeatingValues = Feature.extend({
  onInit: function () {
    const self = this;
    // Sorting with this feature causes issues so we need to prevent the grid
    // from syncing the dom and force a rerender. In the future we might want to
    // come up with a `sync` function that can be executed within the feature
    // that will apply the necessary changes to the dom manually.
    this.parent.canSync = false;

    // The priority in which the feature processor is executed. Lower ordered priorities are processed first.
    // We want SuppressRepeatingValues feature be executed last.
    this.priority = 1000;

    // For UI consistency, use of rowspan is limited to unselectable grids only
    if (this.config.useRowSpan) {
      this.config.useRowSpan = this.parent.controller.selectable === false;
    }

    this.resetOnValue = false;
    if (this.config.resetGroupPropertyName) {
      this.resetOnValue = !this.parent.groups.some((group) => {
        return group.propertyName === self.config.resetGroupPropertyName;
      });
    }
  },

  getFormattedValue: function (record, index, records, colIndex) {
    return this.column.valueProvider.getFormattedValue(record, index, colIndex);
  },

  getRowSpan: function (records, index, colIndex) {
    let rowspan = 1;
    const length = records.length;

    while (++index < length) {
      const value = this.getFormattedValue(records[index], index, records, colIndex);
      if (!dataUtils.compareObjects(value, this._lastValue)) {
        break;
      }

      rowspan++;
    }

    return rowspan;
  },

  reset: function () {
    this._lastValue = null;
    this._currentRowBackgroundStyle = null;
    this._lastNewValueIndex = -1;
    this._rowSpan = 1;
  },

  onExecute: function (record, index, records, rowConfig, indexInGroup, group, results, colIndex) {
    if (this.shouldReset(index, indexInGroup, group, record) || index === 0 || this._lastNewValueIndex >= index) {
      this.reset();
    }

    const value = this.getFormattedValue(record, index, records, colIndex);
    const sameValue = dataUtils.compareObjects(value, this._lastValue);
    this._lastValue = value;

    if (sameValue) {
      if (this.config.useRowSpan) {
        results.render = false;
      } else {
        // clear content in order to fake a row span
        results.content = "&nbsp;";
        if (this._currentRowBackgroundStyle) {
          results.style["background-color"] = this._currentRowBackgroundStyle;
        } else {
          results.css.push(this._currentRowCss);
        }

        results.css.push("plex-grid-cell-suppressed");
      }
    } else {
      this._lastNewValueIndex = index;
      this._currentRowCss = index === 0 || this._currentRowCss === EVEN_ROW_CSS ? ODD_ROW_CSS : EVEN_ROW_CSS;
      this._currentRowBackgroundStyle =
        (rowConfig.features && rowConfig.features.style && rowConfig.features.style["background-color"]) || null;

      if (rowConfig.css.indexOf(this._currentRowCss) === -1) {
        results.css.push(this._currentRowCss);
      }

      if (this.config.useRowSpan) {
        results.attr.rowspan = this.getRowSpan(group ? group.data : records, group ? indexInGroup : index, colIndex);
        results.css.push("plex-grid-rowspan");
      }
    }

    return results;
  },

  shouldReset: function (index, indexInGroup, group, record) {
    if (this.resetOnValue) {
      const priorValue = this._resetValue;
      this._resetValue = dataUtils.getValue(record, this.config.resetGroupPropertyName);
      return !dataUtils.compareObjects(priorValue, this._resetValue);
    }

    if (group && !this._lastGroup) {
      if (!this.config.resetGroupPropertyName) {
        this._lastGroup = group;
        return true;
      }
      // reset _lastGroupIndex initial value
      // recurse up and find the group we are resetting on
      while (group && group.config && group.config.propertyName !== this.config.resetGroupPropertyName) {
        group = group.parent;
      }
      if (group) {
        this._lastGroup = group;
      }
      return true;
    }

    if (group) {
      if (!this.config.resetGroupPropertyName) {
        if (this._lastGroup !== group) {
          this._lastGroup = group;
          return true;
        }
        return false;
      }

      // recurse up and find the group we are resetting on
      while (group && group.config && group.config.propertyName !== this.config.resetGroupPropertyName) {
        group = group.parent;
      }

      // see if the group has changed
      if (group && this._lastGroup !== group) {
        this._lastGroup = group;
        return true;
      }
    }

    return false;
  }
});

module.exports = SuppressRepeatingValues;
plexExport("grid.features.SuppressRepeatingValues", SuppressRepeatingValues);
