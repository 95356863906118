const ko = require("knockout");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const ImageValueProvider = require("./plex-grid-value-providers-image");
const plexExport = require("../../../global-export");

const DocumentAsImageValueProvider = ImageValueProvider.extend({
  getUrlComponents: function (record) {
    const nodeNo = this.getValue(record);

    if (!nodeNo) {
      return null;
    }

    const url = this.column.rootUrl || "";

    const data = { NodeNo: nodeNo };

    return {
      url,
      data
    };
  },

  getHtml: function (record, _rowNumber, _columnNumber) {
    const fullUrl = this.getUrl.apply(this, arguments);
    const urlBlob = ko.observable();

    if (fullUrl) {
      const imageRequest = new XMLHttpRequest();
      imageRequest.addEventListener("load", () => urlBlob(window.URL.createObjectURL(imageRequest.response)));
      imageRequest.open("GET", fullUrl);
      imageRequest.responseType = "blob";
      imageRequest.send();
    }

    const alt =
      (this.column.alternateTextPropertyName && record[this.column.alternateTextPropertyName]) ||
      this.column.alternateText;
    const options = {
      alternateText: alt || "",
      imageUrl: urlBlob
    };

    return this.renderTemplate("elements-image", { options });
  },

  getPrintValue: function (_record) {
    const urlComponents = this.getUrlComponents.apply(this, arguments);

    if (!urlComponents) {
      return "";
    }

    return new DocumentXml("plex-control-image").addAttribute("nodeNo", urlComponents.data.NodeNo).serialize();
  }
});

module.exports = DocumentAsImageValueProvider;
plexExport("grid.valueProviders.DocumentAsImageValueProvider", DocumentAsImageValueProvider);
