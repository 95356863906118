const client = require("./plex-component-host-client");
const plexExport = require("../../global-export");

/**
 * Class which interacts with the Weigh Scale component in the
 * Plex Component Host.
 */
class WeighScaleReader {
  constructor(scaleIndex) {
    this.scaleIndex = scaleIndex;
    this.handlers = [];
  }

  /**
   * Indicates whether the weigh scale is configured for the reader.
   * @returns {Promise<boolean>} The promise which resolves to true if configured; false otherwise.
   */
  isEnabled() {
    // swallow error - assume that plugin is not enabled if error is returned
    return client
      .getSession()
      .then((s) => s.call("com.plex.weighscale.is_configured", [this.scaleIndex]))
      .then(
        (res) => res,
        () => false
      );
  }

  /**
   * Reads the scale and gives the current results. This can fail if the
   * scale is not configured or is inaccessible.
   * @returns {Promise<WeighScaleResult>} The weigh scale result.
   */
  read() {
    // let consumer handler error case - typically they would've already checked
    // whether the scale is enabled or not
    return client.getSession().then((s) => s.call("com.plex.weighscale.read", [this.scaleIndex]));
  }

  /**
   * Subscribes to the scale instance and returns results when the weight has changes.
   * @param {function} handler - Function which responses to new weight scale results
   * @returns {Subscription} Object which includes an `unwatch` method to stop subscriptions.
   */
  watch(handler) {
    if (this.handlers.length === 0) {
      client
        .getSession()
        .then((s) =>
          s
            .subscribe(`com.plex.weighscale.value_changed_${this.scaleIndex}`, this._handleChange.bind(this))
            .then((sub) => (this._changeSub = sub))
        );
    }

    this.handlers.push(handler);

    return {
      unwatch: () => this.unwatch(handler)
    };
  }

  unwatch(handler) {
    const index = this.handlers.indexOf(handler);
    if (index !== -1) {
      this.handlers.splice(index, 1);
    }

    if (this.handlers.length === 0) {
      if (this._changeSub) {
        client.getSession().then((s) => {
          s.unsubscribe(this._changeSub);
          this._changeSub = null;
        });
      }
    }
  }

  _handleChange([result]) {
    this.handlers.forEach((func) => func(result));
  }
}

module.exports = WeighScaleReader;
plexExport("native.WeighScaleReader", WeighScaleReader);
