ï»¿const formatUtils = require("../Globalization/plex-formatting");
const logger = require("../Core/plex-logger");
const Token = require("./plex-tokens-token-base");
const plexExport = require("../../global-export");

const dateFormats = {
  shortdatetime: "g",
  shortdate: "d",
  longdatetime: "F",
  longdate: "D"
};

// todo: culture code, ugh
const FormatToken = Token.extend({
  getValue: function (_record, _i, _records) {
    const value = this.getAttr("value", arguments);
    const type = this.getAttr("type", arguments);
    let format = this.getAttr("format", arguments);
    const scale = this.getAttr("scale", arguments);
    let address = "NumericFormatter";

    if (type === "date" || value instanceof Date) {
      address = "DateTimeFormatter";

      if (format && format.length > 0) {
        const formatLowerCase = format.toLowerCase();

        if (formatLowerCase in dateFormats) {
          format = dateFormats[formatLowerCase];
        }
      } else {
        format = dateFormats.shortdatetime;
      }
    }

    const formatter = {
      format,
      scale,
      address
    };

    try {
      return formatUtils.formatValue(value, formatter);
    } catch (ex) {
      // todo: create a mapping between vp format strings
      logger.error(ex);
      return String(value);
    }
  }
});

module.exports = FormatToken;
plexExport("tokens.format", FormatToken);
