ï»¿const $ = require("jquery");
const draggables = require("../DragAndDrop/plex-draggables");
const Feature = require("./plex-features");
const plexExport = require("../../global-export");

const DraggableFeature = Feature.extend({
  onInit: function () {
    /// <summary>Sets up the draggable operation.</summary>

    draggables.setupDraggable(this.config, this.parent.controller || this.parent, $("#" + this.element.id)[0]);
  }
});

module.exports = DraggableFeature;
plexExport("features.Draggable", DraggableFeature);
