const gridUtils = require("./plex-grid-utils");

const GroupRowTypes = {
  header: "headers",
  footer: "footers"
};

function getGroupSearchIterator(records, columns, pattern, patternMatcher, columnMatcher) {
  const indexers = {
    [GroupRowTypes.header]: -1,
    [GroupRowTypes.footer]: -1,
    data: -1,
    currentGroupLevel: 0,
    previousGroupLevel: -1,
    currentGroup: records.groups[0]
  };

  return {
    next: function () {
      do {
        // search headers first
        let result = _searchNonDataRows(GroupRowTypes.header, indexers, pattern, patternMatcher);
        if (result) {
          return result;
        }

        // search nested groups
        result = _searchNestedGroup(indexers, pattern, patternMatcher);
        if (result) {
          return result;
        }

        // search data records
        result = _searchDataRows(records, columns, indexers, pattern, columnMatcher);
        if (result) {
          return result;
        }

        // search footers
        result = _searchNonDataRows(GroupRowTypes.footer, indexers, pattern, patternMatcher);
        if (result) {
          return result;
        }

        if (indexers.currentGroup.isLast()) {
          if (indexers.currentGroupLevel === 0) {
            break;
          } else {
            // step out of nested group, back to parent
            // in the parent, headers & data are already visited so only search footers
            _switchToParentGroup(indexers);
          }
        } else {
          // search within next group in current hierarchy
          _switchToNextSiblingGroup(indexers);
        }
      } while (indexers.currentGroup);

      return {
        done: true
      };
    }
  };
}

function _resetRowIndexers(indexers) {
  indexers[GroupRowTypes.header] = -1;
  indexers[GroupRowTypes.footer] = -1;
  indexers.data = -1;
}

function _searchAggregates(currentGroup, config, pattern, patternMatcher) {
  return config.writer.aggregates?.some(
    (op) => op.column?.visible?.() && patternMatcher(pattern, op.getValue(currentGroup.data))
  );
}

function _shouldSearchFooters(config) {
  return config.writer.aggregates?.some((agg) => agg.column?.visible?.());
}

function _searchNonDataRows(rowType, indexers, pattern, patternMatcher) {
  const isFooter = rowType === GroupRowTypes.footer;
  const currentGroup = indexers.currentGroup;
  const rowConfig = currentGroup.config[rowType];
  const count = rowConfig?.length;
  if (count && (isFooter || indexers.currentGroupLevel >= indexers.previousGroupLevel)) {
    while (indexers[rowType] < count - 1) {
      const index = ++indexers[rowType];
      const firstRecord = gridUtils.getFirstRecord(currentGroup);
      const config = rowConfig[index];
      const value = isFooter
        ? _shouldSearchFooters(config) && config.$$footerTextEvaluator?.(firstRecord)
        : config.valueProvider.getFormattedValue?.(firstRecord);

      if (patternMatcher(pattern, value) || _searchAggregates(currentGroup, config, pattern, patternMatcher)) {
        return {
          done: false,
          value: {
            index,
            groupInfo: {
              groupIndex: currentGroup.gIndex,
              groupRecordIndex: firstRecord.$$index,
              rowType
            }
          }
        };
      }
    }
  }

  return null;
}

function _searchDataRows(records, columns, indexers, pattern, columnMatcher) {
  const currentGroup = indexers.currentGroup;
  const dataCount = currentGroup.data.length;
  // eslint-disable-next-line no-unmodified-loop-condition
  while (dataCount && indexers.data < dataCount - 1) {
    const dataIndex = currentGroup.data[++indexers.data].$$index;
    const dataValue = records[dataIndex];
    if (columns.some(columnMatcher(pattern, dataValue, dataIndex))) {
      return {
        done: false,
        value: {
          record: dataValue,
          index: dataIndex
        }
      };
    }
  }

  return null;
}

function _searchNestedGroup(indexers, pattern, patternMatcher) {
  let groupCount = indexers.currentGroup.groups.length;
  while (groupCount && indexers.currentGroupLevel >= indexers.previousGroupLevel) {
    // step into nested group
    indexers.previousGroupLevel = indexers.currentGroupLevel++;
    indexers.currentGroup = indexers.currentGroup.groups[0];
    groupCount = indexers.currentGroup.groups.length;

    // search all headers first
    _resetRowIndexers(indexers);
    const result = _searchNonDataRows(GroupRowTypes.header, indexers, pattern, patternMatcher);
    if (result) {
      return result;
    }
  }

  return null;
}

function _switchToParentGroup(indexers) {
  indexers[GroupRowTypes.footer] = -1;
  indexers.previousGroupLevel = indexers.currentGroupLevel--;
  indexers.currentGroup = indexers.currentGroup.parent;
}

function _switchToNextSiblingGroup(indexers) {
  _resetRowIndexers(indexers);
  indexers.previousGroupLevel = indexers.currentGroupLevel;
  indexers.currentGroup = indexers.currentGroup.nextSibling();
}

module.exports = {
  getGroupSearchIterator,
  GroupRowTypes
};
