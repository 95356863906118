ï»¿const Node = require("./plex-favorites-tree-node");

class ActionNode extends Node {
  _init() {
    super._init();

    this.isHomePage = this._getRecordObservable("IsHomePage");
  }
}

module.exports = ActionNode;
