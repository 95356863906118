ï»¿const $ = require("jquery");
const Action = require("./plex-actions");
const actionHandler = require("../Controls/plex-handler-action");
const plexExport = require("../../global-export");
const nav = require("../Core/plex-navigate");
const SidetabsMenuUrlTokenManager = require("../Controls/SidetabsMenu/plex-sidetabs-menu-url-token-manager");

const SIDETABS_TOKEN_NAME = "__st";

function getSidetabsActionAddress(action, commonTokens) {
  let address = action.address;

  if (commonTokens.length) {
    const filteredTokens = [...commonTokens];

    action.tokens.forEach((token) => {
      const commonToken = SidetabsMenuUrlTokenManager.findCommonToken(token, commonTokens, true);
      if (commonToken) {
        if (token.alias && !SidetabsMenuUrlTokenManager.isDataTokenName(token.alias)) {
          token.propertyName = commonToken.propertyName;
          filteredTokens.push(token);
        }
      } else {
        filteredTokens.push(token);
      }
    });

    action.tokens = filteredTokens;

    if (address) {
      const token = {};
      token[SIDETABS_TOKEN_NAME] = commonTokens
        .map((constToken) => {
          return constToken.alias ? constToken.alias : constToken.propertyName;
        })
        .join("|")
        .toString();
      address = nav.buildUrl(action.address, token);
    }
  }

  return address;
}

const SidetabsRedirectAction = Action.extend({
  onExecute: function (data, rawData, event) {
    event.preventDefault();
    const self = this;
    data = this.tokens && this.tokens.length ? data : {};
    const commonTokens = this.tokens;

    if (this.filterActionKey) {
      return nav
        .post(this.address, { submenuKey: this.submenuKey, data, filterActionKey: this.filterActionKey })
        .then((submenuItems) => {
          if (submenuItems) {
            const action =
              submenuItems.length === 1
                ? submenuItems[0].action
                : submenuItems.filter((item) => item.action.type !== "Print")[0].action;

            if (commonTokens.length) {
              action.address = getSidetabsActionAddress(action, commonTokens);
            }

            return self._executeSidetabsAction(action, rawData, event);
          }

          return $.when();
        });
    } else {
      const action = this.action;
      action.address = self.address;
      return self._executeSidetabsAction(action, rawData, event);
    }
  },

  _executeSidetabsAction: function (action, rawData, event) {
    actionHandler.initAction(action);
    return actionHandler.executeAction(action, rawData, event);
  }
});

SidetabsRedirectAction.configure = function (action) {
  if (action.action && action.action.type === "Redirect") {
    action.address = getSidetabsActionAddress(action.action, action.tokens);
    action.tokens.push(...action.action.tokens);
  }
};

module.exports = SidetabsRedirectAction;
plexExport("actions.SidetabsRedirect", SidetabsRedirectAction);
