ï»¿const plexExport = require("../../global-export");

function Disposable() {
  // constructor
}

Disposable.prototype = {
  constructor: Disposable,

  addDisposable: function (disposable) {
    /// <summary>Adds a disposable object to the objects collection.</summary>
    /// <param name="disposable">A disposable object. This is any object that has a `dispose` function.</param>
    /// <returns type="Object">Returns the disposable object for chaining.</returns>

    this._disposables = this._disposables || [];
    this._disposables.push(disposable);

    // return object for chaining
    return disposable;
  },

  dispose: function () {
    /// <summary>Disposes all disposable objects.</summary>

    if (!this._disposables) {
      return;
    }

    let sub;
    while ((sub = this._disposables.pop())) {
      if (typeof sub === "function") {
        sub();
      } else {
        sub.dispose();
      }
    }
  }
};

module.exports = Disposable;
plexExport("mixins.disposable", Disposable);
