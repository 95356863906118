ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const { ulid } = require("ulid");
const plexExport = require("../../global-export");
require("../Polyfills/array.findIndex"); // eslint-disable-line import/no-unassigned-import
require("../Polyfills/number.isInteger"); // eslint-disable-line import/no-unassigned-import

const stateTokenId = "__psid";
const sessionStorage = require("./plex-sessionstorage");

const pageStack = getPageStack() || [];
const historyEnabled = history.pushState && history.replaceState && document.origin !== "null";
const keyStore = historyEnabled ? new HistoryKeyStore(stateTokenId) : new HashKeyStore(stateTokenId);
let currentStateKey = keyStore.getKey() || ulid();
let currentState = sessionStorage.getItem(currentStateKey, {
  cacheParsers: false
});
let pageIndex;
const copyHistoryFromReferrer = sessionStorage.getItem("copy-history-from-referrer") || false;
const isDuplicatedWindow = sessionStorage.getItem("is-duplicated-window") || false;
const referrerCurrentPageId = sessionStorage.getItem("referrer-current-page-id") || null;

function HashKeyStore(id) {
  const keyRgx = new RegExp("(" + id + ")=(\\d+)");
  let currentKey;

  function updateHash(key) {
    const hash = document.location.hash;
    const value = id + "=" + key;

    if (hash) {
      if (keyRgx.test(hash)) {
        // if it already exists in the hash just replace the key
        return hash.replace(keyRgx, (_, $1) => {
          return $1 + "=" + key;
        });
      }

      return hash + "&" + value;
    }

    return "#" + value;
  }

  this.getKey = () => {
    if (currentKey) {
      return currentKey;
    }

    if (keyRgx.test(document.location.hash)) {
      return keyRgx.exec(document.location.hash)[2] || null;
    }

    return null;
  };

  this.setKey = (key) => {
    document.location.replace(updateHash(key));
  };

  this.addKey = (key) => {
    document.location.hash = updateHash(key);
  };
}

function HistoryKeyStore(id) {
  this.getKey = () => {
    return (history.state && history.state[id]) || null;
  };

  this.setKey = (key, url) => {
    const state = history.state || {};
    state[id] = key;

    // IE will cry if you pass the URL parameter with it being undefined
    // to replaceState or pushState.
    if (url) {
      history.replaceState(state, "", url);
    } else {
      history.replaceState(state, "");
    }
  };

  this.addKey = (key, url) => {
    const state = {};
    state[id] = key;

    if (url) {
      history.pushState(state, "", url);
    } else {
      history.pushState(state, "");
    }
  };
}

if (currentState) {
  pageIndex = pageStack.findIndex((x) => x.key === currentStateKey);
} else {
  saveState((currentState = createState()));
  keyStore.setKey((currentStateKey = currentState.id));
}

if (historyEnabled) {
  // we can do any actions with history, only if it's supported
  if (copyHistoryFromReferrer) {
    sessionStorage.removeItem("copy-history-from-referrer");
    loadHistoryFromReferrer();
  } else if (isDuplicatedWindow) {
    document.title = pageStack[pageIndex].title;
    $(window).on("popstate", () => {
      // we should refresh page on popstate event fired (only for duplicated windows)
      window.location.reload();
    });
  }
}

function loadHistoryFromReferrer() {
  if (pageStack && pageStack.length > 0) {
    deletePageStack(pageStack.length - 1);

    let returnIndex = 0;

    pageStack.forEach((item, index) => {
      if (index === 0) {
        keyStore.setKey(item.key, item.url);
      } else {
        keyStore.addKey(item.key, item.url);
      }
      if (referrerCurrentPageId !== null) {
        if (item.key === referrerCurrentPageId) {
          returnIndex = index + 1;
        }
      }
    });

    // set flag value in session storage, to reload previous windows on popstate
    sessionStorage.setItem("is-duplicated-window", true);

    sessionStorage.removeItem("referrer-current-page-id");

    if (referrerCurrentPageId !== null && returnIndex > 1) {
      $(window).on("popstate", () => {
        // we should refresh page on popstate event fired
        window.location.reload();
      });
      // move back
      window.history.go(returnIndex - pageStack.length);
    } else {
      // because pushing history doesn't refresh page, we should do it manually
      window.location.reload();
    }
  }
}

function isState(obj) {
  return obj && obj.id && obj.data;
}

function saveState(state) {
  sessionStorage.setItem(state.id, state);

  const index = pageStack.findIndex((x) => x.key === state.id);
  if (index === -1) {
    pageIndex = pageStack.length;
    pageStack.push({
      key: state.id,
      url: document.location.href,
      title: document.title
    });
  } else {
    pageIndex = index;
    pageStack[index] = {
      key: state.id,
      url: document.location.href,
      title: document.title
    };
  }

  currentState = state;
  updatePageStack();
}

function createState(data, id) {
  return {
    id: id || ulid(),
    data: data || {}
  };
}

function getPageStack() {
  return sessionStorage.getItem("page-stack");
}

function updatePageStack() {
  sessionStorage.setItem("page-stack", pageStack);
}

function deletePageStack(startingIndex) {
  if (!startingIndex) {
    startingIndex = 0;
  }

  let i = pageStack.length - 1;
  while (i >= startingIndex) {
    sessionStorage.removeItem(pageStack[i].key);
    pageStack.splice(i, 1);
    i--;
  }

  updatePageStack();
}

const api = {
  getCurrentState() {
    return currentState;
  },

  getTopState(predicate = () => true) {
    let i = pageIndex;

    while (i > 0) {
      const pageKey = pageStack[i--].key;
      const pageState = sessionStorage.getItem(pageKey);
      if (pageState && predicate(pageState)) {
        return pageState;
      }
    }

    return null;
  },

  updateForRedirect(index) {
    // navigating away, delete the stack above the current state
    const startIndex = $.isNumeric(index) ? index : pageIndex;
    deletePageStack(startIndex + 1);
  },

  setCurrentState(state) {
    if (!isState(state)) {
      state = createState(state, currentState.id);
    }

    currentState = state;
    sessionStorage.setItem(state.id, state);
  },

  addToCurrentState(key, data) {
    currentState[key] = data;
    sessionStorage.setItem(currentState.id, currentState);
  },

  removeFromCurrentState(key) {
    delete currentState[key];
    sessionStorage.setItem(currentState.id, currentState);
  },

  getLastKnownValue(key) {
    let i = pageStack.length - 1;
    let currentPage, state;

    while ((currentPage = pageStack[i--])) {
      state = sessionStorage.getItem(currentPage.key);
      if (state && key in state) {
        return state[key];
      }
    }

    return null;
  },

  addState(state, url) {
    if (!isState(state)) {
      state = createState(state);
    }

    state.url = url;
    keyStore.addKey(state.id, url);
    saveState(state);
  },

  replaceState(state, url) {
    if (!isState(state)) {
      state = createState(state);
    }

    state.url = url;
    keyStore.setKey(state.id, url);
    saveState(state);
  },

  updateState(state) {
    sessionStorage.setItem(state.id, state);

    if (state.id === currentStateKey) {
      currentState = state;
    }
  },

  updateFromHistory() {
    currentStateKey = keyStore.getKey();
    pageIndex = pageStack.findIndex((x) => x.key === currentStateKey);
    return (currentState = sessionStorage.getItem(currentStateKey));
  },

  clearStateByKey(key) {
    let index = pageStack.length - 1;
    let currentPage, state;

    while ((currentPage = pageStack[index--])) {
      state = sessionStorage.getItem(currentPage.key);
      if (state && key in state) {
        delete state[key];
        this.updateState(state);
      }
    }
  },

  markPageHistoryToCopy() {
    sessionStorage.setItem("copy-history-from-referrer", true);
    sessionStorage.setItem("referrer-current-page-id", this.getCurrentState().id);
    this.addToCurrentState("scrollPosition", $(document).scrollTop());
  },

  unmarkPageHistoryToCopy() {
    sessionStorage.removeItem("copy-history-from-referrer");
    sessionStorage.removeItem("referrer-current-page-id");
  }
};

module.exports = api;
plexExport("PageState", api);
