const $ = require("jquery");
const {
  createAttachmentContext,
  getAttachmentsCounts,
  compareAttachmentContext
} = require("../Utilities/plex-utils-dcs-attachments");

const pendingRequests = [];
let requestTimer = null;

function resolveObservables(requestMap, attachmentContexts, attachmentCountContexts) {
  attachmentCountContexts.forEach((attachmentCountContext) => {
    const match = attachmentContexts.findIndex((arrayContext) =>
      compareAttachmentContext(attachmentCountContext, arrayContext)
    );

    if (match >= 0) {
      requestMap[match].forEach((observable) => {
        observable(attachmentCountContext.attachmentCount);
      });
    }
  });
}

function generateRequest() {
  if (pendingRequests.length === 0) {
    return;
  }

  const requestMap = [];
  const attachmentContexts = [];

  pendingRequests.splice(0, pendingRequests.length).forEach((request) => {
    requestMap.push(request.callbacks);
    attachmentContexts.push(request.attachmentContext);
  });

  getAttachmentsCounts(attachmentContexts).then((attachmentCountContexts) => {
    resolveObservables(requestMap, attachmentContexts, attachmentCountContexts);
  });
}

const enqueueRequest = (attachmentContext, callback) => {
  const pendingRequest = pendingRequests.filter((request) => {
    return compareAttachmentContext(request.attachmentContext, attachmentContext);
  });

  if (pendingRequest.length === 0) {
    pendingRequests.push({
      attachmentContext,
      callbacks: [callback]
    });

    clearTimeout(requestTimer);
    requestTimer = setTimeout(generateRequest, 0);
  } else {
    pendingRequest[0].callbacks.push(callback);
  }
};

module.exports = {
  getAttachmentCount(config, record) {
    const deferred = new $.Deferred();

    const attachmentContext = createAttachmentContext(record, config);

    enqueueRequest(attachmentContext, deferred.resolve);
    return deferred.promise();
  }
};
