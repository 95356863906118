ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
// eslint-disable-next-line import/no-unassigned-import
require("jquery-validation");
const ko = require("knockout");
const pubsub = require("./plex-pubsub");
const dataUtils = require("../Utilities/plex-utils-data");
const expressions = require("../Expressions/plex-expressions-compiler");
const glossary = require("../Globalization/plex-glossary-handler");
const jsUtils = require("../Utilities/plex-utils-js");
const dateUtils = require("./plex-dates");
const banner = require("../Plugins/plex-banner");
const gridSelectionMode = require("../Grid/plex-grid-selectionmode");
const parsing = require("./plex-parsing-url");
const plexExport = require("../../global-export");
const domUtils = require("../Utilities/plex-utils-dom");
const culture = require("../Globalization/plex-culture-number");
const strings = require("../Utilities/plex-utils-strings");

const api = {};
const customRules = [];

const pendingAsync = [];

const requiredStates = {
  required: 1,
  requireGroup: 2,
  requiredOff: 3,
  requireGroupOff: 4,
  notRequired: 5
};

api.requiredStates = requiredStates;

const ValidationController = function (element, config, controller) {
  /// <summary></summary>
  /// <param name="element" type="Object"></param>
  /// <param name="config" type="Object"></param>

  this.$element = $(element);
  this.config = config;
  this.controller = controller;
  this.init();
};

function getElements(validationController, propertyName) {
  let $elements;

  if (validationController.controller.$elements) {
    $elements = validationController.controller.$elements[propertyName];

    if ($elements) {
      return $elements;
    }
  }

  return [validationController.$element.find($("[name='" + propertyName + "']"))];
}

function getFriendlyControlName($element, propertyName) {
  // Check if element already contains a friendly name
  let controlName = $element.data(propertyName);

  if (controlName) {
    return controlName;
  }

  // exclude the error labels, which are also 'for' the element
  controlName = $("label[for='" + $element.attr("id") + "']")
    .not(".plex-error")
    .text()
    .trim();

  // Use the handle for validation if it exists
  if (!controlName && $element.data("handle")) {
    controlName = $element.data("handle");
  }

  if (!controlName) {
    controlName = propertyName.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return controlName;
}

function resolveElement(validationController, propertyName, resultCollection, resultIndex, ruleViolationIndex) {
  /// <summary>
  /// Resolves element for server-side collection of validation results, such as for editable grids, validation result may be returned for each record.
  /// </summary>

  let index = resultIndex;
  const errorIndexes = resultCollection[resultIndex].ErrorIndexes;
  let $elements = getElements(validationController, propertyName);
  const controller = validationController.controller;

  if ($elements.length === 1 && $elements[0] instanceof $ && $elements[0].length > 1) {
    // actual element collection is in the first array item
    $elements = $elements[0].toArray().map((x) => $(x));
  }

  if ($elements.length > 0) {
    // ErrorIndexes are populated by collection validation.
    if (errorIndexes && errorIndexes.length > 0 && $elements[errorIndexes[ruleViolationIndex]]) {
      index = errorIndexes[ruleViolationIndex];
    } else if (typeof controller.getSelectedBySelectionMode === "function") {
      const selected = controller.getSelectedBySelectionMode();
      if (selected.length === resultCollection.length) {
        index = selected[resultIndex].$$index;
      }

      // The element index might be different than the result index in cases the element is not rendered such as in master column
      const $indexElement = $elements.filter(($el) => {
        return $el.closest("tr").data("index") === index;
      });
      if ($indexElement.length > 0) {
        index = $elements.indexOf($indexElement[0]);
      }
    }

    return {
      index,
      $element: $elements[index] instanceof $ ? $elements[index] : $($elements[index])
    };
  } else {
    return null;
  }
}

// We always apply the rule unless there's a condition and
// the condition is false
function shouldApplyRule(rule, data) {
  if (rule && rule.condition) {
    const fn = expressions.compile(rule.condition);

    // If this is a collection rule, evaluate against collection data
    let args = rule.collectionName ? [data[rule.collectionName][rule.collectionIndex]] : [data];

    if (rule.associatedElementId && data.$$controller && data.$$controller.elements) {
      args = args.concat([null, null, data.$$controller.elements[rule.associatedElementId]]);
    }

    return fn.apply(null, args);
  }

  return true;
}

function displayBannerMessage(pageBanner, numberOfErrors) {
  if (numberOfErrors > 0) {
    const message = {};
    message.autoGlossarize = true;
    message.tokens = [numberOfErrors];

    if (numberOfErrors === 1) {
      message.text = "There was {1} error processing the form. Fix the entry highlighted in red.";
    } else {
      message.text = "There were {1} errors processing the form. Fix the entries highlighted in red.";
    }

    const options = { publisherName: "framework-validation" };

    pageBanner.setMessage(message, options);
  }
}

function getViewModelFor(element, propertyName) {
  const context = ko.contextFor(element);
  if (!propertyName) {
    return context.$root.data ? context.$root : context.$data;
  }

  if (context.$root.data && context.$data) {
    if (context.$data.data && propertyName in context.$data.data) {
      return context.$data;
    } else if (context.$index && context.$parent?.data && propertyName in context.$parent.data) {
      // handle nested data, for example with a repeater element
      return context.$parent;
    } else {
      return context.$root;
    }
  }

  if (context.$root.data) {
    return context.$root;
  }

  return context.$data;
}

function isValidValue(propertyValue) {
  let validValue = true;

  if (typeof propertyValue === "number" && isNaN(propertyValue)) {
    validValue = false;
  }

  if (Array.isArray(propertyValue)) {
    validValue = propertyValue.length > 0;
  }

  if (typeof propertyValue === "boolean" && propertyValue === false) {
    validValue = false;
  }

  return dataUtils.isEmpty(propertyValue) === false && validValue;
}

function getPropertyValue(element, record) {
  const propertyName = $(element).attr("data-val-property-name");
  return dataUtils.getValue(record, propertyName);
}

function getValue(element, record) {
  if (element.$$controller && element.$$controller.getValue) {
    return element.$$controller.getValue();
  }

  return getPropertyValue(element, record);
}

function calculateRangeForYearsUnit(primaryDate, secondaryDate, maxValue) {
  // Amount of days for not leap year
  const multiplier = 365;

  let rangeInDays = maxValue * multiplier;
  const primaryYear = primaryDate.getFullYear();
  const secondaryYear = secondaryDate.getFullYear();

  for (let year = primaryYear + 1; year < secondaryYear; year++) {
    if (isLeapYear(year)) {
      rangeInDays++;
    }
  }

  // getMonth() returns a 0-based index.
  // This returns true for primary dates in January & February of a leap year.
  if (isLeapYear(primaryYear) && primaryDate.getMonth() <= 1) {
    rangeInDays++;
  }

  // getMonth() returns a 0-based index.
  // This returns true for secondary dates March or later of a leap year.
  if (isLeapYear(secondaryYear) && secondaryDate.getMonth() > 1) {
    rangeInDays++;
  }

  return rangeInDays;
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

ValidationController.prototype = {
  constructor: ValidationController,

  init: function () {
    /// <summary>
    /// Starts up the validation engine and sets the default behavior.
    /// </summary>

    const self = this;

    const $element = self.$element.length ? self.$element : self.controller.$element;
    self.banner = banner.findClosest(domUtils.isInDialog($element) ? domUtils.getTopDialog() : $(document.body));

    self.rules = self.config.rules;

    if (self.config.validationCollectionProperties) {
      self.setupCollectionValidation(self.config.validationCollectionProperties, self.rules);
    }

    // Override jquery-validate checkForm() to support multiple html elements with the same name attribute.
    // http://bit.ly/P1OFyf
    $.validator.prototype.checkForm = function () {
      this.prepareForm();
      for (let i = 0, elements = (this.currentElements = this.elements()); elements[i]; i++) {
        if (this.findByName(elements[i].name).length !== undefined && this.findByName(elements[i].name).length > 1) {
          for (let cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
            this.check(this.findByName(elements[i].name)[cnt]);
          }
        } else {
          this.check(elements[i]);
        }
      }
      return this.valid();
    };

    // Override jquery-validate showErrors() to support multiple html elements with the same name attribute.
    $.validator.prototype.showErrors = function (errors) {
      if (errors) {
        // add items to error list and map
        $.extend(this.errorMap, errors);
        this.errorList = Object.keys(errors).map((id) => {
          return {
            message: errors[id].message,
            element: this.findByName(errors[id].name)[errors[id].index]
          };
        });

        // remove items from success list
        this.successList = $.grep(this.successList, (element) => {
          return !(element.name in errors);
        });
      }
      if (this.settings.showErrors) {
        this.settings.showErrors.call(this, this.errorMap, this.errorList);
      } else {
        this.defaultShowErrors();
      }
    };

    // Override jquery-validate optional() to support conditional requred.
    $.validator.prototype.optional = function (element) {
      const val = this.elementValue(element);
      return $.validator.methods.isOptional.call(this, val, element);
    };

    if (self.rules) {
      self.rules = self.rules.concat(customRules);
    } else {
      self.rules = customRules;
    }

    self.getCustomHighlightedElements = function ($el) {
      // Collection of parent elements which should be highlighted instead of inputs
      const customHighlightedElements = [".plex-picker-input-wrapper", ".plex-icon-picker-wrapper"];

      return customHighlightedElements.filter((selector) => {
        return $el.parents(selector).length > 0;
      });
    };

    self.validator = self.$element.validate({
      errorClass: "plex-error",
      wrapper: "div",
      ignore: [],
      showErrors: function () {
        this.defaultShowErrors();
      },
      highlight: function (element, errorClass) {
        const $el = $(element);
        const customHighlighted = self.getCustomHighlightedElements($el);
        if (customHighlighted.length > 0) {
          $el.not("[disabled]").parents(customHighlighted[0]).addClass(errorClass);
        } else {
          $(element).not("[disabled]").addClass(errorClass);
        }

        // open section if it is collapsed
        $el.closest(".plex-fieldset-collapsible.collapsed").removeClass("collapsed");

        this.$invalidElements[$el.attr("id")] = $el;
      },

      unhighlight: function (element, errorClass) {
        const $el = $(element);
        const customHighlighted = self.getCustomHighlightedElements($el);

        if (customHighlighted.length > 0) {
          $el.parents(customHighlighted[0]).removeClass(errorClass);
        } else {
          $(element).removeClass(errorClass);
        }

        delete this.$invalidElements[$el.attr("id")];
      },
      invalidHandler: function () {
        $(self.validator.errorList[0].element).focus();
      },
      onfocusout: false,
      onkeyup: false,
      onclick: false,

      errorPlacement: function (error, element) {
        const closest = element.closest(".plex-controls");

        if (closest.length > 0) {
          error.appendTo(closest);
        } else {
          error.insertAfter(element);
        }

        let parents = element.closest(".plex-controls-element");
        if (parents.length === 0) {
          parents = element.closest(".plex-controls");
        }
        if (parents.length > 0 && closest.find(".plex-controls-element").find("input").length > 1) {
          error.appendTo(parents[0]);
        }
      }
    });

    if (self.validator) {
      // This is used to track invalid elements
      self.validator.$invalidElements = {};

      // Validation Controllers within the same form will have the same $.validator
      self.validator.validationControllers = self.validator.validationControllers || [];
      self.validator.validationControllers.push(self);
    }
  },

  getElementRequiredState: function (propertyName, data, controller) {
    const requiredOffs = [];

    // Evaluate whether Required
    const requireds = controller.rules.filter((rule) => {
      return rule.propertyName === propertyName && (rule.name === "required" || rule.name === "requiredDate");
    });

    if (requireds.length > 0) {
      if (shouldApplyRule(requireds[0], data)) {
        return requiredStates.required;
      }

      requiredOffs.push(requiredStates.requiredOff);
    }

    // Evaluated whether in a RequiredGroup
    const requireGroups = controller.rules.filter((rule) => {
      return rule.propertyName === propertyName && rule.name === "requireGroup";
    });

    if (requireGroups.length > 0) {
      const requireGroupsApplied = requireGroups.filter((rule) => {
        return shouldApplyRule(rule, data);
      });
      if (requireGroupsApplied.length > 0) {
        return requiredStates.requireGroup;
      }

      requiredOffs.push(requiredStates.requireGroupOff);
    }

    return requiredOffs[0] || requiredStates.notRequired;
  },

  setupCollectionValidation: function (validationCollectionProperties, rules) {
    // Setup Collection Validation for Each Collection Property
    validationCollectionProperties.forEach((validation) => {
      // Create a Rule for Each item in the collection
      validation.rules.forEach((rule) => {
        // Get the list of collection property elements
        const collectionElements = $(
          "[name^=" + validation.propertyName + "\\[][name$=\\]\\." + rule.propertyName + "]"
        ).get();

        // To Support elements added via repeater
        if (collectionElements.length === 0) {
          rules.push(rule);
        }

        // Create A Rule for each collection property
        collectionElements.forEach((element) => {
          const collectionRule = $.extend({}, rule);
          collectionRule.propertyName = element.name;
          collectionRule.collectionName = validation.propertyName;
          collectionRule.collectionIndex = element.name.match(/\[(.*?)]/)[1];
          rules.push(collectionRule);
        });
      });
    });
  },

  whenCustomValidationReady: function () {
    /// <summary>
    /// Returns a promise that is resolved when all custom validation rules are ready to be used.
    /// </summary>

    const deferred = new $.Deferred();

    $.when.apply($, pendingAsync).done(() => {
      // resolve promise when all custom rule's messages are glossarized
      deferred.resolve();
    });

    return deferred;
  },

  initPropertyValidation: function (propertyName) {
    /// <summary>
    /// Attaches the rules to a property's element.
    /// </summary>
    /// <param name="propertyName">The property that will have their rules initialized.</param>

    const self = this;

    if (!self.controller) {
      return;
    }

    if (pendingAsync.length > 0) {
      jsUtils.defer(self.initPropertyValidation, self, arguments, 0);

      return;
    }

    const propertyRules = self.rules.filter((item) => {
      return item.propertyName === propertyName;
    });

    const rules = { messages: {} };

    ko.utils.arrayForEach(propertyRules, (rule) => {
      const key = rule.name;
      const type = rule.type;
      let labelText;

      getElements(self, propertyName).forEach(($element) => {
        if ($element.length === 0 || typeof $.validator.methods[key] === "undefined") {
          return;
        }

        // TODO: Figure out serialization so I can use a string of enum name instead of value.
        switch (type) {
          case "Boolean":
            // Boolean
            rules[key] = true;
            break;
          case "Value":
            // Value
            rules[key] = rule.value;
            break;
          default:
            break;
        }

        // TODO: See if there is another way to get property from element.
        $element.attr("data-val-property-name", propertyName);

        if (key === "requireGroup") {
          $element.attr("data-val-require-group", "true");
        }

        labelText = getFriendlyControlName($element, propertyName);

        if (rule.isPercentageFormat) {
          $element.data("isPercentageFormat", true);
        }

        if (rule.message) {
          if (rule.name === "overflow") {
            // Overflow message needs 2 special tokens: minValue and maxValue
            rules.messages[key] = $.validator.format(rule.message, labelText, rule.minValue, rule.maxValue);
          } else {
            rules.messages[key] = $.validator.format(rule.message, labelText, rule.value);
          }
        }

        // Attach complete rule to element
        let completeRules = $element.data("rules");

        if (!completeRules) {
          completeRules = {};
        }

        if (!completeRules[key]) {
          completeRules[key] = {};
        }

        completeRules[key] = ko.track(rule);
        $element.data("rules", completeRules);

        $element.rules("add", rules);
      });
    });
  },

  numberOfInvalids: function () {
    /// <summary>
    /// Returns the number of invalid elements found on the controller.
    /// </summary>
    /// <returns type="">The number of invalid elements found on the controller.</returns>

    const self = this;
    return Object.keys(self.validator.$invalidElements).length;
  },

  applyCustomValidation: function (propertyName, ruleName, value) {
    /// <summary>
    /// Attaches a client-side custom rule to a property.
    /// </summary>
    /// <param name="propertyName">The property that will have the rule applied to.</param>
    /// <param name="ruleName">The name of the client-side rule.</param>
    /// <param name="value">(optional)The value that the property will be compared against.</param>

    const self = this;
    getElements(self, propertyName).forEach(($element) => {
      if ($element.length === 0) {
        return;
      }

      const rules = {};

      if (typeof value === "undefined") {
        rules[ruleName] = true;
      } else {
        rules[ruleName] = value;
      }

      $element.rules("add", rules);
    });
  },

  getValidationResult: function (dataResult, result, resultCollection) {
    if (dataResult.ValidationErrors && dataResult.ValidationErrors.length > 0) {
      let i, ruleViolation;
      for (i = 0; i < dataResult.ValidationErrors.length; i++) {
        ruleViolation = dataResult.ValidationErrors[i];

        const elementResult = resolveElement(
          this,
          ruleViolation.PropertyName,
          resultCollection,
          resultCollection.indexOf(dataResult),
          i
        );

        if (elementResult && elementResult.$element && elementResult.$element.length > 0) {
          const labelText = getFriendlyControlName(elementResult.$element, ruleViolation.PropertyName);

          result[elementResult.$element[0].id] = {
            name: elementResult.$element[0].name,
            message: $.validator.format(ruleViolation.Message, labelText, ruleViolation.Value),
            index: elementResult.index
          };
        }
      }
    }
  },

  validateResults: function (data) {
    /// <summary>
    /// Used to parse through server-side validation results. If validation errors were found, it will display the errors.
    /// </summary>
    /// <param name="data">The JSON object containing the results from the server.</param>
    /// <returns type="">Returns false if there were validation errors. Otherwise, returns true.</returns>

    const self = this;
    let isValid = true;
    const $el = self.controller.$element || self.$element;
    const options = {};

    $el.find(".plex-error:not(label)").removeClass("plex-error");
    $el.find("label.plex-error").parent().remove();

    if (self.validator) {
      // reset invalid elements, already removed all errors
      self.validator.$invalidElements = {};
    }

    const result = {};

    if (data.CollectionValidationResults && data.CollectionValidationResults.length > 0) {
      data.CollectionValidationResults.forEach((dataResult) => {
        self.getValidationResult(dataResult, result, data.CollectionValidationResults);
      });
    }

    self.getValidationResult(data, result, [data]);

    if ($.isEmptyObject(result)) {
      self.banner.cancel({ autoCancel: true, publisherName: "framework-validation" });
    } else {
      self.validator.resetForm();
      self.validator.showErrors(result);
      displayBannerMessage(self.banner, self.numberOfInvalids());
      isValid = false;
    }

    if (data.Message) {
      isValid = isValid && data.Success;
      if (isValid === true) {
        options.status = banner.states.success;
      }

      options.publisherName = "framework-validation";

      self.banner.setMessage(data.Message, options);
    }

    return isValid;
  },

  // Calls isValid on validation controllers. The controllers
  // must have the same $validator
  validateJustValidationControllers: function () {
    let controller;
    let valid = true;

    if (arguments.length === 0) {
      return true;
    }

    const validator = arguments[0].validator;
    const pageBanner = validator ? validator.banner : null;

    for (let arg = 0; arg < arguments.length; ++arg) {
      controller = arguments[arg];
      if (controller.validator) {
        // Only change valid if false
        valid = controller.validator.isValid(true) ? valid : false;
      }
    }

    if (valid) {
      pageBanner.cancel({ publisherName: "framework-validation" });
      return true;
    } else {
      displayBannerMessage(pageBanner, validator.numberOfInvalids());
      return false;
    }
  },

  // Calls valid for all validation controllers that are passed in
  // along with any validations controllers attached to the same
  // $validator. Example would be two grids each with a validator
  // but they share the same banner
  validateMultipleRelatedValidators: function () {
    let controller, $validator, exists;
    const self = this;
    let errors = 0;
    const $validators = [];

    // User can pass controls to validate together
    for (let arg = 0; arg < arguments.length; ++arg) {
      controller = arguments[arg];
      if (controller.validator) {
        $validator = controller.validator.validator;

        exists = (function ($val) {
          return $validators.some((val) => {
            if (val === $val) {
              return true;
            }
            return false;
          });
        })($validator);

        if (!exists) {
          $validators.push({ validator: $validator, controller });
        }
      }
    }

    $validators.forEach((val) => {
      errors += self.validateRelatedValidationControllers(val.validator, val.controller);
    });

    return errors;
  },

  // Validates all ValidationControllers attached to the $validator.
  // Example would be a form with a grid that both have validation.
  validateRelatedValidationControllers: function ($validator, _controller) {
    let valid = true;
    let errors = 0;

    $validator.validationControllers.forEach((validationController) => {
      if (!validationController.isValid(true)) {
        valid = false;
      }
    });

    if (!valid) {
      errors = $validator.validationControllers[0].numberOfInvalids();
    }

    return errors;
  },

  validateAll: function () {
    let errors = 0;
    let pageBanner = this.banner;

    if (!this.controller || !this.validator) {
      return true;
    }

    // If user passed in controllers to validate
    if (arguments.length > 0) {
      errors = this.validateMultipleRelatedValidators.apply(this, arguments);
      // Reset Banner to First Validator
      pageBanner = arguments.validator && arguments.validator.banner ? arguments.validator.banner : pageBanner;
    } else {
      errors = this.validateRelatedValidationControllers(this.validator, this.controller);
    }

    const hasErrors = errors > 0;

    pubsub.publish("validationCompleted", this.validator.$invalidElements);

    if (hasErrors) {
      displayBannerMessage(pageBanner, errors);
      return false;
    } else {
      if (pageBanner) {
        pageBanner.cancel({ autoCancel: true, publisherName: "framework-validation" });
      }

      return true;
    }
  },

  isValid: function (suppressError) {
    /// <summary>
    /// Client-side check to see if the current model is valid.
    /// </summary>
    /// <returns type="">Returns false if there were validation errors. Otherwise, returns true.</returns>

    const self = this;
    let valid = true;

    if (self.rules.length === 0 || !self.controller) {
      return true;
    }

    // remove all elements from "$invalidElements" that are no longer validatable
    $.each(self.validator.$invalidElements, (index, el) => {
      const shouldValidate = self.shouldValidateElement(el);

      if (shouldValidate === false) {
        delete self.validator.$invalidElements[index];
      }
    });

    // Get elements
    const $elements = self.controller.$elements;
    const invalidElements = Object.keys(self.validator.$invalidElements);

    if ($elements && self.controller && self.controller.$elements) {
      Object.keys($elements).forEach((element) => {
        $.each($elements[element], (_index, el) => {
          if (invalidElements.indexOf(el.attr("id")) === -1 && self._isValidatableData(el) === false) {
            return;
          }

          $(el).each((_i, e) => {
            const $el = $(e);
            const shouldValidate = self.shouldValidateElement(e);

            if (shouldValidate && !$el.valid()) {
              valid = false;
            }
          });
        });
      });
    }

    const controller = self.controller;

    if (controller && controller.onValidation) {
      controller.onValidation(valid);
    }

    if (valid && !suppressError) {
      self.banner.cancel({ publisherName: "framework-validation" });
      return true;
    } else if (!valid && !suppressError) {
      displayBannerMessage(self.banner, self.numberOfInvalids());
      return false;
    }

    return valid;
  },

  /// <summary>
  /// Determines if the given element should be validated.
  /// </summary>
  /// <param name="el">The DOM element (or jQuery object that represents a DOM element) the in question of being validated.</param>
  /// <returns type="bool">Returns true if the element should be validated; otherwise, returns false.</returns>
  shouldValidateElement: function (el) {
    let shouldValidate;
    /* eslint no-undef: "off" */
    const element = el instanceof jQuery ? el[0] : el;
    const koContext = ko.contextFor(element);

    if (!koContext) {
      shouldValidate = false;
    } else if (koContext.$data.shouldValidate) {
      shouldValidate = ko.unwrap(koContext.$data.shouldValidate);
    } else if (koContext.$data.config && koContext.$data.config.shouldValidate) {
      shouldValidate = ko.unwrap(koContext.$data.config.shouldValidate);
    } else if (koContext.$data.options && koContext.$data.options.shouldValidate) {
      shouldValidate = ko.unwrap(koContext.$data.options.shouldValidate);
    } else {
      shouldValidate = false;
    }

    return shouldValidate;
  },

  _isValidatableData: function (el) {
    const self = this;
    const index = $(el).closest("tr").data("index");

    if (
      self.config.modifiedOnly === true &&
      self.controller.datasource &&
      self.controller.datasource.source().length > index
    ) {
      const record = self.controller.datasource.source()[index];

      if (record.$$dirtyFlag && record.$$dirtyFlag.isDirty() === false) {
        return false;
      }

      return true;
    }

    if (
      self.config.selectedOnly === true &&
      self.controller.config.selectionMode &&
      self.controller.datasource &&
      self.controller.datasource.source().length > index
    ) {
      const record = self.controller.datasource.source()[index];

      if (
        gridSelectionMode.isSelectable(self.controller.config.selectionMode) &&
        record.$$selected &&
        record.$$selected() === false
      ) {
        return false;
      }

      if (
        gridSelectionMode.isDirty(self.controller.config.selectionMode) &&
        record.$$dirtyFlag &&
        record.$$dirtyFlag.isDirty() === false
      ) {
        return false;
      }
    }

    return true;
  }
};

api.createValidator = function (element, config, controller) {
  /// <summary>
  /// Creates a validation controller.
  /// </summary>
  /// <param name="element">The form element that the validation controller will scan through.</param>
  /// <param name="config">JSON object containing the rules to apply to the model.</param>
  /// <param name="controller">The parent controller that is creating the validation controller.</param>
  /// <returns type="">A new validation controller.</returns>

  return new ValidationController(element, config, controller);
};

api.createCustomRule = function (ruleName, ruleMessage, validationFunction) {
  /// <summary>
  /// Creates a client-side custom rule and adds it to the validation engine.
  /// </summary>
  /// <param name="ruleName">The name of the rule. Must be unique</param>
  /// <param name="ruleMessage">The message (which is auto-glossarized) that will display if the element is found to be invalid.
  /// This can be set as a function that will be passed the rule parameters, and then the element that was validated.
  /// If using a function to return a message, you are responsible for glossarization.</param>
  /// <param name="validationFunction">The function that runs the actual validation. Must return a boolean value.</param>

  if (typeof ruleMessage === "function") {
    $.validator.addMethod(ruleName, validationFunction, ruleMessage);
  } else {
    const promise = glossary.getCustomerWordAsync(ruleMessage);
    pendingAsync.push(promise);

    promise.done((gloassarizedMessage) => {
      $.validator.addMethod(ruleName, validationFunction, gloassarizedMessage);
      pendingAsync.remove(promise);
    });
  }
};

api.applyValidationRule = function (propertyName, ruleName, value) {
  /// <summary>
  /// Link a rule to a property in the model.
  /// </summary>
  /// <param name="propertyName">The property that will have the rule attached to.</param>
  /// <param name="ruleName">The name of the rule to run.</param>
  /// <param name="value">(optional) The value to compare the property to.</param>

  const rule = {};

  rule.name = ruleName;
  rule.propertyName = propertyName;

  if (typeof value === "undefined") {
    rule.type = "Boolean";
  } else {
    rule.type = "Value";
    rule.value = value;
  }

  customRules.push(rule);
};

// Validating from model, input data could have globalized commas or
// periods 10.000 or 10,000. Input control will prevent non-numeric
// or decimal values.
$.validator.addMethod("integer", function (_value, element) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").integer;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const propertyValue = getValue(element, viewModel.data);
  return this.optional(element) || propertyValue % 1 === 0;
});

$.validator.addMethod("requireGroup", (_currentValue, element, ruleValue) => {
  // Return the Required Rule
  const rule = $(element).data("rules").requireGroup;
  const propertyName = $(element).attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const selector = "[data-val-require-group]";
  const validOrNot =
    $(selector, element.form).filter(function () {
      const $element = $(this);

      const requiredPropertyName = $element.attr("data-val-property-name");
      if (rule.propertyGroupNames.indexOf(requiredPropertyName) === -1) {
        return false;
      }

      const propertyValue = getValue(this, viewModel.data);
      return isValidValue(propertyValue);
    }).length >= ruleValue;

  if (!$(element).data("being_validated")) {
    const fields = $(selector, element.form);
    fields.data("being_validated", true);
    fields.valid();
    fields.data("being_validated", false);
  }

  return validOrNot;
});

$.validator.addMethod("time", (_value, element) => {
  const $element = $(element);
  const requiredPropertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, requiredPropertyName);
  const rule = $element.data("rules").time;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  let invalidDate = ko.contextFor(element).$data.displayInvalidDateText;
  if (invalidDate) {
    invalidDate = invalidDate();
  }

  let propertyValue = getValue(element, viewModel.data);
  if (!(propertyValue instanceof Date)) {
    propertyValue = new Date(propertyValue);
  }

  // Do not call optional on Date, not necessary and causes unintended side effects
  return (!/Invalid|NaN/.test(propertyValue.toString()) && !invalidDate) || (!propertyValue && !invalidDate);
});

// Changed from validating input value to validating model value. Input data
// could contain commas or could be globalized which would swap commas and decimals.
// Model value decimal scale can be controlled by the inputbox knockout numeric binding
$.validator.addMethod("decimal", function (_value, element, decimalPlaces) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").decimal;
  const isPercentageFormat = $element.data("isPercentageFormat");

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const propertyValue = getValue(element, viewModel.data);
  let isValid = $.isNumeric(propertyValue);

  if (isValid && isPercentageFormat) {
    decimalPlaces += 2;
  }

  if (isValid && Math.floor(propertyValue) !== propertyValue) {
    const resultLength = propertyValue.toString().split(".")[1].length || 0;
    isValid = resultLength >= 0 && resultLength <= decimalPlaces;
  }

  return this.optional(element) || isValid;
});

$.validator.addMethod("dateBefore", function (_value, element, endDatePropertyName) {
  const $beginDate = $(element);
  let $endDate;
  const rule = $beginDate.data("rules").dateBefore;
  const beginDatePropertyName = $beginDate.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, beginDatePropertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const beginDateValue = dataUtils.getValue(viewModel.data, beginDatePropertyName);
  const endDateValue = dataUtils.getValue(viewModel.data, endDatePropertyName);

  // Property exists in a grid
  if (Object.prototype.hasOwnProperty.call(viewModel, "index")) {
    $endDate = $(element)
      .closest("tr")
      .find("[name='" + endDatePropertyName + "']");
  } else {
    $endDate = $("[name='" + endDatePropertyName + "']");
  }

  // Daterange picker enforces endDate > beginDate
  if ($endDate.length === 0) {
    return true;
  }

  return this.optional(element) || this.optional($endDate[0]) || beginDateValue <= endDateValue;
});

$.validator.addMethod("url", function (value, element) {
  const rule = $(element).data("rules").url;
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  return this.optional(element) || parsing.isValidExternalUrl(value);
});

$.validator.addMethod("expression", function (_value, element, expressionText) {
  // Return the Required Rule
  const rule = $(element).data("rules").expression;
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  if (this.optional(element)) {
    return true;
  }

  const fn = expressions.compile(expressionText);
  return fn(viewModel.data);
});

$.validator.addMethod("regex", function (value, element, pattern) {
  const rule = $(element).data("rules").regex;
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const regex = new RegExp(pattern);

  return this.optional(element) || regex.test(value);
});

$.validator.addMethod("regexList", function (value, element) {
  const rule = $(element).data("rules").regexList;
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false || this.optional(element)) {
    return true;
  }

  const regexRules = rule.rules;

  for (let i = 0; i < regexRules.length; i++) {
    const regexRule = regexRules[i];
    const regex = new RegExp(regexRule.pattern);

    if (!regex.test(value)) {
      const labelText = getFriendlyControlName($element, propertyName);
      $.validator.messages.regexList = $.validator.format(regexRule.message, labelText);
      return false;
    }
  }

  return true;
});

$.validator.methods.required = function (_value, element, param) {
  const $element = $(element);

  // Return the Required Rule
  const rule = $element.data("rules").required;
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);

  // Required is called for all rules. So we need to run the original required code for rules we don't explicitly specify as required.
  if (!this.depend(param, element)) {
    return "dependency-mismatch";
  }

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const propertyValue = getValue(element, viewModel.data);
  return isValidValue(propertyValue);
};

$.validator.methods.requiredDate = function (_value, element, _param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").requiredDate;
  const propertyValue = dataUtils.getValue(viewModel.data, propertyName);

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const invalidDate = ko.contextFor(element).$data.displayInvalidDateText;
  if (invalidDate && invalidDate()) {
    return false;
  }

  return dataUtils.isEmpty(propertyValue) === false;
};

// This Method is validating against a date property on the model, which will always be a valid date. Care
// must be taken in the date conversion process as new Date("2/31/2014") would convert to 3/3/2014 and still
// be seen as a valid date. Globalize.parseDate() solves this problem, but as long as a date picker is used,
// this shouldnt be an issue because the date picker only allows valid dates.
$.validator.methods.date = function (_value, element, _param) {
  const $element = $(element);
  const requiredPropertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, requiredPropertyName);
  const rule = $element.data("rules").date;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  // DateTime picker only allows valid dates, invalidDate is used as an
  // indicator to the user that the date they entered was invalid. This prevents
  // issues where user enters a date and immediatley presses ok, when date is optional.
  // User would not know the date was invalid and a null would be sent.
  let invalidDate = ko.contextFor(element).$data.displayInvalidDateText;
  if (invalidDate) {
    invalidDate = invalidDate();
  }

  let propertyValue = dataUtils.getValue(viewModel.data, requiredPropertyName);
  if (!(propertyValue instanceof Date)) {
    propertyValue = new Date(propertyValue);
  }

  // Do not call optional on Date, not necessary and causes unintended side effects
  return (!/Invalid|NaN/.test(propertyValue.toString()) && !invalidDate) || (!propertyValue && !invalidDate);
};

$.validator.methods.validSqlDate = function (_value, element, _param) {
  const $element = $(element);
  const requiredPropertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, requiredPropertyName);
  const rule = $element.data("rules").validSqlDate;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const isYearWithinBoundaries = function (date) {
    if (date) {
      const year = date.getUTCFullYear();
      const minSqlDate = { year: 1753, month: 0, date: 1 };
      const maxSqlDate = { year: 9999, month: 11, date: 31 };

      if (year < minSqlDate.year || year > maxSqlDate.year) {
        return false;
      }
    }
    return true;
  };

  const propertyValue = dataUtils.getValue(viewModel.data, requiredPropertyName);

  // Secondary Date Ranges
  const secondaryPropertyName = ko.contextFor(element).$data.config
    ? ko.contextFor(element).$data.config.secondaryPropertyName
    : null;
  const secondaryPropertyValue = dataUtils.getValue(viewModel.data, secondaryPropertyName);

  return isYearWithinBoundaries(propertyValue) && isYearWithinBoundaries(secondaryPropertyValue);
};

$.validator.methods.maxDateRange = function (_value, element, maxValue) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").maxDateRange;

  // Process any rule conditions
  if (this.optional(element) || shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const primaryDateValue = dataUtils.getValue(viewModel.data, propertyName);

  const context = ko.contextFor(element);
  const config = context && context.$data ? context.$data.config : null;

  // Secondary Date Ranges
  const secondaryPropertyName = config ? config.secondaryPropertyName : null;
  let secondaryDateValue = dataUtils.getValue(viewModel.data, secondaryPropertyName);

  if (!primaryDateValue || !secondaryDateValue) {
    return true;
  }

  let rangeInDays = maxValue;
  if (rule.valueUnit === "years") {
    rangeInDays = calculateRangeForYearsUnit(primaryDateValue, secondaryDateValue, maxValue);
  }

  if (config.secondaryDateAdjust) {
    const adjustment = 1;
    if (config.enableTime) {
      secondaryDateValue = new secondaryDateValue.constructor(
        dateUtils.subtractMinutes(secondaryDateValue, adjustment)
      );
    } else {
      secondaryDateValue = new secondaryDateValue.constructor(dateUtils.subtractDays(secondaryDateValue, adjustment));
    }
  }

  // The compareDays function ignores the time component & only compares the calendar dates.
  return Math.abs(dateUtils.compareDays(secondaryDateValue, primaryDateValue)) <= rangeInDays;
};

$.validator.methods.min = function (_value, element, param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").min;
  const isPercentageFormat = $element.data("isPercentageFormat");

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  if (isPercentageFormat) {
    param /= 100;
  }

  const propertyValue = getValue(element, viewModel.data);
  return this.optional(element) || propertyValue >= param;
};

$.validator.methods.max = function (_value, element, param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $element.data("rules").max;
  const isPercentageFormat = $element.data("isPercentageFormat");

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  if (isPercentageFormat) {
    param /= 100;
  }

  const propertyValue = getValue(element, viewModel.data);
  return this.optional(element) || propertyValue <= param;
};

$.validator.methods.overflow = function (_value, element, _param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const propertyValue = getPropertyValue(element, viewModel.data);
  const rule = $element.data("rules").overflow;
  const minValue = rule.minValue;
  const maxValue = rule.maxValue;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  // Exclude arrays from being range validated here
  // Case: picker's key property is probably numeric in server-side model, but on client it will be an array
  return (
    this.optional(element) || Array.isArray(propertyValue) || (propertyValue >= minValue && propertyValue <= maxValue)
  );
};

$.validator.methods.number = function (value, element, _param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $(element).data("rules").number;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  if (typeof value === "string") {
    value = culture.parseNumber(value, { strict: true });
  }

  return this.optional(element) || !isNaN(value);
};

$.validator.methods.email = function (value, element, _param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $(element).data("rules").email;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  let isValid = true;
  if (this.optional(element) === false && value) {
    const emails = value.split(/[;,]+/).filter((record) => {
      return record !== "";
    });
    emails.forEach((email) => {
      isValid =
        isValid &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(
          email.trim()
        );
    });
  }

  return isValid;
};

$.validator.methods.maxlength = function (value, element, param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  let rule = null;

  if ($(element).data("rules")) {
    rule = $(element).data("rules").maxlength;
  }

  // The maxlength attribute will trigger this rule. We want skip this
  // rule if maxlength has not been configured as a rule
  if (!rule) {
    return true;
  }

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const propertyValue = getValue(element, viewModel.data);
  const length = Array.isArray(propertyValue) ? value.length : strings.byteLength(String(propertyValue).trim());
  return this.optional(element) || length <= param;
};

$.validator.methods.minlength = function (value, element, param) {
  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");
  const viewModel = getViewModelFor(element, propertyName);
  const rule = $(element).data("rules").minlength;

  // Process any rule conditions
  if (shouldApplyRule(rule, viewModel.data) === false) {
    return true;
  }

  const propertyValue = getValue(element, viewModel.data);
  const length = Array.isArray(propertyValue) ? value.length : strings.byteLength(String(propertyValue).trim());
  return this.optional(element) || length >= param;
};

$.validator.addMethod("isOptional", (_value, element, _param) => {
  const elOptional =
    !element.required ||
    element.required === api.requiredStates.notRequired ||
    element.required === api.requiredStates.requiredOff;

  const $element = $(element);
  const propertyName = $element.attr("data-val-property-name");

  const viewModel = getViewModelFor(element, propertyName);
  const propertyValue = getValue(element, viewModel.data);

  return elOptional && dataUtils.isEmpty(propertyValue);
});

module.exports = api;
plexExport("validation", api);
