ï»¿const plexExport = require("../../global-export");

const logger = {};
const consoleLogger = window.console;
const timers = Object.create(null);
let loggerEnabled = true;
const methodLevels = {
  debug: 1,
  log: 2,
  info: 3,
  warn: 4,
  error: 5
};

// default to 'log' level
let loggerLevel = 2;

// copy all methods to logger object
let initMethodName;
for (initMethodName in methodLevels) {
  if (Object.prototype.hasOwnProperty.call(methodLevels, initMethodName)) {
    logger[initMethodName] = (function (level) {
      return function (message) {
        log.call(logger, level, message);
      };
    })(initMethodName);
  }
}

function log(methodName, message) {
  // exit out if console isn't present or the level is below our current level
  if (!consoleLogger || !loggerEnabled || loggerLevel > methodLevels[methodName]) {
    return;
  }

  // fallback to "log" method if given method isn't present
  // prefix message with method name in this case
  if (!(methodName in consoleLogger)) {
    message = methodName.toUpperCase() + ": " + message;
    methodName = "log";
  }

  consoleLogger[methodName](message);
}

logger.time = function (name) {
  if (!loggerEnabled || !name) {
    return;
  }

  if ("time" in consoleLogger) {
    consoleLogger.time(name);
    return;
  }

  timers[name] = Date.now();
};

logger.timeEnd = function (name) {
  if (!loggerEnabled) {
    return;
  }

  if ("timeEnd" in consoleLogger) {
    consoleLogger.timeEnd(name);
    return;
  }

  if (name in timers) {
    log("log", name + ": " + (Date.now() - timers[name]) + "ms");
    delete timers[name];
  }
};

// public methods
logger.setLevel = function (level) {
  loggerLevel = level;
};

logger.off = function () {
  loggerEnabled = false;
};
logger.on = function () {
  loggerEnabled = true;
};

logger.logLevels = methodLevels;

module.exports = logger;
plexExport("logger", logger);
