ï»¿const $ = require("jquery");
const ko = require("knockout");
const logger = require("../../Core/plex-logger");
const printing = require("../../Core/plex-printing");
const DocumentXml = require("../../Utilities/plex-utils-documentxml");
const jsUtils = require("../../Utilities/plex-utils-js");
const stringUtils = require("../../Utilities/plex-utils-strings");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");

function cleanseDisplayValue(value) {
  if (typeof value === "string") {
    return stringUtils.removeHtml(value.replace(/(&nbsp;)/gi, ""), { replaceLineBreaks: true });
  }

  return value;
}

function createElementAttributes(element) {
  const attr = {};

  const viewName = printing.resolveViewName(element.viewName);
  if (
    ["richtexteditor", "htmlliteral"].indexOf(viewName) >= 0 ||
    (viewName === "datalabel" && element.printLabelAsHtml)
  ) {
    attr.richtext = true;
  }

  if (element.printBreak) {
    attr[element.printBreak.attribute] = element.printBreak.condition;
  }

  if (element.style && element.style()) {
    $.each(element.style(), (key, prop) => {
      const styleVal = hyphenateAttributeName(key) + ":" + prop + ";";
      attr.style = attr.style ? attr.style + styleVal : styleVal;
    });
  }

  return attr;
}

function hyphenateAttributeName(value) {
  return value.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

const DefaultXmlProvider = function (element) {
  // eslint-disable-next-line no-invalid-this
  this.config = element;
};

DefaultXmlProvider.prototype = {
  constructor: DefaultXmlProvider,

  onPreInit: function () {
    // empty but may be overriden by inheritors
  },

  init: function () {
    this.onPreInit();

    $.extend(this, this.config);
    this.node = new DocumentXml(this.getNodeName());

    this.onInit();
  },

  isInvalidNodeName: function () {
    const nodeName = this.getNodeName();
    if (nodeName.includes("~")) {
      return true;
    }
    return false;
  },

  onInit: function () {
    // empty but may be overriden by inheritors
  },

  getNodeName: function () {
    return "plex-control-" + printing.resolveViewName(this.viewName).replace(" ", "");
  },

  getXml: function () {
    if (printing.shouldPrint(this.config) && this.onGetXml.apply(this, arguments) !== false && this.node) {
      const attr = createElementAttributes(this.config);
      if (attr) {
        this.node.addAttribute(attr);
      }

      return this.node.serialize();
    }

    return "";
  },

  onGetXml: function () {
    if (typeof this.initialDisplayValue !== "undefined") {
      return this.node.setValue(cleanseDisplayValue(this.initialDisplayValue));
    }

    if (
      this.displayValue &&
      this.displayValue.initialValue &&
      typeof ko.unwrap(this.displayValue.initialValue) !== "undefined"
    ) {
      return this.node.setValue(cleanseDisplayValue(ko.unwrap(this.displayValue.initialValue)));
    }

    // check if controller will handle serialization
    const ctrl = plexImport("currentPage")[this.id];
    if (ctrl && typeof ctrl.toDocumentXml === "function") {
      return this.node.appendXml(ctrl.toDocumentXml());
    }

    logger.warn("Unable to serialize: " + this.viewName);
    return false;
  }
};

jsUtils.makeExtendable(DefaultXmlProvider);

module.exports = DefaultXmlProvider;
plexExport("printing.xmlProviders.DefaultXmlProvider", DefaultXmlProvider);
