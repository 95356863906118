const DefaultDataLabelValueProvider = require("./plex-labels-value-providers");
const dataToken = require("../Utilities/plex-utils-datatoken");
const plexExport = require("../../global-export");

const DataTokenValueProvider = DefaultDataLabelValueProvider.extend({
  init: function () {
    this.token = this.options.valueProvider.token;
  },

  getValue: function (record) {
    return dataToken.resolveToken(record, this.token, record.$$outputParameters);
  }
});

plexExport("labels.valueProviders.DataTokenValueProvider", DataTokenValueProvider);
