ï»¿const ko = require("knockout");

ko.extenders.negate = function (target) {
  /// <summary>Extender which inverts the boolean value of the target observable.</summary>

  const wrappedComputed = ko.deferredComputed(() => {
    return !target();
  });

  if (target.dispose) {
    const originalDispose = wrappedComputed.dispose;
    wrappedComputed.dispose = function () {
      target.dispose();
      originalDispose.call(wrappedComputed);
    };
  }

  return wrappedComputed;
};
